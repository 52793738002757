import {combineReducers} from 'redux';
import * as actions from './additional-company-data.actions';
import {LOCATION_CHANGED} from '../../../../lib/router/connected-router-saga';

const initialState = {
    enterpriseInformation: {},
    isLoadingEnterpriseInformation: true,
    isUpdatingEnterpriseInformation: false,
};

const enterpriseInformationReducer = (state = initialState.enterpriseInformation, action) => {
    if (action.type === actions.STORE_ENTERPRISE_INFORMATION) {
        return action.payload;
    }

    if (action.type === LOCATION_CHANGED) {
        return initialState.enterpriseInformation;
    }

    return state;
};

const isLoadingEnterpriseInformationReducer = (state = initialState.isLoadingEnterpriseInformation, action) => {
    if (action.type === actions.SET_IS_LOADING_ENTERPRISE_INFORMATION) {
        return action.payload;
    }

    if (action.type === LOCATION_CHANGED) {
        return initialState.isLoadingEnterpriseInformation;
    }

    return state;
};

const isUpdatingEnterpriseInformationReducer = (state = initialState.isUpdatingEnterpriseInformation, action) => {
    if (action.type === actions.SET_IS_UPDATING_ENTERPRISE_INFORMATION) {
        return action.payload;
    }

    return state;
};

export const additionalCompanyDataReducer = combineReducers({
    enterpriseInformation: enterpriseInformationReducer,
    isLoadingEnterpriseInformation: isLoadingEnterpriseInformationReducer,
    isUpdatingEnterpriseInformation: isUpdatingEnterpriseInformationReducer,
});
