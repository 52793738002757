import SearchIcon from '@mui/icons-material/Search';
import {TextField} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';
import {emptyFunction} from '../../../../util/constants';

const CategoriesSearch = ({onSetValue, setIsShowAllCategories, text}) => {
    const {t} = useTranslation(LocalesConstants.Bank);

    return (
        <TextField
            defaultValue={(text && typeof text === 'string') ? text : undefined}
            fullWidth
            autoFocus
            label={t('categorisation.search')}
            InputProps={{
                startAdornment: <SearchIcon />,
            }}
            onKeyUp={event => {
                onSetValue(event.target.value);
                setIsShowAllCategories(event.target.value);
            }}
        />
    );
};

CategoriesSearch.propTypes = {
    onSetValue: PropTypes.func,
    setIsShowAllCategories: PropTypes.func,
    text: PropTypes.string,
};

CategoriesSearch.defaultProps = {
    text: '',
    onSetValue: emptyFunction,
    setIsShowAllCategories: emptyFunction,
};

export default CategoriesSearch;
