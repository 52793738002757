import {faFileSignature} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Typography} from '@mui/joy';
import {ThemeProvider} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import JoyUIThemeProvider from '../../../../../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import useMediaQuery from '../../../../../../components/joy-ui/mui-legacy/useMediaQuery';
import {theme} from '../../../../../../lib/theme';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import DownloadAllAsZipButton from '../DownloadAllAsZipButton';

const AccountStatementsDataTableHeader = ({searchField}) => {
    const {t} = useTranslation(LocalesConstants.BankRibAndDocuments);
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <JoyUIThemeProvider>
            <Box
                component="div"
                sx={{
                    display: 'flex',
                    flexDirection: {
                        mobile: 'column',
                        desktop: 'row',
                    },
                    alignItems: {
                        mobile: 'flex-start',
                        desktop: 'center',
                    },
                    justifyContent: 'space-between',
                    backgroundColor: 'common.black',
                    px: 2,
                    py: 1,
                    ...(isMobileSize && {
                        gap: 2,
                        p: 2,
                    }),
                }}
            >
                <Box
                    component="div"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                    }}
                >
                    <Typography textColor="common.white">
                        <FontAwesomeIcon icon={faFileSignature} />
                    </Typography>
                    <Typography
                        level="title-md"
                        textColor="common.white"
                    >
                        {t('accountStatementsTable.tableTitle')}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        flexDirection: 'row',
                        ...(isMobileSize && {
                            flexDirection: 'column',
                            width: '100%',
                        }),
                    }}
                >
                    {/* Disabled for now */}
                    {false && (
                        <Box width={isMobileSize ? '100%' : '300px'} sx={{'.MuiOutlinedInput-notchedOutline': {border: 'none !important'}}}>
                            <ThemeProvider theme={theme}>{searchField}</ThemeProvider>
                        </Box>
                    )}
                    <DownloadAllAsZipButton />
                </Box>
            </Box>
        </JoyUIThemeProvider>
    );
};

AccountStatementsDataTableHeader.propTypes = {
    searchField: PropTypes.node.isRequired,
};

export default AccountStatementsDataTableHeader;
