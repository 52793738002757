import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MissionContractDocumentStatus} from './modules/client-side/utils/constants';
import CraSend from './modules/cra/components/CraSend';
import CraSigning from './modules/cra/components/CraSigning';
import CreateCra from './modules/cra/components/CreateCra';
import {MissionsCraActions} from './modules/cra/store/missions-cra.action';
import {MissionsCraSelector} from './modules/cra/store/missions-cra.selector';
import {MissionsActions} from './store/missions.actions';
import {MissionsSelector} from './store/missions.selector';
import {MISSION_CRA_SCREENS} from './utils/constants';
import * as i18next from '../../lib/i18next';
import {selectRouterLocation} from '../../lib/router/connected-router-saga';
import {LocalesConstants} from '../../utils/locales-constants';
import {LoggedInUserSelectors} from '../user/modules/logged-in-user';

i18next.importLocaleBundle(LocalesConstants.Missions);

const MissionsCra = () => {
    const dispatch = useDispatch();
    const screen = useSelector(MissionsCraSelector.selectScreen);
    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const mission = useSelector(MissionsSelector.selectMission);
    const location = useSelector(selectRouterLocation);
    const documents = useSelector(MissionsCraSelector.selectDocuments);

    const [document, setDocument] = useState(null);

    useEffect(() => {
        if (location?.query?.missionId) {
            dispatch(MissionsActions.getMission(location.query.missionId));
        }
    }, [location?.query, dispatch]);

    useEffect(() => {
        // After mission is pulled take all the documents that might exist
        if (mission?.id && screen !== MISSION_CRA_SCREENS.SUCCESS_SCREEN) {
            dispatch(MissionsCraActions.getCraDocuments({
                missionId: mission?.id,
                userId: loggedInUser?.id,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mission?.id, screen]);

    useEffect(() => {
        // If param documentId is passed in url use that document, otherwise last created
        let lastDoc;
        if (location?.query?.documentId) {
            lastDoc = documents.find(document => document?.id === location?.query?.documentId);
        } else {
            lastDoc = documents[documents?.length - 1];
        }

        // If document is generated, but unsigned, go to document signing page
        if (lastDoc?.status === MissionContractDocumentStatus.Generated
            && (screen !== MISSION_CRA_SCREENS.RE_CREATE_FORM
                && screen !== MISSION_CRA_SCREENS.SUCCESS_SCREEN)) {
            dispatch(MissionsCraActions.getSigningUrl(lastDoc));
        }

        setDocument(lastDoc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documents]);

    if (screen === MISSION_CRA_SCREENS.DOCUMENT_SIGNING) {
        return (
            <CraSigning />
        );
    }

    if (screen === MISSION_CRA_SCREENS.SUCCESS_SCREEN) {
        return (
            <CraSend />
        );
    }

    return (
        <CreateCra
            document={document}
        />
    );
};

export default MissionsCra;
