import {FormLabel, Grid, TextField} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller} from 'react-hook-form';
import {useSelector} from 'react-redux';
import Grow from '../../../../../../components/joy-ui/mui-legacy/Grow';
import {BE_DATE_FORMAT} from '../../../../../../utils/constants';
import {AnimationsSelectors} from '../../../../../animations/store/animations.selector';
import {FORM_ANIMATION_DELAY} from '../../../../../animations/utils/constants';
import {
    usePersonalInformationFormStyles,
} from '../../../../../freelancer/modules/company-setup/components/PersonalInformationForm/styles';

const PersonalInformationDoB = ({t, control, errors}) => {
    const styles = usePersonalInformationFormStyles();
    const isSubAnimationActive = useSelector(AnimationsSelectors.selectIsSubAnimationActive);

    const maxDate = moment('01/01/2100', BE_DATE_FORMAT);
    const minDate = moment('01/01/1900', BE_DATE_FORMAT);

    return (
        <>
            <FormLabel component="legend" className={styles.formLabel}>
                {t('dateOfBirth')}
            </FormLabel>

            <Grow
                in={isSubAnimationActive}
                timeout={FORM_ANIMATION_DELAY * 2}
            >
                <Grid container spacing={4}>
                    <Grid item xs={12} md={7}>
                        <Controller
                            name="dateOfBirth"
                            control={control}
                            render={({field}) => {
                                return (
                                    <DatePicker
                                        {...field}
                                        label={t('dateOfBirth')}
                                        invalidDateMessage={t('form:validation.notDate')}
                                        error={!!errors.dateOfBirth}
                                        helperText={errors.dateOfBirth?.message}
                                        openTo="year"
                                        format={moment.localeData().longDateFormat('L')}
                                        views={['year', 'month', 'day']}
                                        inputVariant="filled"
                                        minDateMessage={t('form:validation.minDate', {date: minDate.format('L')})}
                                        maxDateMessage={t('form:validation.maxDate', {date: maxDate.format('L')})}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="filled"
                                                required={true}
                                            />
                                        )}
                                    />
                                );
                            }}
                            required
                            fullWidth
                            disableFuture
                        />
                    </Grid>
                </Grid>
            </Grow>
        </>
    );
};

PersonalInformationDoB.propTypes = {
    t: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

export default PersonalInformationDoB;
