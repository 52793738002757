import GroupsIcon from '@mui/icons-material/Groups';
import {Box, Button, Paper, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link, generatePath} from 'react-router-dom';
import smileyEmoji from '../../../../assets/png/smiley-face-emoji.png';
import {importLocaleBundle} from '../../../../lib/i18next';
import {RoutePaths} from '../../../../lib/router/route-paths';
import {LocalesConstants} from '../../../../utils/locales-constants';

importLocaleBundle(LocalesConstants.Opportunity);

export const SubmitOpportunitySuccess = () => {
    const {t} = useTranslation(LocalesConstants.Opportunity);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Box sx={{p: {xs: 2, md: 0}}}>
            <Typography
                variant="h4"
                sx={{mb: {
                    xs: 1,
                    md: 2,
                }}}
            >
                <Box component="span" sx={{mr: 1}}>
                    {t('submitOpportunity.success.header')}
                </Box>
            </Typography>

            <Paper
                sx={
                    isMobileSize
                        ? {px: 4, pb: 6, pt: 2}
                        : {pb: 9, pt: 6, borderRadius: 2}
                }
                elevation={1}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
                >
                    <Box sx={{mb: isMobileSize ? 0 : 0}}>
                        <GroupsIcon
                            sx={{
                                color: 'v2.blueGray.200',
                                lineHeight: isMobileSize ? '116px' : '192px',
                                fontSize: isMobileSize ? '116px' : '192px',
                            }}
                        />
                    </Box>
                    <Box sx={{
                        mb: 2,
                        maxWidth: '700px',
                    }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 700,
                                fontSize: theme => theme.typography.pxToRem(isMobileSize ? 34 : 48),
                                lineHeight: theme => theme.typography.pxToRem(isMobileSize ? 40 : 58),
                            }}
                        >
                            {t('submitOpportunity.success.main')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: 700,
                                fontSize: theme => theme.typography.pxToRem(21),
                                color: 'v2.light.text.disabled',
                                maxWidth: '700px',
                            }}
                        >
                            {t('submitOpportunity.success.subText')}
                            <img
                                style={{
                                    width: '22px',
                                    marginLeft: '5px',
                                    position: 'relative',
                                    top: '2px',
                                }}
                                src={smileyEmoji}
                                alt="smiley emoji"
                            />
                        </Typography>
                    </Box>
                </Box>
            </Paper>
            <Box sx={{mt: 3, display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                    fullWidth={isMobileSize}
                    component={Link}
                    to={generatePath(RoutePaths.CLUSTER)}
                    variant="contained"
                    color="error"
                >
                    {t('submitOpportunity.success.close')}
                </Button>
            </Box>
        </Box>
    );
};
