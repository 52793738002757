import SearchIcon from '@mui/icons-material/Search';
import {Autocomplete, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {OpportunitiesActions} from '../../store/opportunity.action';
import {OpportunitySelector} from '../../store/opportunity.selector';

export const FreelancerSearch = ({onChange, usedList, bidCreatorId}) => {
    const dispatch = useDispatch();

    const {t} = useTranslation(LocalesConstants.Opportunity);

    const selectedInput = useSelector(OpportunitySelector.getSearch);

    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if (inputValue.length > 1) {
                dispatch(OpportunitiesActions.searchFreelancer(inputValue, 20));
            }
        }, 250);

        return () => {
            clearTimeout(debounceTimer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);

    useEffect(() => {
        const nonUsed = selectedInput.filter(
            searchItem => !usedList.find(
                listItem => listItem?.freelancerId === searchItem.id,
            )
            && searchItem.id !== bidCreatorId,
        );
        if (nonUsed && nonUsed.length > 0) {
            setOptions(nonUsed);
        } else if (inputValue.length > 1) {
            dispatch(OpportunitiesActions.searchFreelancer(inputValue, 100));
        }
        // eslint-disable-next-line
    }, [selectedInput]);

    const onClose = () => {
        setOpen(false);
        setInputValue('');
    };

    return (
        <Autocomplete
            sx={{
                width: 300,
                background: 'white',
                pr: 0,
            }}
            open={open}
            openOnFocus={true}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={onClose}
            value={inputValue || null}
            getOptionLabel={option => {
                if (option.first_name && option.last_name) {
                    return `${option.first_name} ${option.last_name} (${option.email})`;
                }

                return option;
            }}
            options={options}
            renderInput={params => {
                return (
                    <TextField
                        sx={{pr: '0 !important'}}
                        fullWidth
                        label={t('opportunityBids.search')}
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <SearchIcon sx={{
                                    color: 'v2.light.action.active',
                                    transform: 'scale(-1, 1)',
                                }}
                                />
                            ),
                        }}
                    />
                );
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, option) => {
                onChange(option.id);
                onClose(true);
            }}
            handleHomeEndKeys
            clearOnBlur
            disablePortal
            selectOnFocus
            onInputChange={(event, value) => {
                setInputValue(value);
            }}
        />
    );
};

FreelancerSearch.propTypes = {
    onChange: PropTypes.func.isRequired,
    usedList: PropTypes.array,
    bidCreatorId: PropTypes.string.isRequired,
};

FreelancerSearch.defaultProps = {
    usedList: [],
};
