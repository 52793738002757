import {safeTransformData} from '../../../../../app/api/axios';
import {coreApiInstance} from '../../../../../lib/api-providers/core-api-instance';

// const MissionsCra = function (dto) {
//     this.id = dto.id;
// };

const generateMissionContract = ({userId, missionId, dates, period}) => {
    return coreApiInstance({
        method: 'POST',
        data: {
            period,
            'activityInfo': {
                'selectedDates': dates,
            },
        },
        url: `/api/v2/users/${userId}/missions/${missionId}/generate-cra-document`,
    }).then(response => safeTransformData(response));
};

const reGenerateMissionContract = ({userId, missionId, period}) => {
    return coreApiInstance({
        method: 'POST',
        data: {
            period,
        },
        url: `/api/v2/users/${userId}/missions/${missionId}/regenerate-cra-document`,
    }).then(response => safeTransformData(response));
};

const patchMissionContract = ({userId, missionId, dates, documentId}) => {
    return coreApiInstance({
        method: 'PATCH',
        data: {
            documentId,
            'activityInfo': {
                'selectedDates': dates,
            },
        },
        url: `/api/v2/users/${userId}/missions/${missionId}/edit-cra-document`,
    }).then(response => safeTransformData(response));
};

const getMissionContractDocuments = ({userId, missionId}) => {
    return coreApiInstance({
        method: 'GET',
        url: `/api/v2/users/${userId}/missions/${missionId}/cra-documents`,
    }).then(response => safeTransformData(response));
};

const sendMissionContract = ({userId, missionId, documentId}) => {
    return coreApiInstance({
        method: 'POST',
        data: {
            documentId,
        },
        url: `/api/v2/users/${userId}/missions/${missionId}/send-cra-document`,
    }).then(response => safeTransformData(response));
};

const signMissionContract = ({userId, missionId, documentId}) => {
    return coreApiInstance({
        method: 'POST',
        data: {
            documentId,
        },
        url: `/api/v2/users/${userId}/missions/${missionId}/sign-cra-document`,
    }).then(response => safeTransformData(response));
};

export const MissionsCraApi = {
    generateMissionContract,
    reGenerateMissionContract,
    patchMissionContract,
    getMissionContractDocuments,
    sendMissionContract,
    signMissionContract,
};
