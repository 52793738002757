import {UmbrellaCompanyActionTypes} from './umbrella-company.action-type';

const getUmbrellaCompanyDocument = (
    userId,
    documentId,
    isDownload = false,
    isView = false,
) => ({
    type: UmbrellaCompanyActionTypes.GET_COMPANY_DOCUMENT,
    payload: {
        userId,
        documentId,
        isDownload,
        isView,
    },
});

const getUmbrellaCompanyDocuments = (userId, documentContext) => ({
    type: UmbrellaCompanyActionTypes.GET_COMPANY_DOCUMENTS,
    payload: {
        userId,
        documentContext,
    },
});

export const UmbrellaCompanyActions = {
    getUmbrellaCompanyDocument,
    getUmbrellaCompanyDocuments,
};
