import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import SearchIcon from '@mui/icons-material/Search';
import {Box, CircularProgress, Divider, Paper, Typography} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import CategoriesSearch from './CategoriesSearch';
import PopularCategoriesSection from './PopularCategoriesSection';
import EmptySearchSvg from '../../../../../../../../assets/svg/bank/empty-search.svg';
import {useDynamicIcon} from '../../../../../../../../hooks/useDynamicIcon';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';
import {createUnaccentedString} from '../../../../../../../../utils/string';
import {LoadingSelectors, LoadingTypes} from '../../../../../../../loading';
import {SettingsSelectors} from '../../../../../../../settings/store/settings.selectors';
import {TransactionsSelector} from '../../../../store/transactions.selector';
import {COLORS_PALETTE, TransactionSide} from '../../../../util/constants';

const TransactionSourcesList = ({
    isMobileSize,
    onClose,
    selected,
    setIsShowAllCategories,
    setSelected,
    text,
}) => {
    const {t} = useTranslation(LocalesConstants.Bank);

    const [filteredSources, setFilteredSources] = useState([]);

    const sourcesList = useSelector(TransactionsSelector.selectSources);

    const isLoading = useSelector(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.BANK_SOURCES,
    ));

    const userSettings = useSelector(SettingsSelectors.selectSettings);
    const language = userSettings?.language ?? 'fr';

    useEffect(() => {
        if (sourcesList && sourcesList.length > 0) {
            setFilteredSources(sourcesList);
        }
    }, [sourcesList]);

    const filterSources = search => {
        const upperCaseSearch = createUnaccentedString(search.trim().toUpperCase());

        const filteredList = sourcesList.map(parent => {
            let hasSource = false;
            const sourceOfRevenueGroupItems = parent.sourceOfRevenueGroupItems.map(source => {
                const hasFoundKeyword = source.uppercase[language]
                    .keywords.find(keyword => keyword.includes(upperCaseSearch))
                    || source.uppercase[language].categoryName.includes(upperCaseSearch);

                if (hasFoundKeyword) {
                    hasSource = true;
                    return source;
                }

                return null;
            }).filter(Boolean);

            return hasSource
                ? {...parent, sourceOfRevenueGroupItems} : {
                    sourceOfRevenueGroupKey: parent?.sourceOfRevenueGroupKey,
                    sourceOfRevenueGroupName: parent?.sourceOfRevenueGroupName,
                };
        });

        setFilteredSources(filteredList);
    };

    const onSelect = source => {
        setSelected(source);
    };

    if (isMobileSize) {
        return (
            <Drawer
                anchor="bottom"
                open={true}
                onClose={onClose}
                sx={{
                    '.MuiDrawer-paperAnchorBottom': {
                        borderTopRightRadius: '32px',
                        borderTopLeftRadius: '32px',
                    },
                }}
            >
                <Box sx={{
                    minHeight: '300px',
                    borderTopRightRadius: '32px',
                    borderTopLeftRadius: '32px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                >
                    <Paper
                        elevation={1}
                        sx={{
                            height: !isMobileSize ? '100vh' : '100%',
                            width: isMobileSize ? '100%' : '400px',
                        }}
                    >
                        <Box sx={{display: 'flex', flexDirection: 'column', px: 3}}>
                            <Typography sx={{
                                mt: isMobileSize ? 2 : 7,
                                textAlign: 'center',
                                fontSize: theme => theme.typography.pxToRem(21),
                                fontWeight: 700,
                            }}
                            >
                                {t('translationDetails.validateCategory')}
                            </Typography>
                            <Box sx={{mt: 2}}>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    label={t('translationDetails.searchCategory')}
                                    InputProps={{
                                        startAdornment: <SearchIcon />,
                                    }}
                                    onKeyUp={event => {
                                        filterSources(event.target.value);
                                    }}
                                />
                            </Box>

                            <SourcesList
                                isLoading={isLoading}
                                filtered={filteredSources}
                                selected={selected}
                                onSelect={onSelect}
                                language={language}
                                sourcesList={sourcesList}
                            />
                        </Box>
                    </Paper>
                </Box>
            </Drawer>
        );
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 3}}>
            <CategoriesSearch
                onSetValue={filterSources}
                text={text}
                setIsShowAllCategories={setIsShowAllCategories}
            />

            <PopularCategoriesSection
                onCategorySelect={onSelect}
                side={TransactionSide.CREDIT}
                isSubscribed={false}
            />

            <SourcesList
                isLoading={isLoading}
                filtered={filteredSources}
                selected={selected}
                onSelect={onSelect}
                language={language}
                sourcesList={sourcesList}
            />
        </Box>
    );
};

TransactionSourcesList.propTypes = {
    isMobileSize: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    setIsShowAllCategories: PropTypes.func.isRequired,
    selected: PropTypes.string,
    setSelected: PropTypes.func.isRequired,
    text: PropTypes.string,
};

TransactionSourcesList.defaultProps = {
    selected: '',
    text: '',
};

const SourcesList = ({
    isLoading,
    filtered,
    selected,
    onSelect,
    language,
}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const {t} = useTranslation(LocalesConstants.Bank);

    let hasFiltered = false;
    filtered.forEach(parent => {
        if (parent?.sourceOfRevenueGroupItems?.length > 0) {
            hasFiltered = true;
        }
    });


    if (!hasFiltered) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '60vh',
                width: '100%',
            }}
            >
                <img src={EmptySearchSvg} alt="empty search" />
                <Typography sx={{
                    maxWidth: '480px',
                    textAlign: 'center',
                    color: 'v2.light.text.disabled',
                }}
                >
                    {t('categorisation.noCategories')}
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={isMobileSize ? {
            display: 'flex',
            flexDirection: 'column',
            pt: 1,
        } : {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 2,
            pt: 2,
        }}
        >
            {isLoading && <CircularProgress />}
            {!isLoading
                && filtered.map((parent, index) => {
                    if (!parent?.sourceOfRevenueGroupItems?.length) {
                        return (
                            <Box
                                sx={{
                                    'flex': '1',
                                }}
                            />
                        );
                    }

                    return (
                        <Accordion
                            defaultExpanded={true}
                            key={parent?.sourceOfRevenueGroupKey}
                            sx={{
                                'borderRadius': '32px !important',
                                'background': 'white',
                                'boxShadow': 'none',
                                '&:before': {
                                    display: 'none',
                                },
                                'flex': '1',
                            }}
                            disableGutters={true}
                        >
                            <Box sx={{
                                background: 'white',
                                position: 'sticky',
                                top: 0,
                            }}
                            >
                                <AccordionSummary
                                    sx={{
                                        background: COLORS_PALETTE[parent?.sourceOfRevenueGroupKey],
                                        borderRadius: '32px',
                                        zIndex: `${index + 2}`,
                                    }}
                                    expandIcon={<KeyboardArrowDownTwoToneIcon />}
                                    aria-controls="panel1a-content"
                                >
                                    <Typography sx={{
                                        fontWeight: 500,
                                        color: 'text_v2.secondary',
                                        top: '20px',
                                    }}
                                    >
                                        {parent?.sourceOfRevenueGroupName?.[language]}
                                    </Typography>
                                </AccordionSummary>
                            </Box>
                            <AccordionDetails sx={{
                                background: 'white',
                                p: 0,
                                mt: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                flexWrap: isMobileSize ? 'nowrap' : 'wrap',
                            }}
                            >
                                {parent?.sourceOfRevenueGroupItems
                                    && parent.sourceOfRevenueGroupItems.map(source => {
                                        return (
                                            <SingleSource
                                                key={source.sourceOfRevenueGroupKey}
                                                source={source}
                                                selected={selected}
                                                onSelect={onSelect}
                                                language={language}
                                            />
                                        );
                                    })}
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
        </Box>
    );
};

SourcesList.propTypes = {
    isLoading: PropTypes.bool,
    filtered: PropTypes.array,
    selected: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
};

SourcesList.defaultProps = {
    filtered: [],
    selected: '',
    isLoading: false,
};

const SingleSource = ({source, selected, onSelect, language}) => {
    const dynamicIcon = useDynamicIcon(source?.faIcon);
    const isSelected = source.sourceOfRevenue === selected;

    return (
        <React.Fragment key={source.sourceOfRevenueGroupKey}>
            <Box
                sx={{
                    py: 2,
                    color: isSelected ? '#fff' : 'text_v2.secondary',
                    backgroundColor: isSelected ? 'primary_v2.main' : 'transparent',
                    borderRadius: '8px',
                    display: 'flex',
                    cursor: 'pointer',
                    px: 1,
                    alignItems: 'center',
                    [`&:hover`]: {
                        backgroundColor: isSelected ? 'primary_v2.main' : 'rgba(25, 118, 210, 0.04)',
                    },
                }}
                onClick={() => {
                    onSelect(source?.sourceOfRevenue);
                }}
            >
                <FontAwesomeIcon icon={dynamicIcon} />
                <Typography
                    sx={{
                        ml: 2,
                        fontWeight: 500,
                        color: isSelected ? '#fff' : 'text_v2.secondary',
                    }}
                >
                    {source[language].categoryName}
                </Typography>
            </Box>
            <Divider />
        </React.Fragment>
    );
};

SingleSource.propTypes = {
    source: PropTypes.object.isRequired,
    selected: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
};

SingleSource.defaultProps = {
    selected: '',
};

export default TransactionSourcesList;
