import {
    faCircleArrowDown,
    faCircleArrowUp,
    faClockRotateLeft,
    faCreditCard,
    faRepeat,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import EventRepeatTwoToneIcon from '@mui/icons-material/EventRepeatTwoTone';
import ShoppingBagTwoToneIcon from '@mui/icons-material/ShoppingBagTwoTone';
import {Box} from '@mui/joy';
import {Box as MuiBox} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {ConditionalTooltip} from '../../../../../../components/tooltip/ConditionalTooltip';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {TransactionsSelector} from '../../store/transactions.selector';
import {PAYMENT_TYPES} from '../../util/constants';
import {AccountBalanceHelperFunctions} from '../../util/functions';

const AccountBalanceIcon = ({
    paymentType,
    category,
    sourceOfRevenue,
    size,
    iconSize,
    iconColor,
    hasBackground,
    boxSx,
    isV3,
}) => {
    const selectCategoriesObject = useSelector(TransactionsSelector.selectCategoriesObject);
    const selectSourcesObject = useSelector(TransactionsSelector.selectSourcesObject);

    const {t} = useTranslation(LocalesConstants.Bank);

    let icon = null;
    let tooltip = null;
    let {color} = AccountBalanceHelperFunctions.getColorAndIconName({
        selectCategoriesObject,
        selectSourcesObject,
        category,
        sourceOfRevenue,
    });

    const iconStyles = {
        color: iconColor,
        ...(iconSize ? {fontSize: iconSize} : {}),
    };

    switch (paymentType) {
        case PAYMENT_TYPES.CREDIT_CARD_PAYMENT:
            icon = isV3 ? faCreditCard : (
                <ShoppingBagTwoToneIcon sx={iconStyles} />
            );
            tooltip = t('paymentType.CREDIT_CARD_PAYMENT');
            color = iconColor || '#FEEBEE';
            break;
        case PAYMENT_TYPES.DIRECT_DEBIT:
            icon = isV3 ? faRepeat : (
                <CurrencyExchangeIcon sx={iconStyles} />
            );
            tooltip = t('paymentType.DIRECT_DEBIT');
            color = (iconColor && isV3) ? iconColor : 'rgba(2, 136, 209, 0.12)';
            break;
        case 'RECURRING':
            icon = isV3 ? faClockRotateLeft : (
                <EventRepeatTwoToneIcon sx={iconStyles} />
            );
            tooltip = ''; // TODO Check
            color = iconColor || 'rgba(2, 136, 209, 0.12)';
            break;
        case PAYMENT_TYPES.TRANSFER_OUT:
            icon = isV3 ? faCircleArrowUp : (
                <ArrowUpwardIcon sx={iconStyles} />
            );
            tooltip = t('paymentType.TRANSFER_OUT');
            color = iconColor || 'rgba(2, 136, 209, 0.12)';
            break;
        case PAYMENT_TYPES.TRANSFER_IN:
            icon = isV3 ? faCircleArrowDown : (
                <ArrowDownwardIcon sx={iconStyles} />
            );
            tooltip = t('paymentType.TRANSFER_IN');
            color = iconColor || '#E0F2F1';
            break;
    }
    // }

    if (isV3) {
        return (
            <Box sx={{
                minWidth: size,
                width: size,
                height: size,
                borderRadius: '50%',
                color,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 'md',
                ml: 2,
                mr: 1,
                ...boxSx,
            }}
            >
                <ConditionalTooltip tooltip={tooltip} isV3>
                    <FontAwesomeIcon
                        icon={icon}
                        fixedWidth
                    />
                </ConditionalTooltip>
            </Box>
        );
    }

    return (
        <MuiBox sx={{
            minWidth: size,
            width: size,
            height: size,
            borderRadius: '50%',
            background: hasBackground ? color : 'transparent',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mx: 2,
            my: 2,
            ...boxSx,
        }}
        >
            <ConditionalTooltip tooltip={tooltip}>
                {icon}
            </ConditionalTooltip>
        </MuiBox>
    );
};

AccountBalanceIcon.propTypes = {
    paymentType: PropTypes.string.isRequired,
    category: PropTypes.arrayOf(PropTypes.string),
    sourceOfRevenue: PropTypes.string,
    size: PropTypes.string,
    iconSize: PropTypes.string,
    hasBackground: PropTypes.bool,
    iconColor: PropTypes.string,
    boxSx: PropTypes.object,
    isV3: PropTypes.bool, // TODO: make true by default and delete the code, related to false value
};

AccountBalanceIcon.defaultProps = {
    category: null,
    sourceOfRevenue: null,
    size: '48px',
    iconSize: '',
    hasBackground: true,
    iconColor: '',
    boxSx: {},
    isV3: false,
};


export default AccountBalanceIcon;
