import {IconButton} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import {useCompanyActionButtonStyles} from './styles';
import ReactRouterButtonLink from '../../app/router/ReactRouterButtonLink';

const ActionButton = React.forwardRef(({children, className, ...rest}, ref) => {
    const styles = useCompanyActionButtonStyles();

    if (rest?.to) {
        return (
            <ReactRouterButtonLink
                ref={ref}
                className={clsx(styles.root, className)}
                ButtonComponent={IconButton}
                {...rest}
            >
                {children}
            </ReactRouterButtonLink>
        );
    }

    return (
        <IconButton ref={ref} className={clsx(styles.root, className)} {...rest} size="large">
            {children}
        </IconButton>
    );
});

ActionButton.propTypes = {
    className: PropTypes.string,
};

ActionButton.defaultProps = {
    className: '',
};

export default ActionButton;
