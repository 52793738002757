import {combineReducers} from 'redux';
import {MissionsActionType} from './missions.action-type';
import {createReducer} from '../../../app/utils/store/create-reducer';
import {missionsCraReducer} from '../modules/cra/store/missions-cra.reducer';
import {missionsSigningReducer} from '../modules/missions-signing/store/missions-signing.reducer';
import {MISSION_CREATE_SCREENS} from '../utils/constants';

export const missionsReducer = combineReducers({
    mission: createReducer(null, MissionsActionType.STORE_MISSION, true),
    missionList: createReducer(null, MissionsActionType.STORE_MISSION_LIST, false),
    details: createReducer(null, MissionsActionType.STORE_MISSION_DETAILS, true),
    missionInProgress: createReducer(
        null,
        MissionsActionType.STORE_MISSION_IN_PROGRESS,
        true,
    ),
    createMissionScreen: createReducer(
        MISSION_CREATE_SCREENS.CREATE_FORM,
        MissionsActionType.STORE_MISSION_SCREEN,
        true,
    ),
    signing: missionsSigningReducer,
    cra: missionsCraReducer,
});
