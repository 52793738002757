import React from 'react';
import {RoutePaths} from '../../../lib/router/route-paths';
import {
    getCategoriesWorker,
    getDetailsWorker,
    getGlobalPopularArticlesWorker,
    getListWorker,
} from '../store/documentation.saga';

// eslint-disable-next-line import/no-restricted-paths
const DocumentationPage = React.lazy(() => import('../components/DocumentationPage'));
const DocumentationCategoryPage = React.lazy(() => import('../components/DocumentationCategoryPage'));
const DocumentationListPage = React.lazy(() => import('../components/DocumentationListPage'));
const DocumentationDetailsPage = React.lazy(() => import('../components/DocumentationDetailsPage'));

export const DocumentationRoutes = [
    {
        path: RoutePaths.DOCUMENTATION,
        element: <DocumentationPage />,
        exact: true,
        locationChangeSideEffects: [
            [getCategoriesWorker],
            [getGlobalPopularArticlesWorker],
        ],
    },
    {
        path: RoutePaths.DOCUMENTATION_CATEGORY,
        element: <DocumentationCategoryPage />,
        exact: true,
        locationChangeSideEffects: [
            [getCategoriesWorker],
            [getGlobalPopularArticlesWorker],
        ],
    },
    {
        path: RoutePaths.DOCUMENTATION_LIST,
        element: <DocumentationListPage />,
        exact: true,
        locationChangeSideEffects: [
            [getListWorker],
            [getGlobalPopularArticlesWorker],
        ],
    },
    {
        path: RoutePaths.DOCUMENTATION_DETAILS,
        element: <DocumentationDetailsPage />,
        exact: true,
        locationChangeSideEffects: [
            [getDetailsWorker],
            [getGlobalPopularArticlesWorker],
        ],
    },
];
