import CheckIcon from '@mui/icons-material/Check';
import {Box, Button} from '@mui/material';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';
import {LoadingSelectors, LoadingTypes} from '../../../../../../../loading';
import {TransactionsActions} from '../../../../store/transactions.action';
import TransactionTva from '../debit/TransactionTva';

const savingArticlesInProgressSelector = LoadingSelectors.createLoadingSelectorByType(LoadingTypes.BANK_ARTICLES);

const EditAndConfirmVAT = ({details, onConfirm}) => {
    const {t} = useTranslation(LocalesConstants.Bank);
    const dispatch = useDispatch();

    const articleSaveInProgress = useSelector(savingArticlesInProgressSelector);

    const handleSaveTransaction = newArticles => {
        if (articleSaveInProgress) {
            return;
        }

        dispatch(TransactionsActions.saveArticles({
            id: details.id,
            articles: newArticles.map(article => {
                const {id, amount, description, mccKey, tva, tvaAmount} = article;
                return {
                    id,
                    amount,
                    description,
                    mccKey,
                    tva,
                    tvaAmount,
                };
            }),
            accountId: details?.bankAccountId,
        }));
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, width: '100%'}}>
            <TransactionTva
                details={details}
                isEditable={details?.articles?.[0]?.editableVat && !articleSaveInProgress}
                saveTransaction={handleSaveTransaction}
                isAmountInNewRow
            />
            <Button
                sx={{
                    display: 'flex',
                    flex: 1,
                    color: 'white',
                }}
                disableRipple={true}
                disableFocusRipple={true}
                variant="contained"
                color="success"
                startIcon={<CheckIcon sx={{color: 'white'}} />}
                onClick={() => onConfirm()}
                disabled={articleSaveInProgress}
            >
                {t('categorisation.vatValidation.validate')}
            </Button>
        </Box>
    );
};

EditAndConfirmVAT.propTypes = {
    details: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,

};

export default EditAndConfirmVAT;
