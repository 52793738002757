import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import ExternalInvoiceForm from './ExternalInvoiceForm';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';
import {DRAG_ITEMS} from '../../utils/constants';
import CreditDnDLayerWrapper from '../credit/CreditDnDLayerWrapper';
import {InvoiceMainSectionTitle, InvoiceResult} from '../credit/CreditInvoiceLayoutSegments';

const ExternalInvoiceLayout = ({
    details,
    handleOpenAllCategories,
    data,
}) => {
    const {t} = useTranslation(LocalesConstants.Bank);

    return (
        <CreditDnDLayerWrapper
            categorySectionTitle={t('categorisation.invoiceMatching.categorySectionTitle')}
            mainSectionTitle={<InvoiceMainSectionTitle />}
            selectedSectionTitle={t('categorisation.invoiceMatching.selectedSectionTitle')}
            MainSection={ExternalInvoiceForm}
            ResultSection={InvoiceResult}
            BottomAlert={null}
            AdditionalSection={null}
            data={{
                details,
                handleOpenAllCategories,
                type: DRAG_ITEMS.INVOICE,
                ...data,
            }}
        />
    );
};

ExternalInvoiceLayout.propTypes = {
    details: PropTypes.object.isRequired,
    handleOpenAllCategories: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
};

export default ExternalInvoiceLayout;
