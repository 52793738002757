import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {Alert, Box, Button, Paper, TextField, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {v4 as uuidv4} from 'uuid';
import AddArticle from './AddArticle';
import Article from './Article';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';
import {hasMismatchArticleAmount} from '../../utils/functions';

const MAX_NUMBER_OF_ARTICLES = 40;

export const articleTemplate = (props = {}) => {
    const article = {
        id: uuidv4(),
        mccKey: null,
        description: '',
        tva: null,
    };

    return {
        ...article,
        ...props,
    };
};

const CategorizaitionAriclesSection = ({
    details,
    onClose,
    saveTransaction,
    setIsShowAllCategories,
    setUsedArticleId,
}) => {
    const {t} = useTranslation(LocalesConstants.Bank);
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const articles = details?.articles;
    const articleCount = articles?.length;

    const isAmountCorrect = !hasMismatchArticleAmount(details?.categorizationWarnings);

    const [removedArticleDraft, setRemovedArticleDraft] = useState(null);

    // If there is a single article we must add another one for this view
    useEffect(() => {
        if (articleCount === 1) {
            const newArticle = {...articles[0]};
            const templateSegments = {
                mccKey: newArticle.mccKey,
                tva: newArticle.tva,
            };

            saveTransaction([
                articles[0],
                articleTemplate(templateSegments),
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createNewArticle = () => {
        if (removedArticleDraft?.length > 0) {
            const draft = [...removedArticleDraft];
            const template = draft.splice(0, 1);
            setRemovedArticleDraft(draft);

            return template[0];
        }

        const newArticle = {...articles[0]};
        const templateSegments = {
            mccKey: newArticle.mccKey,
            tva: newArticle.tva,
        };

        return articleTemplate(templateSegments);
    };

    const onRemoveArticle = index => {
        const newArticles = [...articles];

        const deletedArticles = newArticles.splice(index, 1);

        setRemovedArticleDraft(deletedArticles);

        saveTransaction(newArticles);
    };

    const onArticleNumberChange = num => {
        if (num < 1 || num === articleCount) {
            return;
        }

        if (num === 1) {
            saveTransaction([articles[0]]);
            onClose();
        }

        // Prevent having more than max articles
        if (num > MAX_NUMBER_OF_ARTICLES) {
            num = MAX_NUMBER_OF_ARTICLES;
        }

        if (num > articleCount) {
            // Add additional articles
            const newArticleCount = num - articleCount;

            const newArticleArray = [];
            for (let i = 0; i < newArticleCount; i += 1) {
                newArticleArray.push(createNewArticle());
            }

            const newArticles = [...articles, ...newArticleArray];

            saveTransaction(newArticles);
        } else {
            // Remove extra articles
            const newArticles = [...articles];

            const deleteArticleCount = articleCount - num;

            const deletedArticles = newArticles.splice(articleCount - deleteArticleCount, deleteArticleCount);

            setRemovedArticleDraft(deletedArticles);

            saveTransaction(newArticles);
        }
    };

    const onArticleNumberFieldChange = e => {
        const num = parseInt(e.target.value, 10);

        onArticleNumberChange(num);
    };

    const onChangeArticle = (editedArticle, index) => {
        const newArticles = [...articles];

        newArticles.splice(index, 1, editedArticle);

        saveTransaction(newArticles);
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 36px)',
            pt: 2,
        }}
        >
            <Paper
                elevation={isMobileSize ? 0 : 8}
                sx={{
                    borderRadius: '24px',
                    width: 'calc(100% - 32px)',
                    height: 'calc(100% - 32px)',
                    display: 'flex',
                    flexDirection: 'column',
                    ml: 2,
                    mb: 2,
                    p: isMobileSize ? 1 : 3,
                    pt: 2,
                }}
            >
                <Box sx={{
                    display: 'flex',
                    justifyContent: isMobileSize ? 'center' : 'space-between',
                }}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: isMobileSize ? 'center' : 'inherit',
                        mb: isMobileSize ? 1 : 0,
                    }}
                    >
                        <Typography variant="h5">
                            {t('categorisation.articles.title')}
                        </Typography>

                        <Typography>
                            {t('categorisation.articles.subtitle')}
                        </Typography>
                    </Box>

                    {!isMobileSize && (
                        <ButtonSection
                            onArticleNumberChange={onArticleNumberChange}
                            onArticleNumberFieldChange={onArticleNumberFieldChange}
                            articleCount={articleCount}
                            t={t}
                        />
                    )}
                </Box>

                {!isAmountCorrect && (
                    <Alert
                        sx={{mt: 2}}
                        severity="warning"
                    >
                        {t('categorisation.articles.amountAlert')}
                    </Alert>
                )}

                <Box sx={isMobileSize ? {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                } : {
                    mt: 2,
                    display: 'grid',
                    gridTemplateColumns: articles?.length < 3 ? 'repeat(3, 1fr)' : 'repeat(4, 1fr)',
                    gridTemplateRows: 'auto',
                    gap: 2,
                    height: '100%',
                    overflowY: 'auto',
                }}
                >
                    {articles.map((article, index) => {
                        return (
                            <Article
                                key={article.id}
                                article={article}
                                articleNumber={index + 1}
                                onRemoveArticle={() => {
                                    onRemoveArticle(index);

                                    if (articles?.length === 2) {
                                        onClose();
                                    }
                                }}
                                onChangeArticle={newArticle => {
                                    onChangeArticle(newArticle, index);
                                }}
                                setIsShowAllCategories={() => {
                                    setUsedArticleId(article?.id);
                                    setIsShowAllCategories(true);
                                }}
                            />
                        );
                    })}

                    {articleCount < MAX_NUMBER_OF_ARTICLES && (
                    <AddArticle
                        onClick={() => onArticleNumberChange(articleCount + 1)}
                        t={t}
                    />
                    )}
                </Box>
            </Paper>
        </Box>
    );
};

CategorizaitionAriclesSection.propTypes = {
    details: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    saveTransaction: PropTypes.func.isRequired,
    setIsShowAllCategories: PropTypes.func.isRequired,
    setUsedArticleId: PropTypes.func.isRequired,
};

const ButtonSection = ({
    onArticleNumberChange,
    onArticleNumberFieldChange,
    articleCount,
    t,
}) => {
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
        }}
        >
            <Box sx={{
                pr: 2,
            }}
            >
                <Typography sx={{
                    fontSize: theme => theme.typography.pxToRem(12),
                    fontWeight: 500,
                    color: 'v2.blueGray.300',
                    letterSpacing: '1px',
                    textTransform: 'uppercase',
                }}
                >
                    {t('categorisation.articles.articles')} :
                </Typography>
            </Box>

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                height: '40px !important',
                gap: 0,
            }}
            >
                <Button
                    onClick={() => {
                        onArticleNumberChange(articleCount - 1);
                    }}
                >
                    <RemoveCircleIcon
                        sx={{
                            color: 'v2.light.secondary.main',
                            fontSize: '30px',
                        }}
                    />
                </Button>

                <TextField
                    sx={{
                        'width': '64px',
                        '& input': {
                            'height': '40px',
                            'py': 0,
                            'textAlign': 'center',
                        },
                    }}
                    onChange={onArticleNumberFieldChange}
                    inputMode="numeric"
                    value={articleCount}
                />

                <Button
                    disabled={articleCount === MAX_NUMBER_OF_ARTICLES}
                    onClick={() => {
                        onArticleNumberChange(articleCount + 1);
                    }}
                >
                    <AddCircleIcon sx={{
                        color: articleCount === MAX_NUMBER_OF_ARTICLES
                            ? 'inherit' : 'primary_v2.main',
                        fontSize: '30px',
                    }}
                    />
                </Button>
            </Box>
        </Box>
    );
};

ButtonSection.propTypes = {
    onArticleNumberChange: PropTypes.func.isRequired,
    onArticleNumberFieldChange: PropTypes.func.isRequired,
    articleCount: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
};

export default CategorizaitionAriclesSection;
