import {Box, Chip} from '@mui/joy';
import Typography from '@mui/joy/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {LocalesConstants} from '../../../utils/locales-constants';
import JoyUIThemeProvider from '../joyui-theme-provider/JoyUiThemeProvider';

const StatusSection = ({title, isRequired, children, sx}) => {
    const {t} = useTranslation(LocalesConstants.Common);

    return (
        <JoyUIThemeProvider>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 'xl',
                border: '1px solid var(--joy-palette-neutral-outlinedBorder)',
                overflow: 'hidden',
                ...sx,
            }}
            >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderTopLeftRadius: 'var(--joy-radius-xl)',
                    borderTopRightRadius: 'var(--joy-radius-xl)',
                    backgroundColor: 'common.black',
                    borderBottom: '1px solid var(--joy-palette-neutral-outlinedBorder)',
                    py: 1,
                    px: 2,
                    gap: 1,
                }}
                >
                    <Typography level="h4" textColor="neutral.solidColor">
                        {title}
                    </Typography>
                    <Chip
                        size="md"
                        color={isRequired ? 'primary' : 'neutral'}
                        variant="soft"
                    >
                        {isRequired ? t('status.required') : t('status.optional')}
                    </Chip>
                </Box>
                <Box sx={{
                    backgroundColor: 'background.body',
                    p: 3,
                }}
                >
                    {children}
                </Box>
            </Box>
        </JoyUIThemeProvider>
    );
};

StatusSection.propTypes = {
    title: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    sx: PropTypes.object,
};

StatusSection.defaultProps = {
    isRequired: false,
    sx: {},
};

export default StatusSection;
