import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {currencyToSign, getNumberSegments, numberWithSpaces} from '../../../../../../../../app/utils/app.helpers';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';
import {SettingsSelectors} from '../../../../../../../settings/store/settings.selectors';
import {TransactionsSelector} from '../../../../store/transactions.selector';
import {AccountBalanceHelperFunctions} from '../../../../util/functions';
import Category from '../../../account-balance/account-balance-data-row/AccountBalanceTableDataRowCategory';
import ChipSection from '../../../account-balance/account-balance-data-row/AccountBalanceTableDataRowChip';
import AccountBalanceTableDataRowDate
    from '../../../account-balance/account-balance-data-row/AccountBalanceTableDataRowDate';
import AccountBalanceIcon from '../../../account-balance/AccountBalanceIcon';

const SubscriptionTransaction = ({id}) => {
    const {t} = useTranslation(LocalesConstants.Bank);
    const data = useSelector(TransactionsSelector.createSelectTransactionById(id));

    const userSettings = useSelector(SettingsSelectors.selectSettings);
    const language = userSettings?.language ?? 'fr';

    const selectCategoriesObject = useSelector(TransactionsSelector.selectCategoriesObject);

    if (!data) {
        return null;
    }

    const onOpenClick = () => {
        // They are view only - for now
        // dispatch(TransactionsActions.setCategorizationScreenInfo({
        //     screen: TRANSACTION_CATEGORIZATION_SCREENS.CATEGORY,
        //     transactionId: id,
        // }));
        //
        // dispatch(TransactionsActions.addToCategorisationList({
        //     transactionId: id,
        //     hasSpecialHandlingIfCategorised: true,
        // }));
    };

    const numberSegments = getNumberSegments(numberWithSpaces(data.amount));

    const {
        isRejected,
        isCancelled,
        isNotCategorized,
        isCategorized,
        isAutoCategorized,
    } = AccountBalanceHelperFunctions.getCategorizationState(data);

    return (
        <Box
            onClick={onOpenClick}
            sx={{
                display: 'flex',
                width: '100%',
                cursor: 'default',
            }}
        >
            <Box sx={{
                width: '50px',
            }}
            >
                <AccountBalanceTableDataRowDate
                    date={data.date}
                    isNewDate={true}
                />
            </Box>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 'calc(100% - 50px)',
            }}
            >
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    >
                        <AccountBalanceIcon
                            paymentType={data.type}
                            category={data.category}
                            sourceOfRevenue={data.sourceOfRevenue}
                            hasBackground={false}
                            iconColor="v2.blueGray.400"
                            size="20px"
                        />

                        <Typography sx={{
                            fontWeight: 600,
                            fontSize: '16px',
                            lineHeight: '24px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            color: 'inherit',
                        }}
                        >
                            {AccountBalanceHelperFunctions.getTransactionLabel(data)}
                        </Typography>
                    </Box>

                    <Box>
                        <Box sx={{
                            fontWeight: 600,
                            color: 'lightTextSecondary.main',
                            textAlign: 'right',
                            whiteSpace: 'nowrap',
                            fontSize: theme => theme.typography.pxToRem(12),
                        }}
                        >
                            <Typography
                                component="span"
                                sx={{
                                    fontSize: theme => theme.typography.pxToRem(16),
                                    fontWeight: 600,
                                }}
                            >
                                {numberSegments?.integer}.
                            </Typography>
                            <Typography
                                component="span"
                                sx={{fontSize: theme => theme.typography.pxToRem(12)}}
                            >
                                {numberSegments?.decimal}
                            </Typography>
                            {currencyToSign(data.currency)}
                        </Box>
                    </Box>
                </Box>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
                >
                    <Box sx={{
                        pl: 1.2,
                    }}
                    >
                        <Category
                            side={data.side}
                            category={data.category}
                            sourceOfRevenue={data.sourceOfRevenue}
                            language={language}
                            selectSourcesObject={[]}
                            selectCategoriesObject={selectCategoriesObject}
                            isEditable={false}
                        />
                    </Box>

                    <Box>
                        <ChipSection
                            isNotCategorized={isNotCategorized}
                            isRejected={isRejected}
                            isCancelled={isCancelled}
                            isCategorized={isCategorized}
                            isAutoCategorized={isAutoCategorized}
                            t={t}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

SubscriptionTransaction.propTypes = {
    id: PropTypes.string.isRequired,
};

export default SubscriptionTransaction;
