import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Box, Divider, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Toast} from '../../../../../../../../lib/toast';
import {copyToClipboard} from '../../../../../../../../utils/copy-to-clipboard';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';
import {PAYMENT_TYPES} from '../../../../util/constants';

const types = {
    label: 'label',
    reference: 'reference',
    iban: 'iban',
    ibanCredit: 'ibanCredit',
    beneficiary: 'beneficiary',
    merchantName: 'merchantName',
    creditorName: 'creditorName',
    creditorIban: 'creditorIban',
    name: 'name',
};

const InfoSidebarOther = ({data}) => {
    const {t} = useTranslation(LocalesConstants.Bank);

    const getTitle = type => {
        switch (type) {
            case PAYMENT_TYPES.TRANSFER_IN:
                return t('translationDetails.creditInformation');
            case PAYMENT_TYPES.TRANSFER_OUT:
                return t('translationDetails.transferInformation');
            case PAYMENT_TYPES.DIRECT_DEBIT:
                return t('translationDetails.directDebitInformation');
        }
    };

    return (
        <Box sx={{
            backgroundColor: 'grey.50',
            borderRadius: '16px',
            px: 3,
            py: 2,
            mb: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
        }}
        >
            <Typography sx={{fontWeight: 500}}>
                {getTitle(data?.type)}
            </Typography>

            {data.type === PAYMENT_TYPES.CREDIT_CARD_PAYMENT && (
                <Box>
                    <InfoSidebarBox
                        type={types.merchantName}
                        value={data?.label}
                    />

                    {/* Bussiness needs to descide what should this be */}
                    {/* <InfoSidebarBox */}
                    {/*    type={types.merchantName} */}
                    {/*    value={data?.debtorName} */}
                    {/*    hasDivider */}
                    {/* /> */}
                </Box>
            )}

            {data.type === PAYMENT_TYPES.TRANSFER_OUT && (
                <Box>
                    <InfoSidebarBox
                        type={types.beneficiary}
                        value={data?.transferOrder?.label}
                    />

                    <InfoSidebarBox
                        type={types.iban}
                        // Danilo mentioned paymentMethodId, but Milan says it is not changed to transferOrder object
                        // Now Milan changed his mind, so it is back to paymentMethodId
                        // We changed it yet again back to transferOrder.iban
                        value={data?.transferOrder?.iban}
                        hasDivider
                    />
                </Box>
            )}

            {data.type === PAYMENT_TYPES.TRANSFER_IN && (
                <Box>
                    <InfoSidebarBox
                        type={types.ibanCredit}
                        value={data?.paymentMethodId}
                    />

                    <InfoSidebarBox
                        type={types.name}
                        value={data?.debtorName}
                        hasDivider
                    />

                    <InfoSidebarBox
                        type={types.label}
                        value={data?.label}
                        hasDivider
                    />
                </Box>
            )}

            {data.type === PAYMENT_TYPES.DIRECT_DEBIT && (
                <Box>
                    <InfoSidebarBox
                        type={types.creditorName}
                        value={data?.creditorName}
                    />

                    <InfoSidebarBox
                        type={types.creditorIban}
                        value={data?.creditorDetails?.IBAN}
                        hasDivider
                    />

                    <InfoSidebarBox
                        type={types.label}
                        value={data?.label}
                        hasDivider
                    />

                    <InfoSidebarBox
                        type={types.reference}
                        value={data?.reference}
                        hasDivider
                    />
                </Box>
            )}
        </Box>
    );
};

InfoSidebarOther.propTypes = {
    data: PropTypes.object.isRequired,
};

const InfoSidebarBox = ({type, value, hasDivider}) => {
    const {t} = useTranslation(LocalesConstants.Bank);

    if (!value || typeof value === 'object') {
        return null;
    }

    const onCopy = () => {
        copyToClipboard(value);
        Toast.success('copiedToClipboard');
    };

    return (
        <>
            {hasDivider && <Divider sx={{borderStyle: 'dotted', my: 1}} />}
            <Box sx={{
                'display': 'flex',
                'justifyContent': 'space-between',
                'alignItems': 'center',
                '&:hover': {
                    '> .copy': {
                        visibility: 'visible',
                    },
                },
            }}
            >
                <Box>
                    <Typography sx={{color: 'v2.light.text.disabled', mb: 0.5}}>
                        {t(`categorisation.sidebar.${type}`)}
                    </Typography>
                    <Box sx={{color: 'text_v2.secondary'}}>{value}</Box>
                </Box>
                <Box
                    sx={{
                        visibility: 'hidden',
                        cursor: 'pointer',
                    }}
                    className="copy"
                    onClick={onCopy}
                >
                    <ContentCopyIcon
                        sx={{
                            color: 'v2.blueGray.300',
                        }}
                    />
                </Box>
            </Box>
        </>
    );
};

InfoSidebarBox.propTypes = {
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    hasDivider: PropTypes.bool,
};

InfoSidebarBox.defaultProps = {
    hasDivider: false,
};

export default InfoSidebarOther;
