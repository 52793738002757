import AdapterMoment from '@date-io/moment';
import {
    faCalendarDays,
    faCalendarXmark,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Typography, styled} from '@mui/joy';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {getDaysBetween} from '../../../features/invoicing/util/date';
import {useIsMobileSize} from '../../../hooks/use-is-mobile-size';
import {getFormattedMonthWorkingDays, isWorkingDay} from '../../../utils/holidays';
import {LocalesConstants} from '../../../utils/locales-constants';
import withJoyUI from '../joyui-theme-provider/JoyUiThemePRoviderWrapper';
import {LocalizationProvider, PickersDay, StaticDatePicker} from '../x-date-pickers';

const formatDate = 'YYYY-MM-DD';
const MUI_PICKERS_DAY_CLASS = 'MuiPickersDay-root';

const EmptyBoxComponent = () => {
    return <Box />;
};

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: prop => !['isDaySelected', 'isHalfDaySelected', 'isNonWorking'].includes(prop),
})(({theme, isDraggingDate, isDaySelected, isHalfDaySelected, isNonWorking}) => ({
    'borderRadius': theme.radius.md,
    'fontStyle': 'italic',
    'fontWeight': theme.fontWeight.lg,
    'fontFamily': theme.fontFamily.body,
    'color': theme.palette.text.secondary,
    'fontSize': theme.fontSize.md,
    'border': '1px solid',
    'borderColor': theme.palette.neutral.outlinedBorder,
    ...(isDraggingDate && {
        'backgroundColor': theme.palette.neutral.softBg,
        'borderColor': theme.palette.success.softBg,
    }),
    ...(isDaySelected && {
        'background': theme.palette.success[200],
        'borderColor': theme.palette.success[200],
        '&:hover, &:focus': {
            backgroundColor: theme.palette.success[200],
        },
    }),
    ...(isHalfDaySelected && {
        'borderColor': theme.palette.success.softBg,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.success.softBg,
        },
        'background': 'linear-gradient(to bottom right, #C1EAD4 0%, #C1EAD4 50%, #FFF 50%, #FFF 100%)',
    }),
    ...(isNonWorking && {
        'color': theme.palette.neutral.outlinedDisabledColor,
        'fontWeight': theme.fontWeight.smd,
    }),
    'py': 2,
    'width': '100%',
    'height': '56px',
}));

const isDateInMonth = (date, month) => {
    return moment(month).isSame(moment(date, formatDate), 'month');
};

export const DateSelection = withJoyUI(({
    selectedDates,
    selectedHalfDates,
    setSelectedDates,
    setSelectedHalfDates,
    minDate,
    maxDate,
}) => {
    const {t} = useTranslation(LocalesConstants.Datepickers);
    const isMobileSize = useIsMobileSize();
    const [currentMonth, setCurrentMonth] = useState(moment().startOf('month'));
    const [selectedDnDDates, setSelectedDnDDates] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const formattedMonth = currentMonth.format(formatDate);

    const allMonthWorkingDays = useMemo(() => {
        const month = moment(formattedMonth, formatDate);
        const startOfMonth = month.clone().startOf('month');
        const endOfMonth = month.clone().endOf('month');

        const startDate = minDate && startOfMonth.isBefore(minDate, 'day') ? minDate : startOfMonth;
        const endDate = maxDate && endOfMonth.isAfter(maxDate, 'day') ? maxDate : endOfMonth;

        return getFormattedMonthWorkingDays(startDate, endDate, formatDate);
    }, [formattedMonth, minDate, maxDate]);
    const allMonthWorkingDaysLength = allMonthWorkingDays.length;

    const selectedDatesThisMonth = useMemo(() => selectedDates
        .filter(date => isDateInMonth(date, currentMonth)),
    [currentMonth, selectedDates]);

    const selectedHalfDatesThisMonth = useMemo(() => selectedHalfDates
        .filter(date => isDateInMonth(date, currentMonth)),
    [currentMonth, selectedHalfDates]);

    const selectedDatesThisMonthLength = selectedDatesThisMonth.length;
    const selectedHalfDatesThisMonthLength = selectedHalfDatesThisMonth.length;

    const selectDates = useCallback(() => {
        const newSelectedDates = [...selectedDates];

        selectedDnDDates.forEach(date => {
            const formattedDate = date.format(formatDate);

            const indexDate = selectedDates.indexOf(formattedDate);
            const indexHalfDate = selectedHalfDates.indexOf(formattedDate);

            if (indexDate === -1 && indexHalfDate === -1) {
                newSelectedDates.push(formattedDate);
            }
        });

        setSelectedDates(newSelectedDates.sort());
        setIsDragging(false);
        setSelectedDnDDates([]);
    }, [selectedDates, selectedDnDDates, selectedHalfDates, setSelectedDates]);

    const handleMouseDown = date => {
        setIsDragging(true);
        setStartDate(date);
    };

    const handleMouseEnter = date => {
        if (isDragging) {
            const dates = getDaysBetween(startDate, date);
            const workingDates = [];
            dates.forEach(date => {
                const formattedDate = date.format(formatDate);
                const isWorkingDay = allMonthWorkingDays.includes(formattedDate);

                if (isWorkingDay) {
                    workingDates.push(date);
                }
            });
            setSelectedDnDDates(workingDates);
        }
    };

    const handleMouseUp = () => {
        selectDates();
    };

    const halfDaysIntegerPart = selectedDatesThisMonthLength + Math.floor(selectedHalfDatesThisMonthLength / 2);
    const halfDaysFractionalPart = (selectedHalfDatesThisMonthLength / 2) % 1 ? '½' : '';
    const allSelectedDatesFormatted = halfDaysIntegerPart + halfDaysFractionalPart;

    const getAreAllMonthWorkingDaysSelected = () => {
        if (selectedDatesThisMonthLength < allMonthWorkingDaysLength) {
            return false;
        }

        return allMonthWorkingDays.every(workingDay => selectedDatesThisMonth.includes(workingDay));
    };

    const areAllMonthWorkingDaysSelected = getAreAllMonthWorkingDaysSelected();

    const onClearMonth = () => {
        const newSelectedDates = [];
        const newSelectedHalfDates = [];

        selectedDates.forEach(date => {
            if (!moment(currentMonth).isSame(moment(date, formatDate), 'month')) {
                newSelectedDates.push(date);
            }
        });
        selectedHalfDates.forEach(date => {
            if (!moment(currentMonth).isSame(moment(date, formatDate), 'month')) {
                newSelectedHalfDates.push(date);
            }
        });

        setSelectedDates(newSelectedDates);
        setSelectedHalfDates(newSelectedHalfDates);
    };

    const selectAllMonthWorkingDays = () => {
        const newSelectedDates = [...allMonthWorkingDays];
        const newSelectedHalfDates = [];

        selectedDates.forEach(date => {
            if (!moment(currentMonth).isSame(moment(date, formatDate), 'month')) {
                newSelectedDates.push(date);
            }
        });
        selectedHalfDates.forEach(date => {
            if (!moment(currentMonth).isSame(moment(date, formatDate), 'month')) {
                newSelectedHalfDates.push(date);
            }
        });

        setSelectedDates(newSelectedDates.sort());
        setSelectedHalfDates(newSelectedHalfDates);
    };

    const onChangeValue = value => {
        const formattedDate = value.format(formatDate);

        const indexDate = selectedDates.indexOf(formattedDate);
        const indexHalfDate = selectedHalfDates.indexOf(formattedDate);

        const newSelectedDates = [...selectedDates];
        const newSelectedHalfDates = [...selectedHalfDates];

        if (indexHalfDate > -1) {
            newSelectedHalfDates.splice(indexHalfDate, 1);
        } else if (indexDate > -1) {
            newSelectedDates.splice(indexDate, 1);
            newSelectedHalfDates.push(formattedDate);
        } else {
            newSelectedDates.push(formattedDate);
        }

        setSelectedDates(newSelectedDates.sort());
        setSelectedHalfDates(newSelectedHalfDates.sort());
    };

    const renderDay = (date, _, pickersDayProps) => {
        const formattedDate = date.format(formatDate);

        const isDaySelected = selectedDates.includes(formattedDate);
        const isHalfDaySelected = selectedHalfDates.includes(formattedDate);

        // TODO remove selected in some better way
        pickersDayProps.selected = false;

        const isDraggingDate = selectedDnDDates.length
            ? selectedDnDDates.find(dndDate => dndDate.isSame(date, 'day'))
            : false;

        return (
            <Box
                key={date.format(formatDate)}
                sx={{
                    flexGrow: 1,
                    width: 'calc((100% - 48px) / 7)',
                }}
            >
                <CustomPickersDay
                    {...pickersDayProps}
                    disableMargin
                    isDaySelected={isDaySelected}
                    isHalfDaySelected={isHalfDaySelected}
                    isNonWorking={!isWorkingDay(date)}
                    isDraggingDate={isDraggingDate}
                    onMouseDown={() => {
                        handleMouseDown(date);
                    }}
                    onMouseEnter={() => {
                        handleMouseEnter(date);
                    }}
                    onMouseUp={handleMouseUp}
                />
            </Box>
        );
    };

    useEffect(() => {
        const handleMouseUp = e => {
            if (isDragging && !e?.target.classList.contains(MUI_PICKERS_DAY_CLASS)) {
                selectDates();
            }
        };

        document.addEventListener('mouseup', handleMouseUp);

        return () => document.removeEventListener('mouseup', handleMouseUp);
    }, [isDragging, selectDates]);

    return (
        <Box
            component="div"
            sx={{
                '& .MuiDayPicker-slideTransition': {
                    minHeight: '386px',
                },
                '& .JoyDatePickerWeek': {
                    justifyContent: 'space-around',
                    display: 'flex',
                    gap: 1,
                    mb: 1,
                },
                '& .JoyDatePickerWeekDays': {
                    justifyContent: 'space-around',
                },
                '& .JoyDatePickerWeekDay': {
                    fontSize: 'md',
                },
                '& .JoyDatePickerViewRoot': {
                    maxHeight: 'none',
                    width: '100%',
                },
                '& .JoyDatePickerCalendarRoot': {
                    maxHeight: 'none',
                    width: '100%',
                },
                '& .MuiPickersCalendarHeader-root': {
                    my: 3,
                    px: 0,
                },
            }}
        >
            <LocalizationProvider dateLibInstance={moment} dateAdapter={AdapterMoment}>
                <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    views={['day']}
                    openTo="day"
                    minDate={minDate}
                    maxDate={maxDate}
                    value={currentMonth}
                    renderDay={renderDay}
                    headerAdditionalContent={(
                        <>
                            <Typography
                                level="h4"
                                textColor="text.primary"
                                fontWeight="xl"
                            >
                                <Trans
                                    t={t}
                                    i18nKey={selectedDatesThisMonthLength + selectedHalfDatesThisMonthLength === 1
                                        ? 'day'
                                        : 'days'
                                    }
                                    values={{
                                        days: allSelectedDatesFormatted,
                                        allMonthWorkingDays: allMonthWorkingDaysLength,
                                    }}
                                    components={{
                                        mark: (
                                            <Box component="span" sx={{color: 'text.tertiary'}} />
                                        ),
                                    }}
                                />
                            </Typography>
                            {areAllMonthWorkingDaysSelected ? (
                                <Button
                                    size="sm"
                                    color="neutral"
                                    variant="soft"
                                    fullWidth={isMobileSize}
                                    startDecorator={(
                                        <FontAwesomeIcon icon={faCalendarXmark} />
                                    )}
                                    onClick={onClearMonth}
                                >
                                    {t('clear')}
                                </Button>
                            ) : (
                                <Button
                                    size="sm"
                                    color="neutral"
                                    variant="soft"
                                    fullWidth={isMobileSize}
                                    startDecorator={(
                                        <FontAwesomeIcon icon={faCalendarDays} />
                                    )}
                                    onClick={selectAllMonthWorkingDays}
                                >
                                    {t('selectWholeMonth')}
                                </Button>
                            )}
                        </>
                    )}
                    onChange={newValue => {
                        onChangeValue(newValue);
                        return newValue;
                    }}
                    onMonthChange={newValue => {
                        setCurrentMonth(newValue.clone().startOf('month'));
                        return newValue;
                    }}
                    disableOpenPicker
                    renderInput={EmptyBoxComponent}
                    disableHighlightToday
                    dayOfWeekFormatter={day => day.charAt(0).toUpperCase() + day.charAt(1).toLowerCase()}
                    isInRange
                />
            </LocalizationProvider>
        </Box>
    );
});

DateSelection.propTypes = {
    selectedDates: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedHalfDates: PropTypes.arrayOf(PropTypes.string).isRequired,
    setSelectedDates: PropTypes.func.isRequired,
    setSelectedHalfDates: PropTypes.func.isRequired,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
};

DateSelection.defaultProps = {
    minDate: undefined,
    maxDate: undefined,
};
