import {yupResolver} from '@hookform/resolvers/yup';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {LoadingButton} from '@mui/lab';
import {Box, Divider, Paper} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import * as Yup from 'yup';
import PersonalInformatioinName from './PersonalInformatioinName';
import PersonalInformationDoB from './PersonalInformationDoB';
import PersonalInformationMedical from './PersonalInformationMedical';
import PersonalInformationMobile from './PersonalInformationMobile';
import PersonalInformationMutual from './PersonalInformationMutual';
import PersonalInformationPlace from './PersonalInformationPlace';
import PersonalInformationSsn from './PersonalInformationSsn';
import {getDefaultLocale} from '../../../../../../app/utils/app.helpers';
import {PageHeader} from '../../../../../../components/page-header/PageHeader';
import {useCommonValidations} from '../../../../../../lib/yup/common-validatons';
import {BE_DATE_FORMAT} from '../../../../../../utils/constants';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {REGIONAL_DEPARTMENTS} from '../../../../../../utils/regional-departments';
import {
    getPersonalInformationData,
} from '../../../../../company/modules/setup-company/store/personal-information/personal-information.selectors';
import {TITLES} from '../../../../../company/modules/setup-company/utils/constants';
import {
    usePersonalInformationFormStyles,
} from '../../../../../freelancer/modules/company-setup/components/PersonalInformationForm/styles';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {SettingsSelectors} from '../../../../../settings/store/settings.selectors';
import {LoggedInUserSelectors} from '../../../../../user/modules/logged-in-user';

const PersonalInformationForm = ({onSubmitFn}) => {
    const styles = usePersonalInformationFormStyles();
    const {t} = useTranslation(LocalesConstants.Setup);

    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const personalInformationData = useSelector(getPersonalInformationData);
    const settings = useSelector(SettingsSelectors.selectSettings);
    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.GENERIC_CRUD_LOADER),
    );

    const userLanguage = settings?.language || getDefaultLocale();

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const defaultValues = {
        title: personalInformationData?.title || '',
        firstName: personalInformationData?.firstName || loggedInUser?.firstName || '',
        lastName: personalInformationData?.lastName || loggedInUser?.lastName || '',
        dateOfBirth: personalInformationData?.dateOfBirth
            ? moment(personalInformationData.dateOfBirth, BE_DATE_FORMAT)
            : null,
        lastMedicalCheckupDate: personalInformationData?.lastMedicalCheckupDate
            ? moment(personalInformationData.lastMedicalCheckupDate, BE_DATE_FORMAT)
            : null,
        countryOfBirth: personalInformationData?.countryOfBirth || '',
        placeOfBirth: personalInformationData?.placeOfBirth || '',
        postalCode: personalInformationData?.postalCode || '',
        regionalDepartmentOfBirth: personalInformationData?.regionalDepartmentOfBirth || '',
        nationality: personalInformationData?.nationality || '',
        socialSecurityNumber: personalInformationData?.socialSecurityNumber || '',
        doNotHaveMedical: false,
    };

    const {dateOfBirthValidation} = useCommonValidations();

    const validationSchema = Yup.object().shape({
        title: Yup.string().oneOf(Object.values(TITLES)).required(t('validations.isRequired', {what: t('title')})),
        firstName: Yup.string().min(2, t('validations.minMessage')).max(26, t('validations.maxMessage')).required(t('validations.isRequired', {what: t('firstName')})),
        lastName: Yup.string().min(2, t('validations.minMessage')).max(26, t('validations.maxMessage')).required(t('validations.isRequired', {what: t('lastName')})),
        dateOfBirth: dateOfBirthValidation('dateOfBirth'),
        countryOfBirth: Yup.string().max(32, t('validations.maxMessage')).required(t('validations.isRequired', {what: t('countryOfBirth')})),
        placeOfBirth: Yup.string().max(128, t('validations.maxMessage')).required(t('validations.isRequired', {what: t('placeOfBirth')})),
        regionalDepartmentOfBirth: Yup.string().min(2, t('validations.minMessage')).max(3, t('validations.maxMessage')).required(t('validations.isRequired', {what: t('regionalDepartmentOfBirth')})),
        nationality: Yup.string().min(2, t('validations.minMessage')).max(16, t('validations.maxMessage')).required(t('validations.isRequired', {what: t('nationality')})),
        postalCode: Yup.string().length(5, t('validations.lengthZip')).required(t('validations.isRequired', {what: t('postalCode')})),
        lastMedicalCheckupDate: Yup.date().when('doNotHaveMedical', {
            is: false,
            then: dateOfBirthValidation('lastMedicalCheckupDate'),
            otherwise: Yup.date().nullable(),
        }),
        socialSecurityNumber: Yup
            .string()
            .matches(/^\d+$/, t('validations.invalidSocialSecurity'))
            .length(15, t('validations.lengthSocialSecurity'))
            .required(t('validations.isRequired', {what: t('socialSecurityNumber')})),
        subscribedToHiwayInsurance: Yup.boolean()
            // Currently not showing these messages, nor having design, so the can stay english only
            .required('This field is required.')
            .typeError('Must be a boolean.'),
        personalInsuranceDocument: Yup.mixed().when('subscribedToHiwayInsurance', {
            is: false, // When subscribedToHiwayInsurance is false
            then: Yup.mixed()
                // Currently not showing these messages, nor having design, so the can stay english only
                .required('Personal insurance document is required.'),
            otherwise: Yup.mixed().notRequired(), // Not required if false
        }),
    });

    const {
        handleSubmit: createSubmitHandler,
        control,
        formState: {errors, isValid},
        watch,
        setValue,
        reset,
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    const countryOfBirth = watch('countryOfBirth');
    const postalCode = watch('postalCode');
    const hasNoMedicalCheckup = watch('doNotHaveMedical');
    const subscribedToHiwayInsurance = watch('subscribedToHiwayInsurance');
    const personalInsuranceDocument = watch('personalInsuranceDocument');

    const [isRegionalDepartmentOfBirthDisabled, setIsRegionalDepartmentOfBirthDisabled] = useState(false);

    const formRef = useRef(null);

    const handleSubmit = useCallback(data => {
        const payload = {
            ...data,
            dateOfBirth: moment(data.dateOfBirth).format(BE_DATE_FORMAT),
            personalInsuranceDocument: data?.personalInsuranceDocument?.[0] ? data.personalInsuranceDocument[0] : null,
            lastMedicalCheckupDate: data?.doNotHaveMedical
                ? null
                : moment(data.lastMedicalCheckupDate).format(BE_DATE_FORMAT),
        };

        onSubmitFn(payload);
    }, [onSubmitFn]);

    useEffect(() => {
        if (countryOfBirth !== 'fr' && countryOfBirth) {
            setValue('regionalDepartmentOfBirth', '99');
            setIsRegionalDepartmentOfBirthDisabled(true);

            return;
        }

        setIsRegionalDepartmentOfBirthDisabled(false);

        if (postalCode) {
            const digits = postalCode.substring(0, 2);

            if (digits.length < 2) {
                return;
            }

            const department = REGIONAL_DEPARTMENTS.find(department => department.id === digits);

            if (!department) {
                return;
            }

            setValue('regionalDepartmentOfBirth', digits);
        }
    }, [countryOfBirth, setValue, postalCode]);

    useEffect(() => {
        if (!personalInformationData || !loggedInUser) {
            return;
        }

        const foundTitle = Object.values(TITLES).find(title => title === personalInformationData.title);

        reset({
            title: foundTitle || '',
            firstName: personalInformationData?.firstName || loggedInUser?.firstName || '',
            lastName: personalInformationData?.lastName || loggedInUser?.lastName || '',
            dateOfBirth: personalInformationData?.dateOfBirth
                ? moment(personalInformationData.dateOfBirth, BE_DATE_FORMAT)
                : null,
            countryOfBirth: personalInformationData.countryOfBirth || '',
            placeOfBirth: personalInformationData.placeOfBirth || '',
            postalCode: personalInformationData.postalCode || '',
            regionalDepartmentOfBirth: personalInformationData.regionalDepartmentOfBirth || '',
            nationality: personalInformationData.nationality || '',
            socialSecurityNumber: personalInformationData.socialSecurityNumber || '',
        });
    }, [personalInformationData, reset, setValue, loggedInUser]);

    useEffect(() => {
        if (isRegionalDepartmentOfBirthDisabled) {
            return;
        }

        if (postalCode) {
            const digits = postalCode.substring(0, 2);

            if (digits.length < 2) {
                return;
            }

            const department = REGIONAL_DEPARTMENTS.find(department => department.id === digits);

            if (!department) {
                return;
            }

            setValue('regionalDepartmentOfBirth', digits);
        }
        // eslint-disable-next-line
    }, [postalCode, isRegionalDepartmentOfBirthDisabled]);

    if (!isMobileSize) {
        return (
            <form ref={formRef} onSubmit={createSubmitHandler(handleSubmit)} noValidate>
                <PageHeader>
                    {t('stepTitles.personalInformation')}
                </PageHeader>

                <Paper radius={16} elevation={2} sx={{px: 5, py: 4}}>
                    <div className={styles.container}>
                        <PersonalInformatioinName
                            t={t}
                            control={control}
                            errors={errors}
                        />

                        <Divider className={styles.divider} />

                        <PersonalInformationDoB
                            t={t}
                            control={control}
                            errors={errors}
                        />

                        <Divider className={styles.divider} />

                        <PersonalInformationPlace
                            t={t}
                            control={control}
                            errors={errors}
                            userLanguage={userLanguage}
                            isRegionalDepartmentOfBirthDisabled={isRegionalDepartmentOfBirthDisabled}
                        />

                        <Divider className={styles.divider} />

                        <PersonalInformationSsn
                            t={t}
                            control={control}
                            errors={errors}
                        />

                        <Divider className={styles.divider} />

                        <PersonalInformationMedical
                            t={t}
                            control={control}
                            errors={errors}
                            hasNoMedicalCheckup={hasNoMedicalCheckup}
                        />

                        <Divider className={styles.divider} />

                        <PersonalInformationMutual
                            t={t}
                            personalInsuranceDocument={personalInsuranceDocument}
                            subscribedToHiwayInsurance={subscribedToHiwayInsurance}
                            formErrors={errors}
                            setValue={setValue}
                        />
                    </div>
                </Paper>

                <Box display="flex" justifyContent="flex-end" sx={{mt: 3}}>
                    <LoadingButton
                        loading={isLoading}
                        size="large"
                        variant="contained"
                        color="secondary"
                        endIcon={<ArrowForwardIcon />}
                        disabled={!isValid}
                        type="submit"
                    >
                        {t('saveAndProceed')}
                    </LoadingButton>
                </Box>
            </form>
        );
    }

    return (
        <form ref={formRef} onSubmit={createSubmitHandler(handleSubmit)} noValidate>
            <PageHeader isMobile>
                {t('stepTitles.personalInformation')}
            </PageHeader>

            <Paper radius={16} elevation={2} sx={{px: 2, py: 4}}>
                <PersonalInformationMobile
                    t={t}
                    control={control}
                    errors={errors}
                    userLanguage={userLanguage}
                    isRegionalDepartmentOfBirthDisabled={isRegionalDepartmentOfBirthDisabled}
                    hasNoMedicalCheckup={hasNoMedicalCheckup}
                    hasPersonalInsuranceDocument={personalInsuranceDocument}
                    isSubscribedToHiwayInsurance={subscribedToHiwayInsurance}
                    setValue={setValue}
                />
            </Paper>

            <Box display="flex" justifyContent="center" flexDirection="column" sx={{mt: 3}}>
                <LoadingButton
                    sx={{mx: 2}}
                    loading={isLoading}
                    size="large"
                    variant="contained"
                    color="secondary"
                    endIcon={<ArrowForwardIcon />}
                    disabled={!isValid}
                    type="submit"
                >
                    {t('saveAndProceed')}
                </LoadingButton>
            </Box>
        </form>
    );
};

PersonalInformationForm.propTypes = {
    onSubmitFn: PropTypes.func.isRequired,
};

export default PersonalInformationForm;
