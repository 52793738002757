import {select} from 'redux-saga/effects';

import {selectFreelancerAccount} from '../../freelancer/store/freelancer.selectors';
import {selectSelectedCompanyId} from '../store/current-company/current-company.selectors';

export const getCompanyId = function* () {
    const companyId = yield select(selectSelectedCompanyId);

    if (!companyId) {
        const freelancerAccount = yield select(selectFreelancerAccount);

        // In case of Umbrella users
        if (!freelancerAccount) {
            return undefined;
        }

        return freelancerAccount.defaultCompanyId;
    }

    return companyId;
};
