import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import ClientModificationsRequested from './ClientModificationsRequested';
import ClientPage from './ClientPage';
import JoyUIThemeProvider from '../../../../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {LocalesConstants} from '../../../../../utils/locales-constants';
import {MissionClientsActions} from '../store/missions-client.actions';
import {MissionsClientSelector} from '../store/missions-client.selector';
import {MISSION_CLIENT_SCREENS, MISSION_CLIENT_SIDE_TYPE} from '../utils/constants';

const BonDeCommande = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation(LocalesConstants.Client);

    const screen = useSelector(MissionsClientSelector.selectClientScreen);
    const document = useSelector(MissionsClientSelector.selectDocumentInfo);

    useEffect(() => {
        dispatch(MissionClientsActions.getDocumentInfo());
        dispatch(MissionClientsActions.getClientSignature());
    }, [dispatch]);

    if (screen === MISSION_CLIENT_SCREENS.REQUESTED_MODIFICATIONS) {
        return (
            <JoyUIThemeProvider>
                <ClientModificationsRequested
                    document={document}
                    type={MISSION_CLIENT_SIDE_TYPE.BDC}
                />
            </JoyUIThemeProvider>
        );
    }

    return (
        <JoyUIThemeProvider>
            <ClientPage
                title={t('quote.title', {userName: document?.missionInfo?.userName})}
                isSigning={screen === MISSION_CLIENT_SCREENS.PENDING_SIGNING}
            />
        </JoyUIThemeProvider>
    );
};

export default BonDeCommande;
