import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import * as actions from './my-companies.actions';
import {Toast} from '../../../../../lib/toast';
import {getCompanyRequest} from '../../../../company/modules/documents/api/company.api';
import {loadAdditionalCompanyData} from '../../../../company/store/additional-company-data/additional-company-data.sagas';
import {LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';

const getMyCompanySaga = function* ({payload}) {
    yield put(actions.setIsLoadingMyCompany(true));

    try {
        const currentCognitoUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

        const company = yield call(getCompanyRequest, currentCognitoUser.id, payload.companyId);

        yield put(actions.storeMyCompany(company));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingMyCompany(false));
    }
};

export const loadMyCompany = function* ({payload}) {
    const {params: {companyId}} = payload;

    const currentCognitoUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield put(actions.getMyCompany(companyId));

    yield call(loadAdditionalCompanyData, currentCognitoUser.id, companyId);
};

export const watchMyCompaniesSaga = function* () {
    yield all([
        takeLatest(actions.GET_MY_COMPANY, getMyCompanySaga),
    ]);
};
