import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Step, StepContent, StepLabel, Stepper} from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {MainOnboardingSelectors} from '../../store/main-onboarding.slector';

const SubStepConnector = () => {
    return null;
};

const SubStepStepIcon = ({active, completed}) => {
    let backgroundColor = '#90CAF9';

    if (active) {
        backgroundColor = 'v2.light.primary.main';
    } else if (completed) {
        backgroundColor = 'v2.light.primary.main';
    }

    if (active) {
        return (
            <Box sx={{position: 'relative'}}>
                <ChevronRightIcon sx={{position: 'absolute', left: '-31px', top: '-8px', color: '#1976D280'}} />
                <Box sx={{width: 8, height: 8, backgroundColor, borderRadius: '50%'}} className="SubStepStepIcon-circle" />
            </Box>
        );
    }

    return (
        <Box sx={{width: 8, height: 8, backgroundColor, borderRadius: '50%'}} className="SubStepStepIcon-circle" />
    );
};

SubStepStepIcon.propTypes = {
    // eslint-disable-next-line react/boolean-prop-naming
    active: PropTypes.bool,
    // eslint-disable-next-line react/boolean-prop-naming
    completed: PropTypes.bool,
};

SubStepStepIcon.defaultProps = {
    active: false,
    completed: false,
};

export const UmbrellaStepperSubStep = ({
    hasLabel,
    subSteps,
}) => {
    const {t} = useTranslation(LocalesConstants.FreelancerOnboarding);
    const activeSubStep = useSelector(MainOnboardingSelectors.selectSubStep);

    const subStepArray = Object.values(subSteps);

    if (!activeSubStep) {
        return null;
    }

    const onSubstepClick = () => {
        // TODO Do I even need this?
    };

    const activeStepIndex = subStepArray.findIndex(({id}) => id === activeSubStep);

    return (
        <StepContent>
            <Stepper
                sx={{
                    position: 'relative',
                    right: '24px',
                }}
                // activeStep={1}
                orientation="vertical"
                connector={<SubStepConnector />}
            >
                {subStepArray.map((subStepConfig, index) => {
                    const isActive = activeStepIndex === index;
                    const isCompleted = index < activeStepIndex;

                    return (
                        <Step
                            key={subStepConfig.id}
                            active={isActive}
                            completed={isCompleted}
                            onClick={event => {
                                event.stopPropagation();

                                onSubstepClick(subStepConfig);
                            }}
                            sx={{
                                'cursor': 'pointer',
                                '& .Mui-active': {
                                    fontWeight: 'normal',
                                },
                                '& .Mui-completed': {
                                    fontWeight: 'normal',
                                },
                                '& .SubStepStepIcon-circle': {
                                    backgroundColor: isCompleted ? 'v2.light.success.light' : isActive ? '' : '#90CAF9',
                                },
                            }}
                        >
                            <StepLabel
                                StepIconComponent={SubStepStepIcon}
                                sx={{
                                    'minHeight': 36,
                                    'whiteSpace': 'normal',
                                    '& .Mui-active': {
                                        fontWeight: 'normal',
                                    },
                                }}
                            >
                                {hasLabel ? t(subStepConfig.labelKey) : ''}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </StepContent>
    );
};

UmbrellaStepperSubStep.propTypes = {
    hasLabel: PropTypes.bool.isRequired,
    subSteps: PropTypes.object.isRequired,
};
