// TODO Create component that will be generic for this and TransactionFile
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {Box, Fade, Paper, Popper, Typography} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';

export const ExternalInvoiceFile = ({
    file,
    fileMenuOpen,
    setFileMenuOpen,
    onDeleteFile,
}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const buttonRef = useRef();

    const isOpen = (file.name ?? file.path) === fileMenuOpen;
    const setIsOpen = shouldOpen => {
        if (shouldOpen === false) {
            setFileMenuOpen(null);
            return;
        }

        if (isOpen) {
            setFileMenuOpen(null);
        } else {
            setFileMenuOpen((file.name ?? file.path));
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);

    if (typeof file === 'string') {
        return null;
    }

    const fileSplit = file.originalFilename ? file.originalFilename?.split('.') : file.name?.split('.');
    const extension = fileSplit[fileSplit.length - 1];

    const onDropdownClick = event => {
        setAnchorEl(event.currentTarget);
        setIsOpen();
    };

    if (!isMobileSize) {
        return (
            <>
                <Box
                    ref={buttonRef}
                    sx={{
                        'mb': 2,
                        'fontSize': theme => theme.typography.pxToRem(13),
                        'display': 'flex',
                        'justifyContent': 'space-between',
                        'alignItems': 'center',
                        'cursor': 'pointer',
                        'p': 1,
                        'backgroundColor': isOpen ? 'primary_v2.dark' : 'primary_v2.main',
                        'color': 'white',
                        'borderRadius': '8px',
                    }}
                    onClick={onDropdownClick}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    >
                        {extension === 'pdf'
                            ? (<PictureAsPdfIcon sx={{mr: 1.8}} />)
                            : (<FilePresentIcon sx={{mr: 1.8}} />)
                        }
                        <Box component="span" sx={{wordBreak: 'break-word'}}>
                            {file.originalFilename ?? file.name}
                        </Box>
                    </Box>
                </Box>
                <Popper
                    open={isOpen}
                    anchorEl={anchorEl}
                    transition
                    sx={{
                        zIndex: '433',
                    }}
                >
                    {({TransitionProps}) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper
                                sx={{
                                    width: buttonRef?.current?.clientWidth ?? '328px',
                                    borderRadius: '16px',
                                }}
                                elevation={8}
                            >
                                <FileDropownOptions
                                    onDeleteFile={onDeleteFile}
                                />
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </>
        );
    }

    return (
        <>
            <Box
                sx={{
                    'mb': 2,
                    'fontSize': theme => theme.typography.pxToRem(13),
                    'display': 'flex',
                    'justifyContent': 'space-between',
                    'alignItems': 'center',
                    'cursor': 'pointer',
                    'p': 1,
                    'backgroundColor': isOpen ? 'primary_v2.dark' : 'primary_v2.main',
                    'color': 'white',
                    'borderRadius': '8px',
                }}
                onClick={onDropdownClick}
            >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
                >
                    {extension === 'pdf'
                        ? (<PictureAsPdfIcon sx={{mr: 1.8}} />)
                        : (<FilePresentIcon sx={{mr: 1.8}} />)
                    }
                    {file.originalFilename ?? file.name}
                </Box>
            </Box>
            <Drawer
                anchor="bottom"
                open={isOpen}
                onClose={() => {
                    setIsOpen(false);
                }}
                sx={{
                    '.MuiDrawer-paperAnchorBottom': {
                        borderTopRightRadius: '32px',
                        borderTopLeftRadius: '32px',
                    },
                }}
            >
                <Box sx={{
                    minHeight: '100px',
                    borderTopRightRadius: '32px',
                    borderTopLeftRadius: '32px',
                    display: 'flex',
                    flexDirection: 'column',
                    px: 3,
                }}
                >
                    <FileDropownOptions
                        onDeleteFile={onDeleteFile}
                    />
                </Box>
            </Drawer>
        </>
    );
};

ExternalInvoiceFile.propTypes = {
    file: PropTypes.object.isRequired,
    onDeleteFile: PropTypes.func.isRequired,
    fileMenuOpen: PropTypes.object,
    setFileMenuOpen: PropTypes.func.isRequired,
};

ExternalInvoiceFile.defaultProps = {
    fileMenuOpen: null,
};

const FileDropownOptions = ({onDeleteFile}) => {
    const {t} = useTranslation(LocalesConstants.Bank);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                py: 1,
            }}
        >
            <Box
                sx={{
                    'display': 'flex',
                    'flexDirection': 'row',
                    'alignItems': 'center',
                    'px': 2,
                    'py': 2,
                    'cursor': 'pointer',
                    '&:hover': {
                        backgroundColor: 'v2.light.primary.shades',
                    },
                }}
                onClick={onDeleteFile}
            >
                <DeleteTwoToneIcon sx={{mr: 2, color: 'v2.light.error.main'}} />
                <Typography
                    sx={{
                        fontSize: theme => theme.typography.pxToRem(13),
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        color: 'v2.light.error.main',
                    }}
                >
                    {t('fileDetails.delete')}
                </Typography>
            </Box>
        </Box>
    );
};

FileDropownOptions.propTypes = {
    onDeleteFile: PropTypes.func.isRequired,
};
