export const selectEnterpriseInformation = state => state.company.additionalCompanyData.enterpriseInformation;

export const selectIsLoadingEnterpriseInformation = state => state
    .company.additionalCompanyData.isLoadingEnterpriseInformation;

export const selectIsUpdatingEnterpriseInformation = state => state
    .company.additionalCompanyData.isUpdatingEnterpriseInformation;

export const selectIsUpdatingCompanyManagementInformation = state => state
    .company.additionalCompanyData.isUpdatingCompanyManagementInformation;
