import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import CreateCraForm from './CreateCraForm';
import SendCraModal from './SendCraModal';
import JoyUIThemeProvider from '../../../../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {BE_DATE_FORMAT, MOMENT_DATE_FORMAT} from '../../../../../utils/constants';
import {MissionsSelector} from '../../../store/missions.selector';

const CreateCra = ({document}) => {
    const mission = useSelector(MissionsSelector.selectMission);

    const [selectedDates, setSelectedDates] = useState([]);
    const [selectedHalfDates, setSelectedHalfDates] = useState([]);

    useEffect(() => {
        if (document) {
            if (document?.activityInfo?.selectedDates) {
                const selectedDates = [];
                const selectedHalfDates = [];
                document.activityInfo.selectedDates.forEach(date => {
                    if (date?.hours === 8) {
                        selectedDates.push(moment(date?.date, BE_DATE_FORMAT).format(MOMENT_DATE_FORMAT));
                    } else {
                        selectedHalfDates.push(moment(date?.date, BE_DATE_FORMAT).format(MOMENT_DATE_FORMAT));
                    }
                });
                setSelectedDates(selectedDates);
                setSelectedHalfDates(selectedHalfDates);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [document]);


    return (
        <JoyUIThemeProvider>
            <CreateCraForm
                mission={mission}
                selectedDates={selectedDates}
                selectedHalfDates={selectedHalfDates}
                setSelectedDates={setSelectedDates}
                setSelectedHalfDates={setSelectedHalfDates}
            />
            <SendCraModal />
        </JoyUIThemeProvider>
    );
};

CreateCra.propTypes = {
    document: PropTypes.object.isRequired,
};

export default CreateCra;
