import {safeTransformData} from '../../../../../../app/api/axios';
import {coreApiInstance} from '../../../../../../lib/api-providers/core-api-instance';
import {transformUmbrellaCompanyDocuments} from '../company.dto';

export const uploadUmbrellaCompanyDocumentRequest = (
    userId,
    file,
    type,
    category,
    id,
    subType,
    year = undefined,
) => {
    const formData = new FormData();

    formData.append('file', file);

    formData.append('documentType', type);
    formData.append('documentCategory', category);

    if (id) {
        formData.append('documentId', id);
    }

    if (year) {
        formData.append('year', year);
    }

    if (subType) {
        formData.append('documentSubType', subType);
    }

    return coreApiInstance({
        method: 'POST',
        url: `/api/v2/users/${userId}/documents/upload`,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data: formData,
    }).then(response => safeTransformData(response));
};

export const getUmbrellaCompanyDocuments = (userId, documentContext) => {
    return coreApiInstance({
        method: 'GET',
        url: `/api/v2/users/${userId}/documents`,
        params: {
            // TODO: When BE is ready
            // doc_context: documentContext,
        },
    }).then(response => safeTransformData(response, transformUmbrellaCompanyDocuments, {freelancerId: userId}));
};
