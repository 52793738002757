import {
    faCalendar,
    faCalendarDays,
    faCalendarWeek,
    faHouseCircleCheck,
    faInfoCircle,
    faPersonCane,
    faPersonCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Divider, Stack, Tooltip, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {Tab, TabsBlock} from './TabsBlock';
import {EurAmount} from '../../../../../../components/eur-amount/EurAmount';
import {InfoSheet} from '../../../../../../components/joy-ui/info-sheet/InfoSheet';
import Section from '../../../../../../components/joy-ui/sections/Section';
import {useIsMobileSize} from '../../../../../../hooks/use-is-mobile-size';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {useSimulationData} from '../../utils/useSimulationData';

const RevenueAmounts = ({items}) => {
    const isMobileSize = useIsMobileSize();
    return (
        <Stack
            direction="row"
            divider={<Divider orientation="vertical" />}
            spacing={2}
            flex={1}
            mb={3}
            minHeight="60px"
            whiteSpace="nowrap"
        >
            {items.map(({amount, description, icon, smallText}, index) => (
                // items never change so using index as key is ok
                <Stack flex={1} key={index} justifyContent="center" textAlign={isMobileSize ? 'center' : 'left'}>
                    {amount !== undefined && (
                        <Typography
                            level={isMobileSize ? 'h3' : 'h2'}
                            textColor={index === 0 ? 'success.softActiveColor' : 'neutral.outlinedColor'}
                        >
                            <EurAmount amount={amount} />
                        </Typography>
                    )}
                    {description && (
                        <Typography level="h4" textColor="neutral.outlinedColor">
                            {description}
                        </Typography>
                    )}
                    <Typography level="body-xs" textColor="text.tertiary">
                        <Stack
                            spacing={0.5}
                            direction="row"
                            alignItems="center"
                            justifyContent={isMobileSize ? 'center' : 'flex-start'}
                        >
                            <FontAwesomeIcon icon={icon} />
                            <Box>{smallText}</Box>
                        </Stack>
                    </Typography>
                </Stack>
            ))}
        </Stack>
    );
};

RevenueAmounts.propTypes = {
    items: PropTypes.array.isRequired,
};

const SubSection = ({title, children, tooltip}) => {
    return (
        <Stack sx={{borderRadius: 'md', p: 2, border: '1px solid', borderColor: 'neutral.outlinedBorder', flex: 1}}>
            {title && (
                <Stack direction="row" spacing={1} alignItems="center" mb={2}>
                    <Typography level="title-sm" textColor="text.tertiary">
                        {title}
                    </Typography>
                    {tooltip && (
                        <Tooltip title={tooltip} placement="top">
                            <Box sx={{color: 'text.secondary', cursor: 'pointer'}}>
                                <FontAwesomeIcon icon={faInfoCircle} />
                            </Box>
                        </Tooltip>
                    )}
                </Stack>
            )}
            {children}
        </Stack>
    );
};

SubSection.propTypes = {
    title: PropTypes.string,
    tooltip: PropTypes.node,
};

SubSection.defaultProps = {
    title: undefined,
    tooltip: undefined,
};

export const IncomeSection = () => {
    const {t} = useTranslation(LocalesConstants.RevenueSimulator);
    const simulation = useSimulationData();
    const input = simulation.simulationInput;
    const output = simulation.simulationOutput;

    const numberOfParts = input.personalInfo.numberOfAdults + input.personalInfo.numberOfDependents;
    const hasHouseholdIncome = output.householdIncome && numberOfParts >= 2;

    const BEFORE_TAXES = 'income.beforeTaxes';
    const AFTER_TAXES = 'income.afterTaxes';

    const revenueIncrease = {
        [BEFORE_TAXES]: output.income.annualIncomeBeforeTaxes - input.professionalInfo.monthlyNetIncome * 12,
        [AFTER_TAXES]: output.income.annualIncomeAfterTaxes - input.professionalInfo.monthlyNetIncome * 12,
    };

    const householdRevenueIncrease = hasHouseholdIncome && {
        [BEFORE_TAXES]:
            output.householdIncome.annualIncomeBeforeTaxes
            - (input.professionalInfo.monthlyNetIncome + input.professionalInfo.spouseMonthlyNetIncome) * 12,
        [AFTER_TAXES]:
            output.householdIncome.annualIncomeAfterTaxes
            - (input.professionalInfo.monthlyNetIncome + input.professionalInfo.spouseMonthlyNetIncome) * 12,
    };

    const [householdFutureIncomeTab, setHouseholdFutureIncomeTab] = useState(BEFORE_TAXES);

    const householdIncomeInfoSheet = hasHouseholdIncome && (
        <InfoSheet
            title={t('income.householdIncome.infoTitle')}
            description={(
                <Trans
                    t={t}
                    i18nKey="income.householdIncome.description"
                    components={{
                        strong: (
                            <Box
                                component="span"
                                sx={{
                                    color: 'text.success',
                                    fontSize: 'md',
                                    fontWeight: 'xl',
                                }}
                            />
                        ),
                        amount: <EurAmount amount={householdRevenueIncrease[householdFutureIncomeTab]} />,
                    }}
                />
              )}
            sx={{flex: 1, alignItems: 'flex-start'}}
        />
    );

    return (
        <Stack spacing={5} mb={5}>
            <Section icon={faPersonCircleCheck} title={t('income.title')}>
                <Stack spacing={2} direction={{mobile: 'column', desktop: 'row'}}>
                    {/* ======================================== */}
                    {/* ========== Your Future Revenue ========= */}
                    {/* ======================================== */}
                    <SubSection title={t('income.yourFutureIncome.title')}>
                        <TabsBlock>
                            <Tab titleKey={BEFORE_TAXES}>
                                <RevenueAmounts
                                    items={[
                                        {
                                            amount: output.income.annualIncomeBeforeTaxes,
                                            icon: faCalendar,
                                            smallText: t('income.perYear'),
                                        },
                                        {
                                            amount: output.income.monthlyIncomeBeforeTaxes,
                                            icon: faCalendarDays,
                                            smallText: t('income.perMonth'),
                                        },
                                    ]}
                                />
                            </Tab>
                            <Tab titleKey={AFTER_TAXES} isDisabled={numberOfParts >= 2}>
                                <RevenueAmounts
                                    items={[
                                        {
                                            amount: output.income.annualIncomeAfterTaxes,
                                            icon: faCalendar,
                                            smallText: t('income.perYear'),
                                        },
                                        {
                                            amount: output.income.monthlyIncomeAfterTaxes,
                                            icon: faCalendarDays,
                                            smallText: t('income.perMonth'),
                                        },
                                    ]}
                                />
                            </Tab>
                        </TabsBlock>
                        <InfoSheet
                            title={t('income.yourFutureIncome.infoTitle')}
                            description={(
                                <Trans
                                    t={t}
                                    i18nKey="income.yourFutureIncome.description"
                                    components={{
                                        strong: (
                                            <Box
                                                component="span"
                                                sx={{
                                                    color: 'text.success',
                                                    fontSize: 'md',
                                                    fontWeight: 'xl',
                                                }}
                                            />
                                        ),
                                        amount: (
                                            <EurAmount
                                                style={{whiteSpace: 'nowrap'}}
                                                amount={revenueIncrease[BEFORE_TAXES]}
                                            />
                                        ),
                                    }}
                                />
                              )}
                            sx={{flex: 1, alignItems: 'flex-start'}}
                        />
                    </SubSection>

                    {/* ======================================== */}
                    {/* ========= Your Social Benefits ========= */}
                    {/* ======================================== */}
                    <SubSection title={t('income.yourSocialBenefits.title')}>
                        <TabsBlock>
                            <Tab titleKey="income.yourSocialBenefits.firstYear.title">
                                <RevenueAmounts
                                    items={[
                                        {
                                            amount: output.socialBenefits.firstYear,
                                        },
                                        {
                                            smallText: t('income.yourSocialBenefits.firstYear.smallText'),
                                            description: t('income.yourSocialBenefits.firstYear.description'),
                                            icon: faCalendarWeek,
                                        },
                                    ]}
                                />
                                <InfoSheet
                                    description={t('income.yourSocialBenefits.firstYear.infoBoxDescription')}
                                    title={t('income.yourSocialBenefits.infoTitle')}
                                    sx={{flex: 1, alignItems: 'flex-start'}}
                                />
                            </Tab>
                            <Tab titleKey="income.yourSocialBenefits.inCaseOfClosure.title">
                                <RevenueAmounts
                                    items={[
                                        {
                                            amount: output.socialBenefits.inCaseOfClosure,
                                        },
                                        {
                                            smallText: t('income.yourSocialBenefits.inCaseOfClosure.smallText'),
                                            description: t('income.yourSocialBenefits.inCaseOfClosure.description'),
                                            icon: faCalendarWeek,
                                        },
                                    ]}
                                />
                                <InfoSheet
                                    description={t('income.yourSocialBenefits.inCaseOfClosure.infoBoxDescription')}
                                    title={t('income.yourSocialBenefits.infoTitle')}
                                    sx={{flex: 1, alignItems: 'flex-start'}}
                                />
                            </Tab>
                        </TabsBlock>
                    </SubSection>

                    {/* ======================================== */}
                    {/* =========== Your Investments =========== */}
                    {/* ======================================== */}
                    {input.investments.monthlyRetirementSavingsPlanAmount && (
                        <SubSection title={t('income.yourInvestments.title')} tooltip={t('income.yourFutureIncome.tooltip')}>
                            <TabsBlock>
                                <Tab titleKey="income.yourInvestments.wise">
                                    <RevenueAmounts
                                        items={[
                                            {
                                                amount: output.investments.wise.retirementCapital,
                                                smallText: t('income.retirementCapital'),
                                                icon: faPersonCane,
                                            },
                                            {
                                                amount: output.investments.wise.monthlyGains,
                                                smallText: t('income.complementaryPerMonth'),
                                                icon: faCalendarDays,
                                            },
                                        ]}
                                    />
                                </Tab>
                                <Tab titleKey="income.yourInvestments.balanced">
                                    <RevenueAmounts
                                        items={[
                                            {
                                                amount: output.investments.balanced.retirementCapital,
                                                smallText: t('income.retirementCapital'),
                                                icon: faPersonCane,
                                            },
                                            {
                                                amount: output.investments.balanced.monthlyGains,
                                                smallText: t('income.complementaryPerMonth'),
                                            },
                                        ]}
                                    />
                                </Tab>
                                <Tab titleKey="income.yourInvestments.dynamic">
                                    <RevenueAmounts
                                        items={[
                                            {
                                                amount: output.investments.dynamic.retirementCapital,
                                                smallText: t('income.retirementCapital'),
                                                icon: faPersonCane,
                                            },
                                            {
                                                amount: output.investments.dynamic.monthlyGains,
                                                smallText: t('income.complementaryPerMonth'),
                                            },
                                        ]}
                                    />
                                </Tab>
                            </TabsBlock>
                            <InfoSheet
                                title={t('income.yourInvestments.infoTitle')}
                                description={<Trans t={t} i18nKey="income.yourInvestments.description" />}
                                sx={{flex: 1, alignItems: 'flex-start'}}
                            />
                        </SubSection>
                    )}
                </Stack>
            </Section>

            {/* ======================================== */}
            {/* =========== Household Income =========== */}
            {/* ======================================== */}
            {hasHouseholdIncome && (
                <Section title={t('income.householdIncome.sectionTitle')} icon={faHouseCircleCheck}>
                    <SubSection>
                        <Stack direction="row" spacing={2}>
                            <TabsBlock
                                beforeTabs={(
                                    <Typography sx={{mb: 2}} level="title-sm" textColor="text.tertiary">
                                        {t('income.householdIncome.title')}
                                    </Typography>
                                  )}
                                onTabChange={setHouseholdFutureIncomeTab}
                            >
                                <Tab titleKey={BEFORE_TAXES}>
                                    <Stack direction={{mobile: 'column', desktop: 'row'}} spacing={2}>
                                        <RevenueAmounts
                                            items={[
                                                {
                                                    amount: output.householdIncome.annualIncomeBeforeTaxes,
                                                    smallText: t('income.perYear'),
                                                    icon: faCalendar,
                                                },
                                                {
                                                    amount: output.householdIncome.monthlyIncomeBeforeTaxes,
                                                    smallText: t('income.perMonth'),
                                                    icon: faCalendarDays,
                                                },
                                            ]}
                                        />
                                        {householdIncomeInfoSheet}
                                    </Stack>
                                </Tab>
                                <Tab titleKey={AFTER_TAXES}>
                                    <Stack direction={{mobile: 'column', desktop: 'row'}} spacing={2}>
                                        <RevenueAmounts
                                            items={[
                                                {
                                                    amount: output.householdIncome.annualIncomeAfterTaxes,
                                                    smallText: t('income.perYear'),
                                                    icon: faCalendar,
                                                },
                                                {
                                                    amount: output.householdIncome.monthlyIncomeAfterTaxes,
                                                    smallText: t('income.perMonth'),
                                                    icon: faCalendarDays,
                                                },
                                            ]}
                                        />
                                        {householdIncomeInfoSheet}
                                    </Stack>
                                </Tab>
                            </TabsBlock>
                        </Stack>
                    </SubSection>
                </Section>
            )}
        </Stack>
    );
};
