export const MISSION_CREATE_SCREENS = {
    CREATE_FORM: 'CREATE_FORM',
    DOCUMENT_SIGNING: 'DOCUMENT_SIGNING',
};

export const MISSION_CRA_SCREENS = {
    CREATE_FORM: 'CREATE_FORM',
    RE_CREATE_FORM: 'RE_CREATE_FORM',
    DOCUMENT_SIGNING: 'DOCUMENT_SIGNING',
    SUCCESS_SCREEN: 'SUCCESS_SCREEN',
};

export const ClientMissionDocumentType = {
    Quote: 'QUOTE',
    Cra: 'CRA',
};

export const MissionListingStatus = {
    InProgress: 'IN_PROGRESS',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
};

export const missionsFormElements = {
    name: 'name',
    email: 'email',
    paymentTerm: 'paymentTerm',
    isEsn: 'isEsn',
    esnContactName: 'esnContactName',
    esnEmail: 'esnEmail',
    countryCode: 'countryCode',
    city: 'city',
    zipCode: 'zipCode',
    address: 'address',
    vatType: 'vatType',
    vatIntraCommunity: 'vatIntraCommunity',
    jobType: 'jobType',
    reference: 'reference',
    startDate: 'startDate',
    endDate: 'endDate',
    billableDays: 'billableDays',
    dailyRate: 'dailyRate',
    description: 'description',
};
