import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Grid, Grow, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import {motion} from 'framer-motion';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import AllSmoke from '../../../../../assets/svg/all-smoke.svg';
import CloudBlurry from '../../../../../assets/svg/cloud-blurry.svg';
import CloudVisible from '../../../../../assets/svg/cloud-visible.svg';
import FinalConfetiHalf1 from '../../../../../assets/svg/final-confeti-half1.svg';
import FinalConfetiHalf2 from '../../../../../assets/svg/final-confeti-half2.svg';
import LogoText from '../../../../../assets/svg/logo-text.svg';
import MobileRocketFinal from '../../../../../assets/svg/mobile-rocket-finalv2.svg';
import RocketFumes from '../../../../../assets/svg/rocket-fumes.svg';
import RocketMonitor from '../../../../../assets/svg/rocket-monitor.svg';
import RocketPedestal from '../../../../../assets/svg/rocket-recktangle.png';
import RocketWithFlame from '../../../../../assets/svg/rocket-with-flamev2.svg';
import Rocket from '../../../../../assets/svg/rocketv2.svg';
import SmokeBottom from '../../../../../assets/svg/smoke-bottom.svg';
import {ButtonAction} from '../../../../../components/buttons/ButtonAction';
import {LocalesConstants} from '../../../../../utils/locales-constants';
import {AnimationsSelectors} from '../../../../animations/store/animations.selector';
import {FreelancerSelectors} from '../../../store/freelancer.selectors';
import {CompaniesSelectors} from '../../companies';
import {OnboardingActions} from '../../onboarding/store/onboarding.action';

const ANIMATION_DURATION = 15;

const styles = {
    card: {
        width: '100%',
        minHeight: '300px',
        borderRadius: '16px',
        background: 'linear-gradient(180deg, #E3F2FD 0%, #FFFFFF 55.73%)',
        boxShadow: '0 1px 1px 0 rgb(11 35 51 / 5%), 0 1px 1px -1px rgb(11 35 51 / 5%), 0 1px 3px 0 rgb(11 35 51 / 17%)',
    },
    button: {
        pl: 3,
        pr: 3,
    },
};

export const FinalScreen = ({onConfirmClick, isUmbrella}) => {
    const {t} = useTranslation(LocalesConstants.FreelancerOnboarding);

    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const dispatch = useDispatch();

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const freelancer = useSelector(FreelancerSelectors.selectAccount);
    const company = useSelector(CompaniesSelectors.createCompanyByIdSelector(freelancer?.defaultCompanyId));

    const onActivateCompany = () => {
        setIsButtonLoading(true);
        dispatch(OnboardingActions.activateCompany(freelancer.id, freelancer.defaultCompanyId));
    };

    if (!isMobileSize) {
        return (
            <>
                <Grid
                    sx={styles.card}
                    container
                    flexDirection="row"
                    justifyContent="space-between"
                    flexWrap="nowrap"
                >
                    <Grid sx={{zIndex: 0}} container item flexDirection="column" justifyContent="space-between" alignItems="center">
                        <Grid>
                            <div>
                                <Box sx={{
                                    position: 'absolute',
                                    top: '230px',
                                }}
                                >
                                    <motion.div
                                        animate={{
                                            x: [222, 222],
                                            scaleX: [2.3, 2.3, 2.3, 2.3, 4, 5.2],
                                            scaleY: [0.4, 0.4, 0.4, 0.4, 0.7, 1.3, 2, 3, 4],
                                            y: [0, 0, 0, 0, -20, -40, -60, -60, -60],
                                            opacity: [0, 0, 0, 0.3, 0.8, 2, 1, 0.4, 0.1, 0, 0.0, 0, 0, 0],
                                        }}
                                        transition={{ease: 'linear', duration: ANIMATION_DURATION}}
                                    >
                                        <img src={RocketFumes} alt="smoke" />
                                    </motion.div>
                                </Box>

                                <motion.div
                                    animate={{opacity: [0, 1]}}
                                    transition={{ease: 'linear', duration: 0.5, delay: 11}}
                                >
                                    <Confeti />
                                </motion.div>

                                <Box sx={{position: 'absolute', top: '175px'}}>
                                    <motion.div
                                        animate={{x: [140, 180, 140]}}
                                        transition={{ease: 'linear', duration: 5, repeat: Infinity}}
                                    >
                                        <img src={CloudVisible} alt="cloud 1" />
                                    </motion.div>
                                </Box>
                                <Box sx={{
                                    position: 'absolute',
                                    top: '165px',
                                    transform: 'scale(0.7)',
                                }}
                                >
                                    <motion.div
                                        animate={{x: [480, 510, 480]}}
                                        transition={{ease: 'linear', duration: 5, repeat: Infinity}}
                                    >
                                        <img src={CloudVisible} alt="cloud 2" />
                                    </motion.div>
                                </Box>
                                <Box sx={{
                                    position: 'absolute',
                                    top: '155px',
                                }}
                                >
                                    <motion.div
                                        animate={{x: [180, 210, 180]}}
                                        transition={{ease: 'linear', duration: 7, repeat: Infinity}}
                                    >
                                        <img src={CloudBlurry} alt="cloud 3" />
                                    </motion.div>
                                </Box>
                                <Box sx={{
                                    position: 'absolute',
                                    top: '145px',
                                    transform: 'scale(0.9)',
                                }}
                                >
                                    <motion.div
                                        animate={{x: [330, 410, 330]}}
                                        transition={{ease: 'linear', duration: 11, repeat: Infinity}}
                                    >
                                        <img src={CloudBlurry} alt="cloud 4" />
                                    </motion.div>
                                </Box>

                                <Box sx={{
                                    position: 'absolute',
                                    top: '250px',
                                }}
                                >
                                    <motion.div
                                        animate={{opacity: [0, 0, 0.8, 0.4, 0, 0], x: [150, 200, 230, 250, 260]}}
                                        transition={{ease: 'linear', duration: ANIMATION_DURATION}}
                                    >
                                        <img src={SmokeBottom} alt="smoke" />
                                    </motion.div>
                                </Box>
                                <Box sx={{
                                    position: 'absolute',
                                    top: '250px',
                                }}
                                >
                                    <motion.div
                                        animate={{opacity: [0, 0, 0.8, 0.4, 0, 0], x: [150, 100, 70, 50, 40]}}
                                        transition={{ease: 'linear', duration: ANIMATION_DURATION}}
                                    >
                                        <img src={SmokeBottom} alt="smoke" />
                                    </motion.div>
                                </Box>

                                <Box sx={{
                                    position: 'absolute',
                                    top: '130px',
                                }}
                                >
                                    <motion.div
                                        animate={{
                                            x: [135, 135],
                                            opacity: [0, 0, 0, 0, 0, 0.4, 0.8, 1, 1, 0.6, 0.4, 0.2, 0, 0],
                                        }}
                                        transition={{ease: 'linear', duration: ANIMATION_DURATION}}
                                    >
                                        <img src={AllSmoke} alt="smoke" />
                                    </motion.div>
                                </Box>

                                <Box sx={{position: 'absolute', top: '285px'}}>
                                    <motion.div
                                        animate={{x: [265, 265]}}
                                        transition={{ease: 'linear', duration: ANIMATION_DURATION}}
                                    >
                                        <img src={RocketPedestal} alt="rocket" />
                                    </motion.div>
                                </Box>
                                <Box sx={{position: 'absolute', top: '165px'}}>
                                    <motion.div
                                        animate={{
                                            x: [240, 240, 240, 240, 240, 240, 240, 239, 241,
                                                239, 240, 239, 240, 239, 241, 239, 240, 239,
                                                240, 239, 241, 239, 240, 239, 240, 239, 241,
                                                239, 240, 239, 240, 239, 241, 239, 240, 239],
                                            y: [0, 0, -20, -80, -170, -500, -3000],
                                            opacity: [1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                                        }}
                                        transition={{ease: 'linear', duration: ANIMATION_DURATION}}
                                    >
                                        <img src={Rocket} alt="rocket" />
                                    </motion.div>
                                </Box>
                                <Box sx={{position: 'absolute', top: '165px'}}>
                                    <motion.div
                                        animate={{
                                            x: [240, 239, 241, 238, 240, 239, 240, 239, 242,
                                                238, 240, 239, 240, 239, 241, 238, 240, 239,
                                                240, 239, 242, 238, 240, 239, 240, 239, 241,
                                                238, 240, 239, 240, 239, 242, 238, 240, 239,
                                                240, 239, 241, 238, 240, 239, 240, 239, 242,
                                                238, 240, 239],
                                            opacity: [0, 0, 0.3, 0.6, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
                                            scale: [1, 1, 0.98, 1, 1.02, 0.7, 0.5],
                                            y: [0, 0, -20, -80, -170, -500, -3000],
                                        }}
                                        transition={{ease: 'linear', duration: ANIMATION_DURATION}}
                                    >
                                        <img src={RocketWithFlame} alt="rocket" />
                                    </motion.div>
                                </Box>

                                <Box sx={{position: 'absolute', top: '205px'}}>
                                    <motion.div
                                        animate={{
                                            opacity: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.3, 0.6, 1, 1, 1],
                                            x: [250, 250],
                                            scale: [1.3, 1.3],
                                        }}
                                        transition={{ease: 'linear', duration: ANIMATION_DURATION}}
                                    >
                                        <img src={LogoText} alt="rocket" />
                                    </motion.div>
                                </Box>

                                <img src={RocketMonitor} alt="Rocket monitor" />
                            </div>
                        </Grid>
                        <Grid>
                            <motion.div
                                animate={{
                                    opacity: [0, 1],
                                    scale: [0, 1, 1],
                                }}
                                transition={{ease: 'linear', duration: 2, delay: 0.5}}
                            >
                                <Typography sx={{
                                    fontSize: '60px',
                                    lineHeight: '70px',
                                    fontWeight: 'bold',
                                    mt: 2,
                                    px: 8,
                                    textAlign: 'center',
                                }}
                                >
                                    <Trans
                                        t={t}
                                        i18nKey={isUmbrella ? 'umbrella.finalScreenHeader' : 'finalScreen.header'}
                                        values={{companyName: company?.name ?? t('umbrella.rocketText')}}
                                        components={{bold: <Box component="span" sx={{color: '#E84C4C'}} />}}
                                    />
                                </Typography>
                            </motion.div>
                        </Grid>
                        <Grow in={isAnimationActive}>
                            <motion.div
                                animate={{
                                    opacity: [0, 1],
                                    scale: [0, 1, 1],
                                }}
                                transition={{ease: 'linear', duration: 2, delay: ANIMATION_DURATION - 4}}
                            >
                                <Typography sx={{
                                    fontSize: '21px',
                                    fontWeight: 'bold',
                                    color: 'rgba(0, 0, 0, 0.38)',
                                    mt: 2,
                                    mb: 5,
                                    textAlign: 'center',
                                }}
                                >
                                    {t('finalScreen.text')}
                                </Typography>
                            </motion.div>
                        </Grow>
                    </Grid>
                </Grid>
                <Grid container item justifyContent="flex-end" sx={{mt: 3}} alignItems="center">
                    <ButtonAction
                        endIcon={<ArrowForwardIcon />}
                        sx={styles.button}
                        onClick={() => {
                            if (onConfirmClick) {
                                setIsButtonLoading(true);
                                onConfirmClick();
                                return;
                            }
                            onActivateCompany();
                        }}
                        disabled={isButtonLoading}
                        isLoading={isButtonLoading}
                        color="secondary"
                    >
                        {t(isUmbrella ? 'finalScreen.buttonPortage' : 'finalScreen.button')}
                    </ButtonAction>
                </Grid>
            </>
        );
    }

    if (isMobileSize) {
        return (
            <Grid>
                <Grid>
                    <img src={MobileRocketFinal} alt="Rocket preparing" />
                </Grid>
                <Grid>
                    <Typography sx={{
                        fontSize: '34px',
                        fontWeight: 'bold',
                        mt: 2,
                        px: 2,
                        textAlign: 'center',
                    }}
                    >
                        <Trans
                            t={t}
                            values={{companyName: company?.name ?? t('umbrella.rocketText')}}
                            i18nKey={isUmbrella ? 'umbrella.finalScreenHeader' : 'finalScreen.header'}
                            components={{bold: <Box component="span" sx={{color: '#E84C4C'}} />}}
                        />
                    </Typography>
                </Grid>
                <Grid>
                    <Typography sx={{
                        fontSize: '21px',
                        fontWeight: 'bold',
                        color: 'rgba(0, 0, 0, 0.38)',
                        mt: 2,
                        mb: 2,
                        px: 2,
                        textAlign: 'center',
                    }}
                    >
                        {t('finalScreen.text')}
                    </Typography>

                </Grid>

                <Grid container item justifyContent="flex-end" sx={{mt: 3, px: 2}} alignItems="center">
                    <ButtonAction
                        endIcon={<ArrowForwardIcon />}
                        sx={styles.button}
                        onClick={() => {
                            if (onConfirmClick) {
                                setIsButtonLoading(true);
                                onConfirmClick();
                                return;
                            }
                            setIsButtonLoading(true);
                            onActivateCompany();
                        }}
                        disabled={isButtonLoading}
                        isLoading={isButtonLoading}
                        fullWidth
                        color="secondary"
                    >
                        {t(isUmbrella ? 'finalScreen.buttonPortage' : 'finalScreen.button')}
                    </ButtonAction>
                </Grid>
            </Grid>
        );
    }
};

const Confeti = () => {
    return (
        <Box sx={{zIndex: -2}}>
            <Box sx={{position: 'absolute', zIndex: -1}}>
                <motion.div
                    animate={{
                        scale: [0, 2],
                        y: [110, -200],
                    }}
                    transition={{ease: 'lienar', duration: 2.4, repeat: Infinity, delay: 0.8}}
                >
                    <img src={FinalConfetiHalf1} alt="confeti 1" />
                </motion.div>
            </Box>
            <Box sx={{position: 'absolute', zIndex: -1}}>
                <motion.div
                    animate={{
                        scale: [0, 2],
                        y: [100, -200],
                    }}
                    transition={{ease: 'linear', duration: 2.4, repeat: Infinity, delay: 0.7}}
                >
                    <img src={FinalConfetiHalf2} alt="confeti 1" />
                </motion.div>
            </Box>

            <Box sx={{position: 'absolute', zIndex: -1}}>
                <motion.div
                    animate={{
                        scale: [0, 1.5],
                        y: [100, -300],
                    }}
                    transition={{ease: 'lienar', duration: 2.4, repeat: Infinity, delay: 0.6}}
                >
                    <img src={FinalConfetiHalf1} alt="confeti 1" />
                </motion.div>
            </Box>
            <Box sx={{position: 'absolute', zIndex: -1}}>
                <motion.div
                    animate={{
                        scale: [0, 1.5],
                        y: [110, -300],
                    }}
                    transition={{ease: 'linear', duration: 2.4, repeat: Infinity, delay: 0.55}}
                >
                    <img src={FinalConfetiHalf2} alt="confeti 1" />
                </motion.div>
            </Box>

            <Box sx={{position: 'absolute', zIndex: -1}}>
                <motion.div
                    animate={{
                        scale: [0, 1.5],
                        y: [95, -400],
                    }}
                    transition={{ease: 'lienar', duration: 2.4, repeat: Infinity, delay: 0.4}}
                >
                    <img src={FinalConfetiHalf1} alt="confeti 1" />
                </motion.div>
            </Box>
            <Box sx={{position: 'absolute', zIndex: -1}}>
                <motion.div
                    animate={{
                        scale: [0, 1.5],
                        y: [100, -400],
                    }}
                    transition={{ease: 'linear', duration: 2.4, repeat: Infinity, delay: 0.35}}
                >
                    <img src={FinalConfetiHalf2} alt="confeti 1" />
                </motion.div>
            </Box>

            <Box sx={{position: 'absolute', zIndex: -1}}>
                <motion.div
                    animate={{
                        scale: [0, 1],
                        y: [100, -500],
                    }}
                    transition={{ease: 'lienar', duration: 2.4, repeat: Infinity, delay: 0.2}}
                >
                    <img src={FinalConfetiHalf1} alt="confeti 1" />
                </motion.div>
            </Box>
            <Box sx={{position: 'absolute', zIndex: -1}}>
                <motion.div
                    animate={{
                        scale: [0, 1],
                        y: [100, -500],
                    }}
                    transition={{ease: 'linear', duration: 2.4, repeat: Infinity, delay: 0.2}}
                >
                    <img src={FinalConfetiHalf2} alt="confeti 1" />
                </motion.div>
            </Box>

            <Box sx={{position: 'absolute', zIndex: -1}}>
                <motion.div
                    animate={{
                        scale: [0, 0.8],
                        y: [100, -600],
                    }}
                    transition={{ease: 'lienar', duration: 2.4, repeat: Infinity, delay: 0}}
                >
                    <img src={FinalConfetiHalf1} alt="confeti 1" />
                </motion.div>
            </Box>
            <Box sx={{position: 'absolute', zIndex: -1}}>
                <motion.div
                    animate={{
                        scale: [0, 0.8],
                        y: [100, -600],
                    }}
                    transition={{ease: 'linear', duration: 2.4, repeat: Infinity, delay: 0}}
                >
                    <img src={FinalConfetiHalf2} alt="confeti 1" />
                </motion.div>
            </Box>
        </Box>
    );
};

FinalScreen.propTypes = {
    onConfirmClick: PropTypes.func,
    isUmbrella: PropTypes.bool,
};

FinalScreen.defaultProps = {
    onConfirmClick: null,
    isUmbrella: false,
};
