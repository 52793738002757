import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {getDocumentsTableConfig} from './documents-table-config';
import DataTable from '../../../components/ui-kit/DataTable/DataTable';
import {DATA_TABLE_TAB_FILTERS, DOCUMENT_CATEGORIES} from '../../../consts/document-constants';
import {LocalesConstants} from '../../../utils/locales-constants';
import {UserRoles} from '../../../utils/user-roles';
import {CompaniesSelectors} from '../../freelancer/modules/companies';
import {LoggedInUserSelectors} from '../../user/modules/logged-in-user';
import {
    createDocumentsDataTableSelector,
    selectAtLeastOnePayCategoryDocumentExist,
    selectIsLoadingCompanyDocuments,
} from '../modules/documents/store/company-documents.selectors';
import {selectCompany} from '../store/company.selectors';

const DocumentsTable = () => {
    const {t} = useTranslation(LocalesConstants.Documents);

    const user = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const userCompany = useSelector(CompaniesSelectors.createCompanyByIdSelector(user.defaultCompanyId));
    const adminCompany = useSelector(selectCompany);
    const atLastOnePayCategoryDocumentExists = useSelector(selectAtLeastOnePayCategoryDocumentExist);
    const company = userCompany || adminCompany;

    const isLoading = useSelector(selectIsLoadingCompanyDocuments);

    const columnConfig = useMemo(() => {
        return getDocumentsTableConfig(t);
    }, [t]);

    const filters = useMemo(() => {
        let tabFilters = DATA_TABLE_TAB_FILTERS;

        // Hide training tab for user role FREELANCER & non-eligible for training
        if (user.role === UserRoles.FREELANCER && !company?.training?.isEligible) {
            tabFilters = tabFilters.filter(tab => tab !== DOCUMENT_CATEGORIES.TRAINING);
        }

        // Display PAY tab only if there is at least one document in the category
        if (!atLastOnePayCategoryDocumentExists) {
            tabFilters = tabFilters.filter(tab => tab !== DOCUMENT_CATEGORIES.PAY);
        }

        return tabFilters;
        // eslint-disable-next-line
    }, [company, atLastOnePayCategoryDocumentExists]);

    // TODO: If the route is for a tab that does not exist, redirect to first tab that exists

    return (
        <DataTable
            tableId="documents"
            columnConfig={columnConfig}
            missingDataMessage={t('missingDocuments')}
            dataSelector={createDocumentsDataTableSelector}
            isLoading={isLoading}
            defaultRowsPerPage={25}
            tabFilterConfig={filters.map(category => ({
                id: category,
                label: t(`documentsDatabaseCategories.${category}`),
            }))}
            tabFilterKey="category"
            searchBy={['docName', 'fileName']}
            searchPlaceholder={t('documentsDatabaseTable.searchDocuments')}
            hasTabFilters
            hasSearch
        />
    );
};

export default DocumentsTable;

