import {Company} from './companies.dto';
import {safeTransformData} from '../../../../../app/api/axios';
import {transformFreelancerCompanies} from '../../../../../app/api/providers/freelancer/freelancer.dto';
import {hiwayApiInstance} from '../../../../../lib/hiway-api-instance';

const getCompanies = ({freelancerId}) => {
    return hiwayApiInstance
        .get(`/v1/freelancers/${freelancerId}/companies`)
        .then(result => {
            const companies = {};

            result.data.forEach(companyDto => {
                companies[companyDto.id] = new Company(companyDto);
            });

            return {
                companies,
                freelancerCompanies: safeTransformData(result, transformFreelancerCompanies),
            };
        });
};

// Move freelancer company to registration not prepared status
const registrationNotPrepared = (
    freelancerId,
    companyId,
) => {
    return hiwayApiInstance
        .put(`/v1/freelancers/${freelancerId}/companies/${companyId}/deposit-attestation-step`)
        .then(result => result?.data);
};

export const FreelancerCompaniesApi = {
    getCompanies,
    registrationNotPrepared,
};
