import RefreshIcon from '@mui/icons-material/Refresh';
import {Box, Button, Step, Stepper, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import INPIStepLabel, {STEP_STATE} from './INPIStepLabel';
import Alert from '../../../../../../../components/ui-kit/Alert/Alert';
import {LocalesConstants} from '../../../../../../../utils/locales-constants';
import {DEPOSIT_OF_ACCOUNTS_FLOW_ORDER, DEPOSIT_OF_ACCOUNTS_INPI_STATUS} from '../../../utils/constants';

const INPIStepper = ({inpiSubmissionDetails, isINPIInProcess, onRetry}) => {
    const {t} = useTranslation(LocalesConstants.Companies);
    const inpiStatus = inpiSubmissionDetails?.status ?? DEPOSIT_OF_ACCOUNTS_INPI_STATUS.NOT_STARTED;

    if (!isINPIInProcess
        && DEPOSIT_OF_ACCOUNTS_FLOW_ORDER[inpiStatus]
        < DEPOSIT_OF_ACCOUNTS_FLOW_ORDER[DEPOSIT_OF_ACCOUNTS_INPI_STATUS.REJECTED]) {
        return null;
    }

    return (
        <Box>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                <Stepper
                    activeStep={DEPOSIT_OF_ACCOUNTS_FLOW_ORDER[inpiStatus]}
                    sx={{
                        backgroundColor: 'v2.gray.50',
                        px: 2,
                        py: 1,
                        borderRadius: '8px',
                    }}
                >
                    {steps.map(step => {
                        return (
                            <Step
                                key={step.label}
                            >
                                <INPIStepLabel
                                    stepId={step.id}
                                    stepLabel={step.label}
                                    stepState={getStepState(step.id, inpiSubmissionDetails)}
                                />
                            </Step>
                        );
                    })}
                </Stepper>
                {/* Display error  */}
                {inpiSubmissionDetails?.error && (
                    <Alert
                        sx={{
                            '& .MuiAlert-message': {
                                width: '100%',
                            },
                            'margin': '0 !important',
                        }}
                        severity="error"
                        message={(
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, width: '100%'}}>
                                <Typography>
                                    <span style={{fontWeight: 500}}>{t('common:error')}:{' '}</span>
                                    {inpiSubmissionDetails?.error?.message}
                                </Typography>
                                <Button
                                    variant="text"
                                    startIcon={<RefreshIcon />}
                                    sx={{color: 'text.primary', alignSelf: 'flex-end', py: 0.5}}
                                    onClick={onRetry}
                                >
                                    {t('formalities.depositOfAccounts.flowSteps.inpi.buttonTryAgain')}
                                </Button>
                            </Box>
                        )}
                    />
                )}
                {/* Display warnings */}
                {inpiSubmissionDetails?.warnings?.length > 0 && (
                    <Alert
                        sx={{
                            '& .MuiAlert-message': {
                                width: '100%',
                            },
                            'margin': '0 !important',
                        }}
                        severity="warning"
                        message={(
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, width: '100%'}}>
                                {inpiSubmissionDetails?.warnings.map((warning, index) => (
                                    <Typography key={index}>
                                        <span style={{fontWeight: 500}}>{warning.type}:{' '}</span>
                                        {warning?.message}
                                    </Typography>
                                ))}
                                <Button
                                    variant="text"
                                    startIcon={<RefreshIcon />}
                                    sx={{color: 'text.primary', alignSelf: 'flex-end', py: 0.5}}
                                    onClick={onRetry}
                                >
                                    {t('formalities.depositOfAccounts.flowSteps.inpi.buttonTryAgain')}
                                </Button>
                            </Box>
                        )}
                    />
                )}
            </Box>
        </Box>
    );
};

const steps = [
    {
        id: DEPOSIT_OF_ACCOUNTS_INPI_STATUS.NOT_STARTED,
        label: `formalities.depositOfAccounts.flowSteps.inpi.stepLabels.${DEPOSIT_OF_ACCOUNTS_INPI_STATUS.NOT_STARTED}`,
    },
    {
        id: DEPOSIT_OF_ACCOUNTS_INPI_STATUS.SIGNATURE_PENDING,
        label: `formalities.depositOfAccounts.flowSteps.inpi.stepLabels.${DEPOSIT_OF_ACCOUNTS_INPI_STATUS.SIGNATURE_PENDING}`,
    },
    {
        id: DEPOSIT_OF_ACCOUNTS_INPI_STATUS.PAYMENT_PENDING,
        label: `formalities.depositOfAccounts.flowSteps.inpi.stepLabels.${DEPOSIT_OF_ACCOUNTS_INPI_STATUS.PAYMENT_PENDING}`,
    },
];

const getStepState = (step, inpiSubmissionDetails) => {
    const inpiStatus = inpiSubmissionDetails?.status;
    const retry = inpiSubmissionDetails?.retry;

    switch (step) {
        case DEPOSIT_OF_ACCOUNTS_INPI_STATUS.NOT_STARTED: {
            if (DEPOSIT_OF_ACCOUNTS_FLOW_ORDER[inpiStatus]
                > DEPOSIT_OF_ACCOUNTS_FLOW_ORDER[DEPOSIT_OF_ACCOUNTS_INPI_STATUS.NOT_STARTED]) {
                return STEP_STATE.Completed;
            }

            if (inpiSubmissionDetails?.error || inpiSubmissionDetails?.warnings?.length > 0) {
                if (retry) {
                    return STEP_STATE.Active;
                }

                return STEP_STATE.Error;
            }

            return STEP_STATE.Active;
        }
        case DEPOSIT_OF_ACCOUNTS_INPI_STATUS.SIGNATURE_PENDING: {
            if (DEPOSIT_OF_ACCOUNTS_FLOW_ORDER[inpiStatus]
                === DEPOSIT_OF_ACCOUNTS_FLOW_ORDER[DEPOSIT_OF_ACCOUNTS_INPI_STATUS.SIGNATURE_PENDING]) {
                if (inpiSubmissionDetails?.error || inpiSubmissionDetails?.warnings?.length > 0) {
                    if (retry) {
                        return STEP_STATE.Active;
                    }

                    return STEP_STATE.Error;
                }

                return STEP_STATE.Active;
            }

            if (DEPOSIT_OF_ACCOUNTS_FLOW_ORDER[inpiStatus]
                > DEPOSIT_OF_ACCOUNTS_FLOW_ORDER[DEPOSIT_OF_ACCOUNTS_INPI_STATUS.SIGNATURE_PENDING]) {
                return STEP_STATE.Completed;
            }

            return STEP_STATE.Pending;
        }
        case DEPOSIT_OF_ACCOUNTS_INPI_STATUS.PAYMENT_PENDING: {
            if (DEPOSIT_OF_ACCOUNTS_FLOW_ORDER[inpiStatus]
                === DEPOSIT_OF_ACCOUNTS_FLOW_ORDER[DEPOSIT_OF_ACCOUNTS_INPI_STATUS.PAYMENT_PENDING]) {
                if (inpiSubmissionDetails?.error || inpiSubmissionDetails?.warnings?.length > 0) {
                    if (retry) {
                        return STEP_STATE.Active;
                    }

                    return STEP_STATE.Error;
                }

                return STEP_STATE.Active;
            }

            if (DEPOSIT_OF_ACCOUNTS_FLOW_ORDER[inpiStatus]
                > DEPOSIT_OF_ACCOUNTS_FLOW_ORDER[DEPOSIT_OF_ACCOUNTS_INPI_STATUS.PAYMENT_PENDING]) {
                return STEP_STATE.Completed;
            }

            return STEP_STATE.Pending;
        }
    }
};

INPIStepper.propTypes = {
    inpiSubmissionDetails: PropTypes.object.isRequired,
    isINPIInProcess: PropTypes.bool.isRequired,
    onRetry: PropTypes.func.isRequired,
};

export default INPIStepper;
