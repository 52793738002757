import moment from 'moment';
import {all, call, fork, put, select, takeEvery} from 'redux-saga/effects';

import {watchAdminExpenses} from './admin-expenses/admin-expenses.sagas';
import * as actions from './expenses.actions';
import {setIsLoadingHomeOfficeTravelsForm} from './home-office-travels-form/home-office-travels-form.actions';
import {watchHomeOfficeTravelsFormSagas} from './home-office-travels-form/home-office-travels-form.sagas';
import {getBusinessAllowanceTotalDistanceRequest} from '../../../app/api/providers/expenses/expenses.provider';
import {getConfig} from '../../../config/get-config';
import {replace} from '../../../lib/router/connected-router-saga';
import {Toast} from '../../../lib/toast';
import {createDefaultCompanySelector} from '../../company/store/company-list/company-list.selectors';
import {COMPANY_LEGAL_STATUSES} from '../../company/utils/constants';
import {FreelancerSelectors} from '../../freelancer';
import {watchBusinessAllowanceSagas} from '../modules/business-allowance/store/business-allowance.sagas';
import {selectCurrentlyEditingTravel} from '../modules/business-allowance/store/business-allowance.selectors';
import {watchPersonalExpensesFormSagas} from '../modules/personal/store/personal-expenses-form/personal-expenses-form.sagas';
import {watchPersonalExpensesSagas} from '../modules/personal/store/personal-expenses.sagas';
import {watchProfessionalExpensesFormSagas} from '../modules/professional/store/professional-expenses-form/professional-expenses-form.sagas';
import {watchProfessionalExpensesSagas} from '../modules/professional/store/professional-expenses.sagas';
import {setIsLoadingProfessionalTravelsForm} from '../modules/professional/store/professional-travels-form/professional-travels-form.actions';
import {watchProfessionalTravelsFormSagas} from '../modules/professional/store/professional-travels-form/professional-travels-form.sagas';

const getTotalDistanceSaga = function* (freelancerId, companyId, date) {
    try {
        const totalDistance = yield call(
            getBusinessAllowanceTotalDistanceRequest,
            freelancerId,
            companyId,
            date,
        );

        yield put(actions.storeTotalDistance(totalDistance));
    } catch (error) {
        Toast.error('anErrorOccurred');
    }
};

const getTotalDistanceWorker = function* ({payload}) {
    yield put(setIsLoadingHomeOfficeTravelsForm(true));
    yield put(setIsLoadingProfessionalTravelsForm(true));

    const freelancer = yield select(FreelancerSelectors.selectAccount);

    // const {id: freelancerId} = yield select(selectCurrentCognitoUser);
    // const {defaultCompanyId} = yield select(selectFreelancerAccount);

    const {date} = payload;

    yield call(getTotalDistanceSaga, freelancer.id, freelancer.defaultCompanyId, date);

    yield put(setIsLoadingHomeOfficeTravelsForm(false));
    yield put(setIsLoadingProfessionalTravelsForm(false));
};

export const homeOfficeRestrictionSaga = function* () {
    const company = yield select(createDefaultCompanySelector());

    if (!company) {
        return;
    }

    if (company.legalForm === COMPANY_LEGAL_STATUSES.EURL
        || company.legalForm === COMPANY_LEGAL_STATUSES.EURL_HOLDING) {
        yield put(replace(getConfig().ROUTE_PATHS.ACCESS_DENIED));
    }
};

export const travelLoaderSaga = function* () {
    yield put(setIsLoadingHomeOfficeTravelsForm(true));
    yield put(setIsLoadingProfessionalTravelsForm(true));

    const freelancer = yield select(FreelancerSelectors.selectAccount);
    const freelancerId = freelancer.id;
    const defaultCompanyId = freelancer.defaultCompanyId;

    const travel = yield select(selectCurrentlyEditingTravel);

    let date = moment().endOf('month').format('DD/MM/YYYY');

    if (travel?.date) {
        date = travel.date;
    }

    yield call(getTotalDistanceSaga, freelancerId, defaultCompanyId, date);

    yield put(setIsLoadingHomeOfficeTravelsForm(false));
    yield put(setIsLoadingProfessionalTravelsForm(false));
};

export const watchExpensesSagas = function* () {
    yield all([
        fork(watchPersonalExpensesSagas),
        fork(watchPersonalExpensesFormSagas),
        fork(watchProfessionalExpensesSagas),
        fork(watchProfessionalExpensesFormSagas),
        fork(watchHomeOfficeTravelsFormSagas),
        fork(watchProfessionalTravelsFormSagas),
        fork(watchBusinessAllowanceSagas),
        fork(watchAdminExpenses),
        takeEvery(actions.GET_TOTAL_DISTANCE, getTotalDistanceWorker),
    ]);
};
