import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {Box, Button, IconButton, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import CategoriesSearch from './CategoriesSearch';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';
import {SettingsSelectors} from '../../../../../../../settings/store/settings.selectors';
import {TransactionsSelector} from '../../../../store/transactions.selector';
import {SECTION_STATUS} from '../../utils/constants';
import ViewAllCategoriesButton from '../common/ViewAllCategoriesButton';
import CategorisationSection from '../header/CategorisationSection';

const SuggestedCategories = ({
    setIsShowAllCategories,
    suggestedMccKey,
    onSelectCategory,
}) => {
    const {t} = useTranslation(LocalesConstants.Bank);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const userSettings = useSelector(SettingsSelectors.selectSettings);
    const language = userSettings?.language ?? 'fr';

    const categoryList = useSelector(TransactionsSelector.selectCategories);

    let categoryInfo;
    categoryList.forEach(categoryParent => {
        categoryParent.mccGroupItems.forEach(category => {
            if (category.mccKey === suggestedMccKey) {
                categoryInfo = category;
            }
        });
    });

    const onSelect = value => {
        onSelectCategory({mccKey: value});
    };

    return (
        <CategorisationSection
            title={t(`categorisation.section.category`)}
            status={SECTION_STATUS.REQUIRED}
            isActive
            isFocused
        >
            <CategoriesSearch />

            <Box>
                <Typography
                    sx={{
                        fontSize: theme => theme.typography.pxToRem(16),
                        letterSpacing: '0.1px',
                        fontWeight: 500,
                        color: 'text_v2.secondary',
                        mb: 1,
                    }}
                >
                    {t('categorisation.suggestion')}
                </Typography>
                <Box sx={{
                    py: 0.5,
                    px: 1,
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    backgroundColor: 'v2.light.warning.shades12',
                    borderRadius: 2,
                }}
                >
                    <FontAwesomeIcon icon={faInfoCircle} />

                    <Typography
                        component="span"
                        sx={{
                            fontSize: theme => theme.typography.pxToRem(14),
                            lineHeight: theme => theme.typography.pxToRem(18),
                            letterSpacing: '0.17px',
                            fontWeight: 400,
                            color: 'text_v2.secondary',
                            flex: '1 0 0',
                        }}
                    >
                        {categoryInfo?.[language]?.categoryName}
                    </Typography>

                    {isMobileSize ? (
                        <IconButton
                            onClick={() => {
                                onSelect(suggestedMccKey);
                            }}
                            sx={{
                                p: 0.5,
                            }}
                        >
                            <KeyboardArrowRightIcon htmlColor="#263238" />
                        </IconButton>
                    ) : (
                        <Button
                            onClick={() => {
                                onSelect(suggestedMccKey);
                            }}
                            sx={{py: 0.5}}
                            variant="text"
                            color="secondary"
                            endIcon={<KeyboardArrowRightIcon />}
                        >
                            {t('categorisation.select')}
                        </Button>
                    )}
                </Box>
            </Box>

            <ViewAllCategoriesButton setIsShowAllCategories={setIsShowAllCategories} />
        </CategorisationSection>
    );
};

SuggestedCategories.propTypes = {
    setIsShowAllCategories: PropTypes.func.isRequired,
    suggestedMccKey: PropTypes.string.isRequired,
    onSelectCategory: PropTypes.func.isRequired,
};

export default SuggestedCategories;
