import {
    faArrowLeft,
    faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Typography} from '@mui/joy';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {AlertInfo} from '../../../../components/joy-ui/alerts/AlertInfo';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {LoadingTypes, useLoadingState} from '../../../loading';
import {UiSelectors} from '../../../ui/store/ui.selector';
import {ModalsKeys} from '../../../ui/utils/constants';
import {TransactionsSelector} from '../../modules/account-balance/store/transactions.selector';
import {getIntegrationInfo} from '../../modules/bridge-api/utils/bridge-util';
import {BankAccountStatus} from '../../modules/bridge-api/utils/constants';
import {BankActions} from '../../store/bank.action';

const modalKey = ModalsKeys.CLOSE_HIWAY_INTEGRATION_MODAL;

const CloseHiwayIntegrationModal = () => {
    const {t} = useTranslation(LocalesConstants.Bank);
    const dispatch = useDispatch();

    const data = useSelector(UiSelectors.createModalDataSelector(modalKey));
    const isDeleting = useLoadingState(LoadingTypes.DELETE_BANK);
    const transactionIds = useSelector(TransactionsSelector.selectTransactionIds);

    const handleClick = () => {
        const integration = data?.integration;

        const {account} = getIntegrationInfo(integration);

        // If account is already closing or closed finish archiving/deleting
        if (
            account?.status === BankAccountStatus.CLOSED
            || account?.status === BankAccountStatus.CLOSING
        ) {
            // If there are transactions call for archiving, otherwise delete
            const shouldArchive = transactionIds?.length > 0 && parseFloat(account?.availableBalance) === 0;
            if (shouldArchive) {
                dispatch(BankActions.archiveIntegrationAccount({integration: integration}));
            } else {
                dispatch(BankActions.deleteIntegrationAccount({id: integration?.id}));
            }
        } else {
            dispatch(BankActions.closeBankAccount({integration: integration}));
        }
    };

    return (
        <ModalWrapper
            containerSx={{width: '640px'}}
            isV3
            modalKey={modalKey}
            title={(
                <Typography level="h1">
                    {t('accountArchiving.closeTitle')}
                </Typography>
            )}
            buttons={({handleClose}) => (
                <>
                    <Button
                        fullWidth
                        startDecorator={(
                            <FontAwesomeIcon icon={faArrowLeft} />
                        )}
                        onClick={() => {
                            handleClose();
                        }}
                        variant="outlined"
                        color="primary"
                        size="lg"
                    >
                        {t('accountArchiving.backButton')}
                    </Button>

                    <Button
                        loading={isDeleting}
                        fullWidth
                        startDecorator={(
                            <FontAwesomeIcon icon={faCircleXmark} />
                        )}
                        loadingPosition="start"
                        onClick={handleClick}
                        color="danger"
                        variant="soft"
                        size="lg"
                    >
                        {t('archivingActions.close')}
                    </Button>
                </>
            )}
        >
            <Typography level="body-lg" sx={{mb: 1, color: 'text.secondary'}}>
                {t('accountArchiving.closeInfoText1')}
            </Typography>

            <Typography level="body-lg" sx={{mb: 2, color: 'text.secondary'}}>
                {t('accountArchiving.closeInfoText2')}
            </Typography>

            <AlertInfo>
                {t('accountArchiving.closeAlertText')}
            </AlertInfo>
        </ModalWrapper>
    );
};

export default CloseHiwayIntegrationModal;
