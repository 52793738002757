import PropTypes from 'prop-types';
import React from 'react';

import DocumentStatus from '../../../../components/ui-kit/DocumentStatus/DocumentStatus';
import {DOCUMENT_STATUSES} from '../../../../consts/document-constants';

const DocumentStatusCell = ({rowData}) => {
    return <DocumentStatus status={rowData.status} />;
};

DocumentStatusCell.propTypes = {
    rowData: PropTypes.shape({
        status: PropTypes.oneOf(Object.values(DOCUMENT_STATUSES)),
    }).isRequired,
};

export default DocumentStatusCell;
