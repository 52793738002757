import LocationOnIcon from '@mui/icons-material/LocationOn';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {LocalesConstants} from '../../../../utils/locales-constants';

const Location = ({rowData}) => {
    const {t} = useTranslation(LocalesConstants.Opportunity);
    const {jobLocation, remoteJobType} = rowData;

    return (
        <Box>
            {jobLocation && (
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
                >
                    <LocationOnIcon sx={{
                        color: 'v2.blueGray.100',
                        fontSize: theme => theme.typography.pxToRem(16),
                        mr: 1,
                    }}
                    /> {jobLocation}
                </Box>
            )}

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
            }}
            >
                <TimelapseIcon sx={{
                    color: 'v2.blueGray.100',
                    fontSize: theme => theme.typography.pxToRem(16),
                    mr: 1,
                }}
                /> {t(`submitOpportunity.${remoteJobType?.toLowerCase()}`)}
            </Box>
        </Box>
    );
};

Location.propTypes = {
    rowData: PropTypes.any.isRequired,
};

export default React.memo(Location);
