import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useExpandingContainerStyles} from './styles';
import {LocalesConstants} from '../../../utils/locales-constants';

const ExpandingContainer = ({title, complexTitle, actions, isInitiallyExpanded, elevation, sxOverride, children}) => {
    const {t} = useTranslation(LocalesConstants.Common);

    const styles = useExpandingContainerStyles();

    const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded);

    const handleClick = () => {
        setIsExpanded(previousState => !previousState);
    };

    return (
        <Paper elevation={elevation} className={styles.paper} sx={sxOverride}>
            <Box display="flex" alignItems="center" className={styles.titleParent}>
                <Box display="flex" flexGrow={1}>
                    {complexTitle}
                    {!complexTitle && (
                        <Typography variant="h5" className={styles.title}>
                            {title}
                        </Typography>
                    )}
                </Box>

                <Box display="flex">
                    <IconButton
                        aria-label={isExpanded ? t('collapse') : t('expand')}
                        onClick={handleClick}
                        size="large"
                    >
                        {isExpanded ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
                    </IconButton>
                </Box>
            </Box>

            <Collapse in={isExpanded}>
                <Grid container direction="column" wrap="nowrap">
                    {children}

                    {!!actions && (
                        <Box display="block" className={styles.actionsParent}>
                            {actions}
                        </Box>
                    )}
                </Grid>
            </Collapse>
        </Paper>
    );
};

ExpandingContainer.defaultProps = {
    title: '',
    actions: null,
    isInitiallyExpanded: true,
    complexTitle: null,
    sxOverride: {},
    elevation: 1,
};

ExpandingContainer.propTypes = {
    title: PropTypes.string,
    actions: PropTypes.any,
    isInitiallyExpanded: PropTypes.bool,
    complexTitle: PropTypes.node,
    sxOverride: PropTypes.object,
    elevation: PropTypes.number,
};

export default ExpandingContainer;
