import {
    faInfoCircle,
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button, Chip, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import useMediaQuery from '../../../../../../components/joy-ui/mui-legacy/useMediaQuery';
import {useIsMobileSize} from '../../../../../../hooks/use-is-mobile-size';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {USER_ROLES} from '../../../../../../utils/user-roles';
import {UiActions} from '../../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../../ui/utils/constants';
import {LoggedInUserSelectors} from '../../../../../user/modules/logged-in-user';
import {MAX_NUMBER_OF_BANKS} from '../../../bridge-api/utils/constants';

export const AddBankAccountActions = ({
    isHiwayBankAddAllowed,
    onClickHiwayAdd,
    isBankAddAllowed,
    onClickAdd,
    handleClose,
}) => {
    const {t} = useTranslation(LocalesConstants.Bank);
    const dispatch = useDispatch();
    const isMobileSize = useIsMobileSize();
    const isDesktopSmall = useMediaQuery('(max-width: 1250px)');
    const isMobileSmall = useMediaQuery('(max-width: 500px)');
    const isHiddenRecommended = (isDesktopSmall && !isMobileSize)
        || (isMobileSmall && isMobileSize);

    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const isAdmin = loggedInUser.role === USER_ROLES.ADMINISTRATOR;

    const handleAddBankAccount = () => {
        if (isBankAddAllowed) {
            onClickAdd();
            if (handleClose) {
                handleClose();
            }
        } else {
            dispatch(UiActions.setActiveModal(ModalsKeys.BANK_ACCOUNT_LIMIT, true));
        }
    };

    return (
        <>
            {isHiwayBankAddAllowed && (
                <Button
                    color="primary"
                    variant="solid"
                    fullWidth
                    startDecorator={(
                        <FontAwesomeIcon icon={faPlus} />
                    )}
                    endDecorator={isHiddenRecommended ? null : (
                        <Chip
                            color="danger"
                            size="sm"
                            variant="solid"
                        >
                            {t('connectProPage.recommended')}
                        </Chip>
                    )}
                    onClick={() => {
                        onClickHiwayAdd();
                        if (handleClose) {
                            handleClose();
                        }
                    }}
                    size={isMobileSmall ? 'sm' : 'lg'}
                >
                    {t('creationProPage.addHiwayPro')}
                </Button>
            )}
            {(isBankAddAllowed || isMobileSize) && (
                <Button
                    color="primary"
                    variant="outlined"
                    fullWidth
                    startDecorator={(
                        <FontAwesomeIcon icon={faPlus} />
                    )}
                    onClick={handleAddBankAccount}
                    size={isMobileSmall ? 'sm' : 'lg'}
                >
                    {t('creationProPage.addButtonTitle')}
                </Button>
            )}
            {!isBankAddAllowed && !isMobileSize && !isAdmin && (
                <Alert
                    color="danger"
                    variant="outlined"
                    startDecorator={(
                        <Typography textColor="text.icon">
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </Typography>
                    )}
                >
                    <Typography textColor="primary.outlinedColor">
                        {t('bankAccountLimitModal.content', {maxBankAccounts: MAX_NUMBER_OF_BANKS})}
                    </Typography>
                </Alert>
            )}
        </>
    );
};

AddBankAccountActions.propTypes = {
    isHiwayBankAddAllowed: PropTypes.bool.isRequired,
    onClickHiwayAdd: PropTypes.func.isRequired,
    isBankAddAllowed: PropTypes.bool.isRequired,
    onClickAdd: PropTypes.func.isRequired,
    handleClose: PropTypes.func,
};

AddBankAccountActions.defaultProps = {
    handleClose: null,
};
