import {faArrowRight, faCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Stack, Typography} from '@mui/joy';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import JoyUIThemeProvider from '../../../../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {useIsMobileSize} from '../../../../../hooks/use-is-mobile-size';
import {push} from '../../../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {LocalesConstants} from '../../../../../utils/locales-constants';
import {MissionsSelector} from '../../../store/missions.selector';

const CraSend = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation(LocalesConstants.Missions);
    const isMobileSize = useIsMobileSize();

    const mission = useSelector(MissionsSelector.selectMission);

    const onButtonClick = () => {
        dispatch(push(RoutePaths.MISSIONS));
    };

    return (
        <JoyUIThemeProvider>
            <Stack
                sx={{
                    height: '100%',
                }}
                justifyContent="center"
                alignItems="center"
            >
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={isMobileSize ? {
                        height: 'calc(100vh - 100px)',
                        px: 4,
                    } : {
                        borderRadius: 'xxl',
                        backgroundColor: 'background.body',
                        boxShadow: 'lg',
                        minWidth: 'calc(212px + 128px)',
                        maxWidth: '700px',
                        px: 8,
                        pt: 4,
                        pb: 5,
                    }}
                    gap={2}
                >
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            width: '133px',
                            height: '133px',
                            borderRadius: '50%',
                            backgroundColor: 'success.100',
                            color: 'success.softActiveColor',
                            mb: 1,
                        }}
                    >
                        <FontAwesomeIcon
                            style={{
                                fontSize: '88px', // Set the custom size here
                            }}
                            icon={faCheck}
                        />
                    </Stack>
                    <Typography
                        level="h1"
                        textAlign="center"
                    >
                        {t('cra.sentTitle')}
                    </Typography>
                    <Typography textAlign="center">
                        {t('cra.sentText1', {email: mission?.client?.email})}
                        {t('cra.sentText2')}
                    </Typography>

                    <Button
                        onClick={onButtonClick}
                        endDecorator={(
                            <FontAwesomeIcon
                                icon={faArrowRight}
                            />
                          )}
                    >
                        {t('cra.sentButton')}
                    </Button>
                </Stack>
            </Stack>
        </JoyUIThemeProvider>
    );
};

export default CraSend;
