import moment from 'moment';
import DocumentNameCell from './DocumentNameCell/DocumentNameCell';
import DocumentRowActions from './DocumentRowActions';

export const getDocumentsTableConfig = translate => ([
    {
        key: 'docName',
        title: translate('documentsDatabaseTable.document'),
        isSortable: true,
        width: '45%',
        component: DocumentNameCell,
    },
    {
        key: 'dateCreated',
        title: translate('documentsDatabaseTable.date'),
        isSortable: true,
        width: '20%',
        componentProps: {
            formatter: rowData => moment(rowData.dateCreated).format('DD/MM/YYYY'),
        },
    },
    {
        key: 'category',
        title: translate('documentsDatabaseTable.category'),
        isSortable: true,
        width: '20%',
        componentProps: {
            formatter: rowData => translate(`documentsDatabaseCategories.${rowData.category}`),
        },
    },
    {
        key: 'actions',
        title: translate('documentsDatabaseTable.actions'),
        isSortable: false,
        additionalCellProps: {
            align: 'right',
        },
        shouldPreserveWhiteSpace: true,
        component: DocumentRowActions,
    },
]);
