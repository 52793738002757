export const SET_IS_LOADING_COMPANY_DASHBOARD_DATA = 'company-dashboard/SET_IS_LOADING_COMPANY_DASHBOARD_DATA';
export const STORE_COMPANY_DASHBOARD_DATA = 'company-dashboard/STORE_COMPANY_DASHBOARD_DATA';
export const GET_COMPANY_DASHBOARD_DATA = 'company-dashboard/GET_COMPANY_DASHBOARD_DATA';

export const setIsLoadingCompanyDashboardData = isLoading => ({
    type: SET_IS_LOADING_COMPANY_DASHBOARD_DATA,
    payload: isLoading,
});

export const storeCompanyDashboardData = dashboardData => ({
    type: STORE_COMPANY_DASHBOARD_DATA,
    payload: dashboardData,
});

export const getCompanyDashboardData = (freelancerId, companyId, year) => ({
    type: GET_COMPANY_DASHBOARD_DATA,
    payload: {
        freelancerId,
        companyId,
        year,
    },
});
