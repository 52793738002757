import {combineReducers} from 'redux';
import {assignFreelancersFormReducer} from './assign-freelancers-form/assign-freelancers-form.reducer';
import {assignedFreelancersReducer} from './assigned-freelancers/assigned-freelancers.reducer';
import {availableFreelancersReducer} from './available-freelancers/available-freelancers.reducer';
import {changePasswordFormReducer} from './change-password-form/change-password-form.reducer';
import {coachingInformationReducer} from './coaching-information/coaching-information.reducer';
import * as actions from './user.actions';
import {createReducer} from '../../../app/utils/store/create-reducer';
import {LOCATION_CHANGED} from '../../../lib/router/connected-router-saga';
import {MissionClientsActionType} from '../../missions/modules/client-side/store/missions-client.action-type';
import {userListReducer} from '../../user-list/store/userList.reducer';
import {isUserUpdatingReducer, loggedInUserDashboardReducer, loggedInUserReducer} from '../modules/logged-in-user/store/logged-in-user.reducer';
import {userSelectReducer} from '../modules/user-select/store/user-select.reducer';

const singleUserReducer = (state = null, action) => {
    if (action.type === actions.STORE_USER) {
        return action.payload;
    }

    // Reset to initial state when user leaves the view.
    if (action.type === LOCATION_CHANGED && !action.payload.location.pathname.includes(state?.id)) {
        return null;
    }

    return state;
};

const isLoadingUserReducer = (state = true, action) => {
    if (action.type === actions.SET_IS_LOADING_USER) {
        return action.payload;
    }

    // Reset to initial state when user leaves the view.
    if (action.type === LOCATION_CHANGED && !action.payload.location.pathname.includes('/users')) {
        return true;
    }

    return state;
};

export const userReducer = combineReducers({
    assignedFreelancers: assignedFreelancersReducer,
    assignFreelancersForm: assignFreelancersFormReducer,
    coachingInformation: coachingInformationReducer,
    availableFreelancers: availableFreelancersReducer,
    changePasswordForm: changePasswordFormReducer,
    coachAccounts: createReducer([], actions.STORE_COACH_ACCOUNTS),
    isLoadingLoggedInUser: createReducer(true, actions.SET_IS_LOADING_LOGGED_IN_USER, true),
    isLoadingUser: isLoadingUserReducer,
    isUpdatingCanSetupCompany: createReducer(false, actions.SET_IS_UPDATING_CAN_SETUP_COMPANY),
    adminMustEnterDataBeforeSetupCompany: createReducer(false, actions.SET_ADMIN_MUST_ENTER_DATA_BEFORE_SETUP_COMPANY),
    isUpdatingLoggedInUser: createReducer(false, actions.SET_IS_UPDATING_LOGGED_IN_USER),
    isUpdatingUser: createReducer(false, actions.SET_IS_UPDATING_USER),
    isUpdatingUserActiveStatus: createReducer(false, actions.SET_IS_UPDATING_USER_ACTIVE_STATUS),
    // loggedInUser: createReducer(null, actions.STORE_LOGGED_IN_USER),
    loggedInUser: loggedInUserReducer, // v1 to v2
    singleUser: singleUserReducer,
    userList: userListReducer,
    userSelect: userSelectReducer,
    loggedInUserDashboard: loggedInUserDashboardReducer,
    isUserUpdating: isUserUpdatingReducer,
    isPublicUser: createReducer(false, MissionClientsActionType.STORE_IS_PUBLIC_USER, false),
});

// import {combineReducers} from 'redux';
// import {createReducer} from '../../../app/utils/store/create-reducer';
// import {MissionClientsActionType} from '../../missions/modules/client-side/store/missions-client.action-type';
// import {loggedInUserReducer} from '../modules/logged-in-user';
// import {
//     isUserUpdatingReducer,
//     loggedInUserDashboardReducer,
// } from '../modules/logged-in-user/store/logged-in-user.reducer';
// import {userSelectReducer} from '../modules/user-select/store/user-select.reducer';

// export const userReducer = combineReducers({
//     loggedInUser: loggedInUserReducer,
// });
