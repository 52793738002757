import {safeTransformData} from '../../../../../app/api/axios';
import {publicClientApiInstance} from '../../../../../lib/api-providers/public-client-api-instance';

const getDocumentInfo = ({
    missionDocumentId,
    documentType,
    missionId,
}) => {
    return publicClientApiInstance({
        method: 'GET',
        params: {
            missionDocumentId,
            documentType,
        },
        url: `/api/v2/missions-client/${missionId}/document-info`,
    }).then(response => safeTransformData(response));
};

const getClientSignature = ({missionId}) => {
    return publicClientApiInstance({
        method: 'GET',
        url: `/api/v2/missions-client/${missionId}/client-signature`,
    }).then(response => safeTransformData(response));
};

const postClientSignature = ({missionId, file}) => {
    const formData = new FormData();

    formData.append('file', file);

    return publicClientApiInstance({
        method: 'POST',
        url: `/api/v2/missions-client/${missionId}/client-signature`,
        data: formData,
        header: {
            'Content-Type': 'multipart/form-data',
        },
    }).then(response => safeTransformData(response));
};

const postSignDocument = ({
    missionDocumentId,
    documentType,
    missionId,
}) => {
    return publicClientApiInstance({
        method: 'POST',
        // BE Created this as query param
        params: {
            missionDocumentId,
            documentType,
        },
        url: `/api/v2/missions-client/${missionId}/sign-document`,
    }).then(response => safeTransformData(response));
};

const postValidateDocument = ({
    missionDocumentId,
    documentType,
    missionId,
}) => {
    return publicClientApiInstance({
        method: 'POST',
        params: {
            missionDocumentId,
            documentType,
        },
        url: `/api/v2/missions-client/${missionId}/validate-document`,
    }).then(response => safeTransformData(response));
};

const postRequireModification = ({
    missionDocumentId,
    documentType,
    missionId,
    comment,
}) => {
    return publicClientApiInstance({
        method: 'POST',
        params: {
            missionDocumentId,
            documentType,
        },
        data: {
            comment,
        },
        url: `/api/v2/missions-client/${missionId}/request-modification`,
    }).then(response => safeTransformData(response));
};

export const MissionClientApi = {
    getDocumentInfo,
    getClientSignature,
    postClientSignature,
    postSignDocument,
    postValidateDocument,
    postRequireModification,
};
