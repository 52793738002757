import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {LoadingButton} from '@mui/lab';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {CompanyRegistrationInternalSubSteps} from '../../../../../freelancer/modules/company-registration/utils/constants';
import {CompanySetupSubSteps} from '../../../../../freelancer/modules/company-setup/utils/constants';
import {OnboardingActions} from '../../../../../freelancer/modules/onboarding/store/onboarding.action';
import {OnboardingSteps} from '../../../../../freelancer/modules/onboarding/utils/onboadingStepsConstant';
import {SigningSelectors} from '../../store/signing.selector';
import {DocumentSigningWrapper} from '../DocumentSigningWrapper';

export const DocumentsSigningOnboardingWrapper = ({
    step,
    type,
    isBackButtonVisible,
    MainActionComponent,
}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation(LocalesConstants.Document);

    const isSigningAdditionalDocuments = useSelector(SigningSelectors.selectIsSigningAdditionalDocuments);

    const goToDocumentsClick = () => {
        if (step !== OnboardingSteps.COMPANY_REGISTRATION) {
            dispatch(OnboardingActions.setInternalSubStep(CompanySetupSubSteps.DOCUMENT_GENERATION));
            dispatch(OnboardingActions.setSubStep(CompanySetupSubSteps.DOCUMENT_GENERATION));
        } else {
            dispatch(OnboardingActions.setInternalSubStep(CompanyRegistrationInternalSubSteps.FILE_LIST));
            dispatch(OnboardingActions.setSubStep(CompanyRegistrationInternalSubSteps.FILE_LIST));
        }
    };

    const hasDocumentsLink = !isSigningAdditionalDocuments && isBackButtonVisible;
    const DocumentsLinkComponent = hasDocumentsLink ? (
        <LoadingButton
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={goToDocumentsClick}
            color="secondary"
            size="small"
            sx={{mr: 2, py: '3px', borderRadius: '6px'}}
        >
            {t('signing.subTextButton')}
        </LoadingButton>
    ) : null;

    return (
        <DocumentSigningWrapper
            type={type}
            MainActionComponent={MainActionComponent}
            DocumentsLinkComponent={DocumentsLinkComponent}
        />
    );
};

DocumentsSigningOnboardingWrapper.propTypes = {
    step: PropTypes.string,
    type: PropTypes.string,
    isBackButtonVisible: PropTypes.bool,
    MainActionComponent: PropTypes.object,
};

DocumentsSigningOnboardingWrapper.defaultProps = {
    step: OnboardingSteps.COMPANY_SETUP,
    type: undefined,
    isBackButtonVisible: true,
    MainActionComponent: null,
};
