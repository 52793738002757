import {call} from 'redux-saga/effects';

import {Toast} from '../../../lib/toast';
import * as errors from '../../errors';

export const safe = (saga, handler, ...args) => (function* (action) {
    try {
        yield call(saga, ...args, action);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error({error});

        if (handler) {
            yield call(handler, error);

            return;
        }

        if (error instanceof errors.AccessDeniedError) {
            return;
        } else if (error instanceof errors.MaintenanceError) {
            return;
        } else if (error instanceof errors.UnauthorizedError) {
            return;
        }

        Toast.error('anErrorOccurred');
    }
});

export const actionParamMapper = (saga, paramKeys) => (function* (action) {
    const params = paramKeys.map(paramKey => action?.payload[paramKey]);

    yield call(saga, ...params);
});
