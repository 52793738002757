import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CoffeeIcon from '@mui/icons-material/Coffee';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {EuroIcon} from '../../../../../../assets/wrapped-svg/euro';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {employmentSituationOptions} from '../../utils/employmentSituationOptions';
import {EurField} from '../EurField';
import {FieldBlock} from '../FieldBlock';
import {FormSection} from '../FormSection';
import {FormSectionRow} from '../FormSectionRow';
import {SelectField} from '../SelectField';
import {ToggleField} from '../ToggleField';

export const MySituationStep3 = () => {
    const {t} = useTranslation(LocalesConstants.RevenueSimulator);
    const {watch} = useFormContext();

    const hasOtherRevenue = watch('professionalInfo.spouseHasOtherRevenue');

    return (
        <>
            <FormSection Icon={BusinessCenterIcon} title={t('professionalInfo.spouseProfessionalSituation')}>
                <FormSectionRow>
                    <FieldBlock
                        Icon={PeopleAltOutlinedIcon}
                        title={t('professionalInfo.spouseEmploymentSituation.title')}
                        subtitle={t('professionalInfo.spouseEmploymentSituation.subtitle')}
                    >
                        <SelectField
                            required
                            name="professionalInfo.spouseEmploymentSituation"
                            options={employmentSituationOptions}
                            label={t('professionalInfo.spouseEmploymentSituation.placeholder')}
                        />
                    </FieldBlock>
                    <FieldBlock
                        Icon={EuroIcon}
                        title={t('professionalInfo.spouseMonthlyNetIncome.title')}
                        subtitle={t('professionalInfo.spouseMonthlyNetIncome.subtitle')}
                    >
                        <EurField defaultValue={0} name="professionalInfo.spouseMonthlyNetIncome" decimalScale={2} />
                    </FieldBlock>
                </FormSectionRow>
            </FormSection>

            <FormSection Icon={BusinessCenterIcon} title={t('professionalInfo.spouseOtherRevenue.sectionTitle')}>
                <FormSectionRow>
                    <FieldBlock
                        Icon={EuroIcon}
                        variant="horizontal"
                        title={t('professionalInfo.spouseOtherRevenue.title')}
                        subtitle={t('professionalInfo.spouseOtherRevenue.subtitle')}
                    >
                        <ToggleField
                            name="professionalInfo.spouseHasOtherRevenue"
                            fullWidth
                            validate={value => value !== undefined || t('requiredFieldError')}
                            options={[
                                {label: t('yes'), value: true, icon: CheckIcon},
                                {label: t('no'), value: false, icon: CloseIcon},
                            ]}
                        />
                    </FieldBlock>
                </FormSectionRow>
                <FormSectionRow isHidden={!hasOtherRevenue}>
                    <FieldBlock
                        Icon={MedicalServicesIcon}
                        variant="compact"
                        title={t('professionalInfo.spouseOtherRevenue.meBIC')}
                    >
                        <EurField name="professionalInfo.spouseOtherRevenue.meBIC" defaultValue={0} decimalScale={2} />
                    </FieldBlock>
                    <FieldBlock
                        Icon={HealthAndSafetyIcon}
                        variant="compact"
                        title={t('professionalInfo.spouseOtherRevenue.meBNC')}
                    >
                        <EurField name="professionalInfo.spouseOtherRevenue.meBNC" defaultValue={0} decimalScale={2} />
                    </FieldBlock>
                    <FieldBlock
                        Icon={CoffeeIcon}
                        variant="compact"
                        title={t('professionalInfo.spouseOtherRevenue.MEPurchaseOrResale')}
                    >
                        <EurField name="professionalInfo.spouseOtherRevenue.MEPurchaseOrResale" defaultValue={0} decimalScale={2} />
                    </FieldBlock>
                </FormSectionRow>
                <FormSectionRow isHidden={!hasOtherRevenue}>
                    <FieldBlock
                        Icon={MedicalServicesIcon}
                        variant="compact"
                        title={t('professionalInfo.spouseOtherRevenue.spouseTravelAllowances')}
                    >
                        <EurField name="professionalInfo.spouseOtherRevenue.spouseTravelAllowances" defaultValue={0} />
                    </FieldBlock>
                    <FieldBlock
                        Icon={CoffeeIcon}
                        variant="compact"
                        title={t('professionalInfo.spouseOtherRevenue.managerIncome')}
                    >
                        <EurField name="professionalInfo.spouseOtherRevenue.managerIncome" defaultValue={0} decimalScale={2} />
                    </FieldBlock>
                </FormSectionRow>
            </FormSection>
        </>
    );
};
