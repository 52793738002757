import {all, call, fork, put, select, take, takeLatest} from 'redux-saga/effects';

import {watchContractDataSagas} from './contract/contract.sagas';
import {FreelancerActionTypes} from './freelancer.action-type';
import {FreelancerActions, getFreelancerCompanies} from './freelancer.actions';
import {selectFreelancerAccount} from './freelancer.selectors';
import {
    getFreelancerAccountRequest,
    getFreelancerCompaniesRequest,
} from '../../../app/api/providers/freelancer/freelancer.provider';
import {getConfig} from '../../../config/get-config';
import {push} from '../../../lib/router/connected-router-saga';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import {selectCurrentCognitoUser} from '../../auth/store/auth.selectors';
import {contractSaga} from '../../contract/store/contract.saga';
import {LoadingActions, LoadingTypes} from '../../loading';
import {FreelancerApi} from '../api/freelancer.api';
import {companiesSaga} from '../modules/companies';
import {contractSigningSaga} from '../modules/contract-signing/store/contract-signing.saga';
// eslint-disable-next-line import/no-cycle
import {onboardingSaga} from '../modules/onboarding/store/onboarding.saga';
// eslint-disable-next-line import/no-cycle
import {workshopsSaga} from '../modules/workshops/store/workshops.saga';

const getFreelancerAccountSaga = function* getFreelancerAccountSaga({payload}) {
    yield put(FreelancerActions.setIsLoadingFreelancerAccount(true));

    try {
        const freelancerAccount = yield call(getFreelancerAccountRequest, payload);

        yield put(FreelancerActions.storeFreelancerAccount(freelancerAccount));
    } catch (error) {
        if (error?.response?.status === 404) {
            Toast.error('canNotSetupCompany');

            yield put(push(getConfig().ROUTE_PATHS.DASHBOARD));

            return;
        }

        // TODO:LOW Better error handling.
        Toast.error('anErrorOccurred');
    } finally {
        yield put(FreelancerActions.setIsLoadingFreelancerAccount(false));
    }
};

const getFreelancerCompaniesSaga = function* getFreelancerCompaniesSaga({payload}) {
    yield put(FreelancerActions.setIsLoadingFreelancerCompanies(true));

    try {
        const {freelancerId} = payload;

        const companies = yield call(getFreelancerCompaniesRequest, freelancerId);

        yield put(FreelancerActions.storeFreelancerCompanies(companies));
    } catch (error) {
        // TODO:LOW Better error handling.
        Toast.error('anErrorOccurred');
    } finally {
        yield put(FreelancerActions.setIsLoadingFreelancerCompanies(false));
    }
};

export const loadFreelancerAccountSaga = function* loadFreelancerAccountSaga(freelancerId) {
    const freelancerAccount = yield select(selectFreelancerAccount);

    if (!freelancerAccount || freelancerAccount.id !== freelancerId) {
        const account = yield put(FreelancerActions.getFreelancerAccount(freelancerId));
        yield put(FreelancerActions.storeFreelancerAccount(account));
    }
};

export const loadCompaniesForCurrentUser = function* () {
    const currentCognitoUser = yield select(selectCurrentCognitoUser);

    if (!currentCognitoUser || !currentCognitoUser.id) {
        return;
    }

    yield put(getFreelancerCompanies(currentCognitoUser.id));

    yield take(FreelancerActionTypes.STORE_FREELANCER_COMPANIES);
};

const getAccountFlow = function* ({freelancerId}) {
    try {
        const data = yield call(FreelancerApi.getAccount, {freelancerId});

        yield put(FreelancerActions.storeFreelancerAccount(data));
    } catch (error) {
        Debug.error('freelancer', error.message, {error});
    }
};

export const loadFreelancerAccount = function* ({freelancerId}) {
    yield put(LoadingActions.setLoading(LoadingTypes.FREELANCER_ACCOUNT, true));

    yield call(getAccountFlow, {freelancerId});

    yield put(LoadingActions.setLoading(LoadingTypes.FREELANCER_ACCOUNT, false));
};

export const watchFreelancerSagas = function* watchFreelancerSagas() {
    yield all([
        fork(watchContractDataSagas),
        fork(companiesSaga),
        fork(onboardingSaga),
        fork(contractSigningSaga),
        fork(workshopsSaga),
        fork(contractSaga),
        takeLatest(FreelancerActionTypes.GET_FREELANCER_ACCOUNT, getFreelancerAccountSaga),
        takeLatest(FreelancerActionTypes.GET_FREELANCER_COMPANIES, getFreelancerCompaniesSaga),
    ]);
};
