import {
    faCalendarDays,
    faListCheck,
    faReceipt,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    Badge,
    Button,
    Stack,
    Typography,
} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {AccountBalanceSearch} from './AccountBalanceSearch';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {FILTER_ANCHORS, IN_HEADER} from '../../util/constants';

export const AccountBalanceFilter = ({
    filter,
    setFilter,
    setAnchorEl,
    isInHeader,
    anchorEl,
}) => {
    const {t} = useTranslation(LocalesConstants.Bank);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const selectedButtonId = anchorEl?.id;
    const datesButtonId = `${FILTER_ANCHORS.DATES}${isInHeader ? `-${IN_HEADER}` : ''}`;
    const nonCategorizedButtonId = `${FILTER_ANCHORS.NON_CATEGIROZED}${isInHeader ? `-${IN_HEADER}` : ''}`;
    const typesButtonId = `${FILTER_ANCHORS.TYPE}${isInHeader ? `-${IN_HEADER}` : ''}`;

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
            sx={{pb: isInHeader ? 0 : 2, flexGrow: 1}}
        >
            <AccountBalanceSearch
                filter={filter}
                setFilter={setFilter}
                searchPlaceholder={t('accountBalanceFilter.searchPlaceholder')}
            />

            <Typography
                level="body-md"
                sx={{
                    color: 'text.tertiary',
                }}
            >
                {t('accountBalanceFilter.filter')}
            </Typography>

            <AccountBalanceFilterButton
                id={datesButtonId}
                isSelected={selectedButtonId === datesButtonId}
                hasFilter={filter.startDate || filter.endDate}
                label={t('accountBalanceFilter.dates')}
                handleClick={handleClick}
                icon={faCalendarDays}
                isInHeader={isInHeader}
            />

            <AccountBalanceFilterButton
                id={nonCategorizedButtonId}
                isSelected={selectedButtonId === nonCategorizedButtonId}
                hasFilter={filter.isNotCategorized}
                label={t('accountBalanceFilter.nonCategorized')}
                handleClick={handleClick}
                icon={faListCheck}
                isInHeader={isInHeader}
            />

            <AccountBalanceFilterButton
                id={typesButtonId}
                isSelected={selectedButtonId === typesButtonId}
                hasFilter={filter?.transactionTypes?.length !== 0}
                label={t('accountBalanceFilter.types')}
                handleClick={handleClick}
                icon={faReceipt}
                isInHeader={isInHeader}
            />
        </Stack>
    );
};

AccountBalanceFilter.propTypes = {
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
    setAnchorEl: PropTypes.func.isRequired,
    isInHeader: PropTypes.bool.isRequired,
    anchorEl: PropTypes.object,
};

AccountBalanceFilter.defaultProps = {
    anchorEl: {},
};

const AccountBalanceFilterButton = ({
    id,
    isSelected,
    hasFilter,
    label,
    handleClick,
    icon,
    isInHeader,
}) => (
    <Badge
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        color="primary"
        invisible={!hasFilter}
        sx={{
            '& > .MuiBadge-badge': {
                top: '2px',
                right: '2px',
            },
        }}
    >
        <Button
            id={id}
            size="sm"
            variant={isSelected || hasFilter ? (
                isInHeader ? 'outlined' : 'solid'
            ) : (
                isInHeader ? 'solid' : 'outlined'
            )}
            color="neutral"
            sx={{
                border: '1px solid',
                borderColor: 'neutral.outlinedDisabledBorder',
                ...(isInHeader ? {
                    backgroundColor: isSelected || hasFilter
                        ? 'background.body'
                        : 'common.black',
                } : {
                    backgroundColor: isSelected || hasFilter
                        ? 'neutral.softActiveColor'
                        : 'background.body',
                }),
            }}
            onClick={handleClick}
            startDecorator={(
                <FontAwesomeIcon icon={icon} />
            )}
        >
            {label}
        </Button>
    </Badge>
);

AccountBalanceFilterButton.propTypes = {
    id: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    isSelected: PropTypes.bool,
    label: PropTypes.string.isRequired,
    hasFilter: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    icon: PropTypes.any.isRequired,
    isInHeader: PropTypes.bool.isRequired,
};

AccountBalanceFilterButton.defaultProps = {
    isSelected: false,
    hasFilter: false,
};
