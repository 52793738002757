import {Card, Chip, Grid, Typography, useMediaQuery} from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {LocalesConstants} from '../../../../utils/locales-constants';

const SelectedSpecializations = props => {
    const {jobProfession, specialization, onItemsUpdate} = props;
    const {t: tSpec} = useTranslation(LocalesConstants.JobProfessions);
    const {t: tUsers} = useTranslation(LocalesConstants.Users);

    const toggleItemSelection = data => {
        const index = specialization.findIndex(item => item.name === data);
        const targetItem = specialization.splice(index, 1)[0];
        specialization.push({...targetItem, selected: !targetItem.selected});
        onItemsUpdate(jobProfession, specialization);
    };

    const isMobileSize = useMediaQuery(`(max-width:899px)`);
    const noSpecializationsSelected = !specialization?.find(item => item.selected);
    const jobHasNoSpecializations = !specialization.length;

    return (
        <Grid item container md={5} xs={12}>
            <Card
                sx={{width: 1,
                    minHeight: 58,
                    lineHeight: theme => theme.typography.pxToRem(24),
                    py: 1.5,
                    px: 1.5,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderStyle: jobHasNoSpecializations ? 'dotted' : 'solid'}}
                variant="outlined"
            >
                {noSpecializationsSelected && (
                    <Typography sx={{color: jobHasNoSpecializations ? 'text.disabled' : 'elephant.main'}}>
                        {tUsers('professionAndSpecialization.specialities')}
                    </Typography>
                )}
                <Grid container spacing={1}>
                    {specialization
                        .filter(item => item.selected)
                        .map(data => (
                            <Grid key={data.name} item xs="auto">
                                <Chip
                                    disableRipple
                                    sx={[{
                                        bgcolor: 'primary.light',
                                        color: '#FFFFFF',
                                        p: 0,
                                        lineHeight: '18px',
                                        fontSize: '13px',
                                        fontWeight: '400',
                                    }, {
                                        '& .MuiChip-deleteIcon': {color: 'rgba(255, 255, 255, 0.7)'},
                                    }]}
                                    label={tSpec(`specializations.${data.name}`)}
                                    onDelete={() => toggleItemSelection(data.name)}
                                />
                            </Grid>
                        ))}
                </Grid>
            </Card>
            <Box
                sx={{pt: '12px'}}
            >
                <Grid container spacing={1}>
                    {specialization
                        .filter(item => !item.selected)
                        .map(data => (
                            <Grid key={data.name} item xs="auto">
                                <Chip
                                    disableRipple
                                    size={isMobileSize ? 'medium' : 'small'}
                                    variant="outlined"
                                    label={tSpec(`specializations.${data.name}`)}
                                    onClick={() => toggleItemSelection(data.name)}
                                />
                            </Grid>
                        ))}
                </Grid>
            </Box>
        </Grid>
    );
};

SelectedSpecializations.propTypes = {
    jobProfession: PropTypes.string.isRequired,
    specialization: PropTypes.array.isRequired,
    onItemsUpdate: PropTypes.func.isRequired,
};

export default SelectedSpecializations;
