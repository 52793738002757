import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';

const TabState = {
    Loading: 'tab-state/loading',
    Success: 'tab-state/success',
    Failed: 'tab-state/failed',
    Empty: 'tab-state/empty',
};

const navTabsContext = React.createContext({
    tabs: [],
    setTabs: _tabs => void 0,
    activeTab: '',
    setActiveTab: _ => void 0,
    setTabState: (_id, _state) => void 0,
    tabStates: {},
});

const TabsProvider = ({children, initTabs}) => {
    const [activeTab, setActiveTab] = useState();
    const [tabs, setTabs] = useState(initTabs);
    const [tabStates, setTabStates] = useState({});

    const setTabState = useCallback((tabId, tabState) => {
        setTabStates(states => {
            const newStates = {...states};
            newStates[tabId] = tabState;
            return newStates;
        });
    }, []);

    return (
        <navTabsContext.Provider
            value={{
                tabs,
                setTabs,
                tabStates,
                setTabState,
                activeTab,
                setActiveTab,
            }}
        >
            {children}
        </navTabsContext.Provider>
    );
};

TabsProvider.propTypes = {
    initTabs: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const useTabs = () => React.useContext(navTabsContext);

export {
    TabState,
    TabsProvider,
    useTabs,
};
