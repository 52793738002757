import {
    faClipboardList,
    faFileLines,
    faSuitcase,
    faWallet,
} from '@fortawesome/free-solid-svg-icons';

export const UMBRELLA_USER_TABS = {
    INFORMATION: 'information-tab',
    MISSIONS: 'missions-tab',
    DOCUMENTS: 'documents-tab',
    PERSONAL_EXPENSES: 'personal-expenses-tab',
};

export const UMBRELLA_USER_TABS_DATA = {
    [UMBRELLA_USER_TABS.INFORMATION]: {id: 'information-tab', icon: faClipboardList},
    [UMBRELLA_USER_TABS.MISSIONS]: {id: 'missions-tab', icon: faSuitcase},
    [UMBRELLA_USER_TABS.DOCUMENTS]: {id: 'documents-tab', icon: faFileLines},
    [UMBRELLA_USER_TABS.PERSONAL_EXPENSES]: {id: 'personal-expenses-tab', icon: faWallet},
};
