import {
    HEADQUARTERS_DEFAULT_CITY,
    HEADQUARTERS_DEFAULT_STREET_NAME,
    HEADQUARTERS_DEFAULT_STREET_NUMBER,
    HEADQUARTERS_DEFAULT_ZIP_CODE,
    HEADQUARTERS_DEFAULT_ZIP_COUNTRY,
} from './constants';
import {
    CARE_COMPANY_LEGAL_STATUSES,
    CARE_COMPANY_TAX_SYSTEMS,
    COMPANY_LEGAL_STATUSES,
    COMPANY_TAX_SYSTEMS,
} from '../features/company/utils/constants';

export const isHeadquartersAddressSameAsHiwayAddress = ({
    headquartersCity,
    headquartersStreet,
    headquartersStreetNumber,
    headquartersZip,
    headquartersCountry,
}) => {
    if (
        !headquartersCity
        || !headquartersCountry
        || !headquartersStreet
        || !headquartersStreetNumber
        || !headquartersZip
    ) {
        return false;
    }

    return (
        HEADQUARTERS_DEFAULT_STREET_NUMBER.trim().toLowerCase() === headquartersStreetNumber.trim().toLowerCase()
        && HEADQUARTERS_DEFAULT_STREET_NAME.trim().toLowerCase() === headquartersStreet.trim().toLowerCase()
        && HEADQUARTERS_DEFAULT_CITY.trim().toLowerCase() === headquartersCity.trim().toLowerCase()
        && HEADQUARTERS_DEFAULT_ZIP_CODE.trim().toLowerCase() === headquartersZip.trim().toLowerCase()
        && HEADQUARTERS_DEFAULT_ZIP_COUNTRY.trim().toLowerCase() === headquartersCountry.trim().toLowerCase()
    );
};

export const isSasuIr = company => {
    return company?.legalForm === COMPANY_LEGAL_STATUSES.SASU && company?.taxSystem === COMPANY_TAX_SYSTEMS.IR;
};

export const isSasuIs = company => {
    return company?.legalForm === COMPANY_LEGAL_STATUSES.SASU && company?.taxSystem === COMPANY_TAX_SYSTEMS.IS;
};

export const isEurlIs = company => {
    return company?.legalForm === COMPANY_LEGAL_STATUSES.EURL && company.taxSystem === COMPANY_TAX_SYSTEMS.IS;
};

export const isEurlHolding = company => {
    return company?.legalForm === COMPANY_LEGAL_STATUSES.EURL_HOLDING;
};

export const isEiBnc = company => {
    return company?.legalForm === CARE_COMPANY_LEGAL_STATUSES.EI
    && (
        company?.taxSystem === CARE_COMPANY_TAX_SYSTEMS.BNC
        || company?.taxSystem === CARE_COMPANY_TAX_SYSTEMS.MicroBNC
    );
};

export const CompanyVariant = {
    SASU_IR: 'SASU_IR',
    SASU_IS: 'SASU_IS',
    EURL_IS: 'EURL_IS',
    EURL_HOLDING: 'EURL_HOLDING',
    EI_BNC: 'EI_BNC', // includes MicroBNC
};

export const getCompanyVariant = company => {
    switch (true) {
        case isSasuIr(company):
            return CompanyVariant.SASU_IR;
        case isSasuIs(company):
            return CompanyVariant.SASU_IS;
        case isEurlIs(company):
            return CompanyVariant.EURL_IS;
        case isEurlHolding(company):
            return CompanyVariant.EURL_HOLDING;
        case isEiBnc(company):
            return CompanyVariant.EI_BNC;
        default:
            return null;
    }
};
