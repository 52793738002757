import {Box, Skeleton, Typography} from '@mui/joy';
import {Skeleton as MuiSkeleton, Typography as MuiTypography, TableCell, TableRow} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import {useDataTableLoaderStyles} from './styles';

const DataTableLoader = ({numberOfRows, hasCheckboxes, columnConfig, isV3}) => {
    const styles = useDataTableLoaderStyles();

    return Array(numberOfRows)
        .fill(null)
        .map((_, index) => {
            return isV3 ? (
                <Box
                    key={index}
                    component="tr"
                    sx={{
                        borderBottom: '1px solid',
                        borderColor: 'neutral.outlinedBorder',
                    }}
                >
                    {hasCheckboxes && (
                        <Box
                            component="td"
                            sx={{
                                '--TableCell-paddingY': '16px',
                                '--TableCell-paddingX': '16px',
                            }}
                        />
                    )}

                    {columnConfig.map(column => (
                        <Box
                            key={column.key}
                            component="td"
                            sx={{
                                '--TableCell-paddingY': '16px',
                                '--TableCell-paddingX': '24px',
                            }}
                            style={{width: column.width || 'auto'}}
                            {...(column.additionalCellProps || {})}
                        >
                            <Skeleton
                                variant="rectangular"
                                sx={{
                                    display: 'inline-flex',
                                    verticalAlign: 'middle',
                                    maxWidth: 'max-content',
                                }}
                            >
                                <Typography level="body-md">
                                    {column?.componentProps?.isDate
                                        ? moment().format(column?.componentProps?.dateFormat || 'L')
                                        : column.title}
                                </Typography>
                            </Skeleton>
                        </Box>
                    ))}
                </Box>
            ) : (
                <TableRow key={index}>
                    {hasCheckboxes && (
                        <TableCell className={styles.checkboxTableCell} />
                    )}

                    {columnConfig.map(column => (
                        <TableCell
                            key={column.key}
                            className={styles.tableCell}
                            style={{width: column.width || 'auto'}}
                            {...(column.additionalCellProps || {})}
                        >
                            <MuiSkeleton className={styles.skeleton}>
                                <MuiTypography>
                                    {column?.componentProps?.isDate
                                        ? moment().format(column?.componentProps?.dateFormat || 'L')
                                        : column.title}
                                </MuiTypography>
                            </MuiSkeleton>
                        </TableCell>
                    ))}
                </TableRow>
            );
        });
};

DataTableLoader.propTypes = {
    columnConfig: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        title: PropTypes.string.isRequired,
        additionalCellProps: PropTypes.object,
    })).isRequired,
    numberOfRows: PropTypes.number.isRequired,
    hasCheckboxes: PropTypes.bool,
    isV3: PropTypes.bool,
};

DataTableLoader.defaultProps = {
    hasCheckboxes: false,
    isV3: false,
};

export default DataTableLoader;
