export const MissionsActionType = {
    GET_MISSION_LIST: 'missions/GET_MISSION_LIST',
    STORE_MISSION_LIST: 'missions/STORE_MISSION_LIST',
    GET_MISSION: 'missions/GET_MISSION',
    CREATE_MISSION: 'missions/CREATE_MISSION',
    PUT_EDIT_MISSION: 'missions/PUT_EDIT_MISSION',
    DEACTIVATE_MISSION: 'missions/DEACTIVATE_MISSION',
    STORE_MISSION: 'missions/STORE_MISSION',
    STORE_MISSION_IN_PROGRESS: 'missions/STORE_MISSION_IN_PROGRESS',
    STORE_MISSION_SCREEN: 'missions/STORE_MISSION_SCREEN',
    GET_MISSION_DETAILS: 'missions/GET_MISSION_DETAILS',
    STORE_MISSION_DETAILS: 'missions/STORE_MISSION_DETAILS',
    POST_SERVICE_AGREEMENT: 'mission/POST_SERVICE_AGREEMENT',
    POST_WORK_CONTRACT: 'mission/POST_WORK_CONTRACT',
};
