import {combineReducers} from 'redux';

import * as actions from './business-allowance.actions';
import {createReducer} from '../../../../../app/utils/store/create-reducer';
import {getConfig} from '../../../../../config/get-config';
import {LOCATION_CHANGED} from '../../../../../lib/router/connected-router-saga';

const currentlyEditingTravelReducer = (state = null, action) => {
    if (action.type === actions.SET_CURRENTLY_EDITING_TRAVEL) {
        return action.payload;
    } else if (
        action.type === LOCATION_CHANGED
        && action.payload.location.pathname !== getConfig().ROUTE_PATHS.HOME_OFFICE_TRAVEL
        && action.payload.location.pathname !== getConfig().ROUTE_PATHS.PROFESSIONAL_TRAVEL
    ) {
        return null;
    }

    return state;
};

export const businessAllowanceReducer = combineReducers({
    isLoadingTravels: createReducer(true, actions.SET_IS_LOADING_BUSINESS_ALLOWANCE_TRAVELS, true),
    isLoadingRecaps: createReducer(true, actions.SET_IS_LOADING_BUSINESS_ALLOWANCE_RECAPS, true),
    travels: createReducer({}, actions.STORE_BUSINESS_ALLOWANCE_TRAVELS, true),
    recaps: createReducer({}, actions.STORE_BUSINESS_ALLOWANCE_RECAPS, true),
    isGeneratingRecap: createReducer(false, actions.SET_IS_GENERATING_RECAP, true),
    currentlyEditingTravel: currentlyEditingTravelReducer,
});
