import {CssBaseline, ThemeProvider} from '@mui/material';
import {StyledEngineProvider} from '@mui/material/styles';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import 'moment/locale/fr';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {App} from './App';
import CrispModule from './components/crisp-module/CrispModule';
import GoogleAnalytics from './components/google-analytics/GoogleAnalytics';
import {Hotjar} from './components/hotjar/Hotjar';
import {SuspenseContainer} from './components/suspense/Suspense';
import * as i18next from './lib/i18next';
import {Router} from './lib/router/connected-router-saga';
import {history} from './lib/router/history';
import {theme} from './lib/theme';
import reportWebVitals from './reportWebVitals';
import {store} from './store';
import {LocalesConstants} from './utils/locales-constants';

// v1 locales - remove once new screen components are created
i18next.importLocaleBundle(LocalesConstants.Breadcrumbs);
i18next.importLocaleBundle(LocalesConstants.Common);
i18next.importLocaleBundle(LocalesConstants.Companies);
i18next.importLocaleBundle(LocalesConstants.Dashboard);
i18next.importLocaleBundle(LocalesConstants.Documents);
i18next.importLocaleBundle(LocalesConstants.Dropzone);
i18next.importLocaleBundle(LocalesConstants.Dropzone);
i18next.importLocaleBundle(LocalesConstants.Invoicing);
i18next.importLocaleBundle(LocalesConstants.Login);
i18next.importLocaleBundle(LocalesConstants.Notifications);
i18next.importLocaleBundle(LocalesConstants.Setup);
i18next.importLocaleBundle(LocalesConstants.Users);
i18next.importLocaleBundle(LocalesConstants.Bank);
i18next.importLocaleBundle(LocalesConstants.BankTransfer);
i18next.importLocaleBundle(LocalesConstants.BankCards);
i18next.importLocaleBundle(LocalesConstants.InvoicingNew);
i18next.importLocaleBundle(LocalesConstants.Aria);
i18next.importLocaleBundle(LocalesConstants.WealthManagement);
i18next.importLocaleBundle(LocalesConstants.Insurance);
i18next.importLocaleBundle(LocalesConstants.RevenueSimulator);
i18next.importLocaleBundle(LocalesConstants.Datepickers);
// TODO Maybe import dynamically only for umbrella users
i18next.importLocaleBundle(LocalesConstants.Missions);

ReactDOM.render(
    <>
        <React.Suspense fallback={(<SuspenseContainer />)}>
            <Provider store={store}>
                <Router history={history} store={store}>
                    <GoogleAnalytics />
                    <CrispModule />
                    <Hotjar />
                    <ThemeProvider theme={theme}>
                        <CssBaseline />

                        {/* Remove this after full migration to v5 */}
                        <StyledEngineProvider injectFirst>
                            <LocalizationProvider dateLibInstance={moment} dateAdapter={AdapterMoment}>
                                <App />
                            </LocalizationProvider>
                        </StyledEngineProvider>
                    </ThemeProvider>
                </Router>
            </Provider>
        </React.Suspense>
    </>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
