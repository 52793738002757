import {combineReducers} from 'redux';
import {SET_IS_LOADING_CHANGE_PASSWORD_FORM} from './change-password-form.actions';

const isLoadingReducer = (state = false, action) => {
    switch (action.type) {
        case SET_IS_LOADING_CHANGE_PASSWORD_FORM:
            return action.payload;

        default:
            return state;
    }
};

export const changePasswordFormReducer = combineReducers({
    isLoading: isLoadingReducer,
});
