import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Button, Checkbox, FormControlLabel, Grid, Grow, Paper, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import trainingFinalization from '../../../../assets/svg/training-finalization.svg';
import {theme} from '../../../../lib/theme';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {AnimationActions} from '../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../animations/utils/constants';
import {TrainingActions} from '../../store/training.action';
import {TRAINING_CONSTANTS} from '../../utils/constants';

const Finalization = () => {
    const dispatch = useDispatch();
    const [isChecked, setIsChecked] = useState(false);

    const {t: tTraining} = useTranslation(LocalesConstants.Training);

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const onCheckboxClick = e => {
        const checked = e.target.checked;
        setIsChecked(checked);
    };

    const onButtonClick = () => {
        if (!isChecked) {
            return;
        }

        dispatch(AnimationActions.storeNextDispatch(
            TrainingActions.submitStep(TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_FINALIZATION_PENDING_PAYMENT),
        ));
        dispatch(AnimationActions.setIsAnimationActive(false));
    };

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    return (
        <Box sx={{px: {xs: 2, lg: 0}, display: 'flex', flexDirection: 'column'}}>
            <Grow in={!!isAnimationActive} onExited={onEndedAnimation}>
                <Paper
                    elevation={1}
                    sx={{
                        borderRadius: 2,
                        minHeight: 290,
                        height: 'calc(100vh - 290px)',
                        overflowY: 'auto',
                        p: {xs: 2, md: 5},
                        mb: isMobileSize ? 0 : 2,
                    }}
                >
                    <Paper
                        elevation={0}
                        sx={{
                            bgcolor: '#FAFAFA',
                            borderRadius: 2,
                            height: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            py: 3,
                            px: 2,
                            gap: 2,
                        }}
                    >
                        <Box component="div" sx={{height: {xs: 128, sm: 202}}}>
                            <img src={trainingFinalization} height="100%" alt="hourGlass" />
                        </Box>
                        <Box
                            component="div"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: 0,
                                gap: 0.5,
                                textAlign: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: {xs: '600', sm: '700'},
                                    fontSize: {
                                        xs: theme.typography.pxToRem(16),
                                        sm: theme.typography.pxToRem(21),
                                    },
                                }}
                                color="text_v2.secondary"
                            >
                                {tTraining('finalization.paymentScreenLine1')}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: {
                                        xs: theme.typography.pxToRem(14),
                                        sm: theme.typography.pxToRem(16),
                                    },
                                }}
                                color="text_v2.secondary"
                            >
                                {tTraining('finalization.paymentScreenLine2')}
                            </Typography>
                        </Box>
                    </Paper>
                </Paper>
            </Grow>
            <Grid container sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Grid
                    item
                    xs={isMobileSize ? 12 : 'auto'}
                    sx={{
                        py: isMobileSize ? 2 : 0,
                        textAlign: 'center',
                    }}
                >
                    <FormControlLabel
                        control={(
                            <Checkbox
                                defaultChecked={isChecked}
                                onClick={onCheckboxClick}
                            />
                          )}
                        label={(
                            <Typography sx={{color: 'text_v2.primary'}}>
                                {tTraining('finalization.invoicePaid')}
                            </Typography>
                        )}
                    />
                </Grid>
                <Grid item xs={isMobileSize ? 12 : 'auto'} sx={{display: 'flex', justifyContent: 'end'}}>
                    <Button
                        size="large"
                        disabled={!isChecked}
                        variant="contained"
                        color="secondary"
                        endIcon={<ArrowForwardIcon />}
                        fullWidth={isMobileSize}
                        onClick={onButtonClick}
                    >
                        {tTraining('finalization.Finalize')}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Finalization;
