export const SESSION_NAMES = {
    PUBLIC_USER_SESSION: 'PUBLIC_USER_SESSION',
    PUBLIC_USER_MISSION_ID: 'PUBLIC_USER_MISSION_ID',
    PUBLIC_USER_DOCUMENT_TYPE: 'PUBLIC_USER_DOCUMENT_TYPE',
    PUBLIC_USER_CONTRACT_ID: 'PUBLIC_USER_CONTRACT_ID',
};

export const SessionUtil = {
    getSession: key => {
        return sessionStorage.getItem(key);
    },
    setSession: (key, value) => {
        sessionStorage.setItem(key, value);
    },
};
