import {
    faCircleMinus,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, RadioGroup, Stack, Typography} from '@mui/joy';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {SelectItem} from '../../../../components/joy-ui/forms/SelectItem';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {LoadingTypes, useLoadingState} from '../../../loading';
import {UiSelectors} from '../../../ui/store/ui.selector';
import {ModalsKeys} from '../../../ui/utils/constants';
import {BankActions} from '../../store/bank.action';

const modalKey = ModalsKeys.DELETE_INTEGRATION_MODAL;

const DeleteIntegrationModal = () => {
    const dispatch = useDispatch();

    const data = useSelector(UiSelectors.createModalDataSelector(modalKey));
    const isDeleting = useLoadingState(LoadingTypes.DELETE_BANK);

    const {t} = useTranslation(LocalesConstants.Bank);
    const [radioValue, setRadioValue] = useState(null);

    const handleClick = () => {
        dispatch(BankActions.deleteIntegrationAccount({id: data?.integration?.id}));
    };

    return (
        <ModalWrapper
            isV3
            containerSx={{width: '720px'}}
            modalKey={modalKey}
            title={(
                <Typography level="h1">
                    {t('accountArchiving.deleteTitle')}
                </Typography>
            )}
            buttons={({handleClose}) => (
                <>
                    <Button
                        fullWidth
                        onClick={() => {
                            handleClose();
                        }}
                        variant="outlined"
                        color="neutral"
                        size="lg"
                    >
                        {t('accountArchiving.cancelButton')}
                    </Button>

                    <Button
                        loading={isDeleting}
                        disabled={!radioValue}
                        fullWidth
                        startDecorator={(
                            <FontAwesomeIcon icon={faCircleMinus} />
                        )}
                        loadingPosition="start"
                        onClick={handleClick}
                        color="danger"
                        variant="soft"
                        size="lg"
                    >
                        {t('archivingActions.delete')}
                    </Button>
                </>
            )}
        >
            <Typography level="body-lg" sx={{mb: 1}}>
                {t('accountArchiving.deleteText1')}
            </Typography>


            <Box component="ul" sx={{my: 1}}>
                <Box component="li">
                    <Typography level="body-lg" component="span">
                        {t('accountArchiving.deleteItem1')}
                    </Typography>
                </Box>
                <Box component="li">
                    <Typography level="body-lg" component="span">
                        {t('accountArchiving.deleteItem2')}
                    </Typography>
                </Box>
            </Box>

            <Typography level="body-lg" sx={{mb: 2}}>
                {t('accountArchiving.deleteText2')}
            </Typography>

            <RadioGroup
                name="position"
                value={radioValue}
                onChange={e => {
                    setRadioValue(e.target.value);
                }}
            >
                <Stack
                    direction="column"
                    gap={{desktop: 3, mobile: 2}}
                >
                    <SelectItem
                        value={1}
                        name={t('accountArchiving.deleteQuestion')}
                        text={t('accountArchiving.deleteAnswer1')}
                        isSelected={radioValue === 1}
                    />
                    <SelectItem
                        value={2}
                        name={t('accountArchiving.deleteQuestion')}
                        text={t('accountArchiving.deleteAnswer2')}
                        isSelected={radioValue === 2}
                    />
                </Stack>
            </RadioGroup>
        </ModalWrapper>
    );
};

export default DeleteIntegrationModal;
