import {Box, CircularProgress, Grow} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';
import {LoadingTypes, useLoadingState} from '../../../../../../../loading';
import {TransactionsActions} from '../../../../store/transactions.action';
import {TransactionsSelector} from '../../../../store/transactions.selector';
import {TransactionSide} from '../../../../util/constants';
import {SECTION_STATUS} from '../../utils/constants';
import CategoriesList from '../categories/CategoriesList';
import {SelectedSource} from '../categories/SelectedSource';
import CategorisationSection from '../header/CategorisationSection';

const FakeReimbursementLayout = ({
    details,
    handleOpenAllCategories,
    setIsFakeReimbursementOpen,
}) => {
    const {t} = useTranslation(LocalesConstants.Bank);
    const dispatch = useDispatch();
    const mostUsedCategoryList = useSelector(TransactionsSelector.selectMostUsedDebitCategories);

    const isInProgress = useLoadingState(LoadingTypes.BANK_TRANSACTION_FIELD);

    useEffect(() => {
        if (!mostUsedCategoryList) {
            dispatch(TransactionsActions.getMostUsedCategories({type: TransactionSide.DEBIT}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCategorySelect = category => {
        // Create fake reimbursement
        dispatch(TransactionsActions.saveTransactionField({
            id: details.id,
            fieldKey: 'fakeReimbursedTransactions',
            fieldValue: [{
                'category': category.mccKey,
                'amount': details?.amount, // TODO Check?
                'tva': category.tva,
            }],
            accountId: details?.bankAccountId,
        }));

        setIsFakeReimbursementOpen(false);
    };

    return (
        <Grow in={true}>
            <div>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    mx: 2,
                    mt: 2,
                }}
                >
                    <CategorisationSection
                        title={t('categorisation.fakeReimbursement.categoryTitle')}
                        sx={{
                            flex: '2 0 0',
                            p: 2,
                            pt: 1,
                            justifyContent: 'space-between',
                            gap: 1.5,
                            borderRadius: '16px 16px 0 0',
                            mb: 0.3,
                        }}
                        isActive
                    >
                        <SelectedSource
                            selected={details.sourceOfRevenue}
                            onOpen={handleOpenAllCategories}
                        />
                    </CategorisationSection>

                    <CategorisationSection
                        title={t('categorisation.fakeReimbursement.fakeCategoryTitle')}
                        sx={{
                            flex: '2 0 0',
                            p: 2,
                            pt: 1,
                            justifyContent: 'space-between',
                            gap: 1.5,
                            borderRadius: '0 0 16px 16px',
                        }}
                        isActive
                        status={SECTION_STATUS.REQUIRED}
                    >
                        {isInProgress ? (<CircularProgress />) : (
                            <CategoriesList
                                selectedCategory={null}
                                setSelectedCategory={onCategorySelect}
                                side={TransactionSide.DEBIT}
                                text=""
                                isSubscribed={false}
                                hasMultipleArticles={false}
                            />
                        )}
                    </CategorisationSection>
                </Box>
            </div>
        </Grow>
    );
};

FakeReimbursementLayout.propTypes = {
    details: PropTypes.object.isRequired,
    handleOpenAllCategories: PropTypes.func.isRequired,
    setIsFakeReimbursementOpen: PropTypes.func.isRequired,
};

export default FakeReimbursementLayout;
