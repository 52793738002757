import {
    faCamera,
    faPaperclip,
    faSignature,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CameraAltTwoToneIcon from '@mui/icons-material/CameraAltTwoTone';
import CheckIcon from '@mui/icons-material/Check';
import GestureIcon from '@mui/icons-material/Gesture';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import {Box, Button} from '@mui/joy';
import {LoadingButton} from '@mui/lab';
import {Box as MuiBox} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {SIGNATURE_FILE_MAX_SIZE, SignatureUploadDropZone} from './SignatureUploadDropZone';
import {SIGNING_OPTIONS} from '../utils/constants';

export const SigningButtonSection = ({
    selectedSigningOption,
    setSelectedSigningOption,
    onChange,
    t,
    isLoading,
    handleSubmit,
    imagePreviewUrl,
    isPadEmpty,
    isMobileSize,
    isUploaded,
    isV3,
    isPublic,
}) => {
    const [isEnabled, setIsEnabled] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        const newIsEnabled = (selectedSigningOption === SIGNING_OPTIONS.UPLOAD && imagePreviewUrl)
            || (selectedSigningOption === SIGNING_OPTIONS.DRAW && !isPadEmpty)
            || (selectedSigningOption === SIGNING_OPTIONS.QR && imagePreviewUrl && isMobileSize);
        setIsEnabled(newIsEnabled);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSigningOption, imagePreviewUrl, isPadEmpty]);

    if (isV3) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                gap: 1,
                width: isMobileSize ? '100%' : 'calc(100% - 400px - 12px)',
                color: '#546E7A',
                mt: isMobileSize ? 2 : 0,
                ml: isMobileSize ? 0 : 2,
                mb: isMobileSize ? 14 : 0,
            }}
            >
                <Button
                    fullWidth
                    color="primary"
                    variant={selectedSigningOption === SIGNING_OPTIONS.DRAW ? 'soft' : 'outlined'}
                    onClick={() => {
                        if (!isLoading) {
                            setSelectedSigningOption(SIGNING_OPTIONS.DRAW);
                        }
                    }}
                    startDecorator={(
                        <FontAwesomeIcon icon={faSignature} />
                    )}
                >
                    {t('uploadSignatureModal.draw')}
                </Button>

                <SignatureUploadDropZone
                    maxFiles={1}
                    fileTypes=".jpg, .png, .jpeg"
                    onChange={onChange}
                    maxSize={SIGNATURE_FILE_MAX_SIZE.SIGNATURE}
                >
                    <Button
                        fullWidth
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            if (!isLoading) {
                                setSelectedSigningOption(SIGNING_OPTIONS.UPLOAD);
                            }
                        }}
                        startDecorator={(
                            <FontAwesomeIcon icon={faPaperclip} />
                        )}
                    >
                        {t('uploadSignatureModal.upload')}
                    </Button>
                </SignatureUploadDropZone>

                {!isMobileSize && !isPublic && (
                    <Button
                        fullWidth
                        color="primary"
                        variant={selectedSigningOption === SIGNING_OPTIONS.QR ? 'soft' : 'outlined'}
                        onClick={() => setSelectedSigningOption(SIGNING_OPTIONS.QR)}
                        startDecorator={(
                            <FontAwesomeIcon icon={faCamera} />
                        )}
                    >
                        {t('uploadSignatureModal.useQR')}
                    </Button>
                )}

                {isMobileSize && !isPublic && (
                    <Button
                        fullWidth
                        color="primary"
                        variant={selectedSigningOption === SIGNING_OPTIONS.QR ? 'soft' : 'outlined'}
                        startDecorator={(
                            <FontAwesomeIcon icon={faCamera} />
                        )}
                        onClick={() => {
                            setSelectedSigningOption(SIGNING_OPTIONS.QR);
                            inputRef.current.click();
                        }}
                    >
                        {t('uploadSignatureModal.useCamera')}

                        <Box sx={{
                            display: 'none',
                        }}
                        >
                            <input
                                ref={inputRef}
                                type="file"
                                accept="image/*"
                                capture="camera"
                                onChange={e => {
                                    if (e.target.files && e.target.files[0]) {
                                        const files = e.target.files;
                                        onChange(files); // Handle the selected file (image)
                                    }
                                }}
                            />
                        </Box>
                    </Button>
                )}

                <Box>
                    <Button
                        sx={{
                            mt: isPublic ? 6 : '14px',
                            color: 'primary',
                        }}
                        fullWidth
                        loading={isLoading}
                        startIcon={<CheckIcon />}
                        disabled={!isEnabled && !isUploaded}
                        color="primary"
                        onClick={handleSubmit}
                    >
                        {t('uploadSignatureModal.continueButton')}
                    </Button>
                </Box>
            </Box>
        );
    }

    return (
        <MuiBox sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            gap: 1,
            width: isMobileSize ? '100%' : 'calc(100% - 400px - 12px)',
            color: '#546E7A',
            mt: isMobileSize ? 2 : 0,
            ml: isMobileSize ? 0 : 2,
        }}
        >
            <MuiBox
                sx={{
                    cursor: 'pointer',
                    p: 2,
                    borderRadius: '8px',
                    width: '100%',
                    height: isMobileSize ? '100%' : '56px',
                    fontSize: theme => theme.typography.pxToRem(12),
                    textTransform: 'uppercase',
                    fontWeight: 500,
                    letterSpacing: '0.46px',
                    color: 'v2.blueGray.600',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    backgroundColor: selectedSigningOption === SIGNING_OPTIONS.DRAW ? 'rgba(25, 118, 210, 0.30)' : 'rgba(25, 118, 210, 0.08)',
                }}
                onClick={() => {
                    if (!isLoading) {
                        setSelectedSigningOption(SIGNING_OPTIONS.DRAW);
                    }
                }}
            >
                <GestureIcon sx={{
                    color: 'primary_v2.main',
                }}
                />
                {t('uploadSignatureModal.draw')}
            </MuiBox>

            <SignatureUploadDropZone
                maxFiles={1}
                fileTypes="image/png, image/jpg, image/jpeg"
                onChange={onChange}
                maxSize={SIGNATURE_FILE_MAX_SIZE.SIGNATURE}
            >
                <MuiBox
                    sx={{
                        cursor: 'pointer',
                        p: 2,
                        borderRadius: '8px',
                        width: 'calc(100%)',
                        height: '56px',
                        fontSize: theme => theme.typography.pxToRem(12),
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        letterSpacing: '0.46px',
                        color: 'v2.blueGray.600',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        backgroundColor: selectedSigningOption === SIGNING_OPTIONS.UPLOAD ? 'rgba(25, 118, 210, 0.30)' : 'rgba(25, 118, 210, 0.08)',
                    }}
                    onClick={() => {
                        if (!isLoading) {
                            setSelectedSigningOption(SIGNING_OPTIONS.UPLOAD);
                        }
                    }}
                >
                    <UploadTwoToneIcon sx={{
                        color: 'primary_v2.main',
                    }}
                    />
                    {t('uploadSignatureModal.upload')}
                </MuiBox>
            </SignatureUploadDropZone>

            {!isMobileSize && (
                <MuiBox
                    disabled={true}
                    sx={{
                        cursor: 'default',
                        p: 2,
                        borderRadius: '8px',
                        width: '100%',
                        height: '56px',
                        fontSize: theme => theme.typography.pxToRem(12),
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        letterSpacing: '0.46px',
                        color: 'v2.blueGray.600',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        backgroundColor: selectedSigningOption === SIGNING_OPTIONS.QR ? 'rgba(25, 118, 210, 0.30)' : 'rgba(25, 118, 210, 0.08)',
                    }}
                    onClick={() => setSelectedSigningOption(SIGNING_OPTIONS.QR)}
                >
                    <CameraAltTwoToneIcon sx={{
                        color: 'primary_v2.main',
                    }}
                    />
                    {t('uploadSignatureModal.useQR')}
                </MuiBox>
            )}

            {isMobileSize && (
                <MuiBox
                    disabled={true}
                    sx={{
                        cursor: 'default',
                        p: 2,
                        borderRadius: '8px',
                        width: '100%',
                        height: '56px',
                        fontSize: theme => theme.typography.pxToRem(12),
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        letterSpacing: '0.46px',
                        color: 'v2.blueGray.600',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        backgroundColor: selectedSigningOption === SIGNING_OPTIONS.QR ? 'rgba(25, 118, 210, 0.30)' : 'rgba(25, 118, 210, 0.08)',
                    }}
                    onClick={() => {
                        setSelectedSigningOption(SIGNING_OPTIONS.QR);
                        inputRef.current.click();
                    }}
                >
                    <CameraAltTwoToneIcon sx={{
                        color: 'primary_v2.main',
                    }}
                    />
                    {t('uploadSignatureModal.useCamera')}

                    <MuiBox sx={{
                        display: 'none',
                    }}
                    >
                        <input
                            ref={inputRef}
                            type="file"
                            accept="image/*"
                            capture="camera"
                            onChange={e => {
                                if (e.target.files && e.target.files[0]) {
                                    const files = e.target.files;
                                    onChange(files); // Handle the selected file (image)
                                }
                            }}
                        />
                    </MuiBox>
                </MuiBox>
            )}

            <MuiBox>
                <LoadingButton
                    sx={{
                        mt: isPublic ? 6 : '14px',
                    }}
                    variant="contained"
                    fullWidth={true}
                    loading={isLoading}
                    startIcon={<CheckIcon />}
                    disabled={!isEnabled && !isUploaded}
                    color="secondary"
                    onClick={handleSubmit}
                    size="large"
                >
                    {t('uploadSignatureModal.continueButton')}
                </LoadingButton>
            </MuiBox>
        </MuiBox>
    );
};

SigningButtonSection.propTypes = {
    selectedSigningOption: PropTypes.string,
    setSelectedSigningOption: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    imagePreviewUrl: PropTypes.string,
    isPadEmpty: PropTypes.bool.isRequired,
    isMobileSize: PropTypes.bool.isRequired,
    isUploaded: PropTypes.bool.isRequired,
    isV3: PropTypes.bool,
    isPublic: PropTypes.bool.isRequired,
};

SigningButtonSection.defaultProps = {
    selectedSigningOption: '',
    imagePreviewUrl: '',
    isV3: false,
};
