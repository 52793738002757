import {yupResolver} from '@hookform/resolvers/yup';
import {LoadingButton} from '@mui/lab';
import {Box, Grid, TextField, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ButtonAction} from '../../../../components/buttons/ButtonAction';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import Yup from '../../../../lib/yup/yup';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {ModalsKeys} from '../../../ui/utils/constants';
import {TrainingActions} from '../../store/training.action';
import {TrainingSelector} from '../../store/training.selector';

const defaultValues = {
    atlasFileNumber: '',
};

const validationRegex = /^\S+$/;
const atlasFileNumberMinLength = 3;
// min 3 printable characters

export const DossierTrainingModal = () => {
    const {t} = useTranslation(LocalesConstants.Training);
    const dispatch = useDispatch();

    const trainingInfo = useSelector(TrainingSelector.getTrainingInfo);
    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.TRAINING_STEP_SUBMIT),
    );

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const validationSchema = Yup.object().shape({
        atlasFileNumber: Yup.string()
            .trim()
            .required(t('form:validation.required', {
                fieldName: t('dossierModal.atlasFileNumber'),
            }))
            .min(
                atlasFileNumberMinLength,
                t(
                    'form:validation/v2.string/min',
                    {label: t('dossierModal.atlasFileNumber'), min: atlasFileNumberMinLength},
                ),
            )
            .matches(validationRegex, t('form:validation/v2.string/matches')),
    });

    const {
        control,
        formState: {errors, isValid, isDirty},
        handleSubmit: createSubmitHandler,
        setValue,
    } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues,
        resolver: yupResolver(validationSchema),
    });

    const handleClick = createSubmitHandler(data => {
        dispatch(TrainingActions.saveAtlasNumber(data));
    });

    const defaultFieldProps = {
        fullWidth: true,
        variant: 'outlined',
        disabled: isLoading,
    };

    useEffect(() => {
        if (trainingInfo && trainingInfo.atlasFileNumber) {
            setValue('atlasFileNumber', trainingInfo.atlasFileNumber, {shouldDirty: true});
        }
    }, [trainingInfo, setValue]);

    return (
        <ModalWrapper
            modalKey={ModalsKeys.TRAINING_DOSSIER_MODAL}
            title={(
                <Typography variant={isMobileSize ? 'h4' : 'h5'}>
                    {t('dossierModal.header')}
                </Typography>
            )}
            buttons={({handleClose}) => (
                <>
                    <ButtonAction
                        fullWidth={isMobileSize}
                        onClick={handleClose}
                        sx={{mr: 3}}
                        color="grey"
                    >
                        {t('dossierModal.cancel')}
                    </ButtonAction>

                    <LoadingButton
                        fullWidth={isMobileSize}
                        color="secondary"
                        variant="contained"
                        onClick={handleClick}
                        disabled={!isValid || !isDirty}
                        loading={isLoading}
                    >
                        {t('dossierModal.submit')}
                    </LoadingButton>
                </>
            )}
            containerSx={{
                width: '464px',
            }}
            buttonsSx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}
        >
            <Box>{t('dossierModal.text')}</Box>
            <Box sx={{mt: 1.5}}>
                <Grid item xs={12} lg={6}>
                    <Controller
                        control={control}
                        name="atlasFileNumber"
                        render={({field}) => (
                            <TextField
                                label={t('dossierModal.atlasFileNumber')}
                                error={!!errors.atlasFileNumber}
                                helperText={errors.atlasFileNumber?.message}
                                {...field}
                                {...defaultFieldProps}
                            />
                        )}
                    />
                </Grid>
            </Box>
        </ModalWrapper>
    );
};
