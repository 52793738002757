import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Button, SvgIcon, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as Axa} from '../../../assets/svg/axa.svg';
import {LocalesConstants} from '../../../utils/locales-constants';

const InsuranceBanner = ({taxSystem, handleOpenSkipModal, handleOpenSubscribeModal}) => {
    const {t} = useTranslation(LocalesConstants.Insurance);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const bannerTitleSx = {
        'fontSize': theme => theme.typography.pxToRem(60),
        'lineHeight': theme => theme.typography.pxToRem(70),
        'fontWeight': 700,
        'color': 'text_v2.primary',
        '@media (max-width: 959.95px)': {
            fontSize: theme => theme.typography.pxToRem(34),
            lineHeight: theme => theme.typography.pxToRem(40),
        },
    };

    return (
        <Box sx={{
            display: 'flex',
            alignSelf: 'stretch',
            padding: isMobileSize ? 3 : '70px 70px 88px 70px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 1,
            borderRadius: 4,
            backgroundColor: '#88C0E9',
            backgroundImage: 'radial-gradient(434.71% 148.58% at 1.21% 36.24%, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.00) 100%)',
            backgroundBlendMode: 'overlay, normal',
        }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: isMobileSize ? 6 : '38px',
                alignSelf: 'stretch',
            }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '10px',
                    alignSelf: 'stretch',
                }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        alignSelf: 'stretch',
                    }}
                    >
                        <Typography sx={{
                            'fontSize': theme => theme.typography.pxToRem(24),
                            'textAlign': 'center',
                            '@media (max-width: 959.95px)': {
                                fontSize: theme => theme.typography.pxToRem(16),
                            },
                        }}
                        >
                            {t('banner.axaPartnership')}
                        </Typography>
                        <SvgIcon
                            component={Axa}
                            inheritViewBox
                            sx={{
                                width: isMobileSize ? '30px' : '36px',
                                height: isMobileSize ? '30px' : '36px',
                            }}
                        />
                    </Box>
                    <Box sx={{
                        'display': 'flex',
                        'flexDirection': 'column',
                        'alignItems': 'flex-start',
                        'alignSelf': 'stretch',
                    }}
                    >
                        <Typography sx={bannerTitleSx}>
                            {t(taxSystem === 'IR' ? 'banner.irTitle1' : 'banner.isTitle1')}
                        </Typography>
                        <Typography sx={bannerTitleSx}>
                            {t(taxSystem === 'IR' ? 'banner.irTitle2' : 'banner.isTitle2')}
                        </Typography>
                    </Box>
                    <Typography sx={{
                        'fontSize': theme => theme.typography.pxToRem(24),
                        '@media (max-width: 959.95px)': {
                            fontSize: theme => theme.typography.pxToRem(16),
                        },
                    }}
                    >
                        {t('banner.subtitle')}
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: isMobileSize ? 'column' : 'row',
                    alignItems: 'flex-start',
                    gap: isMobileSize ? 2 : 3,
                    alignSelf: 'stretch',
                }}
                >
                    <Button
                        variant="contained"
                        size="large"
                        color="secondary"
                        onClick={handleOpenSubscribeModal}
                        endIcon={<ArrowForwardIcon />}
                        sx={{
                            width: isMobileSize ? '100%' : 'auto',
                            height: '56px',
                        }}
                    >
                        {t(isMobileSize ? 'banner.ctaMobile' : 'banner.cta')}
                    </Button>
                    <Button
                        variant="contained"
                        size="large"
                        color="inherit"
                        onClick={handleOpenSkipModal}
                        sx={{
                            width: isMobileSize ? '100%' : 'auto',
                            height: '56px',
                            backgroundColor: 'whitePallete.default',
                        }}
                    >
                        {t('banner.subscribeLater')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

InsuranceBanner.propTypes = {
    taxSystem: PropTypes.string.isRequired,
    handleOpenSkipModal: PropTypes.func.isRequired,
    handleOpenSubscribeModal: PropTypes.func.isRequired,
};

export default InsuranceBanner;
