import {Box, Typography} from '@mui/joy';
import {Box as MuiBox, Typography as MuiTypography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import AreYouSureModal from './AreYouSureModal';
import {SignatureDisplay} from './SignatureDisplay';
import SignatureQrCode from './SignatureQRCode';
import {SigningButtonSection} from './SigningButtonSection';
import Grow from '../../../components/joy-ui/mui-legacy/Grow';
import {ModalWrapper} from '../../../components/modal-wrapper/ModalWrapper';
import {importLocaleBundle} from '../../../lib/i18next';
import {LocalesConstants} from '../../../utils/locales-constants';
import {LoadingTypes, useLoadingState} from '../../loading';
import {MissionClientsActions} from '../../missions/modules/client-side/store/missions-client.actions';
import {UiActions} from '../../ui/store/ui.action';
import {ModalsKeys} from '../../ui/utils/constants';
import {SignatureActions} from '../store/signature.action';
import {SignatureSelectors} from '../store/signature.selector';
import {SIGNING_OPTIONS} from '../utils/constants';

importLocaleBundle(LocalesConstants.Signature);

const modalKey = ModalsKeys.UPLOAD_SIGNATURE;

export const UploadSignatureModal = ({isV3, isPublic}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation(LocalesConstants.Signature);

    const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
    const [selectedSigningOption, setSelectedSigningOption] = useState('');
    const [signaturePad, setSignaturePad] = useState(null);
    const [isPadEmpty, setIsPadEmpty] = useState(true);
    const signatureUrl = useSelector(SignatureSelectors.selectUrl);

    const isLoading = useLoadingState(LoadingTypes.UPLOAD_SIGNATURE);
    const isUploaded = useSelector(SignatureSelectors.selectIsUploaded);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const [files, setFiles] = useState([]);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');

    useEffect(() => {
        if (signaturePad) {
            signaturePad.addEventListener('endStroke', () => {
                const isEmpty = signaturePad.isEmpty();
                setIsPadEmpty(isEmpty);
            });
        }
        return () => {
            if (signaturePad) {
                signaturePad.removeEventListener('endStroke');
            }
        };
    }, [signaturePad]);

    const clearState = () => {
        setSelectedSigningOption('');
        setImagePreviewUrl('');
        setFiles([]);
        dispatch(SignatureActions.hasSignatureUploadSucced(null));
    };

    useEffect(() => {
        if (isUploaded) {
            clearState();
            dispatch(UiActions.setActiveModal(ModalsKeys.UPLOAD_SIGNATURE, false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUploaded]);

    const handleClose = closeFnc => {
        if (selectedSigningOption === SIGNING_OPTIONS.UPLOAD) {
            if (files.length) {
                setShowAreYouSureModal(true);
                return;
            }
        }

        if (selectedSigningOption === SIGNING_OPTIONS.DRAW) {
            if (signaturePad && !signaturePad.isEmpty()) {
                setShowAreYouSureModal(true);
                return;
            }
        }

        clearState();
        closeFnc();
    };

    const onChange = useCallback(newFiles => {
        if (newFiles?.length > 0) {
            const reader = new FileReader();

            reader.onload = e => {
                setImagePreviewUrl(e.target.result);
            };

            reader.readAsDataURL(newFiles[0]);
        }

        setFiles(newFiles);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const handleSubmit = useCallback(() => {
        if (isUploaded) {
            dispatch(UiActions.setActiveModal(modalKey, false));
            clearState();
        }

        // Handle for non logged-in clients
        if (isPublic) {
            if (selectedSigningOption === SIGNING_OPTIONS.UPLOAD) {
                dispatch(MissionClientsActions.postClientSignature(files[0], selectedSigningOption));
            } else {
                const file = signaturePad.toDataURL();

                dispatch(MissionClientsActions.postClientSignature(file, selectedSigningOption));
            }
            return;
        }

        if (selectedSigningOption === SIGNING_OPTIONS.UPLOAD) {
            if (files.length) {
                dispatch(SignatureActions.uploadSignature(files[0], selectedSigningOption));
            }
        }

        if (selectedSigningOption === SIGNING_OPTIONS.DRAW) {
            if (signaturePad && !signaturePad.isEmpty()) {
                const file = signaturePad.toDataURL();

                dispatch(SignatureActions.uploadSignature(file, selectedSigningOption));
            }
        }

        if (selectedSigningOption === SIGNING_OPTIONS.QR && isMobileSize) {
            if (files.length) {
                dispatch(SignatureActions.uploadSignature(files[0], SIGNING_OPTIONS.UPLOAD));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, selectedSigningOption, files, signaturePad, isMobileSize]);

    if (showAreYouSureModal) {
        return (
            <AreYouSureModal
                onBack={() => {
                    // TODO Repopulate old signaturePad data if drawing was in progress
                    setShowAreYouSureModal(false);
                }}
                onClose={() => {
                    dispatch(UiActions.setActiveModal(modalKey, false));
                    clearState();
                }}
            />
        );
    }

    if (isV3) {
        return (
            <ModalWrapper
                isV3
                modalKey={modalKey}
                handleCloseFnc={handleClose}
                handleCloseCB={() => {
                    setSelectedSigningOption('');
                }}
                title={(
                    <Typography level="h1">
                        {!signatureUrl
                            ? t('uploadSignatureModal.createTitle')
                            : t('uploadSignatureModal.editTitle')}
                    </Typography>
                )}
                buttons={null}
                containerSx={{
                    width: '746px',
                    padding: '0 !important',
                }}
                bodySx={{
                    p: 3,
                }}
                buttonsSx={{
                    flexDirection: 'row',
                }}
            >
                <>
                    <Typography sx={{my: 2, fontSize: '1rem'}} color="text.disabled" variant="subtitle2">
                        {t('uploadSignatureModal.description')}
                    </Typography>

                    <Box>
                        {selectedSigningOption === SIGNING_OPTIONS.QR && !isUploaded && (
                            <Grow in={true}>
                                <div>
                                    <SignatureQrCode />
                                </div>
                            </Grow>
                        )}

                        <Box sx={{
                            display: 'flex',
                            flexDirection: isMobileSize ? 'column' : 'row',
                        }}
                        >
                            <Box
                                sx={{
                                    width: isMobileSize ? '100%' : '400px !important',
                                    height: '256px',
                                    border: '1px dashed #E0E0E0',
                                    borderRadius: '12px',
                                    backgroundColor: 'gray_v2.100',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <SignatureDisplay
                                    selectedSigningOption={selectedSigningOption}
                                    signatureUrl={signatureUrl}
                                    imagePreviewUrl={imagePreviewUrl}
                                    signaturePad={signaturePad}
                                    setSignaturePad={setSignaturePad}
                                    isPadEmpty={isPadEmpty}
                                    setIsPadEmpty={setIsPadEmpty}
                                    isUploaded={isUploaded}
                                    isMobileSize={isMobileSize}
                                />
                            </Box>

                            <SigningButtonSection
                                t={t}
                                selectedSigningOption={selectedSigningOption}
                                imagePreviewUrl={imagePreviewUrl}
                                setSelectedSigningOption={setSelectedSigningOption}
                                isLoading={isLoading === true}
                                onChange={onChange}
                                signatureUrl={signatureUrl}
                                handleSubmit={handleSubmit}
                                isPadEmpty={isPadEmpty}
                                isMobileSize={isMobileSize}
                                isUploaded={isUploaded}
                                isPublic={isPublic}
                                isV3
                            />
                        </Box>
                    </Box>
                </>
            </ModalWrapper>
        );
    }

    return (
        <ModalWrapper
            modalKey={modalKey}
            handleCloseFnc={handleClose}
            handleCloseCB={() => {
                setSelectedSigningOption('');
            }}
            title={(
                <MuiTypography variant="h4">
                    {!signatureUrl
                        ? t('uploadSignatureModal.createTitle')
                        : t('uploadSignatureModal.editTitle')}
                </MuiTypography>
                )}
            buttons={null}
            containerSx={{
                width: '746px',
                padding: '0 !important',
            }}
            bodySx={{
                p: 3,
            }}
            buttonsSx={{
                flexDirection: 'row',
            }}
        >
            <>
                <MuiTypography sx={{my: 2, fontSize: '1rem'}} color="text.disabled" variant="subtitle2">
                    {t('uploadSignatureModal.description')}
                </MuiTypography>

                <MuiBox>
                    {selectedSigningOption === SIGNING_OPTIONS.QR && !isUploaded && (
                        <Grow in={true}>
                            <div>
                                <SignatureQrCode />
                            </div>
                        </Grow>
                    )}

                    <MuiBox sx={{
                        display: 'flex',
                        flexDirection: isMobileSize ? 'column' : 'row',
                    }}
                    >
                        <MuiBox
                            sx={{
                                width: isMobileSize ? '100%' : '400px !important',
                                height: '256px',
                                border: '1px dashed #E0E0E0',
                                borderColor: 'v2.gray.300',
                                borderRadius: '12px',
                                backgroundColor: 'gray_v2.100',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <SignatureDisplay
                                selectedSigningOption={selectedSigningOption}
                                signatureUrl={signatureUrl}
                                imagePreviewUrl={imagePreviewUrl}
                                signaturePad={signaturePad}
                                setSignaturePad={setSignaturePad}
                                isPadEmpty={isPadEmpty}
                                setIsPadEmpty={setIsPadEmpty}
                                isUploaded={isUploaded}
                            />
                        </MuiBox>

                        <SigningButtonSection
                            t={t}
                            selectedSigningOption={selectedSigningOption}
                            imagePreviewUrl={imagePreviewUrl}
                            setSelectedSigningOption={setSelectedSigningOption}
                            isLoading={isLoading === true}
                            onChange={onChange}
                            signatureUrl={signatureUrl}
                            handleSubmit={handleSubmit}
                            isPadEmpty={isPadEmpty}
                            isMobileSize={isMobileSize}
                            isUploaded={isUploaded}
                        />

                    </MuiBox>
                </MuiBox>
            </>
        </ModalWrapper>
    );
};

UploadSignatureModal.propTypes = {
    isV3: PropTypes.bool,
    isPublic: PropTypes.bool,
};

UploadSignatureModal.defaultProps = {
    isV3: false,
    isPublic: false,
};
