export const MissionClientsActionType = {
    STORE_IS_PUBLIC_USER: 'MissionClientsActionType/STORE_IS_PUBLIC_USER',
    GET_DOCUMENT_INFO: 'MissionClientsActionType/GET_DOCUMENT_INFO',
    STORE_DOCUMENT_INFO: 'MissionClientsActionType/STORE_DOCUMENT_INFO',
    GET_CLIENT_SIGNATURE: 'MissionClientsActionType/GET_CLIENT_SIGNATURE',
    STORE_CLIENT_SIGNATURE: 'MissionClientsActionType/CLIENT_SIGNATURE',
    POST_CLIENT_SIGNATURE: 'MissionClientsActionType/POST_CLIENT_SIGNATURE',
    POST_SIGN_DOCUMENT: 'MissionClientsActionType/POST_SIGN_DOCUMENT',
    POST_VALIDATE_DOCUMENT: 'MissionClientsActionType/POST_VALIDATE_DOCUMENT',
    POST_REQUIRE_MODIFICATIONS: 'MissionClientsActionType/POST_REQUIRE_MODIFICATIONS',
    SET_CLIENT_SCREEN: 'MissionClientsActionType/SET_CLIENT_SCREEN',
    DOWNLOAD_DOCUMENT: 'MissionClientActionType/DOWNLOAD_DOCUMENT',
};
