import {Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {LocalesConstants} from '../../../../utils/locales-constants';

export const TrainingHeadline = () => {
    const {t} = useTranslation(LocalesConstants.Training);

    return (
        <Typography variant="h4">
            {t('header')}
        </Typography>
    );
};
