import {
    faHourglassHalf,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, ButtonGroup} from '@mui/joy';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {FORMALITIES_TABS} from '../../utils/constants';

export const FormalitiesTabMenu = ({activeTab, onChange}) => {
    const {t} = useTranslation(LocalesConstants.Companies);

    const handleChange = value => {
        if (value !== activeTab) {
            onChange(value);
        }
    };

    return (
        <ButtonGroup
            orientation="horizontal"
            variant="outlined"
            size="sm"
            color="neutral"
            row
            value={activeTab}
            onChange={onChange}
        >
            {Object.values(FORMALITIES_TABS).map(tabId => {
                const isActive = tabId === activeTab;

                return (
                    <Button
                        sx={{
                            py: 1,
                            px: 4,
                            justifyContent: 'center',
                            ...(!isActive ? {
                                backgroundColor: 'background.surface',
                            } : {}),
                            flex: 1,
                            height: '32px',
                        }}
                        key={tabId}
                        variant="soft"
                        color={isActive ? 'primary' : 'neutral'}
                        onClick={() => handleChange(tabId)}
                        /* TODO: Remove this once the OFFICE_CHANGE tab is built */
                        disabled={tabId === FORMALITIES_TABS.OFFICE_CHANGE}
                        startDecorator={
                            (tabId === FORMALITIES_TABS.OFFICE_CHANGE) && (
                                <Box color="neutral.400" fontSize="xs">
                                    <FontAwesomeIcon icon={faHourglassHalf} fixedWidth />
                                </Box>
                            )
                        }
                    >
                        {t(`formalities.tabs.${tabId}`)}
                    </Button>
                );
            })}
        </ButtonGroup>
    );
};

FormalitiesTabMenu.propTypes = {
    activeTab: PropTypes.oneOf(Object.values(FORMALITIES_TABS)).isRequired,
    onChange: PropTypes.func.isRequired,
};
