export const MissionStatus = {
    Created: 'CREATED',
    QuoteSentToClient: 'QUOTE_SENT_TO_CLIENT',
    QuotePendingClientSignature: 'QUOTE_PENDING_CLIENT_SIGNATURE',
    QuoteModificationRequested: 'QUOTE_MODIFICATION_REQUESTED',
    QuoteInHiwayReview: 'QUOTE_IN_HIWAY_REVIEW',
    WaitingForWorkContract: 'WAITING_FOR_WORK_CONTRACT',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
};

export const MissionContractDocumentStatus = {
    Generated: 'GENERATED',
    UserSigned: 'USER_SIGNED',
    Sent: 'SENT',
    Validated: 'VALIDATED',
    ModificationRequested: 'MODIFICATION_REQUESTED',
    Canceled: 'CANCELED',
};

export const MissionCraStatus = {
    Generated: 'GENERATED',
    UserSigned: 'USER_SIGNED',
    Sent: 'SENT',
    Validated: 'VALIDATED',
    ModificationRequested: 'MODIFICATION_REQUESTED',
    Canceled: 'CANCELED',
};

export const MISSION_CLIENT_SCREENS = {
    PENDING_SIGNING: 'PENDING_SIGNING',
    REQUESTED_MODIFICATIONS: 'REQUESTED_MODIFICATIONS',
    TOKEN_EXPIRED: 'TOKEN_EXPIRED',
};

export const MISSION_CLIENT_SIDE_TYPE = {
    CRA: 'cra',
    BDC: 'quote',
};
