import {combineReducers} from 'redux';
import * as actions from './app/store/app.actions';
import {createReducer} from './app/utils/store/create-reducer';
import {animationsReducer} from './features/animations/store/animations.reducer';
import {authReducer} from './features/auth/store/auth.reducer';
import {backofficeReducer} from './features/backoffice/store/backoffice.reducer';
import {bankReducer} from './features/bank/store/bank.reducer';
import {changePasswordFormReducer} from './features/change-password/store/changePasswordForm.reducer';
import {accountingReducer} from './features/company-profile/modules/accounting/store/accounting.reducer';
import {payslipReducer} from './features/company-profile/modules/payslip/store/payslip.reducer';
import {quickAccessReducer} from './features/company/modules/documents-quick-access/store/quick-access.reducer';
import {companyReducer} from './features/company/store/company.reducer';
import {contractReducer} from './features/contract/store/contract.reducer';
import {v1SignContractReducer} from './features/contract/store/sign-contract/sign-contract.reducer';
import {dashboardV3Reducer} from './features/dashboard-v3/store/dashboard-v3.reducer';
import {dashboardReducer} from './features/dashboard/store/dashboard.reducer';
import {documentReducer} from './features/document/store/document.reducer';
import {documentationReducer} from './features/documentation/store/documentation.reducer';
import {expensesReducer} from './features/expenses/store/expenses.reducer';
import {financialStatementsReducer} from './features/financial-statements/store/financial-statements.reducer';
import {freelancerReducer} from './features/freelancer';
import {v1MyCompaniesReducer} from './features/freelancer/modules/my-companies/store/my-companies.reducer';
import {insuranceReducer} from './features/insurance/store/insurance.reducer';
import {invoiceReducer} from './features/invoicing/store/invoice.reducer';
import {professionAndSpecializationReducer} from './features/job-profession-and-specilties/store/profession-and-specialization.reducer';
import {loadingReducer} from './features/loading';
import {missionsClientReducer} from './features/missions/modules/client-side/store/missions-client.reducer';
import {missionsReducer} from './features/missions/store/missions.reducer';
import {v1NotificationReducer} from './features/notifications/store/notifications.reducer';
import {mainOnboardingReducer} from './features/onboarding/store/main-onboarding.reducer';
import {opportunityReducer} from './features/opportunity/store/opportunity.reducer';
import {revenueSimulatorReducer} from './features/revenue-simulator/store/revenue-simulator.reducer';
import {SSEReducer} from './features/server-side-events/store/sse.reducer';
import {settingsReducer} from './features/settings/store/settings.reducer';
import {sidebarReducer} from './features/sidebar/store/sidebar.reducer';
import {signatureReducer} from './features/signature/store/signature.reducer';
import {themeReducer} from './features/theme/store/theme.reducer';
import {boTrainingReducer} from './features/training-bo/store/bo-training.reducer';
import {trainingReducer} from './features/training/store/training.reducer';
import {uiReducer} from './features/ui/store/ui.reducer';
import {umbrellaUsersListReducer} from './features/umbrella-users-list/store/umbrella-users-list.reducer';
import {userReducer} from './features/user/store/user.reducer';
import {vatDeclarationReducer} from './features/vat-declaration/store/vat-declaration.reducer';
import {createRouterReducer} from './lib/router/connected-router-saga';

export const createRootReducer = history => combineReducers({
    router: createRouterReducer(history),
    auth: authReducer,
    theme: themeReducer,
    company: companyReducer,
    collapsedSidebarLists: createReducer([], actions.SET_COLLAPSED_SIDEBAR_LISTS),
    isLoadingApp: createReducer(false, actions.SET_IS_LOADING_APP), // TODO Check for initial state
    isMaintenanceModeActive: createReducer(false, actions.SET_IS_MAINTENANCE_MODE_ACTIVE),
    isSidebarShrunk: createReducer(false, actions.SET_IS_SIDEBAR_SHRUNK),
    v1MyCompanies: v1MyCompaniesReducer,
    v1Notifications: v1NotificationReducer,
    shouldRenderCompanyRegisteredOverlay: createReducer(false, actions.SET_SHOULD_RENDER_COMPANY_REGISTERED_OVERLAY),
    v1SignContract: v1SignContractReducer,
    user: userReducer,
    expenses: expensesReducer,
    loading: loadingReducer,
    sidebar: sidebarReducer,
    freelancer: freelancerReducer,
    document: documentReducer,
    documentation: documentationReducer,
    contract: contractReducer,
    ui: uiReducer,
    signature: signatureReducer,
    changePassword: changePasswordFormReducer,
    settings: settingsReducer,
    animations: animationsReducer,
    backoffice: backofficeReducer,
    dashboard: dashboardReducer,
    professionAndSpecialization: professionAndSpecializationReducer,
    opportunity: opportunityReducer,
    training: trainingReducer,
    boTraining: boTrainingReducer,
    payslip: payslipReducer,
    bank: bankReducer,
    sse: SSEReducer,
    invoice: invoiceReducer,
    accounting: accountingReducer,
    insurance: insuranceReducer,
    quickAccessDocuments: quickAccessReducer,
    financialStatements: financialStatementsReducer,
    revenueSimulator: revenueSimulatorReducer,
    dashboardV3: dashboardV3Reducer,
    vatDeclaration: vatDeclarationReducer,
    mainOnboarding: mainOnboardingReducer,
    missions: missionsReducer,
    publicClient: missionsClientReducer,
    umbrellaUsersList: umbrellaUsersListReducer,
});
