import {faCircleExclamation} from '@fortawesome/free-solid-svg-icons';
import {Stack} from '@mui/joy';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {DocumentUploadSection} from './DocumentUploadSection';
import {InputFieldsSection} from './InputFieldsSection';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {VatDeclarationSelectors} from '../../store/vat-declaration.selector';
import {StatusAlert} from '../results/StatusAlert';

export const VatDeclarationManualEdit = () => {
    const {t} = useTranslation(LocalesConstants.VatDeclaration);
    const manualValidationCustomError = useSelector(VatDeclarationSelectors.selectManualValidationCustomError);

    useEffect(() => {
        if (manualValidationCustomError) {
            window.scrollTo(0, 0);
        }
    }, [manualValidationCustomError]);

    return (
        <Stack spacing={2} pb={10} maxWidth="600px" margin="0 auto">
            {manualValidationCustomError && (
                <StatusAlert
                    color="danger"
                    icon={faCircleExclamation}
                    title={t(`customErrors.${manualValidationCustomError}`)}
                />
            )}

            <DocumentUploadSection />
            <InputFieldsSection />
        </Stack>
    );
};
