import {safeTransformData} from '../../../../../app/api/axios';
import {hiwayApiInstance} from '../../../../../lib/hiway-api-instance';
import {invoiceApiInstance} from '../../../../../lib/invoice-api-instance';

const putSignDocument = ({freelancerId, documentId, data}) => {
    return hiwayApiInstance
        .put(`/v1/freelancers/${freelancerId}/documents/${documentId}/sign`, data)
        .then(response => safeTransformData(response));
};

const getSignedUrlForDocumentRequest = (freelancerId, companyId, documentId, isDownload = false) => {
    return hiwayApiInstance({
        method: 'GET',
        params: {
            content_disposition: isDownload ? 'attachment' : 'inline',
            preview: isDownload ? undefined : true,
        },
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/documents/${documentId}/sign-url-get-document`,
    }).then(response => safeTransformData(response));
};

const signDocumentRequest = (freelancerId, companyId, documentId) => {
    return hiwayApiInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/documents/${documentId}/sign`,
    }).then(response => safeTransformData(response));
};

const signAccountingDocumentRequest = (companyId, documentId) => {
    return invoiceApiInstance({
        method: 'PUT',
        url: `/v1/companies/${companyId}/documents/${documentId}/sign`,
    }).then(response => safeTransformData(response));
};

export const SigningApi = {
    putSignDocument,
    getSignedUrlForDocumentRequest,
    signDocumentRequest,
    signAccountingDocumentRequest,
};
