import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PeopleIcon from '@mui/icons-material/People';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PersonIcon from '@mui/icons-material/Person';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import {useEffect} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {CakeIcon} from '../../../../../../assets/wrapped-svg/cake';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {FieldBlock} from '../FieldBlock';
import {FormSection} from '../FormSection';
import {FormSectionRow} from '../FormSectionRow';
import {NumberField} from '../NumberField';
import {ToggleField} from '../ToggleField';

const NUMBER_OF_PARTS_STEP = 0.25;

export const MySituationStep1 = () => {
    const {t} = useTranslation(LocalesConstants.RevenueSimulator);
    const {setValue, getValues, trigger} = useFormContext();

    const numberOfAdults = useWatch({
        name: 'personalInfo.numberOfAdults',
        defaultValue: getValues('personalInfo.numberOfAdults') || 1,
    });

    useEffect(() => {
        const numberOfParts = getValues('personalInfo.numberOfParts');
        if (numberOfAdults > numberOfParts) {
            setValue('personalInfo.numberOfParts', numberOfAdults);
        }
        trigger(); // Trigger validation explicitly because rules for `min` value change for numberOfParts
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numberOfAdults]);

    return (
        <FormSection Icon={BusinessCenterIcon} title={t('personalInfo.myPersonalSituation')}>
            <FormSectionRow>
                <FieldBlock Icon={CakeIcon} title={t('personalInfo.age')}>
                    <NumberField
                        withSlider
                        required
                        defaultValue={18}
                        name="personalInfo.age"
                        min={18}
                        max={67}
                        inputStyles={{
                            width: '88px',
                            textAlign: 'center',
                        }}
                    />
                </FieldBlock>
                <FieldBlock
                    Icon={PeopleAltOutlinedIcon}
                    title={t('personalInfo.numberOfAdults.title')}
                    subtitle={t('personalInfo.numberOfAdults.subtitle')}
                    containerSx={{gap: 0}}
                >
                    <ToggleField
                        name="personalInfo.numberOfAdults"
                        required
                        defaultValue={1}
                        orientation="vertical"
                        options={[
                            {label: t('personalInfo.numberOfAdults.one'), value: 1, icon: PersonIcon},
                            {label: t('personalInfo.numberOfAdults.two'), value: 2, icon: PeopleIcon},
                        ]}
                    />
                </FieldBlock>
                <FieldBlock
                    Icon={SupervisorAccountOutlinedIcon}
                    title={t('personalInfo.numberOfParts.title')}
                    subtitle={t('personalInfo.numberOfParts.subtitle')}
                >
                    <NumberField
                        name="personalInfo.numberOfParts"
                        required
                        defaultValue={1}
                        min={numberOfAdults}
                        decimalScale={2}
                        decimalSeparator="."
                        allowedDecimalSeparators={[',']}
                        validate={value => Number.isInteger(value / NUMBER_OF_PARTS_STEP)}
                        inputStyles={{
                            width: theme => theme.typography.pxToRem(88),
                            textAlign: 'center',
                        }}
                    />
                </FieldBlock>
            </FormSectionRow>
        </FormSection>
    );
};
