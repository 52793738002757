import {Box, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import ClientFormSigning from './ClientSigning';
import {useIsMobileSize} from '../../../../../hooks/use-is-mobile-size';

const ClientPage = ({title, isSigning}) => {
    const isMobileSize = useIsMobileSize();

    return (
        <>
            <Box sx={{
                position: 'sticky',
                top: 0,
                width: '100vw',
                height: '56px',
                display: 'flex',
                p: 1,
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                alignSelf: 'stretch',
                boxShadow: 'md',
                backgroundColor: 'linen.50',
            }}
            >
                <Typography
                    level={isMobileSize ? 'body-md' : 'h2'}
                    textAlign="center"
                    textColor="text.primary"
                    fontWeight="xl"
                >
                    {title}
                </Typography>
            </Box>
            <Box sx={{
                padding: 1,
                width: '100%',
                height: 'calc(100vh - 56px)',
                backgroundColor: 'linen.50',
                display: 'flex',
                justifyContent: 'center',
            }}
            >
                <Box sx={{
                    maxWidth: '1200px', // TODO Add to layout
                    width: '100%',
                }}
                >
                    {isSigning && (
                        <ClientFormSigning />
                    )}
                </Box>
            </Box>
        </>
    );
};

ClientPage.propTypes = {
    title: PropTypes.string.isRequired,
    isSigning: PropTypes.bool.isRequired,
};

export default ClientPage;
