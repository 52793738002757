import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Slide, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import BankKycImage from '../../../../../../assets/svg/bank/kyc.svg';
import BankInformationLayout from '../../../../../../layout/BankInformationLayout/BankInformationLayout';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {AnimationActions} from '../../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../../../animations/utils/constants';
import {BankActions} from '../../../../../bank/store/bank.action';
import {BankSelector} from '../../../../../bank/store/bank.selector';
import {KycIdentificationStatus} from '../../utils/constants';

const CapitolDepositBankKYCValidation = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation(LocalesConstants.Bank);
    const {t: tOnboarding} = useTranslation(LocalesConstants.FreelancerOnboarding);

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const integration = useSelector(BankSelector.selectIntegrations);
    const status = integration?.[0]?.kyc?.identificationStatus;

    const onContinueClick = () => {
        dispatch(BankActions.startKyc());
    };

    const notStarted = !status || status === KycIdentificationStatus.NOT_STARTED;
    const isWaitingForVerification = !notStarted;

    return (
        <BankInformationLayout
            header={tOnboarding('capitalKYC.headline')}
            subTitle={tOnboarding('capitalKYC.subtitle')}
            Button={(
                <Button
                    color="secondary"
                    variant="contained"
                    endIcon={<ArrowForwardIcon />}
                    onClick={onContinueClick}
                >
                    {notStarted ? (
                        <Box>{tOnboarding('capitalKYC.validateButton')}</Box>
                    ) : (
                        <Box>{tOnboarding('capitalKYC.validateButton')}</Box>
                    )}
                </Button>
            )}
        >
            <Slide in={isAnimationActive} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '600px',
                }}
                >
                    {notStarted && (
                        <Box>
                            <img src={BankKycImage} alt="pending kyc" />
                            <Box sx={{mb: 2, mt: 2}}>
                                <Typography variant="h4">
                                    {t('kycPage.headerNotStarted')}
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            >
                                <Box sx={{
                                    width: '400px',
                                    textAlign: 'center',
                                }}
                                >
                                    {t('kycPage.textNotStarted')}
                                </Box>
                            </Box>
                        </Box>
                    )}

                    {isWaitingForVerification && (
                        <Box>
                            <img src={BankKycImage} alt="pending kyc" />
                            <Box sx={{mb: 2, mt: 2}}>
                                <Typography variant="h4">
                                    {t('kycPage.headerWaitingVerification')}
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            >
                                <Box sx={{
                                    width: '380px',
                                    textAlign: 'center',
                                }}
                                >
                                    {t('kycPage.textWaitingVerification')}
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Slide>
        </BankInformationLayout>
    );
};

export default CapitolDepositBankKYCValidation;
