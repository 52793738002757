import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';
import {roundHalfUpToTwoDecimalPlaces} from '../../../../../../../../utils/number';
import {infoSectionStyles} from '../../../transaction-details/styles';

const AlreadyPaidInfo = ({
    amount,
    allocated,
}) => {
    const {t} = useTranslation(LocalesConstants.Bank);
    const percents = !amount
        ? 100
        : Math.round(100 * allocated / amount);

    const isCompleted = percents >= 100 || (Number(amount) - Number(allocated) <= 1);

    return (
        <Box sx={{
            ...infoSectionStyles,
            backgroundColor: isCompleted ? 'v2.light.success.light' : 'gray_v2.50',
            color: isCompleted ? 'white' : 'inherited',
        }}
        >
            <Box sx={{letterSpacing: '0.15px'}}>
                <Typography sx={{fontWeight: 500, display: 'inline'}}>
                    {t('invoices.alreadyPaidAmount')} :&nbsp;&nbsp;
                </Typography>

                <Typography component="span" sx={{fontWeight: 500}}>
                    {roundHalfUpToTwoDecimalPlaces(allocated)}&nbsp;€
                </Typography>
                <Typography component="span" sx={{fontWeight: 400}}>
                    &nbsp;&nbsp;/&nbsp;&nbsp;{roundHalfUpToTwoDecimalPlaces(amount)}&nbsp;€
                </Typography>

                <Box sx={{
                    height: '4px',
                    borderRadius: '2px',
                    backgroundColor: 'primary_v2.shades60',
                    position: 'relative',
                }}
                >
                    <Box sx={{
                        height: '4px',
                        borderRadius: '2px',
                        backgroundColor: isCompleted ? 'white' : 'primary_v2.main',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: `${percents}%`,
                    }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

AlreadyPaidInfo.propTypes = {
    amount: PropTypes.number.isRequired,
    allocated: PropTypes.number.isRequired,
};

export default AlreadyPaidInfo;
