import {Typography} from '@mui/joy';
import {Typography as MuiTypography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {useNoInputStyles} from './styles';

const NoInput = ({noInputText, isV3}) => {
    const {t} = useTranslation();
    const styles = useNoInputStyles();

    return isV3 ? (
        <Typography
            level="body-md"
            sx={{
                color: 'text.tertiary',
                fontStyle: 'italic',
            }}
        >
            {noInputText ?? t('common:noInput')}
        </Typography>
    ) : (
        <MuiTypography variant="body2" className={styles.root}>
            {noInputText ?? t('common:noInput')}
        </MuiTypography>
    );
};

NoInput.propTypes = {
    noInputText: PropTypes.string,
    isV3: PropTypes.bool,
};

NoInput.defaultProps = {
    noInputText: null,
    isV3: false,
};

export default NoInput;
