import {faArrowRight, faClose} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Stack, Typography, useTheme} from '@mui/joy';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {EurAmount} from '../../../../../components/eur-amount/EurAmount';
import {back} from '../../../../../lib/router/connected-router-saga';
import {LocalesConstants} from '../../../../../utils/locales-constants';

export const CreateCraFormFooter = ({calendarRef, onConfirmClick, amount, amountWithTax, isMobileSize}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation(LocalesConstants.Missions);
    const theme = useTheme();

    const [width, setWidth] = useState(600);

    const onCancelClick = () => {
        dispatch(back());
    };

    useEffect(() => {
        if (calendarRef.current) {
            const {width} = calendarRef.current.getBoundingClientRect();
            setWidth(width);
        }
    }, [calendarRef]);

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                borderRadius: 'lg',
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
                boxShadow: 'lg',
                p: 2,
                bgcolor: 'background.surface',
                display: 'flex',
                flexDirection: {desktop: 'row', mobile: 'column'},
                gap: 1,
                justifyContent: {desktop: 'space-between', mobile: 'flex-start'},
                alignItems: {desktop: 'center', mobile: 'stretch'},
                mr: 0.5,
                ml: {mobile: 2, desktop: 0},
                width: `${width}px`,
                borderTop: '1px solid',
                borderRight: '1px solid',
                borderLeft: '1px solid',
                borderColor: 'neutral.outlinedBorder',
            }}
        >
            <Stack
                direction="column"
            >
                <Typography
                    level={isMobileSize ? 'h3' : 'title-md'}
                    textColor="text.secondary"
                >
                    {t('cra.summary')} :
                </Typography>
                <Stack
                    sx={{mr: 'auto', ml: 0.5}}
                    gap={3}
                    direction="row"
                >
                    <Typography
                        level="body-md"
                    >
                        {t('cra.totalHt')} •{' '}
                        <EurAmount
                            style={{
                                color: theme.palette.text.primary,
                                fontWeight: 700,
                                fontStyle: 'italic',
                            }}
                            amount={amount}
                        />
                    </Typography>
                    <Typography
                        level="body-md"
                    >
                        {t('cra.totalTtc')} •{' '}
                        <EurAmount
                            style={{
                                color: '#7B2329', // TODO: use theme
                                fontWeight: 700,
                                fontStyle: 'italic',
                            }}
                            amount={amountWithTax}
                        />
                    </Typography>
                </Stack>
            </Stack>

            <Stack
                direction={isMobileSize ? 'column-reverse' : 'row'}
                spacing={isMobileSize ? 1 : 2}
            >
                <Button
                    size="md"
                    color="neutral"
                    variant="outlined"
                    startDecorator={<FontAwesomeIcon icon={faClose} />}
                    loading={false}
                    loadingPosition="end"
                    fullWidth={isMobileSize}
                    onClick={onCancelClick}
                >
                    {t('cra.cancel')}
                </Button>
                <Button
                    variant="solid"
                    color="success"
                    size="md"
                    endDecorator={<FontAwesomeIcon icon={faArrowRight} />}
                    loading={false}
                    loadingPosition="end"
                    fullWidth={isMobileSize}
                    onClick={onConfirmClick}
                    disabled={amount === 0}
                >
                    {t('cra.submitCra')}
                </Button>
            </Stack>
        </Box>
    );
};

CreateCraFormFooter.propTypes = {
    calendarRef: PropTypes.object.isRequired,
    onConfirmClick: PropTypes.func.isRequired,
    amount: PropTypes.number.isRequired,
    amountWithTax: PropTypes.number.isRequired,
    isMobileSize: PropTypes.bool.isRequired,
};
