import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {
    ReimbursementAdditionalSection,
    ReimbursementBottomAlert,
    ReimbursementMainSection,
    ReimbursementMainSectionTitle,
    ReimbursementResult,
} from './RembursementLayoutSegments';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';
import useReimbusement from '../../../hooks/use-reimbusement';
import {DRAG_ITEMS} from '../../utils/constants';
import CreditDnDLayerWrapper from '../credit/CreditDnDLayerWrapper';
import {InvoiceResultAlert} from '../credit/CreditInvoiceLayoutSegments';

const ReinburnsmentLayout = ({
    details,
    isEditable,
    handleOpenAllCategories,
    setIsFakeReimbursementOpen,
}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation(LocalesConstants.Bank);

    const [activeTransaction, setActiveTransaction] = useState('');

    useEffect(() => {
        setActiveTransaction('');
    }, [details?.id]);

    const {
        amount,
        selectedReimbursementTransactionsObj,
        selectedReimbursementTransactions,
        paidAmount,
        unpaidAmount,
        perfectMatches,
        suggestedMatches,
        onSelectTransaction,
    } = useReimbusement({details, dispatch, isEditable});

    const numberOfNotSelected = {
        main: perfectMatches.filter(element => element.selected === false).length ?? 0,
        other: suggestedMatches.filter(element => element.selected === false).length ?? 0,
    };

    const onDrop = item => {
        let amount;
        if (item?.partialAllocatedValue) {
            amount = Math.min(Number(item.partialAllocatedValue), Number(unpaidAmount));
        } else {
            amount = Math.min(Number(item?.amount), Number(unpaidAmount));
        }

        onSelectTransaction(item?.id, amount, true);
    };

    return (
        <CreditDnDLayerWrapper
            categorySectionTitle={t('categorisation.rembursement.categorySectionTitle')}
            mainSectionTitle={<ReimbursementMainSectionTitle />}
            selectedSectionTitle={t('categorisation.rembursement.selectedSectionTitle')}
            mainSectionAccordionTitle={t('categorisation.rembursement.mainSectionAccordionTitle')}
            otherSectionAccordionTitle={t('categorisation.rembursement.otherSectionTitle')}
            hasShownDropArea={unpaidAmount !== 0}
            MainSection={ReimbursementMainSection}
            AdditionalSection={ReimbursementAdditionalSection}
            ResultSection={ReimbursementResult}
            BottomAlert={ReimbursementBottomAlert}
            ResultAlert={InvoiceResultAlert}
            onDrop={onDrop}
            dndDependancy={selectedReimbursementTransactions}
            data={{
                details,
                handleOpenAllCategories,
                selectedReimbursementTransactionsObj,
                perfectMatches,
                suggestedMatches,
                selectedReimbursementTransactions,
                unpaidAmount,
                onSelectTransaction,
                setIsFakeReimbursementOpen,
                activeTransaction,
                setActiveTransaction,
                amount,
                hasSuggestions: true,
                paidAmount,
                numberOfNotSelected,
                type: DRAG_ITEMS.REIMBURSEMENT,
            }}
        />
    );
};

ReinburnsmentLayout.propTypes = {
    details: PropTypes.object.isRequired,
    isEditable: PropTypes.bool.isRequired,
    handleOpenAllCategories: PropTypes.func.isRequired,
    setIsFakeReimbursementOpen: PropTypes.func.isRequired,
};

export default ReinburnsmentLayout;
