import InfoIcon from '@mui/icons-material/Info';
import {Box, Button, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';
import {TransactionsActions} from '../../../../store/transactions.action';
import {CATEGORIZATION_SIDEBAR_TYPE} from '../../../../util/constants';
import CategorisationSection from '../header/CategorisationSection';

const RejectedAndCancelled = ({
    isRejected,
    isCanceled,
}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation(LocalesConstants.Bank);

    const onButtonClick = () => {
        dispatch(TransactionsActions.setCategorizationSidebar(CATEGORIZATION_SIDEBAR_TYPE.INFO));
    };

    return (
        <CategorisationSection
            title=""
            sx={{
                flex: '2 0 0',
                pb: 3,
                px: 3,
                pt: 1,
                justifyContent: 'space-between',
                gap: 1,
            }}
            isActive
            isFocused={true}
        >
            <Box sx={{
                textAlign: 'center',
            }}
            >
                <Typography
                    sx={{
                        fontWeight: '500',
                    }}
                >
                    {isRejected && t(`categorisation.rejectedCanceled.rejectedTitle`)}
                    {isCanceled && t(`categorisation.rejectedCanceled.canceledTitle`)}
                </Typography>

                <Typography
                    sx={{
                        mb: 3,
                        mt: 1,
                        fontSize: theme => theme.typography.pxToRem(14),
                    }}
                >
                    {t(`categorisation.rejectedCanceled.text1`)}
                    <br />
                    {t(`categorisation.rejectedCanceled.text2`)}
                </Typography>

                <Button
                    sx={{
                        py: 0.6,
                    }}
                    size="small"
                    startIcon={<InfoIcon />}
                    variant="outlined"
                    type="primary"
                    onClick={onButtonClick}
                >
                    {t(`categorisation.rejectedCanceled.button`)}
                </Button>
            </Box>
        </CategorisationSection>
    );
};

RejectedAndCancelled.propTypes = {
    isRejected: PropTypes.bool.isRequired,
    isCanceled: PropTypes.bool.isRequired,
};

export default RejectedAndCancelled;
