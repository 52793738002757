export const DOCUMENT_CONTEXTS = {
    SIGNABLE: 'signable',
    DATABASE: 'database',
    PENDING_SIGNING: 'pending_signing',
};

export const DOCUMENT_TYPES = {
    ACCOUNTANT_ASSIGNING_LETTER: 'ACCOUNTANT_ASSIGNING_LETTER',
    ARCE_1: 'ARCE_1',
    ARCE_2: 'ARCE_2',
    ATTESTATION_TVA: 'ATTESTATION_TVA',
    BALANCE_SHEET: 'BALANCE_SHEET',
    LIASSE_FISCALE: 'LIASSE_FISCALE',
    BANK_DELEGATED_ACCESS: 'BANK_DELEGATED_ACCESS',
    BUSINESS_KM_RECAP: 'BUSINESS_KM_RECAP',
    CAPITAL_DEPOSIT: 'CAPITAL_DEPOSIT',
    CAPITAL_DEPOSIT_STATUS: 'CAPITAL_DEPOSIT_STATUS',
    CERTIFICATE_OF_ACHIEVEMENT: 'CERTIFICATE_OF_ACHIEVEMENT',
    CRA_DOC: 'CRA_DOC',
    CRA_TEMPLATE: 'CRA_TEMPLATE',
    DEVIS_TEMPLATE: 'DEVIS_TEMPLATE',
    TUTORIAL_QB_CREATION_AND_CONNECTION: 'TUTORIAL_QB_CREATION_AND_CONNECTION',
    TUTORIAL_TAXES_DOWN_PAYMENT_MANAGEMENT: 'TUTORIAL_TAXES_DOWN_PAYMENT_MANAGEMENT',
    DNC: 'DNC',
    DOMICILIATION_CERTIFICATE: 'DOMICILIATION_CERTIFICATE',
    END_CLIENT_SERVICE_PROVIDER: 'END_CLIENT_SERVICE_PROVIDER',
    EXPENSES_PERSONAL: 'EXPENSES_PERSONAL',
    EXPENSES_PROFESSIONAL: 'EXPENSES_PROFESSIONAL',
    HOME_RENTAL_CONTRACT: 'HOME_RENTAL_CONTRACT',
    HIWAY_ACCOUNTING_MISSION_LETTER: 'HIWAY_ACCOUNTING_MISSION_LETTER',
    ID_DOC: 'ID_DOC',
    INVOICE: 'INVOICE',
    JOB_DOC: 'JOB_DOC',
    MIDDLEMAN_SERVICE_PROVIDER: 'MIDDLEMAN_SERVICE_PROVIDER',
    NIN_DOC: 'NIN_DOC',
    OGI_FRANCE_MEMBERSHIP: 'OGI_FRANCE_MEMBERSHIP',
    OTHER: 'OTHER',
    PARTNERS_LIST: 'PARTNERS_LIST',
    PAYSLIP: 'PAYSLIP',
    REGISTRATION_STATUS: 'REGISTRATION_STATUS',
    RESIDENCE_CERT: 'RESIDENCE_CERT',
    RESIDENCE_CERT_HOST: 'RESIDENCE_CERT_HOST',
    RESIDENCE_CERT_HOST_ID: 'RESIDENCE_CERT_HOST_ID',
    SALARIED_PRESIDENT_CERTIFICATE: 'SALARIED_PRESIDENT_CERTIFICATE',
    TAX_OPTION: 'TAX_OPTION',
    TRAINING_AGREEMENT: 'TRAINING_AGREEMENT',
    TRAINING_INVOICE: 'TRAINING_INVOICE',
    TRAINING_PROGRAM: 'TRAINING_PROGRAM',
    TRAINING_TUTORIAL: 'TRAINING_TUTORIAL',
    TUTORIAL_EMPLOYMENT_CENTER: 'TUTORIAL_EMPLOYMENT_CENTER',
    TUTORIAL_QONTO: 'TUTORIAL_QONTO',
    VIGILANCE_CERT: 'VIGILANCE_CERT',
    LEGAL_NOTICE: 'LEGAL_NOTICE',
    DIRECT_DEBIT_MANDATE: 'DIRECT_DEBIT_MANDATE',
    KBIS: 'KBIS',
    NON_REJECTION_ACRE_CERT: 'NON_REJECTION_ACRE_CERT',
    POWER_OF_ATTORNEY_REGISTRATION_MANDATE: 'POWER_OF_ATTORNEY_REGISTRATION_MANDATE',
    BANK_ACCOUNT_DETAILS: 'BANK_ACCOUNT_DETAILS',
    BANK_ACCOUNT_STATEMENT: 'BANK_ACCOUNT_STATEMENT',
    INSURANCE_GROUP_INSURANCE_DIRECT_DEBIT_MANDATE: 'INSURANCE_GROUP_INSURANCE_DIRECT_DEBIT_MANDATE',
    VALIDATED_SYNTHESIS: 'VALIDATED_SYNTHESIS',
    ANNUAL_ACCOUNT_PRIVACY_STATEMENT: 'ANNUAL_ACCOUNT_PRIVACY_STATEMENT',
    POWER_OF_ATTORNEY_SUBMISSION_OF_ACCOUNTS: 'POWER_OF_ATTORNEY_SUBMISSION_OF_ACCOUNTS',
    PV_AGO: 'PV_AGO',
    ANNUAL_ACCOUNT_BALANCE_SHEET: 'ANNUAL_ACCOUNT_BALANCE_SHEET',
    ANNUAL_ACCOUNT_INCOME_STATEMENT: 'ANNUAL_ACCOUNT_INCOME_STATEMENT',
    // Added with Portage onboarding
    RIB: 'RIB',
    CV: 'CV',
    DRIVERS_LICENCE: 'DRIVERS_LICENCE',
    VEHICLE_REGISTRATION_CARD: 'VEHICLE_REGISTRATION_CARD',
    PERSONAL_INSURANCE: 'PERSONAL_INSURANCE',
    WORK_CONTRACT: 'WORK_CONTRACT',
};

export const DOCUMENT_CATEGORIES = {
    ADMINISTRATIVE: 'ADMINISTRATIVE',
    COMPANY_SETUP: 'COMPANY_SETUP',
    EXPENSES: 'EXPENSES',
    MISSION: 'MISSION',
    PERSONAL: 'PERSONAL',
    TAXES: 'TAXES',
    TRAINING: 'TRAINING',
    PAY: 'PAY',
    BANK: 'BANK',
    BANK_ACCOUNT_STATEMENT: 'BANK_ACCOUNT_STATEMENT',
    FORMALITY: 'FORMALITY',
};

export const DOCUMENT_STATUSES = {
    DONE: 'DONE',
    ERROR: 'ERROR',
    GENERATING: 'GENERATING',
    PENDING_COMPANY_ID: 'PENDING_COMPANY_ID',
    PENDING_SIGNING: 'PENDING_SIGNING',
    SIGNED: 'SIGNED',
    GENERATED: 'GENERATED',
};

export const DATA_TABLE_TAB_FILTERS = [
    DOCUMENT_CATEGORIES.ADMINISTRATIVE,
    DOCUMENT_CATEGORIES.COMPANY_SETUP,
    DOCUMENT_CATEGORIES.MISSION,
    DOCUMENT_CATEGORIES.PERSONAL,
    DOCUMENT_CATEGORIES.TRAINING,
    DOCUMENT_CATEGORIES.PAY,
    DOCUMENT_CATEGORIES.BANK_ACCOUNT_STATEMENT,
    DOCUMENT_CATEGORIES.FORMALITY,
];

export const GLOBAL_DOCUMENT_TYPES = [
    DOCUMENT_TYPES.ARCE_1,
    DOCUMENT_TYPES.ARCE_2,
    DOCUMENT_TYPES.CRA_TEMPLATE,
    DOCUMENT_TYPES.DEVIS_TEMPLATE,
    DOCUMENT_TYPES.TUTORIAL_QB_CREATION_AND_CONNECTION,
    DOCUMENT_TYPES.TUTORIAL_TAXES_DOWN_PAYMENT_MANAGEMENT,
    DOCUMENT_TYPES.END_CLIENT_SERVICE_PROVIDER,
    DOCUMENT_TYPES.MIDDLEMAN_SERVICE_PROVIDER,
    DOCUMENT_TYPES.TUTORIAL_EMPLOYMENT_CENTER,
    DOCUMENT_TYPES.TUTORIAL_QONTO,
    DOCUMENT_TYPES.TRAINING_TUTORIAL,
    DOCUMENT_TYPES.TRAINING_PROGRAM,
    DOCUMENT_TYPES.TRAINING_AGREEMENT,
];

export const UPLOADABLE_DOCUMENT_CATEGORIES = {
    [DOCUMENT_CATEGORIES.ADMINISTRATIVE]: 'ADMINISTRATIVE',
    [DOCUMENT_CATEGORIES.COMPANY_SETUP]: 'COMPANY_SETUP',
    [DOCUMENT_CATEGORIES.MISSION]: 'MISSION',
    [DOCUMENT_CATEGORIES.TRAINING]: 'TRAINING',
    [DOCUMENT_CATEGORIES.PAY]: 'PAY',
    [DOCUMENT_CATEGORIES.FORMALITY]: 'FORMALITY',
};

export const UPLOADABLE_DOCUMENT_CATEGORIES_FOR_FREELANCER = {
    [DOCUMENT_CATEGORIES.ADMINISTRATIVE]: 'ADMINISTRATIVE',
    [DOCUMENT_CATEGORIES.COMPANY_SETUP]: 'COMPANY_SETUP',
    [DOCUMENT_CATEGORIES.MISSION]: 'MISSION',
};

export const DOCUMENT_TYPES_BY_CATEGORY = {
    [DOCUMENT_CATEGORIES.ADMINISTRATIVE]: {
        [DOCUMENT_TYPES.ACCOUNTANT_ASSIGNING_LETTER]: 'ACCOUNTANT_ASSIGNING_LETTER',
        [DOCUMENT_TYPES.BALANCE_SHEET]: 'BALANCE_SHEET',
        [DOCUMENT_TYPES.LIASSE_FISCALE]: 'LIASSE_FISCALE',
        [DOCUMENT_TYPES.OGI_FRANCE_MEMBERSHIP]: 'OGI_FRANCE_MEMBERSHIP',
        [DOCUMENT_TYPES.NON_REJECTION_ACRE_CERT]: 'NON_REJECTION_ACRE_CERT',
        [DOCUMENT_TYPES.PAYSLIP]: 'PAYSLIP',
        [DOCUMENT_TYPES.TAX_OPTION]: 'TAX_OPTION',
        [DOCUMENT_TYPES.HIWAY_ACCOUNTING_MISSION_LETTER]: 'HIWAY_ACCOUNTING_MISSION_LETTER',
        [DOCUMENT_TYPES.OTHER]: 'OTHER',
    },
    [DOCUMENT_CATEGORIES.COMPANY_SETUP]: {
        [DOCUMENT_TYPES.BANK_DELEGATED_ACCESS]: 'BANK_DELEGATED_ACCESS',
        [DOCUMENT_TYPES.CAPITAL_DEPOSIT_STATUS]: 'CAPITAL_DEPOSIT_STATUS',
        [DOCUMENT_TYPES.CAPITAL_DEPOSIT]: 'CAPITAL_DEPOSIT',
        [DOCUMENT_TYPES.DNC]: 'DNC',
        [DOCUMENT_TYPES.DOMICILIATION_CERTIFICATE]: 'DOMICILIATION_CERTIFICATE',
        [DOCUMENT_TYPES.HOME_RENTAL_CONTRACT]: 'HOME_RENTAL_CONTRACT',
        [DOCUMENT_TYPES.REGISTRATION_STATUS]: 'REGISTRATION_STATUS',
        [DOCUMENT_TYPES.POWER_OF_ATTORNEY_REGISTRATION_MANDATE]: 'POWER_OF_ATTORNEY_REGISTRATION_MANDATE',
        [DOCUMENT_TYPES.KBIS]: 'KBIS',
        [DOCUMENT_TYPES.OTHER]: 'OTHER',
    },
    [DOCUMENT_CATEGORIES.MISSION]: {
        [DOCUMENT_TYPES.ATTESTATION_TVA]: 'ATTESTATION_TVA',
        [DOCUMENT_TYPES.VIGILANCE_CERT]: 'VIGILANCE_CERT',
        [DOCUMENT_TYPES.OTHER]: 'OTHER',
    },
    [DOCUMENT_CATEGORIES.TRAINING]: {
        [DOCUMENT_TYPES.TRAINING_INVOICE]: 'TRAINING_INVOICE',
        [DOCUMENT_TYPES.CERTIFICATE_OF_ACHIEVEMENT]: 'CERTIFICATE_OF_ACHIEVEMENT',
    },
    [DOCUMENT_CATEGORIES.PAY]: {
        DSN: 'DSN',
        CADRE: 'CADRE',
        PAYSLIP: 'PAYSLIP',
    },
    [DOCUMENT_CATEGORIES.FORMALITY]: {
        PV_AGO: 'PV_AGO',
        ANNUAL_ACCOUNT_BALANCE_SHEET: 'ANNUAL_ACCOUNT_BALANCE_SHEET',
        ANNUAL_ACCOUNT_PRIVACY_STATEMENT: 'ANNUAL_ACCOUNT_PRIVACY_STATEMENT',
        POWER_OF_ATTORNEY_SUBMISSION_OF_ACCOUNTS: 'POWER_OF_ATTORNEY_SUBMISSION_OF_ACCOUNTS',
        ANNUAL_ACCOUNT_INCOME_STATEMENT: 'ANNUAL_ACCOUNT_INCOME_STATEMENT',
    },
};

export const DOCUMENT_TYPES_BY_CATEGORY_FOR_FREELANCER = {
    [DOCUMENT_CATEGORIES.ADMINISTRATIVE]: {
        [DOCUMENT_TYPES.OTHER]: 'OTHER',
    },
    [DOCUMENT_CATEGORIES.COMPANY_SETUP]: {
        [DOCUMENT_TYPES.OTHER]: 'OTHER',
        [DOCUMENT_TYPES.KBIS]: 'KBIS',
    },
    [DOCUMENT_CATEGORIES.MISSION]: {
        [DOCUMENT_TYPES.OTHER]: 'OTHER',
    },
};

export const UPLOAD_DOCUMENTS_STEPS = {
    ATTACH_DOCUMENTS: 'ATTACH_DOCUMENTS',
    MAP_CATEGORIES_AND_TYPES: 'MAP_CATEGORIES_AND_TYPES',
};

export const DOCUMENT_TYPE_UPLOAD = {
    INDENTIFICATION_DOCUMENT: 'identificationDocument',
};

export const FIRST_YEAR = 2016;

export const DELETE_FORBIDDEN_DOCUMENT_TYPES = [
    ...GLOBAL_DOCUMENT_TYPES,
    DOCUMENT_TYPES.ANNUAL_ACCOUNT_PRIVACY_STATEMENT,
    DOCUMENT_TYPES.POWER_OF_ATTORNEY_SUBMISSION_OF_ACCOUNTS,
    DOCUMENT_TYPES.PV_AGO,
    DOCUMENT_TYPES.ANNUAL_ACCOUNT_BALANCE_SHEET,
    DOCUMENT_TYPES.ANNUAL_ACCOUNT_INCOME_STATEMENT,
    DOCUMENT_TYPES.BALANCE_SHEET,
];
