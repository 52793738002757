import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CoffeeIcon from '@mui/icons-material/Coffee';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import TrainIcon from '@mui/icons-material/Train';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {EuroIcon} from '../../../../../../assets/wrapped-svg/euro';
import {PersonFrameIcon} from '../../../../../../assets/wrapped-svg/person-frame';
import {WalletThinIcon} from '../../../../../../assets/wrapped-svg/wallet-thin';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {employmentSituationOptions} from '../../utils/employmentSituationOptions';
import {EurField} from '../EurField';
import {FieldBlock} from '../FieldBlock';
import {FormSection} from '../FormSection';
import {FormSectionRow} from '../FormSectionRow';
import {SelectField} from '../SelectField';
import {ToggleField} from '../ToggleField';

export const MySituationStep2 = () => {
    const {t} = useTranslation(LocalesConstants.RevenueSimulator);
    const {watch} = useFormContext();

    const hasOtherRevenue = watch('professionalInfo.hasOtherRevenue');
    const eligibilityForSubvention = watch('professionalInfo.eligibleTravelSubvention');

    return (
        <>
            <FormSection Icon={BusinessCenterIcon} title={t('professionalInfo.professionalSituation')}>
                <FormSectionRow>
                    <FieldBlock
                        Icon={PersonFrameIcon}
                        title={t('professionalInfo.employmentSituation.title')}
                        subtitle={t('professionalInfo.employmentSituation.subtitle')}
                    >
                        <SelectField
                            required
                            name="professionalInfo.employmentSituation"
                            options={employmentSituationOptions}
                            label={t('professionalInfo.employmentSituation.title')}
                        />
                    </FieldBlock>
                    <FieldBlock
                        Icon={EuroIcon}
                        title={t('professionalInfo.monthlyNetIncome.title')}
                        subtitle={t('professionalInfo.monthlyNetIncome.subtitle')}
                    >
                        <EurField name="professionalInfo.monthlyNetIncome" defaultValue={0} decimalScale={2} />
                    </FieldBlock>
                    <FieldBlock
                        Icon={WalletThinIcon}
                        title={t('professionalInfo.eligibleTravelSubvention.title')}
                        subtitle={t('professionalInfo.eligibleTravelSubvention.subtitle')}
                    >
                        <ToggleField
                            fullWidth
                            validate={value => value !== undefined || t('requiredFieldError')}
                            name="professionalInfo.eligibleTravelSubvention"
                            options={[
                                {label: t('yes'), value: true, icon: CheckIcon},
                                {label: t('no'), value: false, icon: CloseIcon},
                            ]}
                        />
                    </FieldBlock>
                </FormSectionRow>
                <FormSectionRow isHidden={eligibilityForSubvention !== false}>
                    <FieldBlock
                        variant="horizontal"
                        Icon={WorkHistoryOutlinedIcon}
                        title={t('professionalInfo.monthsToRestoreTravelSubvention.title')}
                        subtitle={t('professionalInfo.monthsToRestoreTravelSubvention.subtitle')}
                    >
                        <ToggleField
                            name="professionalInfo.monthsToRestoreTravelSubvention"
                            fullWidth
                            required={eligibilityForSubvention === false}
                            options={[
                                {
                                    label: `1 ${t('professionalInfo.monthsToRestoreTravelSubvention.month')}`,
                                    value: 1,
                                    icon: CalendarMonthIcon,
                                },
                                {
                                    label: `4 ${t('professionalInfo.monthsToRestoreTravelSubvention.months')}`,
                                    value: 4,
                                    icon: CalendarMonthIcon,
                                },
                            ]}
                        />
                    </FieldBlock>
                </FormSectionRow>
            </FormSection>

            <FormSection Icon={BusinessCenterIcon} title={t('professionalInfo.otherRevenue.title')}>
                <FormSectionRow>
                    <FieldBlock
                        Icon={EuroIcon}
                        variant="horizontal"
                        title={t('professionalInfo.otherRevenue.title')}
                        subtitle={t('professionalInfo.otherRevenue.subtitle')}
                    >
                        <ToggleField
                            name="professionalInfo.hasOtherRevenue"
                            fullWidth
                            validate={value => value !== undefined || t('requiredFieldError')}
                            options={[
                                {label: t('yes'), value: true, icon: CheckIcon},
                                {label: t('no'), value: false, icon: CloseIcon},
                            ]}
                        />
                    </FieldBlock>
                </FormSectionRow>
                <FormSectionRow isHidden={!hasOtherRevenue}>
                    <FieldBlock
                        Icon={MedicalServicesIcon}
                        variant="compact"
                        title={t('professionalInfo.otherRevenue.managerIncome')}
                    >
                        <EurField name="professionalInfo.otherRevenue.managerIncome" defaultValue={0} decimalScale={2} />
                    </FieldBlock>
                    <FieldBlock
                        Icon={HealthAndSafetyIcon}
                        variant="compact"
                        title={t('professionalInfo.otherRevenue.meBIC')}
                    >
                        <EurField name="professionalInfo.otherRevenue.meBIC" defaultValue={0} decimalScale={2} />
                    </FieldBlock>
                    <FieldBlock Icon={CoffeeIcon} variant="compact" title={t('professionalInfo.otherRevenue.meBNC')}>
                        <EurField name="professionalInfo.otherRevenue.meBNC" defaultValue={0} decimalScale={2} />
                    </FieldBlock>
                    <FieldBlock
                        Icon={TrainIcon}
                        variant="compact"
                        title={t('professionalInfo.otherRevenue.MEPurchaseOrResale')}
                    >
                        <EurField name="professionalInfo.otherRevenue.MEPurchaseOrResale" defaultValue={0} decimalScale={2} />
                    </FieldBlock>
                </FormSectionRow>
            </FormSection>
        </>
    );
};
