import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {UmbrellaUsersListActionTypes, UmbrellaUsersListActions} from './umbrella-users-list.actions';
import {selectRouterLocation} from '../../../lib/router/connected-router-saga';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import {getPaginationAndSortParams} from '../../../utils/pagination';
import {UserRoles} from '../../../utils/user-roles';
import {LoadingActions, LoadingTypes} from '../../loading';
import {getUsersRequest} from '../../user-list/api/user.api';

const fetchUsersListSaga = function* () {
    try {
        const location = yield select(selectRouterLocation);
        const search = location?.query;
        const queryPrefix = 'umbrellaUsers';

        const paginationAndSortParams = getPaginationAndSortParams({search, queryPrefix});

        const filterQuery = {role: UserRoles.UMBRELLA, status: 'ACTIVE'};

        const activeFilter = search?.[`${queryPrefix}-active`];
        if (activeFilter) {
            filterQuery.active = JSON.parse(activeFilter);
        }

        const params = {
            ...paginationAndSortParams,
            searchQuery: search?.[`${queryPrefix}-searchTerm`],
            filterQuery,
            include: ['missions', 'accountManager'],
        };

        yield put(LoadingActions.setLoading(LoadingTypes.UMBRELLA_USERS_LIST, true));

        const response = yield call(getUsersRequest, params);
        yield put(UmbrellaUsersListActions.storeUsersList(response));
    } catch (e) {
        Toast.error('genericError');
        Debug.error('fetchUsersListSaga', 'Error:', e);
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.UMBRELLA_USERS_LIST, false));
    }
};

export const umbrellaUsersListLoaderSaga = function* () {
    yield call(fetchUsersListSaga);
};

export const watchUmbrellaUsersListSaga = function* watchUmbrellaUsersListSaga() {
    yield all([takeLatest(UmbrellaUsersListActionTypes.FETCH_USERS_LIST, fetchUsersListSaga)]);
};
