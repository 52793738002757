import EventBusyIcon from '@mui/icons-material/EventBusy';
import {Box, Divider, Paper, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import CancelTransferView from './CancelTransferView';
import {recurringTransferValues} from './CreateRecurringTransfer';
import RecurringTransferScrollHeader from './RecurringTransferSidebarScrollHeader';
import {debounce} from '../../../../../../utils/debounce';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import BankQr from '../../../../components/BankQR';
import {BankSelector} from '../../../../store/bank.selector';
import TransactionCategoryList from '../../../account-balance/components/transaction-details/TransactionCategoryList';
import TransactionDetailsCloseSection
    from '../../../account-balance/components/transaction-details/TransactionDetailsCloseSection';
import TransactionDetailsFilesSection
    from '../../../account-balance/components/transaction-details/TransactionDetailsFilesSection';
import TransactionDetailsHeader from '../../../account-balance/components/transaction-details/TransactionDetailsHeader';
import {
    TVA,
    TransactionDetailsNonSelectedCategory,
    TransactionDetailsSelectedCategory,
} from '../../../account-balance/components/transaction-details/TransactionDetailsHelpers';
import {TransactionsActions} from '../../../account-balance/store/transactions.action';
import {TransactionsSelector} from '../../../account-balance/store/transactions.selector';
import {getIntegrationInfo} from '../../../bridge-api/utils/bridge-util';
import {getActiveHiwayIntegration} from '../../../rib-and-documents/utils/ribAndDocuments.util';
import {TransfersActions} from '../../store/transfers.actions';
import {TransfersSelector} from '../../store/transfers.selector';

const RecurringTransferSidebar = ({information, onCloseSidebar}) => {
    const {t} = useTranslation(LocalesConstants.Bank);
    const {t: tBankTransfer} = useTranslation(LocalesConstants.BankTransfer);
    const dispatch = useDispatch();
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const details = useSelector(TransfersSelector.selectTransfer);
    const integration = useSelector(BankSelector.selectIntegrations);
    const categoryList = useSelector(TransactionsSelector.selectCategories);

    const hiwayIntegration = getActiveHiwayIntegration(integration);
    const {account: hiwayBankAccount} = getIntegrationInfo(hiwayIntegration);

    const [data, setData] = useState(information);
    const [fileList, setFileList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [isCancelOpen, setIsCancelOpen] = useState(false);
    const [isCateogrySelectorOpen, setIsCategorySelectorOpen] = useState(false);
    const [isQROpen, setIsQROpen] = useState(false);

    const onClose = () => {
        setSelectedCategory(null);
        setIsCategorySelectorOpen(false);

        onCloseSidebar();
    };

    const onOpenCategory = useCallback(() => {
        setIsCategorySelectorOpen(true);
    }, []);

    const onCategoryClose = useCallback(() => {
        setIsCategorySelectorOpen(false);
    }, []);

    const onSelectCategory = category => {
        setSelectedCategory(category.mccKey);
        dispatch(TransfersActions.update({id: data.id, mccKey: category.mccKey}));
        setIsCategorySelectorOpen(false);
    };

    const onTvaChange = tva => {
        dispatch(TransfersActions.update({id: data.id, tvaType: tva}));
    };

    const onTvaValueChange = debounce(value => {
        if (value !== '') {
            dispatch(TransfersActions.update({
                id: data.id,
                value: data.value,
                tvaAmount: value,
                tvaType: 'TVA_CUSTOM',
            }));
        }
    }, 800);

    const onCancelClick = () => {
        setIsCancelOpen(true);
    };

    useEffect(() => {
        setData(information.order);
        setSelectedCategory(information?.order?.mccKey);
        setFileList([]);

        if (information?.order?.id) {
            dispatch(TransfersActions.getTransfer({id: information.order.id}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [information]);

    useEffect(() => {
        if (categoryList.length === 0 && integration?.length > 0) {
            dispatch(TransactionsActions.getCategoryList());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integration, dispatch]);

    useEffect(() => {
        if (details?.documents) {
            setFileList(details.documents);
        } else {
            setFileList([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details]);

    if (isQROpen) {
        return (
            <BankQr
                id={data.id}
                bankAccountId={hiwayBankAccount?.id}
                onClose={() => setIsQROpen(false)}
                type="transfer"
            />
        );
    }

    if (isCateogrySelectorOpen) {
        return (
            <TransactionCategoryList
                isMobileSize={isMobileSize}
                onClose={onCategoryClose}
                selectedCategory={selectedCategory}
                setSelectedCategory={onSelectCategory}
            />
        );
    }

    if (isCancelOpen) {
        return (
            <CancelTransferView
                data={data}
                onClose={() => setIsCancelOpen(false)}
                onCloseSidebar={onCloseSidebar}
            />
        );
    }

    return (
        <Box
            sx={isMobileSize ? {display: 'flex', flexDirection: 'column', px: 2}
                : {
                    display: data ? 'flex' : 'none',
                    flexDirection: 'column',
                    boxShadow: information
                        ? '0px 11px 15px -7px rgba(11, 35, 51, 0.08), 0px 24px 38px 3px rgba(11, 35, 51, 0.08), 0px 9px 46px 8px rgba(11, 35, 51, 0.1);'
                        : 'none',
                }}
        >
            <TransactionDetailsCloseSection
                isMobileSize={isMobileSize}
                onClose={onClose}
                isOpen
            />

            <Paper
                elevation={1}
                sx={{
                    height: !isMobileSize ? '100vh' : '100%',
                    width: isMobileSize ? '100%' : '400px',
                }}
            >
                <Box
                    id="navbar-transfer-details-main"
                    sx={{
                        overflowY: 'auto',
                        height: '100vh',
                    }}
                >
                    {data && (
                        <>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                            >
                                <RecurringTransferScrollHeader data={data} onClose={onClose} />
                                <TransactionDetailsHeader data={data} />

                                <Box sx={{
                                    textAlign: 'center',
                                    fontWeight: 500,
                                    mb: 2,
                                    mt: -2,
                                }}
                                >
                                    {data.period === recurringTransferValues.daily && tBankTransfer('transfer.daily')}
                                    {data.period === recurringTransferValues.weekly && tBankTransfer('transfer.weekly')}
                                    {data.period === recurringTransferValues.monthly && tBankTransfer('transfer.monthly')}
                                    : {moment(data.additionalData?.firstExecutionDate).format('DD MMM YYYY')} -
                                    {data.additionalData?.lastExecutionDate
                                        ? ' '
                                        + moment(data.additionalData?.lastExecutionDate).format('DD MMM YYYY')
                                        : ''}
                                </Box>

                                <Box sx={{
                                    backgroundColor: 'gray_v2.50',
                                    borderRadius: '16px',
                                    padding: '16px 24px 24px 24px',
                                    mx: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                                >
                                    <Typography sx={{fontWeight: 500}}>
                                        {t('translationDetails.category')}
                                    </Typography>

                                    {!selectedCategory
                                        && (
                                            <TransactionDetailsNonSelectedCategory
                                                onOpenCategory={onOpenCategory}
                                                suggestedCategory={data.suggestedMccKey}
                                                onSelectCategory={onSelectCategory}
                                                isEditable={true}
                                            />
                                        )}

                                    {!!selectedCategory
                                        && (
                                            <TransactionDetailsSelectedCategory
                                                onOpenCategory={onOpenCategory}
                                                selectedCategory={selectedCategory}
                                                isEditable={true}
                                            />
                                        )
                                    }

                                </Box>

                                <TransactionDetailsFilesSection
                                    fileList={fileList}
                                    details={{...information.order, type: information.type}}
                                    isEditable={true}
                                    setIsQROpen={setIsQROpen}
                                    hasNoFileOption={false}
                                />

                                <Box sx={{
                                    backgroundColor: 'grey.50',
                                    borderRadius: '16px',
                                    padding: '16px 24px 24px 24px',
                                    mx: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    mb: 2,
                                }}
                                >
                                    <Typography sx={{fontWeight: 500}}>
                                        {t('translationDetails.vat')}
                                    </Typography>
                                    <TVA
                                        key={details?.tvaType ?? data.tvaType}
                                        tva={details?.tvaType ?? data.tvaType}
                                        changeTva={onTvaChange}
                                        changeTvaValue={onTvaValueChange}
                                        value={details?.tvaAmount ?? data.tvaAmount}
                                        isEditable={selectedCategory}
                                        articleAmount={data?.amount}
                                    />
                                </Box>

                                <Box sx={{
                                    backgroundColor: 'grey.50',
                                    borderRadius: '16px',
                                    padding: '16px 24px 24px 24px',
                                    mx: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    mb: 2,
                                }}
                                >
                                    <Typography sx={{fontWeight: 500}}>
                                        {tBankTransfer('transfer.update.transferInfromation')}
                                    </Typography>

                                    <Typography sx={{
                                        mt: 1,
                                        color: 'v2.light.text.disabled',
                                    }}
                                    >
                                        IBAN
                                    </Typography>
                                    <Typography>
                                        {data.iban}
                                    </Typography>

                                    {data.description && (
                                        <>
                                            <Divider sx={{mt: 1}} />

                                            <Typography sx={{
                                                mt: 1,
                                                color: 'v2.light.text.disabled',
                                            }}
                                            >
                                                {t('translationDetails.reference')}
                                            </Typography>
                                            <Typography>
                                                {data.description}
                                            </Typography>
                                        </>
                                    )}
                                </Box>

                                <Box sx={{
                                    mb: 2,
                                    px: 2,
                                }}
                                >
                                    <Button
                                        color="secondary"
                                        variant="outlined"
                                        onClick={onCancelClick}
                                        fullWidth
                                        startIcon={<EventBusyIcon />}
                                    >
                                        {tBankTransfer('transfer.update.cancel')}
                                    </Button>
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
            </Paper>


        </Box>
    );
};

RecurringTransferSidebar.propTypes = {
    information: PropTypes.object.isRequired,
    onCloseSidebar: PropTypes.func.isRequired,
};

export default RecurringTransferSidebar;
