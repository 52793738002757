import {saveAs} from 'file-saver';
import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import * as actions from './personal-expenses.actions';
import {
    setIsLoadingPersonalExpenses,
    storePersonalExpenses,
} from './personal-expenses.actions';
import {selectPersonalExpenses} from './personal-expenses.selectors';
import {
    deletePersonalExpenseRequest,
    getPersonalExpensesRequest,
    updatePersonalExpenseRequest,
} from '../../../../../app/api/providers/expenses/expenses.provider';
import {isMobileSafari, isSafari} from '../../../../../app/utils/app.helpers';
import {Toast} from '../../../../../lib/toast';
import {selectCurrentCognitoUser} from '../../../../auth/store/auth.selectors';
import {DocumentApi} from '../../../../document/api/document.api';
import {FreelancerSelectors} from '../../../../freelancer';
// eslint-disable-next-line import/no-cycle
import {loadFreelancerAccountSaga} from '../../../../freelancer/store/freelancer.sagas';

const getPersonalExpensesSaga = function* ({payload}) {
    try {
        yield put(actions.setIsLoadingPersonalExpenses(true));

        const expenses = yield call(
            getPersonalExpensesRequest,
            payload.freelancerId,
        );

        yield put(actions.storePersonalExpenses(expenses));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingPersonalExpenses(false));
    }
};

export const personalExpensesLoaderSaga = function* ({payload}) {
    const {params = {}} = payload || {};
    let {companyId, freelancerId, userId} = params;

    // Umbrella user
    if (userId) {
        yield put(actions.getPersonalExpenses(freelancerId ?? userId));

        return;
    }

    if (!companyId || !freelancerId) {
        const freelancer = yield select(FreelancerSelectors.selectAccount);
        const {id} = yield select(selectCurrentCognitoUser);

        freelancerId = id;

        // In case of other users than a freelancer we need nullcheck
        companyId = freelancer?.defaultCompanyId;
    }

    if (freelancerId) {
        yield call(loadFreelancerAccountSaga, freelancerId);
    }

    yield put(actions.getPersonalExpenses(freelancerId));
};

export const deletePersonalExpenseSaga = function* ({payload}) {
    try {
        yield put(setIsLoadingPersonalExpenses(true));

        yield call(
            deletePersonalExpenseRequest,
            payload.freelancerId,
            payload.expenseId,
        );

        const expenses = yield select(selectPersonalExpenses);

        const newExpenses = Object.keys(expenses)
            .filter(expenseId => expenseId !== payload.expenseId)
            .reduce((result, current) => {
                result[current] = expenses[current];

                return result;
            }, {});

        yield put(storePersonalExpenses(newExpenses));

        Toast.success('expenseDeleted');
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(setIsLoadingPersonalExpenses(false));
    }
};

export const updatePersonalExpenseSaga = function* ({payload}) {
    try {
        yield put(setIsLoadingPersonalExpenses(true));

        const expenses = yield select(selectPersonalExpenses);

        const expense = expenses[payload.expenseId];

        const updatedExpense = yield call(
            updatePersonalExpenseRequest,
            payload.freelancerId,
            payload.expenseId,
            {
                category: expense.category,
                date: expense.date,
                totalAmount: expense.amount,
                reimbursed: payload.isReimbursed,
            },
        );


        expenses[payload.expenseId] = updatedExpense;

        yield put(storePersonalExpenses(expenses));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(setIsLoadingPersonalExpenses(false));
    }
};

export const downloadPersonalExpenseSaga = function* ({payload}) {
    try {
        let childWindow;

        if ((isSafari || isMobileSafari) && !payload.isDownload) {
            childWindow = window.open('', '_blank');
        }

        yield put(actions.setIsLoadingPersonalExpenses(true));

        const {signedUrl} = yield call(
            DocumentApi.getSignedUrlForDocumentRequestV2,
            {
                documentId: payload.receiptId,
                isDownload: payload.isDownload,

            },
        );


        if (!signedUrl) {
            throw new Error('The document URL is missing.');
        }

        if ((isSafari || isMobileSafari) && !payload.isDownload) {
            childWindow.location = signedUrl;

            return;
        }

        if ((isSafari || isMobileSafari) && payload.isDownload) {
            // TODO:HIGH: It's ugly but it works.
            fetch(signedUrl).then(response => {
                return response.blob();
            }).then(blob => {
                const matchedGroups = signedUrl.match(/filename[^;=\n]*%3D(%22(.*)%22[^;\n]*)/);
                const filename = matchedGroups[2];

                saveAs(blob, decodeURI(filename));
            });

            return;
        }

        window.open(signedUrl, '_blank');
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingPersonalExpenses(false));
    }
};

export const watchPersonalExpensesSagas = function* () {
    yield all([
        takeLatest(actions.GET_PERSONAL_EXPENSES, getPersonalExpensesSaga),
        takeLatest(actions.DELETE_PERSONAL_EXPENSE, deletePersonalExpenseSaga),
        takeLatest(actions.DOWNLOAD_PERSONAL_EXPENSE, downloadPersonalExpenseSaga),
        takeLatest(actions.UPDATE_PERSONAL_EXPENSE, updatePersonalExpenseSaga),
    ]);
};
