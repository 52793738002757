import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import SearchIcon from '@mui/icons-material/Search';
import {Box, CircularProgress, Divider, IconButton, Paper, Slide, Typography} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useDynamicIcon} from '../../../../../../hooks/useDynamicIcon';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {createUnaccentedString} from '../../../../../../utils/string';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {SettingsSelectors} from '../../../../../settings/store/settings.selectors';
import {TransactionsSelector} from '../../store/transactions.selector';
import {COLORS_PALETTE} from '../../util/constants';

// NOTE: new component - CategoriesList
// src/features/bank/modules/account-balance/components/categorisation/components/categories/CategoriesList.js

const TransactionCategoryList = ({isMobileSize, onClose, selectedCategory, setSelectedCategory}) => {
    const {t} = useTranslation(LocalesConstants.Bank);

    const [filteredCategories, setFilteredCategories] = useState([]);

    const categoryList = useSelector(TransactionsSelector.selectCategories);
    const isLoadingCategories = useSelector(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.BANK_CATEGORIES,
    ));

    const userSettings = useSelector(SettingsSelectors.selectSettings);
    const language = userSettings?.language ?? 'fr';

    useEffect(() => {
        if (categoryList && categoryList.length > 0) {
            setFilteredCategories(categoryList);
        }
    }, [categoryList]);

    const filterCategories = search => {
        const upperCaseSearch = createUnaccentedString(search.trim().toUpperCase());

        const filteredList = categoryList.map(categoryParent => {
            let hasCategory = false;
            const mccGroupItems = categoryParent.mccGroupItems.map(category => {
                const hasFoundKeyword = category.uppercase[language]
                    .keywords.find(keyword => keyword.includes(upperCaseSearch))
                    || category.uppercase[language].categoryName.includes(upperCaseSearch);

                if (hasFoundKeyword) {
                    hasCategory = true;
                    return category;
                }

                return null;
            }).filter(Boolean);

            return hasCategory ? {...categoryParent, mccGroupItems} : null;
        }).filter(Boolean);

        setFilteredCategories(filteredList);
    };

    const onCategorySelect = category => {
        setSelectedCategory(category);
    };

    if (isMobileSize) {
        return (
            <Drawer
                anchor="bottom"
                open={true}
                onClose={onClose}
                sx={{
                    '.MuiDrawer-paperAnchorBottom': {
                        borderTopRightRadius: '32px',
                        borderTopLeftRadius: '32px',
                    },
                }}
            >
                <Box
                    sx={{
                        minHeight: '300px',
                        borderTopRightRadius: '32px',
                        borderTopLeftRadius: '32px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Paper
                        elevation={1}
                        sx={{
                            height: !isMobileSize ? '100vh' : '100%',
                            width: isMobileSize ? '100%' : '400px',
                        }}
                    >
                        <Box sx={{display: 'flex', flexDirection: 'column', px: 3}}>
                            <Box sx={{
                                position: 'absolute',
                                right: '20px',
                                top: '20px',
                            }}
                            >
                                <IconButton aria-label="close" onClick={onClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>

                            <Typography
                                sx={{
                                    mt: isMobileSize ? 2 : 7,
                                    textAlign: 'center',
                                    fontSize: theme => theme.typography.pxToRem(21),
                                    fontWeight: 700,
                                }}
                            >
                                {t('translationDetails.validateCategory')}
                            </Typography>
                            <Box sx={{mt: 2}}>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    label={t('translationDetails.searchCategory')}
                                    InputProps={{
                                        startAdornment: <SearchIcon />,
                                    }}
                                    onKeyUp={event => {
                                        filterCategories(event.target.value);
                                    }}
                                />
                            </Box>

                            <CategoryList
                                isLoadingCategories={isLoadingCategories}
                                filteredCategories={filteredCategories}
                                selectedCategory={selectedCategory}
                                onCategorySelect={onCategorySelect}
                                language={language}
                                categoryList={categoryList}
                            />
                        </Box>
                    </Paper>
                </Box>
            </Drawer>
        );
    }

    return (
        <Box
            sx={isMobileSize ? {display: 'flex', flexDirection: 'column'}
                : {
                    display: 'flex',
                    flexDirection: 'column',
                    transition: 'display 0.8s 0.4s ease-in-out',
                    boxShadow: '0px 11px 15px -7px rgba(11, 35, 51, 0.08), 0px 24px 38px 3px rgba(11, 35, 51, 0.08), 0px 9px 46px 8px rgba(11, 35, 51, 0.1);',
                    zIndex: 222,
                }}
        >
            <Paper
                elevation={1}
                sx={{
                    height: !isMobileSize ? '100vh' : '100%',
                    width: isMobileSize ? '100%' : '400px',
                }}
            >
                <Slide direction="left" in={true}>
                    <Box sx={{display: 'flex', flexDirection: 'column', px: 2}}>
                        <Box sx={{
                            position: 'absolute',
                            right: '20px',
                            top: '20px',
                            background: 'white',
                        }}
                        >
                            <IconButton aria-label="close" onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>

                        <Typography
                            sx={{
                                mt: isMobileSize ? 2 : 7,
                                textAlign: 'center',
                                fontSize: theme => theme.typography.pxToRem(21),
                                fontWeight: 700,
                            }}
                        >
                            {t('translationDetails.validateCategory')}
                        </Typography>
                        <Box sx={{mt: 2}}>
                            <TextField
                                fullWidth
                                autoFocus
                                label={t('translationDetails.searchCategory')}
                                InputProps={{
                                    startAdornment: <SearchIcon />,
                                }}
                                onKeyUp={event => {
                                    filterCategories(event.target.value);
                                }}
                            />
                        </Box>

                        <CategoryList
                            isLoadingCategories={isLoadingCategories}
                            filteredCategories={filteredCategories}
                            selectedCategory={selectedCategory}
                            onCategorySelect={onCategorySelect}
                            language={language}
                            categoryList={categoryList}
                        />
                    </Box>
                </Slide>
            </Paper>
        </Box>
    );
};

TransactionCategoryList.propTypes = {
    isMobileSize: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedCategory: PropTypes.string,
    setSelectedCategory: PropTypes.func.isRequired,
};

TransactionCategoryList.defaultProps = {
    selectedCategory: '',
};

let categoryListEmpty = {};
const CategoryList = ({
    isLoadingCategories,
    filteredCategories,
    selectedCategory,
    onCategorySelect,
    language,
    categoryList,
}) => {
    const [expanded, setExpanded] = React.useState({});

    const hasFilter = categoryList?.length !== filteredCategories?.length;

    const handleChange = key => {
        if (hasFilter) {
            setExpanded({...expanded, [key]: !expanded[key]});
        } else {
            // eslint-disable-next-line
            if (expanded[key]) {
                setExpanded({...categoryListEmpty, [key]: false});
            } else {
                setExpanded({...categoryListEmpty, [key]: true});
            }
        }
    };

    useEffect(() => {
        if (categoryList.length > 0) {
            const expandedList = {};
            categoryList.forEach(categoryParent => {
                expandedList[categoryParent.mccGroupKey] = false;
            });
            categoryListEmpty = {...expandedList};
            setExpanded(expandedList);
        }
    }, [categoryList]);

    useEffect(() => {
        if (categoryList?.length > 0 && filteredCategories?.length > 0) {
            if (hasFilter) {
                const newExpanded = {};
                Object.keys(categoryListEmpty).forEach(key => { newExpanded[key] = true; });
                setExpanded(newExpanded);
            } else {
                setExpanded({...categoryListEmpty});
            }
        }
        // eslint-disable-next-line
    }, [hasFilter]);


    return (
        <>
            {isLoadingCategories && <CircularProgress />}
            {!isLoadingCategories && (
                <Box sx={{mt: 2, overflowY: 'auto', height: 'calc(100vh - 200px)'}}>
                    {filteredCategories && filteredCategories.map((categoryParent, index) => {
                        return (
                            <Accordion
                                expanded={expanded[categoryParent.mccGroupKey]}
                                onChange={() => {
                                    handleChange(categoryParent.mccGroupKey);
                                }}
                                key={categoryParent.mccGroupKey}
                                sx={{
                                    'mb': 2,
                                    'borderRadius': '32px !important',
                                    'background': 'white',
                                    'boxShadow': 'none',
                                    '&:before': {
                                        display: 'none',
                                    },
                                }}
                                disableGutters={true}
                            >
                                <Box sx={{
                                    'background': 'white',
                                    'position': 'sticky',
                                    'top': 0,
                                }}
                                >
                                    <AccordionSummary
                                        sx={{
                                            'background': COLORS_PALETTE[categoryParent.mccGroupKey],
                                            'borderRadius': '32px',
                                            'zIndex': `${index + 2}`,
                                        }}
                                        expandIcon={<KeyboardArrowDownTwoToneIcon />}
                                        aria-controls="panel1a-content"
                                    >
                                        <Typography sx={{
                                            fontWeight: 500,
                                            color: 'text_v2.secondary',
                                            top: '20px',
                                        }}
                                        >
                                            {categoryParent?.mccGroupName?.[language]}
                                        </Typography>
                                    </AccordionSummary>
                                </Box>
                                <AccordionDetails sx={{
                                    background: 'white',
                                    p: 0,
                                    mt: 1,
                                }}
                                >
                                    {categoryParent.mccGroupItems.map(category => {
                                        return (
                                            <SingleCategory
                                                key={category.mccKey}
                                                category={category}
                                                selectedCategory={selectedCategory}
                                                onCategorySelect={onCategorySelect}
                                                language={language}
                                            />
                                        );
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </Box>
            )}
        </>
    );
};

CategoryList.propTypes = {
    isLoadingCategories: PropTypes.bool,
    filteredCategories: PropTypes.array,
    selectedCategory: PropTypes.string,
    onCategorySelect: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    categoryList: PropTypes.array,
};

CategoryList.defaultProps = {
    filteredCategories: [],
    categoryList: [],
    selectedCategory: '',
    isLoadingCategories: false,
};

const SingleCategory = ({category, selectedCategory, onCategorySelect, language}) => {
    const isSelected = category.mccKey === selectedCategory;
    const dynamicIcon = useDynamicIcon(category?.faIcon);

    return (
        <React.Fragment key={category.mccKey}>
            <Box
                sx={{
                    py: 2,
                    color: isSelected ? '#fff' : 'text_v2.secondary',
                    backgroundColor: isSelected ? 'primary_v2.main' : 'transparent',
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    px: 1,
                    [`&:hover`]: {
                        backgroundColor: isSelected ? 'primary_v2.main' : 'rgba(25, 118, 210, 0.04)',
                    },
                }}
                onClick={() => {
                    onCategorySelect(category);
                }}
            >
                <FontAwesomeIcon icon={dynamicIcon} />
                <Typography
                    sx={{
                        ml: 2,
                        fontWeight: 500,
                        color: isSelected ? '#fff' : 'text_v2.secondary',
                    }}
                >
                    {category[language].categoryName}
                </Typography>
            </Box>
            <Divider />
        </React.Fragment>
    );
};

SingleCategory.propTypes = {
    category: PropTypes.object.isRequired,
    selectedCategory: PropTypes.string,
    onCategorySelect: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
};

SingleCategory.defaultProps = {
    selectedCategory: '',
};

export default TransactionCategoryList;
