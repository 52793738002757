import EventBusyIcon from '@mui/icons-material/EventBusy';
import InfoIcon from '@mui/icons-material/Info';
import {Box, Divider, Grow, Paper, Slide, Zoom} from '@mui/material';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {DossierDate} from './DossierDate';
import {DossierDocumentViewButtons} from './DossierDocumentViewButtons';
import {DossierResetDateModal} from './DossierResetDateModal';
import {DossierTrainingModal} from './DossierTrainingModal';
import DossierViewPdf from './DossierViewPdf';
import SwipeableEdgeDrawer from '../../../../components/swipable-edge/SwipableEdge';
import {ConditionalTooltip} from '../../../../components/tooltip/ConditionalTooltip';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {AnimationActions} from '../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../animations/utils/constants';
import {UiActions} from '../../../ui/store/ui.action';
import {ModalsKeys} from '../../../ui/utils/constants';
import {TrainingSelector} from '../../store/training.selector';
import {OpcoButtonSection} from '../opco-registration/OpcoButtonSection';

export const DossierDocumentView = () => {
    const {t} = useTranslation(LocalesConstants.Training);

    const dispatch = useDispatch();

    const [isChecked, setIsChecked] = useState(false);

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const trainingInfo = useSelector(TrainingSelector.getTrainingInfo);

    const startDate = trainingInfo?.startDate ?? '-';
    const endDate = trainingInfo?.endDate ?? '-';

    const tooltip = `${t('dossier.dateAlert.startDateFormation')} ${startDate}
    ${t('dossier.dateAlert.endDateFormation')} ${endDate}`;

    const onButtonClick = () => {
        dispatch(UiActions.setActiveModal(ModalsKeys.TRAINING_DOSSIER_MODAL, true));
    };

    const onCheckboxClick = e => {
        const checked = e.target.checked;
        setIsChecked(checked);
    };

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    const onDateResetClick = () => {
        dispatch(UiActions.setActiveModal(ModalsKeys.TRAINING_DOSSIER_RESET_DATE, true));
    };

    return (
        <Box>
            <DossierTrainingModal />
            {isMobileSize && (
                <>
                    <Paper elevation={1} sx={{borderRadius: 2}}>
                        <Box sx={{height: 'calc(100vh - 290px)', overflowY: 'auto'}}>
                            <DossierViewPdf />
                        </Box>
                    </Paper>

                    <SwipeableEdgeDrawer
                        height="78px"
                        bleedingHeight={140}
                        drawerContent={(
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexDirection: 'column',
                                px: 3,
                                pt: 1.5,
                                pb: 3,
                            }}
                            >
                                <OpcoButtonSection
                                    onCheckboxClick={onCheckboxClick}
                                    onButtonClick={onButtonClick}
                                    isChecked={isChecked}
                                />
                                <Divider sx={{mt: 2}} />
                                <Box sx={{
                                    mt: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                                >
                                    <Button
                                        sx={{
                                            height: '36px',
                                            mr: 2,
                                        }}
                                        startIcon={<EventBusyIcon />}
                                        onClick={onDateResetClick}
                                        color="primary"
                                        variant="outlined"
                                        disableElevation
                                        fullWidth
                                    >
                                        {t('dossier.dateAlert.buttonText')}
                                    </Button>

                                    <ConditionalTooltip tooltip={tooltip}>
                                        <InfoIcon color="primary" />
                                    </ConditionalTooltip>
                                </Box>

                                <DossierResetDateModal />
                            </Box>
                        )}
                    />
                </>
            )}

            {!isMobileSize && (
                <>
                    <Zoom in={isAnimationActive} timeout={350}>
                        <Box sx={{mb: 2}}>
                            <DossierDate
                                hasResetOption={true}
                                hasSingleLineDates={true}
                            />
                        </Box>
                    </Zoom>

                    <Grow in={isAnimationActive} onExited={onEndedAnimation}>
                        <Paper elevation={0}>
                            <Box sx={{height: 'calc(100vh - 390px)', overflowY: 'auto'}}>
                                <DossierViewPdf />
                            </Box>
                        </Paper>
                    </Grow>

                    <Slide in={isAnimationActive} direction="left" timeout={200}>
                        <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
                            <DossierDocumentViewButtons
                                isChecked={isChecked}
                                onCheckboxClick={onCheckboxClick}
                                onButtonClick={onButtonClick}
                            />
                        </Box>
                    </Slide>
                </>
            )}
        </Box>
    );
};
