import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Tab} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import PcTab from './pc-tab/PcTab';
import RcTab from './rc-tab/RcTab';
import {LocalesConstants} from '../../../utils/locales-constants';
import {INSURANCE_TABS} from '../utils/constants';

const InsuranceTabs = ({handleOpenSubscribeModal, tab, handleTabChange}) => {
    const {t} = useTranslation(LocalesConstants.Insurance);

    return (
        <Box sx={{width: '100%'}}>
            <TabContext value={tab}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleTabChange} variant="fullWidth">
                        <Tab label={t('tabs.rc.title')} value={INSURANCE_TABS.RC_TAB} sx={{color: 'text.primary'}} />
                        <Tab label={t('tabs.pc.title')} value={INSURANCE_TABS.PC_TAB} sx={{color: 'text.primary'}} />
                    </TabList>
                </Box>
                <TabPanel value={INSURANCE_TABS.RC_TAB} sx={{padding: '24px 0 0 0'}}>
                    <RcTab handleOpenSubscribeModal={handleOpenSubscribeModal} />
                </TabPanel>
                <TabPanel value={INSURANCE_TABS.PC_TAB} sx={{padding: '24px 0 0 0'}}>
                    <PcTab handleOpenSubscribeModal={handleOpenSubscribeModal} />
                </TabPanel>
            </TabContext>
        </Box>
    );
};

InsuranceTabs.propTypes = {
    handleOpenSubscribeModal: PropTypes.func.isRequired,
    tab: PropTypes.string.isRequired,
    handleTabChange: PropTypes.func.isRequired,
};

export default InsuranceTabs;
