import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Chip, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {LocalesConstants} from '../../utils/locales-constants';
import {
    UserRoleColors,
    UserRoleContrastColors,
    UserRoleIcons,
} from '../../utils/user-roles';
import JoyUIThemeProvider from '../joy-ui/joyui-theme-provider/JoyUiThemeProvider';

const DtCellRole = ({rowData}) => {
    const {t} = useTranslation(LocalesConstants.CreateUser);

    const {role} = rowData;
    const hasIcon = !!UserRoleIcons[role];

    return (
        <JoyUIThemeProvider>
            <Chip
                color="neutral"
                size="lg"
                variant="soft"
                startDecorator={hasIcon ? (
                    <Box
                        color={UserRoleContrastColors[role]}
                        fontSize="sm"
                    >
                        <FontAwesomeIcon
                            icon={UserRoleIcons[role]}
                            fixedWidth
                        />
                    </Box>
                ) : null}
                sx={{
                    'backgroundColor': UserRoleColors[role],
                    'color': UserRoleContrastColors[role],
                }}
            >
                <Typography level="title-sm" sx={{color: UserRoleContrastColors[role]}}>
                    {t(`roles.${role}`)}
                </Typography>
            </Chip>
        </JoyUIThemeProvider>
    );
};

DtCellRole.propTypes = {
    rowData: PropTypes.object.isRequired,
};

export default DtCellRole;
