import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../../components/ui-kit/DataTable/DataTable';
import {LocalesConstants} from '../../../utils/locales-constants';
import {DocumentGenerationMobile} from '../../document-generation/components/DocumentGenerationMobile';
import {DatabaseSelectors} from '../../document/modules/database/store/database.selector';
import {getDocumentGenerationTableConfig} from '../utils/document-generation-table-config';

const DocumentGenerationTable = ({hasSearch, hasPagination}) => {
    const {t} = useTranslation(LocalesConstants.Documents);

    const columnConfig = useMemo(() => {
        return getDocumentGenerationTableConfig(t);
    }, [t]);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    if (isMobileSize) {
        return (
            <DocumentGenerationMobile />
        );
    }

    return (
        <DataTable
            tableId="documentGeneration"
            columnConfig={columnConfig}
            missingDataMessage={t('missingDocuments')}
            dataSelector={DatabaseSelectors.createDocumentsDataTableSelector}
            isLoading={false}
            defaultRowsPerPage={25}
            searchBy={['docName', 'fileName']}
            searchPlaceholder={t('documentsDatabaseTable.searchDocuments')}
            hasSearch={hasSearch}
            hasPagination={hasPagination}
        />
    );
};

DocumentGenerationTable.propTypes = {
    hasSearch: PropTypes.bool,
    hasPagination: PropTypes.bool,
};

DocumentGenerationTable.defaultProps = {
    hasSearch: true,
    hasPagination: true,
};

export default DocumentGenerationTable;

