import {
    faCheck,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Typography} from '@mui/joy';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {AccountItem} from '../../../../components/joy-ui/forms/AccountItem';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {ModalsKeys} from '../../../ui/utils/constants';
import {getIntegrationInfo} from '../../modules/bridge-api/utils/bridge-util';
import {BankSelector} from '../../store/bank.selector';

const modalKey = ModalsKeys.NEW_DEFAULT_ACCOUNT_MODAL;
const NewDefaultAccountModal = () => {
    const {t} = useTranslation(LocalesConstants.Bank);

    const integrations = useSelector(BankSelector.selectIntegrations);

    const {account: chosenAccount} = getIntegrationInfo(integrations?.[0]);

    return (
        <ModalWrapper
            isV3
            containerSx={{width: '600px'}}
            modalKey={modalKey}
            title={(
                <Typography level="h1">
                    {t('accountArchiving.selectDefaultTitle')}
                </Typography>
            )}
            buttons={({handleClose}) => (
                <Button
                    fullWidth
                    startDecorator={(
                        <FontAwesomeIcon icon={faCheck} />
                    )}
                    loadingPosition="start"
                    onClick={() => {
                        handleClose();
                    }}
                    color="success"
                    size="lg"
                    variant="soft"
                >
                    {t('archivingActions.validate')}
                </Button>
            )}
        >
            <Typography level="body-lg" sx={{mb: 1, color: 'text.secondary'}}>
                {t('accountArchiving.selectDefaultText1')}
            </Typography>

            <Typography level="body-lg" sx={{mb: 2, color: 'text.secondary'}}>
                {t('accountArchiving.selectDefaultText2')}
            </Typography>

            {chosenAccount?.iban && (
                <AccountItem
                    value={chosenAccount.iban}
                    name={chosenAccount?.name}
                    accountNumber={chosenAccount.iban}
                    balance={chosenAccount?.availableBalance}
                    isSelected
                    isDefault
                    defaultBadgeTitle={t('bridgeAPI.pickDefaultBankModal.default')}
                    isRadioButtonHidden
                />
            )}
        </ModalWrapper>
    );
};

export default NewDefaultAccountModal;
