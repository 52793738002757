import {createSelector} from 'reselect';
import {V3ModalsKeys} from '../utils/constants';

const selectActiveModals = state => state.ui.activeModals;
const selectModalData = state => state.ui.modalData;
const selectActiveSidebarDrawers = state => state.ui.activeSidebarDrawers;
const selectSidebarDrawerData = state => state.ui.sidebarDrawerData;
const selectIsMaintenanceActive = state => state.ui.isMaintenanceActive;

const createIsModalActiveSelector = modalKey => createSelector(
    [selectActiveModals],
    activeModals => !!activeModals[modalKey],
);

const createModalDataSelector = modalKey => createSelector(
    [selectModalData],
    modalData => modalData[modalKey],
);

const createIsSidebarDrawerActiveSelector = drawerKey => createSelector(
    [selectActiveSidebarDrawers],
    activeSidebarDrawers => !!activeSidebarDrawers[drawerKey],
);

const createSidebarDrawerDataSelector = drawerKey => createSelector(
    [selectSidebarDrawerData],
    sidebarDrawerData => sidebarDrawerData[drawerKey],
);

// Returns true if at least one MUI (not JoyUI) modal is open
// TODO: remove after deleting MUI version
const createHasMuiActiveModal = () => createSelector(
    [selectActiveModals],
    activeModals => {
        return Object.entries(activeModals).some(entry => {
            const modalKey = entry[0];
            const value = entry[1];

            return !V3ModalsKeys.includes(modalKey) && value === true;
        });
    },
);

export const UiSelectors = {
    selectActiveModals,
    selectIsMaintenanceActive,
    createIsModalActiveSelector,
    createHasMuiActiveModal,
    createModalDataSelector,
    createIsSidebarDrawerActiveSelector,
    createSidebarDrawerDataSelector,
};
