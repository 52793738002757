import {all, call, fork, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import {DashboardV3Actions} from './dashboard-v3.action';
import {DashboardV3ActionTypes} from './dashboard-v3.action-type';
import {selectDashboardCompany, selectDashboardUser} from './dashboard-v3.selector';
import {Toast} from '../../../lib/toast';
import {getCurrentDateTz} from '../../../utils/date';
import {Debug} from '../../../utils/debug';
import {isUserAdminFn, isUserCareFn, isUserUmbrellaFn} from '../../../utils/user-roles';
import {BankApi} from '../../bank/api/bank.api';
import {TransactionsActions} from '../../bank/modules/account-balance/store/transactions.action';
import {getUserIntegrationDataFlow} from '../../bank/store/bank.saga';
import {AccountingApi} from '../../company-profile/modules/accounting/api/accounting.api';
import {getAnnualAccountsFlow} from '../../company-profile/modules/accounting/store/accounting.saga';
import {DashboardApi} from '../../dashboard/api/dashboard.api';
import {InsuranceActions} from '../../insurance/store/insurance.action';
import {InvoiceApi} from '../../invoicing/api/invoice.api';
import {LoadingActions, LoadingTypes} from '../../loading';
import {toPayCalculationInfoDTO} from '../../user-list/api/user.dto';
import {LoggedInUserActions, LoggedInUserSelectors} from '../../user/modules/logged-in-user';
import {selectLoggedInUser} from '../../user/store/user.selectors';
import {YearPickerKeys} from '../utils/constants';

export const dashboardV3LoaderSaga = function* () {
    yield put(DashboardV3Actions.fetchData());
    yield put(DashboardV3Actions.resetYearPickers());
};

const fetchTransactionOverview = function* (freelancerId) {
    try {
        return yield call(BankApi.getTransactionOverview, freelancerId);
    } catch (error) {
        Debug.error('fetchTransactionOverview', error);
        return null;
    }
};

const fetchFinancialData = function* (freelancerId, year) {
    try {
        return yield call(InvoiceApi.getFinancialData, {freelancerId, year});
    } catch (error) {
        Debug.error('fetchFinancialData', error);
        return null;
    }
};

const fetchInvoiceStatsIndicators = function* (companyId) {
    try {
        return yield call(InvoiceApi.getInvoiceStatsIndicators, {companyId});
    } catch (error) {
        Debug.error('fetchInvoiceStatsIndicators', 'Error: ', {error});
        return null;
    }
};

const fetchExpensesStats = function* (freelancerId, year) {
    try {
        return yield call(BankApi.getExpensesStats, {freelancerId, year});
    } catch (error) {
        Debug.error('fetchExpensesStats', error);
        return null;
    }
};

const fetchRemunerationStats = function* (freelancerId, year) {
    try {
        return yield call(BankApi.getRemunerationStats, {freelancerId, year});
    } catch (error) {
        Debug.error('fetchRemunerationStats', error);
        return null;
    }
};

const fetchSocialTaxStats = function* (freelancerId) {
    try {
        return yield call(BankApi.getSocialTaxStats, {freelancerId});
    } catch (error) {
        Debug.error('getSocialTaxStats', error);
        return null;
    }
};

// Flow
const requestRemunerationCalculationFlow = function* ({companyId, calculationType}) {
    yield put(LoadingActions.setLoading(LoadingTypes.REQUEST_PAY_CALCULATION_INFO, true));

    try {
        const data = yield call(DashboardApi.requestPayCalculation, {
            companyId,
            calculationType,
        });

        // TODO: Refetch only financial data, no need for the whole dashboard
        yield call(fetchDataFlow);

        const loggedInUserData = yield select(LoggedInUserSelectors.selectLoggedInUserDashboard);
        yield put(
            LoggedInUserActions.storeLoggedInUserAccountForDashboard({
                ...loggedInUserData,
                additionalInfo: {
                    ...loggedInUserData?.additionalInfo,
                    pay_calculation_info: toPayCalculationInfoDTO(data),
                },
            }),
        );
    } catch (error) {
        Toast.error('anErrorOccurred');
        Debug.error('pay-calculation-info', 'Error: ', {error});
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.REQUEST_PAY_CALCULATION_INFO, false));
    }
};

const fetchDataFlow = function* () {
    try {
        const loggedInUser = yield select(selectLoggedInUser);
        const dashboardUser = yield select(selectDashboardUser);
        const dashboardCompany = yield select(selectDashboardCompany);

        const isDashboardUserCare = isUserCareFn(dashboardUser);
        const isDashboardUserUmbrella = isUserUmbrellaFn(dashboardUser);

        const isUserNonUmbrellaAndCare = isDashboardUserUmbrella || isDashboardUserCare;

        yield put(LoadingActions.setLoading(LoadingTypes.FETCH_DASHBOARD_V3_DATA, true));

        // Need to call this first in order to be able to get category list
        if (isUserAdminFn(loggedInUser)) {
            yield call(getUserIntegrationDataFlow, {userId: dashboardUser.id});
        }

        yield put(TransactionsActions.getCategoryList());

        if (!isUserNonUmbrellaAndCare) {
            yield put(InsuranceActions.getInsurance({freelancerId: dashboardUser.id, companyId: dashboardCompany.id}));
        }

        // Needed for notifications in control center
        if (!isDashboardUserUmbrella) {
            yield fork(getAnnualAccountsFlow, dashboardCompany.id);
        }

        // const currentYear = getCurrentDateTz().year()u;
        const freelancerId = dashboardUser.id;

        const currentYear = getCurrentDateTz().year();

        const apiCalls = {
            bankAccounts: call(fetchTransactionOverview, freelancerId),
            financialData: call(fetchFinancialData, freelancerId, currentYear),
            expensesStats: call(fetchExpensesStats, freelancerId, currentYear),
            remunerationStats: call(fetchRemunerationStats, freelancerId, currentYear),
            socialTaxStats: call(fetchSocialTaxStats, freelancerId),
            ...(!isDashboardUserCare && {
                invoiceStatsIndicators: call(fetchInvoiceStatsIndicators, dashboardCompany.id),
            }),
        };


        // TODO: Split into multiple reducers
        const {
            bankAccounts,
            financialData,
            expensesStats,
            remunerationStats,
            socialTaxStats,
            invoiceStatsIndicators,
        } = yield all(apiCalls);

        yield put(
            DashboardV3Actions.storeData({
                bankAccounts,
                financialDataByYear: {[currentYear]: financialData},
                expensesStatsByYear: {[currentYear]: expensesStats},
                remunerationStatsByYear: {[currentYear]: remunerationStats},
                socialTaxStats,
                invoiceStatsIndicators,
            }),
        );

        yield put(LoadingActions.setLoading(LoadingTypes.FETCH_DASHBOARD_V3_DATA, false));
    } catch (error) {
        Debug.error('dashboard', 'Error: ', {error});
        Toast.error('anErrorOccurred');
        throw error;
    }
};

const getLatestIncomeTaxSimulationFlow = function* ({year}) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.INCOME_TAX_FETCH_SIMULATION, true));
        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
        const dashboardUser = yield select(selectDashboardUser);
        const isAdmin = isUserAdminFn(loggedInUser);

        const result = yield call(AccountingApi.getLatestIncomeTaxSimulation, {
            userId: isAdmin ? dashboardUser.id : loggedInUser.id,
            year,
        });
        if (!result) {
            yield put(DashboardV3Actions.storeIncomeTaxSimulation({isEmpty: true}));
        } else {
            yield put(DashboardV3Actions.storeIncomeTaxSimulation(result));
        }
    } catch (e) {
        // Notify about the error
        Debug.error('[Dashboard] generateIncomeTaxSimulationFlow', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.INCOME_TAX_FETCH_SIMULATION, false));
    }
};

const generateIncomeTaxSimulationFlow = function* (data) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.INCOME_TAX_GENERATE_SIMULATION, true));
        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
        const result = yield call(AccountingApi.generateIncomeTaxSimulation, {userId: loggedInUser.id, data});
        yield put(DashboardV3Actions.storeIncomeTaxSimulation(result));
    } catch (e) {
        Debug.error('[Dashboard] generateIncomeTaxSimulationFlow', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.INCOME_TAX_GENERATE_SIMULATION, false));
    }
};

// Workers
const fetchDataWorker = function* () {
    yield call(fetchDataFlow);
};

const requestRemunerationCalculationWorker = function* ({payload}) {
    const {companyId, calculationType} = payload;

    yield call(requestRemunerationCalculationFlow, {companyId, calculationType});
};

const generateIncomeTaxSimulationWorker = function* ({payload}) {
    const {data} = payload;
    yield call(generateIncomeTaxSimulationFlow, data);
};

const getLatestIncomeTaxSimulationWorker = function* ({payload}) {
    const {year} = payload;
    yield call(getLatestIncomeTaxSimulationFlow, {year});
};

const getRevenueDetailsDataByYearFlow = function* (year) {
    try {
        const dashboardUser = yield select(selectDashboardUser);
        const freelancerId = dashboardUser.id;
        const dashboardData = yield select(state => state.dashboardV3.data);

        const [financialData, remunerationStats] = yield all([
            call(fetchFinancialData, freelancerId, year),
            call(fetchRemunerationStats, freelancerId, year),
        ]);
        yield put(
            DashboardV3Actions.storeData({
                ...dashboardData,
                financialDataByYear: {
                    ...dashboardData.financialDataByYear,
                    [year]: financialData,
                },
                remunerationStatsByYear: {
                    ...dashboardData.remunerationStatsByYear,
                    [year]: remunerationStats,
                },
            }),
        );
    } catch (e) {
        Toast.error('genericError');
        Debug.error('[Dashboard] getRevenueDetailsDataByYearFlow', 'Error: ', {e});
    }
};

const getActivityDataByYearFlow = function* (year) {
    try {
        const dashboardUser = yield select(selectDashboardUser);
        const freelancerId = dashboardUser.id;
        const dashboardData = yield select(state => state.dashboardV3.data);

        const [financialData, expensesStats] = yield all([
            call(fetchFinancialData, freelancerId, year),
            call(fetchExpensesStats, freelancerId, year),
        ]);
        yield put(
            DashboardV3Actions.storeData({
                ...dashboardData,
                financialDataByYear: {
                    ...dashboardData.financialDataByYear,
                    [year]: financialData,
                },
                expensesStatsByYear: {
                    ...dashboardData.expensesStatsByYear,
                    [year]: expensesStats,
                },
            }),
        );
    } catch (e) {
        Toast.error('genericError');
        Debug.error('[Dashboard] getActivityDataByYearFlow', 'Error: ', {e});
    }
};

const yearPickerWorker = function* ({payload}) {
    const {yearPickerKey, value: year} = payload;

    switch (yearPickerKey) {
        case YearPickerKeys.REVENUE_DETAILS: {
            yield call(getRevenueDetailsDataByYearFlow, year);
            break;
        }
        case YearPickerKeys.ACTIVITY: {
            yield call(getActivityDataByYearFlow, year);
            break;
        }
    }
};

export const watchDashboardV3Saga = function* () {
    yield all([
        takeLatest(DashboardV3ActionTypes.FETCH_DATA, fetchDataWorker),
        takeLatest(
            DashboardV3ActionTypes.REQUEST_REMUNERATION_CALCULATION,
            requestRemunerationCalculationWorker,
        ),
        takeEvery(DashboardV3ActionTypes.INCOME_TAX_GENERATE_SIMULATION, generateIncomeTaxSimulationWorker),
        takeEvery(
            DashboardV3ActionTypes.GET_LATEST_INCOME_TAX_SIMULATION,
            getLatestIncomeTaxSimulationWorker,
        ),
        takeLatest(DashboardV3ActionTypes.SET_YEAR_PICKER_VALUE, yearPickerWorker),
    ]);
};
