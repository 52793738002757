import InfoIcon from '@mui/icons-material/Info';
import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import BankCardSelectorToggler from './BankCardSelectorToggler';
import {PhysicalCard} from './PhysicalCard';
import {VirtualCard} from './VirtualCard';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {CARD_TYPES} from '../../util/constants';

const BankCardSwitcher = ({
    selectedCard,
    setSelectedCard,
    hasReversedCards,
    openPhysModal,
    user,
    card,
    activateCardModal,
    unlockBlockedModal,
    unlockCard,
    openVirtualModal,
}) => {
    const {t} = useTranslation(LocalesConstants.BankCards);

    const isVirtualCardSelected = selectedCard === CARD_TYPES.VIRTUAL;

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
        }}
        >
            <Box sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mb: 1,
            }}
            >
                <Typography sx={{
                    fontSize: theme => theme.typography.pxToRem(21),
                    fontWeight: 700,
                }}
                >
                    {isVirtualCardSelected
                        ? t('cardDetails.virtualCard')
                        : t('cardDetails.physicalCard')
                    }
                </Typography>
                <InfoIcon
                    onClick={isVirtualCardSelected ? openVirtualModal : openPhysModal}
                    sx={{
                        cursor: 'pointer',
                        ml: 1,
                        color: '#78909C',
                    }}
                />
            </Box>


            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                flexDirection: hasReversedCards
                    ? 'row-reverse' : 'row',
                left: hasReversedCards
                    ? selectedCard === CARD_TYPES.PHYS ? '-22.5%' : '27.5%'
                    : selectedCard === CARD_TYPES.PHYS ? '22.5%' : '-27.5%',
                transition: 'left 0.5s',
            }}
            >
                <Box>
                    <PhysicalCard
                        t={t}
                        user={user}
                        card={card}
                        activateCardModal={activateCardModal}
                        unlockBlockedModal={unlockBlockedModal}
                        unlockCard={unlockCard}
                        setSelectedCard={setSelectedCard}
                    />
                </Box>
                <Box>
                    <VirtualCard
                        t={t}
                        user={user}
                        card={card}
                        setSelectedCard={setSelectedCard}
                    />
                </Box>
            </Box>

            <BankCardSelectorToggler
                setSelectedCard={setSelectedCard}
                selectedCard={selectedCard}
                card={card}
            />
        </Box>

    );
};

BankCardSwitcher.propTypes = {
    selectedCard: PropTypes.string.isRequired,
    setSelectedCard: PropTypes.func.isRequired,
    hasReversedCards: PropTypes.bool.isRequired,
    openPhysModal: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    card: PropTypes.object.isRequired,
    activateCardModal: PropTypes.func.isRequired,
    unlockBlockedModal: PropTypes.func.isRequired,
    unlockCard: PropTypes.func.isRequired,
    openVirtualModal: PropTypes.func.isRequired,
};

export default BankCardSwitcher;
