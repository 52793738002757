import {Box} from '@mui/material';
import moment from 'moment/moment';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Prospect} from './components/Prospect';
import {Status} from './components/Status';
import {ViewResultsButton} from './components/ViewResultsButton';
import {LocalesConstants} from '../../../../utils/locales-constants';

export const useColumnConfig = () => {
    const {t} = useTranslation(LocalesConstants.RevenueSimulator);

    return [
        {
            key: 'prospect',
            title: 'Prospect',
            isSortable: false,
            component: Prospect,
        },
        {
            key: 'coachEmail',
            title: t('simulationsList.coach'),
            isSortable: true,
        },
        {
            key: 'dateOfSimulation',
            title: t('simulationsList.simulationDate'),
            isSortable: true,
            component: ({rowData}) => <Box>{moment(rowData.createdAt).format('DD/MM/YYYY')}</Box>,
        },
        {
            key: 'status',
            title: t('simulationsList.status'),
            isSortable: true,
            component: Status,
        },
        {
            key: 'results',
            title: t('simulationsList.results'),
            isSortable: false,
            textAlign: 'right',
            shouldPreserveWhiteSpace: true,
            component: ViewResultsButton,
        },
    ];
};
