import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {LoadingButton} from '@mui/lab';
import {Box, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import BankBuildingImage from '../../../../assets/svg/bank/bank-building.svg';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {LANDING_PAGE_BORDER_RADIUS} from '../../utils/constants';

const BankOfFranceSection = ({onCreationStartClick}) => {
    const {t} = useTranslation(LocalesConstants.Bank);
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.BANK_CREATION),
    );

    return (
        <Box sx={{
            borderRadius: LANDING_PAGE_BORDER_RADIUS,
            background: 'linear-gradient(270deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%), #3779BE',
            display: 'flex',
            flexDirection: isMobileSize ? 'column-reverse' : 'row',
            mt: isMobileSize ? 2 : 3,
            pt: isMobileSize ? 4 : 6,
            pl: isMobileSize ? 2 : 6,
            pb: isMobileSize ? 2 : 5,
        }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: isMobileSize ? '100%' : '50%',
                }}
            >
                <Box sx={{
                    width: isMobileSize ? '100%' : '480px',
                }}
                >
                    <Typography variant="h3" sx={{color: 'white'}}>
                        {t('landing.registeredAtBankOfFrance')}
                    </Typography>
                </Box>
                <Box sx={{
                    mt: 1,
                    width: isMobileSize ? '100%' : '480px',
                    color: 'white',
                    fontSize: theme => theme.typography.pxToRem(21),
                    lineHeight: '31.5px',
                    letterSpacing: '0.25px',
                }}
                >
                    {t('landing.swanActivityRegulation')}
                </Box>
                <Box sx={{mt: isMobileSize ? 5 : 16}}>
                    <LoadingButton
                        loading={isLoading}
                        onClick={onCreationStartClick}
                        sx={{color: 'white'}}
                        endIcon={<ArrowForwardIcon />}
                    >
                        {t('landing.discoverHiwayBank')}
                    </LoadingButton>
                </Box>
            </Box>
            <Box
                sx={{
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img style={isMobileSize ? {width: '160px'} : {}} src={BankBuildingImage} alt="bank" />
            </Box>
        </Box>
    );
};

BankOfFranceSection.propTypes = {
    onCreationStartClick: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default BankOfFranceSection;
