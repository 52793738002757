import AddBoxIcon from '@mui/icons-material/AddBox';
import ReceiptIcon from '@mui/icons-material/Receipt';
import {Box, Button, Grow, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {ReimbursedPanelInfo} from './ReimbursementPanelInfo';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';
import {TransactionsActions} from '../../../../store/transactions.action';
import {emptyFunction} from '../../../../util/constants';
import useReimbusement from '../../../hooks/use-reimbusement';
import {SECTION_STATUS} from '../../utils/constants';
import TransactionTva from '../debit/TransactionTva';
import CategorisationSection from '../header/CategorisationSection';

const CreditReimbusementPanel = ({
    onOpenRembursement,
    isEditable,
    details,
    setIsFakeReimbursementOpen,
}) => {
    const {t} = useTranslation(LocalesConstants.Bank);
    const dispatch = useDispatch();

    const {
        amount,
        selectedReimbursementTransactionsObj,
        paidAmount,
        onSelectTransaction,
    } = useReimbusement({details, dispatch, isEditable});

    const hasPaid = selectedReimbursementTransactionsObj?.length > 0 || paidAmount;

    const isCompleted = Math.round(100 * paidAmount / amount) === 100;

    const hasFakeReimbursement = !!selectedReimbursementTransactionsObj?.[0]?.isFakeReimbursement;

    const saveFakeTransactionTVA = params => {
        dispatch(TransactionsActions.saveTransactionField({
            id: details.id,
            fieldKey: 'fakeReimbursedTransactions',
            fieldValue: [{
                'category': params?.[0].category,
                'amount': params?.[0]?.amount,
                'tva': params?.[0].tva,
            }],
            accountId: details?.bankAccountId,
        }));
    };


    return (
        <Grow in={true}>
            <Box>
                <CategorisationSection
                    title={t(`categorisation.reimbursementPanel.title`)}
                    status={isCompleted ? null : SECTION_STATUS.REQUIRED}
                    sx={{
                        flex: '2 0 0',
                        p: 2,
                        pt: 1,
                        justifyContent: 'space-between',
                        gap: 1.5,
                    }}
                    isActive
                >
                    {!hasPaid ? (
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                        >
                            <Box sx={{
                                display: 'flex',
                                gap: 1,
                                alignItems: 'center',
                            }}
                            >
                                <ReceiptIcon sx={{
                                    color: 'v2.light.action.active',
                                }}
                                />
                                <Typography sx={{
                                    fontSize: theme => theme.typography.pxToRem(14),
                                    fontWeight: 500,
                                    letterSpacing: '0.1px',
                                    fontFeatureSettings: `'clig' off, 'liga' off`,
                                    color: 'text_v2.secondary',
                                }}
                                >
                                    {t(`categorisation.reimbursementPanel.add`)}
                                </Typography>
                            </Box>

                            <Button
                                sx={{
                                    fontSize: theme => theme.typography.pxToRem(14),
                                }}
                                startIcon={<AddBoxIcon />}
                                onClick={onOpenRembursement}
                                fullWidth={false}
                            >
                                {t(`categorisation.reimbursementPanel.addButton`)}
                            </Button>
                        </Box>
                    ) : (
                        <Box>
                            <ReimbursedPanelInfo
                                amount={amount}
                                allocated={paidAmount}
                                onOpenRembursement={onOpenRembursement}
                                selectedReimbursementTransactionsArray={selectedReimbursementTransactionsObj}
                                onSelectTransaction={onSelectTransaction}
                                setIsFakeReimbursementOpen={setIsFakeReimbursementOpen}
                            />
                        </Box>
                    )}

                </CategorisationSection>

                {hasFakeReimbursement && (
                <Grow in={true}>

                    <div>
                        <CategorisationSection
                            title="TVA"
                            status={null}
                            sx={{
                                flex: '2 0 0',
                                p: 2,
                                mt: 2,
                                pt: 1,
                                justifyContent: 'space-between',
                                gap: 1.5,
                            }}
                            isActive
                        >
                            <TransactionTva
                                details={{articles: selectedReimbursementTransactionsObj}}
                                isEditable={true}
                                saveTransaction={saveFakeTransactionTVA}
                                handleOpenArticles={emptyFunction}
                            />
                        </CategorisationSection>
                    </div>
                </Grow>
                )}
            </Box>
        </Grow>
    );
};

CreditReimbusementPanel.propTypes = {
    details: PropTypes.object.isRequired,
    isEditable: PropTypes.bool.isRequired,
    onOpenRembursement: PropTypes.func.isRequired,
    setIsFakeReimbursementOpen: PropTypes.func.isRequired,
};


export default CreditReimbusementPanel;
