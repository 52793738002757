import {Box, Grow, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';

const InvoiceAlreadyPaidInfo = ({
    amount,
    paid,
    isFullPaid,
}) => {
    const {t} = useTranslation(LocalesConstants.Bank);
    const percents = !amount
        ? 100
        : Math.round(100 * paid / amount);

    return (
        <Grow in={true}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                mb: 2,
            }}
            >
                <Typography sx={{fontWeight: 500}}>
                    {t('invoices.amountRemaining')}
                </Typography>

                <Box sx={{
                    mt: 1,
                    height: '4px',
                    borderRadius: '2px',
                    backgroundColor: 'primary_v2.shades60',
                    position: 'relative',
                }}
                >
                    <Box sx={{
                        height: '4px',
                        borderRadius: '2px',
                        backgroundColor: isFullPaid ? 'success_v2.light' : 'primary_v2.main',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: `${percents}%`,
                    }}
                    />
                </Box>
            </Box>
        </Grow>
    );
};

InvoiceAlreadyPaidInfo.propTypes = {
    amount: PropTypes.number.isRequired,
    paid: PropTypes.number.isRequired,
    isFullPaid: PropTypes.bool.isRequired,
};

export default InvoiceAlreadyPaidInfo;
