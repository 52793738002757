import {faBuilding, faClipboardList, faEdit} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Box, Button, Stack, Typography} from '@mui/joy';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, {useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {CreateCraFormFooter} from './CreateCraFormFooter';
import {DateSelection} from '../../../../../components/joy-ui/custom-date-pickers/DateSelection';
import {InfoSheet} from '../../../../../components/joy-ui/info-sheet/InfoSheet';
import Section from '../../../../../components/joy-ui/sections/Section';
import {useIsMobileSize} from '../../../../../hooks/use-is-mobile-size';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {BE_DATE_FORMAT} from '../../../../../utils/constants';
import {LocalesConstants} from '../../../../../utils/locales-constants';
import {
    PAYMENT_TERMS,
    SERVICE_PERIOD_DATE_FORMAT,
    VALUE_ADDED_TAX_OPTIONS_MULTIPLIER,
} from '../../../../invoicing/util/constants';
import {UiActions} from '../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../ui/utils/constants';

const CreateCraForm = ({
    mission,
    selectedDates,
    selectedHalfDates,
    setSelectedDates,
    setSelectedHalfDates,
}) => {
    const calendarRef = useRef();
    const {t} = useTranslation(LocalesConstants.Missions);
    const dispatch = useDispatch();
    const isMobileSize = useIsMobileSize();

    const {
        totalRate,
        totalRateWithTax,
        minDate,
        maxDate,
    } = useMemo(() => {
        const rate = mission?.dailyRate ?? 0;
        let totalRateWithTax = 0;
        const totalRate = rate * selectedDates?.length + (rate * selectedHalfDates?.length) / 2;
        if (mission?.client?.vatType) {
            totalRateWithTax = totalRate * VALUE_ADDED_TAX_OPTIONS_MULTIPLIER[mission.client.vatType];
        }

        let minDate = moment(mission?.startDate);
        let maxDate = moment().endOf('month');

        if (selectedDates?.[0]) {
            const selectedMinDate = moment(selectedDates[0]).startOf('month');
            if (selectedMinDate.isAfter(minDate, 'day')) {
                minDate = selectedMinDate;
            }
            maxDate = moment(selectedDates[0]).endOf('month');
        }

        if (selectedHalfDates?.[0]) {
            const selectedMinDate = moment(selectedHalfDates[0]).startOf('month');
            if (selectedMinDate.isAfter(minDate, 'day')) {
                minDate = selectedMinDate;
            }
            maxDate = moment(selectedHalfDates[0]).endOf('month');
        }

        return {
            totalRate,
            totalRateWithTax,
            minDate,
            maxDate,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDates, selectedHalfDates, mission?.startDate]);

    const onConfirmClick = () => {
        const dates = [];
        let hours = 0;
        let days = 0;
        let period;
        selectedDates.forEach(date => {
            if (!period) {
                period = moment(date).format(SERVICE_PERIOD_DATE_FORMAT);
            }
            hours += 8;
            days += 1;
            dates.push({
                'date': moment(date).format(BE_DATE_FORMAT),
                'hours': 8,
            });
        });
        selectedHalfDates.forEach(date => {
            if (!period) {
                period = moment(date).format(SERVICE_PERIOD_DATE_FORMAT);
            }
            hours += 4;
            days += 0.5;
            dates.push({
                'date': moment(date).format(BE_DATE_FORMAT),
                'hours': 4,
            });
        });

        const data = {
            missionId: mission?.id,
            startDate: mission?.startDate,
            endDate: mission?.endDate,
            isCash: mission?.client?.paymentTerm === PAYMENT_TERMS.IMMEDIATE_PAYMENT,
            totalRate,
            totalRateWithTax,
            dates,
            period,
            hours,
            days,
        };
        dispatch(UiActions.setModalData(ModalsKeys.CONFIRM_CRA_SENDING, data));
        dispatch(UiActions.setActiveModal(ModalsKeys.CONFIRM_CRA_SENDING, true));
    };

    return (
        <>
            <Stack
                direction="column"
                gap={isMobileSize ? 3 : 2}
                sx={{
                    ...(isMobileSize ? {px: 2, pt: 3, pb: 22} : {pb: 8}),
                }}
            >
                <Typography
                    level="h1"
                    sx={isMobileSize
                        ? {textTransform: 'none', fontStyle: 'normal'}
                        : {}
                    }
                >
                    {t('cra.new')}
                </Typography>

                <InfoSheet
                    title={mission?.clientName}
                    titleProps={{
                        level: 'title-lg',
                        sx: {
                            color: 'text.primary',
                        },
                    }}
                    descriptionProps={{
                        level: 'body-md',
                        sx: {
                            color: 'text.secondary',
                        },
                    }}
                    icon={faBuilding}
                    description={<Description mission={mission} isMobileSize={isMobileSize} />}
                    cta={(
                        <Button
                            disabled={true}
                            variant="solid"
                            color="neutral"
                            size="sm"
                            component={Link}
                            // TODO
                            to={RoutePaths.MISSIONS}
                            startDecorator={<FontAwesomeIcon icon={faEdit} />}
                            sx={{width: {desktop: 'auto', mobile: '100%'}}}
                        >
                            {t('cra.editInfo')}
                        </Button>
                    )}
                    isMobileSize={isMobileSize}
                />


                <Box
                    ref={calendarRef}
                >
                    <Section
                        icon={faClipboardList}
                        title={t('cra.activityDeclaration')}
                        bodyStyles={{
                            p: 3,
                            pb: 2,
                        }}
                        sx={{
                            '& .MuiDayPicker-slideTransition': {
                                minHeight: '386px',
                            },
                            '& .JoyDatePickerWeek': {
                                justifyContent: 'space-around',
                                display: 'flex',
                                gap: 1,
                                mb: 1,
                            },
                            '& .JoyDatePickerWeekDays': {
                                justifyContent: 'space-around',
                            },
                            '& .JoyDatePickerWeekDay': {
                                fontSize: 'md',
                            },
                            '& .JoyDatePickerViewRoot': {
                                maxHeight: 'none',
                                width: '100%',
                            },
                            '& .JoyDatePickerCalendarRoot': {
                                maxHeight: 'none',
                                width: '100%',
                            },
                            '& .MuiPickersCalendarHeader-root': {
                                my: 3,
                                px: 0,
                            },
                        }}
                    >
                        <Alert
                            color="neutral"
                            type="soft"
                            sx={{
                                flexDirection: 'column',
                                gap: 0,
                            }}
                        >
                            <Typography level="title-md" textColor="neutral.softColor">
                                {t('cra.selectDates')}
                            </Typography>
                            <Typography level="body-sm" textColor="neutral.softColor">
                                {t('cra.validateDates')}
                            </Typography>
                        </Alert>

                        <DateSelection
                            selectedDates={selectedDates}
                            selectedHalfDates={selectedHalfDates}
                            setSelectedDates={setSelectedDates}
                            setSelectedHalfDates={setSelectedHalfDates}
                            sectionTitle=""
                            alertTitle=""
                            alertText=""
                            minDate={minDate}
                            maxDate={maxDate}
                        />
                    </Section>

                </Box>
            </Stack>

            <CreateCraFormFooter
                calendarRef={calendarRef}
                onConfirmClick={onConfirmClick}
                amount={totalRate}
                amountWithTax={totalRateWithTax}
                isMobileSize={isMobileSize}
            />
        </>
    );
};

CreateCraForm.propTypes = {
    mission: PropTypes.object.isRequired,
    selectedDates: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedHalfDates: PropTypes.arrayOf(PropTypes.string).isRequired,
    setSelectedDates: PropTypes.func.isRequired,
    setSelectedHalfDates: PropTypes.func.isRequired,
};

const Description = ({mission, isMobileSize}) => {
    const {t: tInvoice} = useTranslation(LocalesConstants.InvoicingNew);
    const {t} = useTranslation(LocalesConstants.Missions);
    const {t: userT} = useTranslation(LocalesConstants.CreateUser);

    return (
        <Stack
            direction={isMobileSize ? 'column' : 'row'}
            gap={isMobileSize ? 0 : 2}
        >
            <Stack
                direction="row"
                alignItems="center"
                gap={1}
            >
                <Typography>
                    {userT(`jobTypes.${mission?.jobType}`)}
                </Typography>
                <Box sx={{
                    color: 'text.tertiary',
                }}
                >
                    •
                </Box>
                <Typography sx={{
                    fontWeight: 'bold',
                }}
                >
                    {mission?.dailyRate}€ / {t('cra.day')}
                </Typography>
            </Stack>

            <Stack
                direction="row"
                alignItems="center"
                gap={1}
            >
                <Typography>
                    {t('cra.paymentDeadline')}
                </Typography>
                <Box sx={{
                    color: 'text.tertiary',
                }}
                >
                    •
                </Box>
                <Typography sx={{
                    fontWeight: 'bold',
                }}
                >
                    {tInvoice('clientPaymentFormOptions.' + mission?.client?.paymentTerm)}
                </Typography>
            </Stack>
        </Stack>
    );
};

Description.propTypes = {
    mission: PropTypes.object.isRequired,
    isMobileSize: PropTypes.bool.isRequired,
};

export default CreateCraForm;
