import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {useUploadDocumentStyles} from './styles';
import {LocalesConstants} from '../../../utils/locales-constants';
import DropZone from '../DropZone';

const UploadDocuments = props => {
    const {
        files,
        onAddFiles,
        onDeleteFile,
        acceptFiles,
        dropZoneText,
        buttonText,
        isMultiple,
        isDisabled,
        fileLimit,
        hasMaxFileInfoForced,
        readableFileExtension,
        isUploadInProgress,
    } = props;

    const styles = useUploadDocumentStyles();

    const {t} = useTranslation(LocalesConstants.Documents);

    const translatedDropzoneText = dropZoneText === undefined
        ? t('attachDocumentsDropZoneText')
        : dropZoneText;

    return (
        <DropZone
            className={styles.dropZone}
            acceptFiles={acceptFiles}
            onDropAccepted={onAddFiles}
            buttonText={buttonText}
            dropZoneText={translatedDropzoneText}
            files={files}
            isMultiple={isMultiple}
            hasChips
            onDeleteFile={onDeleteFile}
            hasNotifications={false}
            isDisabled={isDisabled}
            name="document"
            fileLimit={fileLimit}
            hasMaxFileInfoForced={hasMaxFileInfoForced}
            readableFileExtension={readableFileExtension}
            isUploadInProgress={isUploadInProgress}
        />
    );
};

UploadDocuments.propTypes = {
    onAddFiles: PropTypes.func.isRequired,
    onDeleteFile: PropTypes.func.isRequired,
    files: PropTypes.arrayOf(PropTypes.instanceOf(File)),
    dropZoneText: PropTypes.string,
    buttonText: PropTypes.string,
    acceptFiles: PropTypes.string,
    isMultiple: PropTypes.bool,
    isDisabled: PropTypes.bool,
    fileLimit: PropTypes.number,
    hasMaxFileInfoForced: PropTypes.bool,
    readableFileExtension: PropTypes.string,
    isUploadInProgress: PropTypes.bool,
};

UploadDocuments.defaultProps = {
    files: [],
    dropZoneText: undefined,
    acceptFiles: '.jpg,.jpeg,.png,.pdf,.docx,.doc,.xlsx,.xls',
    buttonText: 'attach',
    isMultiple: true,
    isDisabled: false,
    fileLimit: 1,
    hasMaxFileInfoForced: false,
    readableFileExtension: '',
    isUploadInProgress: false,
};

export default UploadDocuments;
