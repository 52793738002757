import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {Box, Button} from '@mui/material';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';

const ConfirmVAT = ({onConfirm, onReject}) => {
    const {t} = useTranslation(LocalesConstants.Bank);

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
            <Box sx={{
                color: 'v2.light.warning.shades160',
                letterSpacing: '0.15px',
            }}
            >
                {t('categorisation.vatValidation.confirmMessage')}
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                gap: 2,
            }}
            >
                <Button
                    sx={{
                        display: 'flex',
                        flex: 1,
                        color: 'white',
                    }}
                    disableRipple={true}
                    disableFocusRipple={true}
                    variant="contained"
                    color="success"
                    startIcon={<CheckIcon sx={{color: 'white'}} />}
                    onClick={onConfirm}
                >
                    {t('categorisation.vatValidation.validate')}
                </Button>
                <Button
                    sx={{
                        display: 'flex',
                        flex: 1,
                        color: 'white',
                    }}
                    disableRipple={true}
                    disableFocusRipple={true}
                    variant="contained"
                    color="error"
                    startIcon={<CloseIcon sx={{color: 'white'}} />}
                    onClick={onReject}
                >
                    {t('categorisation.vatValidation.invalidate')}
                </Button>
            </Box>
        </Box>
    );
};

ConfirmVAT.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
};

export default ConfirmVAT;
