import {Grid, MenuItem, TextField, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {useDocumentStyles} from './styles';
import {ACCOUNTING_DOCUMENTS} from '../../../../features/document/modules/signing/utils/constants';
import {selectLoggedInUser} from '../../../../features/user/store/user.selectors';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {isUserFn} from '../../../../utils/user-roles';
import ExcelIcon from '../../../icons/ExcelIcon';
import ImageIcon from '../../../icons/ImageIcon';
import PdfEditIcon from '../../../icons/PdfEditIcon';
import PdfIcon from '../../../icons/PdfIcon';
import WordIcon from '../../../icons/WordIcon';

const Document = props => {
    const {
        data,
        onTypeChange,
        onCategoryChange,
        onYearChange,
        type,
        category,
        year,
        types,
        categories,
        years,
        isLoading,
        hasYear,
        isYearVisible,
    } = props;

    const styles = useDocumentStyles();

    const {t} = useTranslation(LocalesConstants.Documents);

    const user = useSelector(selectLoggedInUser);

    const fileExtension = useMemo(() => {
        const parts = data.file.name.split('.');

        return parts[parts.length - 1].toLowerCase();
    }, [data.file]);

    const icon = useMemo(() => {
        switch (fileExtension) {
            case 'docx':
            case 'doc':
                return <WordIcon className={styles.icon} />;

            case 'xlsx':
            case 'xls':
                return <ExcelIcon className={styles.icon} />;

            case 'pdf':
                return <PdfIcon className={styles.icon} />;

            case 'pdf_edit':
                return <PdfEditIcon className={styles.icon} />;

            case 'jpg':
            case 'jpeg':
            case 'png':
                return <ImageIcon className={styles.icon} />;

            default:
                return null;
        }
    }, [fileExtension, styles.icon]);

    const categoryOptions = useMemo(() => {
        return Object.values(categories).map(category => (
            <MenuItem value={category} key={category}>
                {t(`documentsDatabaseCategories.${category}`)}
            </MenuItem>
        ));
    }, [categories, t]);

    const typeOptions = useMemo(() => {
        return Object.values(types).map(type => (
            <MenuItem
                value={type}
                key={type}
                disabled={ACCOUNTING_DOCUMENTS.includes(type)}
            >
                {t(`documentsDatabaseNames.${type}`)}
            </MenuItem>
        ));
    }, [types, t]);

    const yearOptions = useMemo(() => {
        return Object.values(years).map(year => (
            <MenuItem value={year} key={year}>
                {year}
            </MenuItem>
        ));
    }, [years]);

    return (
        <Grid container spacing={3} className={styles.container}>
            <Grid item xs={hasYear ? 4 : 6}>
                <Typography variant="body2" className={styles.titleContainer}>
                    {icon}&nbsp;{data.file.name}
                </Typography>
            </Grid>

            <Grid item xs={3}>
                <TextField
                    required={true}
                    fullWidth={true}
                    variant="standard"
                    color="primary"
                    onChange={onCategoryChange}
                    value={category ?? ''}
                    disabled={isLoading}
                    select
                >
                    {categoryOptions}
                </TextField>
            </Grid>

            <Grid item xs={3}>
                <TextField
                    required={true}
                    fullWidth={true}
                    variant="standard"
                    color="primary"
                    onChange={onTypeChange}
                    value={type ?? ''}
                    disabled={
                        !category
                        || !typeOptions.length
                        || isLoading
                        || (isUserFn(user) && typeOptions.length < 2)}
                    select
                >
                    {typeOptions}
                </TextField>
            </Grid>

            {hasYear && (
                <Grid item xs={2}>
                    {isYearVisible && (
                        <TextField
                            required={true}
                            fullWidth={true}
                            variant="standard"
                            color="primary"
                            onChange={onYearChange}
                            value={year ?? ''}
                            disabled={isLoading}
                            select
                        >
                            {yearOptions}
                        </TextField>
                    )}
                </Grid>
            )}
        </Grid>
    );
};

Document.propTypes = {
    onTypeChange: PropTypes.func.isRequired,
    onCategoryChange: PropTypes.func.isRequired,
    onYearChange: PropTypes.func,
    data: PropTypes.object,
    type: PropTypes.string,
    category: PropTypes.string,
    year: PropTypes.number,
    types: PropTypes.array,
    categories: PropTypes.array,
    years: PropTypes.array,
    isLoading: PropTypes.bool,
    hasYear: PropTypes.bool,
    isYearVisible: PropTypes.bool,
};

Document.defaultProps = {
    onYearChange: null,
    data: null,
    type: null,
    category: null,
    year: null,
    types: [],
    categories: [],
    years: [],
    isLoading: false,
    hasYear: false,
    isYearVisible: false,
};

export default Document;
