import {Box, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {AdminOpportunityList} from './admin-opportunity/AdminOpportunityList';
import {importLocaleBundle} from '../../../lib/i18next';
import {LocalesConstants} from '../../../utils/locales-constants';

importLocaleBundle(LocalesConstants.Opportunity);

export const AdminOpportunity = () => {
    const {t} = useTranslation(LocalesConstants.Opportunity);

    return (
        <Box>
            <Typography variant="h4" sx={{mb: 2}}>
                {t('header')}
            </Typography>

            <AdminOpportunityList />
        </Box>
    );
};
