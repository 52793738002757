import CloseIcon from '@mui/icons-material/Close';
import {Box, Container, Paper, useMediaQuery} from '@mui/material';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {recurringTransferValues} from './CreateRecurringTransfer';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import AccountBalanceIcon from '../../../account-balance/components/account-balance/AccountBalanceIcon';
import {AccountTotalSmall} from '../../../account-balance/components/account-balance/AccountBalanceTotal';

const RecurringTransferScrollHeader = ({data, onClose}) => {
    const {t: tBankTransfer} = useTranslation(LocalesConstants.BankTransfer);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const date = data?.date ?? data?.createdAt;
    const isRejected = false;

    const scrollFunction = () => {
        const scrollToShow = isMobileSize ? 200 : 120;
        if (document.getElementById('navbar-transfer-details-main').scrollTop > scrollToShow) {
            document.getElementById('navbar-transfer-details').style.top = isMobileSize ? '70px' : '0px';
        } else {
            document.getElementById('navbar-transfer-details').style.top = '-118px';
        }
    };

    useEffect(() => {
        document.getElementById('navbar-transfer-details-main')
            ?.addEventListener('scroll', scrollFunction);
        return () => {
            document.getElementById('navbar-transfer-details-main')
                ?.removeEventListener('scroll', scrollFunction);
        };
        // eslint-disable-next-line
    }, [isMobileSize]);

    return (
        <Box
            id="navbar-transfer-details"
            sx={{
                position: 'fixed',
                display: 'inline',
                width: isMobileSize ? '100vw' : '400px',
                right: '0px',
                marginLeft: '240px',
                top: '-118px',
                height: '118px',
                zIndex: '104',
                transition: 'all 300ms ease-in-out',
            }}
        >
            <Paper sx={{
                height: '118px',
            }}
            >
                <Container maxWidth="xl">
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '118px',
                        transition: 'all 300ms ease-in-out 100ms',
                    }}
                    >
                        {data && (
                            <Box sx={{
                                width: isMobileSize ? '100vw' : '400px',
                            }}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                                >
                                    <Box>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            mt: 1,
                                        }}
                                        >
                                            <Box
                                                sx={{
                                                    width: '20px',
                                                    height: '20px',
                                                    borderRadius: '50%',
                                                    background: '#E0F2F1',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <AccountBalanceIcon
                                                    size="20px"
                                                    iconSize="11px"
                                                    paymentType={data?.type}
                                                />
                                            </Box>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                fontWeight: 500,
                                                textTransform: 'uppercase',
                                                wordBreak: 'break-all',
                                                textAlign: 'center',
                                                maxWidth: '90%',
                                                pl: '8px',
                                            }}
                                            >
                                                {data?.label}
                                            </Box>
                                        </Box>

                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                        }}
                                        >
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                mb: '6px',
                                            }}
                                            >
                                                <AccountTotalSmall
                                                    amount={data?.amount ?? '0'}
                                                    isDebit={data?.side !== 'CREDIT'}
                                                    isRejected={isRejected}
                                                />
                                            </Box>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: 'grey.light',
                                                ml: 1,
                                            }}
                                            >
                                                • {moment(date).format('DD MMM YYYY • HH[h]mm')}
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    >
                                        <Box
                                            onClick={onClose}
                                            sx={{
                                                'width': '32px',
                                                'height': '32px',
                                                'cursor': 'pointer',
                                                'display': 'flex',
                                                'alignItems': 'center',
                                                'justifyContent': 'center',
                                                '&:hover': {
                                                    backgroundColor: '#ECEFF1',
                                                    borderRadius: '50%',
                                                },
                                            }}
                                        >
                                            <CloseIcon sx={{
                                                color: '#546E7A',
                                            }}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    textAlign: 'left',
                                    textTransform: 'uppercase',
                                    fontWeight: 400,
                                    fontSize: theme => theme.typography.pxToRem(12),
                                }}
                                >
                                    {data.period === recurringTransferValues.daily && tBankTransfer('transfer.daily')}
                                    {data.period === recurringTransferValues.weekly && tBankTransfer('transfer.weekly')}
                                    {data.period === recurringTransferValues.monthly && tBankTransfer('transfer.monthly')}
                                    : {moment(data.additionalData?.firstExecutionDate).format('DD MMM YYYY')} -
                                    {data.additionalData?.lastExecutionDate
                                        ? ' '
                                        + moment(data.additionalData?.lastExecutionDate).format('DD MMM YYYY')
                                        : ''}
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Container>
            </Paper>
        </Box>
    );
};

RecurringTransferScrollHeader.propTypes = {
    data: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default RecurringTransferScrollHeader;
