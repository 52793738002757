import CloseIcon from '@mui/icons-material/Close';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import SignaturePad from 'signature_pad';

const DrawSignature = ({
    signaturePad,
    setSignaturePad,
    isPadEmpty,
    setIsPadEmpty,
    isMobileSize,
}) => {
    const [undoList, setUndoList] = useState([]);
    const canvasRef = useRef();

    const hasUndoList = undoList?.length !== 0;

    const width = isMobileSize
        ? document.body.clientWidth - 50
        : 400;

    useEffect(() => {
        if (canvasRef?.current) {
            const pad = new SignaturePad(canvasRef?.current, {
                minWidth: 0.7,
                maxWidth: 1.2,
                penColor: 'rgb(125,38,80)',
            });
            setSignaturePad(pad);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvasRef]);

    const onUndo = () => {
        const data = signaturePad.toData();

        if (data) {
            const popped = data.pop(); // remove the last dot or line
            setUndoList(list => {
                const newList = [...list];
                newList.push(popped);

                return newList;
            });
            signaturePad.fromData(data);

            if (data?.length === 0) {
                setIsPadEmpty(true);
            }
        }
    };

    const onRedo = () => {
        if (undoList?.length > 0) {
            const newList = [...undoList];
            const popped = newList.pop();
            setUndoList(newList);

            if (popped) {
                const data = signaturePad.toData();

                data.push(popped);
                signaturePad.fromData(data);

                if (data?.length > 0) {
                    setIsPadEmpty(false);
                }
            }
        }
    };

    const onClear = () => {
        signaturePad.clear();
        setUndoList([]);
        setIsPadEmpty(true);
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: `${width}px`,
        }}
        >
            <canvas
                onClick={() => {
                    if (undoList?.length > 0) {
                        setUndoList([]);
                    }
                }}
                ref={canvasRef}
                id="signatureCanvas"
                width={`${width - 2}px`}
                height="206px"
            />

            <Box sx={{
                height: '48px',
                width: '100%',
                background: 'rgba(25, 118, 210, 0.04)',
                display: 'flex',
                justifyContent: 'space-between',
                px: 2,
                py: 1,
                borderBottomLeftRadius: '12px',
                borderBottomRightRadius: '12px',
            }}
            >
                <Box sx={{
                    display: 'flex',
                    gap: 1,
                }}
                >
                    <Box
                        onClick={onUndo}
                    >
                        <UndoIcon
                            sx={{
                                fill: !isPadEmpty ? 'black' : 'rgba(0, 0, 0, 0.26)',
                            }}
                        />
                    </Box>

                    <Box
                        onClick={onRedo}
                    >
                        <RedoIcon
                            sx={{
                                fill: hasUndoList ? 'black' : 'rgba(0, 0, 0, 0.26)',
                            }}
                        />
                    </Box>
                </Box>

                <Box
                    onClick={onClear}
                >
                    <CloseIcon
                        sx={{
                            fill: !isPadEmpty ? 'black' : 'rgba(0, 0, 0, 0.26)',
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

DrawSignature.propTypes = {
    signaturePad: PropTypes.object.isRequired,
    setSignaturePad: PropTypes.func.isRequired,
    isPadEmpty: PropTypes.bool.isRequired,
    setIsPadEmpty: PropTypes.func.isRequired,
    isMobileSize: PropTypes.bool,
};

DrawSignature.defaultProps = {
    isMobileSize: false,
};

export default DrawSignature;
