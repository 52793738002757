import {Box, Typography} from '@mui/joy';
import {Box as MuiBox, Typography as MuiTypography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useIsMobileSize} from '../../../../../../hooks/use-is-mobile-size';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {splitNumber} from '../../../../../../utils/number';

export const AccountBalanceTotal = ({amount, currency, isInHeader, isInSidebar, sx}) => {
    const {t} = useTranslation(LocalesConstants.Bank);

    const {integer, decimal} = splitNumber(Number(amount));

    return (
        <Box sx={{
            textWrap: 'nowrap',
            order: {mobile: 1, desktop: 0},
            ...sx,
        }}
        >
            <Typography
                level="h1"
                sx={{
                    display: 'inline',
                    textWrap: 'nowrap',
                    color: isInSidebar ? 'text.primary' : 'common.white',
                }}
            >
                {integer}
            </Typography>
            <Typography
                level="title-lg"
                color="common.white"
                sx={{
                    display: 'inline',
                    textWrap: 'nowrap',
                    color: isInSidebar ? 'text.primary' : 'common.white',
                    mr: 1,
                    fontWeight: 'smd',
                }}
            >
                ,{decimal} {currency}
            </Typography>
            {!isInHeader && !isInSidebar && (
                <Typography
                    level="title-sm"
                    color="common.white"
                    sx={{
                        display: 'inline',
                        textWrap: 'nowrap',
                        color: 'text.tertiary',
                    }}
                >
                    {t('availableBalance')}
                </Typography>
            )}
        </Box>
    );
};

AccountBalanceTotal.propTypes = {
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currency: PropTypes.string,
    sx: PropTypes.object,
    isInHeader: PropTypes.bool,
    isInSidebar: PropTypes.bool,
};

AccountBalanceTotal.defaultProps = {
    amount: 0,
    currency: '€',
    sx: {},
    isInHeader: false,
    isInSidebar: false,
};

export const AccountDetailsTotal = ({amount, currency, isDebit, isRejected}) => {
    const isMobileSize = useIsMobileSize();

    const {integer, decimal} = splitNumber(amount ?? 0);

    return (
        <MuiBox sx={{
            mb: isMobileSize ? 1 : 2,
            color: isRejected ? 'v2.light.error.dark' : !isDebit ? 'success_v2.light' : 'elephant.main',
        }}
        >
            <MuiTypography sx={{display: 'inline', fontSize: theme => theme.typography.pxToRem(60), fontWeight: 500}}>
                {amount !== 0 && (isDebit ? '-' : '+')}
                {integer}
            </MuiTypography>
            <MuiTypography sx={{display: 'inline', fontSize: theme => theme.typography.pxToRem(42), fontWeight: 500}}>
                ,{decimal} {currency}
            </MuiTypography>
        </MuiBox>
    );
};

AccountDetailsTotal.propTypes = {
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    currency: PropTypes.string,
    isDebit: PropTypes.bool.isRequired,
    isRejected: PropTypes.bool.isRequired,
};

AccountDetailsTotal.defaultProps = {
    currency: '€',
};

export const AccountTotalSmall = ({amount, currency, isDebit, isRejected}) => {
    const {integer, decimal} = splitNumber(amount);

    return (
        <MuiBox sx={{
            color: isRejected ? 'v2.light.error.dark' : !isDebit ? 'success_v2.light' : 'elephant.main',
        }}
        >
            <MuiTypography sx={{display: 'inline', fontSize: theme => theme.typography.pxToRem(24), fontWeight: 500}}>
                {isDebit && amount !== 0 && '-'}
                {integer}
            </MuiTypography>
            <MuiTypography sx={{display: 'inline', fontSize: theme => theme.typography.pxToRem(16), fontWeight: 500}}>
                ,{decimal} {currency}
            </MuiTypography>
        </MuiBox>
    );
};

AccountTotalSmall.propTypes = {
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    currency: PropTypes.string,
    isDebit: PropTypes.bool.isRequired,
    isRejected: PropTypes.bool.isRequired,
};

AccountTotalSmall.defaultProps = {
    currency: '€',
};
