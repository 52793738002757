import {combineReducers} from 'redux';
import {OpportunityActionTypes} from './opportunity.action-type';
import {createReducer} from '../../../app/utils/store/create-reducer';

export const opportunityReducer = combineReducers({
    clusters: createReducer({
        entities: {},
        ids: [],
        totalRecords: 0,
    }, OpportunityActionTypes.STORE_CLUSTER, true),
    opportunityList: createReducer(
        {
            items: [],
            count: 0,
        },
        OpportunityActionTypes.STORE_LIST,
        true,
    ),
    otherOpportunityList: createReducer(
        {
            items: [],
            count: 0,
        },
        OpportunityActionTypes.STORE_OTHER_LIST,
        true,
    ),
    myBidsList: createReducer([], OpportunityActionTypes.STORE_MY_BIDS_LIST, true),
    specialisations: createReducer([], OpportunityActionTypes.STORE_SPECIALISATIONS, true),
    search: createReducer([], OpportunityActionTypes.STORE_SEARCH, true),
});
