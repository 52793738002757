import {Box, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import EmailSent from '../../../../assets/svg/email-read.svg';
import {LocalesConstants} from '../../../../utils/locales-constants';

export const FundingRefusedSent = () => {
    const {t} = useTranslation(LocalesConstants.Training);

    return (
        <Box
            sx={{
                background: 'rgba(25, 118, 210, 0.04)',
                borderRadius: '16px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                pt: 4,
                pb: 5,
            }}
        >
            <Box sx={{
                background: 'rgba(25, 118, 210, 0.08)',
                width: '128px',
                height: '128px',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
            }}
            >
                <img src={EmailSent} alt="email sent" />
            </Box>
            <Box sx={{mt: 2}}>
                <Typography variant="h4">{t('fundingApproval.refusedSentHeader')}</Typography>
            </Box>
            <Box sx={{mt: 0.5}}>{t('fundingApproval.refusedSentText')}</Box>
        </Box>
    );
};
