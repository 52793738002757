export const SET_IS_LOADING_PERSONAL_EXPENSES = 'SET_IS_LOADING_PERSONAL_EXPENSES';
export const GET_PERSONAL_EXPENSES = 'GET_PERSONAL_EXPENSES';
export const STORE_PERSONAL_EXPENSES = 'STORE_PERSONAL_EXPENSES';
export const DELETE_PERSONAL_EXPENSE = 'DELETE_PERSONAL_EXPENSE';
export const DOWNLOAD_PERSONAL_EXPENSE = 'DOWNLOAD_PERSONAL_EXPENSE';
export const UPDATE_PERSONAL_EXPENSE = 'UPDATE_PERSONAL_EXPENSE';

export const setIsLoadingPersonalExpenses = isLoading => ({
    type: SET_IS_LOADING_PERSONAL_EXPENSES,
    payload: isLoading,
});

export const getPersonalExpenses = freelancerId => ({
    type: GET_PERSONAL_EXPENSES,
    payload: {
        freelancerId,
    },
});

export const deletePersonalExpense = (freelancerId, companyId, expenseId) => ({
    type: DELETE_PERSONAL_EXPENSE,
    payload: {
        freelancerId,
        companyId,
        expenseId,
    },
});

export const downloadPersonalExpense = (freelancerId, companyId, receiptId, isDownload = true) => ({
    type: DOWNLOAD_PERSONAL_EXPENSE,
    payload: {
        freelancerId,
        companyId,
        receiptId,
        isDownload,
    },
});

export const updatePersonalExpense = (freelancerId, companyId, expenseId, isReimbursed) => ({
    type: UPDATE_PERSONAL_EXPENSE,
    payload: {
        freelancerId,
        companyId,
        expenseId,
        isReimbursed,
    },
});

export const storePersonalExpenses = personalExpenses => ({
    type: STORE_PERSONAL_EXPENSES,
    payload: personalExpenses,
});
