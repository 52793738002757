import {all, call, put} from 'redux-saga/effects';
import {DOCUMENT_CONTEXTS} from '../../../consts/document-constants';
import {umbrellaCompanyDocumentsLoaderSaga} from '../../company/modules/documents/store/umbrella/umbrella-company.saga';
import {personalExpensesLoaderSaga} from '../../expenses/modules/personal/store/personal-expenses.sagas';
import {LoadingActions, LoadingTypes} from '../../loading';
import {getPortageUserRequest} from '../../user-list/api/user.api';
import {storeUser} from '../../user/store/user.actions';
import {UMBRELLA_USER_TABS} from '../../user/utils/umbrella/constants';

export const getPortageUserLoaderSaga = function* ({payload}) {
    const {userId} = payload.params;

    try {
        const user = yield call(getPortageUserRequest, userId);

        yield put(storeUser(user));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    } finally {
        // yield put(actions.setIsLoadingUser(false));
    }
};

export const umbrellaCompanyProfileLoaderSaga = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.COMPANY_PROFILE_VIEW, true));

    try {
        const {userId, tab} = payload.params;
        const sagas = [[getPortageUserLoaderSaga, {payload: {params: {userId}}}]];

        if (tab === UMBRELLA_USER_TABS.DOCUMENTS) {
            sagas.push([umbrellaCompanyDocumentsLoaderSaga, {
                payload: {
                    params: {
                        userId,
                    },
                    documentContext: DOCUMENT_CONTEXTS.DATABASE,
                },
            }]);
        } else if (tab === UMBRELLA_USER_TABS.PERSONAL_EXPENSES) {
            sagas.push([personalExpensesLoaderSaga, {
                payload: {
                    params: {
                        userId,
                    },
                },
            }]);
        } else if (tab === UMBRELLA_USER_TABS.MISSIONS) {
            // TODO: GET MISSIONS
            // eslint-disable-next-line no-console
            console.log('GET MISSIONS');
        }

        yield all(sagas.map(sagaConfig => call(sagaConfig[0], ...sagaConfig.slice(1))));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.COMPANY_PROFILE_VIEW, false));
    }
};
