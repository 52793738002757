import {
    faLocationDot,
    faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {
    Autocomplete,
    AutocompleteOption,
    Box,
    FormControl,
    ListItemContent,
    ListItemDecorator,
} from '@mui/joy';
import {
    Grid,
    Autocomplete as MuiAutocomplete,
    Box as MuiBox,
    TextField,
    Typography,
} from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash.throttle';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {FormErrorText} from './joy-ui/forms/FormErrorText';
import {FormFieldLabel} from './joy-ui/forms/FormFieldLabel';
import poweredByGoogle from '../assets/png/powered-by-google.png';
import {CONFIG} from '../config';
import {InputIcon} from '../features/auth/components/common/InputIcon';
import {fieldProps} from '../features/user/modules/create-user-form/components/helper';
import {LocalesConstants} from '../utils/locales-constants';

const loadScript = (src, position, id) => {
    if (!position) {
        return;
    }

    const script = document.createElement('script');

    script.setAttribute('async', '');
    script.setAttribute('id', id);

    script.src = src;

    position.appendChild(script);
};

const autocompleteService = {current: null};
const placesService = {current: null};
const sessionToken = {current: null};

export const CityAutocomplete = props => {
    const {
        control,
        label,
        name,
        initialValue,
        types,
        onPlaceDetailsChange,
        TextFieldProps,
        isV3,
        language,
        ...rest
    } = props;
    const [value, setValue] = useState(initialValue);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [placeId, setPlaceId] = useState(null);
    const loaded = useRef(false);
    const {t} = useTranslation(LocalesConstants.Expenses);

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${CONFIG.GOOGLE_MAPS_API_KEY}&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const getPredictions = useMemo(() => throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
    }, 200), []);

    const getPlaceDetails = useCallback((placeId => {
        if (!placesService.current || !placeId || !onPlaceDetailsChange) {
            return undefined;
        }

        placesService.current.getDetails({
            placeId,
            fields: ['address_components', 'formatted_address'],
        }, place => {
            if (!place) {
                return {};
            }

            const {address_components: addressComponents} = place;

            if (!addressComponents) {
                return {};
            }

            let city = '';
            let zipCode = '';
            let streetNumber = '';
            let country = '';
            let region = '';
            let streetName = '';
            addressComponents.forEach(component => {
                if (component.types.includes('locality')) {
                    city = component.long_name;
                } else if (component.types.includes('postal_code')) {
                    zipCode = component.long_name;
                } else if (component.types.includes('street_number')) {
                    streetNumber = component.long_name;
                } else if (component.types.includes('country')) {
                    country = component.long_name;
                } else if (component.types.includes('administrative_area_level_1')) {
                    region = component.long_name;
                } else if (component.types.includes('route')) {
                    streetName = component.long_name;
                }
            });

            country = 'France'; // HPD-5669

            onPlaceDetailsChange({
                city,
                zipCode,
                streetNumber,
                streetName,
                country,
                region,
            });
        });
    }), [onPlaceDetailsChange]);

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
            placesService.current = new window.google.maps.places.PlacesService(document.createElement('span'));
            sessionToken.current = new window.google.maps.places.AutocompleteSessionToken();
        }

        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);

            return undefined;
        }

        getPredictions({
            input: inputValue,
            types,
            componentRestrictions: props.componentRestrictions,
            sessionToken: sessionToken.current,
            language,
        }, results => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [
        value,
        inputValue,
        getPredictions,
        types,
        props.componentRestrictions,
        language,
    ]);

    useEffect(() => {
        getPlaceDetails(placeId);
    }, [placeId, getPlaceDetails]);

    if (isV3) {
        return (
            <Controller
                id={name}
                name={name}
                control={control}
                render={({field}) => (
                    <FormControl error={rest.error}>
                        <FormFieldLabel label={label} />
                        <Autocomplete
                            {...rest}
                            {...field}
                            filterOptions={x => x}
                            options={options}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            value={value}
                            startDecorator={(
                                <InputIcon isError={rest.error} icon={faMagnifyingGlass} />
                            )}
                            onChange={(_, newValue) => {
                                setOptions(newValue ? [newValue, ...options] : options);
                                setValue(newValue);
                                setPlaceId(newValue?.place_id ?? null);

                                if (types === '(cities)') {
                                    const term = newValue ? newValue.terms[0] : null;
                                    field.onChange(term ? term.value : '');
                                } else {
                                    field.onChange(newValue?.structured_formatting?.main_text ?? '');
                                }
                            }}
                            onInputChange={(_, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            getOptionLabel={option => {
                                if (typeof option === 'string') {
                                    return option;
                                }
                                return option.structured_formatting?.main_text || option.description;
                            }}
                            renderOption={(props, option) => {
                                if (!option.structured_formatting) {
                                    return;
                                }

                                const matches = option.structured_formatting.main_text_matched_substrings ?? [];
                                const parts = parse(
                                    option.structured_formatting.main_text,
                                    matches.map(match => [match.offset, match.offset + match.length]),
                                );

                                return (
                                    <AutocompleteOption {...props} key={option.place_id}>
                                        <ListItemDecorator sx={{mr: -3}}>
                                            <Box fontSize="lg" color="text.secondary">
                                                <FontAwesomeIcon icon={faLocationDot} />
                                            </Box>
                                        </ListItemDecorator>
                                        <ListItemContent sx={{fontSize: 'sm'}}>
                                            {option.label}
                                            <Typography
                                                level="body-lg"
                                                color="text.secondary"
                                            >
                                                {parts.map((part, index) => (
                                                    <Box
                                                        component="span"
                                                        key={index}
                                                        sx={{
                                                            fontSize: 'lg',
                                                            lineHeight: 'xs',
                                                            fontWeight: part.highlight ? 'xl' : 'md',
                                                        }}
                                                    >
                                                        {part.text}
                                                    </Box>
                                                ))}
                                            </Typography>
                                            <Typography
                                                level="body-sm"
                                                color="text.secondary"
                                                lineHeight="xs"
                                            >
                                                {types === '(cities)'
                                                    ? option.terms[1].value
                                                    : option.structured_formatting.secondary_text}
                                            </Typography>
                                        </ListItemContent>
                                    </AutocompleteOption>
                                );
                            }}
                            {...fieldProps}
                        />
                        <FormErrorText errorMessage={rest.helperText} />
                    </FormControl>
                )}
            />
        );
    }

    return (
        <Controller
            control={control}
            name={name}
            render={({field}) => (
                <MuiAutocomplete
                    {...field}
                    {...rest}
                    filterOptions={x => x}
                    options={options}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={value}
                    onChange={(_, newValue) => {
                        setOptions(newValue ? [newValue, ...options] : options);
                        setValue(newValue);
                        setPlaceId(newValue?.place_id ?? null);

                        if (types === '(cities)') {
                            const term = newValue ? newValue.terms[0] : null;
                            field.onChange(term ? term.value : '');
                        } else {
                            field.onChange(newValue?.structured_formatting?.main_text ?? '');
                        }
                    }}
                    onInputChange={(_, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    renderInput={params => (
                        <>
                            <TextField
                                {...params}
                                label={label}
                                variant={props.variant}
                                fullWidth
                                {...TextFieldProps}
                            />
                            <MuiBox sx={{display: 'flex', justifyContent: 'flex-end', mt: 0.5}}>
                                <img src={poweredByGoogle} alt={t('poweredByGoogle')} />
                            </MuiBox>
                        </>
                    )}
                    getOptionLabel={option => {
                        if (typeof option === 'string') {
                            return option;
                        }

                        return option.structured_formatting?.main_text || option.description;
                    }}
                    renderOption={(props, option) => {
                        if (!option.structured_formatting) {
                            return;
                        }

                        const matches = option.structured_formatting.main_text_matched_substrings ?? [];
                        const parts = parse(
                            option.structured_formatting.main_text,
                            matches.map(match => [match.offset, match.offset + match.length]),
                        );

                        return (
                            <li {...props} key={option.place_id}>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <MuiBox
                                            component={LocationOnIcon}
                                            sx={{color: 'text.secondary', mr: 2}}
                                        />
                                    </Grid>

                                    <Grid item xs>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    fontWeight: part.highlight ? 700 : 400,
                                                }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}

                                        <Typography variant="body2" color="text.secondary">
                                            {types === '(cities)'
                                                ? option.terms[1].value
                                                : option.structured_formatting.secondary_text}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </li>
                        );
                    }}
                />
            )}
        />
    );
};

CityAutocomplete.propTypes = {
    control: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    types: PropTypes.arrayOf(PropTypes.string),
    onPlaceDetailsChange: PropTypes.func,
    TextFieldProps: PropTypes.object,
    variant: PropTypes.string,
    componentRestrictions: PropTypes.object,
    isV3: PropTypes.bool, // TODO: make true by default and delete the code, related to false value,
    language: PropTypes.string,
};

CityAutocomplete.defaultProps = {
    initialValue: null,
    types: ['(cities)'],
    onPlaceDetailsChange: null,
    TextFieldProps: {},
    variant: 'filled',
    componentRestrictions: {country: ['FR', 'GP', 'MQ', 'RE', 'YT']},
    isV3: false,
    language: 'fr',
};
