import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import * as actions from './coaching-information.actions';
import {Toast} from '../../../../lib/toast';
import {
    coachInformationGetRequest,
    coachInformationSubmitRequest,
    saveUmbrellaUserCoachInformationRequest,
} from '../../../user-list/api/user.api';
import {setIsUpdatingUser, storeUser} from '../user.actions';
import {selectUser} from '../user.selectors';

export const submitCoachingInformationSaga = function* (action) {
    yield put(actions.setIsLoadingSubmitCoachingInformationForm(true));

    try {
        const {payload} = action;

        const coachingInfo = yield call(coachInformationSubmitRequest, payload.form, payload.userId);

        yield put(actions.setCoachingInformationForm(coachingInfo));

        Toast.success('genericSuccessSave');
    } catch (error) {
        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingSubmitCoachingInformationForm(false));
    }
};

export const submitUmbrellaUserCoachingInformationSaga = function* ({payload}) {
    try {
        yield put(setIsUpdatingUser(true));
        const {userId, jobType, coachId, callback} = payload;

        const coachingInfo = yield call(saveUmbrellaUserCoachInformationRequest, userId, jobType, coachId);

        const oldUser = yield select(selectUser);

        yield put(storeUser({
            ...oldUser,
            profile: {
                ...(oldUser?.profile ?? {}),
                coachingInfo: {
                    ...(oldUser?.profile?.coachingInfo ?? {coachId}),
                    professions: coachingInfo.professions,
                },
            },
        }));

        callback?.();
    } catch (error) {
        // TODO:LOW Better error handling.
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(setIsUpdatingUser(false));
    }
};

export const getCoachingInformationSaga = function* (action) {
    yield put(actions.setIsLoadingGetCoachingInformationForm(true));

    try {
        const {payload} = action;

        const coachingInfo = yield call(coachInformationGetRequest, payload.userId);

        yield put(actions.setCoachingInformationForm(coachingInfo));
    } catch (error) {
        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingGetCoachingInformationForm(false));
    }
};

export const watchCoachingInformationSagas = function* () {
    yield all([
        takeLatest(actions.SUBMIT_COACHING_INFORMATION_FORM, submitCoachingInformationSaga),
        takeLatest(actions.GET_COACHING_INFORMATION_FORM, getCoachingInformationSaga),
        takeLatest(actions.SUBMIT_UMBRELLA_USER_COACHING_INFORMATION_FORM, submitUmbrellaUserCoachingInformationSaga),
    ]);
};
