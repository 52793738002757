import {combineReducers} from 'redux';
import {InvoiceActionType} from './invoice.action-type';
import {createReducer} from '../../../app/utils/store/create-reducer';
import {LOCATION_CHANGED} from '../../../lib/router/connected-router-saga';

export const invoiceReducer = combineReducers({
    invoiceList: createReducer(
        {
            items: [],
            count: 0,
        },
        InvoiceActionType.STORE_INVOICE,
        true,
    ),
    invoiceStats: createReducer(
        {
            'totalInvoiced': '0',
            'totalReceived': '0',
            'totalPending': '0',
            'totalOverdue': '0',
        },
        InvoiceActionType.STORE_INVOICE_STATS,
        false,
    ),
    clients: createReducer([], InvoiceActionType.STORE_CLIENTS, false),
    clientSuggestions: createReducer([], InvoiceActionType.STORE_CLIENT_SUGGESTIONS, true),
    invoiceDraft: createReducer(null, InvoiceActionType.STORE_INVOICE_DRAFT, false),
    invoiceDraftError: createReducer(null, InvoiceActionType.STORE_INVOICE_DRAFT_ERROR, false),
    clientEditDraft: createReducer(null, InvoiceActionType.STORE_CLIENT_EDIT_DRAFT, true),
    services: createReducer([], InvoiceActionType.STORE_SERVICES, false),
    invoiceSidebar: createReducer(null, InvoiceActionType.SET_INVOICE_SIDEBAR, true),
    invoiceSidebarData: createReducer(null, InvoiceActionType.SET_INVOICE_SIDEBAR_DATA, true),
    invoicePreview: createReducer(null, InvoiceActionType.STORE_INVOICE_PREVIEW, true),
    invoicePreviewUrl: createReducer(null, InvoiceActionType.STORE_INVOICE_PREVIEW_FILE_URL, true),
    lastInternalInvoice: createReducer(null, InvoiceActionType.STORE_LAST_INTERNAL_INVOICE, false),
    lastExternalInvoice: createReducer(null, InvoiceActionType.STORE_LAST_EXTERNAL_INVOICES, false),
    clientToEdit: createReducer(null, InvoiceActionType.STORE_CLIENT_ID_TO_EDIT, true),
    clientFileLoader: (state = [], action) => {
        if (action.type === InvoiceActionType.UPLOAD_CLIENT_FILE_LOADER) {
            const eventId = action.payload.eventId;

            const hasElement = state.findIndex(element => element.eventId === eventId);

            if (hasElement !== -1) {
                const newState = [...state];
                newState.splice(hasElement, 1, action.payload);
                return newState;
            }

            return [
                ...state,
                action.payload,
            ];
        } else if (action.type === LOCATION_CHANGED) {
            return [];
        }

        return state;
    },
    serviceEditDraft: createReducer(null, InvoiceActionType.STORE_SERVICE_EDIT_DRAFT, true),
    invoiceFinalizationResult: createReducer(null, InvoiceActionType.STORE_FINALIZATION_RESULT, true),
    invoiceHeaderData: createReducer({}, InvoiceActionType.STORE_INVOICE_HEADER_DATA, true),
    invoiceResult: createReducer(null, InvoiceActionType.STORE_INVOICE_RESULT, true),
    nextCreditNoteDocNumber: createReducer(null, InvoiceActionType.STORE_NEXT_CREDIT_NOTE_DOC_NUMBER, true),
    invoiceRemindData: createReducer(null, InvoiceActionType.STORE_INVOICE_REMIND_DATA, true),
    invoiceUrl: createReducer(null, InvoiceActionType.STORE_INVOICE_URL, true),
    showCreateForm: createReducer(false, InvoiceActionType.SET_SHOW_CREATE_FORM, true),
    invoiceImportInformation: createReducer(false, InvoiceActionType.IMPORT_INVOICE_RESULT, true),
    showAriaActivationPage: createReducer(false, InvoiceActionType.SHOW_ARIA_ACTIVATION_PAGE, true),
    showAriaParameters: createReducer(false, InvoiceActionType.SHOW_ARIA_PARAMETERS, true),
    ariaStatus: createReducer(null, InvoiceActionType.STORE_ARIA_STATUS, true),
});
