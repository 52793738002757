import {faHouseLaptop, faMoneyCheck, faRoadCircleCheck, faWallet} from '@fortawesome/free-solid-svg-icons';
import {Stack} from '@mui/joy';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {RoadmapSubSection} from './RoadmapSubSection';
import {FigureSheet} from '../../../../../../components/joy-ui/figure-sheet/FigureSheet';
import Section from '../../../../../../components/joy-ui/sections/Section';
import {Swiper, SwiperSlide} from '../../../../../../components/swiper/Swiper';
import {useIsMobileSize} from '../../../../../../hooks/use-is-mobile-size';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {useSimulationData} from '../../utils/useSimulationData';

const Figures = () => {
    const {t} = useTranslation(LocalesConstants.RevenueSimulator);
    const simulation = useSimulationData();
    const months = simulation.simulationInput.professionalInfo.monthsToRestoreTravelSubvention || 0;
    const withHiway = simulation.simulationOutput.compensationRoadmap.graph.withHiway;
    const withoutHiway = simulation.simulationOutput.compensationRoadmap.graph.withoutHiway;

    const isMobileSize = useIsMobileSize();

    const figures = [
        <FigureSheet
            key="cdi"
            amount={withoutHiway[0]}
            helperText={t('roadmap.perMonth')}
            title={t('roadmap.CDI')}
            icon={faWallet}
        />,
        ...(months
            ? [
                <FigureSheet
                    key="portage"
                    amount={withHiway[1]}
                    title={t('roadmap.portageSalarial')}
                    icon={faMoneyCheck}
                    helperText={t('roadmap.perMonth')}
                />,
            ]
            : []),
        <FigureSheet
            key="freelancing"
            amount={withHiway[months + 1]}
            title={t('roadmap.freelancing')}
            icon={faHouseLaptop}
            helperText={t('roadmap.perMonth')}
        />,
    ];

    return isMobileSize ? (
        <Swiper>
            {figures.map(figure => (
                <SwiperSlide key={figure.key}>{figure}</SwiperSlide>
            ))}
        </Swiper>
    ) : (
        <Stack direction="row" spacing={2}>
            {figures}
        </Stack>
    );
};

export const RoadmapSection = () => {
    const {t} = useTranslation(LocalesConstants.RevenueSimulator);
    return (
        <Section icon={faRoadCircleCheck} title={t('roadmap.sectionTitle')}>
            <RoadmapSubSection />
            <Figures />
        </Section>
    );
};
