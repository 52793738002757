import {Box, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {DocumentSigningV3} from '../../../../document/modules/signing/components/v3/DocumentSigningV3';
import {useSigningWrapper} from '../../../../document/modules/signing/hooks/use-signing-wrapper';

export const ClientSigningWrapper = ({
    MainActionComponent,
    DocumentsLinkComponent,
    signingTitle,
    onSignFn,
    onNextFn,
}) => {
    const {
        t,
        dt,
        isMobileSize,
        documents,
        currentDocumentId,
    } = useSigningWrapper();

    return (
        <Box display="grid" gridTemplateRows="min-content 1fr">
            <Typography
                level="h1"
            >
                {signingTitle ?? t('signing.title')}
            </Typography>

            <Box
                display="grid"
                gridTemplateColumns={!isMobileSize ? '1fr 322px' : '1fr 1fr'}
                gap={2}
                sx={isMobileSize ? {mx: 2} : {}}
            >
                <Typography
                    level="h4"
                    sx={{fontWeight: 'md', mb: 2}}
                >
                    {!!DocumentsLinkComponent && DocumentsLinkComponent}
                    {!isMobileSize && documents[currentDocumentId] && (
                        <Box component="span" sx={{lineHeight: '30.75px'}}>
                            {dt(`documentsDatabaseNames.${documents[currentDocumentId].type}`)}
                        </Box>
                    )}
                </Typography>
            </Box>

            <Box sx={{
                width: 'calc(338px + 24 * 2)',
            }}
            >
                <DocumentSigningV3
                    isPublic
                    MainActionComponent={MainActionComponent}
                    onSignFn={onSignFn}
                    onNextFn={onNextFn}
                    sidebarSx={{
                        width: 'calc(290px + 48px)',
                    }}
                    signatureContainerSx={{
                        width: 'calc(290px + 48px)',
                    }}
                />
            </Box>
        </Box>
    );
};

ClientSigningWrapper.propTypes = {
    MainActionComponent: PropTypes.object,
    DocumentsLinkComponent: PropTypes.object,
    signingTitle: PropTypes.string,
    onSignFn: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    onNextFn: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};
ClientSigningWrapper.defaultProps = {
    MainActionComponent: null,
    DocumentsLinkComponent: null,
    onSignFn: null,
    onNextFn: null,
    signingTitle: '',
};
