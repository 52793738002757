import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {LoadingButton} from '@mui/lab';
import {Box, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import LockImage from '../../../../assets/svg/bank/lock.svg';
import SvanImage from '../../../../assets/svg/svan.svg';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {LANDING_PAGE_BORDER_RADIUS} from '../../utils/constants';

const SwanSection = ({onCreationStartClick}) => {
    const {t} = useTranslation(LocalesConstants.Bank);
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.BANK_CREATION),
    );

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: isMobileSize ? 'column' : 'row',
            mt: 3,
            mb: 3,
        }}
        >
            <Box sx={{
                borderRadius: LANDING_PAGE_BORDER_RADIUS,
                background: '#fff',
                width: isMobileSize ? '100%' : '50%',
                pt: 4,
                pl: isMobileSize ? 3 : 6.5,
                pb: isMobileSize ? 3 : 3.5,
                mb: isMobileSize ? 2 : 0,
            }}
            >
                <Box sx={{
                    width: '192px',
                    height: '192px',
                    background: '#ECEFF1',
                    borderRadius: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mr: isMobileSize ? 0 : 1.5,
                    mt: isMobileSize ? 0 : 3,
                }}
                >
                    <img
                        style={{
                            width: '141px',
                        }}
                        src={SvanImage}
                        alt="svan"
                    />
                </Box>
                <Box sx={{mt: 4}}>
                    <Typography variant="h4">
                        {t('landing.partnerInFrance')}
                    </Typography>
                </Box>

                <Box sx={{mt: 4}}>
                    <Typography sx={{mb: 2}}>
                        {t('landing.swanPartnerBAAS')}
                    </Typography>
                    <Typography>
                        {t('landing.provideFrenchIBAN')}
                    </Typography>
                </Box>

                <Box sx={{mt: 8}}>
                    <LoadingButton
                        loading={isLoading}
                        onClick={onCreationStartClick}
                        color="primary"
                        endIcon={<ArrowForwardIcon />}
                    >
                        {t('landing.activateService')}
                    </LoadingButton>
                </Box>
            </Box>

            <Box
                sx={{
                    borderRadius: LANDING_PAGE_BORDER_RADIUS,
                    background: 'radial-gradient(43.23% 43.23% at 26.52% 24.92%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%), #173244',
                    width: isMobileSize ? '100%' : '50%',
                    ml: isMobileSize ? 0 : 1.5,
                    pt: isMobileSize ? 4 : 4.5,
                    pl: isMobileSize ? 3 : 7,
                    pb: isMobileSize ? 3 : 5,
                }}
            >
                <Box sx={{mb: 5}}>
                    <img src={LockImage} alt="lock" />
                </Box>
                <Box sx={{mb: 4}}>
                    <Typography variant="h4" sx={{color: 'white'}}>
                        {t('landing.moneyProtected')}
                    </Typography>
                </Box>
                <Box sx={{color: 'white'}}>{t('landing.protectedCapital')}</Box>

                <Box sx={{mt: 14}}>
                    <LoadingButton
                        loading={isLoading}
                        onClick={onCreationStartClick}
                        sx={{color: 'white'}}
                        endIcon={<ArrowForwardIcon />}
                    >
                        {t('landing.moneyProtectedButton')}
                    </LoadingButton>
                </Box>
            </Box>
        </Box>
    );
};

SwanSection.propTypes = {
    onCreationStartClick: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default SwanSection;
