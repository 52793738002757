import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import RateReviewIcon from '@mui/icons-material/RateReview';
import ReportIcon from '@mui/icons-material/Report';
import SchoolIcon from '@mui/icons-material/School';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {useCompanyStatusStyles} from './styles';
import {COMPANY_STATUSES} from '../../../features/company/modules/setup-company/utils/constants';
import {LocalesConstants} from '../../../utils/locales-constants';

const CompanyStatus = ({status, hasTrainingIcon}) => {
    const styles = useCompanyStatusStyles();

    const {t} = useTranslation(LocalesConstants.Companies);

    const {icon, label} = useMemo(() => {
        switch (status) {
            case COMPANY_STATUSES.ACTIVE:
                return {
                    icon: <CheckCircleIcon className={clsx(styles.activeIcon, styles.icon)} />,
                    label: t('companiesStatuses.ACTIVE'),
                };

            case COMPANY_STATUSES.PENDING_DATA_INPUT:
                return {
                    icon: <ReportIcon className={clsx(styles.pendingDataInputIcon, styles.icon)} />,
                    label: t('companiesStatuses.PENDING_DATA_INPUT'),
                };

            case COMPANY_STATUSES.READY_TO_REGISTER:
                return {
                    icon: <AssignmentTurnedInIcon className={clsx(styles.pendingCompanyIdIcon, styles.icon)} />,
                    label: t('companiesStatuses.READY_TO_REGISTER'),
                };

            case COMPANY_STATUSES.PENDING_ENABLE_SETUP:
                return {
                    icon: <AssignmentLateIcon className={clsx(styles.pendingCompanyIdIcon, styles.icon)} />,
                    label: t('companiesStatuses.PENDING_ENABLE_SETUP'),
                };

            case COMPANY_STATUSES.PENDING_EDITS:
                return {
                    // TODO:LOW see if we need another icon for this state
                    icon: <ReportIcon className={clsx(styles.pendingDataInputIcon, styles.icon)} />,
                    label: t('companiesStatuses.PENDING_EDITS'),
                };

            case COMPANY_STATUSES.PENDING_COMPANY_ID:
                return {
                    icon: <ReportIcon className={clsx(styles.pendingCompanyIdIcon, styles.icon)} />,
                    label: t('companiesStatuses.PENDING_COMPANY_ID'),
                };

            case COMPANY_STATUSES.PENDING_INIT_SIGS:
                return {
                    icon: <ErrorIcon className={clsx(styles.pendingInitialSignaturesIcon, styles.icon)} />,
                    label: t('companiesStatuses.PENDING_INIT_SIGS'),
                };

            case COMPANY_STATUSES.PENDING_FINAL_SIGS:
                return {
                    icon: <ErrorIcon className={clsx(styles.pendingFinalSignaturesIcon, styles.icon)} />,
                    label: t('companiesStatuses.PENDING_FINAL_SIGS'),
                };

            case COMPANY_STATUSES.USER_DEACTIVATED:
                return {
                    icon: <BlockIcon className={clsx(styles.userDeactivatedIcon, styles.icon)} />,
                    label: t('companiesStatuses.USER_DEACTIVATED'),
                };

            case COMPANY_STATUSES.READY_FOR_REVIEW:
                return {
                    icon: <RateReviewIcon className={clsx(styles.readyForReview, styles.icon)} />,
                    label: t('companiesStatuses.READY_FOR_REVIEW'),
                };

            case COMPANY_STATUSES.REGISTRATION_NOT_PREPARED:
                return {
                    icon: <RateReviewIcon className={clsx(styles.readyForReview, styles.icon)} />,
                    label: t('companiesStatuses.REGISTRATION_NOT_PREPARED'),
                };

            case COMPANY_STATUSES.REGISTRATION_COMPLETED:
                return {
                    icon: <RateReviewIcon className={clsx(styles.readyForReview, styles.icon)} />,
                    label: t('companiesStatuses.REGISTRATION_COMPLETED'),
                };

            default:
                return {icon: null, label: null};
        }
    }, [status, styles, t]);

    if (!icon || !label) {
        return null;
    }

    return (
        <div className={styles.root}>
            <div className={styles.iconContainer}>
                {icon}

                {hasTrainingIcon && <SchoolIcon className={styles.trainingIcon} />}
            </div>

            <div className={styles.label}>
                {label}
            </div>
        </div>
    );
};

CompanyStatus.propTypes = {
    status: PropTypes.oneOf(Object.values(COMPANY_STATUSES)).isRequired,
    hasTrainingIcon: PropTypes.bool,
};

CompanyStatus.defaultProps = {
    hasTrainingIcon: false,
};

export default CompanyStatus;
