import {Box, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import BrochureCard from './BrochureCard';
import InsuranceTabs from './InsuranceTabs';
import RcTab from './rc-tab/RcTab';
import {LocalesConstants} from '../../../utils/locales-constants';
import {COMPANY_TAX_SYSTEMS} from '../../company/utils/constants';
import {INSURANCE_TABS} from '../utils/constants';

const InsuranceBottomSection = ({taxSystem, handleOpenSubscribeModal, tab, handleTabChange}) => {
    const {t} = useTranslation(LocalesConstants.Insurance);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Box sx={{
            'display': 'flex',
            'padding': '32px 40px 40px 40px',
            'flexDirection': 'column',
            'alignItems': 'center',
            'alignSelf': 'stretch',
            'gap': 3,
            'borderRadius': 4,
            'backgroundColor': '#FFFFFF',
            'boxShadow': '0px 2px 1px -1px rgba(11, 35, 51, 0.05), 0px 1px 1px 0px rgba(11, 35, 51, 0.05), 0px 1px 3px 0px rgba(11, 35, 51, 0.07)',
            '@media (max-width: 959.95px)': {
                padding: 3,
                gap: 3,
                borderRadius: 6,
            },
        }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1.5,
                alignSelf: 'stretch',
            }}
            >
                <Typography sx={{
                    textAlign: 'center',
                    fontSize: theme => theme.typography.pxToRem(34),
                    fontWeight: 700,
                }}
                >
                    {t('bottomSection.title')}
                </Typography>
                {taxSystem === COMPANY_TAX_SYSTEMS.IR && !isMobileSize && (
                    <Typography sx={{
                        textAlign: 'center',
                        fontSize: theme => theme.typography.pxToRem(21),
                        fontWeight: 700,
                    }}
                    >
                        {t('bottomSection.subtitle')}
                    </Typography>
                )}
                {taxSystem === COMPANY_TAX_SYSTEMS.IR
                    ? (
                        <InsuranceTabs
                            handleOpenSubscribeModal={handleOpenSubscribeModal}
                            tab={tab}
                            handleTabChange={handleTabChange}
                        />
                    )
                    : <RcTab handleOpenSubscribeModal={handleOpenSubscribeModal} />}
            </Box>
            {(!isMobileSize && tab === INSURANCE_TABS.RC_TAB) && <BrochureCard />}
        </Box>
    );
};

InsuranceBottomSection.propTypes = {
    taxSystem: PropTypes.string.isRequired,
    handleOpenSubscribeModal: PropTypes.func.isRequired,
    tab: PropTypes.string.isRequired,
    handleTabChange: PropTypes.func.isRequired,
};

export default InsuranceBottomSection;
