import {coreApiInstance} from '../../../lib/api-providers/core-api-instance';
import {hiwayApiInstance} from '../../../lib/hiway-api-instance';

const getDocument = ({documentId}) => {
    return coreApiInstance({
        method: 'GET',
        url: `/api/v2/documents/${documentId}`,
    }).then(result => result.data);
};

const getSignedUrlForDocumentRequest = ({freelancerId, companyId, documentId, isDownloading = false}) => {
    return hiwayApiInstance({
        method: 'GET',
        params: {
            content_disposition: isDownloading ? 'attachment' : 'inline',
            preview: isDownloading ? undefined : true,
        },
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/documents/${documentId}/sign-url-get-document`,
    }).then(result => result.data);
};

const getSignedUrlForDocumentRequestV2 = ({documentId, isDownload = false}) => {
    return coreApiInstance({
        method: 'GET',
        params: {
            contentDisposition: isDownload ? 'attachment' : 'inline',
            preview: isDownload ? undefined : true,
        },
        url: `/api/v2/documents/${documentId}/sign-url-get-document`,
    }).then(result => result.data);
};

const getDepositDocumentsRequest = ({freelancerId, companyId}) => {
    return hiwayApiInstance({
        method: 'GET',
        params: {
            preview: undefined,
        },
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/documents?doc_context=deposit`,
    });
};

const getKbisDocumentsRequest = ({freelancerId, companyId}) => {
    return hiwayApiInstance({
        method: 'GET',
        params: {
            preview: undefined,
        },
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/documents?doc_context=company_registraion`,
    });
};

export const generateContractDocumentRequest = userId => {
    return hiwayApiInstance({
        method: 'PUT',
        url: `/v1/users/${userId}/generate-contract`,
    }).then(result => result.data);
};

const getClusterDocument = ({freelancerId, documentId, isDownloading = false}) => {
    return hiwayApiInstance({
        method: 'GET',
        params: {
            content_disposition: isDownloading ? 'attachment' : 'inline',
            preview: isDownloading ? undefined : true,
        },
        url: `/v1/freelancers/${freelancerId}/other-freelancers-opportunities/documents/${documentId}`,
    }).then(result => result.data);
};

const deleteDocument = ({documentId}) => {
    return coreApiInstance({
        method: 'DELETE',
        url: `/api/v2/documents/${documentId}`,
    }).then(result => result.data);
};

export const DocumentApi = {
    getDocument,
    getSignedUrlForDocumentRequest,
    getSignedUrlForDocumentRequestV2,
    getDepositDocumentsRequest,
    getKbisDocumentsRequest,
    generateContractDocumentRequest,
    getClusterDocument,
    deleteDocument,
};
