import Box from '@mui/material/Box';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {PageHeader} from '../../components/page-header/PageHeader';
import UserCompanies from '../../components/ui-kit/UserCompanies';
import {COMPANY_CARD_BUTTONS} from '../../features/company/utils/constants';
import {LoggedInUserSelectors} from '../../features/user/modules/logged-in-user';
import {LocalesConstants} from '../../utils/locales-constants';

export const MyCompaniesScreen = () => {
    const {t} = useTranslation(LocalesConstants.Companies);

    const user = useSelector(LoggedInUserSelectors.selectLoggedInUser);

    return (
        <Box sx={{px: {xs: 2, md: 1}}}>
            <Box component="div" sx={{mt: {lg: 0, xs: 3}}}>
                <PageHeader title={t('companiesTitle')}>{t('companiesTitle')}</PageHeader>
            </Box>

            <UserCompanies
                user={user}
                visibleButtons={[COMPANY_CARD_BUTTONS.VIEW, COMPANY_CARD_BUTTONS.DASHBOARD]}
                lg={6}
                md={6}
                hasCardElevation
            />
        </Box>
    );
};
