import {Box, Typography} from '@mui/material';
import {Auth} from 'aws-amplify';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import QRCode from 'react-qr-code';
import {useDispatch, useSelector} from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import {CONFIG} from '../../../config';
import {RoutePaths} from '../../../lib/router/route-paths';
import {theme} from '../../../lib/theme';
import {LocalesConstants} from '../../../utils/locales-constants';
import {LoggedInUserSelectors} from '../../user/modules/logged-in-user';
import {SignatureActions} from '../store/signature.action';

const SignatureQrCode = () => {
    const {t} = useTranslation(LocalesConstants.Signature);
    const dispatch = useDispatch();
    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);

    const [properties, setProps] = useState({});

    const {accessToken, baseUrl, eventId} = properties;

    useEffect(() => {
        Auth.currentSession().then(response => {
            const eventId = uuidv4();
            setProps({
                accessToken: response.getIdToken().getJwtToken(),
                baseUrl: CONFIG.WEB_BASE_URL.substring(0, CONFIG.WEB_BASE_URL.length - 1),
                eventId: eventId,
            });

            dispatch(SignatureActions.putEventId(eventId));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={{
            display: 'flex',
            borderRadius: '16px',
            backgroundColor: 'gray_v2.50',
            p: 1,
            mb: 3,
            pl: 2,
        }}
        >
            <Box>
                <QRCode
                    size={200}
                    style={{height: 'auto', maxWidth: '100%', width: '100%'}}
                    value={`${baseUrl}${RoutePaths.PUBLIC_FILE_UPLOAD}?eventId=${eventId}&token=${accessToken}&userId=${loggedInUser?.id}`}
                    viewBox="0 0 95 95"
                />
            </Box>
            <Box sx={{
                ml: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
            >
                <Typography variant="h5">
                    {t('uploadSignatureModal.qrHeader')}
                </Typography>
                <Typography
                    sx={{
                        fontSize: theme.typography.pxToRem(14),
                    }}
                >
                    {t('uploadSignatureModal.qrDescription')}
                </Typography>
            </Box>
        </Box>
    );
};

export default SignatureQrCode;
