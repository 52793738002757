import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachmentIcon from '@mui/icons-material/Attachment';
import {Box, Chip, CircularProgress, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import {importLocaleBundle} from '../../lib/i18next';
import {LocalesConstants} from '../../utils/locales-constants';
import {ButtonAction} from '../buttons/ButtonAction';

importLocaleBundle(LocalesConstants.Dropzone);

const style = {
    flex: 1,
    minWidth: '293px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '12px',
    backgroundColor: '#fff',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

export const DropZone = ({
    fileTypes,
    maxFiles,
    maxSize,
    onChange,
    isFileDeleteAvailable,
    isUploadInProgress,
    isInfoTextVisible,
    files,
    sx,
    error,
    textTop,
    textBottom,
    isDisabled,
}) => {
    const {t} = useTranslation(LocalesConstants.Dropzone);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        maxFiles: maxFiles,
        accept: fileTypes,
        maxSize: maxSize,
        disabled: isDisabled,
    });

    const onFileRemove = index => {
        acceptedFiles.splice(index, 1);
        onChange([...acceptedFiles]);
    };

    useEffect(() => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            onChange(acceptedFiles);
        }
    }, [acceptedFiles, onChange]);

    return (
        <Box sx={sx}>
            {!isMobileSize && (
                <Box
                    {...getRootProps({style})}
                    sx={{pt: 2, pb: 2, border: error ? '1px dashed #DB100D' : '1px dashed #05A7D0'}}
                >
                    <input {...getInputProps()} disabled={isDisabled} />

                    <ButtonAction
                        disabled={isDisabled}
                        sx={{py: 0.5, borderRadius: '6px'}}
                        startIcon={<AttachFileIcon />}
                        size="medium"
                    >
                        {t('uploadZoneButton')}
                    </ButtonAction>

                    <Typography color="text.disabled" sx={{mt: 1}}>{textTop || t('uploadZoneText')}</Typography>

                    <Typography color="text.disabled" variant="body2">{textBottom || t('uploadZoneText2')}</Typography>
                </Box>
            )}

            {isMobileSize && (
                <Box
                    sx={{
                        width: '100%',
                    }}
                    {...getRootProps()}
                >
                    <input {...getInputProps()} />

                    <ButtonAction fullWidth startIcon={<AttachFileIcon />} size="large">
                        {t('uploadZoneButton')}
                    </ButtonAction>
                </Box>
            )}

            <Box>
                {error && error.message && (
                    <Typography sx={{fontSize: '0.75rem', color: '#DB100D'}}>
                        {error.message}
                    </Typography>
                )}
                {files && !!files.length && (
                    <Box
                        sx={{
                            mt: 1,
                        }}
                    >
                        <Box sx={{display: 'flex'}}>
                            {files.map((file, index) => {
                                return (
                                    <Chip
                                        key={'' + index + file.lastModified} // ?
                                        icon={isUploadInProgress ? <CircularProgress size={20} /> : <AttachmentIcon />}
                                        label={file.original_filename ?? file.path ?? file.fileName}
                                        onDelete={
                                            isFileDeleteAvailable && !isDisabled && !isUploadInProgress
                                                ? () => onFileRemove(index)
                                                : undefined
                                        }
                                    />
                                );
                            })}

                        </Box>
                    </Box>
                )}

                {files.length === 0 && isInfoTextVisible && (
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography sx={{mt: 1}}>
                            <Typography color="text.disabled" display="inline">
                                {t('fileFormat')}
                            </Typography>
                            &nbsp;{fileTypes.toUpperCase().replace(/\./g, '')}
                        </Typography>

                        <Typography>
                            <Typography color="text.disabled" display="inline">{t('fileSize')}</Typography>&nbsp;10 MB
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

DropZone.propTypes = {
    fileTypes: PropTypes.string.isRequired,
    maxFiles: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    isFileDeleteAvailable: PropTypes.bool,
    isUploadInProgress: PropTypes.bool,
    maxSize: PropTypes.number,
    isInfoTextVisible: PropTypes.bool,
    files: PropTypes.array,
    sx: PropTypes.object,
    error: PropTypes.object,
    textTop: PropTypes.string,
    textBottom: PropTypes.string,
    isDisabled: PropTypes.bool,
};

DropZone.defaultProps = {
    isFileDeleteAvailable: true,
    isUploadInProgress: false,
    maxSize: 10485760, // 10 MB
    isInfoTextVisible: false,
    files: [],
    sx: {},
    textTop: '',
    textBottom: '',
    error: undefined,
    isDisabled: false,
};
