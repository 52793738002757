import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import {Box, CircularProgress, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {LocalesConstants} from '../../../../../../../utils/locales-constants';
import {DEPOSIT_OF_ACCOUNTS_FLOW_ORDER} from '../../../utils/constants';

export const STEP_STATE = {
    Completed: 'Completed',
    Active: 'Active',
    Pending: 'Pending',
    Error: 'Error',
};

const INPIStepLabel = ({stepId, stepState, stepLabel}) => {
    const {t} = useTranslation(LocalesConstants.Companies);

    switch (stepState) {
        case STEP_STATE.Active: {
            return (
                <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress size={24} />
                    <Typography fontWeight={500}>
                        {t(stepLabel)}
                    </Typography>
                </Box>
            );
        }
        case STEP_STATE.Completed: {
            return (
                <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <CheckCircleIcon sx={{color: 'v2.light.success.light'}} />
                    <Typography>
                        {t(stepLabel)}
                    </Typography>
                </Box>
            );
        }
        case STEP_STATE.Error: {
            return (
                <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <ErrorIcon sx={{color: 'v2.light.error.main'}} />
                    <Typography fontWeight={500}>
                        {t(stepLabel)}
                    </Typography>
                </Box>
            );
        }
        case STEP_STATE.Pending: {
            return (
                <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <Box
                        sx={{display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '24px',
                            height: '24px',
                            borderRadius: '50%',
                            color: 'v2.light.info.light',
                            backgroundColor: 'v2.light.primary.shades8'}}
                    >
                        {DEPOSIT_OF_ACCOUNTS_FLOW_ORDER[stepId] + 1}
                    </Box>
                    <Typography>
                        {t(stepLabel)}
                    </Typography>
                </Box>
            );
        }
        default:
            return null;
    }
};

INPIStepLabel.propTypes = {
    stepId: PropTypes.string.isRequired,
    stepState: PropTypes.oneOf(Object.values(STEP_STATE)).isRequired,
    stepLabel: PropTypes.string.isRequired,
};

export default INPIStepLabel;
