import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import BankHelperPagesWrapper from './BankHelperPagesWrapper';
import BankMissingImage from '../../../../assets/svg/bank/bank-missing-data.svg';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {BankActions} from '../../store/bank.action';

const BankMissingInformation = () => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const {t} = useTranslation(LocalesConstants.Bank);

    const dispatch = useDispatch();

    const fillMissingData = () => {
        dispatch(BankActions.fillMissingData());
    };

    return (
        <BankHelperPagesWrapper
            image={<img src={BankMissingImage} alt="missing bank" />}
            header={t('missingInformation.header')}
            text={t('missingInformation.text')}
            Button={(
                <Button
                    onClick={fillMissingData}
                    variant="contained"
                    fullWidth={isMobileSize}
                    endIcon={<OpenInNewIcon />}
                >
                    {t('missingInformation.button')}
                </Button>
              )}
        />
    );
};

export default BankMissingInformation;
