import {
    faCheckCircle,
    faClose,
    faDownload,
    faHourglassStart,
    faSignature,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Box, Button, Stack, Typography} from '@mui/joy';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ClientSigningWrapper} from './ClientSigningWrapper';
import DemandModificationsModal from './DemandModificationsModal';
import {DOCUMENT_STATUSES} from '../../../../../consts/document-constants';
import {useIsMobileSize} from '../../../../../hooks/use-is-mobile-size';
import {LocalesConstants} from '../../../../../utils/locales-constants';
import {SESSION_NAMES, SessionUtil} from '../../../../../utils/session';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';
import {UploadSignatureModal} from '../../../../signature/components/UploadSignatureModal';
import {UiActions} from '../../../../ui/store/ui.action';
import {UiSelectors} from '../../../../ui/store/ui.selector';
import {ModalsKeys, SidebarDrawerKeys} from '../../../../ui/utils/constants';
import ConfirmMissionsSendingModal from '../../missions-signing/components/ConfirmMissionsSendingModal';
import {MissionClientsActions} from '../store/missions-client.actions';
import {MissionsClientSelector} from '../store/missions-client.selector';

const drawerKey = SidebarDrawerKeys.BOTTOM_DRAWER;

const MainActionComponent = () => {
    const dispatch = useDispatch();

    const {t} = useTranslation(LocalesConstants.Client);
    const isMobileSize = useIsMobileSize();

    const isDrawerOpen = useSelector(UiSelectors.createIsSidebarDrawerActiveSelector(drawerKey));

    const type = SessionUtil.getSession(SESSION_NAMES.PUBLIC_USER_DOCUMENT_TYPE).toLowerCase();

    const document = useSelector(MissionsClientSelector.selectDocumentInfo);
    const isSigning = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.SIGN_CONTRACT),
    );

    const signature = useSelector(MissionsClientSelector.selectSignature);

    const isContractDisplayed = !!document?.signedUrl;
    const isSigned = document?.documentStatus === DOCUMENT_STATUSES.SIGNED;

    const onNextClick = () => {
        dispatch(UiActions.setActiveModal(ModalsKeys.UPLOAD_SIGNATURE, true));
    };

    const onDemandModifications = () => {
        dispatch(UiActions.setActiveModal(ModalsKeys.DEMAND_MODIFICATIONS_MODAL, true));
    };

    const onSignClick = () => {
        if (!signature?.signedUrl) {
            dispatch(UiActions.setActiveModal(ModalsKeys.UPLOAD_SIGNATURE, true));
        } else {
            dispatch(MissionClientsActions.postSignDocument());
        }
    };

    const onDownloadDocument = () => {
        dispatch(MissionClientsActions.downloadDocument());
    };

    const isExpanded = isDrawerOpen || !isMobileSize;

    if (isSigned) {
        return (
            <>
                <Stack
                    gap={1}
                >
                    {isExpanded && (
                        <Typography
                            level="h4"
                            sx={{
                                color: 'text.primary',
                            }}
                            startDecorator={(
                                <Box sx={{color: 'text.success'}}>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                </Box>
                            )}
                        >
                            {t(`${type}.signingValid`)}
                        </Typography>
                    )}

                    {isExpanded && (
                        <Typography level="body-md" mb={1}>
                            {t(`${type}.signingText`, {
                                userName: document?.missionInfo?.userName,
                            })}
                        </Typography>
                    )}

                    <Button
                        startDecorator={<FontAwesomeIcon icon={faDownload} />}
                        variant="solid"
                        size="lg"
                        color="neutral"
                        onClick={onDownloadDocument}
                        fullWidth
                    >
                        {t(`${type}.download`)}
                    </Button>
                </Stack>
            </>
        );
    }

    return (
        <>
            {isExpanded && (
                <Typography
                    level="h4"
                    sx={{
                        color: 'text.primary',
                    }}
                >
                    {t('signing.sidebarTitle')}
                </Typography>
            )}

            {isExpanded && (
                <Alert
                    color="warning"
                    variant="soft"
                    sx={{
                        alignItems: 'flex-end',
                        mt: 1,
                        borderRadius: '6px',
                    }}
                    startDecorator={<FontAwesomeIcon icon={faHourglassStart} />}
                >
                    {t('signing.awaitingSignature')}
                </Alert>
            )}

            <Button
                sx={{my: {desktop: 2, mobile: 0}}}
                startDecorator={<FontAwesomeIcon icon={faSignature} />}
                color={isSigned ? 'success' : 'primary'}
                loading={isSigning}
                disabled={!isContractDisplayed}
                onClick={isSigned ? onNextClick : onSignClick}
                fullWidth
            >
                {t('signing.sign')}
            </Button>

            {isExpanded && (
                <Button
                    startDecorator={<FontAwesomeIcon icon={faClose} />}
                    variant="outlined"
                    disabled={isSigning}
                    onClick={onDemandModifications}
                    fullWidth
                >
                    {t('signing.demandModifications')}
                </Button>
            )}
        </>
    );
};

const ClientFormSigning = () => {
    return (
        <>
            <ClientSigningWrapper
                signingTitle=""
                hasMultipleDocuments={false}
                MainActionComponent={MainActionComponent}
            />

            <ConfirmMissionsSendingModal />
            <DemandModificationsModal />

            <UploadSignatureModal
                isV3
                isPublic
            />
        </>
    );
};

export default ClientFormSigning;
