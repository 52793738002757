import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {importLocaleBundle} from '../../../../lib/i18next';
import {LocalesConstants} from '../../../../utils/locales-constants';

importLocaleBundle(LocalesConstants.JobProfessions);

const RequiredProfile = ({rowData, isInMyBids}) => {
    const {t: userT} = useTranslation(LocalesConstants.CreateUser);
    const {t: jobT} = useTranslation(LocalesConstants.JobProfessions);

    const {jobType, jobSpecializations} = rowData;

    return (
        <Box>
            <Typography
                component={isInMyBids ? 'span' : 'p'}
                sx={{
                    fontSize: theme => theme.typography.pxToRem(14),
                    lineHeight: theme => theme.typography.pxToRem(20),
                }}
            >
                {jobType.map(
                    job => userT(`jobTypes.${job}`),
                ).join(', ')}
            </Typography>
            {isInMyBids && jobType.length > 0 && jobSpecializations.length > 0 && (
                <Typography
                    component="span"
                    sx={{
                        fontSize: theme => theme.typography.pxToRem(14),
                        lineHeight: theme => theme.typography.pxToRem(20),
                    }}
                >
                    {' · '}
                </Typography>
            )}
            <Typography
                component={isInMyBids ? 'span' : 'p'}
                sx={{
                    fontSize: theme => theme.typography.pxToRem(14),
                    lineHeight: theme => theme.typography.pxToRem(20),
                    color: 'v2.light.text.disabled',
                }}
            >
                {jobSpecializations.map(
                    specialisation => jobT(`specializations.${specialisation}`),
                ).join(', ')}
            </Typography>
        </Box>
    );
};

RequiredProfile.propTypes = {
    rowData: PropTypes.any.isRequired,
    isInMyBids: PropTypes.bool,
};

RequiredProfile.defaultProps = {
    isInMyBids: false,
};

export default React.memo(RequiredProfile);
