import {createSelector} from 'reselect';

const selectMission = state => state.missions?.mission;
const selectMissionInProgress = state => state.missions?.missionInProgress;
const selectCreationScreen = state => state.missions?.createMissionScreen;
const selectMissionList = state => state?.missions?.missionList;
const selectMissionDetails = state => state?.missions?.details;

const createDocumentsSelector = () => createSelector([selectMissionDetails], documents => {
    if (!documents?.count) {
        return {data: [], count: 0};
    }
    return {data: documents.items, count: Number(documents.count)};
});

export const MissionsSelector = {
    selectMission,
    selectMissionInProgress,
    selectCreationScreen,
    selectMissionList,
    selectMissionDetails,
    createDocumentsSelector,
};
