import {all, call, delay, put, select, takeEvery} from 'redux-saga/effects';
import {DashboardActions} from './dashboard.action';
import {DashboardActionTypes} from './dashboard.action-type';
import {DashboardSelectors} from './dashboard.selector';
import {
    getDashboardDataRequest,
    putIncomeCalculationRequest,
} from '../../../app/api/providers/dashboard/dashboard.provider';
import {COMPANY_TYPES} from '../../../consts/company-constants';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import {selectCurrentCognitoUser} from '../../auth/store/auth.selectors';
import {getBeneficiariesListWorker} from '../../bank/modules/transfers/store/beneficiaries.saga';
import {getRemunerationWorker} from '../../bank/modules/transfers/store/transfers.saga';
import {BankSelector} from '../../bank/store/bank.selector';
import {COMPANY_STATUSES} from '../../company/modules/setup-company/utils/constants';
import {createDefaultCompanySelector} from '../../company/store/company-list/company-list.selectors';
import {COMPANY_LEGAL_STATUSES, COMPANY_TAX_SYSTEMS} from '../../company/utils/constants';
import {InsuranceActions} from '../../insurance/store/insurance.action';
import {LoadingActions, LoadingTypes} from '../../loading';
import {UiActions} from '../../ui/store/ui.action';
import {ModalsKeys} from '../../ui/utils/constants';
import {toPayCalculationInfoDTO} from '../../user-list/api/user.dto';
import {LoggedInUserActions, LoggedInUserSelectors} from '../../user/modules/logged-in-user';
import {DashboardApi} from '../api/dashboard.api';
import {getDashboardDatepickerCurrentYear} from '../util/dashboard.util';

// TODO: remove this saga and related code
export const dashboardLoaderSaga = function* () {
    const company = yield select(createDefaultCompanySelector());

    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield put(InsuranceActions.getInsurance({freelancerId: loggedInUser.id, companyId: company.id}));

    if (loggedInUser.hasBankAccess) {
        // Check if user has integration data and has passed all checks
        const canAccessBankDashboard = yield select(BankSelector.createSelectCanAccessBankDashboard());

        if (canAccessBankDashboard) {
            // Get remuneration
            yield call(getRemunerationWorker);


            // Get beneficiaries for transfer
            yield call(getBeneficiariesListWorker);
        }
    }

    if (company?.status !== COMPANY_STATUSES.ACTIVE
        || company?.legalForm !== COMPANY_LEGAL_STATUSES.SASU
        || company?.taxSystem !== COMPANY_TAX_SYSTEMS.IR
        || company?.type !== COMPANY_TYPES.DEFAULT) {
        yield put(DashboardActions.setIsLoadingDashboardData(false));

        return;
    }

    const currentCognitoUser = yield select(selectCurrentCognitoUser);

    yield call(getDashboardData, {
        freelancerId: currentCognitoUser.id,
        companyId: company.id,
        year: getDashboardDatepickerCurrentYear(),
    });
};

const getTaxIncomeFlow = function* ({freelancerId, companyId, year}) {
    try {
        const data = yield call(DashboardApi.getTaxIncome, {
            freelancerId,
            companyId,
            year,
        });

        yield put(DashboardActions.storeTaxIncome(data));
    } catch (error) {
        yield put(LoadingActions.setLoading(LoadingTypes.GET_TAX_INCOME, false));

        Debug.error('tax-income', 'Error: ', {error});
    }
};

const requestPayCalculationFlow = function* ({companyId, calculationType}) {
    try {
        const data = yield call(DashboardApi.requestPayCalculation, {
            companyId,
            calculationType,
        });

        const loggedInUserData = yield select(LoggedInUserSelectors.selectLoggedInUserDashboard);
        yield put(LoggedInUserActions.storeLoggedInUserAccountForDashboard({
            ...loggedInUserData,
            additionalInfo: {
                ...loggedInUserData?.additionalInfo,
                pay_calculation_info: toPayCalculationInfoDTO(data),
            },
        }));
    } catch (error) {
        yield put(LoadingActions.setLoading(LoadingTypes.REQUEST_PAY_CALCULATION_INFO, false));
        Debug.error('pay-calculation-info', 'Error: ', {error});
    }
};

const saveTaxIncomeFlow = function* ({freelancerId, companyId, data}) {
    try {
        yield call(DashboardApi.saveTaxIncome, {
            freelancerId,
            companyId,
            data,
        });

        Toast.success('taxIncomeSaved');
    } catch (error) {
        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_TAX_INCOME, false));

        Toast.error('anErrorOccurred');

        Debug.error('tax-income', 'Error: ', {error});
    }
};

// Workers
const getTaxIncomeWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.GET_TAX_INCOME, true));

    const {freelancerId, companyId, year} = payload;

    yield call(getTaxIncomeFlow, {
        freelancerId,
        companyId,
        year,
    });

    yield put(LoadingActions.setLoading(LoadingTypes.GET_TAX_INCOME, false));
};


const requestPayCalculationWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.REQUEST_PAY_CALCULATION_INFO, true));

    const {companyId, calculationType} = payload;

    yield call(requestPayCalculationFlow, {
        companyId,
        calculationType,
    });

    yield put(LoadingActions.setLoading(LoadingTypes.REQUEST_PAY_CALCULATION_INFO, false));
};

const saveTaxIncomeWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.SAVE_TAX_INCOME, true));

    const {freelancerId, companyId, data} = payload;

    yield call(saveTaxIncomeFlow, {
        freelancerId,
        companyId,
        data,
    });

    yield put(LoadingActions.setLoading(LoadingTypes.SAVE_TAX_INCOME, false));
    yield put(UiActions.setActiveModal(ModalsKeys.INCOME_TAX_MODAL, false));

    // Get updated dashboard data
    const company = yield select(createDefaultCompanySelector());
    if (company?.legalForm !== COMPANY_LEGAL_STATUSES.SASU
        || company?.type !== COMPANY_TYPES.DEFAULT) {
        if (company?.legalForm === COMPANY_LEGAL_STATUSES.EURL) {
            const key = yield select(DashboardSelectors.selectDashboardIframeKey);
            yield put(DashboardActions.setDashboardIframeKey(key + 1));
        }
        return;
    }

    yield delay(200);
    yield call(getDashboardData, {
        freelancerId: freelancerId,
        companyId: companyId,
        year: data.year,
    });
};

export const getDashboardData = function* ({freelancerId, companyId, year}) {
    try {
        yield put(DashboardActions.setIsLoadingDashboardData(true));

        const data = yield call(getDashboardDataRequest, {freelancerId, companyId, year});

        yield put(DashboardActions.storeDashboardData(data));

        yield put(DashboardActions.setIsLoadingDashboardData(false));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error({error});

        yield put(DashboardActions.setIsLoadingDashboardData(false));
    }
};

const incomeCalculationFlow = function* ({freelancerId}) {
    try {
        yield put(DashboardActions.setIsLoadingIncomeCalculationRequest(true));

        yield call(putIncomeCalculationRequest, {freelancerId});

        yield put(DashboardActions.setIsLoadingIncomeCalculationRequest(false));
        yield put(DashboardActions.setIsFinishedIncomeCalculationRequest(true));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error({error});

        yield put(DashboardActions.setIsLoadingIncomeCalculationRequest(false));
    }
};

const getDashboardDataWorker = function* ({payload}) {
    const company = yield select(createDefaultCompanySelector());
    const currentCognitoUser = yield select(selectCurrentCognitoUser);

    yield call(getDashboardData, {
        freelancerId: currentCognitoUser.id,
        companyId: company.id,
        year: payload,
    });
};

const incomeCalculationWorker = function* () {
    const currentCognitoUser = yield select(selectCurrentCognitoUser);

    yield call(incomeCalculationFlow, {
        freelancerId: currentCognitoUser.id,
    });
};

export const watchDashboardSaga = function* () {
    yield all([
        takeEvery(DashboardActionTypes.SET_DASHBOARD_YEAR, getDashboardDataWorker),
        takeEvery(DashboardActionTypes.INCOME_CALCULATION_REQUEST, incomeCalculationWorker),
        takeEvery(DashboardActionTypes.GET_TAX_INCOME, getTaxIncomeWorker),
        takeEvery(DashboardActionTypes.SAVE_TAX_INCOME, saveTaxIncomeWorker),
        takeEvery(DashboardActionTypes.REQUEST_PAY_CALCULATION_INFO, requestPayCalculationWorker),
    ]);
};
