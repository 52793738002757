import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import {Box, Button, Divider, IconButton, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link as RouterLink} from 'react-router-dom';
import {ConditionalTooltip} from '../../../../../../../../components/tooltip/ConditionalTooltip';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';
import {TransactionSide} from '../../../../util/constants';
import {AccountBalanceHelperFunctions} from '../../../../util/functions';
import AccountBalanceIcon from '../../../account-balance/AccountBalanceIcon';
import CategorisationBalance from '../common/CategorisationBalance';

const CategorisationHeader = ({
    details,
    hasFullScreenOpened,
    setIsInfoOpened,
    isInfoOpened,
    onBack,
}) => {
    const {t} = useTranslation(LocalesConstants.Bank);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isCredit = details?.side === TransactionSide.CREDIT;

    const {
        isRejected,
        isCancelled,
        isOnHold,
        isCategorized,
    } = AccountBalanceHelperFunctions.getCategorizationState(details);


    if (isMobileSize) {
        return (
            <Box sx={{
                display: 'flex',
                p: 2,
                pt: 1,
                backgroundColor: '#fff',
                flexDirection: 'column',
                alignItems: 'stretch',
                gap: 2,
                borderBottomSize: '1px',
                borderBottomColor: 'v2.blueGray.50',
                borderBottomStyle: 'solid',
            }}
            >
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 1,
                    alignItems: 'center',
                }}
                >
                    <IconButton
                        component={RouterLink}
                        onClick={onBack}
                        sx={{
                            p: 0,
                            minWidth: '80px',
                        }}
                    >
                        <ArrowBackIcon htmlColor="#263238" sx={{mr: 'auto'}} />
                    </IconButton>

                    <CategorisationBalance amount={details?.amount} isCredit={isCredit} />

                    <Button
                        onClick={() => {
                            if (!isInfoOpened) {
                                setIsInfoOpened(true);
                            }
                        }}
                        size="small"
                        sx={{
                            fontSize: theme => theme.typography.pxToRem(15),
                            fontWeight: 500,
                            py: 0.25,
                            px: 1,
                            textTransform: 'none',
                            color: 'v2.blueGray.400',
                            borderColor: 'v2.blueGray.50',
                            minWidth: '80px',
                        }}
                        variant="outlined"
                        startIcon={<InfoOutlinedIcon sx={{mr: 0.5}} />}
                    >
                        {t('categorisation.header.info')}
                    </Button>
                </Box>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: 2,
                    backgroundColor: 'gray_v2.100',
                    justifyContent: 'space-around',
                }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '32px',
                        gap: 0.5,
                    }}
                    >
                        <AccountBalanceIcon
                            paymentType={details?.type}
                            category={details?.category}
                            sourceOfRevenue={details?.sourceOfRevenue}
                            hasBackground={false}
                            iconColor="v2.blueGray.300"
                            size="20px"
                        />
                        {isOnHold && (
                            <ConditionalTooltip
                                sx={{
                                    textTransform: 'uppercase',
                                }}
                                tooltip={t('accountBalanceTableHeader.onHoldAlertHeader')}
                            >
                                <AccessTimeFilledIcon
                                    fontSize="small"
                                    sx={{
                                        color: 'warning_v2.main',
                                        ml: -0.4,
                                    }}
                                />
                            </ConditionalTooltip>
                        )}
                        <Typography
                            component="span"
                            sx={{
                                fontSize: theme => theme.typography.pxToRem(16),
                                fontWeight: 600,
                                color: 'text_v2.secondary',
                            }}
                        >
                            {details?.side === TransactionSide.CREDIT ? details?.debtorName : details?.creditorName}
                        </Typography>
                    </Box>
                </Box>

                <CategorisationHeaderButton
                    isCategorized={isCategorized}
                    isCancelled={isCancelled}
                    isRejected={isRejected}
                />
            </Box>
        );
    }

    return (
        <Box sx={{
            display: 'flex',
            px: 2,
            py: 1,
            backgroundColor: '#fff',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
            borderBottomSize: '1px',
            borderBottomColor: 'v2.blueGray.50',
            borderBottomStyle: 'solid',
        }}
        >
            <Button
                onClick={onBack}
                sx={{py: 0.5}}
                variant="text"
                color="primary"
                startIcon={<ArrowBackIcon />}
            >
                {hasFullScreenOpened ? t('categorisation.header.backToTransaction') : t('categorisation.header.back')}
            </Button>

            <Box sx={{
                px: 1,
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                backgroundColor: 'v2.light.info.shades4',
                borderRadius: 1,
            }}
            >
                <CategorisationBalance amount={details?.amount} isCredit={isCredit} />
                <Divider orientation="vertical" variant="middle" flexItem />
                <Box sx={{
                    display: 'flex',
                    gap: 0.5,
                    alignItems: 'center',
                }}
                >
                    <AccountBalanceIcon
                        paymentType={details?.type}
                        category={details?.category}
                        sourceOfRevenue={details?.sourceOfRevenue}
                        hasBackground={false}
                        iconColor="v2.blueGray.300"
                        boxSx={{
                            my: 0,
                            mx: 1,
                            mr: 1.5,
                        }}
                        size="0px"
                    />
                    {isOnHold && (
                        <ConditionalTooltip
                            sx={{
                                textTransform: 'uppercase',
                            }}
                            tooltip={t('accountBalanceTableHeader.onHoldAlertHeader')}
                        >
                            <AccessTimeFilledIcon
                                fontSize="small"
                                sx={{
                                    color: 'warning_v2.main',
                                    ml: -0.4,
                                }}
                            />
                        </ConditionalTooltip>
                    )}

                    <Typography
                        component="span"
                        sx={{
                            fontSize: theme => theme.typography.pxToRem(16),
                            fontWeight: 600,
                            color: 'text_v2.secondary',
                        }}
                    >
                        {details?.side === TransactionSide.CREDIT
                            ? details?.debtorName : details?.creditorName}
                    </Typography>
                </Box>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Button
                    onClick={() => {
                        if (!isInfoOpened) {
                            setIsInfoOpened(true);
                        }
                    }}
                    // disabled={hasFullScreenOpened}
                    size="small"
                    sx={{
                        fontSize: theme => theme.typography.pxToRem(13),
                        fontWeight: 500,
                        py: 0.25,
                        px: 0.125,
                        textTransform: 'none',
                        color: 'v2.blueGray.400',
                        minWidth: 'auto',
                    }}
                    variant="text"
                    startIcon={<InfoOutlinedIcon sx={{mr: 0.5}} />}
                >
                    {t('categorisation.header.info')}
                </Button>
            </Box>

            <CategorisationHeaderButton
                isCategorized={isCategorized}
                isCancelled={isCancelled}
                isRejected={isRejected}
            />
        </Box>
    );
};

CategorisationHeader.propTypes = {
    details: PropTypes.object.isRequired,
    setIsInfoOpened: PropTypes.func.isRequired,
    isInfoOpened: PropTypes.bool.isRequired,
    onBack: PropTypes.func.isRequired,
    hasFullScreenOpened: PropTypes.bool.isRequired,
};

const CategorisationHeaderButton = ({
    isCategorized,
    isCancelled,
    isRejected,
}) => {
    const {t} = useTranslation(LocalesConstants.Bank);

    let backgroundColor;
    let color;
    let text;
    let Icon;

    if (isCancelled) {
        backgroundColor = 'v2.blueGray.200';
        text = t('categorisation.header.canceled');
        color = 'gray';
        Icon = <ErrorOutlineIcon />;
    } else if (isRejected) {
        backgroundColor = 'v2,light.error.main';
        text = t('categorisation.header.rejected');
        color = 'error';
        Icon = <ErrorIcon />;
    } else if (isCategorized) {
        backgroundColor = 'success.main';
        text = t('accountBalanceTableHeader.categorized');
        color = 'success';
        Icon = <CheckIcon sx={{color: 'white'}} />;
    } else {
        backgroundColor = 'v2.light.secondary.main';
        text = t('categorisation.header.toCategorise');
        color = 'secondary';
        Icon = <PlaylistAddCheckIcon />;
    }

    return (
        <Button
            sx={{
                'py': 0.5,
                'textTransform': 'none',
                'color': 'white',
                'cursor': 'default',
                'backgroundColor': backgroundColor,
                '&:hover': {
                    boxShadow: '0 3px 1px -2px rgba(11, 35, 51, 0.05), 0 2px 2px rgba(11, 35, 51, 0.07), 0 1px 5px rgba(11, 35, 51, 0.09);',
                    backgroundColor: backgroundColor,
                },
            }}
            disableRipple={true}
            disableFocusRipple={true}
            variant="contained"
            color={color}
            startIcon={Icon}
        >
            {text}
        </Button>
    );
};

CategorisationHeaderButton.propTypes = {
    isCategorized: PropTypes.bool.isRequired,
    isCancelled: PropTypes.bool.isRequired,
    isRejected: PropTypes.bool.isRequired,
};

export default CategorisationHeader;
