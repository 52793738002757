import {
    faArrowRight,
    faClose,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Stack, Typography} from '@mui/joy';
import moment from 'moment';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ModalWrapper} from '../../../../../components/modal-wrapper/ModalWrapper';
import {useIsMobileSize} from '../../../../../hooks/use-is-mobile-size';
import {LocalesConstants} from '../../../../../utils/locales-constants';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';
import {UiSelectors} from '../../../../ui/store/ui.selector';
import {ModalsKeys} from '../../../../ui/utils/constants';
import {MissionsCraActions as MissionCraActions} from '../store/missions-cra.action';

const modalKey = ModalsKeys.CONFIRM_CRA_SENDING;

const SendCraModal = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation(LocalesConstants.Missions);
    const isMobileSize = useIsMobileSize();

    const data = useSelector(UiSelectors.createModalDataSelector(modalKey));

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(
            LoadingTypes.GENERIC_CRUD_LOADER,
        ),
    );

    const onSubmit = () => {
        dispatch(MissionCraActions.generateCraContract(data));
    };

    return (
        <ModalWrapper
            isV3
            containerSx={{
                width: '600px',
            }}
            modalKey={modalKey}
            title={(
                <Typography level="h1">
                    {t('craUserSendModal.title')}
                </Typography>
            )}
            buttonsSx={{
                flexDirection: {
                    mobile: 'column-reverse',
                    desktop: 'row',
                },
            }}
            buttons={({handleClose}) => (
                <>
                    <Button
                        fullWidth={isMobileSize}
                        startDecorator={(
                            <FontAwesomeIcon icon={faClose} />
                        )}
                        disabled={isLoading}
                        loadingPosition="start"
                        color="neutral"
                        size="lg"
                        variant="outlined"
                        onClick={handleClose}
                    >
                        {t('craUserSendModal.cancel')}
                    </Button>

                    <Button
                        fullWidth={isMobileSize}
                        endDecorator={(
                            <FontAwesomeIcon icon={faArrowRight} />
                        )}
                        loading={isLoading}
                        loadingPosition="start"
                        color="primary"
                        size="lg"
                        variant="solid"
                        onClick={onSubmit}
                    >
                        {t('craUserSendModal.confirm')}
                    </Button>
                </>
            )}
        >
            <Typography>
                {t('craUserSendModal.text')}
            </Typography>

            <Stack
                sx={{
                    mt: 4,
                }}
                direction="column"
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                >
                    <Typography level="body-md">{t('craUserSendModal.numberOfDays')}</Typography>
                    <Typography
                        level="title-lg"
                        sx={{color: 'text.primary'}}
                    >
                        {data?.days % 1 === 0 ? data?.days : Math.floor(data?.days) + '½'} {t('craUserSendModal.days')}
                    </Typography>
                </Stack>

                <Stack
                    direction="row"
                    justifyContent="space-between"
                >
                    <Typography level="body-md">{t('craUserSendModal.startDate')}</Typography>
                    <Typography
                        level="title-lg"
                        sx={{color: 'text.primary'}}
                    >
                        {moment(data?.startDate).format('L')}
                    </Typography>
                </Stack>

                <Stack
                    direction="row"
                    justifyContent="space-between"
                >
                    <Typography level="body-md">{t('craUserSendModal.endDate')}</Typography>
                    <Typography
                        level="title-lg"
                        sx={{color: 'text.primary'}}
                    >
                        {data?.isCash
                            ? moment(data?.startDate).endOf('month').format('L')
                            : moment(data?.endDate).format('L')
                        }
                    </Typography>
                </Stack>

                <Stack
                    direction="row"
                    justifyContent="space-between"
                >
                    <Typography level="body-md">{t('craUserSendModal.totalHT')}</Typography>
                    <Typography
                        level="title-lg"
                        sx={{color: 'text.primary'}}
                    >
                        {data?.totalRate} €
                    </Typography>
                </Stack>

                <Stack
                    direction="row"
                    justifyContent="space-between"
                >
                    <Typography level="body-md">{t('craUserSendModal.totalTTC')}</Typography>
                    <Typography
                        level="title-lg"
                        sx={{color: 'text.primary'}}
                    >
                        {data?.totalRateWithTax?.toFixed(2)} €
                    </Typography>
                </Stack>
            </Stack>
        </ModalWrapper>
    );
};

export default SendCraModal;
