import {select} from 'redux-saga/effects';
import {isUserFn} from '../../../utils/user-roles';
import {selectCurrentCognitoUser} from '../../auth/store/auth.selectors';
import {selectFreelancerAccount} from '../store/freelancer.selectors';

export const getFreelancerId = function* () {
    const user = yield select(selectCurrentCognitoUser);

    if (isUserFn(user)) {
        return user.id;
    }

    const freelancer = yield select(selectFreelancerAccount);

    /* The nullcheck prevents error if user is Umbrella */
    return freelancer?.id;
};
