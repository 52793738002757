import EventIcon from '@mui/icons-material/Event';
import {LoadingButton} from '@mui/lab';
import {Box, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {UiActions} from '../../../ui/store/ui.action';
import {UiSelectors} from '../../../ui/store/ui.selector';
import {ModalsKeys} from '../../../ui/utils/constants';

const modalKey = ModalsKeys.FUNDING_APPROVAL_DATE_ALERT;

export const FundingApproveDateAlertModal = () => {
    const dispatch = useDispatch();

    const {t} = useTranslation(LocalesConstants.Training);
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const data = useSelector(UiSelectors.createModalDataSelector(modalKey));

    const onSubmitClick = () => {
        dispatch(UiActions.setActiveModal(modalKey, false));
    };

    return (
        <ModalWrapper
            modalKey={modalKey}
            title={(
                <Typography variant="h5">
                    {t(`fundingApproval.dateAlertModal.header`)}
                </Typography>
            )}
            buttons={() => (
                <LoadingButton
                    fullWidth={isMobileSize}
                    color="primary"
                    type="submit"
                    onClick={onSubmitClick}
                    variant="contained"
                >
                    {t(`fundingApproval.dateAlertModal.submit`)}
                </LoadingButton>
            )}
            containerSx={{
                width: '631px',
            }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: {
                    xs: 'column',
                    md: 'row',
                },
            }}
            >
                <Box sx={{
                    display: 'flex',
                    width: {
                        xs: '100%',
                        md: '80px',
                    },
                    height: {
                        xs: '112px',
                        md: '80px',
                    },
                    background: 'rgba(2, 136, 209, 0.04)',
                    borderRadius: 2,
                    flexShrink: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    mr: {
                        md: 2,
                        xs: 0,
                    },
                }}
                >
                    <EventIcon
                        sx={{
                            color: '#3779BE',
                            fontSize: {
                                xs: '80px',
                                md: '44px',
                            },
                        }}
                    />
                </Box>

                <Box sx={{
                    fontSize: theme => theme.typography.pxToRem(16),
                    lineHeight: '24px',
                    mt: {
                        xs: 2,
                        md: 0,
                    },
                }}
                >
                    <Typography>
                        {t('fundingApproval.dateAlertModal.textPart1', {date: data?.date})}
                    </Typography>
                    <Typography sx={{mt: 2}}>
                        {t('fundingApproval.dateAlertModal.textPart2', {date: data?.date})}
                    </Typography>
                </Box>
            </Box>
        </ModalWrapper>
    );
};
