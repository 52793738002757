import CheckIcon from '@mui/icons-material/Check';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {LocalesConstants} from '../../../../../utils/locales-constants';
import {FORMALITIES_PAYMENT_STATUS} from '../utils/constants';

const getIconAndShade = status => {
    switch (status) {
        case FORMALITIES_PAYMENT_STATUS.VALID:
            return {
                icon: <HourglassTopIcon sx={{color: 'v2.light.warning.main'}} />,
                shade: 'v2.light.warning.shades4',
            };
        case FORMALITIES_PAYMENT_STATUS.COMPLETED:
            return {
                icon: <CheckIcon sx={{color: 'v2.light.success.main'}} />,
                shade: 'v2.light.success.shades4',
            };
        default:
            return {
                icon: <HourglassTopIcon sx={{color: 'v2.light.warning.main'}} />,
                shade: 'v2.light.warning.shades4',
            };
    }
};

const FormalityPaymentStatusTag = ({status}) => {
    const {t} = useTranslation(LocalesConstants.Companies);
    const {icon, shade} = getIconAndShade(status);

    return (
        <Box
            sx={{
                borderRadius: '8px',
                padding: '2px 8px',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: shade,
                fontWeight: 500,
            }}
        >
            {icon}
            {t(`formalities.depositOfAccounts.flowSteps.formalityPayment.status.${status}`)}
        </Box>
    );
};

FormalityPaymentStatusTag.propTypes = {
    status: PropTypes.string.isRequired,
};

export default FormalityPaymentStatusTag;
