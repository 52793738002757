import moment from 'moment';
import {transformCoreUsersFromDTO} from './user.core-dto';
import {
    fromUmbrellaUserDTO,
    fromUserDTO,
    toUserDTO,
    transformCoachingInfo,
    transformFreelancersFromDTO,
    transformToCoachingInfoDTO,
    transformUsersFromDTO,
} from './user.dto';
import axiosInstance, {safeTransformData} from '../../../app/api/axios';
import {coreApiInstance} from '../../../lib/api-providers/core-api-instance';
import {LoggedInUserAccount} from '../../user/modules/logged-in-user/api/logged-in-user.dto';

export const getUsersRequest = params => {
    return coreApiInstance({
        method: 'GET',
        url: '/api/v2/users',
        params,
    }).then(response => {
        return {
            total: response?.data?.count,
            data: safeTransformData(response, transformCoreUsersFromDTO),
        };
    });
};

export const getCoachesRequest = roles => {
    return axiosInstance({
        method: 'GET',
        url: '/v1/users',
        params: {
            roles: roles || undefined,
        },
    }).then(response => safeTransformData(response, transformUsersFromDTO));
};

export const getUserRequest = userId => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/users/${userId}`,
    }).then(response => safeTransformData(response, fromUserDTO));
};

export const getPortageUserRequest = userId => {
    return coreApiInstance({
        method: 'GET',
        url: `/api/v2/users/${userId}?include=documents&include=profile&include=settings&include=companies`,
    }).then(response => safeTransformData(response, fromUmbrellaUserDTO));
};

export const updateUserRequest = (userId, user, propagateAddressChange) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/users/${userId}`,
        data: {...user, propagateAddressChange},
        transformRequest: data => JSON.stringify(toUserDTO(data)),
    }).then(response => safeTransformData(response));
};

export const updateCanSetupCompanyRequest = (
    userId,
    activityStartDate,
    firstBankTrxDate,
    legalStatus,
    taxSystem,
    useCustomCompanyAddress,
    registrationType,
) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${userId}/company-setup-enable`,
        data: {
            activity_start_date: moment(activityStartDate).format('DD/MM/YYYY'),
            ...(firstBankTrxDate && {first_bank_trx_date: moment(firstBankTrxDate).format('DD/MM/YYYY')}),
            legal_status: legalStatus,
            tax_system: taxSystem,
            use_custom_company_address: useCustomCompanyAddress,
            registration_type: registrationType,
        },
    }).then(response => safeTransformData(response));
};

export const updateCanSetupHoldingCompanyRequest = (userId, activityStartDate, firstBankTrxDate) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${userId}/holding-company-setup-enable`,
        data: {
            activity_start_date: activityStartDate,
            ...(firstBankTrxDate && {first_bank_trx_date: moment(firstBankTrxDate).format('DD/MM/YYYY')}),
        },
    }).then(response => safeTransformData(response));
};

export const createUserRequest = user => {
    return axiosInstance({
        method: 'POST',
        url: '/v1/users',
        data: user,
        transformRequest: data => JSON.stringify(toUserDTO(data)),
    }).then(response => safeTransformData(response, fromUserDTO));
};

export const assignFreelancersRequest = (userId, freelancers) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/users/${userId}/freelancers`,
        data: {freelancers},
    }).then(response => safeTransformData(response));
};

export const saveUmbrellaUserCoachInformationRequest = (userId, jobType, coachId) => {
    const jobTypes = [];
    jobTypes.push({jobType});
    return coreApiInstance({
        method: 'PUT',
        url: `/api/v2/freelancers/${userId}/coaching-info`,
        data: {
            coachId: coachId,
            professions: jobTypes,
        },
    }).then(response => safeTransformData(response));
};

export const coachInformationSubmitRequest = (form, userId) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${userId}/coaching-info`,
        data: {coaching_info: transformToCoachingInfoDTO(form)},
        // data: {coaching_info: form},
        // transformRequest: data => JSON.stringify(transformToCoachingInfoDTO(data)),
    }).then(response => safeTransformData(response, transformCoachingInfo));
};

export const coachInformationGetRequest = userId => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/freelancers/${userId}/coaching-info`,
    }).then(response => safeTransformData(response, transformCoachingInfo));
};

export const getAssignedFreelancersRequest = userId => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/users/${userId}/freelancers`,
    }).then(response => safeTransformData(response, transformFreelancersFromDTO));
};

export const getAvailableFreelancersRequest = userId => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/users/${userId}/available-freelancers`,
    }).then(response => safeTransformData(response, transformFreelancersFromDTO));
};

export const activateUserRequest = userId => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/users/${userId}/activate`,
    }).then(response => safeTransformData(response));
};

export const deactivateUserRequest = userId => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/users/${userId}/deactivate`,
    }).then(response => safeTransformData(response));
};

export const reinviteUserRequest = userId => {
    return axiosInstance({
        method: 'POST',
        url: `/v1/users/${userId}/invite`,
    }).then(response => safeTransformData(response));
};

export const getLoggedInUserRequest = () => {
    return axiosInstance({
        method: 'GET',
        url: '/v1/users/me',
    }).then(response => {
        return {
            users: safeTransformData(response, fromUserDTO),
            loggedInUsers: new LoggedInUserAccount(response.data),
        };
    });
};

export const updateLoggedInUserRequest = user => {
    return axiosInstance({
        method: 'PUT',
        url: '/v1/users/me',
        data: user,
        transformRequest: data => JSON.stringify(toUserDTO(data)),
    }).then(response => safeTransformData(response));
};
