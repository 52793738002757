const BASE_COMPANY_PATH = '/freelancer/:freelancerId/companies/:companyId';

export const PublicRoutePaths = {
    NEW_PASSWORD: '/new-password',
    RESET_PASSWORD: '/reset-password',
    EMAIL_VERIFICATION: '/verify-email',
    ACCESS_DENIED: '/access-denied',
    PUBLIC_FILE_UPLOAD: '/signature-picture-upload',
};

export const PublicRoutesWithToken = {
    MISSION_CLIENT_LANDING: '/missions/bon-de-commande',
    MISSION_CLIENT_CRA_LANDING: '/missions/cra-validation',
    MISSION_CLIENT: '/mes-missions-bon-de-commande',
    MISSION_CLIENT_CRA: '/mes-missions-cra-validation',
};

export const UmbrellaRoutePaths = {
    DOCUMENTS: '/umbrella/documents/:userId',
    USER: '/umbrella/users/:userId/:tab?',
};

export const RoutePaths = {
    // Public routes
    ...PublicRoutePaths,

    // v2 route paths
    ACTIVE_COMPANIES: 'active',
    COACHING: 'coaching',
    DASHBOARD: '/',
    UMBRELLA_USERS_LIST: '/umbrella/users',

    // Bank section
    BANK: '/bank',
    BANK_PROMOTION: '/bank-promotion',
    REQUEST_HIWAY_PRO_ACCESS_LP: '/request-hiway-pro-access',
    BANK_TRANSACTION_LIST: '/bank-transactions',
    BANK_TRANSFER_LIST: '/bank-transfers',
    BANK_CREATE_TRANSFER: '/bank-new-transfer',
    BANK_MANUAL_CREATION: '/bank-manual-creation',
    BANK_CREATION_MISSING_INFORMATION: '/bank-missing-information',
    BANK_CREATION_FINALIZE: '/bank-finalize-creation',
    BANK_ONBOARDING_SUCCESS: '/bank-onboarding-success',
    BANK_AFTER_CREATION_REDIRECT: '/bank-after-creation',
    TRANSFER_CREATED_REDIRECT: '/transfer-consent',
    BANK_CARDS: '/bank-cards',
    CARDS_CONSENT: '/card-consent',
    BANK_KYC: '/bank-kyc',
    BANK_CATEGORISATION: '/bank-categorisation',
    BANK_RIB_AND_DOCUMENTS: '/rib-and-documents',
    ARIA_INVOICE_VALID: '/aria-invoice-validation-valid',
    ARIA_INVOICE_INVALID: '/aria-invoice-validation-invalid',
    ARIA_INVOICE_EXPIRED: '/aria-invoice-validation-resolved',

    DASHBOARD_DETAILS: '/dashboard-details/:year',
    ONBOARDING: '/freelance-onboarding',
    UMBRELLA_ONBOARDING: '/wage-onboarding',
    ONBOARDING_COMPANIES: '/companies-in-creation',
    COMPANY_PROFILE_ADDITIONAL_DATA: `${BASE_COMPANY_PATH}/additional-data-tab`,
    // v1 route paths
    ADP: '/adp',
    BASE_COMPANY_PATH: BASE_COMPANY_PATH,
    BUSINESS_KILOMETERS_ALLOWANCE: '/business-kilometers-allowance',
    CGVS: '/cgvs',
    CHANGE_PASSWORD: '/change-password',
    COMPANIES: '/active-companies',
    COMPANY: `${BASE_COMPANY_PATH}/:tab?`,
    COMPANY_BACKOFFICE: `${BASE_COMPANY_PATH}/backoffice`, // D
    COMPANY_BUSINESS_KILOMETERS_ALLOWANCE: `${BASE_COMPANY_PATH}/business-kilometers-allowance`,
    COMPANY_DASHBOARD: `${BASE_COMPANY_PATH}/dashboard`,
    COMPANY_DOCUMENTS: `${BASE_COMPANY_PATH}/documents`,
    COMPANY_INVOICING: `${BASE_COMPANY_PATH}/invoicing`,
    COMPANY_PERSONAL_EXPENSES: `${BASE_COMPANY_PATH}/personal-expenses`,
    COMPANY_PROFESSIONAL_EXPENSES: `${BASE_COMPANY_PATH}/professional-expenses`,
    COMPANY_QUICKBOOKS: `${BASE_COMPANY_PATH}/quickbooks`, // TODO STUPAR: Do not remove this, should be renamed
    COMPANY_DEPOSIT_OF_ACCOUNTS: `${BASE_COMPANY_PATH}/deposit-of-accounts/`,
    CREATE_USER: 'users/create',
    DOCUMENTS: '/documents/:companyId',
    EDIT_USER: ':tab/:isEdit',
    GENERATE_DOCUMENTS: '/generate-documents/:companyId',
    HOME_OFFICE_TRAVEL: '/home-office-travel',
    INVOICING: '/invoicing',
    CREATE_INVOICE: '/invoicing-new',
    MY_CLIENTS: '/my-clients',
    MY_SERVICES: '/my-services',
    LOGIN: '/login',
    MISSION_SEARCH: '/mission-search',
    MY_COMPANIES: '/my-companies',
    MY_COMPANY: '/my-companies/:companyId',
    MY_PROFILE: '/profile',
    MY_PROFILE_EDIT: '/profile/edit',
    NOT_FOUND: '/not-found',
    PERSONAL_EXPENSES: '/personal-expenses',
    PROFESSIONAL_EXPENSES: '/professional-expenses',
    PROFESSIONAL_TRAVEL: '/professional-travel',
    SETTINGS: '/settings',
    SETUP_COMPANY: '/setup-company/:companyId/:activeStep?',
    SIGN_CONTRACT: '/sign-contract',
    SIGN_DOCUMENTS: '/sign-documents',
    SIGN_DEPOSIT_OF_ACCOUNTS_DOCUMENTS: '/sign-deposit-of-accounts-documents',
    USER: '/users/:userId',
    USER_TAB: ':tab',
    USERS: '/',
    IR_TUTORIAL_PDF: '/ir-tutorial',
    GUIDE_REPRISE_TRANSFORMATION_PDF: '/guide-reprise-transformation',
    REMUNERATION_EXPLANATION_DOCUMENT: '/understanding-my-compensation',
    CLUSTER: '/cluster',
    CLUSTER_SUBMIT: '/cluster/submit-opportunity',
    CLUSTER_SUBMIT_SUCCESS: '/cluster/submit-success',
    CLUSTER_APPLY_BASE: '/cluster/apply',
    CLUSTER_APPLY: '/cluster/apply/:opportunityId',
    TRAINING: '/training',
    WEALTH_MANAGEMENT: '/wealth-management',
    PER_SIMULATOR: '/wealth-management/per',
    VIE_SIMULATOR: '/wealth-management/life-insurance',
    SCPI_SIMULATOR: '/wealth-management/scpi',
    DOCUMENTATION: '/centre-d-aide',
    DOCUMENTATION_CATEGORY: '/centre-d-aide/:categorySlug',
    DOCUMENTATION_LIST: '/centre-d-aide/:parentSlug/:categorySlug',
    DOCUMENTATION_DETAILS: '/centre-d-aide/:parentSlug/:categorySlug/:pageSlug',
    FORMALITIES: '/formalities',
    VAT_DECLARATION: '/formalities/vat-declaration',
    FINANCIAL_STATEMENTS: '/formalities/financial-statements',
    REVENUE_SIMULATIONS_LIST: '/revenue-simulator/simulations',
    REVENUE_SIMULATOR_CREATE: '/revenue-simulator/create',
    REVENUE_SIMULATOR_RESULTS: '/revenue-simulator/simulations/:simulationId',
    REVENUE_SIMULATOR_RESULTS_PUBLIC: '/revenue-simulator-results/:simulationId',
    VAT_DECLARATION_RESULTS: '/formalities/vat-declaration/:declarationId',
    VAT_DECLARATION_YEARLY_HISTORY: '/formalities/vat-declaration/:declarationId/yearly-history',
    MISSIONS: '/mes-missions',
    MISSION_CREATE: '/mes-missions-create',
    MISSION_EDIT: '/mes-missions/:missionId/edit',
    MISSION_DETAILS: '/mes-missions/:missionId',
    MISSION_CREATE_CRA: '/mes-missions-cra', // Needs mission ID as GET param
};

/* TODO: migrate v1 route paths */
export const ROUTE_PATHS = {
    ACCESS_DENIED: '/access-denied', // D
    BASE_COMPANY_PATH: BASE_COMPANY_PATH, // D
    BUSINESS_KILOMETERS_ALLOWANCE: '/business-kilometers-allowance', // D
    COMPANIES: '/active-companies', // D
    COMPANY: `${BASE_COMPANY_PATH}`, // D
    COMPANY_WITH_TAB: `${BASE_COMPANY_PATH}/:tab/`, // D
    COMPANY_BACKOFFICE: `${BASE_COMPANY_PATH}/backoffice`, // D
    COMPANY_BUSINESS_KILOMETERS_ALLOWANCE: `${BASE_COMPANY_PATH}/business-kilometers-allowance`, // D
    COMPANY_DASHBOARD: `${BASE_COMPANY_PATH}/dashboard`, // D
    COMPANY_INVOICING: `${BASE_COMPANY_PATH}/invoicing`, // D
    COMPANY_PERSONAL_EXPENSES: `${BASE_COMPANY_PATH}/personal-expenses`, // D
    COMPANY_PROFESSIONAL_EXPENSES: `${BASE_COMPANY_PATH}/professional-expenses`, // D
    COMPANY_QUICKBOOKS: `${BASE_COMPANY_PATH}/quickbooks`, // D // TODO STUPAR: Do not remove this, should be renamed
    CREATE_USER: 'create', // D
    DASHBOARD: '/', // D
    DOCUMENTS: '/documents/:companyId', // D
    EMAIL_VERIFICATION: '/verify-email', // D
    GENERATE_DOCUMENTS: '/generate-documents/:companyId', // D
    HOME_OFFICE_TRAVEL: '/home-office-travel', // D
    INVOICING: '/invoicing', // D
    LOGIN: '/login', // D
    NEW_PASSWORD: '/new-password', // D
    NOT_FOUND: '/not-found', // D
    PERSONAL_EXPENSES: '/personal-expenses', // D
    PROFESSIONAL_EXPENSES: '/professional-expenses', // D
    PROFESSIONAL_TRAVEL: '/professional-travel', // D
    RESET_PASSWORD: '/reset-password', // D
    SETUP_COMPANY: '/setup-company/:companyId/:activeStep?', // D
    SIGN_CONTRACT: '/sign-contract', // D
    USER: '/users/:userId', // D
    USER_TAB: ':tab', // D
    EDIT_USER: ':tab/:isEdit', // D
    USERS: '/', // D
    SETTINGS: '/settings', // D
    CHANGE_PASSWORD: '/change-password', // D
    MY_COMPANIES: '/my-companies', // D
    MY_COMPANY: '/my-companies/:companyId', // D
    MY_PROFILE: '/profile', // D
    MY_PROFILE_EDIT: '/profile/edit', // D
    ADP: '/adp', // D
    CGVS: '/cgvs', // D
    COMPANY_DOCUMENTS: `${BASE_COMPANY_PATH}/documents`, // D
    MISSIONS: '/mes-missions',
    MISSION_CREATE: '/mes-missions-create',
    MISSION_EDIT: '/mes-missions/:missionId/edit',
    MISSION_DETAILS: '/mes-missions/:missionId',
};
