import {Box, CircularProgress} from '@mui/joy';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AccountBalanceAlert} from './account-balance/AccountBalanceAlert';
import {AccountBalanceTable} from './account-balance/AccountBalanceTable';
import FiltersWrapper from './transaction-list/FiltersWrapper';
import {useIsMobileSize} from '../../../../../hooks/use-is-mobile-size';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';
import {UiActions} from '../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../ui/utils/constants';
import {LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';
import {TransactionsActions} from '../store/transactions.action';
import {TransactionsSelector} from '../store/transactions.selector';
import {MAX_TRANSACTIONS_ANIMATION, TRANSACTION_CATEGORIZATION_SCREENS} from '../util/constants';
import {AccountBalanceHelperFunctions} from '../util/functions';

const TransactionList = ({
    topOffset,
    bank,
    isOpened,
    hasBankSidebarOpen,
    isTabValueAuto,
    filter,
    setFilter,
}) => {
    const dispatch = useDispatch();
    const isMobileSize = useIsMobileSize();

    const bankAccountHolders = bank?.bankAccountHolders;

    const bankCompanyHolder = bankAccountHolders
        ? bankAccountHolders.find(holder => holder.type === 'COMPANY')
        : undefined;

    const balanceString = bankCompanyHolder?.bankAccounts?.[0]?.availableBalance ?? '0';

    const balance = parseFloat(balanceString).toFixed(2);
    const transactionIds = useSelector(TransactionsSelector.selectTransactionIds);

    const transactionNonCategorizedCount = useSelector(TransactionsSelector.selectTransactionNotCategorizedCount);
    const shouldRefreshTransactionsList = useSelector(TransactionsSelector.selectShouldRefreshTransactionsList);

    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const isUserHasInvoiceAccess = loggedInUser?.hasInvoiceAccess;

    const isSidebarOpened = !!hasBankSidebarOpen;

    const onOpenClick = id => {
        dispatch(TransactionsActions.setCategorizationScreenInfo({
            screen: TRANSACTION_CATEGORIZATION_SCREENS.CATEGORY,
            transactionId: id,
        }));

        dispatch(TransactionsActions.addToCategorisationList({
            transactionId: id,
            hasSpecialHandlingIfCategorised: true,
        }));
    };

    const loadNextPage = useCallback(() => {
        dispatch(TransactionsActions.loadNextPage({...filter, accountId: bank.accountId}));
        // eslint-disable-next-line
    }, [filter]);

    const isLoading = useSelector(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.BANK_TRANSACTIONS,
    ));

    useEffect(() => {
        if (shouldRefreshTransactionsList && isOpened) {
            dispatch(TransactionsActions.getTransactionList({...filter, accountId: bank.accountId}));
            dispatch(TransactionsActions.refreshTransactionList(false));
        } else if (isOpened && !isTabValueAuto) {
            dispatch(TransactionsActions.getTransactionList({...filter, accountId: bank.accountId}));

            if (bank?.shouldAskForIntegrationAccount) {
                dispatch(UiActions.setActiveModal(ModalsKeys.PICK_INTEGRATION_ACCOUNT_MODAL, true));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filter, isOpened, shouldRefreshTransactionsList]);

    // Showing loading until transactions are loaded
    if (isLoading) {
        return (
            <Box sx={{
                width: '100%',
                height: '500px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            >
                <CircularProgress />
            </Box>
        );
    }

    const totalLoadedIds = transactionIds?.length;

    return (
        <>
            {!isMobileSize && (
                <FiltersWrapper
                    isSidebarOpened={hasBankSidebarOpen}
                    totalLoadedIds={totalLoadedIds}
                    topOffset={topOffset}
                    balance={balance}
                    filter={filter}
                    setFilter={setFilter}
                />
            )}

            <Box sx={{
                width: !hasBankSidebarOpen
                    ? '100%'
                    : AccountBalanceHelperFunctions.calculateMainContainerOpenWidth(),
                transition: totalLoadedIds > MAX_TRANSACTIONS_ANIMATION ? 'none' : 'width 0.4s ease-in-out',
            }}
            >
                <AccountBalanceAlert
                    setFilter={setFilter}
                    transactionNonCategorizedCount={transactionNonCategorizedCount}
                />

                <AccountBalanceTable
                    filter={filter}
                    transactionIds={transactionIds}
                    loadNextPage={loadNextPage}
                    hasAlert={transactionNonCategorizedCount !== 0}
                    onOpenClick={onOpenClick}
                    isUserHasInvoiceAccess={isUserHasInvoiceAccess}
                    isSidebarOpened={isSidebarOpened}
                />
            </Box>
        </>
    );
};

TransactionList.propTypes = {
    topOffset: PropTypes.number.isRequired,
    bank: PropTypes.object.isRequired,
    isOpened: PropTypes.bool.isRequired,
    hasBankSidebarOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
    isTabValueAuto: PropTypes.bool.isRequired,
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
};

export default TransactionList;
