import axios from 'axios';
import {CONFIG} from '../../config';
import {SESSION_NAMES, SessionUtil} from '../../utils/session';

export const publicClientApiInstance = axios.create({
    baseURL: CONFIG.API.CORE_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

publicClientApiInstance.interceptors.request.use(async config => {
    const sessionToken = SessionUtil.getSession(SESSION_NAMES.PUBLIC_USER_SESSION);

    if (sessionToken) {
        config.headers.Authorization = `${sessionToken}`;
        return config;
    }

    return config;
}, error => Promise.reject(error));
