import {
    transformBankStatementsFromDto,
    transformCategoriesFromDto,
    transformCombinedTransactionsFromDTO,
    transformFromIntegrationDTO,
    transformSourcesFromDto,
    transformTransactionsFromDTO,
    transfromTransactionFromDTO,
} from './bank.dto';
import {safeTransformData} from '../../../app/api/axios';
import {bankApiInstance} from '../../../lib/bank-api-instance';
import {hiwayApiInstance} from '../../../lib/hiway-api-instance';
import {RoutePaths} from '../../../lib/router/route-paths';

const getBankIntegrations = ({withArchived} = {withArchived: false}) => {
    return bankApiInstance({
        method: 'GET',
        url: `/v3/bank/bank-integrations`,
    }).then(result => transformFromIntegrationDTO(result?.data, withArchived));
};

const getBankUserIntegrations = ({userId, withArchived} = {withArchived: false}) => {
    return bankApiInstance({
        method: 'GET',
        params: {
            freelancerId: userId,
        },
        url: `/v3/bank/bank-integrations`,
    }).then(result => transformFromIntegrationDTO(result?.data, withArchived));
};

const onboardCompany = (bankIntegrationId, companyId, integrationAccountIban = undefined) => {
    return bankApiInstance({
        method: 'POST',
        url: `/v3/bank/bank-integrations/${bankIntegrationId}/account-holders/onboard-company`,
        data: {
            companyId,
            integrationAccountIban,
        },
    }).then(result => result.data);
};

const generateSwanAuthUri = (route, isIndividual) => {
    const redirectUri = `${window.location.origin}${route}`;
    return bankApiInstance({
        method: 'GET',
        params: isIndividual ? {
            identificationScope: 'Individual',
            redirectUri: redirectUri,
        } : {
            redirectUri: redirectUri,
        },
        url: `/v3/bank/bank-integrations/swanio/auth`,
    }).then(result => result.data);
};

const getBankCategories = ({accountId, params}) => {
    return bankApiInstance({
        method: 'GET',
        params,
        url: `/v3/bank/accounts/${accountId}/transactions/categories`,
    }).then(result => transformCategoriesFromDto(result?.data));
};

const getBankSources = ({accountId, params}) => {
    return bankApiInstance({
        method: 'GET',
        params,
        url: `/v3/bank/accounts/${accountId}/transactions/categories`,
    }).then(result => transformSourcesFromDto(result?.data));
};

const getBankTransactions = (params, accountId) => {
    return bankApiInstance({
        method: 'GET',
        url: `/v3/bank/accounts/${accountId}/transactions`,
        params,
    }).then(result => transformTransactionsFromDTO(result?.data));
};

const getCombinedBankTransactions = (params, freelancerId) => {
    return bankApiInstance({
        method: 'GET',
        url: `/v3/bank/bank-integrations/overview/transactions`,
        params: freelancerId ? {...params, freelancerId} : params,
    }).then(result => transformCombinedTransactionsFromDTO(result?.data));
};

const getTransactionOverview = freelancerId => {
    return bankApiInstance({
        method: 'GET',
        url: `/v3/bank/bank-integrations/overview/bank-accounts`,
        params: freelancerId ? {freelancerId} : {},
    }).then(result => result?.data);
};

const setTransactionAlertFlow = (userId, value) => {
    return hiwayApiInstance({
        method: 'PUT',
        url: `/v1/users/${userId}/additional-info`,
        data: {show_transaction_categorization_alert: value},
    }).then(result => result?.data);
};

const getTransaction = ({
    accountId,
    id,
}) => {
    return bankApiInstance({
        method: 'GET',
        url: `/v3/bank/accounts/${accountId}/transactions/${id}`,
    }).then(result => transfromTransactionFromDTO(result?.data));
};

const saveTransaction = ({
    accountId,
    id,
    articles,
    ignoreProofDocument,
    type,
    refuseToShareProofDocuments,
}) => {
    return bankApiInstance({
        method: 'PUT',
        url: `/v3/bank/accounts/${accountId}/transactions/${id}`,
        data: {
            articles,
            ignoreProofDocument: ignoreProofDocument ?? undefined,
            transactionSubType: type ?? undefined,
            refuseToShareProofDocuments: refuseToShareProofDocuments ?? undefined,
        },
    }).then(result => transfromTransactionFromDTO(result?.data));
};

const saveTransactionField = ({
    accountId,
    id,
    fieldKey,
    fieldValue,
}) => {
    return bankApiInstance({
        method: 'PUT',
        url: `/v3/bank/accounts/${accountId}/transactions/${id}`,
        // TODO: pass just data as an object
        data: {
            [fieldKey]: fieldValue,
        },
    }).then(result => transfromTransactionFromDTO(result?.data));
};

const validateTransactionVat = ({
    accountId,
    id,
    vatValidationStatus,
    articles,
}) => {
    return bankApiInstance({
        method: 'PUT',
        url: `/v3/bank/accounts/${accountId}/transactions/${id}`,
        data: {
            articles,
            vatValidationStatus,
        },
    }).then(result => transfromTransactionFromDTO(result?.data));
};

const uploadTransactionFile = ({
    accountId,
    id,
    files,
}) => {
    const formData = new FormData();
    files.forEach(file => {
        formData.append('files', file);
    });

    return bankApiInstance({
        method: 'POST',
        url: `/v3/bank/accounts/${accountId}/transactions/${id}/documents/upload`,
        data: formData,
    }).then(result => result?.data);
};

const generateRibFile = ({
    freelancerId,
    companyId,
}) => {
    return hiwayApiInstance({
        method: 'POST',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/generate-documents?stage=6`,
    }).then(result => transfromTransactionFromDTO(result?.data));
};

const deleteBankFile = ({
    accountId,
    fileId,
    transactionId,
}) => {
    return bankApiInstance({
        method: 'DELETE',
        url: `/v3/bank/accounts/${accountId}/transactions/${transactionId}/documents/${fileId}`,
    }).then(result => transfromTransactionFromDTO(result?.data));
};

const startKyc = () => {
    const redirectUri = encodeURIComponent(`${window.location.origin}`);

    return bankApiInstance({
        method: 'GET',
        url: `/v3/bank/bank-integrations/swanio/auth?redirectUri=${redirectUri}`,
    }).then(result => result?.data);
};

const startCapitalDeposit = (bankIntegrationId, companyId) => {
    return bankApiInstance({
        method: 'POST',
        data: {
            companyId,
        },
        url: `/v3/bank/bank-integrations/${bankIntegrationId}/account-holders/onboard-company/capital-deposit`,
    }).then(result => result?.data);
};

const getCapitalDeposit = (bankIntegrationId, accountHolders) => {
    return bankApiInstance({
        method: 'GET',
        url: `/v3/bank/bank-integrations/${bankIntegrationId}/account-holders/${accountHolders}/capital-deposit-info`,
    }).then(result => result?.data);
};

const uploadCapitalDepositDocument = (bankIntegrationId, accountHolders, file, docType) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('docType', docType);

    return bankApiInstance({
        method: 'POST',
        url: `/v3/bank/bank-integrations/${bankIntegrationId}/account-holders/${accountHolders}/upload-capital-deposit-single-document`,
        data: formData,
    }).then(result => result?.data);
};

const transitionCapitalDeposit = (bankIntegrationId, accountHolders) => {
    return bankApiInstance({
        method: 'PUT',
        url: `/v3/bank/bank-integrations/${bankIntegrationId}/account-holders/${accountHolders}/transition-capital-deposit-status`,
    }).then(result => result?.data);
};

const closeBankAccount = data => {
    const redirectUri = data?.redirectUri ?? `${window.location.origin}${RoutePaths.ONBOARDING}`;

    return bankApiInstance({
        method: 'PUT',
        data: {
            redirectUri,
        },
        url: `/v3/bank/account-holders/${data.bankAccountHolderId}/accounts/${data.bankAccountId}/close`,
    }).then(result => result?.data);
};

const deleteBankAccount = ({id}) => {
    return bankApiInstance({
        method: 'DELETE',
        url: `/v3/bank/bank-integrations/${id}`,
    }).then(result => result?.data);
};

const archiveBankAccount = ({bankAccountHolderId, id, bankStatements, archivingDate, isBridgeApiIntegration}) => {
    let formData;
    if (isBridgeApiIntegration) {
        formData = new FormData();
        bankStatements.forEach(statement => formData.append('files', statement));
        formData.append('archivingDate', archivingDate);
    }

    return bankApiInstance({
        method: 'PUT',
        url: `/v3/bank/account-holders/${bankAccountHolderId}/accounts/${id}/archive`,
        ...(isBridgeApiIntegration && {data: formData}),
    }).then(result => result?.data);
};

const getRibDocumentData = ({bankAccountHolderId, bankAccountId}) => {
    return bankApiInstance({
        method: 'GET',
        url: `/v3/bank/account-holders/${bankAccountHolderId}/accounts/${bankAccountId}/details/rib-document-download`,
    }).then(result => result?.data);
};

const getBankAccountStatements = ({bankAccountHolderId, bankAccountId, params}) => {
    return bankApiInstance({
        method: 'GET',
        url: `/v3/bank/account-holders/${bankAccountHolderId}/accounts/${bankAccountId}/statements`,
        params,
    }).then(result => transformBankStatementsFromDto(result?.data));
};

const downloadAllStatementsAsZip = ({bankAccountHolderId, bankAccountId}) => {
    return bankApiInstance({
        method: 'GET',
        url: `/v3/bank/account-holders/${bankAccountHolderId}/accounts/${bankAccountId}/statements/download`,
    }).then(result => result?.data);
};

const getTransactionsMatchingSubscription = ({id, accountId}) => {
    return bankApiInstance({
        method: 'GET',
        url: `/v3/bank/accounts/${accountId}/transactions/${id}/subscription`,
    }).then(result => result?.data);
};

const getEligibleTransactionsMatchingSubscription = ({id, accountId}) => {
    return bankApiInstance({
        method: 'GET',
        url: `/v3/bank/accounts/${accountId}/transactions/${id}/subscription/eligible`,
    }).then(result => result?.data);
};

const activateSubscription = ({id, accountId, type}) => {
    return bankApiInstance({
        method: 'POST',
        data: {
            transactionSubType: type,
        },
        url: `/v3/bank/accounts/${accountId}/transactions/${id}/subscription/activate`,
    }).then(result => result?.data);
};

const deactivateSubscription = ({id, accountId, type}) => {
    return bankApiInstance({
        method: 'POST',
        data: {
            transactionSubType: type,
        },
        url: `/v3/bank/accounts/${accountId}/transactions/${id}/subscription/deactivate`,
    }).then(result => result?.data);
};

const getTransactionCategorizationStats = userId => {
    return bankApiInstance({
        method: 'GET',
        params: {
            freelancerId: userId,
        },
        url: '/v3/bank/accounts-overview/transaction-categorization-stats',
    }).then(result => result?.data);
};

const getBankDetails = freelancerIds => {
    return bankApiInstance({
        method: 'POST',
        data: {
            ids: freelancerIds,
        },
        url: `/v3/bank/bank-integrations/overview/bank-accounts/list`,
    }).then(result => result?.data);
};

const getExpensesStats = ({freelancerId, year}) => {
    return bankApiInstance({
        method: 'GET',
        url: `/v3/bank/bank-integrations/stats/expenses`,
        params: {year, freelancerId},
    }).then(response => safeTransformData(response));
};

const getRemunerationStats = ({freelancerId, year}) => {
    return bankApiInstance({
        method: 'GET',
        url: `/v3/bank/bank-integrations/stats/remuneration`,
        params: {year, freelancerId},
    }).then(response => safeTransformData(response));
};

const getSocialTaxStats = ({freelancerId}) => {
    return bankApiInstance({
        method: 'GET',
        url: '/v3/bank/bank-integrations/stats/social-tax',
        params: {freelancerId},
    }).then(response => safeTransformData(response));
};


export const BankApi = {
    getBankIntegrations,
    getBankUserIntegrations,
    onboardCompany,
    generateSwanAuthUri,
    getBankCategories,
    getBankSources,
    getBankTransactions,
    getCombinedBankTransactions,
    getTransactionOverview,
    setTransactionAlertFlow,
    saveTransaction,
    saveTransactionField,
    validateTransactionVat,
    getTransaction,
    uploadTransactionFile,
    deleteBankFile,
    startKyc,
    generateRibFile,
    startCapitalDeposit,
    getCapitalDeposit,
    uploadCapitalDepositDocument,
    transitionCapitalDeposit,
    closeBankAccount,
    deleteBankAccount,
    archiveBankAccount,
    getRibDocumentData,
    getBankAccountStatements,
    downloadAllStatementsAsZip,
    getTransactionsMatchingSubscription,
    getEligibleTransactionsMatchingSubscription,
    activateSubscription,
    deactivateSubscription,
    getTransactionCategorizationStats,
    getBankDetails,
    getExpensesStats,
    getRemunerationStats,
    getSocialTaxStats,
};
