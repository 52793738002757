import {Auth} from 'aws-amplify';
import {generatePath, matchPath} from 'react-router-dom';
import {all, call, cancel, delay, fork, put, select, take} from 'redux-saga/effects';
import {watchEventSagas} from './app/modules/event/event.sagas';
import {watchAnimationsSaga} from './features/animations/store/animations.saga';
import {appEventSaga} from './features/app-event/store/app-event.saga';
import {initializeAuth, watchAuthSagas} from './features/auth/store/auth.sagas';
import {saveRedirectLocation} from './features/auth/store/loginForm/loginForm.actions';
import {watchBackofficeSaga} from './features/backoffice/store/backoffice.saga';
import {watchCardsSaga} from './features/bank/modules/cards/store/cards.saga';
import {bankFreelancerLoginAuthFlow} from './features/bank/store/bank.loader.saga';
import {watchBankSaga} from './features/bank/store/bank.saga';
import {watchChangePasswordSagas} from './features/change-password/store/changePasswordForm.sagas';
import {watchAccountingSaga} from './features/company-profile/modules/accounting/store/accounting.saga';
import {watchPayslipSaga} from './features/company-profile/modules/payslip/store/payslip.saga';
import {watchQuickAccess} from './features/company/modules/documents-quick-access/store/quick-access.saga';
import {watchCompanySagas} from './features/company/store/company.sagas';
import {watchSignContractSagas} from './features/contract/store/sign-contract/sign-contract.saga';
import {watchDashboardV3Saga} from './features/dashboard-v3/store/dashboard-v3.saga';
import {watchDashboardSaga} from './features/dashboard/store/dashboard.saga';
import {documentSaga} from './features/document/store/document.saga';
import {watchDocumentationSaga} from './features/documentation/store/documentation.saga';
import {watchExpensesSagas} from './features/expenses/store/expenses.sagas';
import {watchFinancialStatementsSaga} from './features/financial-statements/store/financial-statements.saga';
import {FreelancerSelectors} from './features/freelancer';
import {loadFreelancerCompanies} from './features/freelancer/modules/companies/store/companies.saga';
import {watchMyCompaniesSaga} from './features/freelancer/modules/my-companies/store/my-companies.sagas';
import {
    checkOnboardingFinalStatus,
    loadOnboardingData,
} from './features/freelancer/modules/onboarding/store/onboarding.saga';
import {loadFreelancerAccount, watchFreelancerSagas} from './features/freelancer/store/freelancer.sagas';
import {watchInsuranceSaga} from './features/insurance/store/insurance.saga';
import {watchInvoiceSaga} from './features/invoicing/store/invoice.saga';
import {professionAndSpecializationSaga} from './features/job-profession-and-specilties/store/profession-and-specialization.saga';
import {LoadingActions, LoadingTypes} from './features/loading';
import {watchMissionsCraSaga} from './features/missions/modules/cra/store/missions-cra.saga';
import {watchMissionsSigningSaga} from './features/missions/modules/missions-signing/store/missions-signing.saga';
import {watchMissionsSaga} from './features/missions/store/missions.saga';
import {runUserOnboarding} from './features/onboarding/store/main-onboarding-runner.saga';
import {watchMainOnboardingSaga} from './features/onboarding/store/main-onboarding.saga';
import {OnboardingStatus} from './features/onboarding/utils/onboarding.constants';
import {watchOpportunitiesSaga} from './features/opportunity/store/opportunity.saga';
import {revenueSimulatorSaga} from './features/revenue-simulator/store/revenue-simulator.saga';
import {runAuthSSETokens, watchStoreSaga} from './features/server-side-events/store/sse.saga';
import {watchSettingsSaga} from './features/settings/store/settings.sagas';
import {signatureSaga} from './features/signature/store/signature.saga';
import {boTrainingSaga} from './features/training-bo/store/bo-training.saga';
import {watchTrainingSaga} from './features/training/store/training.saga';
import {watchUmbrellaUsersListSaga} from './features/umbrella-users-list/store/umbrella-users-list.sagas';
import {
    LoggedInUserActionTypes,
    LoggedInUserSelectors,
    loadLoggedInUserAccount,
} from './features/user/modules/logged-in-user';
import {watchUserSagas} from './features/user/store/user.saga';
import {vatDeclarationsSaga} from './features/vat-declaration/store/vat-declaration.saga';
import {initPublicUserSaga} from './init/public-user-saga';
import {
    LOCATION_CHANGED,
    connectedRouteWatcher,
    push,
    replace,
    selectRouterLocation,
} from './lib/router/connected-router-saga';
import {PublicRoutePaths, RoutePaths} from './lib/router/route-paths';
import {Routes} from './lib/router/routes';
import {COOKIE_NAMES, getCookie} from './utils/cookie';
import {Debug} from './utils/debug';
import {UserRoles, isUserFn, isUserUmbrellaFn} from './utils/user-roles';

const getIsLoggedIn = function* () {
    let isUserLoggedIn = false;

    try {
        const cookieToken = getCookie(COOKIE_NAMES.MOBILE_SESSION_COOKIE);
        if (!cookieToken) {
            const session = yield call([Auth, Auth.currentSession]);

            isUserLoggedIn = session && !!session.getIdToken().getJwtToken();
        } else {
            isUserLoggedIn = true;
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    return isUserLoggedIn;
};

const ARIA_INVOICE_VALIDATION = 'aria-invoice-validation';

const loadAppData = function* () {
    const isLoggedIn = yield call(getIsLoggedIn);

    Debug.debug('loadAppData', `User is ${!isLoggedIn ? 'not ' : ''}logged in.`);

    if (!isLoggedIn) {
        yield put(LoadingActions.setLoading(LoadingTypes.APPLICATION_DATA, false));

        const location = yield select(selectRouterLocation);

        // Public routes - user is not redirected from them when not logged in
        if (
            location.pathname.includes(ARIA_INVOICE_VALIDATION)
            || matchPath(RoutePaths.REVENUE_SIMULATOR_RESULTS_PUBLIC, location.pathname)
            || location.pathname.includes(RoutePaths.PUBLIC_FILE_UPLOAD)
        ) {
            return;
        }

        yield put(saveRedirectLocation(location));

        yield put(push(RoutePaths.LOGIN));

        return;
    }

    Debug.debug('loadAppData', 'Fetching user\'s account.');

    yield call(loadLoggedInUserAccount);

    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    if (!loggedInUser) {
        Debug.error('loadAppData', 'The logged in user data is missing.');

        yield put(LoadingActions.setLoading(LoadingTypes.APPLICATION_DATA, false));

        return;
    }

    Debug.debug('loadAppData', 'Logged in user\'s account: ', loggedInUser);

    if (isUserFn(loggedInUser)) {
        Debug.debug('loadAppData', 'The logged in user is a freelancer. Loading freelancer account and companies...');

        if (!isUserUmbrellaFn(loggedInUser)) {
            yield all([
                call(loadFreelancerAccount, {freelancerId: loggedInUser.id}),
                call(loadFreelancerCompanies, {freelancerId: loggedInUser.id}),
            ]);
        } else {
            yield all([
                call(loadFreelancerAccount, {freelancerId: loggedInUser.id}),
            ]);
        }
    }
};

const freelancerAccessControl = function* () {
    while (true) {
        yield take(LOCATION_CHANGED);

        // Note - loggedInUser is taken before it is cleared on logout
        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
        const freelancer = yield select(FreelancerSelectors.selectAccount);
        const location = yield select(selectRouterLocation);

        const isUserUmbrella = isUserFn(loggedInUser);
        const onboardingRoute = isUserUmbrella ? RoutePaths.UMBRELLA_ONBOARDING : RoutePaths.ONBOARDING;

        if (!loggedInUser || !freelancer) {
            continue;
        }

        if (location.pathname === RoutePaths.LOGIN) {
            continue;
        }

        if (!loggedInUser.onboardingLaunchedStatus) {
            yield call(checkOnboardingFinalStatus,
                {freelancerId: loggedInUser.id, companyId: loggedInUser.defaultCompanyId});
        }

        const isNotPublicRoute = !Object.values(PublicRoutePaths).includes(location.pathname);

        // TODO At some point this will be for all the users
        if (isUserUmbrella) {
            if (loggedInUser?.onboardingStatus !== OnboardingStatus.COMPLETED) {
                yield call(runUserOnboarding);
            } else if (
                location.pathname === onboardingRoute
            ) {
                if (location?.pathname !== RoutePaths.MISSIONS) {
                    yield delay(20);
                    yield put(push(RoutePaths.MISSIONS));
                }
            }

            return;
        }

        // ONBOARDING SIDEBAR LOCATION CHANGE - Shorthand to find all places we need to change when adding new item
        if (((loggedInUser.status !== 'ACTIVE')
                || (loggedInUser.onboardingLaunchedStatus === 'PENDING'
                    || loggedInUser.onboardingLaunchedStatus === 'READY_FOR_LAUNCH'))
            && location.pathname !== onboardingRoute) {
            if (
                isNotPublicRoute
                && location.pathname !== RoutePaths.ADP
                && location.pathname !== RoutePaths.CGVS
                && location.pathname !== RoutePaths.SETTINGS
                && location.pathname !== RoutePaths.MY_PROFILE
                && location.pathname !== RoutePaths.MY_PROFILE_EDIT
                && location.pathname !== RoutePaths.MY_COMPANIES
                && location.pathname !== RoutePaths.MISSION_SEARCH
                && (location.pathname.indexOf(RoutePaths.CLUSTER) !== 0 || loggedInUser.status === 'CONTRACT_PENDING')
                && location.pathname.indexOf(generatePath(RoutePaths.DOCUMENTS, {
                    companyId: freelancer?.defaultCompanyId,
                })) !== 0
                && location.pathname !== generatePath(RoutePaths.MY_COMPANY, {
                    companyId: freelancer?.defaultCompanyId,
                })
                && location.pathname !== RoutePaths.CHANGE_PASSWORD
                && location.pathname !== RoutePaths.GUIDE_REPRISE_TRANSFORMATION_PDF
                && location.pathname.indexOf(RoutePaths.DOCUMENTATION) === -1
            ) {
                yield delay(20);
                yield put(push(onboardingRoute));
            } else if (loggedInUser) {
                yield call(loadOnboardingData);
            }
        }

        if (
            location.pathname === onboardingRoute
            && loggedInUser?.isFullyActive
        ) {
            yield delay(20);
            yield put(push(RoutePaths.DASHBOARD));
        }
    }
};

const freelancerFinalStepControl = function* () {
    while (true) {
        yield take(LoggedInUserActionTypes.STORE_ACCOUNT);

        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
        const freelancer = yield select(FreelancerSelectors.selectAccount);
        const location = yield select(selectRouterLocation);

        // If there is no user and it is not admin
        const isUserClient = isUserFn(loggedInUser);
        if (!loggedInUser || !freelancer || !isUserClient) {
            continue;
        }

        const isUserUmbrella = isUserUmbrellaFn(loggedInUser);

        if (isUserUmbrella) {
            if (location.pathname === RoutePaths.UMBRELLA_ONBOARDING
                && loggedInUser.onboardingStatus !== OnboardingStatus.COMPLETED) {
                yield put(push(RoutePaths.UMBRELLA_ONBOARDING));
            }
        } else if (location.pathname === RoutePaths.ONBOARDING
                && loggedInUser.onboardingLaunchedStatus
                && loggedInUser.onboardingLaunchedStatus !== 'PENDING'
                && loggedInUser.onboardingLaunchedStatus !== 'READY_FOR_LAUNCH') {
            yield put(push(RoutePaths.DASHBOARD));
        }
    }
};

const routerFn = function* () {
    let task = null;

    while (true) {
        let routes = [...Routes.PublicRoutes];

        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

        if (loggedInUser?.role === UserRoles.ADMINISTRATOR) {
            routes = [
                ...routes,
                ...Routes.AdministratorRoutes,
            ];
        } else if (isUserFn(loggedInUser)) {
            routes = [
                ...routes,
                ...Routes.FreelancerRoutes(),
                ...Routes.UmbrellaRoutes(),
            ];
        }

        task = yield fork(connectedRouteWatcher, routes);

        yield take(LoggedInUserActionTypes.STORE_ACCOUNT);

        if (task) {
            const location = yield select(selectRouterLocation);

            // We need to delay cancel on onboarding because some parts have delay
            if (location.pathname === RoutePaths.ONBOARDING && loggedInUser) {
                yield delay(20000);
            }

            // TODO Why do we have and need this?
            // This is cancelling side effect tasks
            yield cancel(task);
        }
    }
};

export const rootSaga = function* () {
    yield fork(routerFn);

    // TODO Detect if public user
    const location = yield select(selectRouterLocation);

    const isPublicUser = location?.query?.token && location?.query?.missionId;
    if (isPublicUser) {
        yield call(initPublicUserSaga, location);

        return;
    }


    yield call(initializeAuth);

    yield all([
        fork(watchAuthSagas),
        fork(watchUserSagas),
        fork(watchCompanySagas),
        fork(watchFreelancerSagas),
        fork(watchMyCompaniesSaga),
        fork(watchSignContractSagas),
        fork(watchEventSagas),
        fork(watchExpensesSagas),
        fork(signatureSaga),
        fork(documentSaga),
        fork(watchChangePasswordSagas),
        fork(watchSettingsSaga),
        fork(watchAnimationsSaga),
        fork(watchBackofficeSaga),
        fork(appEventSaga),
        fork(watchDashboardSaga),
        fork(watchOpportunitiesSaga),
        fork(professionAndSpecializationSaga),
        fork(watchTrainingSaga),
        fork(boTrainingSaga),
        fork(watchPayslipSaga),
        fork(watchBankSaga),
        fork(watchCardsSaga),
        fork(watchStoreSaga),
        fork(watchInvoiceSaga),
        fork(watchAccountingSaga),
        fork(watchInsuranceSaga),
        fork(watchDocumentationSaga),
        fork(watchQuickAccess),
        fork(revenueSimulatorSaga),
        fork(watchDashboardV3Saga),
        fork(watchFinancialStatementsSaga),
        fork(vatDeclarationsSaga),
        fork(watchMainOnboardingSaga),
        fork(watchMissionsSaga),
        fork(watchMissionsCraSaga),
        fork(watchMissionsSigningSaga),
        fork(watchUmbrellaUsersListSaga),
    ]);

    // If it is called before forks and some API call get stuck
    // it will run components and their useEffects before saga listeners are forked
    yield call(loadAppData);

    yield fork(freelancerAccessControl);
    yield fork(freelancerFinalStepControl);
    yield fork(bankFreelancerLoginAuthFlow);
    yield delay(300);

    yield fork(runAuthSSETokens);

    // TODO Check why it does not load freelancer-onboarding
    // Probably sagas working on location change that does not work on initial load
    // Workaround for initial load
    // It is needed to run side effect sagas for logged in users
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
    if (loggedInUser) {
        // We need special case for umbrella user that tries to access '/' as it does not exist for them
        if (isUserUmbrellaFn(loggedInUser) && (
            location?.pathname === RoutePaths.DASHBOARD
            || location?.pathname === RoutePaths.LOGIN
        )) {
            yield put(replace(RoutePaths.MISSIONS));
        } else {
            yield put(replace(location.pathname + location.search));
        }
    }

    yield put(LoadingActions.setLoading(LoadingTypes.APPLICATION_DATA, false));
};
