import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EuroIcon from '@mui/icons-material/Euro';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import PendingIcon from '@mui/icons-material/Pending';
import SendIcon from '@mui/icons-material/Send';
import {Stack} from '@mui/material';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {LocalesConstants} from '../../../utils/locales-constants';
import {DEPOSIT_OF_ACCOUNTS_STATUS} from '../../company-profile/modules/formalities/utils/constants';

const iconStyles = {
    fontSize: theme => theme.typography.pxToRem(20),
};

const icons = {
    [DEPOSIT_OF_ACCOUNTS_STATUS.NOT_STARTED]: (
        <AssignmentIcon sx={{...iconStyles, color: 'v2.light.info.main'}} />
    ),
    [DEPOSIT_OF_ACCOUNTS_STATUS.MISSING]: (
        <HourglassTopIcon sx={{...iconStyles, color: 'v2.light.warning.main'}} />
    ),
    [DEPOSIT_OF_ACCOUNTS_STATUS.AWAITING_PAYMENT]: (
        <EuroIcon sx={{...iconStyles, color: 'v2.light.warning.main'}} />
    ),
    [DEPOSIT_OF_ACCOUNTS_STATUS.AWAITING_SIGNATURE]: (
        <HourglassTopIcon sx={{...iconStyles, color: 'v2.light.warning.main'}} />
    ),
    [DEPOSIT_OF_ACCOUNTS_STATUS.READY_TO_SEND]: (
        <PendingIcon sx={{...iconStyles, color: 'v2.blueGray.300'}} />
    ),
    [DEPOSIT_OF_ACCOUNTS_STATUS.SENT_TO_INPI]: (
        <SendIcon sx={{...iconStyles, color: 'v2.light.primary.light'}} />
    ),
    [DEPOSIT_OF_ACCOUNTS_STATUS.REJECTED_BY_INPI]: (
        <CancelIcon sx={{...iconStyles, color: 'v2.light.error.main'}} />
    ),
    [DEPOSIT_OF_ACCOUNTS_STATUS.VALIDATED_BY_INPI]: (
        <CheckCircleIcon sx={{...iconStyles, color: 'v2.light.success.light'}} />
    ),
    [DEPOSIT_OF_ACCOUNTS_STATUS.ACTION_REQUIRED_ON_INPI]: (
        <AssignmentTurnedInIcon sx={{...iconStyles, color: 'v2.light.warning.main'}} />
    ),
    [DEPOSIT_OF_ACCOUNTS_STATUS.MISSING]: (
        <AssignmentIcon sx={{...iconStyles, color: 'v2.light.info.main'}} />
    ),
};

const Status = ({rowData}) => {
    const {annualAccountingStatus} = rowData;
    const {t} = useTranslation(LocalesConstants.FinancialStatements);

    return (
        <Stack direction="row" spacing={1} whiteSpace="nowrap">
            <span>{icons[annualAccountingStatus]}</span>
            <span>{t(`status.${annualAccountingStatus}`)}</span>
        </Stack>
    );
};

Status.propTypes = {
    rowData: PropTypes.object.isRequired,
};

export default Status;
