import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Button, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';

const ViewAllCategoriesButton = ({setIsShowAllCategories}) => {
    const {t} = useTranslation(LocalesConstants.Bank);

    return (
        <Box sx={{position: 'relative', mt: 1}}>
            <Box sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: '-8px',
                display: 'flex',
                justifyContent: 'space-around',
                zIndex: 1,
            }}
            >
                <Typography
                    variant="span"
                    sx={{
                        color: 'v2.blueGray.300',
                        fontSize: theme => theme.typography.pxToRem(10),
                        lineHeight: theme => theme.typography.pxToRem(13),
                        fontWeight: 400,
                        letterSpacing: '0.4px',
                        textTransform: 'uppercase',
                        textAlign: 'center',
                        backgroundColor: '#fff',
                        px: 0.5,
                        py: 0.25,
                        borderRadius: 6,
                    }}
                >
                    {t('categorisation.or')}
                </Typography>
            </Box>

            <Button
                fullWidth
                onClick={() => {
                    setIsShowAllCategories(true);
                }}
                sx={{
                    py: 1,
                    backgroundColor: 'v2.light.primary.shades4',
                    borderRadius: 2,
                }}
                variant="text"
                color="primary"
                endIcon={<ArrowForwardIcon />}
            >
                {t('categorisation.allCategories')}
            </Button>
        </Box>
    );
};

ViewAllCategoriesButton.propTypes = {
    setIsShowAllCategories: PropTypes.func.isRequired,
};

export default ViewAllCategoriesButton;
