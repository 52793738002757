import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Box, Button, useMediaQuery} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link as RouterLink, useParams} from 'react-router-dom';
import PageHeader from '../../../components/headers/PageHeader/PageHeader';
import AdvancedFrame from '../../../components/ui-kit/AdvancedFrame';
import {getConfig} from '../../../config/get-config';
import {RoutePaths} from '../../../lib/router/route-paths';
import {LocalesConstants} from '../../../utils/locales-constants';

export const DashboardDetails = () => {
    const {t} = useTranslation(LocalesConstants.Companies);
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const {year} = useParams();

    return (
        <Box sx={isMobileSize ? {p: 1} : {}}>
            <Button
                sx={{mb: 3}}
                variant="outlined"
                color="warning"
                component={RouterLink}
                to={RoutePaths.DASHBOARD}
            >
                <ArrowBackIcon sx={{mr: 1.5}} color="warning" />
                {t('returnToDashboard')}
            </Button>

            <PageHeader title={t('backoffice')} />

            <AdvancedFrame
                url={`${getConfig().legacyAppUrl}/backoffice/${year}?jwt=`}
                title={t('test')}
            />
        </Box>
    );
};

export default DashboardDetails;
