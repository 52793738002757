import {Stack} from '@mui/joy';
import {Box, CircularProgress} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {ComparisonTableSection} from './components/comparison-table/ComparisonTableSection';
import {ContactSection} from './components/ContactSection';
import {IncomeSection} from './components/income/IncomeSection';
import {InputsSummary} from './components/inputs-summary/InputsSummary';
import {LinkSection} from './components/LinkSection';
import {RoadmapSection} from './components/roadmap/RoadmapSection';
import {SimulationExpired} from './components/SimulationExpired';
import {Title} from './components/Title';
import {ReactComponent as HiwayText} from '../../../../assets/svg/logo/hiway.svg';
import JoyUIThemeProvider from '../../../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {useIsMobileSize} from '../../../../hooks/use-is-mobile-size';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {UserRoleColors} from '../../../../utils/user-roles';
import {RevenueSimulatorActions} from '../../store/revenue-simulator.action';
import {RevenueSimulatorSelectors} from '../../store/revenue-simulator.selector';

export const RevenueSimulatorResults = ({isPublic}) => {
    const {t} = useTranslation(LocalesConstants.RevenueSimulator);
    const {simulationId} = useParams();
    const dispatch = useDispatch();
    const simulationData = useSelector(RevenueSimulatorSelectors.selectSimulation);
    const isMobileSize = useIsMobileSize();

    useEffect(() => {
        dispatch(RevenueSimulatorActions.getResults(simulationId));
    }, [dispatch, simulationId]);

    if (!simulationData) {
        return (
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', mt: '40vh'}}>
                <CircularProgress />
            </Box>
        );
    }

    if (isPublic && simulationData.isExpired) {
        return <SimulationExpired />;
    }

    const {firstName} = simulationData.simulation.simulationInput;

    return (
        <JoyUIThemeProvider>
            <Box sx={{bgcolor: 'white'}}>
                {isPublic ? (
                    <Box maxWidth="xl" margin="0 auto" p={2} pt={5}>
                        <HiwayText
                            height={isMobileSize ? '44px' : '58px'}
                            width={isMobileSize ? '196px' : '256px'}
                            fill={UserRoleColors.FREELANCER}
                        />
                        <Title isPublic>{t('resultsFreelancerTitle', {firstName})}</Title>
                        <Stack spacing={5}>
                            <InputsSummary />
                            <IncomeSection />
                            <RoadmapSection />
                            <ComparisonTableSection />
                            <ContactSection />
                        </Stack>
                    </Box>
                ) : (
                    <>
                        <Title isPublic={false}>{t('resultsAdminTitle')}</Title>
                        <Stack spacing={5}>
                            <IncomeSection />
                            <RoadmapSection />
                            <ComparisonTableSection />
                            <LinkSection />
                        </Stack>
                    </>
                )}
            </Box>
        </JoyUIThemeProvider>
    );
};

RevenueSimulatorResults.propTypes = {
    isPublic: PropTypes.bool,
};

RevenueSimulatorResults.defaultProps = {
    isPublic: false,
};
