import {yupResolver} from '@hookform/resolvers/yup';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/Edit';
import {LoadingButton} from '@mui/lab';
import {Box, Checkbox, FormControlLabel, Typography} from '@mui/material';
import Link from '@mui/material/Link';
import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import Yup from '../../../../../lib/yup/yup';
import {LocalesConstants} from '../../../../../utils/locales-constants';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {ContractSelectors} from '../../../../contract/store/contract.selector';
import {SigningActions} from '../../../../document/modules/signing/store/signing.action';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';
import {SignatureSelectors} from '../../../../signature/store/signature.selector';
import {UiActions} from '../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../ui/utils/constants';
import {OnboardingActions} from '../../onboarding/store/onboarding.action';
import {ContractSigningInternalSubSteps} from '../utils/constants';

export const TermsAndConditionsForm = () => {
    const {t} = useTranslation(LocalesConstants.FreelancerOnboarding);

    const dispatch = useDispatch();

    const signatureUrl = useSelector(SignatureSelectors.selectUrl);
    const contractData = useSelector(ContractSelectors.selectData);
    const isSigning = useSelector(LoadingSelectors.createLoadingSelectorByType(LoadingTypes.SIGN_CONTRACT));

    const {handleSubmit: createSubmitHandler, control, setValue, formState: {isValid}} = useForm({
        defaultValues: {
            generalConditions: false,
            personalDataAgreement: false,
        },
        resolver: yupResolver(Yup.object({
            generalConditions: Yup.boolean().isTrue(),
            personalDataAgreement: Yup.boolean().isTrue(),
        })),
        mode: 'onChange',
        reValidateMode: 'onChange',
    });

    useEffect(() => {
        if (contractData && contractData.signedAt) {
            setValue('generalConditions', true);
            setValue('personalDataAgreement', true, {shouldValidate: true, shouldDirty: true});
        }
    }, [contractData, setValue]);

    const handleSubmit = () => {
        if (contractData.signedAt) {
            dispatch(AnimationActions.storeNextDispatch(
                OnboardingActions.setInternalSubStep(ContractSigningInternalSubSteps.FINAL_SCREEN),
            ));
            dispatch(AnimationActions.setIsAnimationActive(false));

            return;
        }

        if (signatureUrl) {
            dispatch(SigningActions.signContract());

            return;
        }

        dispatch(UiActions.setActiveModal(ModalsKeys.UPLOAD_SIGNATURE, true));
    };

    return (
        <Box sx={{mt: 2}}>
            <Typography variant="body2">
                {t('contractSigning.complianceNote')}
            </Typography>

            <form onSubmit={createSubmitHandler(handleSubmit)}>
                <Controller
                    name="generalConditions"
                    control={control}
                    render={({field}) => (
                        <FormControlLabel
                            label={(
                                <Link
                                    href={RoutePaths.CGVS}
                                    target="_blank"
                                >
                                    {t('contractSigning.generalConditionsTitle')}
                                </Link>
                              )}
                            componentsProps={{typography: {color: 'primary', variant: 'body2'}}}
                            sx={{mt: 1}}
                            control={
                                <Checkbox {...field} checked={field.value} />
                            }
                        />
                    )}
                />

                <Controller
                    name="personalDataAgreement"
                    control={control}
                    render={({field}) => (
                        <FormControlLabel
                            label={(
                                <Link
                                    href={RoutePaths.ADP}
                                    target="_blank"
                                >
                                    {t('contractSigning.personalDataAgreementTitle')}
                                </Link>
                              )}
                            componentsProps={{typography: {color: 'primary', variant: 'body2'}}}
                            sx={{mt: 1}}
                            control={
                                <Checkbox {...field} checked={field.value} />
                            }
                        />
                    )}
                />

                <LoadingButton
                    sx={{py: 0.75, borderRadius: 2, mt: 1, color: 'white'}}
                    startIcon={!contractData?.signedAt ? <EditIcon /> : null}
                    endIcon={contractData?.signedAt ? <ArrowForwardIcon /> : null}
                    color={contractData?.signedAt ? 'buttonSuccess' : 'secondary'}
                    loading={isSigning}
                    variant="contained"
                    size="small"
                    disabled={!isValid}
                    type="submit"
                    fullWidth
                >
                    {!contractData?.signedAt ? t('contractSigning.signContractButton') : t('contractSigning.continueButton')}
                </LoadingButton>
            </form>
        </Box>
    );
};
