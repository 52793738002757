import {call, put} from 'redux-saga/effects';
import {DOCUMENT_CATEGORIES} from '../../../../../../consts/document-constants';
import {getUmbrellaCompanyDocuments} from '../../api/umbrella/umbrella-company.api';
import * as companyActions from '../company-documents.actions';

const getUmbrellaCompanyDocumentsFlow = function* (userId, documentContext) {
    yield put(companyActions.setIsCompanyDocumentsLoading(true));

    const data = yield call(getUmbrellaCompanyDocuments, userId, documentContext);

    yield put(companyActions.storeCompanyDocuments(data));

    const atLastOnePayCategoryDocumentExists = !data
        ? false
        : (Object.values(data).some(document => DOCUMENT_CATEGORIES.PAY === document.category));

    yield put(companyActions.storeAtLastOnePayCategoryDocumentExists(atLastOnePayCategoryDocumentExists));
    yield put(companyActions.setIsCompanyDocumentsLoading(false));
};

export const umbrellaCompanyDocumentsLoaderSaga = function* ({payload}) {
    const {params: {userId}, documentContext} = payload || {};
    yield call(getUmbrellaCompanyDocumentsFlow, userId, documentContext);
};

export const getUmbrellaCompanyDocumentsWorker = function* ({payload}) {
    const {userId, documentContext} = payload;
    yield call(getUmbrellaCompanyDocumentsFlow, userId, documentContext);
};
