import {Box, Button, MenuItem, MenuList, Typography} from '@mui/joy';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {DateRangePicker} from './DateRange';
import withJoyUI from '../../../../components/joy-ui/joyui-theme-provider/JoyUiThemePRoviderWrapper';
import * as i18next from '../../../../lib/i18next';
import {LocalesConstants} from '../../../../utils/locales-constants';

i18next.importLocaleBundle('backoffice');

const today = moment();
const startOfMonth = today.clone().startOf('month');
const endOfMonth = today.clone().endOf('month');
const startOfPrevMonth = today.clone().subtract(1, 'month').startOf('month');
const endOfPrevMonth = today.clone().subtract(1, 'month').endOf('month');

// TODO Add memoization
const getMenuItemSelection = (startDate, endDate) => {
    if (!startDate || !endDate) {
        return {
            isThisMonth: false,
            isPrevMonth: false,
            isCustom: false,
            isNothingSelected: true,
        };
    }

    const isThisMonth = startDate.isSame(startOfMonth, 'day')
        && endDate.isSame(endOfMonth, 'day');

    const isPrevMonth = startDate.isSame(startOfPrevMonth, 'day')
        && endDate.isSame(endOfPrevMonth, 'day');

    const isCustom = !isThisMonth
        && !isPrevMonth;

    return {
        isThisMonth,
        isPrevMonth,
        isCustom,
        isNothingSelected: false,
    };
};

const menuItemStyles = {
    'borderRadius': 'sm',
    '&.Mui-selected': {
        backgroundColor: 'neutral.plainColor',
    },
    '&.MuiMenuItem-root:hover': {
        background: 'none',
    },
    '&.Mui-selected:hover': {
        backgroundColor: 'neutral.plainColor',
    },
};

const menuTextStyles = {
    'color': 'text.secondary',
    '.MuiMenuItem-root:hover > &': {
        color: 'text.primary',
    },
    '.Mui-selected > &': {
        color: 'primary.solidColor',
    },
    '.Mui-selected:hover > &': {
        color: 'primary.solidColor',
    },
};

export const DateRangeWithPredefinedOptions = withJoyUI(({
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange,
}) => {
    // TODO: Low Menu list and all options could be dynamic
    const menuItemSelect = getMenuItemSelection(startDate, endDate);

    const {t} = useTranslation(LocalesConstants.Backoffice);

    const onThisMonthClick = () => {
        onStartDateChange(startOfMonth);
        onEndDateChange(endOfMonth);
    };

    const onPreviousMonthClick = () => {
        onStartDateChange(startOfPrevMonth);
        onEndDateChange(endOfPrevMonth);
    };

    const onTodayClick = () => {
        onStartDateChange(today);
        onEndDateChange(today);
    };

    const onClearClick = () => {
        onStartDateChange(null);
        onEndDateChange(null);
    };

    return (
        <Box sx={{display: 'flex', backgroundColor: 'background.body'}}>
            <MenuList
                sx={{
                    backgroundColor: 'background.surface',
                    p: 2,
                }}
            >
                <MenuItem
                    selected={menuItemSelect.isThisMonth}
                    onClick={onThisMonthClick}
                    sx={menuItemStyles}
                >
                    <Typography
                        level="body-sm"
                        sx={menuTextStyles}
                    >
                        {t('datepicker.this_month')}
                    </Typography>
                </MenuItem>
                <MenuItem
                    selected={menuItemSelect.isPrevMonth}
                    onClick={onPreviousMonthClick}
                    sx={menuItemStyles}
                >
                    <Typography
                        level="body-sm"
                        sx={menuTextStyles}
                    >
                        {t('datepicker.prev_month')}
                    </Typography>
                </MenuItem>

                <MenuItem
                    selected={menuItemSelect.isCustom}
                    onClick={onTodayClick}
                    sx={menuItemStyles}
                >
                    <Typography
                        level="body-sm"
                        sx={menuTextStyles}
                    >
                        {t('datepicker.custom_range')}
                    </Typography>
                </MenuItem>

                <Button
                    sx={{
                        mt: 'auto',
                        color: 'text.secondary',
                    }}
                    color="neutral"
                    variant="outlined"
                    fullWidth
                    size="sm"
                    onClick={onClearClick}
                >
                    {t('datepicker.clear')}
                </Button>
            </MenuList>

            <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={onStartDateChange}
                onEndDateChange={onEndDateChange}
            />
        </Box>
    );
});

DateRangeWithPredefinedOptions.propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    onStartDateChange: PropTypes.func.isRequired,
    onEndDateChange: PropTypes.func.isRequired,
};

DateRangeWithPredefinedOptions.defaultProps = {
    startDate: null,
    endDate: null,
};
