import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import {Box, Button, CircularProgress, Grid, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import ReactRouterButtonLink from '../../../components/app/router/ReactRouterButtonLink';
import {DOCUMENT_STATUSES} from '../../../consts/document-constants';
import {AccountingActions} from '../../../features/company-profile/modules/accounting/store/accounting.action';
import {AccountingSelector} from '../../../features/company-profile/modules/accounting/store/accounting.selector';
import FormalityStatusTag from '../../../features/company-profile/modules/formalities/components/FormalityStatusTag';
import BalanceSheetSidebar from '../../../features/company-profile/modules/formalities/deposit-of-accounts/components/BalanceSheetSidebar';
import FlowStepBalanceSheetUpload from '../../../features/company-profile/modules/formalities/deposit-of-accounts/components/FlowStepBalanceSheetUpload';
import FlowStepDocumentSigning from '../../../features/company-profile/modules/formalities/deposit-of-accounts/components/FlowStepDocumentSigning';
import FlowStepFormalityStatus from '../../../features/company-profile/modules/formalities/deposit-of-accounts/components/FlowStepFormalityStatus';
import FlowStepINPI from '../../../features/company-profile/modules/formalities/deposit-of-accounts/components/FlowStepINPI';
import FlowStepPaymentStatus from '../../../features/company-profile/modules/formalities/deposit-of-accounts/components/FlowStepPaymentStatus';
import FlowStepUploadFinalFec from
    '../../../features/company-profile/modules/formalities/deposit-of-accounts/components/FlowStepUploadFinalFec';
import GenerateDocumentsModal from '../../../features/company-profile/modules/formalities/deposit-of-accounts/components/GenerateDocumentsModal';
import ManualModeModal from '../../../features/company-profile/modules/formalities/deposit-of-accounts/components/ManualModeModal';
import RegenerateDocumentsModal from '../../../features/company-profile/modules/formalities/deposit-of-accounts/RegenerateDocumentsModal';
import {DEPOSIT_OF_ACCOUNTS_BALANCE_SHEET_REPLACEABLE_STATUS,
    DEPOSIT_OF_ACCOUNTS_FLOW,
    DEPOSIT_OF_ACCOUNTS_STATUS} from '../../../features/company-profile/modules/formalities/utils/constants';
import {selectCompany} from '../../../features/company/store/company.selectors';
import {LoadingSelectors, LoadingTypes} from '../../../features/loading';
import {UiActions} from '../../../features/ui/store/ui.action';
import {ModalsKeys} from '../../../features/ui/utils/constants';
import {resolveRoute} from '../../../lib/router/resolveRoute';
import {RoutePaths} from '../../../lib/router/route-paths';
import {LocalesConstants} from '../../../utils/locales-constants';

const DepositOfAccountsFlowScreen = () => {
    const {companyId} = useParams();
    const company = useSelector(selectCompany);

    const [searchParams, setSearchParams] = useSearchParams();
    const annualAccountId = searchParams.get('annualAccountId');
    const backTo = searchParams.get('backTo');
    const year = searchParams.get('year');

    const dispatch = useDispatch();
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const annualAccounts = useSelector(AccountingSelector.selectAnnualAccounts);
    const annualAccount = annualAccounts[year];
    const status = annualAccount?.status ?? DEPOSIT_OF_ACCOUNTS_STATUS.MISSING;
    const manualMode = annualAccount?.manual
        && status === DEPOSIT_OF_ACCOUNTS_STATUS.VALIDATED_BY_INPI;

    const backLink = backTo === 'table'
        ? resolveRoute(RoutePaths.FINANCIAL_STATEMENTS)
        : resolveRoute(backTo);

    const backLinkWithYear = backTo === 'table'
        ? `${backLink}?year=${year}`
        : backLink;

    const [flowStep, setFlowStep] = useState(null);
    const latestBalanceSheet = useMemo(() => annualAccount?.balanceSheetDocument ?? {}, [annualAccount]);
    const [balanceSheetDetailsOpen, setBalanceSheetDetailsOpen] = useState(false);

    const isLoadingAnnualAccount = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.GET_ANNUAL_ACCOUNTS),
    );

    /*
     * Prop optimisation
     * */
    const finalFecHash = JSON.stringify(annualAccount?.finalFec);
    const finalFec = useMemo(() => {
        return annualAccount?.finalFec;
    },
    /*
    * Excluded deps:
    * - annualAccount - we want to change this only when
    *                   final FEC is actually different and
    *                   avoid when annualAccount is refreshed
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [finalFecHash]);

    const handleOpenBalanceSheetDetails = useCallback(() => {
        setBalanceSheetDetailsOpen(true);
    }, []);

    useEffect(() => {
        // When id is missing we need to initialise the annual accounts instead
        if (!annualAccountId) {
            dispatch(AccountingActions.initialiseAnnualAccounts({
                companyId,
                year,
            }));
        } else {
            dispatch(AccountingActions.getAnnualAccountById({
                companyId,
                annualAccountId,
            }));
        }
    }, [dispatch, companyId, annualAccountId, year]);

    useEffect(() => {
        // After initialising we need to add param to the URL
        // in order to prevent issues when refreshing the page

        if (annualAccount && !annualAccountId) {
            setSearchParams(searchParams => {
                searchParams.set('annualAccountId', annualAccount.id);
                return searchParams;
            });
        }
    }, [annualAccount, annualAccountId, setSearchParams]);

    // Determine flow step
    useEffect(() => {
        // Pre-flow step
        if (!annualAccount?.balanceSheetId) {
            setFlowStep(DEPOSIT_OF_ACCOUNTS_FLOW.UPLOAD_REPORT);
        } else {
            // Determine other steps,
            // for now it's upload FEC:
            setFlowStep(prevStep => {
                const areAllDocumentsSigned = annualAccount?.generatedDocuments
                    .reduce((result, doc) => result && doc.docStatus === DOCUMENT_STATUSES.SIGNED, true);

                if (!annualAccount?.finalFec) {
                    return DEPOSIT_OF_ACCOUNTS_FLOW.UPLOAD_FEC;
                }

                if (annualAccount?.status === DEPOSIT_OF_ACCOUNTS_STATUS.NOT_STARTED
                        && annualAccount?.generatedDocuments?.length === 0) {
                    return DEPOSIT_OF_ACCOUNTS_FLOW.UPLOAD_FEC;
                }

                // Documents generated
                if (annualAccount?.generatedDocuments?.length > 0
                    && (annualAccount?.status === DEPOSIT_OF_ACCOUNTS_STATUS.AWAITING_SIGNATURE
                    || annualAccount?.status === DEPOSIT_OF_ACCOUNTS_STATUS.NOT_STARTED
                    || !areAllDocumentsSigned)) {
                    return DEPOSIT_OF_ACCOUNTS_FLOW.SIGNING_DOCUMENTS;
                }

                if (annualAccount?.status === DEPOSIT_OF_ACCOUNTS_STATUS.AWAITING_PAYMENT) {
                    return DEPOSIT_OF_ACCOUNTS_FLOW.FORMALITY_PAYMENT;
                }

                // INPI block
                if (annualAccount?.status === DEPOSIT_OF_ACCOUNTS_STATUS.READY_TO_SEND
                    || annualAccount?.status === DEPOSIT_OF_ACCOUNTS_STATUS.SENT_TO_INPI
                    || annualAccount?.status === DEPOSIT_OF_ACCOUNTS_STATUS.REJECTED_BY_INPI
                    || annualAccount?.status === DEPOSIT_OF_ACCOUNTS_STATUS.ACTION_REQUIRED_ON_INPI
                    || annualAccount?.status === DEPOSIT_OF_ACCOUNTS_STATUS.VALIDATED_BY_INPI) {
                    return DEPOSIT_OF_ACCOUNTS_FLOW.READY_TO_SEND;
                }

                return prevStep;
            });
        }
    }, [dispatch, annualAccount]);

    if (!annualAccount) {
        return (
            <Box sx={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <Header
                    company={company}
                    isManualMode={manualMode}
                    status={status}
                    backLinkWithYear={backLinkWithYear}
                />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        overflow: 'auto',
                        ...(manualMode && {
                            height: '100%',
                        }),
                    }}
                >
                    {!manualMode && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                height: 'min-content',
                                width: '100%',
                                gap: 2,
                                my: 4,
                            }}
                        >
                            <FlowStepBalanceSheetUpload
                                isActive={flowStep >= DEPOSIT_OF_ACCOUNTS_FLOW.UPLOAD_REPORT}
                                isFocused={flowStep === DEPOSIT_OF_ACCOUNTS_FLOW.UPLOAD_REPORT}
                                annualAccountsId={annualAccount?.id ?? ''}
                                balanceSheet={latestBalanceSheet}
                                isBalanceSheetLocked={!DEPOSIT_OF_ACCOUNTS_BALANCE_SHEET_REPLACEABLE_STATUS
                                    .includes(annualAccount?.status)}
                                onBalanceSheetReset={() => {
                                    setFlowStep(DEPOSIT_OF_ACCOUNTS_FLOW.UPLOAD_REPORT);
                                }}
                            />
                            <FlowStepUploadFinalFec
                                isActive={flowStep >= DEPOSIT_OF_ACCOUNTS_FLOW.UPLOAD_FEC}
                                isFocused={flowStep === DEPOSIT_OF_ACCOUNTS_FLOW.UPLOAD_FEC}
                                annualAccountId={annualAccount.id}
                                status={annualAccount.status}
                                finalFec={finalFec}
                                areDocumentsGenerated={annualAccount?.generatedDocuments?.length
                                    && annualAccount.generatedDocuments.length > 0}
                                onOpenBalanceSheetDetails={handleOpenBalanceSheetDetails}
                                isRefreshingAnnualAccount={isLoadingAnnualAccount}
                                balanceSheetAdditionalInfo={annualAccount.balanceSheetAdditionalInfo}
                            />
                            <FlowStepDocumentSigning
                                isActive={flowStep >= DEPOSIT_OF_ACCOUNTS_FLOW.SIGNING_DOCUMENTS}
                                isFocused={flowStep === DEPOSIT_OF_ACCOUNTS_FLOW.SIGNING_DOCUMENTS}
                                annualAccount={annualAccount}
                            />
                            <FlowStepPaymentStatus
                                isActive={flowStep >= DEPOSIT_OF_ACCOUNTS_FLOW.FORMALITY_PAYMENT}
                                isFocused={flowStep === DEPOSIT_OF_ACCOUNTS_FLOW.FORMALITY_PAYMENT}
                                annualAccount={annualAccount}
                            />
                            <FlowStepINPI
                                isActive={flowStep >= DEPOSIT_OF_ACCOUNTS_FLOW.READY_TO_SEND}
                                isFocused={flowStep === DEPOSIT_OF_ACCOUNTS_FLOW.READY_TO_SEND}
                                status={status}
                                companyId={companyId}
                                annualAccountId={annualAccount?.id}
                            />
                        </Box>
                    )}
                    {manualMode && (
                        <Box
                            sx={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <FlowStepFormalityStatus />
                        </Box>
                    )}
                </Box>
            </Box>
            <BalanceSheetSidebar
                isOpen={balanceSheetDetailsOpen}
                onClose={() => {
                    setBalanceSheetDetailsOpen(false);
                }}
                isMobileSize={isMobileSize}
                inFlow
                overrideHeader={(
                    <Box sx={{px: 2, py: 3}}>
                        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                            <Typography sx={{fontSize: '22px'}}>Calculs comptables</Typography>
                            <Box
                                sx={{
                                    backgroundColor: 'v2.blueGray.50',
                                    borderRadius: '50%',
                                    p: 0.5,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <CloseIcon sx={{color: 'v2.blueGray.600'}} />
                            </Box>
                        </Box>
                    </Box>
                )}
            />
            <ManualModeModal annualAccount={annualAccount} />
            <GenerateDocumentsModal />
            <RegenerateDocumentsModal />
        </>
    );
};

export default DepositOfAccountsFlowScreen;

const Header = ({company, isManualMode, status, backLinkWithYear}) => {
    const {t} = useTranslation(LocalesConstants.Companies);
    const dispatch = useDispatch();

    return (
        <Grid
            container
            item
            wrap="nowrap"
            sx={{
                display: 'flex',
                px: 2,
                py: 2,
                backgroundColor: '#fff',
                alignItems: 'center',
                gap: 2,
                borderBottomSize: '1px',
                borderBottomColor: 'v2.blueGray.50',
                borderBottomStyle: 'solid',
                width: '100%',
                height: 'auto',
            }}
        >
            <Grid item xs={4}>
                <ReactRouterButtonLink
                    to={backLinkWithYear}
                    color="primary"
                    startIcon={<ArrowBackIcon />}
                >
                    {t('common:back')}
                </ReactRouterButtonLink>
            </Grid>
            <Grid container item xs={4} sx={{display: 'flex', justifyContent: 'center', gap: 0, textAlign: 'center'}}>
                <Typography variant="h6" sx={{fontWeight: 700}}>
                    {company?.name}:&nbsp;
                </Typography>
                <Typography variant="h6" sx={{fontWeight: 700}}>
                    {t('formalities.depositOfAccounts.title')}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'right',
                        gap: 2,
                    }}
                >
                    {!isManualMode && status === DEPOSIT_OF_ACCOUNTS_STATUS.NOT_STARTED && (
                    <Button
                        color="primary"
                        size="small"
                        variant="text"
                        startIcon={<CheckCircleIcon />}
                        onClick={() => {
                            dispatch(UiActions.setActiveModal(ModalsKeys.DEPOSIT_OF_ACCOUNTS_MANUAL_MODE, true));
                        }}
                    >
                        {t('formalities.depositOfAccounts.manualMode.openDialogButton')}
                    </Button>
                    )}
                    {status && (
                        <FormalityStatusTag status={status} />
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};

Header.propTypes = {
    company: PropTypes.object.isRequired,
    isManualMode: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    backLinkWithYear: PropTypes.string.isRequired,
};
