import {all, call, fork, put, select} from 'redux-saga/effects';
// eslint-disable-next-line import/no-cycle
import {OnboardingProgress} from './onboarding-progress.saga';
import {onboardingStepSetter} from './onboarding-step.saga';
import {OnboardingActions} from './onboarding.action';
import {OnboardingSelectors} from './onboarding.selectors';
import {generateDocumentsRequest} from '../../../../../app/api/providers/documents/documents.provider';
import {DOCUMENT_TYPES} from '../../../../../consts/document-constants';
import {Debug} from '../../../../../utils/debug';
import {isUserCareFn} from '../../../../../utils/user-roles';
import {UserStatuses} from '../../../../../utils/user-statuses';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {BankActions} from '../../../../bank/store/bank.action';
// eslint-disable-next-line import/no-cycle
import {bankCareFinalizeOnboardingFlow} from '../../../../bank/store/bank.loader.saga';
import {VerificationStatus} from '../../../../bank/utils/constants';
import {FULL_COMPANY_STATUSES} from '../../../../company/modules/setup-company/utils/constants';
import {loadContractData} from '../../../../contract/store/contract.saga';
import {DocumentApi} from '../../../../document/api/document.api';
import {DatabaseApi} from '../../../../document/modules/database/api/database.api';
import {DatabaseActions} from '../../../../document/modules/database/store/database.action';
import {delayedLoadDocuments, loadDocuments} from '../../../../document/modules/database/store/database.saga';
import {DatabaseSelectors} from '../../../../document/modules/database/store/database.selector';
import {DocumentContexts} from '../../../../document/modules/database/utils/constants';
import {DocumentActions} from '../../../../document/store/document.action';
import {InsuranceActions} from '../../../../insurance/store/insurance.action';
import {InsuranceSelectors} from '../../../../insurance/store/insurance.selector';
import {InsuranceStatus} from '../../../../insurance/utils/constants';
import {LoadingActions, LoadingTypes} from '../../../../loading';
import {LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';
import {
    CapitalDepositInternalSubSteps,
    CapitalDepositStatus, FinalizationSubsteps,
    KycIdentificationStatus,
} from '../../capital-deposit/utils/constants';
import {CompanyRegistrationInternalSubSteps} from '../../company-registration/utils/constants';
import {ContractSigningInternalSubSteps} from '../../contract-signing/utils/constants';
import {WorkshopsInternalSubSteps} from '../../workshops/utils/constants';
import {OnboardingSteps} from '../utils/onboadingStepsConstant';

const shouldUserLandOnWelcomeScreen = loggedInUser => {
    return loggedInUser.status === UserStatuses.CONTRACT_PENDING;
};

const shouldUserLandOnSigningBatch1 = loggedInUser => {
    return loggedInUser.status === UserStatuses.CONTRACT_SIGNED;
};

const shouldUserLandOnWorkshopScreen = ({loggedInUser, company}) => {
    return (loggedInUser.status === UserStatuses.WORKSHOPS_SCHEDULED
            && company.status === FULL_COMPANY_STATUSES.PENDING_ENABLE_SETUP)
    || (loggedInUser.status === UserStatuses.COMPANY_PENDING
            && company.status === FULL_COMPANY_STATUSES.PENDING_SETUP_START);
};

const shouldUserLandOnCapitalDeposit = ({registrationWithExistingCompany, company, deposit}) => {
    return !registrationWithExistingCompany
        && ((company.status === FULL_COMPANY_STATUSES.REGISTRATION_NOT_PREPARED
                || company.status === FULL_COMPANY_STATUSES.REGISTRATION_PARTIALLY_PREPARED
                || company.status === FULL_COMPANY_STATUSES.REGISTRATION_FINALIZATION
                // || company.status === FULL_COMPANY_STATUSES.REGISTRATION_VALIDATED // TODO Check if this exists
                || company.status === FULL_COMPANY_STATUSES.REGISTRATION_LAUNCHED)
            // The condition below is related only to the capital deposit onboarding flow
            || (
                company.status === FULL_COMPANY_STATUSES.REGISTRATION_COMPLETED
                && !!deposit?.status
                && ![
                    CapitalDepositStatus.WAITING_FOR_PROOF_OF_REGISTRATION_UPLOAD,
                    CapitalDepositStatus.WAITING_FOR_NOTARY_TRANSFER,
                    CapitalDepositStatus.COMPLETED,
                    CapitalDepositStatus['NOTARY_DEPOSIT_CERTIFICATE_UPLOADED'], // TODO Check what to do for this status
                ].includes(deposit?.status)
            ));
};

const isUserPendingCompany = loggedInUser => {
    return loggedInUser.status === UserStatuses.COMPANY_PENDING;
};

const isUserActive = loggedInUser => {
    return loggedInUser.status === UserStatuses.ACTIVE;
};

const isCompanyPendingDataInput = company => {
    return company?.status === FULL_COMPANY_STATUSES.PENDING_DATA_INPUT;
};

const isCompanyPendingInitSigs = company => {
    return company?.status === FULL_COMPANY_STATUSES.PENDING_INIT_SIGS;
};

const isCompanyReadyForReview = company => {
    return company?.status === FULL_COMPANY_STATUSES.READY_FOR_REVIEW;
};

const isCompanyDataValidated = company => {
    return company?.status === FULL_COMPANY_STATUSES.DATA_VALIDATED;
};

const isCompanyRegistrationCompleted = company => {
    return company.status === FULL_COMPANY_STATUSES.REGISTRATION_COMPLETED;
};

const isCompanyPendingFinalSigs = company => {
    return company.status === FULL_COMPANY_STATUSES.PENDING_FINAL_SIGS;
};

const handleUserWelcomeScreen = function* ({loggedInUser, progress}) {
    // Set step
    yield all([
        put(OnboardingActions.setStep(OnboardingSteps.CONTRACT_SIGNING)),
        put(OnboardingActions.setInternalSubStep(ContractSigningInternalSubSteps.INITIAL_SCREEN)),
    ]);

    // Set progress
    yield call(OnboardingProgress.setUserOnWelcomePage, {loggedInUser, progress});

    // Set loader
    yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, false));
};

const handleUserSignedBatch1 = function* ({progress}) {
    // Set step
    yield all([
        put(OnboardingActions.setStep(OnboardingSteps.CONTRACT_SIGNING)),
        put(OnboardingActions.setInternalSubStep(ContractSigningInternalSubSteps.FINAL_SCREEN)),
        call(loadContractData),
    ]);

    // Set progress
    yield call(OnboardingProgress.setUserSignedContractOnContractSigning, {progress});

    // Set loader
    yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, false));
};

const handleUserWorkshopScreen = function* ({progress}) {
    // Set step
    yield all([
        put(OnboardingActions.setStep(OnboardingSteps.WORKSHOPS)),
        put(OnboardingActions.setInternalSubStep(WorkshopsInternalSubSteps.INITIAL_SCREEN)),
    ]);

    // Set progress
    yield call(OnboardingProgress.setUserFinishedContractSigning, {progress});

    // Set loader
    yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, false));
};

const handleUserCapitalDepositScreen = function* ({
    freelancer,
    company,
    bankIntegrationDataList,
    kycStatus,
    progress,
    deposit,
    individualBankAccountHolders,
}) {
    yield put(OnboardingActions.setSubStep(CapitalDepositInternalSubSteps.INITIAL_SCREEN));
    yield put(OnboardingActions.setInternalSubStep(
        CapitalDepositInternalSubSteps.INITIAL_SCREEN,
    ));

    const capitalDepositResult = yield call(DocumentApi.getDepositDocumentsRequest,
        {
            freelancerId: freelancer.id,
            companyId: company.id,
        });

    yield put(DocumentActions.storeDepositDocuments(capitalDepositResult.data));

    yield put(OnboardingActions.setProgress({
        ...progress,
        [OnboardingSteps.CONTRACT_SIGNING]: {
            ...progress[OnboardingSteps.CONTRACT_SIGNING],
            isCompleted: true,
        },
        [OnboardingSteps.WORKSHOPS]: {
            ...progress[OnboardingSteps.WORKSHOPS],
            isCompleted: true,
        },
        [OnboardingSteps.COMPANY_SETUP]: {
            ...progress[OnboardingSteps.COMPANY_SETUP],
            isCompleted: true,
        },
    }));

    // If there is a bank integration at this point
    // It should mean that we should apply the capital deposit onboarding flow logic
    if (bankIntegrationDataList?.[0]) {
        // KYC status is only relevant for Capital deposit flow
        if (!kycStatus
            || (kycStatus !== KycIdentificationStatus.VERIFIED)) {
            yield put(OnboardingActions.setStep(OnboardingSteps.CAPITAL_DEPOSIT));
            yield put(OnboardingActions.setSubStep(CapitalDepositInternalSubSteps.INITIAL_SCREEN));
            yield put(OnboardingActions.setInternalSubStep(
                CapitalDepositInternalSubSteps.INITIAL_SCREEN,
            ));

            return {capitalDepositResult};
        }

        yield put(OnboardingActions.setStep(OnboardingSteps.CAPITAL_DEPOSIT));

        // Identity verification screen
        if (
            deposit?.status === CapitalDepositStatus.WAITING_FOR_INDIVIDUAL_ACCOUNT_HOLDER_VERIFICATION
        ) {
            // If shareholder is WAITING_FOR_VERIFICATION or VERIFIED with IBAN he can see fund deposit
            // As per HPD-3762
            if ((individualBankAccountHolders?.verificationStatus
                    === VerificationStatus.WAITING_FOR_VERIFICATION
                    || individualBankAccountHolders?.status === VerificationStatus.VERIFIED)
                && individualBankAccountHolders?.bankAccount?.[0]?.iban) {
                yield call(onboardingStepSetter.capitalDeposit.goToBankCDFundTransfer, {progress});
            } else {
                yield call(onboardingStepSetter.capitalDeposit.goToBankCDIdentityValidation, {progress});
            }

            yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, false));
            return {capitalDepositResult};
        }

        // Fund deposit
        if (deposit?.status === CapitalDepositStatus.WAITING_FOR_SHAREHOLDERS_FUND_DEPOSIT) {
            yield call(onboardingStepSetter.capitalDeposit.goToBankCDFundTransfer, {progress});

            yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, false));
            return {capitalDepositResult};
        }

        if (individualBankAccountHolders) {
            // Validation
            if (
                deposit?.status === CapitalDepositStatus.WAITING_FOR_REQUIREMENTS_APPROVAL
                || deposit?.status === CapitalDepositStatus.UPLOADED_DOCUMENTS_REFUSED
                || deposit?.status === CapitalDepositStatus.WAITING_FOR_NOTARY_DEPOSIT_CERTIFICATE
                || deposit?.status === CapitalDepositStatus.NOTARY_DEPOSIT_CERTIFICATE_UPLOADED
                || deposit?.status === CapitalDepositStatus.WAITING_FOR_NOTARY_TRANSFER
            ) {
                yield call(onboardingStepSetter.capitalDeposit.goToBankCDDepositValidation, {progress});

                yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, false));

                return {};
            }
        }
    } else {
        yield put(OnboardingActions.setStep(OnboardingSteps.CAPITAL_DEPOSIT));
        yield put(OnboardingActions.setSubStep(CapitalDepositInternalSubSteps.DEPOSIT_DOCUMENTATION_UPLOAD));
        yield put(OnboardingActions.setInternalSubStep(
            CapitalDepositInternalSubSteps.DEPOSIT_DOCUMENTATION_UPLOAD,
        ));
    }

    return {capitalDepositResult};
};


const handleUserCompanyRegistration = function* ({progress, capitalDepositResult}) {
    const hasCapitalDeposit = capitalDepositResult.data.find(file => file.doc_type === DOCUMENT_TYPES.CAPITAL_DEPOSIT);

    if (hasCapitalDeposit) {
        yield put(OnboardingActions.setStep(OnboardingSteps.COMPANY_REGISTRATION));
        yield put(OnboardingActions.setSubStep(CompanyRegistrationInternalSubSteps.INITIAL_SCREEN));
        yield put(OnboardingActions.setInternalSubStep(
            CompanyRegistrationInternalSubSteps.INITIAL_SCREEN,
        ));

        yield put(OnboardingActions.setProgress({
            ...progress,
            [OnboardingSteps.CONTRACT_SIGNING]: {
                ...progress[OnboardingSteps.CONTRACT_SIGNING],
                isCompleted: true,
            },
            [OnboardingSteps.WORKSHOPS]: {
                ...progress[OnboardingSteps.WORKSHOPS],
                isCompleted: true,
            },
            [OnboardingSteps.COMPANY_SETUP]: {
                ...progress[OnboardingSteps.COMPANY_SETUP],
                isCompleted: true,
            },
            [OnboardingSteps.CAPITAL_DEPOSIT]: {
                ...progress[OnboardingSteps.CAPITAL_DEPOSIT],
                isCompleted: true,
            },
        }));

        yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, false));
    }
};

const handleCareUserCompanyRegistration = function* ({progress}) {
    yield put(OnboardingActions.setStep(OnboardingSteps.COMPANY_REGISTRATION));
    yield put(OnboardingActions.setSubStep(CompanyRegistrationInternalSubSteps.INITIAL_SCREEN));

    const registrationCompletedProgress = {
        ...progress,
        [OnboardingSteps.CONTRACT_SIGNING]: {
            ...progress[OnboardingSteps.CONTRACT_SIGNING],
            isCompleted: true,
        },
        [OnboardingSteps.WORKSHOPS]: {
            ...progress[OnboardingSteps.WORKSHOPS],
            isCompleted: true,
        },
        [OnboardingSteps.COMPANY_SETUP]: {
            ...progress[OnboardingSteps.COMPANY_SETUP],
            isCompleted: true,
        },
        [OnboardingSteps.CAPITAL_DEPOSIT]: {
            ...progress[OnboardingSteps.CAPITAL_DEPOSIT],
            isCompleted: true,
        },
    };

    yield put(OnboardingActions.setProgress(registrationCompletedProgress));

    yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, false));
};

const handleCompanyRegistrationCompleted = function* ({
    progress,
    hasIntegration,
    freelancer,
    company,
    loggedInUser,
}) {
    const isKbisUploaded = !!(yield select(
        DatabaseSelectors.createDocumentByTypeSelector(DOCUMENT_TYPES.KBIS),
    ));
    const isUserCareRole = isUserCareFn(loggedInUser);

    const registrationCompletedProgress = {
        ...progress,
        [OnboardingSteps.CONTRACT_SIGNING]: {
            ...progress[OnboardingSteps.CONTRACT_SIGNING],
            isCompleted: true,
        },
        [OnboardingSteps.WORKSHOPS]: {
            ...progress[OnboardingSteps.WORKSHOPS],
            isCompleted: true,
        },
        [OnboardingSteps.COMPANY_SETUP]: {
            ...progress[OnboardingSteps.COMPANY_SETUP],
            isCompleted: true,
        },
        [OnboardingSteps.CAPITAL_DEPOSIT]: {
            ...progress[OnboardingSteps.CAPITAL_DEPOSIT],
            isCompleted: true,
        },
    };

    // As per HPD-5401 it should skip iban bic form and call stuff it previously called on button click
    if (hasIntegration && isUserCareRole) {
        yield call(bankCareFinalizeOnboardingFlow);
        return;
    }

    if (hasIntegration && !!isKbisUploaded) {
        yield call(loadDocuments, {
            freelancerId: freelancer.id,
            companyId: company.id,
            context: DocumentContexts.SIGNABLE,
        });

        yield put(OnboardingActions.setStep(OnboardingSteps.FINAL_POINTS));
        yield put(OnboardingActions.setSubStep(FinalizationSubsteps.PENDING_FINALIZATION));
        yield put(OnboardingActions.setProgress({
            ...registrationCompletedProgress,
            [OnboardingSteps.COMPANY_REGISTRATION]: {
                ...progress[OnboardingSteps.COMPANY_REGISTRATION],
                isCompleted: true,
            },
        }));
    } else {
        yield put(OnboardingActions.setStep(OnboardingSteps.COMPANY_REGISTRATION));
        yield put(OnboardingActions.setSubStep(CompanyRegistrationInternalSubSteps.IBAN_BIC_FORM));
        yield put(OnboardingActions.setInternalSubStep(
            CompanyRegistrationInternalSubSteps.IBAN_BIC_FORM,
        ));

        yield put(OnboardingActions.setProgress({
            ...registrationCompletedProgress,
        }));
    }

    // TODO Check if I need it again?
    // Needed if user decides to go back in menu
    // Fetch bank integration data
    // const bankIntegrationDataList = yield call(getBankIntegrationsFlow);
    //
    // // Store bank integration data
    // yield put(BankActions.storeIntegrationData(bankIntegrationDataList));

    // Get capital deposit info
    if (!isUserCareFn(loggedInUser)) {
        yield put(BankActions.getCapitalDeposit());
    }
};

const handleCompanyRegistrationCompletedCare = function* ({
    isAccountCompleted,
    isAccountVerified,
    registrationCompletedProgress,
    loggedInUser,
    company,
}) {
    // CLear documents so user does not see invalid
    yield put(DatabaseActions.clearDocuments());

    const progress = yield select(OnboardingSelectors.selectProgress);

    if (isAccountCompleted && isAccountVerified) {
        // When there is integration this belongs to 6th step
        yield put(OnboardingActions.setStep(OnboardingSteps.FINAL_POINTS));
        yield put(OnboardingActions.setSubStep(FinalizationSubsteps.PENDING_FINALIZATION));
        yield put(OnboardingActions.setProgress({
            ...registrationCompletedProgress,
            [OnboardingSteps.CONTRACT_SIGNING]: {
                isCompleted: true,
            },
            [OnboardingSteps.WORKSHOPS]: {
                isCompleted: true,
            },
            [OnboardingSteps.COMPANY_REGISTRATION]: {
                isCompleted: true,
            },
            [OnboardingSteps.COMPANY_SETUP]: {
                isCompleted: true,
            },
            [OnboardingSteps.CAPITAL_DEPOSIT]: {
                isCompleted: true,
            },
        }));

        yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, false));

        const documents = yield call(DatabaseApi.getDocuments, {
            freelancerId: loggedInUser.id,
            companyId: company.id,
            context: DocumentContexts.STAGE_TWO,
        });

        if (Object.values(documents)?.length === 0) {
            const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

            const freelancerId = loggedInUser?.id;
            const companyId = loggedInUser?.defaultCompanyId;

            // Generate documents for stage 2
            try {
                yield fork(generateDocumentsRequest, freelancerId, companyId, 2);
            } catch (e) {
                Debug.error('Generate documenst request onboarding helper', 'Error: ', {e});
            }

            progress[OnboardingSteps.FINAL_POINTS].subStepProgress = {
                [FinalizationSubsteps.PENDING_FINALIZATION]: {
                    isCompleted: true,
                },
            };
            yield put(OnboardingActions.setProgress(progress));

            // Move to the next step
            yield put(OnboardingActions.setStep(
                OnboardingSteps.FINAL_POINTS,
            ));
            yield put(OnboardingActions.setSubStep(
                FinalizationSubsteps.DOCUMENT_SIGNING_FINALIZATION,
            ));
            yield put(OnboardingActions.setInternalSubStep(
                CompanyRegistrationInternalSubSteps.FILE_LIST,
            ));
            yield put(AnimationActions.setIsSubAnimationActive(false));

            yield fork(delayedLoadDocuments, {
                freelancerId: loggedInUser.id,
                companyId: company.id,
                context: DocumentContexts.STAGE_TWO,
            });
        } else {
            yield put(DatabaseActions.storeDocuments(documents));
        }
    } else {
        yield put(OnboardingActions.setProgress({
            ...registrationCompletedProgress,
            [OnboardingSteps.CONTRACT_SIGNING]: {
                isCompleted: true,
            },
            [OnboardingSteps.WORKSHOPS]: {
                isCompleted: true,
            },
            [OnboardingSteps.COMPANY_SETUP]: {
                isCompleted: true,
            },
            [OnboardingSteps.COMPANY_REGISTRATION]: {
                isCompleted: true,
            },
            [OnboardingSteps.CAPITAL_DEPOSIT]: {
                isCompleted: true,
            },
        }));

        yield put(OnboardingActions.setStep(OnboardingSteps.FINAL_POINTS));
        yield put(OnboardingActions.setSubStep(FinalizationSubsteps.PENDING_FINALIZATION));
        yield put(OnboardingActions.setInternalSubStep(FinalizationSubsteps.PENDING_BANK_ONBOARDING));
    }
};

const handleCompanyPendingFinalSigs = function* ({
    progress,
    hasIntegration,
    loggedInUser,
}) {
    if (!hasIntegration && !isUserCareFn(loggedInUser)) {
        yield put(OnboardingActions.setStep(OnboardingSteps.COMPANY_REGISTRATION));
        yield put(OnboardingActions.setSubStep(CompanyRegistrationInternalSubSteps.FILE_LIST));
        yield put(OnboardingActions.setInternalSubStep(
            CompanyRegistrationInternalSubSteps.FILE_LIST,
        ));
        yield put(OnboardingActions.setProgress({
            ...progress,
            [OnboardingSteps.CONTRACT_SIGNING]: {
                ...progress[OnboardingSteps.CONTRACT_SIGNING],
                isCompleted: true,
            },
            [OnboardingSteps.WORKSHOPS]: {
                ...progress[OnboardingSteps.WORKSHOPS],
                isCompleted: true,
            },
            [OnboardingSteps.COMPANY_SETUP]: {
                ...progress[OnboardingSteps.COMPANY_SETUP],
                isCompleted: true,
            },
            [OnboardingSteps.CAPITAL_DEPOSIT]: {
                ...progress[OnboardingSteps.CAPITAL_DEPOSIT],
                isCompleted: true,
            },
        }));
    } else {
        // When there is integration this belongs to 6th step
        yield put(OnboardingActions.setStep(OnboardingSteps.FINAL_POINTS));
        yield put(OnboardingActions.setSubStep(FinalizationSubsteps.DOCUMENT_SIGNING_FINALIZATION));
        yield put(OnboardingActions.setInternalSubStep(
            CompanyRegistrationInternalSubSteps.FILE_LIST,
        ));

        yield put(OnboardingActions.setProgress({
            ...progress,
            [OnboardingSteps.CONTRACT_SIGNING]: {
                ...progress[OnboardingSteps.CONTRACT_SIGNING],
                isCompleted: true,
            },
            [OnboardingSteps.WORKSHOPS]: {
                ...progress[OnboardingSteps.WORKSHOPS],
                isCompleted: true,
            },
            [OnboardingSteps.COMPANY_SETUP]: {
                ...progress[OnboardingSteps.COMPANY_SETUP],
                isCompleted: true,
            },
            [OnboardingSteps.CAPITAL_DEPOSIT]: {
                ...progress[OnboardingSteps.CAPITAL_DEPOSIT],
                isCompleted: true,
            },
            [OnboardingSteps.COMPANY_REGISTRATION]: {
                ...progress[OnboardingSteps.COMPANY_REGISTRATION],
                isCompleted: true,
            },
            [OnboardingSteps.FINAL_POINTS]: {
                ...progress[OnboardingSteps.FINAL_POINTS],
                subStepProgress: {
                    [FinalizationSubsteps.PENDING_FINALIZATION]: {
                        isCompleted: true,
                    },
                },
                isCompleted: false,
            },
        }));
    }
};

const handleUserActive = function* ({
    progress,
    hasIntegration,
    individualBankAccountHolders,
    freelancer,
    company,
    registrationWithExistingCompany,
    loggedInUser,
}) {
    yield put(InsuranceActions.getInsurance({freelancerId: freelancer.id, companyId: company.id}));

    yield put(OnboardingActions.setStep(OnboardingSteps.FINAL_POINTS));

    const newProgress = {
        ...progress,
        [OnboardingSteps.CONTRACT_SIGNING]: {
            ...progress[OnboardingSteps.WORKSHOPS],
            isCompleted: true,
        },
        [OnboardingSteps.WORKSHOPS]: {
            ...progress[OnboardingSteps.WORKSHOPS],
            isCompleted: true,
        },
        [OnboardingSteps.COMPANY_SETUP]: {
            ...progress[OnboardingSteps.COMPANY_SETUP],
            isCompleted: true,
        },
        [OnboardingSteps.CAPITAL_DEPOSIT]: {
            ...progress[OnboardingSteps.CAPITAL_DEPOSIT],
            isCompleted: true,
        },
        [OnboardingSteps.COMPANY_REGISTRATION]: {
            ...progress[OnboardingSteps.COMPANY_REGISTRATION],
            isCompleted: true,
        },
    };

    if (hasIntegration) {
        if (isUserCareFn(loggedInUser)) {
            yield put(OnboardingActions.setSubStep(FinalizationSubsteps.ACCESS_PLATFORM));
            yield put(OnboardingActions.setInternalSubStep(
                FinalizationSubsteps.ACCESS_PLATFORM,
            ));

            newProgress[OnboardingSteps.FINAL_POINTS].subStepProgress = {
                [FinalizationSubsteps.DOCUMENT_SIGNING_FINALIZATION]: {
                    isCompleted: true,
                },
                [FinalizationSubsteps.PENDING_FINALIZATION]: {
                    isCompleted: true,
                },
                [FinalizationSubsteps.ACCESS_PLATFORM]: {
                    isCompleted: true,
                },
            };
        } else {
            // If it is not closed show second step
            // eslint-disable-next-line no-lonely-if
            if (individualBankAccountHolders
            && individualBankAccountHolders?.bankAccounts?.[0]?.status !== 'CLOSED'
            && individualBankAccountHolders?.bankAccounts?.[0]?.status !== 'CLOSING') {
                yield put(OnboardingActions.setSubStep(FinalizationSubsteps.PENDING_FINALIZATION));
                yield put(OnboardingActions.setInternalSubStep(
                    FinalizationSubsteps.PENDING_FINALIZATION,
                ));

                newProgress[OnboardingSteps.FINAL_POINTS].subStepProgress = {
                    [FinalizationSubsteps.DOCUMENT_SIGNING_FINALIZATION]: {
                        isCompleted: true,
                    },
                    [FinalizationSubsteps.DOCUMENT_SIGNING_FINALIZATION]: {
                        isCompleted: true,
                    },
                };
            } else {
                const onboardingFinalStatus = yield select(OnboardingSelectors.selectOnboardingFinalStatus);
                if (onboardingFinalStatus?.onBoardingFinalizedStatus?.READY_FOR_LAUNCH) {
                    yield put(OnboardingActions.setSubStep(FinalizationSubsteps.ACCESS_PLATFORM));
                    yield put(OnboardingActions.setInternalSubStep(
                        FinalizationSubsteps.ACCESS_PLATFORM,
                    ));

                    newProgress[OnboardingSteps.FINAL_POINTS].subStepProgress = {
                        [FinalizationSubsteps.DOCUMENT_SIGNING_FINALIZATION]: {
                            isCompleted: true,
                        },
                        [FinalizationSubsteps.PENDING_FINALIZATION]: {
                            isCompleted: true,
                        },
                        [FinalizationSubsteps.ACCESS_PLATFORM]: {
                            isCompleted: true,
                        },
                    };
                } else {
                // Handle insurance step
                    const insurance = yield select(InsuranceSelectors.selectInsurance);

                    yield put(OnboardingActions.setSubStep(FinalizationSubsteps.INSURANCE));
                    yield put(OnboardingActions.setInternalSubStep(
                        insurance?.status === InsuranceStatus.SIGNED || isUserCareFn(loggedInUser)
                            ? FinalizationSubsteps.ACCESS_PLATFORM
                            : FinalizationSubsteps.INSURANCE,
                    ));

                    newProgress[OnboardingSteps.FINAL_POINTS].subStepProgress = {
                        [FinalizationSubsteps.DOCUMENT_SIGNING_FINALIZATION]: {
                            isCompleted: true,
                        },
                        [FinalizationSubsteps.PENDING_FINALIZATION]: {
                            isCompleted: true,
                        },
                    };
                }
            }
        }
    } else {
        // Handle insurance step
        // eslint-disable-next-line no-lonely-if
        if (!registrationWithExistingCompany) {
            const insurance = yield select(InsuranceSelectors.selectInsurance);

            yield put(OnboardingActions.setSubStep(FinalizationSubsteps.INSURANCE));
            yield put(OnboardingActions.setInternalSubStep(
                insurance?.status === InsuranceStatus.SIGNED
                    ? FinalizationSubsteps.ACCESS_PLATFORM
                    : FinalizationSubsteps.INSURANCE,
            ));

            newProgress[OnboardingSteps.FINAL_POINTS].subStepProgress = {
                [FinalizationSubsteps.DOCUMENT_SIGNING_FINALIZATION]: {
                    isCompleted: true,
                },
                [FinalizationSubsteps.PENDING_FINALIZATION]: {
                    isCompleted: true,
                },
            };
        }
    }

    yield put(OnboardingActions.setProgress(newProgress));

    yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, false));
};

export const OnboardingHelper = {
    shouldUserLandOnWelcomeScreen,
    shouldUserLandOnSigningBatch1,
    shouldUserLandOnWorkshopScreen,
    shouldUserLandOnCapitalDeposit,

    isUserPendingCompany,
    isUserActive,

    isCompanyPendingDataInput,
    isCompanyPendingInitSigs,
    isCompanyReadyForReview,
    isCompanyDataValidated,
    isCompanyRegistrationCompleted,
    isCompanyPendingFinalSigs,

    handleUserWelcomeScreen,
    handleUserSignedBatch1,
    handleUserWorkshopScreen,
    handleUserCapitalDepositScreen,
    handleCareUserCompanyRegistration,
    handleUserCompanyRegistration,
    handleCompanyRegistrationCompleted,
    handleCompanyRegistrationCompletedCare,
    handleCompanyPendingFinalSigs,
    handleUserActive,
};
