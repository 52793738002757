import {faCircleChevronDown, faCircleChevronUp, faEye} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Stack} from '@mui/joy';
import Typography from '@mui/joy/Typography';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LocalesConstants} from '../../../utils/locales-constants';
import withJoyUI from '../joyui-theme-provider/JoyUiThemePRoviderWrapper';
import Collapse from '../mui-legacy/Collapse';

export const Section = withJoyUI(({
    title,
    icon,
    children,
    isCollapsible,
    isInitiallyCollapsed,
    sx,
    bodyStyles,
    headerStyles,
    headerComponent,
}) => {
    const {t} = useTranslation(LocalesConstants.Common);
    const [isExpanded, setIsExpanded] = useState(isInitiallyCollapsed || !isCollapsible);

    const toggleCollapse = event => {
        event.stopPropagation();
        setIsExpanded(prevState => !prevState);
    };

    return (
        <Stack
            direction="column"
            borderRadius="xl"
            sx={{
                border: '1px solid',
                borderColor: 'neutral.outlinedBorder',
                overflow: 'hidden',
                ...sx,
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap={1}
                sx={{
                    borderTopLeftRadius: 'var(--joy-radius-xl)',
                    borderTopRightRadius: 'var(--joy-radius-xl)',
                    backgroundColor: 'common.black',
                    borderBottom: '1px solid',
                    borderColor: 'neutral.outlinedBorder',
                    py: 1,
                    px: 2,
                    ...headerStyles,
                }}
            >
                {headerComponent ?? (
                    <>
                        <Stack
                            direction="row"
                            alignItems="center"
                            fontSize="md"
                            gap={1}
                            sx={{
                                color: 'neutral.solidColor',
                            }}
                        >
                            <FontAwesomeIcon icon={icon} />
                            <Typography level="title-md" textColor="neutral.solidColor">
                                {title}
                            </Typography>
                        </Stack>
                        {isCollapsible && (
                            <Button
                                size="sm"
                                variant="soft"
                                color="neutral"
                                onClick={toggleCollapse}
                                sx={{my: -0.5}}
                                endDecorator={(
                                    <FontAwesomeIcon
                                        icon={isExpanded ? faCircleChevronDown : faCircleChevronUp}
                                    />
                                )}
                            >
                                {t('section.hideInformation')}
                            </Button>
                        )}
                    </>
                )}
            </Stack>
            {isCollapsible ? (
                <Collapse in={isExpanded}>
                    <Box sx={{
                        backgroundColor: 'background.body',
                        p: 2,
                        ...bodyStyles,
                    }}
                    >
                        {children}
                    </Box>
                </Collapse>
            ) : (
                <Box sx={{
                    backgroundColor: 'background.body',
                    p: 2,
                    ...bodyStyles,
                }}
                >
                    {children}
                </Box>
            )}
        </Stack>
    );
});

Section.propTypes = {
    title: PropTypes.node,
    icon: PropTypes.any,
    isCollapsible: PropTypes.bool,
    isInitiallyCollapsed: PropTypes.bool,
    sx: PropTypes.object,
    bodyStyles: PropTypes.object,
    headerStyles: PropTypes.object,
    headerComponent: PropTypes.node,
};

Section.defaultProps = {
    title: '',
    icon: faEye,
    isCollapsible: false,
    isInitiallyCollapsed: false,
    sx: {},
    bodyStyles: {},
    headerStyles: {},
    headerComponent: null,
};

export default Section;
