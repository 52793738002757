import {
    faFileArrowUp,
    faFileCircleCheck,
    faHourglassStart,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadIcon from '@mui/icons-material/Download';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import {Alert, Button} from '@mui/joy';
import {LoadingButton} from '@mui/lab';
import {Alert as MuiAlert, Paper} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ButtonAction} from '../../../../../../components/buttons/ButtonAction';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {ContractSelectors} from '../../../../../contract/store/contract.selector';
import {LoadingTypes, useLoadingState} from '../../../../../loading';
import {SignatureSelectors} from '../../../../../signature/store/signature.selector';
import {UiActions} from '../../../../../ui/store/ui.action';
import {UiSelectors} from '../../../../../ui/store/ui.selector';
import {ModalsKeys, SidebarDrawerKeys} from '../../../../../ui/utils/constants';
import {DocumentActions} from '../../../../store/document.action';
import {SigningSelectors} from '../../store/signing.selector';
import DocumentSigningToolbar from '../DocumentSigningToolbar';
import DocumentSigningToolbarV3 from '../v3/DocumentSigningToolbarV3';

const drawerKey = SidebarDrawerKeys.BOTTOM_DRAWER;

export const DocumentSigningMobile = ({
    MainActionComponent,
    isLoadingNextStep,
    onNextClick,
    onSignClick,
    signedLocale,
    isPublic,
    isPublicSigned,
    isShowingSignature,
    isV3,
}) => {
    const {t} = useTranslation(LocalesConstants.Document);

    const dispatch = useDispatch();

    const isDrawerOpen = useSelector(UiSelectors.createIsSidebarDrawerActiveSelector(drawerKey));

    const contractData = useSelector(ContractSelectors.selectData);
    const isDocumentSigned = useSelector(SigningSelectors.selectCurrentDocumentIsSigned);
    const isSigned = isPublicSigned || isDocumentSigned;

    const signatureUrl = useSelector(SignatureSelectors.selectUrl);

    const isSigning = useSelector(SigningSelectors.selectIsSigningCurrentDocument);

    const isDownloadingContract = useLoadingState(LoadingTypes.DOWNLOAD_DOCUMENT);

    const documentId = useSelector(SigningSelectors.selectCurrentDocumentId);

    if (isV3) {
        return (
            <>
                {isDrawerOpen && !isPublic && (
                    <Alert
                        color={isSigned ? 'success' : 'warning'}
                        variant={isSigned ? 'outlined' : 'soft'}
                        sx={{
                            'borderRadius': 'sm',
                            'alignItems': 'center',
                            '& .MuiAlert-startDecorator': {
                                pt: 0,
                            },
                            ...(isSigned ? {backgroundColor: 'background.body'} : {}),
                        }}
                        startDecorator={isSigned
                            ? <FontAwesomeIcon icon={faFileCircleCheck} />
                            : <FontAwesomeIcon icon={faHourglassStart} />}
                    >
                        {!isSigned
                            ? t('signing.awaitingSignature')
                            : t(signedLocale)
                        }
                    </Alert>
                )}

                <DocumentSigningToolbarV3
                    MainActionComponent={MainActionComponent}
                    onNextClick={onNextClick}
                    onSignClick={onSignClick}
                    isSigning={isSigning}
                    isLoadingNextStep={isLoadingNextStep}
                    documentId={documentId}
                />

                {isShowingSignature && isDrawerOpen && (
                    <Button
                        startDecorator={<FontAwesomeIcon icon={faFileArrowUp} />}
                        color="primary"
                        variant="outlined"
                        size="md"
                        onClick={() => dispatch(UiActions.setActiveModal(ModalsKeys.UPLOAD_SIGNATURE, true))}
                        fullWidth
                    >
                        {t('signing.mySignatureTitle')}
                    </Button>
                )}
            </>
        );
    }

    return (
        <Paper radius={8} elevation={2} sx={{p: 2}}>
            <MuiAlert
                severity={isSigned ? 'success' : 'warning'}
                sx={{mt: 1, borderRadius: 3, color: isSigned ? 'rgba(0, 0, 0, 0.6)' : 'warning.dark'}}
                icon={isSigned ? <CheckCircleIcon sx={{color: theme => theme.palette.buttonSuccess.main}} /> : <HourglassTopIcon sx={{color: 'warning.dark'}} />}
            >
                {!isSigned ? t('signing.awaitingSignature') : t('signing.contractSigned')}
            </MuiAlert>

            <DocumentSigningToolbar
                MainActionComponent={MainActionComponent}
                onNextClick={onNextClick}
                onSignClick={onSignClick}
                isSigning={isSigning}
                isLoadingNextStep={isLoadingNextStep}
                documentId={documentId}
                isSigned={isSigned}
            />

            {isSigned && (
                <LoadingButton
                    sx={{py: 0.75, borderRadius: 2, mt: 1}}
                    startIcon={<DownloadIcon />}
                    variant="outlined"
                    size="small"
                    loading={isDownloadingContract}
                    disabled={!MainActionComponent || !signatureUrl}
                    loadingPosition="start"
                    onClick={() => {
                        if (!MainActionComponent || !signatureUrl) {
                            return;
                        }
                        dispatch(DocumentActions.getDocument(contractData.documentId, true));
                    }}
                    fullWidth
                >
                    {t('signing.downloadPDFMobile')}
                </LoadingButton>
            )}

            {isSigned && (
                <ButtonAction
                    startIcon={<PublishedWithChangesIcon />}
                    size="medium"
                    onClick={() => dispatch(UiActions.setActiveModal(ModalsKeys.UPLOAD_SIGNATURE, true))}
                    fullWidth
                    variant="outlined"
                    sx={{mt: 1, py: 0.7, borderRadius: 2, fontSize: theme => theme.typography.pxToRem(14)}}
                >
                    {t('signing.mySignatureTitle')}
                </ButtonAction>
            )}
        </Paper>
    );
};

DocumentSigningMobile.propTypes = {
    MainActionComponent: PropTypes.any,
    isLoadingNextStep: PropTypes.bool,
    onNextClick: PropTypes.func.isRequired,
    onSignClick: PropTypes.func.isRequired,
    signedLocale: PropTypes.string,
    isPublicSigned: PropTypes.bool,
    isPublic: PropTypes.bool,
    isShowingSignature: PropTypes.bool,
    isV3: PropTypes.bool, // TODO: make true by default and delete the code, related to false value
};

DocumentSigningMobile.defaultProps = {
    MainActionComponent: null,
    isLoadingNextStep: false,
    signedLocale: 'signing.documentSignedPolite',
    isPublicSigned: null,
    isShowingSignature: false,
    isPublic: false,
    isV3: false,
};
