import InfoIcon from '@mui/icons-material/Info';
import {Box, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import BankCardSelectorToggler from './BankCardSelectorToggler';
import {PhysicalCard} from './PhysicalCard';
import {VirtualCard} from './VirtualCard';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {CARD_TYPES} from '../../util/constants';


const leftPositionInactive = 0;
const rightPositionInactive = 330;

const leftPositionActive = 30;
const rightPositionActive = 290;

const useStyles = makeStyles({
    container: {
        position: 'relative',
        width: '100vw',
        height: '100%',
        overflow: 'hidden',
    },
    centered: {
        position: 'absolute',
        transform: 'translateX(360px)',
        width: '100wv',
        height: '100%',
    },
    offscreen: {
        position: 'absolute',
        transform: 'translateX(-20px)',
        width: '100wv',
        height: '100%',
        transition: 'all 0.3s ease',
    },
});

const BankCardSwitcherMobile = ({
    selectedCard,
    setSelectedCard,
    isActive,
    openPhysModal,
    user,
    card,
    activateCardModal,
    unlockBlockedModal,
    unlockCard,
    openVirtualModal,
}) => {
    const left = isActive ? CARD_TYPES.VIRTUAL : CARD_TYPES.PHYS;
    const right = !isActive ? CARD_TYPES.VIRTUAL : CARD_TYPES.PHYS;
    const leftPosition = isActive ? leftPositionActive : leftPositionInactive;
    const rightPosition = isActive ? rightPositionActive : rightPositionInactive;

    const {t} = useTranslation(LocalesConstants.BankCards);
    const classes = useStyles();

    const [dragging, setDragging] = useState(false);
    const [startX, setStartX] = useState(leftPosition);
    const [offsetX, setOffsetX] = useState(selectedCard === right ? -rightPosition : leftPosition);

    // const handleMouseDown = event => {
    //     event.preventDefault();
    //     setDragging(true);
    //     setStartX(event.clientX);
    //     console.log('test');
    // };
    //
    // const handleMouseMove = event => {
    //     event.preventDefault();
    //     if (dragging) {
    //         let offsetX = event.clientX - startX;
    //         if (offsetX > 0) {
    //             offsetX = 0;
    //         }
    //         if (offsetX < -360) {
    //             offsetX = 360;
    //         }
    //
    //         if (selectedCard === left) {
    //             if (offsetX < -300) {
    //                 setOffsetX(-360);
    //                 setDragging(false);
    //                 setSelectedCard(left);
    //             } else {
    //                 setOffsetX(event.clientX - startX);
    //             }
    //         } else if (offsetX > -30) {
    //             setOffsetX(-20);
    //             setDragging(false);
    //             setSelectedCard(right);
    //         } else {
    //             setOffsetX(event.clientX - startX);
    //         }
    //     }
    // };
    //
    // const handleMouseUp = event => {
    //     event.preventDefault();
    //
    //     setDragging(false);
    // };

    const handleTouchStart = e => {
        setDragging(true);
        setStartX(e.touches[0].pageX);
    };

    const handleTouchMove = e => {
        if (!dragging) {
            return;
        }
        const currentX = e.touches[0].pageX;
        let dx = currentX - startX;

        if (selectedCard === left) {
            if (dx > leftPosition) {
                dx = leftPosition;
            }
            if (dx < -rightPosition) {
                dx = -rightPosition;
            }
        } else {
            if (dx < leftPosition) {
                dx = leftPosition;
            }
            if (dx > rightPosition) {
                dx = rightPosition;
            }
        }

        if (selectedCard === right) {
            if (dx > rightPosition - 50) {
                setOffsetX(leftPosition);
                setDragging(false);
                setSelectedCard(left);
            } else {
                setOffsetX(-rightPosition - 30 + dx);
            }
        }

        if (selectedCard === left) {
            if (dx < -rightPosition + 50) {
                setOffsetX(-rightPosition);
                setDragging(false);
                setSelectedCard(right);
            } else {
                setOffsetX(dx);
            }
        }
    };

    const handleTouchEnd = () => {
        if (!dragging) {
            return;
        }
        setDragging(false);

        if (selectedCard === left) {
            if (offsetX < -rightPosition / 2) {
                setOffsetX(-rightPosition);
                setSelectedCard(right);
            } else {
                setOffsetX(leftPosition);
            }
        }

        if (selectedCard === right) {
            if (offsetX > rightPosition / 2) {
                setOffsetX(leftPosition);
                setSelectedCard(left);
            } else {
                setOffsetX(-rightPosition);
            }
        }
    };

    const setSelectedCardSlider = value => {
        setSelectedCard(value);
        setOffsetX(value === right ? -rightPosition : leftPosition);
    };

    return (
        <>
            <div
                style={{
                    position: 'relative',
                    width: '100%',
                    height: '280px',
                    overflow: 'hidden',
                    paddingTop: '64px',
                    marginBottom: '40px',
                }}
            // onMouseDown={handleMouseDown}
            // onMouseMove={handleMouseMove}
            // onMouseUp={handleMouseUp}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <div
                    style={{
                        position: 'absolute',
                        left: `${offsetX}px`,
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <div className={!isActive ? classes.offscreen : classes.centered}>
                        <Box sx={{
                            marginLeft: isActive ? '-70px' : '0',
                            marginRight: !isActive ? '-70px' : '0',
                        }}
                        >
                            {selectedCard === CARD_TYPES.PHYS && (
                            <Box sx={{
                                position: 'absolute',
                                top: '-40px',
                                width: '396px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            >
                                <Typography sx={{
                                    fontSize: theme => theme.typography.pxToRem(21),
                                    fontWeight: 700,
                                }}
                                >
                                    {t('cardDetails.physicalCard')}
                                </Typography>
                                <InfoIcon
                                    onClick={openPhysModal}
                                    sx={{
                                        cursor: 'pointer',
                                        ml: 1,
                                        color: '#78909C',
                                    }}
                                />
                            </Box>
                            )}
                            <Box>
                                <PhysicalCard
                                    t={t}
                                    user={user}
                                    card={card}
                                    activateCardModal={activateCardModal}
                                    unlockBlockedModal={unlockBlockedModal}
                                    unlockCard={unlockCard}
                                />
                            </Box>
                        </Box>
                    </div>
                    <div className={isActive ? classes.offscreen : classes.centered}>
                        <Box sx={{
                            position: 'relative',
                            top: '10px',
                        }}
                        >
                            {selectedCard === CARD_TYPES.VIRTUAL && (
                            <Box sx={{
                                position: 'absolute',
                                top: '-40px',
                                width: '327px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            >
                                <Typography sx={{
                                    fontSize: theme => theme.typography.pxToRem(21),
                                    fontWeight: 700,
                                }}
                                >
                                    {t('cardDetails.virtualCard')}
                                </Typography>
                                <InfoIcon
                                    onClick={openVirtualModal}
                                    sx={{
                                        cursor: 'pointer',
                                        ml: 1,
                                        color: '#78909C',
                                    }}
                                />
                            </Box>
                            )}
                            <Box>
                                <VirtualCard
                                    t={t}
                                    user={user}
                                    card={card}
                                />
                            </Box>
                        </Box>
                    </div>
                </div>
            </div>

            <Box sx={{
                position: 'relative',
                bottom: '20px',
            }}
            >
                <BankCardSelectorToggler
                    setSelectedCard={setSelectedCardSlider}
                    selectedCard={selectedCard}
                    card={card}
                />
            </Box>
        </>
    );
};

BankCardSwitcherMobile.propTypes = {
    selectedCard: PropTypes.string.isRequired,
    setSelectedCard: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
    openPhysModal: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    card: PropTypes.object,
    activateCardModal: PropTypes.func.isRequired,
    unlockBlockedModal: PropTypes.func.isRequired,
    unlockCard: PropTypes.func.isRequired,
    openVirtualModal: PropTypes.func.isRequired,
};

BankCardSwitcherMobile.defaultProps = {
    card: {},
};

export default BankCardSwitcherMobile;
