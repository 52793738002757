import AddCardIcon from '@mui/icons-material/AddCard';
import {Box, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {LocalesConstants} from '../../../utils/locales-constants';

const InsuranceMidSectionCardWrapper = () => {
    const {t} = useTranslation(LocalesConstants.Insurance);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: isMobileSize ? 'column' : 'row',
            padding: isMobileSize ? 3 : 5,
            alignItems: 'stretch',
            gap: 3,
            alignSelf: 'stretch',
            borderRadius: 6,
            border: '1px solid',
            borderColor: 'v2.light.success.shades12',
            backgroundColor: 'v2.light.primary.shades8',
        }}
        >
            <Box sx={{
                'display': 'flex',
                'alignSelf': 'stretch',
                'flexDirection': 'column',
                'padding': isMobileSize ? '16px 24px 32px 24px' : 4,
                'alignItems': isMobileSize ? 'flex-start' : 'center',
                'gap': 2,
                'flex': '1 0 0',
                'borderRadius': 4,
                'backgroundColor': '#FFF',
            }}
            >
                <Box sx={{
                    display: 'flex',
                    width: '60px',
                    height: '60px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '100px',
                    backgroundColor: 'primary_v2.main',
                }}
                >
                    <AddCardIcon sx={{width: '30px', height: '30px', color: '#FFF'}} />
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    alignSelf: 'stretch',
                    textAlign: isMobileSize ? 'left' : 'center',
                }}
                >
                    <Typography sx={{fontSize: theme => theme.typography.pxToRem(21), fontWeight: 700}}>
                        {t('midSection.cards.0.title')}
                    </Typography>
                    <Typography>{t('midSection.cards.0.description')}</Typography>
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: isMobileSize ? '16px 24px 32px 24px' : 4,
                alignItems: isMobileSize ? 'flex-start' : 'center',
                gap: 2,
                flex: '1 0 0',
                borderRadius: 4,
                backgroundColor: '#FFF',
            }}
            >
                <Box sx={{
                    display: 'flex',
                    width: '60px',
                    height: '60px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '100px',
                    backgroundColor: 'primary_v2.main',
                }}
                >
                    <AddCardIcon sx={{width: '30px', height: '30px', color: '#FFF'}} />
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    alignSelf: 'stretch',
                    textAlign: isMobileSize ? 'left' : 'center',
                }}
                >
                    <Typography sx={{fontSize: theme => theme.typography.pxToRem(21), fontWeight: 700}}>
                        {t('midSection.cards.1.title')}
                    </Typography>
                    <Typography>{t('midSection.cards.1.description')}</Typography>
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: isMobileSize ? '16px 24px 32px 24px' : 4,
                alignItems: isMobileSize ? 'flex-start' : 'center',
                gap: 2,
                flex: '1 0 0',
                borderRadius: 4,
                backgroundColor: '#FFF',
            }}
            >
                <Box sx={{
                    display: 'flex',
                    width: '60px',
                    height: '60px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '100px',
                    backgroundColor: 'primary_v2.main',
                }}
                >
                    <AddCardIcon sx={{width: '30px', height: '30px', color: '#FFF'}} />
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    alignSelf: 'stretch',
                    textAlign: isMobileSize ? 'left' : 'center',
                }}
                >
                    <Typography sx={{fontSize: theme => theme.typography.pxToRem(21), fontWeight: 700}}>
                        {t('midSection.cards.2.title')}
                    </Typography>
                    <Typography>{t('midSection.cards.2.description')}</Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default InsuranceMidSectionCardWrapper;
