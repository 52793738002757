import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {Alert, Box, Button, Divider, Paper, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import ConfirmVAT from './ConfirmVAT';
import EditAndConfirmVAT from './EditAndConfirmVAT';
import OCRView from './OCRView';
import FormattedNumber from '../../../../../../../../components/ui-kit/FormattedNumber/FormattedNumber';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';
import {getCompanyDocument} from '../../../../../../../company/store/company.actions';
import {InvoiceSelector} from '../../../../../../../invoicing/store/invoice.selector';
import {LoadingSelectors, LoadingTypes} from '../../../../../../../loading';
import {TransactionsActions} from '../../../../store/transactions.action';
import {TransactionsSelector} from '../../../../store/transactions.selector';
import {TVA_ENUM, VAT_MATCH_STATUS, VAT_VALIDATION_STATUS} from '../../../../util/constants';

const VATValidation = ({transactionId, onCompleteVATValidation, defaultVATPercent, defaultVATAmount}) => {
    const {t} = useTranslation(LocalesConstants.Bank);
    const details = useSelector(TransactionsSelector.createSelectTransactionById(transactionId));
    const documentDetails = details.documents[0];
    const article = details?.articles?.[0];
    const dispatch = useDispatch();


    const transactionDocument = useSelector(InvoiceSelector.selectInvoiceUrl);
    const isDocumentPdf = transactionDocument?.includes('.pdf') ?? false;
    const loadingSelector = LoadingSelectors.createLoadingSelectorByType(LoadingTypes.VIEW_DOCUMENT);
    const isLoading = useSelector(loadingSelector);

    const vatValidationStatus = details?.suggestedVat?.matchStatus;


    const vat = details?.suggestedVat?.tva ?? article.tva;
    const vatAmount = details?.suggestedVat?.amount ?? article.tvaAmount;

    const [vatRejected, setVatRejected] = useState(false);

    const onConfirmVat = () => {
        dispatch(TransactionsActions.validateTransactionVat({
            id: details.id,
            accountId: details?.bankAccountId,
            vatValidationStatus: VAT_VALIDATION_STATUS.validated,
            articles: [{
                mccKey: article.mccKey,
                tva: vat,
                ...(vat === TVA_ENUM.TVA_CUSTOM && ({tvaAmount: vatAmount})),
            }],
        }));

        onCompleteVATValidation();
    };

    const onConfirmVatEdit = () => {
        dispatch(TransactionsActions.validateTransactionVat({
            id: details.id,
            accountId: details?.bankAccountId,
            vatValidationStatus: VAT_VALIDATION_STATUS.validated,
        }));

        onCompleteVATValidation();
    };

    const onRejectVat = () => {
        setVatRejected(true);
    };

    useEffect(() => {
        if (!documentDetails) {
            return;
        }

        dispatch(getCompanyDocument(
            documentDetails.userId,
            documentDetails.companyId,
            documentDetails.id,
            true,
            true,
        ));
    }, [dispatch, documentDetails]);


    return (
        <Box sx={{
            width: '100%',
            height: '95%',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignSelf: 'center',
            p: 4,
        }}
        >
            <Paper
                elevation={1}
                sx={{
                    display: 'flex',
                    p: 3,
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    borderRadius: '16px',
                    gap: 2,
                    width: '100%',
                    height: '100%',
                    flex: 1,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '100%',
                        // height: '100%',
                        flex: 0,
                        gap: 2,
                    }}
                >
                    <Typography sx={{
                        fontWeight: 500,
                        color: 'v2.blueGray.300',
                        textTransform: 'uppercase',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 1.5,
                        letterSpacing: '1px',
                    }}
                    >
                        {t('categorisation.vatValidation.title')}
                        <HelpOutlineIcon />
                    </Typography>
                    {vatValidationStatus === VAT_MATCH_STATUS.mismatch && (
                        <Alert
                            severity="warning"
                            sx={{
                                'width': '100%',
                                'color': 'v2.light.warning.shades160',
                                '.MuiSvgIcon-root': {
                                    color: 'v2.light.warning.shades160',
                                },
                            }}
                        >
                            {t('categorisation.vatValidation.validatedByAIErrorMessage', {
                                vatPercent: defaultVATPercent,
                                vatAmount: defaultVATAmount,
                            })}
                        </Alert>
                    )}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        flexGrow: 1,
                        overflow: 'auto',
                    }}
                >
                    <OCRView
                        document={transactionDocument}
                        documentsData={documentDetails?.ocrData.documentsData ?? []}
                        isDocumentPdf={isDocumentPdf}
                        isLoading={isLoading}
                    />
                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{
                            margin: '0 16px',
                        }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            width: '342px',
                            gap: 2,
                        }}
                    >
                        <Typography variant="h5">
                            {vatValidationStatus === VAT_MATCH_STATUS.mismatch
                                ? t('categorisation.vatValidation.vatDetectedValidationRequired')
                                : t('categorisation.vatValidation.vatDetected')}
                        </Typography>
                        {vatValidationStatus === VAT_MATCH_STATUS.match && (
                            <Alert
                                severity="info"
                                icon={<AutoFixHighIcon />}
                            >
                                {t('categorisation.vatValidation.validatedByAI')}
                            </Alert>
                        )}
                        <Box sx={styles.vatSection}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 2,
                            }}
                            >
                                <AutoAwesomeIcon sx={{color: 'v2.light.info.light'}} />
                                <Typography variant="h6">
                                    {t('categorisation.vatValidation.valuesDetected')}
                                </Typography>
                            </Box>
                            <Box sx={styles.vatRow}>
                                {t('categorisation.vatValidation.totalVatDetected')}
                                <Box component="span" sx={styles.vatRowValue}>
                                    <FormattedNumber amount={vatAmount} numberStyle="currency" />
                                </Box>
                            </Box>
                            <Box sx={styles.vatRow}>
                                {t('categorisation.vatValidation.vatRatesDetected')}
                                <Box component="span" sx={styles.vatRowValue}>
                                    {t(`vat.${vat}`)}
                                </Box>
                            </Box>
                            {vatValidationStatus === VAT_MATCH_STATUS.mismatch && !vatRejected && (
                                <Box sx={styles.vatRow}>
                                    <ConfirmVAT onConfirm={onConfirmVat} onReject={onRejectVat} />
                                </Box>
                            )}
                            {vatRejected && (
                                <Box sx={styles.vatRow}>
                                    <Button
                                        sx={{
                                            display: 'flex',
                                            flex: 1,
                                            color: 'white',
                                            width: '100%',
                                        }}
                                        disableRipple={true}
                                        disableFocusRipple={true}
                                        variant="contained"
                                        color="error"
                                        startIcon={<CloseIcon />}
                                        disabled
                                    >
                                        {t('categorisation.vatValidation.vatInvalid')}
                                    </Button>
                                </Box>
                            )}
                        </Box>
                        {vatRejected && (
                            <Box sx={styles.vatSection}>
                                <EditAndConfirmVAT details={details} onConfirm={onConfirmVatEdit} />
                            </Box>
                        )}
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};

const styles = {
    vatSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: 'v2.gray.50',
        borderRadius: '16px',
        p: 3,
        pt: 2,
        gap: 2,
        width: '100%',
    },
    vatRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        fontSize: theme => theme.typography.pxToRem(16),
        fontWeight: 400,
        letterSpacing: '0.15px',
    },
    vatRowValue: {
        fontWeight: 500,
        letterSpacing: '0.1px',
    },
};

VATValidation.propTypes = {
    transactionId: PropTypes.string.isRequired,
    onCompleteVATValidation: PropTypes.func.isRequired,
    defaultVATPercent: PropTypes.string.isRequired,
    defaultVATAmount: PropTypes.number.isRequired,
};


export default VATValidation;
