import {combineReducers} from 'redux';
import {additionalCompanyDataReducer} from './additional-company-data/additional-company-data.reducer';
import {companyDashboardReducer} from './company-dashboard/company-dashboard.reducer';
import {companyListReducer} from './company-list/company-list.reducer';
import * as actions from './company.actions';
import {currentCompanyReducer} from './current-company/current-company.reducer';
import {createReducer} from '../../../app/utils/store/create-reducer';
import {LOCATION_CHANGED} from '../../../lib/router/connected-router-saga';
import {trainingReducer} from '../../training/store/training.reducer';
import {companyDocumentsReducer} from '../modules/documents/store/company-documents.reducer';
import {setupCompanyReducer} from '../modules/setup-company/store/setup-company.reducer';

const singleCompanyReducer = (state = null, action) => {
    if (action.type === actions.STORE_COMPANY) {
        return action.payload;
    }

    // Reset to initial state.
    // ONBOARDING SIDEBAR LOCATION CHANGE - Shorthand to find all places we need to change when adding new item
    if (action.type === LOCATION_CHANGED
        && !(
            action.payload.location.pathname.includes('freelance')
            || action.payload.location.pathname.includes('settings')
            || action.payload.location.pathname.includes('profile')
            || action.payload.location.pathname.includes('documents')
            || action.payload.location.pathname.includes('change-password')
            || action.payload.location.pathname.includes('my-companies')
            || action.payload.location.pathname.includes('mission-search')
            || action.payload.location.pathname.includes('centre-d-aide')
            || action.payload.location.pathname.includes('cluster')
        )
    ) {
        return null;
    }

    return state;
};

export const companyReducer = combineReducers({
    additionalCompanyData: additionalCompanyDataReducer,
    companyDocuments: companyDocumentsReducer,
    companyList: companyListReducer,
    currentCompany: currentCompanyReducer,
    dashboard: companyDashboardReducer,
    companyPersonalDocuments: createReducer(null, actions.STORE_PERSONAL_COMPANY_DOCUMENTS, true),
    isApprovingCompanyInProgress: createReducer(false, actions.SET_IS_APPROVING_COMPANY),
    isLoadingCompanyProfileView: createReducer(true, actions.SET_IS_LOADING_COMPANY_PROFILE_VIEW, true),
    isLoadingPersonalCompanyDocuments: createReducer(false, actions.SET_IS_LOADING_PERSONAL_COMPANY_DOCUMENTS, true),
    isLoadingSingleCompany: createReducer(true, actions.SET_IS_LOADING_COMPANY, true),
    isRequestingEditsInProgress: createReducer(false, actions.SET_IS_REQUESTING_EDITS),
    isUpdatingCompanyInformation: createReducer(false, actions.SET_IS_UPDATING_COMPANY_INFORMATION),
    isUpdatingNonConvictionDeclaration: createReducer(false, actions.SET_IS_UPDATING_NON_CONVICTION_DECLARATION),
    isUpdatingPersonalInformation: createReducer(false, actions.SET_IS_UPDATING_PERSONAL_INFORMATION),
    setupCompany: setupCompanyReducer,
    singleCompany: singleCompanyReducer,
    training: trainingReducer,
    uploadingDocumentIds: createReducer([], actions.SET_UPLOADING_DOCUMENT_IDS),
});
