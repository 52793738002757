import {all, call, put, takeLatest} from 'redux-saga/effects';
import * as actions from './assigned-freelancers.actions';
import {getAssignedFreelancersRequest} from '../../../user-list/api/user.api';

const getAssignedFreelancers = function* getAssignedFreelancers({payload}) {
    yield put(actions.setIsLoadingAssignedFreelancers(true));

    try {
        const {userId} = payload;

        const freelancers = yield call(getAssignedFreelancersRequest, userId);

        yield put(actions.storeAssignedFreelancers(freelancers));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    } finally {
        yield put(actions.setIsLoadingAssignedFreelancers(false));
    }
};

export const watchAssignedFreelancersSagas = function* watchAssignedFreelancersSagas() {
    yield all([takeLatest(actions.GET_ASSIGNED_FREELANCERS, getAssignedFreelancers)]);
};
