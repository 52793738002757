import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DownloadIcon from '@mui/icons-material/Download';
import {CircularProgress, Divider, Grid, Grow, Paper, Slide, Typography} from '@mui/material';
import Box from '@mui/material/Box';
// import useMediaQuery from '@mui/material/useMediaQuery';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ButtonAction} from '../../../../../components/buttons/ButtonAction';
import DropZone from '../../../../../components/ui-kit/DropZone';
import {LocalesConstants} from '../../../../../utils/locales-constants';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../animations/store/animations.selector';
import {ANIMATION_TYPE, BANNER_ANIMATION_DELAY, FORM_ANIMATION_DELAY} from '../../../../animations/utils/constants';
import {getCompanyInformationData} from '../../../../company/modules/setup-company/store/company-information/company-information.selectors';
import {DatabaseSelectors} from '../../../../document/modules/database/store/database.selector';
import {DocumentActions} from '../../../../document/store/document.action';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';
import {FreelancerSelectors} from '../../../store/freelancer.selectors';
import {CompaniesSelectors} from '../../companies';
import {useDocumentsUploadFormStyles} from '../../company-setup/components/DocumentsUploadForm/styles';
import DocumentUploadStepTitle from '../../company-setup/components/DocumentUploadStepTitle';
import {OnboardingActions} from '../../onboarding/store/onboarding.action';
import {OnboardingSelectors} from '../../onboarding/store/onboarding.selectors';
import {OnboardingSteps} from '../../onboarding/utils/onboadingStepsConstant';

export const DocumentUpload = () => {
    const document = useSelector(DatabaseSelectors.createDepositDocumentByTypeSelector('CAPITAL_DEPOSIT'));

    const [files, setFiles] = useState(document ? [new File([], document.original_filename)] : []);

    const dispatch = useDispatch();
    const styles = useDocumentsUploadFormStyles();

    const isQontoDownloading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.DOWNLOAD_QONTO_DOCUMENT),
    );

    const isStatusesDownloading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.DOWNLOAD_STATUSES_DOCUMENT),
    );

    const freelancer = useSelector(FreelancerSelectors.selectAccount);
    const company = useSelector(CompaniesSelectors.createCompanyByIdSelector(freelancer?.defaultCompanyId));
    const progress = useSelector(OnboardingSelectors.selectProgress);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const {t} = useTranslation(LocalesConstants.FreelancerOnboarding);

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);
    const isSubAnimationActive = useSelector(AnimationsSelectors.selectIsSubAnimationActive);

    const companyInformationData = useSelector(getCompanyInformationData);

    const onNextClick = () => {
        dispatch(AnimationActions.storeNextDispatch(
            OnboardingActions.setStep(OnboardingSteps.COMPANY_REGISTRATION),
        ));
        dispatch(AnimationActions.setIsSubAnimationActive(false));

        dispatch(OnboardingActions.setProgress({
            ...progress,
            [OnboardingSteps.CAPITAL_DEPOSIT]: {
                ...progress[OnboardingSteps.CAPITAL_DEPOSIT],
                isCompleted: true,
            },
        }));
    };

    const createDropAcceptedHandler = file => {
        if (file) {
            dispatch(DocumentActions.uploadDocument({
                file: file[0],
                type: 'CAPITAL_DEPOSIT',
                category: 'COMPANY_SETUP',
            }));
            setFiles(file);
        }
    };

    const onDownloadQontoClick = () => {
        dispatch(DocumentActions.getQonto());
    };

    const onDownloadStatusesClick = () => {
        dispatch(DocumentActions.getStatuses());
    };

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    return (
        <>
            <Slide
                direction="left"
                in={isAnimationActive}
                timeout={FORM_ANIMATION_DELAY}
                // onTransitionEnd={() => {
                //     dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
                // }}
                onEntered={onEndedAnimation}
                onExited={onEndedAnimation}
            >
                <Paper radius={16} elevation={2} sx={{px: 5, py: 4}}>
                    <div className={styles.documentUploadStepContainer}>
                        <DocumentUploadStepTitle
                            title={t('capitalDepositStep2.createBankAccount')}
                            stepNumber="1"
                            isCompleted={false}
                        />

                        <Grow
                            in={isSubAnimationActive}
                            timeout={FORM_ANIMATION_DELAY}
                            onExited={() => {
                                dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.SECONDARY));
                            }}
                        >
                            <Box sx={{pl: 5.1, mt: 1}}>
                                {companyInformationData.bank === 'Qonto'
                                && (
                                    <>
                                        <Typography sx={{mt: 1}}>{t('capitalDepositStep2.qontoText')}</Typography>
                                        <ButtonAction
                                            sx={{py: 0.5, borderRadius: '6px', mt: 1}}
                                            startIcon={isQontoDownloading
                                                ? <CircularProgress size={20} />
                                                : <DownloadIcon />
                                            }
                                            onClick={onDownloadQontoClick}
                                            isLoading={isQontoDownloading}
                                            loadingPosition="start"
                                            variant="outlined"
                                        >
                                            {t('capitalDepositStep2.qontoDownloadText')}
                                        </ButtonAction>
                                    </>
                                )
                                }
                                <Typography sx={{mt: 2}}>{t('capitalDepositStep2.tdfText')}</Typography>
                                <ButtonAction
                                    sx={{py: 0.5, borderRadius: '6px', mt: 1}}
                                    startIcon={isStatusesDownloading
                                        ? <CircularProgress size={20} />
                                        : <DownloadIcon />}
                                    onClick={onDownloadStatusesClick}
                                    isLoading={isStatusesDownloading}
                                    loadingPosition="start"
                                    variant="outlined"
                                >
                                    {t('capitalDepositStep2.tdfDownloadText', {companyName: company?.name ?? ''})}
                                </ButtonAction>
                            </Box>
                        </Grow>

                        <Divider className={styles.divider} />

                        <DocumentUploadStepTitle
                            title={t('capitalDepositStep2.depositCapital')}
                            stepNumber="2"
                            isCompleted={false}
                        />

                        <Grow
                            in={isSubAnimationActive}
                            timeout={FORM_ANIMATION_DELAY}
                            onExited={() => {
                                dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.SECONDARY));
                            }}
                        >
                            <Box sx={{pl: 5.1, mt: 1}}>
                                <Typography>{t('capitalDepositStep2.depositCapitalLine1', {amount: companyInformationData.amountOfShareCapital})}</Typography>
                                <Typography>{t('capitalDepositStep2.depositCapitalLine2')}</Typography>
                            </Box>
                        </Grow>

                        <Divider className={styles.divider} />

                        <DocumentUploadStepTitle
                            title={t('capitalDepositStep2.uploadDepositCertificate')}
                            stepNumber="3"
                            isCompleted={false}
                        />

                        <Grow
                            in={isSubAnimationActive}
                            timeout={FORM_ANIMATION_DELAY}
                            onExited={() => {
                                dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.SECONDARY));
                            }}
                        >
                            <Box sx={{pl: isMobileSize ? 0 : 5.1, mt: 1}}>
                                <Typography sx={{mb: 1}}>{t('capitalDepositStep2.uploadText')}</Typography>
                                <DropZone
                                    acceptFiles="application/pdf"
                                    errorMessage=""
                                    files={files}
                                    isFileRemovalDisabled={true}
                                    onDropAccepted={createDropAcceptedHandler}
                                    readableFileExtension="PDF"
                                    name="identification-document" // TODO: Check if this is a typo
                                    isDisabled={company?.status === 'REGISTRATION_COMPLETED'
                                     || company?.status === 'PENDING_FINAL_SIGS'
                                    || company?.status === 'ACTIVE'}
                                />
                            </Box>
                        </Grow>
                    </div>
                    {isMobileSize && (
                        <Box sx={{mt: 2}}>
                            <ButtonAction
                                endIcon={<ArrowForwardIcon />}
                                onClick={onNextClick}
                                color="secondary"
                                disabled={files.length === 0}
                                fullWidth
                            >
                                {t('capitalDepositStep1.continueButton')}
                            </ButtonAction>
                        </Box>
                    )}
                </Paper>
            </Slide>

            {!isMobileSize && (
            <Slide direction="left" in={isAnimationActive} timeout={BANNER_ANIMATION_DELAY * 2}>
                <Grid container item justifyContent="flex-end" sx={{mt: 3}} alignItems="center">
                    <ButtonAction
                        endIcon={<ArrowForwardIcon />}
                        onClick={onNextClick}
                        color="secondary"
                        disabled={files.length === 0}
                    >
                        {t('capitalDepositStep1.continueButton')}
                    </ButtonAction>
                </Grid>
            </Slide>
            )}
        </>
    );
};
