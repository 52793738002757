import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Actions from './Actions';
import CommonForMobile from './CommonForMobile';
import {styles} from './styles';
import {LocalesConstants} from '../../../../utils/locales-constants';

const OtherOpportunityForMobile = ({rowData}) => {
    const {t} = useTranslation(LocalesConstants.Opportunity);

    return (
        <Box sx={styles.container}>
            <CommonForMobile rowData={rowData} />
            <Box sx={styles.row}>
                <Typography variant="body2" sx={styles.label}>
                    {t('element.header.actions')}
                </Typography>
                <Box component="span" sx={styles.value}>
                    <Actions rowData={rowData} isMobile />
                </Box>
            </Box>
        </Box>
    );
};

OtherOpportunityForMobile.propTypes = {
    rowData: PropTypes.any.isRequired,
};

export default React.memo(OtherOpportunityForMobile);
