import {
    faFileArrowUp,
    faFileCircleCheck,
    faHourglassStart,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Box, Grid, IconButton, Stack, Tooltip, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useFirstMountState} from 'react-use';
// eslint-disable-next-line import/no-cycle
import DocumentSigningToolbarV3 from './DocumentSigningToolbarV3';
import {PdfViewerV3} from './PdfViewerV3';
import BottomDrawerWrapper from '../../../../../../components/joy-ui/drawer/BottomDrawerWrapper';
import Slide from '../../../../../../components/joy-ui/mui-legacy/Slide';
import {DOCUMENT_STATUSES} from '../../../../../../consts/document-constants';
import {useIsMobileSize} from '../../../../../../hooks/use-is-mobile-size';
import {useMemoizedCreateSelector} from '../../../../../../hooks/use-memoized-create-selector';
import {importLocaleBundle} from '../../../../../../lib/i18next';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {AnimationActions} from '../../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../../../animations/utils/constants';
import {MissionsClientSelector} from '../../../../../missions/modules/client-side/store/missions-client.selector';
import {SignatureSelectors} from '../../../../../signature/store/signature.selector';
import {UiActions} from '../../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../../ui/utils/constants';
import {DatabaseSelectors} from '../../../database/store/database.selector';
import {SigningActions} from '../../store/signing.action';
import {SigningSelectors} from '../../store/signing.selector';
import {DOCUMENT_SIGNING_TYPES} from '../../utils/constants';
import {DocumentSigningMobile} from '../mobile/DocumentSigningMobile';
import {DocumentSigningMobileTraining} from '../mobile/DocumentSigningMobileTraining';

importLocaleBundle('document');

const TIMEOUT_DELAY = 400;

const SIGNATURE_OFFSETS_BY_DOCUMENT_TYPE = {
    PARTNERS_LIST: 0.87,
    DNC: 0.52,
    DOMICILIATION_CERTIFICATE: 0.55,
    REGISTRATION_STATUS: 0.91,
    HOME_RENTAL_CONTRACT: 0.96,
    BANK_DELEGATED_ACCESS: 0.49,
    NON_REJECTION_ACRE_CERT: 0.49,
    CAPITAL_DEPOSIT_STATUS: 0.97,
    OGI_FRANCE_MEMBERSHIP: 0.35,
    TAX_OPTION: 0.54,
    SALARIED_PRESIDENT_CERTIFICATE: 0.34,
    POWER_OF_ATTORNEY_REGISTRATION_MANDATE: 0.75,
    HIWAY_ACCOUNTING_MISSION_LETTER: 0.25,
    CONTRACT_SIGNED: 0.95,
};

export const DocumentSigningV3 = ({
    MainActionComponent,
    type,
    isLoadingNextStep,
    signedLocale,
    isShowingSignatureBox,
    onSignFn,
    onNextFn,
    isPublic,
    sidebarSx,
    signatureContainerSx,
}) => {
    const {t} = useTranslation(LocalesConstants.Document);

    const isMobileSize = useIsMobileSize();

    const dispatch = useDispatch();
    const isFirstMount = useFirstMountState() ?? true;

    const pdfViewerRef = useRef();
    const pdfViewerContainerRef = useRef();

    const signatureUrl = useSelector(SignatureSelectors.selectUrl);

    const publicDocument = useSelector(MissionsClientSelector.selectDocumentInfo);
    const isPublicSigned = publicDocument?.documentStatus === DOCUMENT_STATUSES.SIGNED;

    const isSigned = useSelector(SigningSelectors.selectCurrentDocumentIsSigned);
    const documentId = useSelector(SigningSelectors.selectCurrentDocumentId);
    const isSigning = useSelector(SigningSelectors.selectIsSigningCurrentDocument);
    const document = useMemoizedCreateSelector(DatabaseSelectors.createDocumentByIdSelector, documentId);

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    const onSignClick = () => {
        if (onSignFn) {
            onSignFn();
        } else {
            // Sign document
            dispatch(SigningActions.signCurrentDocument());
        }
    };

    const onNextClick = () => {
        if (onNextFn) {
            onNextFn();
        } else {
            dispatch(AnimationActions.storeNextDispatch(
                SigningActions.openNextDocument(),
            ));
            dispatch(AnimationActions.setIsAnimationActive(false));
        }
    };

    const onOpenSignatureModal = () => {
        dispatch(UiActions.setActiveModal(ModalsKeys.UPLOAD_SIGNATURE, true));
    };

    const handleLoadSuccess = () => {
        if (isSigned && !isSigning && pdfViewerRef.current) {
            setTimeout(() => {
                const height = pdfViewerRef.current.size.content.height;
                const containerHeightOffset = pdfViewerContainerRef.current.offsetHeight / 2;
                let documentOffset = SIGNATURE_OFFSETS_BY_DOCUMENT_TYPE[document?.type];

                if (!document) {
                    // For contract
                    documentOffset = SIGNATURE_OFFSETS_BY_DOCUMENT_TYPE.CONTRACT_SIGNED;
                }

                let offset = height * documentOffset;
                offset -= containerHeightOffset;

                pdfViewerRef.current.scrollTo(0, offset || pdfViewerRef.current.size.content.height, 1500, {
                    easing: position => {
                        return (position === 1) ? 1 : -Math.pow(2, -10 * position) + 1;
                    },
                });
            }, 500);
        }
    };

    if (!isMobileSize) {
        return (
            <Box display="grid" gridTemplateColumns="1fr 292px" gap={2} minHeight="100%">
                <Slide
                    in={isAnimationActive}
                    direction="up"
                    onEntered={onEndedAnimation}
                    onExited={onEndedAnimation}
                >
                    <div ref={pdfViewerContainerRef}>
                        <PdfViewerV3
                            scrollRef={pdfViewerRef}
                            onLoadSuccess={handleLoadSuccess}
                        />
                    </div>
                </Slide>

                <Box sx={{height: 'min-content'}}>
                    <Slide
                        in={!isFirstMount || isAnimationActive}
                        direction="left"
                        timeout={TIMEOUT_DELAY}
                    >
                        <div>
                            <Box
                                borderRadius="md"
                                boxShadow="sm"
                                sx={{
                                    p: 2,
                                    backgroundColor: 'background.body',
                                    ...sidebarSx,
                                }}
                            >
                                {!isPublic && (
                                    <Typography
                                        level="h4"
                                        sx={{
                                            color: 'text.primary',
                                        }}
                                    >
                                        {t('signing.sidebarTitle')}
                                    </Typography>
                                )}

                                {!isPublic && (
                                    <Alert
                                        color={isSigned ? 'success' : 'warning'}
                                        variant={isSigned ? 'outlined' : 'soft'}
                                        sx={{
                                            'mt': 1,
                                            'borderRadius': 'sm',
                                            'alignItems': 'center',
                                            '& .MuiAlert-startDecorator': {
                                                pt: 0,
                                            },
                                            ...(isSigned ? {backgroundColor: 'background.body'} : {}),
                                        }}
                                        startDecorator={isSigned
                                            ? <FontAwesomeIcon icon={faFileCircleCheck} />
                                            : <FontAwesomeIcon icon={faHourglassStart} />}
                                    >
                                        {!isSigned
                                            ? t('signing.awaitingSignature')
                                            : t(signedLocale)
                                        }
                                    </Alert>
                                )}

                                <DocumentSigningToolbarV3
                                    MainActionComponent={MainActionComponent}
                                    isSigning={isSigning}
                                    isLoadingNextStep={isLoadingNextStep}
                                    documentId={documentId}
                                    isSigned={isSigned}
                                    onSignClick={onSignClick}
                                    onNextClick={onNextClick}
                                />

                                {/* {isDownloadPDFAvailable && isSigned && signatureUrl && ( */}
                                {/*    <Button */}
                                {/*        sx={{py: 0.75, borderRadius: 2, mt: 1}} */}
                                {/*        startDecorator={<DownloadIcon />} */}
                                {/*        variant="outlined" */}
                                {/*        loading={isDownloading || !documentId} */}
                                {/*        onClick={() => { */}
                                {/*            dispatch(DocumentActions.getDocument(documentId, true)); */}
                                {/*        }} */}
                                {/*        fullWidth */}
                                {/*    > */}
                                {/*        {t('signing.downloadPdf')} */}
                                {/*    </Button> */}
                                {/* )} */}
                            </Box>
                        </div>
                    </Slide>

                    <Slide
                        in={signatureUrl || isShowingSignatureBox}
                        direction="up"
                        timeout={TIMEOUT_DELAY * 2}
                    >
                        <div>
                            <Box
                                borderRadius="md"
                                boxShadow="sm"
                                sx={{
                                    p: 2,
                                    mt: 2,
                                    backgroundColor: 'background.body',
                                    ...signatureContainerSx,
                                }}
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography
                                        level="h4"
                                        sx={{
                                            color: 'text.primary',
                                        }}
                                    >
                                        {t('signing.mySignatureTitle')}
                                    </Typography>

                                    <Tooltip title={t('signing.changeSignatureTooltip')} placement="top" arrow>
                                        <IconButton
                                            color="primary"
                                            sx={{
                                                color: 'primary.plainColor',
                                            }}
                                            size="sm"
                                            onClick={onOpenSignatureModal}
                                        >
                                            <FontAwesomeIcon icon={faFileArrowUp} />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>

                                <Box sx={{mt: 2}}>
                                    {signatureUrl && (
                                        <img
                                            src={signatureUrl}
                                            alt="Signature"
                                            style={{width: '100%'}}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </div>
                    </Slide>
                </Box>
            </Box>
        );
    }

    return (
        <>
            <Grid container justifyContent="space-between" flexDirection="column">
                <div ref={pdfViewerContainerRef}>
                    <PdfViewerV3 scrollRef={pdfViewerRef} onLoadSuccess={handleLoadSuccess} />
                </div>
            </Grid>

            {type === DOCUMENT_SIGNING_TYPES.TRAINING ? (
                <BottomDrawerWrapper>
                    <DocumentSigningMobileTraining isLoadingNextStep={isLoadingNextStep} />
                </BottomDrawerWrapper>
            ) : (
                <BottomDrawerWrapper>
                    <DocumentSigningMobile
                        MainActionComponent={MainActionComponent}
                        isLoadingNextStep={isLoadingNextStep}
                        onNextClick={onNextClick}
                        onSignClick={onSignClick}
                        signedLocale={signedLocale}
                        isPublicSigned={isPublicSigned}
                        isPublic={isPublic}
                        isShowingSignature={signatureUrl || isShowingSignatureBox}
                        isV3
                    />
                </BottomDrawerWrapper>
            )}
        </>
    );
};

DocumentSigningV3.propTypes = {
    MainActionComponent: PropTypes.any,
    type: PropTypes.oneOf(Object.values(DOCUMENT_SIGNING_TYPES)),
    isLoadingNextStep: PropTypes.bool,
    signedLocale: PropTypes.string,
    onSignFn: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    onNextFn: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    isShowingSignatureBox: PropTypes.bool,
    isPublic: PropTypes.bool,
    sidebarSx: PropTypes.object,
    signatureContainerSx: PropTypes.object,
};

DocumentSigningV3.defaultProps = {
    MainActionComponent: null,
    type: DOCUMENT_SIGNING_TYPES.ONBOARDING,
    isLoadingNextStep: false,
    signedLocale: 'signing.documentSignedPolite',
    onSignFn: null,
    onNextFn: null,
    isShowingSignatureBox: false,
    isPublic: false,
    sidebarSx: {},
    signatureContainerSx: {},
};
