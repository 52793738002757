import EuroIcon from '@mui/icons-material/Euro';
import {LocalizationProvider} from '@mui/lab';
import DatePicker from '@mui/lab/DatePicker';
import {Box, Grid, InputAdornment, TextField, Typography} from '@mui/material';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {CityAutocomplete} from '../../../../components/CityAutocomplete';
import {LocalesConstants} from '../../../../utils/locales-constants';

window.moment = moment;

export const OpportunityInformations = ({control, errors, defaultFieldProps}) => {
    const {t} = useTranslation(LocalesConstants.Opportunity);

    return (
        <Box sx={{mb: 4, mt: 3}}>
            <Typography sx={{
                mb: 1.5,
                fontSize: theme => theme.typography.pxToRem(16),
                fontWeight: 600,
            }}
            >
                {t('submitOpportunity.informationsHeader')}
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} lg={4}>
                    <Controller
                        control={control}
                        name="beforeTaxes"
                        render={({field}) => (
                            <TextField
                                label={t('submitOpportunity.beforeTaxes')}
                                error={!!errors.beforeTaxes}
                                helperText={errors.beforeTaxes?.message ?? t('submitOpportunity.beforeTaxesHelperText')}
                                {...field}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <EuroIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                {...defaultFieldProps}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Controller
                        control={control}
                        name="startMonth"
                        render={({field}) => (
                            <LocalizationProvider dateLibInstance={moment} dateAdapter={AdapterMoment}>
                                <DatePicker
                                    {...field}
                                    orientation="portrait"
                                    openTo="month"
                                    inputFormat="MMMM YYYY"
                                    views={['year', 'month']}
                                    label={t('submitOpportunity.startMonth')}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            {...defaultFieldProps}
                                            error={!!errors.startMonth}
                                            helperText={errors.startMonth?.message}
                                        />
                                    )}
                                    inputProps={{
                                        readOnly: true,
                                        placeholder: t('submitOpportunity.startMonth'),
                                    }}
                                />
                            </LocalizationProvider>
                        )}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <CityAutocomplete
                        control={control}
                        name="venue"
                        label={t('submitOpportunity.venue')}
                        variant="outlined"
                        componentRestrictions={null}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

OpportunityInformations.propTypes = {
    control: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    defaultFieldProps: PropTypes.object.isRequired,
};
