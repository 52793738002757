import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Grid, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {useAttachDocumentsStyles} from './styles';
import {ModalsKeys} from '../../../features/ui/utils/constants';
import {LocalesConstants} from '../../../utils/locales-constants';
import {ModalWrapper} from '../../modal-wrapper/ModalWrapper';
import ButtonWithCircularProgress from '../ButtonWithCircularProgress';
import UploadDocuments from '../UploadDocuments';

const modalKey = ModalsKeys.ATTACH_DOCUMENTS;

const AttachDocuments = props => {
    const {
        onClose,
        isLoading,
        onSubmit,
        title,
        message,
        files,
        onAddFiles,
        onRemoveFile,
    } = props;

    const styles = useAttachDocumentsStyles();
    const {t} = useTranslation(LocalesConstants.Documents);
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const handleSubmitButtonClick = useCallback(() => {
        onSubmit(files);
    }, [files, onSubmit]);

    return (
        <ModalWrapper
            modalKey={modalKey}
            containerSx={{
                width: '900px',
            }}
            handleCloseCB={onClose}
        >
            {() => (
                <>
                    <Typography
                        sx={{mx: 1, mb: 2}}
                        variant={isMobileSize ? 'h5' : 'h4'}
                    >
                        {title || t('attachDocuments')}
                    </Typography>

                    <Box sx={{transform: 'translate3d(0, 0, 0)'}}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                mb: 3,
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{fontWeight: theme => theme.typography.fontWeightMedium}}
                            >
                                {message || t('pleaseAttachDocuments')}
                            </Typography>
                        </Box>

                        <UploadDocuments
                            files={files}
                            onAddFiles={onAddFiles}
                            onDeleteFile={onRemoveFile}
                            fileLimit={1}
                            isMultiple={false}
                            acceptFiles="image/jpeg, image/png, image/jpg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        />

                        <Grid className={styles.footerContainer} container>
                            <ButtonWithCircularProgress
                                buttonProps={{
                                    variant: 'contained',
                                    color: 'secondary',
                                    size: 'large',
                                    endIcon: <ArrowForwardIcon />,
                                    onClick: handleSubmitButtonClick,
                                    disabled: !files.length || isLoading,
                                }}
                                isLoading={isLoading}
                            >
                                {t('proceed')}
                            </ButtonWithCircularProgress>
                        </Grid>
                    </Box>
                </>
            )}
        </ModalWrapper>
    );
};

AttachDocuments.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onAddFiles: PropTypes.func.isRequired,
    onRemoveFile: PropTypes.func.isRequired,
    files: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
};

AttachDocuments.defaultProps = {
    isLoading: false,
    title: null,
    message: null,
};

export default AttachDocuments;
