import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Stack, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useIsMobileSize} from '../../../../../hooks/use-is-mobile-size';
import {LocalesConstants} from '../../../../../utils/locales-constants';

const ClientModificationsRequested = ({document, type}) => {
    const {t} = useTranslation(LocalesConstants.Client);
    const {t: userT} = useTranslation(LocalesConstants.CreateUser);

    const isMobileSize = useIsMobileSize();

    return (
        <Stack
            sx={{
                height: '100vh',
                backgroundColor: 'background.surface',
            }}
            justifyContent="center"
            alignItems="center"
        >
            <Stack
                justifyContent="center"
                alignItems="center"
                sx={isMobileSize ? {
                    height: 'calc(100vh - 100px)',
                    px: 4,
                } : {
                    borderRadius: 'xxl',
                    backgroundColor: 'background.body',
                    boxShadow: 'lg',
                    minWidth: 'calc(212px + 128px)',
                    maxWidth: '700px',
                    px: 8,
                    pt: 4,
                    pb: 5,
                }}
                gap={2}
            >
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        width: '133px',
                        height: '133px',
                        borderRadius: '50%',
                        backgroundColor: 'success.100',
                        color: 'success.softActiveColor',
                        mb: 1,
                    }}
                >
                    <FontAwesomeIcon
                        style={{
                            fontSize: '88px', // Set the custom size here
                        }}
                        icon={faCheck}
                    />
                </Stack>
                <Typography
                    level="h1"
                    textAlign="center"
                >
                    {t('cra.modificationsRequiredTitle')}
                </Typography>
                <Typography textAlign="center">
                    {t(`${type}.modificationsRequired`, {
                        userName: document?.missionInfo?.userName,
                        jobName: userT(`jobTypes.${document?.missionInfo?.jobName}`),
                    })}
                </Typography>
                <Typography textAlign="center">
                    - Hiway Portage
                </Typography>
            </Stack>
        </Stack>
    );
};

ClientModificationsRequested.propTypes = {
    document: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
};

export default ClientModificationsRequested;
