import AddCardIcon from '@mui/icons-material/AddCard';
import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import BankHelperPagesWrapper from './BankHelperPagesWrapper';
import BankFinishedImage from '../../../../assets/svg/bank/bank-finished.svg';
import ConfetiMobileImage from '../../../../assets/svg/bank/confetti-mobile.svg';
import ConfetiImage from '../../../../assets/svg/bank/confetti.svg';
import {push} from '../../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../../lib/router/route-paths';
import {LocalesConstants} from '../../../../utils/locales-constants';

const BankCreated = () => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const {t} = useTranslation(LocalesConstants.Bank);

    const dispatch = useDispatch();

    const onFinishedCreationClick = () => {
        dispatch(push(RoutePaths.BANK_CARDS));
    };

    return (
        <BankHelperPagesWrapper
            image={(
                !isMobileSize ? (
                    <Box sx={{position: 'relative'}}>
                        <Box sx={{position: 'absolute', top: '-145px', left: '-430px'}}>
                            <img
                                src={ConfetiImage}
                                alt="confeti"
                            />
                        </Box>
                        <img src={BankFinishedImage} alt="finished creation bank" />
                    </Box>
                ) : (
                    <Box sx={{position: 'relative'}}>
                        <Box sx={{position: 'absolute', top: '-150px', left: '-100px'}}>
                            <img
                                src={ConfetiMobileImage}
                                alt="confeti"
                            />
                        </Box>
                        <img src={BankFinishedImage} alt="finished creation bank" />
                    </Box>
                )
            )}
            header={t('creationCompleted.header')}
            text={t('creationCompleted.text')}
            Button={(
                <Button
                    onClick={onFinishedCreationClick}
                    variant="contained"
                    fullWidth={isMobileSize}
                    color="secondary"
                    startIcon={<AddCardIcon />}
                >
                    {t('creationCompleted.button')}
                </Button>
            )}
        />
    );
};

export default BankCreated;
