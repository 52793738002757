import moment from 'moment-timezone';

import {DEFAULT_TIME_ZONE} from './constants';
import {getEaster} from './moontool';
import {COMPANY_REGISTRATION_TYPE} from '../features/company/utils/constants';

// TODO:HIGH Fix up the rounding so it's the same as on API.
export const getTwoDecimals = num => (Math.round((Number(num) + Number.EPSILON) * 100) / 100).toFixed(2);

export const isPublicHoliday = date => {
    const year = date.year();

    // Make some adjustments
    const easter = getEaster(year);

    const holidays = FRENCH_PUBLIC_HOLIDAYS.map(holiday => {
        return {
            name: holiday.name,
            date: moment.tz({
                ...holiday.date,
                year,
            }, DEFAULT_TIME_ZONE),
        };
    });

    holidays.push({
        name: PUBLIC_HOLIDAY_KEYS.EASTER,
        date: moment.tz(easter, DEFAULT_TIME_ZONE),
    });

    holidays.push({
        name: PUBLIC_HOLIDAY_KEYS.EASTER_MONDAY,
        date: moment.tz(easter, DEFAULT_TIME_ZONE).add(1, 'day'),
    });

    holidays.push({
        name: PUBLIC_HOLIDAY_KEYS.ASCENSION_DAY,
        date: moment.tz(easter, DEFAULT_TIME_ZONE).add(39, 'day'),
    });

    holidays.push({
        name: PUBLIC_HOLIDAY_KEYS.WHIT_MONDAY,
        date: moment.tz(easter, DEFAULT_TIME_ZONE).add(50, 'day'),
    });

    return !!holidays.find(holiday => holiday.date.isSame(date, 'day'));
};

const PUBLIC_HOLIDAY_KEYS = {
    NEW_YEARS_DAY: 'NEW_YEARS_DAY',
    LABOR_DAY: 'LABOR_DAY',
    VICTORY_IN_EUROPE: 'VICTORY_IN_EUROPE',
    BASTILLE_DAY: 'BASTILLE_DAY',
    ASSUMPTION_OF_MARY: 'ASSUMPTION_OF_MARY',
    ALL_SAINTS_DAY: 'ALL_SAINTS_DAY',
    WORLD_WAR_I_ARMISTICE: 'WORLD_WAR_I_ARMISTICE',
    CHRISTMAS: 'CHRISTMAS',
    EASTER: 'EASTER',
    EASTER_MONDAY: 'EASTER_MONDAY',
    ASCENSION_DAY: 'ASCENSION_DAY',
    WHIT_MONDAY: 'WHIT_MONDAY',
};

export const FRENCH_PUBLIC_HOLIDAYS = [
    {
        name: PUBLIC_HOLIDAY_KEYS.NEW_YEARS_DAY,
        date: {month: 0, day: 1},
    },
    {
        name: PUBLIC_HOLIDAY_KEYS.LABOR_DAY,
        date: {month: 4, day: 1},
    },
    {
        name: PUBLIC_HOLIDAY_KEYS.VICTORY_IN_EUROPE,
        date: {month: 4, day: 8},
    },
    {
        name: PUBLIC_HOLIDAY_KEYS.BASTILLE_DAY,
        date: {month: 6, day: 14},
    },
    {
        name: PUBLIC_HOLIDAY_KEYS.ASSUMPTION_OF_MARY,
        date: {month: 7, day: 15},
    },
    {
        name: PUBLIC_HOLIDAY_KEYS.ALL_SAINTS_DAY,
        date: {month: 10, day: 1},
    },
    {
        name: PUBLIC_HOLIDAY_KEYS.WORLD_WAR_I_ARMISTICE,
        date: {month: 10, day: 11},
    },
    {
        name: PUBLIC_HOLIDAY_KEYS.CHRISTMAS,
        date: {month: 11, day: 25},
    },
];

// Checks if string is a date, must be in DD/MM/YYYY format
export const isDate = value => /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(value);

export const getIsRegistrationWithExistingCompany = registrationType => (
    [
        COMPANY_REGISTRATION_TYPE.REPRISE,
        COMPANY_REGISTRATION_TYPE.TRANSFORMATION,
    ].includes(registrationType)
);

export const isWorkingDay = date => {
    const dayNum = date.day();
    if (dayNum === 6 || dayNum === 0) {
        return false;
    }
    return !isPublicHoliday(date);
};

export const getFormattedMonthWorkingDays = (startDay, endDay, formatDate) => {
    const clonedStartDay = startDay.clone();
    const clonedEndDay = endDay.clone().add(1, 'day');

    const days = [];

    while (!clonedStartDay.isSame(clonedEndDay, 'day')) {
        if (!isPublicHoliday(clonedStartDay) && isWorkingDay(clonedStartDay)) {
            days.push(clonedStartDay.format(formatDate));
        }
        clonedStartDay.add(1, 'day');
    }

    return days;
};
