import {combineReducers} from 'redux';
import {BankActionTypes} from './bank.action-type';
import {createReducer} from '../../../app/utils/store/create-reducer';
import {transactionsReducer} from '../modules/account-balance/store/transactions.reducer';
import {cardsReducer} from '../modules/cards/store/cards.reducer';
import {ribAndDocumentsReducer} from '../modules/rib-and-documents/store/ribAndDocuments.reducer';
import {beneficiariesReducer} from '../modules/transfers/store/beneficiaries.reducer';
import {transfersReducer} from '../modules/transfers/store/transfers.reducer';

export const bankReducer = combineReducers({
    activeIntegrations: (state = [], action) => {
        if (action.type === BankActionTypes.STORE_ALL_INTEGRATION_DATA) {
            return action.payload?.nonArchived ?? [];
        } else if (action.type === BankActionTypes.STORE_ACTIVE_INTEGRATION_DATA) {
            return action.payload;
        }

        return state;
    },
    archivedIntegrations: (state = [], action) => {
        if (action.type === BankActionTypes.STORE_ALL_INTEGRATION_DATA) {
            return action.payload?.archived ?? [];
        } else if (action.type === BankActionTypes.STORE_ARCHIVED_INTEGRATION_DATA) {
            return action.payload;
        }

        return state;
    },
    isBankMenuFullyActive: createReducer(false, BankActionTypes.STORE_BANK_MENU_IS_FULLY_ACTIVE, false),
    qrCodeEvent: createReducer(null, BankActionTypes.UPLOAD_FILE_QR_CODE_EVENT, true),
    capitalDepositError: createReducer(false, BankActionTypes.SET_CAPITAL_DEPOSIT_ERROR, true),
    capitalDeposit: createReducer(null, BankActionTypes.STORE_CAPITAL_DEPOSIT, false),
    beneficiaries: beneficiariesReducer,
    transfers: transfersReducer,
    cards: cardsReducer,
    ribAndDocuments: ribAndDocumentsReducer,
    transactions: transactionsReducer,
});
