import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import React from 'react';
import {useTranslation} from 'react-i18next';
import PageHeader from '../../components/headers/PageHeader/PageHeader';
import ChangePasswordForm from '../../features/change-password/components/ChangePasswordForm';
import {LocalesConstants} from '../../utils/locales-constants';

export const ChangePasswordScreen = () => {
    const {t} = useTranslation(LocalesConstants.Login);

    return (
        <Box sx={{px: {xs: 2, md: 1}}}>
            <Box component="div" sx={{mt: {lg: 0, xs: 3}}}>
                <PageHeader title={t('changePassword')} hasMargin={false} />
            </Box>

            <Paper elevation={1} sx={{marginTop: theme => theme.spacing(3)}}>
                <ChangePasswordForm />
            </Paper>
        </Box>
    );
};
