import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {LoadingButton, TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Tab, Typography} from '@mui/material';
import Chip from '@mui/material/Chip';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import DataItemRow from '../../../components/ui-kit/DataItemRow';
import DataItemRowLabel from '../../../components/ui-kit/DataItemRowLabel';
import ExpandingContainer from '../../../components/ui-kit/ExpandingContainer';
import NoInput from '../../../components/ui-kit/NoInput/NoInput';
import {Toast} from '../../../lib/toast';
import {swapArrayElements} from '../../../utils/array';
import {copyToClipboard} from '../../../utils/copy-to-clipboard';
import {LocalesConstants} from '../../../utils/locales-constants';
import {BridgeActions} from '../../bank/modules/bridge-api/store/bridge-api.action';
import {getIntegrationInfo} from '../../bank/modules/bridge-api/utils/bridge-util';
import {BANK_TYPES} from '../../bank/modules/bridge-api/utils/constants';
import {BankActions} from '../../bank/store/bank.action';
import {BankSelector} from '../../bank/store/bank.selector';
import {createContactInfoAddress} from '../../company-profile/components/EnterpriseInformationForm/util';
import {FreelancerSelectors} from '../../freelancer';
import {CompaniesSelectors} from '../../freelancer/modules/companies';
import {LoadingTypes, useLoadingState} from '../../loading';

export const BankAccountContainer = ({company}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const {t} = useTranslation(LocalesConstants.Companies);
    const dispatch = useDispatch();

    const integrations = useSelector(BankSelector.selectIntegrations);
    const archivedIntegrations = useSelector(BankSelector.selectIntegrationsWithArchived);

    const freelancer = useSelector(FreelancerSelectors.selectAccount);
    const userCompany = useSelector(CompaniesSelectors.createCompanyByIdSelector(freelancer?.defaultCompanyId));

    const usedCompany = company ?? userCompany;
    const hiwayAddress = createContactInfoAddress(usedCompany?.companyInfo?.hiway_bank_info);

    const integrationsData = useMemo(() => {
        let defaultIndex;
        const integrationsData = integrations.map(integration => {
            const {bankAccountHolder, account: bankAccount} = getIntegrationInfo(integration);

            if (!bankAccount) {
                return null;
            }

            const isHiway = integration?.type === BANK_TYPES.hiway;

            return {
                tabId: integration?.id,
                tabName: isHiway ? 'HIWAY' : integration?.bankName,
                iban: bankAccount?.iban,
                bic: bankAccount?.bic,
                name: bankAccount?.name,
                address: isHiway && hiwayAddress ? hiwayAddress : bankAccount?.address,
                isDefault: bankAccount?.isDefault ?? false,
                bankAccountHolderId: bankAccountHolder?.id,
                bankAccountId: bankAccount?.id,
            };
        }).filter(x => x);

        integrationsData.forEach((integration, index) => {
            if (integration?.isDefault) {
                defaultIndex = index;
            }
        });

        if (defaultIndex) {
            return swapArrayElements(integrationsData, 0, defaultIndex);
        }

        return integrationsData;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integrations]);

    const archivedIntegrationsData = useMemo(() => {
        return archivedIntegrations.map(integration => {
            const {bankAccountHolder, account: bankAccount} = getIntegrationInfo(integration);

            const isHiway = integration?.type === BANK_TYPES.hiway;

            return {
                tabId: integration?.id,
                tabName: isHiway ? 'HIWAY' : integration?.bankName,
                iban: bankAccount?.iban,
                bic: bankAccount?.bic,
                name: bankAccount?.name,
                address: isHiway && hiwayAddress ? hiwayAddress : bankAccount?.address,
                isDefault: false,
                bankAccountHolderId: bankAccountHolder?.id,
                bankAccountId: bankAccount?.id,
                isArchived: true,
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integrations]);

    const [tab, setTab] = useState('');

    useEffect(() => {
        if (integrationsData?.length > 0 && !tab) {
            setTab(integrationsData[0].tabId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integrationsData]);

    useEffect(() => {
        if (company) {
            dispatch(BankActions.getUserIntegrationData(company.userId));
        }
        // eslint-disable-next-line
    }, [company]);

    // If there are no integrations don't show panel
    if (integrationsData?.length === 0) {
        return null;
    }

    return (
        <ExpandingContainer title={t('bankAccount.title')}>
            <TabContext
                value={tab}
            >
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList
                        variant={isMobileSize ? 'scrollable' : 'fullWidth'}
                        onChange={(_, value) => setTab(value)}
                    >
                        {integrationsData.map(integration => {
                            return (
                                <Tab
                                    key={integration.tabId}
                                    label={(
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 0.5,
                                        }}
                                        >
                                            <Typography>{integration.tabName}</Typography>
                                            <Box>{integration?.isDefault
                                                && (
                                                <Chip
                                                    sx={{
                                                        lineHeight: '14px',
                                                        textTransform: 'none !important',
                                                    }}
                                                    size="small"
                                                    label={t('bankAccount.default')}
                                                    color="secondary"
                                                />
                                                )}
                                            </Box>
                                        </Box>
                                    )}
                                    value={integration.tabId}
                                />
                            );
                        })}

                        {archivedIntegrationsData.map(integration => {
                            return (
                                <Tab
                                    key={integration.tabId}
                                    label={(
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 0.5,
                                        }}
                                        >
                                            <Typography>{integration.tabName}</Typography>
                                            <Box>
                                                <Chip
                                                    sx={{
                                                        lineHeight: '14px',
                                                        textTransform: 'none !important',
                                                        backgroundColor: 'v2.gray.500',
                                                        color: 'white',
                                                    }}
                                                    size="small"
                                                    label={t('bankAccount.archived')}

                                                />
                                            </Box>
                                        </Box>
                                    )}
                                    value={integration.tabId}
                                />
                            );
                        })}
                    </TabList>
                </Box>

                {integrationsData.map(integration => {
                    return (
                        <BankContainerItem
                            key={integration.tabId}
                            integration={integration}
                            company={company}
                            t={t}
                        />
                    );
                })}

                {archivedIntegrationsData.map(integration => {
                    return (
                        <BankContainerItem
                            key={integration.tabId}
                            integration={integration}
                            company={company}
                            t={t}
                        />
                    );
                })}
            </TabContext>
        </ExpandingContainer>
    );
};

BankAccountContainer.propTypes = {
    company: PropTypes.object,
};

BankAccountContainer.defaultProps = {
    company: null,
};

const BankContainerItem = ({integration, t, company}) => {
    const dispatch = useDispatch();

    const isLoading = useLoadingState(LoadingTypes.SET_DEFAULT_BANK);

    const handleDefaultChange = () => {
        dispatch(BridgeActions.setDefaultBank(
            {
                id: integration.bankAccountId,
                bankAccountHolderId: integration.bankAccountHolderId,
                userId: company?.userId,
            },
        ));
    };

    return (
        <TabPanel value={integration.tabId}>
            <DataItemRow
                label={<DataItemRowLabel>{t('bankAccount.iban')}</DataItemRowLabel>}
                content={integration.iban ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            copyToClipboard(integration.iban);
                            Toast.success('copiedToClipboard');
                        }}
                    >
                        <Typography noWrap>
                            {integration.iban}
                        </Typography>
                        <ContentCopyIcon
                            sx={{
                                cursor: 'pointer',
                                color: 'v2.light.action.active',
                            }}

                        />
                    </Box>
                ) : <NoInput />}
            />
            <DataItemRow
                label={<DataItemRowLabel>{t('bankAccount.bic')}</DataItemRowLabel>}
                content={integration.bic ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            copyToClipboard(integration.bic);
                            Toast.success('copiedToClipboard');
                        }}
                    >
                        <Typography noWrap>
                            {integration.bic}
                        </Typography>
                        <ContentCopyIcon
                            sx={{
                                cursor: 'pointer',
                                color: 'v2.light.action.active',
                            }}
                        />
                    </Box>
                ) : <NoInput />}
            />
            <DataItemRow
                label={<DataItemRowLabel>{t('bankAccount.name')}</DataItemRowLabel>}
                content={integration.name ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            copyToClipboard(integration.name);
                            Toast.success('copiedToClipboard');
                        }}
                    >
                        <Typography noWrap>
                            {integration.name}
                        </Typography>
                        <ContentCopyIcon
                            sx={{
                                cursor: 'pointer',
                                color: 'v2.light.action.active',
                            }}
                        />
                    </Box>
                ) : <NoInput />}
            />

            <DataItemRow
                label={<DataItemRowLabel>{t('bankAccount.address')}</DataItemRowLabel>}
                content={integration.address ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            copyToClipboard(integration.address);
                            Toast.success('copiedToClipboard');
                        }}
                    >
                        <Typography noWrap>
                            {integration.address}
                        </Typography>
                        <ContentCopyIcon
                            sx={{
                                cursor: 'pointer',
                                color: 'v2.light.action.active',
                            }}
                        />
                    </Box>
                ) : <NoInput />}
            />

            {!integration?.isArchived && (
            <DataItemRow
                label={<DataItemRowLabel>{t('bankAccount.isDefault')}</DataItemRowLabel>}
                content={(
                    <>
                        <LoadingButton
                            loading={isLoading}
                            onClick={handleDefaultChange}
                            variant="text"
                            startIcon={<DoneAllIcon />}
                            disabled={integration.isDefault}
                        >
                            {t('bankAccount.makeDefault')}
                        </LoadingButton>
                    </>
                )}
            />
            )}

        </TabPanel>
    );
};

BankContainerItem.propTypes = {
    integration: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    company: PropTypes.object,
};

BankContainerItem.defaultProps = {
    company: null,
};
