import {
    faCircleCheck,
    faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Chip} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ConditionalTooltip} from '../../../../../../components/tooltip/ConditionalTooltip';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {BANK_TYPES} from '../../../bridge-api/utils/constants';

export const AccountsMenuItemChip = ({
    value,
    uncategorized,
    hasError,
    isSelected,
    isMobile,
}) => {
    const {t} = useTranslation(LocalesConstants.Bank);

    if (value === BANK_TYPES.archived) {
        if (isMobile) {
            return null;
        }

        return (
            <ConditionalTooltip
                isV3
                tooltip={t('accountArchiving.tabSubtitle')}
            >
                <Box color="text.tertiary">
                    <FontAwesomeIcon icon={faCircleInfo} />
                </Box>
            </ConditionalTooltip>
        );
    }

    if (uncategorized !== 0) {
        return (
            <Chip
                color={hasError && !isSelected ? 'warning' : 'primary'}
                size="md"
                variant={hasError && !isSelected ? 'solid' : 'soft'}
                sx={{
                    fontStyle: 'normal',
                }}
            >
                {uncategorized}
            </Chip>
        );
    }

    return (
        <Box fontSize="lg" color="text.success">
            <FontAwesomeIcon icon={faCircleCheck} />
        </Box>
    );
};

AccountsMenuItemChip.propTypes = {
    value: PropTypes.string,
    uncategorized: PropTypes.number.isRequired,
    hasError: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
};

AccountsMenuItemChip.defaultProps = {
    value: null,
};
