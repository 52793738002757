export const MissionsCraActionTypes = {
    GET_CRA_DOCUMENTS: 'missionsCra/GET_CRA_DOCUMENTS',
    STORE_CRA_DOCUMENTS: 'missionsCra/STORE_CRA_DOCUMENTS',
    GET_SIGNING_URL: 'missionsCra/GET_SIGNING_URL',
    GENERATE_CRA_CONTRACT: 'missionsCra/GENERATE_CRA_CONTRACT',
    GET_CRA_CONTRACT: 'missionsCra/GET_CRA_CONTRACT',
    CHANGE_MISSION_CRA_SCREEN: 'missionsCra/CHANGE_MISSION_CRA_SCREEN',
    STORE_CRA_CONTRACT: 'missionsCra/STORE_CRA_CONTRACT',
    SIGN_CRA_CONTRACT: 'missionsCra/SIGN_CRA_CONTRACT',
    SEND_CRA_CONTRACT: 'missionsCra/SEND_CRA_CONTRACT',
};
