import {MissionSigningActionType} from './missions-signing.action-type';

export const MissionSigningActions = {
    getGenerateQuoteContract: id => ({
        type: MissionSigningActionType.GET_QUOTA_CONTRACT,
        payload: id,
    }),
    storeGenerateQuoteContract: document => ({
        type: MissionSigningActionType.STORE_QUOTA_CONTRACT,
        payload: document,
    }),
    postSignQuotaContract: data => ({
        type: MissionSigningActionType.SIGN_QUOTA_CONTRACT,
        payload: {
            contractId: data?.contractId,
            missionId: data?.missionId,
        },
    }),
    postSendQuotaContract: data => ({
        type: MissionSigningActionType.SEND_QUOTA_CONTRACT,
        payload: {
            contractId: data?.contractId,
            missionId: data?.missionId,
        },
    }),
    // TODO Check if needed
    postGenerateQuoteContract: () => ({
        type: MissionSigningActionType.GENERATE_QUOTA_CONTRACT,
    }),
};
