import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {Pagination} from '@mui/lab';
import {Badge, Box, Button, Paper, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import MyBid from './MyBid';
import MyServicesSVg from '../../../../assets/svg/invoicing/my-service.svg';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {OpportunitySelector} from '../../store/opportunity.selector';
import {calculatePage} from '../../utls/pagination';

const PAGE_SIZE = 3;

const MyBidsCard = ({scrollToTable}) => {
    const {t} = useTranslation(LocalesConstants.Opportunity);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const [page, setPage] = useState(1);

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(
            LoadingTypes.GET_MY_BIDS,
        ),
    );

    const myBidsList = useSelector(OpportunitySelector.selectMyBids);

    const count = myBidsList.length;

    const listToShow = calculatePage(page, count, myBidsList, PAGE_SIZE);

    const onPaginationChange = page => {
        setPage(page);
    };

    return (
        <Paper
            sx={{
                width: isMobileSize ? '100%' : '33%',
                borderRadius: 4,
                p: isMobileSize ? 2 : 3,
                pt: 2,
                color: 'text_v2.secondary',
                display: 'flex',
                flexDirection: 'column',
                alignSelf: isMobileSize ? 'flex-start' : 'stretch',
            }}
            elevation={1}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '100%',
            }}
            >
                <Typography
                    variant="h5"
                    sx={{
                        alignSelf: 'flex-start',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {t('myBids.title')}
                    {count !== 0 && (
                        <Badge
                            badgeContent={count}
                            color="v2Grey300Palette"
                            sx={{
                                color: 'white !important',
                                ml: 2.5,
                                fontWeight: 500,
                            }}
                        />
                    )}
                </Typography>

                {isLoading || !count ? (
                    <>
                        {!isMobileSize && (
                            <Box><img src={MyServicesSVg} alt="My Bids" /></Box>
                        )}

                        <Typography sx={{textAlign: 'center'}}>
                            {t('myBids.description')}
                        </Typography>

                        <Button
                            onClick={scrollToTable}
                            variant="outlined"
                            color="primary"
                            endIcon={<ArrowDownwardIcon />}
                            fullWidth
                        >
                            {t('myBids.button')}
                        </Button>
                    </>
                ) : (
                    <>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignSelf: 'stretch',
                            mb: 'auto',
                        }}
                        >
                            {listToShow.map((bid, index) => (
                                <MyBid key={bid.id} bid={bid} isFirst={!index} />
                            ))}
                        </Box>

                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <Pagination
                                size="small"
                                color="blueGray"
                                count={Math.ceil(count / PAGE_SIZE)}
                                page={page}
                                onChange={(_, page) => {
                                    onPaginationChange(page);
                                }}
                            />
                        </Box>
                    </>
                )}
            </Box>
        </Paper>
    );
};

MyBidsCard.propTypes = {
    scrollToTable: PropTypes.func.isRequired,
};

export default MyBidsCard;

