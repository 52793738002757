import moment from 'moment';
import {all, call, fork, put, select, takeLatest} from 'redux-saga/effects';
import * as actions from './company-list.actions';
import {selectCompanies} from './company-list.selectors';
import hiwayLocalStorage from '../../../../config/storage';
import {TRAINING_FILTER_OFFSET, TRAINING_STATUSES} from '../../../../consts/training-constants';
import {selectRouterLocation} from '../../../../lib/router/connected-router-saga';
import {Toast} from '../../../../lib/toast';
import {Debug} from '../../../../utils/debug';
import {FULL_USER_ROLES} from '../../../../utils/user-roles';
import {BankApi} from '../../../bank/api/bank.api';
import {BankAccountStatus} from '../../../bank/modules/bridge-api/utils/constants';
import {LoadingActions, LoadingTypes} from '../../../loading';
import {SidebarSelectors} from '../../../sidebar/store/sidebar.selector';
import {getCoachesRequest} from '../../../user-list/api/user.api';
import {storeCoachAccounts} from '../../../user/store/user.actions';
import {selectCoachAccounts} from '../../../user/store/user.selectors';
import {getCompaniesRequest} from '../../modules/documents/api/company.api';
import {COMPANY_STATUSES} from '../../modules/setup-company/utils/constants';

const getTrainingCompanies = function* (companies) {
    const trainingCompanies = {};
    const now = moment();

    Object.values(companies).forEach(company => {
        const endDate = moment(company.trainingEndDate, 'DD/MM/YYYY').subtract(TRAINING_FILTER_OFFSET, 'days');
        const isOngoing = company.trainingStatus
            && company.trainingStatus !== TRAINING_STATUSES.COMPLETED
            && company.trainingStatus !== TRAINING_STATUSES.PENDING;
        const hasInvoice = !!company.trainingInvoiceId;

        if (isOngoing && now.isSameOrAfter(endDate, 'day') && !hasInvoice) {
            trainingCompanies[company.id] = company;
        }
    });

    yield put(actions.storeTrainingCompanies(trainingCompanies));
};

// TODO Move to const file after v1 -> v2
const companiesSortValuesEnum = {
    name: 'name',
    userFullName: 'user',
    coachFullName: 'coach',
    legalForm: 'legal_form',
    status: 'status',
    activityStartDate: 'start_date',
    payInfoStatus: 'pay_info_status',
};

const getCompaniesSaga = function* () {
    yield put(actions.setIsLoadingCompanies(true));

    const location = yield select(selectRouterLocation);
    const selectedRole = yield select(SidebarSelectors.selectSelectedRole);

    const search = location?.query;

    const storageKey = `companies-savedRowsPerPage`;
    const limit = search?.['companies-rowsPerPage'] ?? (hiwayLocalStorage.has(storageKey)
        ? parseInt(hiwayLocalStorage.get(storageKey), 10)
        : 10);

    const params = {
        limit: limit,
        offset: search?.['companies-page'] ? (parseInt(search['companies-page'], 10)) * limit : 0,
        sortColumn: search?.['companies-sortBy'] ? companiesSortValuesEnum[search['companies-sortBy']] : undefined,
        sortDirection: search?.['companies-sortBy']
            ? search?.['companies-sortDirection'] ? search?.['companies-sortDirection'].toUpperCase() : 'DESC'
            : undefined,
        statuses: search?.['companies-tabFilter']
        && search?.['companies-tabFilter'] !== COMPANY_STATUSES.USER_DEACTIVATED
            ? [search?.['companies-tabFilter']] : ['ACTIVE'],
        filterQuery: {
            coach_id: search?.['companies-coachId'],
            legalForm: search?.['companies-legalForm'],
            active: search?.['companies-tabFilter'] === COMPANY_STATUSES.USER_DEACTIVATED ? false : undefined,
            hasHiwayPro: search?.['companies-activeFilter'] === 'hiway-bank-filter' ? true : undefined,
        },
        searchTerm: search?.['companies-searchTerm'],
        roles: !selectedRole || Array.isArray(selectedRole) ? undefined : [selectedRole],
    };

    try {
        const data = yield call(getCompaniesRequest, params);

        const freelancerIds = Array.from(Object.keys(data?.companies), company => data?.companies[company].userId);

        // TODO Remove
        yield call(getTrainingCompanies, data?.companies);

        yield put(actions.storeHiwayCompanies(data?.hiwayTotal));
        yield put(actions.storeCompanies(data?.companies));
        yield put(actions.storeCompaniesTotal(data?.total));

        if (freelancerIds?.length) {
            yield fork(connectBankIntegrationsWithCompanies, freelancerIds);
        }

        yield put(actions.setIsLoadingCompanies(false));
    } catch (error) {
        // TODO:LOW Better error handling.
        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingCompanies(false));
    }
};

const connectBankIntegrationsWithCompanies = function* (freelancerIds) {
    yield put(LoadingActions.setLoading(LoadingTypes.CONNECT_BANK_INTEGRATIONS_WITH_COMPANIES, true));

    try {
        const bankDetails = yield call(BankApi.getBankDetails, freelancerIds);

        const companies = yield select(selectCompanies);

        const newCompaniesData = yield Object.keys(companies).reduce((acc, curr) => {
            const {bankAccountOverview} = bankDetails?.find(bank => bank?.freelancerId === companies?.[curr]?.userId)
            ?? {bankAccountOverview: {}};

            return {
                ...acc,
                [curr]: {
                    ...companies[curr],
                    bankIntegrations: bankAccountOverview
                        ?.overviewPerAccount
                        ?.filter(bank => bank?.status === BankAccountStatus.ACTIVE),
                    totalUncategorizedTransactions: bankAccountOverview?.totalUncategorizedTransactions,
                },
            };
        }, {});

        yield put(actions.storeCompanies(newCompaniesData));

        yield put(LoadingActions.setLoading(LoadingTypes.CONNECT_BANK_INTEGRATIONS_WITH_COMPANIES, false));
    } catch (error) {
        Debug.error('companyList', 'Error: ', {error});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.CONNECT_BANK_INTEGRATIONS_WITH_COMPANIES, false));
    }
};

const getCoachesSaga = function* () {
    try {
        const users = yield call(getCoachesRequest, [FULL_USER_ROLES.COACH, FULL_USER_ROLES.ADMINISTRATOR]);

        const coaches = Object.values(users).map(user => ({
            key: user?.id,
            label: `${user?.fullName}`,
        }));

        yield put(storeCoachAccounts(coaches));
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
};

export const companyListLoaderSaga = function* () {
    yield put(actions.getCompanies());

    const coaches = yield select(selectCoachAccounts);
    if (coaches?.length === 0) {
        yield put(actions.getCoaches());
    }
};

export const watchCompanyListSagas = function* () {
    yield all([
        takeLatest(actions.GET_COMPANIES, getCompaniesSaga),
        takeLatest(actions.GET_COACHES, getCoachesSaga),
    ]);
};
