import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import {LoadingButton} from '@mui/lab';
import {Box, FormControlLabel, FormGroup} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Checkbox} from '../../../../components/checkbox/Checkbox';
import {LocalesConstants} from '../../../../utils/locales-constants';

export const DossierDocumentDownloadButtons = ({
    onButtonClick,
    onCheckboxClick,
    isChecked,
    isLoading,
    onDownloadClick,
    isDownloading,
}) => {
    const {t} = useTranslation(LocalesConstants.Training);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <>
            <Box sx={isMobileSize ? {mb: 2} : {}}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox defaultChecked={false} onClick={onCheckboxClick} />
                        }
                        label={t('dossier.checkbox')}
                    />
                </FormGroup>
            </Box>
            <Box sx={isMobileSize ? {display: 'flex', flexDirection: 'column'} : {}}>
                <LoadingButton
                    sx={isMobileSize ? {mb: 2} : {mr: 2}}
                    fullWidth={isMobileSize}
                    startIcon={<DownloadForOfflineIcon />}
                    onClick={onDownloadClick}
                    color="primary"
                    variant={!isChecked ? 'contained' : 'outlined'}
                    disableElevation
                    loading={isDownloading}
                >
                    {t('dossier.download')}
                </LoadingButton>
                <LoadingButton
                    fullWidth={isMobileSize}
                    endIcon={<ArrowForwardIcon />}
                    onClick={onButtonClick}
                    color="secondary"
                    variant="contained"
                    disableElevation
                    disabled={!isChecked}
                    loading={isLoading}
                >
                    {t('dossier.submit')}
                </LoadingButton>
            </Box>
        </>
    );
};

DossierDocumentDownloadButtons.propTypes = {
    onButtonClick: PropTypes.func.isRequired,
    onDownloadClick: PropTypes.func.isRequired,
    onCheckboxClick: PropTypes.func.isRequired,
    isChecked: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
    isDownloading: PropTypes.bool,
};

DossierDocumentDownloadButtons.defaultProps = {
    isLoading: false,
    isDownloading: false,
};
