import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import * as actions from './sign-contract.actions';
import {selectContractDocumentId} from './sign-contract.selectors';
import {getContractUrlRequest, signContractRequest} from '../../../../app/api/providers/freelancer/freelancer.provider';
import {Toast} from '../../../../lib/toast';
import {selectCurrentCognitoUser} from '../../../auth/store/auth.selectors';
import {getLoggedInUser} from '../../../user/store/user.actions';

const getContractUrlSaga = function* () {
    try {
        yield put(actions.setIsLoadingContractUrl(true));

        const currentCognitoUser = yield select(selectCurrentCognitoUser);

        const {signedUrl, documentId} = yield call(getContractUrlRequest, currentCognitoUser.id);

        yield put(actions.storeContractUrl(signedUrl));
        yield put(actions.storeContractDocumentId(documentId));
    } catch (error) {
        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingContractUrl(false));
    }
};

const signContractSaga = function* () {
    try {
        yield put(actions.setIsSigningContractUrl(true));

        const currentCognitoUser = yield select(selectCurrentCognitoUser);
        const contractDocumentId = yield select(selectContractDocumentId);

        yield call(signContractRequest, currentCognitoUser.id, contractDocumentId);

        const {signedUrl} = yield call(getContractUrlRequest, currentCognitoUser.id);

        yield put(actions.storeContractUrl(signedUrl));

        Toast.success('contractSigned');

        yield put(getLoggedInUser());
    } catch (error) {
        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsSigningContractUrl(false));
    }
};

export const watchSignContractSagas = function* () {
    yield all([
        takeLatest(actions.GET_CONTRACT_URL, getContractUrlSaga),
        takeLatest(actions.SIGN_CONTRACT, signContractSaga),
    ]);
};
