import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import * as actions from './home-office-travels-form.actions';
import {
    createCompanyBusinessKmTravelRequest,
    updateCompanyBusinessKmTravelRequest,
} from '../../../../app/api/providers/expenses/expenses.provider';
import {getConfig} from '../../../../config/get-config';
import {push} from '../../../../lib/router/connected-router-saga';
import {Toast} from '../../../../lib/toast';
import {selectFreelancerAccount} from '../../../freelancer/store/freelancer.selectors';
import {LoggedInUserSelectors} from '../../../user/modules/logged-in-user';
import {setCurrentlyEditingTravel} from '../../modules/business-allowance/store/business-allowance.actions';

const submitHomeOfficeTravelsFormSaga = function* ({payload}) {
    try {
        yield put(actions.setIsLoadingHomeOfficeTravelsForm(true));

        let freelancerAccount = yield select(selectFreelancerAccount);

        if (!freelancerAccount) {
            freelancerAccount = yield select(LoggedInUserSelectors.selectLoggedInUser);
        }


        // TODO:CRITICAL move to DTO
        const formattedData = {
            type: 'HOME_OFFICE',
            fiscal_power: payload.data.fiscalPower,
            distance: payload.data.roundTripDistance,
            number_of_days: payload.data.numberOfDays,
            routes: [payload.data.departure, payload.data.arrival],
            date: payload.data.date,
            vehicle_type: payload.data.vehicleType,
            engine_type: payload.data.engineType,
        };

        if (payload.isEdit) {
            yield call(
                updateCompanyBusinessKmTravelRequest,
                freelancerAccount.id,
                freelancerAccount.defaultCompanyId,
                payload.travelId,
                formattedData,
            );
        } else {
            yield call(
                createCompanyBusinessKmTravelRequest,
                freelancerAccount.id,
                freelancerAccount.defaultCompanyId,
                formattedData,
            );
        }

        yield put(setCurrentlyEditingTravel(null));

        if (payload.onSuccess && typeof payload.onSuccess === 'function') {
            payload.onSuccess();
        }

        yield put(actions.setIsLoadingHomeOfficeTravelsForm(false));

        Toast.success(payload.isEdit ? 'businessKmTravelUpdated' : 'businessKmTravelCreated');

        yield put(push(getConfig().ROUTE_PATHS.BUSINESS_KILOMETERS_ALLOWANCE));
    } catch (error) {
        Toast.error('anErrorOccurred');

        yield put(actions.setIsLoadingHomeOfficeTravelsForm(false));
    }
};

export const watchHomeOfficeTravelsFormSagas = function* () {
    yield all([
        takeLatest(actions.SUBMIT_HOME_OFFICE_TRAVELS_FORM, submitHomeOfficeTravelsFormSaga),
    ]);
};
