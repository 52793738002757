import {Box, CardMedia, Paper, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {debounce} from '../../../../utils/debounce';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {CLUSTER_VIDEO_LINK} from '../../utls/constants';

const calculateVideoHeight = width => {
    const ratioX = 512;
    const ratioY = 284;

    return (ratioY * width) / ratioX;
};

export const ConceptCard = () => {
    const {t} = useTranslation(LocalesConstants.Opportunity);

    const ref = useRef(null);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const [width, setWidth] = useState(512);

    const updateWidthValue = debounce(
        () => {
            setWidth(ref.current?.offsetWidth ?? 512);
        },
        200,
    );

    useEffect(() => {
        updateWidthValue();
    // eslint-disable-next-line
    }, [ref.current?.offsetWidth]);

    return (
        <Paper
            sx={{
                width: isMobileSize ? '100%' : '33%',
                borderRadius: 4,
                p: isMobileSize ? 2 : 3,
                pt: 2,
                color: 'text_v2.secondary',
            }}
            elevation={1}
        >
            <Box>
                <Typography variant="h5" sx={{mb: isMobileSize ? 1 : 2}}>
                    {t('freelancerOpportunity.concept')}
                </Typography>
            </Box>
            <Box ref={ref} sx={{mb: 2}}>
                <CardMedia
                    sx={{
                        borderRadius: 2,
                    }}
                    width={width}
                    height={calculateVideoHeight(width)}
                    component="iframe"
                    image={CLUSTER_VIDEO_LINK}
                    title="Hiway Cluster video"
                    frameBorder="0"
                    allow="accelerometer;"
                    allowFullScreen
                />
            </Box>
            <Box>
                <Typography sx={{textAlign: 'center'}}>
                    {t('freelancerOpportunity.conceptDescription')}
                </Typography>
            </Box>
        </Paper>
    );
};
