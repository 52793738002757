import {saveAs} from 'file-saver';
import {all, call, put, takeLatest} from 'redux-saga/effects';

import * as actions from './admin-expenses.actions';
import {downloadAsArchiveRequest} from '../../../../app/api/providers/documents/documents.provider';
import {isMobileSafari, isSafari} from '../../../../app/utils/app.helpers';
import {Toast} from '../../../../lib/toast';

const downloadExpensesAsArchiveSaga = function* ({payload}) {
    try {
        let childWindow;

        if ((isSafari || isMobileSafari) && !payload.isDownload) {
            childWindow = window.open('', '_blank');
        }

        yield put(actions.setIsDownloadInProgress(true));

        const signedUrl = yield call(
            downloadAsArchiveRequest,
            payload.freelancerId,
            payload.companyId,
            payload.documentIds,
            payload.archivingStrategy,
        );

        if (payload.onSuccess && typeof payload.onSuccess === 'function') {
            payload.onSuccess();
        }

        if (!signedUrl) {
            throw new Error('The document URL is missing.');
        }

        if ((isSafari || isMobileSafari) && !payload.isDownload) {
            childWindow.location = signedUrl;

            return;
        }

        if ((isSafari || isMobileSafari) && payload.isDownload) {
            // TODO:HIGH: It's ugly but it works.
            fetch(signedUrl).then(response => {
                return response.blob();
            }).then(blob => {
                const matchedGroups = signedUrl.match(/filename[^;=\n]*%3D(%22(.*)%22[^;\n]*)/);
                const filename = matchedGroups[2];

                saveAs(blob, decodeURI(filename));
            });

            return;
        }

        window.open(signedUrl, '_blank');
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsDownloadInProgress(false));
    }
};

export const watchAdminExpenses = function* () {
    yield all([
        takeLatest(actions.DOWNLOAD_EXPENSES_AS_ARCHIVE, downloadExpensesAsArchiveSaga),
    ]);
};
