import {USER_STATUSES} from './constants';
import Coach from '../../../components/data-table-cells/Coach';
import DTCellRole from '../../../components/data-table-cells/DTCellRole';
import LegalStatusAndTaxSystem from '../../../components/data-table-cells/LegalStatusAndTaxSystem';
import UserListCompanyAndUser from '../../../components/data-table-cells/UserListCompanyAndUser';
import {resolveRoute} from '../../../lib/router/resolveRoute';
import {ROUTE_PATHS} from '../../../lib/router/route-paths';
import UserRowActions from '../components/UserRowActions';
import UserTableStatus from '../components/UserTableStatus';

export const getAdminUsersTableConfig = translate => ([{
    key: 'email',
    title: translate('usersDataTable.tableColumnTitles.email'),
    isSortable: true,
    width: '25%',
    componentProps: {
        urlResolver: rowData => resolveRoute(ROUTE_PATHS.USER, {userId: rowData.id}),
    },
}, {
    key: 'fullName',
    title: translate('usersDataTable.tableColumnTitles.name'),
    isSortable: true,
    width: '30%',
    componentProps: {
        urlResolver: rowData => resolveRoute(ROUTE_PATHS.USER, {userId: rowData.id}),
    },
}, {
    key: 'fullCoachName',
    title: translate('usersDataTable.tableColumnTitles.coachName'),
    isSortable: false,
    width: '10%',
    component: Coach,
}, {
    key: 'role',
    title: translate('usersDataTable.tableColumnTitles.role'),
    isSortable: true,
    width: '15%',
    componentProps: {
        formatter: rowData => translate(`userRoles.${rowData.role}`),
    },
}, {
    key: 'status',
    title: translate('usersDataTable.tableColumnTitles.status'),
    isSortable: true,
    width: '15%',
    columnFilterConfig: {
        type: 'select',
        options: Object.values(USER_STATUSES).map(status => ({
            key: status,
            label: translate(`userStatuses.${status}`),
        })),
    },
    component: UserTableStatus,
}, {
    key: 'actions',
    title: translate('usersDataTable.tableColumnTitles.actions'),
    additionalCellProps: {
        align: 'right',
    },
    shouldPreserveWhiteSpace: true,
    component: UserRowActions,
}]);

export const getAllUsersTableConfig = translate => ([{
    key: 'role',
    title: translate('usersDataTable.tableColumnTitles.role'),
    isSortable: true,
    width: '10%',
    component: DTCellRole,
}, {
    key: 'companyAndUser',
    title: translate('usersDataTable.tableColumnTitles.companyAndUser'),
    isSortable: true,
    width: '25%',
    component: UserListCompanyAndUser,
}, {
    key: 'companyType',
    title: translate('usersDataTable.tableColumnTitles.companyType'),
    isSortable: false,
    width: '15%',
    componentProps: {
        translate: translate,
    },
    component: LegalStatusAndTaxSystem,
}, {
    key: 'fullCoachName',
    title: translate('usersDataTable.tableColumnTitles.coachName'),
    isSortable: false,
    component: Coach,
},
{
    key: 'date',
    title: translate('usersDataTable.tableColumnTitles.date'),
    isSortable: true,
    width: '15%',
}, {
    key: 'status',
    title: translate('usersDataTable.tableColumnTitles.status'),
    isSortable: true,
    width: '20%',
    columnFilterConfig: {
        type: 'select',
        options: Object.values(USER_STATUSES).map(status => ({
            key: status,
            label: translate(`userStatuses.${status}`),
        })),
    },
    component: UserTableStatus,
}, {
    key: 'actions',
    title: translate('usersDataTable.tableColumnTitles.actions'),
    additionalCellProps: {
        align: 'right',
    },
    shouldPreserveWhiteSpace: true,
    component: UserRowActions,
}]);

export const getUsersTableConfig = translate => ([{
    key: 'companyAndUser',
    title: translate('usersDataTable.tableColumnTitles.companyAndUser'),
    isSortable: true,
    width: '30%',
    component: UserListCompanyAndUser,
}, {
    key: 'companyType',
    title: translate('usersDataTable.tableColumnTitles.companyType'),
    isSortable: false,
    width: '20%',
    componentProps: {
        translate: translate,
    },
    component: LegalStatusAndTaxSystem,
}, {
    key: 'fullCoachName',
    title: translate('usersDataTable.tableColumnTitles.coachName'),
    isSortable: false,
    width: '15%',
    component: Coach,
},
{
    key: 'date',
    title: translate('usersDataTable.tableColumnTitles.date'),
    isSortable: true,
    width: '15%',
}, {
    key: 'status',
    title: translate('usersDataTable.tableColumnTitles.status'),
    isSortable: true,
    width: '25%',
    columnFilterConfig: {
        type: 'select',
        options: Object.values(USER_STATUSES).map(status => ({
            key: status,
            label: translate(`userStatuses.${status}`),
        })),
    },
    component: UserTableStatus,
}, {
    key: 'actions',
    title: translate('usersDataTable.tableColumnTitles.actions'),
    additionalCellProps: {
        align: 'right',
    },
    shouldPreserveWhiteSpace: true,
    component: UserRowActions,
}]);
