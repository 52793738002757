import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import RefreshIcon from '@mui/icons-material/Refresh';
import {Box, useTheme} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {LocalesConstants} from '../../../../../utils/locales-constants';
import {RevenueSimulatorActions} from '../../../store/revenue-simulator.action';

export const Status = ({rowData}) => {
    const {isExpired, id} = rowData;
    const {t} = useTranslation(LocalesConstants.RevenueSimulator);
    const {pxToRem} = useTheme().typography;
    const dispatch = useDispatch();

    const handleRefreshClick = () => {
        dispatch(RevenueSimulatorActions.refreshSimulation(id));
    };

    return (
        <Box sx={{display: 'flex', alignItems: 'center', gap: 1, fontSize: pxToRem(14)}}>
            {isExpired ? (
                <>
                    <EventBusyIcon sx={{color: '#00000042', fontSize: pxToRem(20)}} />
                    {t('simulationsList.expired')}
                    <IconButton onClick={handleRefreshClick}>
                        <RefreshIcon sx={{color: 'primary_v2.main'}} />
                    </IconButton>
                </>
            ) : (
                <>
                    <CheckCircleIcon sx={{color: 'v2.light.success.light', fontSize: pxToRem(20)}} />
                    {t('simulationsList.valid')}
                </>
            )}
        </Box>
    );
};

Status.propTypes = {
    rowData: PropTypes.object.isRequired,
};

