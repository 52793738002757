import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {MissionsActionType} from './missions.action-type';
import {MissionsActions} from './missions.actions';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import {LoadingActions, LoadingTypes} from '../../loading';
import {UiActions} from '../../ui/store/ui.action';
import {ModalsKeys} from '../../ui/utils/constants';
import {LoggedInUserSelectors} from '../../user/modules/logged-in-user';
import {MissionsApi} from '../api/missions.api';
import {MissionStatus} from '../modules/client-side/utils/constants';
import {MissionsCraApi} from '../modules/cra/api/missions-cra.api';
import {MissionsCraActions} from '../modules/cra/store/missions-cra.action';
import {MissionSigningApi} from '../modules/missions-signing/api/missions-signing.api';
import {MISSION_CREATE_SCREENS, MissionListingStatus} from '../utils/constants';

const flow = {
    getMission: function* ({
        missionId,
        userId,
    }) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

            const mission = yield call(
                MissionsApi.getMission,
                {userId, missionId},
            );

            yield put(MissionsActions.storeMission(mission));
            yield put(MissionsActions.storeMissionInProgress(mission));
        } catch (error) {
            Debug.error('getQuotaContract', 'Error: ', {error});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
        }
    },
    getMissionList: function* ({
        userId,
    }) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

            // TODO Call them at the same time
            const missionsInProgress = yield call(
                MissionsApi.getMissionList,
                {userId, status: MissionListingStatus.InProgress},
            );

            const missionsActive = yield call(
                MissionsApi.getMissionList,
                {userId, status: MissionListingStatus.Active},
            );

            const missionsInactive = yield call(
                MissionsApi.getMissionList,
                {userId, status: MissionListingStatus.Inactive},
            );

            const hasReadyForWorkContract = missionsInProgress?.items.find(item => {
                return item?.status === MissionStatus.WaitingForWorkContract;
            });

            const hasReadyForHiwayReview = missionsInProgress?.items.find(item => {
                return item?.status === MissionStatus.QuoteInHiwayReview;
            });

            // TODO This is what should go
            yield put(MissionsActions.storeMissionList(
                {
                    inProgress: missionsInProgress?.items,
                    active: missionsActive?.items,
                    inactive: missionsInactive?.items,
                    hasInProgress: missionsInProgress?.items?.length > 0,
                    hasActive: missionsActive?.items?.length > 0,
                    hasInactive: missionsInactive?.items?.length > 0,
                    hasReadyForWorkContract,
                    hasReadyForHiwayReview,
                },
            ));
        } catch (error) {
            Debug.error('getQuotaContract', 'Error: ', {error});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
        }
    },
    getMissionDetails: function* ({
        userId,
        missionId,
    }) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

            const documents = yield call(MissionSigningApi.getMissionContractDocuments, {userId, missionId});

            yield put(MissionsActions.storeMissionDetails(documents));

            const craDocuments = yield call(MissionsCraApi.getMissionContractDocuments, {userId, missionId});

            yield put(MissionsCraActions.storeCraDocuments(craDocuments));

            yield call(flow.getMission, {missionId, userId});
        } catch (error) {
            Debug.error('getMissionDetails', 'Error: ', {error});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
        }
    },
    postCreateMission: function* (data) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));
            const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

            const mission = yield call(
                MissionsApi.postCreateMission,
                {userId: loggedInUser?.id, data},
            );

            yield put(MissionsActions.storeMissionInProgress(mission));
            yield put(MissionsActions.changeMissionInCreationScreen(MISSION_CREATE_SCREENS.DOCUMENT_SIGNING));
        } catch (error) {
            Debug.error('getQuotaContract', 'Error: ', {error});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
        }
    },
    postServiceAgreement: function* (data) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

            yield call(
                MissionsApi.postServiceAgreement,
                data,
            );

            yield put(MissionsActions.changeMissionInCreationScreen(MISSION_CREATE_SCREENS.DOCUMENT_SIGNING));

            yield put(UiActions.setActiveModal(ModalsKeys.UPLOAD_MISSION_UPLOAD_MODAL, false));
            Toast.success('genericSuccessSave');

            yield put(MissionsActions.getMissionList(data?.userId));
        } catch (error) {
            Debug.error('postServiceAgreement', 'Error: ', {error});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
        }
    },
    putEditMission: function* (data) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

            return yield call(
                MissionsApi.putEditMission,
                data,
            );
        } catch (error) {
            Debug.error('putEditMission', 'Error: ', {error});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
        }
    },
    postWorkContract: function* (data) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

            yield call(
                MissionsApi.postWorkContract,
                data,
            );

            yield put(MissionsActions.changeMissionInCreationScreen(MISSION_CREATE_SCREENS.DOCUMENT_SIGNING));
            yield put(UiActions.setActiveModal(ModalsKeys.UPLOAD_MISSION_UPLOAD_MODAL, false));
            Toast.success('genericSuccessSave');

            yield put(MissionsActions.getMissionList(data?.userId));
        } catch (error) {
            Debug.error('postServiceAgreement', 'Error: ', {error});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
        }
    },
};

const workers = {
    getMission: function* ({payload}) {
        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

        yield call(flow.getMission, {
            missionId: payload,
            userId: payload?.userId ?? loggedInUser?.id,
        });
    },
    getMissionList: function* ({payload}) {
        // TODO Add pagination and filters
        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

        yield call(flow.getMissionList, {
            userId: payload ?? loggedInUser?.id,
        });
    },
    postCreateMission: function* ({payload}) {
        yield call(flow.postCreateMission, {
            'client': {
                'name': payload?.name,
                'email': payload?.email,
                'address': payload?.address,
                'zipCode': payload?.zipCode,
                'city': payload?.city,
                'countryCode': payload?.countryCode,
                'vatType': payload?.vatType,
                'paymentTerm': payload?.paymentTerm,
                'vatIntraCommunity': payload?.vatIntraCommunity,
                'isEsn': payload?.isEsn,
                'esnContactName': payload?.isEsn ? payload?.esnContactName : undefined,
                'esnEmail': payload?.isEsn ? payload?.esnEmail : undefined,
            },
            'jobType': payload?.jobType,
            'reference': payload?.reference ?? undefined,
            'startDate': payload?.startDate,
            'endDate': payload?.endDate,
            'billableDays': payload?.billableDays,
            'dailyRate': payload?.dailyRate,
            'description': payload?.description,
        });
    },
    getMissionDetails: function* ({payload}) {
        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

        yield call(flow.getMissionDetails, {
            userId: payload?.userId ?? loggedInUser?.id,
            missionId: payload?.missionId,
        });
    },
    postServiceAgreement: function* ({payload}) {
        yield call(flow.postServiceAgreement, {
            userId: payload?.userId,
            missionId: payload?.missionId,
            file: payload?.file,
        });
    },
    postWorkContract: function* ({payload}) {
        yield call(flow.postWorkContract, {
            userId: payload?.userId,
            file: payload?.file,
        });
    },
    putEditMission: function* ({payload}) {
        const mission = yield call(flow.putEditMission, {
            userId: payload?.userId,
            missionId: payload?.missionId,
            data: {
                'client': {
                    'name': payload?.data?.name,
                    'email': payload?.data?.email,
                    'address': payload?.data?.address,
                    'zipCode': payload?.data?.zipCode,
                    'city': payload?.data?.city,
                    'countryCode': payload?.data?.countryCode,
                    'vatType': payload?.data?.vatType,
                    'paymentTerm': payload?.data?.paymentTerm,
                    'vatIntraCommunity': payload?.data?.vatIntraCommunity,
                    'isEsn': payload?.data?.isEsn,
                    'esnContactName': payload?.data?.isEsn ? payload?.data?.esnContactName : undefined,
                    'esnEmail': payload?.data?.isEsn ? payload?.data?.esnEmail : undefined,
                },
                'jobType': payload?.data?.jobType,
                'reference': payload?.data?.reference,
                'startDate': payload?.data?.startDate,
                'endDate': payload?.data?.endDate,
                'billableDays': payload?.data?.billableDays,
                'dailyRate': payload?.data?.dailyRate,
                'description': payload?.data?.description,
                'status': payload?.data?.status ?? undefined,
            },
        });

        if (mission?.id) {
            yield put(MissionsActions.storeMissionInProgress(mission));
            yield put(MissionsActions.changeMissionInCreationScreen(MISSION_CREATE_SCREENS.DOCUMENT_SIGNING));
        }
    },
    deactivateMission: function* ({payload}) {
        const data = yield call(flow.putEditMission, {
            userId: payload?.userId,
            missionId: payload?.missionId,
            data: {
                ...payload?.mission,
                status: MissionStatus.Inactive,
                id: undefined,
                clientName: undefined,
                clientEmail: undefined,
                createdAt: undefined,
                updatedAt: undefined,
                isThisMonthCraCreated: undefined,
                missionId: undefined,
            },
        });

        if (data?.id) {
            yield call(flow.getMission, {
                userId: payload?.userId,
                missionId: payload?.missionId,
            });
            yield call(flow.getMissionDetails, {
                userId: payload?.userId,
                missionId: payload?.missionId,
            });
            yield put(UiActions.setActiveModal(ModalsKeys.DEACTIVATE_MISSION, false));
        }
    },
};

export const watchMissionsSaga = function* () {
    yield all([
        takeEvery(MissionsActionType.GET_MISSION_LIST, workers.getMissionList),
        takeEvery(MissionsActionType.GET_MISSION, workers.getMission),
        takeEvery(MissionsActionType.CREATE_MISSION, workers.postCreateMission),
        takeEvery(MissionsActionType.GET_MISSION_DETAILS, workers.getMissionDetails),
        takeEvery(MissionsActionType.POST_SERVICE_AGREEMENT, workers.postServiceAgreement),
        takeEvery(MissionsActionType.POST_WORK_CONTRACT, workers.postWorkContract),
        takeEvery(MissionsActionType.DEACTIVATE_MISSION, workers.deactivateMission),
        takeEvery(MissionsActionType.PUT_EDIT_MISSION, workers.putEditMission),
    ]);
};
