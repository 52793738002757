export const SET_IS_LOADING_PERSONAL_EXPENSES_FORM = 'personal-expenses-form/SET_IS_LOADING';
export const SUBMIT_PERSONAL_EXPENSES_FORM = 'personal-expenses-form/SUBMIT_PERSONAL_EXPENSES_FORM';


export const setIsLoadingPersonalExpensesForm = isLoading => ({
    type: SET_IS_LOADING_PERSONAL_EXPENSES_FORM,
    payload: isLoading,
});

export const submitPersonalExpensesForm = (formData, onSuccess) => ({
    type: SUBMIT_PERSONAL_EXPENSES_FORM,
    payload: {
        formData,
        onSuccess,
    },
});
