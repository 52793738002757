import React from 'react';
import {appLoaderSaga} from '../../../app/store/app-loader.sagas';
import {RoutePaths} from '../../../lib/router/route-paths';
import {settingsLoaderSaga} from '../../settings/store/settings.sagas';
import ConnectProPage from '../components/connect/ConnectProPage';
import BankCreated from '../components/helper-pages/BankCreated';
import BankFinishCreation from '../components/helper-pages/BankFinishCreation';
import BankKyc from '../components/helper-pages/BankKYC';
import BankMissingInformation from '../components/helper-pages/BankMissingInformation';
import RequestHiwayProAccessLp from '../components/RequestHiwayProAccessLp';
import BankRibAndDocumentsPage from '../components/RibAndDocumentsPage';
import {TransactionAccounts} from '../modules/account-balance/components/TransactionAccounts';
import {getTransactionListLoaderWorker} from '../modules/account-balance/store/transactions.saga';
import {BankCardRoutes} from '../modules/cards/util/bank-card-routes';
import {TransferRoutes} from '../modules/transfers/utils/transfer-routes';
import {
    bankFlagAccessFlow,
    bankIntegrationMainCheckFlow,
    bankRiBAndDocumentsPageAccessControl,
    bankRibDocumentFetchSaga,
    bankStatementsLoaderSaga,
    redirectToDashboardForUsersWithBankAccess,
} from '../store/bank.loader.saga';
import {
    getTransactionListInitWorker,
} from '../store/bank.saga';

export const BankV3Routes = [
    {
        path: RoutePaths.BANK_TRANSACTION_LIST,
        element: <TransactionAccounts />,
        exact: true,
        locationChangeSideEffects: [
            [getTransactionListLoaderWorker],
            [appLoaderSaga],
            [bankFlagAccessFlow],
            [bankIntegrationMainCheckFlow],
            [getTransactionListInitWorker],
        ],
    },
    {
        path: RoutePaths.BANK_RIB_AND_DOCUMENTS,
        element: <BankRibAndDocumentsPage />,
        exact: true,
        locationChangeSideEffects: [
            [appLoaderSaga],
            [bankFlagAccessFlow],
            [bankIntegrationMainCheckFlow],
            [bankRiBAndDocumentsPageAccessControl],
            [bankStatementsLoaderSaga],
            [bankRibDocumentFetchSaga],
        ],
    },
];

export const BankRoutes = [
    {
        path: RoutePaths.BANK_PROMOTION,
        element: <ConnectProPage />,
        exact: true,
        locationChangeSideEffects: [
            [bankFlagAccessFlow],
        ],
    },
    {
        path: RoutePaths.REQUEST_HIWAY_PRO_ACCESS_LP,
        element: <RequestHiwayProAccessLp />,
        exact: true,
        locationChangeSideEffects: [
            [redirectToDashboardForUsersWithBankAccess],
        ],
    },
    {
        path: RoutePaths.BANK_ONBOARDING_SUCCESS,
        element: <BankCreated />,
        exact: true,
        locationChangeSideEffects: [
            // Removed due to user needing to see it after redirect
            // TODO We need additional flag on BE at some point to know whether to run bankIntegrationMainCheckFlow
            // [bankIntegrationMainCheckFlow],
            [settingsLoaderSaga],
        ],
    },
    {
        path: RoutePaths.BANK_CREATION_FINALIZE,
        element: <BankFinishCreation />,
        exact: true,
        locationChangeSideEffects: [
            [bankIntegrationMainCheckFlow],
            [settingsLoaderSaga],
        ],
    },
    {
        path: RoutePaths.BANK_CREATION_MISSING_INFORMATION,
        element: <BankMissingInformation />,
        exact: true,
        locationChangeSideEffects: [
            [bankIntegrationMainCheckFlow],
            [settingsLoaderSaga],
        ],
    },
    {
        path: RoutePaths.BANK_KYC,
        element: <BankKyc />,
        exact: true,
        locationChangeSideEffects: [
            [settingsLoaderSaga],
        ],
    },
    {
        path: RoutePaths.BANK_AFTER_CREATION_REDIRECT,
        element: <BankFinishCreation />,
        exact: true,
    },
    ...TransferRoutes,
    ...BankCardRoutes,
];

