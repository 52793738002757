import {combineReducers} from 'redux';
import {SseActionTypes} from './sse.action-types';
import {createReducer} from '../../../app/utils/store/create-reducer';

export const SSEReducer = combineReducers({
    bankConnectionStatus: createReducer(EventSource.CLOSED, SseActionTypes.STORE_BANK_SSE_STATUS),
    bankSseEventSource: createReducer(null, SseActionTypes.STORE_BANK_SSE_EVENT_SOURCE),

    invoiceConnectionStatus: createReducer(EventSource.CLOSED, SseActionTypes.STORE_INVOICE_SSE_STATUS),
    invoiceSseEventSource: createReducer(null, SseActionTypes.STORE_INVOICE_SSE_EVENT_SOURCE),
});
