import {Box, Input, Option, Select} from '@mui/joy';
import {MenuItem, TableCell, TableRow, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import NumberFormat from 'react-number-format';
import {useSelector} from 'react-redux';

import {useDataTableColumnFilterStyles} from './styles';
import {getSeparator} from '../../../app/utils/app.helpers';
import {SettingsSelectors} from '../../../features/settings/store/settings.selectors';

const DataTableColumnFilters = ({
    columnConfig,
    columnFilters,
    createOnChangeHandler,
    createOnSelectHandler,
    hasCheckboxes,
    isV3,
}) => {
    const styles = useDataTableColumnFilterStyles();

    const {t} = useTranslation();

    const userSettings = useSelector(SettingsSelectors.selectSettings);

    return isV3 ? (
        <Box component="tr">
            {hasCheckboxes && <Box component="td" />}

            {columnConfig.map(column => {
                let children = null;

                if (column.columnFilterConfig) {
                    const key = column.columnFilterConfig?.filterKey || column.key;

                    if (column.columnFilterConfig.type === 'number') {
                        children = (
                            <NumberFormat
                                customInput={Input}
                                decimalSeparator={getSeparator(userSettings?.language, 'decimal')}
                                allowedDecimalSeparators={['.', ',']}
                                value={columnFilters[key]}
                                onChange={createOnChangeHandler(key)}
                                fullWidth
                                isNumericString={isFinite(columnFilters[key])}
                                variant="outlined"
                            />
                        );
                    } else {
                        let selectProps = {};

                        if (column.columnFilterConfig.type === 'select') {
                            selectProps = {
                                select: true,
                            };
                        }

                        children = column.columnFilterConfig.type === 'select' ? (
                            <Select
                                sx={{textTransform: 'capitalize'}}
                                value={columnFilters[key]}
                                onChange={createOnSelectHandler(key)}
                                variant="outlined"
                                size="sm"
                                color="neutral"
                                {...selectProps}
                            >
                                {[
                                    {
                                        key: 'SHOW_ALL',
                                        label: t('common:showAll'),
                                    },
                                    ...column.columnFilterConfig.options,
                                ].map(option => (
                                    <Option key={option.key} value={option.key}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        ) : (
                            <Input
                                variant="outlined"
                                color="neutral"
                                size="sm"
                                value={columnFilters[key]}
                                onChange={createOnChangeHandler(key)}
                                sx={{
                                    backgroundColor: 'background.body',
                                }}
                            />
                        );
                    }
                }

                return (
                    <Box
                        key={column.key}
                        component="td"
                        sx={{
                            py: 1,
                            px: 3,
                        }}
                        {...(column.additionalCellProps || {})}
                    >
                        {children}
                    </Box>
                );
            })}
        </Box>
    ) : (
        <TableRow>
            {hasCheckboxes && <TableCell />}

            {columnConfig.map(column => {
                let children = null;

                if (column.columnFilterConfig) {
                    const key = column.columnFilterConfig?.filterKey || column.key;

                    if (column.columnFilterConfig.type === 'number') {
                        children = (
                            <NumberFormat
                                customInput={TextField}
                                decimalSeparator={getSeparator(userSettings?.language, 'decimal')}
                                allowedDecimalSeparators={['.', ',']}
                                value={columnFilters[key]}
                                onChange={createOnChangeHandler(key)}
                                fullWidth
                                isNumericString={isFinite(columnFilters[key])}
                                variant="standard"
                            />
                        );
                    } else {
                        let selectProps = {};

                        if (column.columnFilterConfig.type === 'select') {
                            selectProps = {
                                select: true,
                            };
                        }

                        children = (
                            <TextField
                                value={columnFilters[key]}
                                onChange={createOnChangeHandler(key)}
                                className={styles.textField}
                                fullWidth
                                variant="standard"
                                {...selectProps}
                            >
                                {column.columnFilterConfig.type === 'select' ? [
                                    {
                                        key: 'SHOW_ALL',
                                        label: t('common:showAll'),
                                    },
                                    ...column.columnFilterConfig.options,
                                ].map(option => (
                                    <MenuItem key={option.key} value={option.key}>
                                        {option.label}
                                    </MenuItem>
                                )) : null}
                            </TextField>
                        );
                    }
                }

                return (
                    <TableCell
                        variant="body"
                        key={column.key}
                        className={styles.tableCell}
                        {...(column.additionalCellProps || {})}
                    >
                        {children}
                    </TableCell>
                );
            })}
        </TableRow>
    );
};

DataTableColumnFilters.propTypes = {
    columnConfig: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        title: PropTypes.string.isRequired,
        additionalCellProps: PropTypes.object,
    })).isRequired,
    columnFilters: PropTypes.object.isRequired,
    createOnChangeHandler: PropTypes.func.isRequired,
    createOnSelectHandler: PropTypes.func.isRequired,
    hasCheckboxes: PropTypes.bool,
    isV3: PropTypes.bool,
};

DataTableColumnFilters.defaultProps = {
    hasCheckboxes: false,
    isV3: false,
};

export default DataTableColumnFilters;
