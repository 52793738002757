import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Paper, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Navigate, useLocation} from 'react-router-dom';
import CardSuccess from '../../../../../assets/png/bank/card-success.png';
import ConfetiImg from '../../../../../assets/svg/bank/confetti.svg';
import ReactRouterButtonLink from '../../../../../components/app/router/ReactRouterButtonLink';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {Toast} from '../../../../../lib/toast';
import {LocalesConstants} from '../../../../../utils/locales-constants';
import {ConsentCardOperation, ConsentResult} from '../util/constants';

const CardsConsent = () => {
    const params = useLocation();
    const searchParams = new URLSearchParams(params.search);

    const {t} = useTranslation(LocalesConstants.BankCards);

    const result = searchParams.get('result');
    const operation = searchParams.get('operation');

    if (result === ConsentResult.SUCCESS && operation === ConsentCardOperation.ViewPin) {
        return <Navigate replace to={RoutePaths.BANK_CARDS} />;
    }

    if (result === ConsentResult.SUCCESS && operation === ConsentCardOperation.ViewCardNumbers) {
        return <Navigate replace to={RoutePaths.BANK_CARDS} />;
    }

    if (result === ConsentResult.SUCCESS && operation === ConsentCardOperation.ViewPhysicalCardNumbers) {
        return <Navigate replace to={RoutePaths.BANK_CARDS} />;
    }

    if (result === ConsentResult.SUCCESS && operation === ConsentCardOperation.ActivateCard) {
        Toast.success('cardActivated');
        return <Navigate replace to={RoutePaths.BANK_CARDS} state={{operation: ConsentCardOperation.ActivateCard}} />;
    }

    if (result === ConsentResult.SUCCESS && operation === ConsentCardOperation.UnlockCard) {
        Toast.success('cardUnblocked');
        return <Navigate replace to={RoutePaths.BANK_CARDS} />;
    }

    if (result === ConsentResult.SUCCESS && operation === ConsentCardOperation.UpdateCard) {
        Toast.success('genericSuccessSave');
        return <Navigate replace to={RoutePaths.BANK_CARDS} />;
    }

    if (result === ConsentResult.SUCCESS && operation === ConsentCardOperation.AddCard) {
        return (
            <Paper
                sx={{
                    width: '100%',
                    height: '90vh',
                    borderRadius: '8px',
                    p: 5,
                }}
                elevation={0}
            >
                <Box sx={{
                    background: '#FAFAFA',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                >
                    <Box sx={{
                        zIndex: 3,
                    }}
                    >
                        <img src={CardSuccess} alt="card" />
                    </Box>
                    <Box sx={{
                        position: 'absolute',
                    }}
                    >
                        <Box sx={{
                            position: 'relative',
                            bottom: '130px',
                        }}
                        >
                            <img src={ConfetiImg} alt="confeti" />
                        </Box>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                    }}
                    >
                        <Typography sx={{
                            fontSize: theme => theme.typography.pxToRem(34),
                            fontWeight: 700,
                            mb: 2,
                        }}
                        >
                            {t('cardConsent.createdTitle')}
                        </Typography>
                        <Typography sx={{
                            mb: 2,
                        }}
                        >
                            {t('cardConsent.createdText1')}
                        </Typography>
                        <Typography sx={{
                            mb: 3,
                        }}
                        >
                            {t('cardConsent.createdText2')}
                        </Typography>
                    </Box>

                    <Box>
                        <ReactRouterButtonLink
                            to={RoutePaths.BANK_CARDS}
                            variant="contained"
                            color="primary"
                            endIcon={<ArrowForwardIcon />}
                        >
                            {t('cardConsent.createdButton')}
                        </ReactRouterButtonLink>
                    </Box>
                </Box>
            </Paper>
        );
    }

    return null;
};

export default CardsConsent;
