export const SET_UPLOAD_DOCUMENTS_STEP = 'company/companyDocuments/uploadDocuments/SET_UPLOAD_DOCUMENTS_STEP';
export const STORE_UPLOAD_DOCUMENTS_DATA = 'company/companyDocuments/uploadDocuments/STORE_UPLOAD_DOCUMENTS_DATA';
export const SET_IS_UPLOADING_DOCUMENTS = 'company/companyDocuments/uploadDocuments/SET_IS_UPLOADING_DOCUMENTS';
export const UPLOAD_DOCUMENTS = 'company/companyDocuments/uploadDocuments/UPLOAD_DOCUMENTS';

export const setUploadDocumentsStep = activeStep => ({
    type: SET_UPLOAD_DOCUMENTS_STEP,
    payload: activeStep,
});

export const storeUploadDocumentsData = data => ({
    type: STORE_UPLOAD_DOCUMENTS_DATA,
    payload: data,
});

export const setIsUploadingDocuments = isUploading => ({
    type: SET_IS_UPLOADING_DOCUMENTS,
    payload: isUploading,
});

export const uploadDocuments = (isReplace, freelancerId, userId, companyId) => ({
    type: UPLOAD_DOCUMENTS,
    payload: {
        freelancerId,
        userId,
        companyId,
        isReplace,
    },
});
