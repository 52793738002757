import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {Box, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import CancelTransfer from '../../../../../../assets/png/bank/cancel-transfer.png';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {TransfersActions} from '../../store/transfers.actions';

const CancelTransferView = ({onClose, onCloseSidebar, data}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation(LocalesConstants.BankTransfer);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const onDeleteConfirm = () => {
        dispatch(TransfersActions.cancel(data));
        onCloseSidebar();
    };

    return (
        <>
            <Box sx={isMobileSize ? {} : {
                position: 'absolute',
                top: 20,
            }}
            >
                <Button
                    sx={{
                        pl: 2.5,
                        color: 'v2.blueGray.900',
                    }}
                    startIcon={<ArrowBackIcon />}
                    onClick={onClose}
                >
                    {t('transfer.update.standingOrder')}
                </Button>
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100vh',
                px: 3,
            }}
            >
                <Box><img src={CancelTransfer} alt="cancel transfer" /></Box>
                <Box sx={{mt: 2, mb: 2}}>
                    <Typography sx={{textAlign: 'center'}} variant="h4">
                        {t('transfer.update.removeRecurringPayment')}
                    </Typography>
                </Box>
                <Box>
                    <Typography sx={{textAlign: 'center'}}>
                        <Trans t={t} i18nKey="transfer.update.areYouSureDelete" values={{label: data.label, amount: data.amount}} />
                    </Typography>
                </Box>
                <Box sx={{mt: 3, width: '100%'}}>
                    <Button
                        onClick={onDeleteConfirm}
                        startIcon={<DeleteForeverIcon />}
                        color="secondary"
                        sx={{
                            backgroundColor: 'v2.light.error.dark',
                        }}
                        variant="contained"
                        fullWidth
                    >
                        {t('transfer.update.confirmDelete')}
                    </Button>
                </Box>
            </Box>
        </>
    );
};

CancelTransferView.propTypes = {
    onClose: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    onCloseSidebar: PropTypes.func.isRequired,
};

export default CancelTransferView;
