import {
    TablePagination as MuiTablePagination,
    TableFooter,
    TableRow,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDataTableFooterStyles} from './styles';
import {LocalesConstants} from '../../../utils/locales-constants';
import {DataTablePagination} from '../../joy-ui/data-table/DataTablePagination';

const DataTableFooter = ({
    rowsPerPage,
    page,
    onPageChange,
    onRowsPerPageChange,
    count,
    rowsPerPageOptions,
    isV3,
}) => {
    const {t} = useTranslation(LocalesConstants.Common);
    const styles = useDataTableFooterStyles();

    return isV3 ? (
        <DataTablePagination
            count={count}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
        />
    ) : (
        <TableFooter className={styles.root}>
            <TableRow>
                <MuiTablePagination
                    className={styles.pagination}
                    count={count}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                    labelRowsPerPage={t('pagination.rowsPerPage')}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    sx={{
                        'p, div': {fontFamily: 'Avantt', fontSize: '16px', fontWeight: 500},
                        '.MuiTablePagination-spacer': {display: 'none'},
                        '.MuiTablePagination-displayedRows': {
                            'marginLeft': 'auto',
                        },
                        '.MuiTablePagination-select': {
                            background: 'var(--joy-palette-background-surface)',
                            boxShadow: 'var(--joy-shadow-xs)',
                        },
                    }}
                />
            </TableRow>
        </TableFooter>
    );
};

DataTableFooter.propTypes = {
    count: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onRowsPerPageChange: PropTypes.func.isRequired,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
    isV3: PropTypes.bool,
};

DataTableFooter.defaultProps = {
    rowsPerPageOptions: [10, 25, 50],
    isV3: false,
};

export default DataTableFooter;
