import {Tab, TabList, Tabs} from '@mui/joy';
import {Tab as MuiTab, Tabs as MuiTabs} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDataTableTabFiltersStyles} from './styles';
import {LocalesConstants} from '../../../utils/locales-constants';

const tabStyles = {mx: 1, p: 1, textTransform: 'uppercase'};

const DataTableTabFilters = ({value, config, onChange, isAllDisplayed, isV3}) => {
    const styles = useDataTableTabFiltersStyles();

    const {t} = useTranslation(LocalesConstants.Common);

    return isV3 ? (
        <Tabs
            value={value}
            onChange={onChange}
        >
            <TabList
                sx={{
                    'overflow': 'auto',
                    'scrollSnapType': 'x mandatory',
                    '&::-webkit-scrollbar': {display: 'none'},
                }}
            >
                {isAllDisplayed && (
                    <Tab
                        value="ALL"
                        sx={tabStyles}
                    >
                        {t('dataTable.tabFilters.all')}
                    </Tab>
                )}
                {config.map(tabFilterItem => (
                    <Tab
                        key={tabFilterItem.id}
                        value={tabFilterItem.id}
                        sx={tabStyles}
                    >
                        {tabFilterItem.label}
                    </Tab>
                ))}
            </TabList>
        </Tabs>
    ) : (
        <MuiTabs
            variant="scrollable"
            className={styles.tabs}
            value={value}
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="auto"
            onChange={onChange}
        >
            {isAllDisplayed && <MuiTab className={styles.tab} label={t('dataTable.tabFilters.all')} value="ALL" />}

            {config.map(tabFilterItem => (
                <MuiTab
                    key={tabFilterItem.id}
                    className={styles.tab}
                    label={tabFilterItem.label}
                    value={tabFilterItem.id}
                />
            ))}
        </MuiTabs>
    );
};

DataTableTabFilters.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
    onChange: PropTypes.func.isRequired,
    config: PropTypes.arrayOf(PropTypes.object).isRequired,
    isAllDisplayed: PropTypes.bool,
    isV3: PropTypes.bool,
};

DataTableTabFilters.defaultProps = {
    isAllDisplayed: true,
    isV3: false,
};

export default DataTableTabFilters;
