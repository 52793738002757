import {createSelector} from 'reselect';

const selectContract = state => state.missions?.cra?.contract;
const selectScreen = state => state.missions?.cra?.screen;
const selectDocuments = state => state.missions?.cra?.documents;

const createCRAsSelector = () => createSelector([selectDocuments], documents => {
    if (!documents?.count) {
        return {data: [], count: 0};
    }
    return {data: documents.items, count: Number(documents.count)};
});

export const MissionsCraSelector = {
    selectContract,
    selectScreen,
    selectDocuments,
    createCRAsSelector,
};
