import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import {Box as JoyBox} from '@mui/joy';
import {
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    AppBar as MuiAppBar,
    Drawer as MuiDrawer,
    Link as MuiLink,
    SwipeableDrawer, Toolbar,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {Suspense, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Outlet, Link as RouterLink} from 'react-router-dom';
import {ReactComponent as HiText} from '../../assets/svg/logo/hi.svg';
import {ReactComponent as HiwayText} from '../../assets/svg/logo/hiway.svg';
import JoyUIThemeProvider from '../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {SuspenseContainer} from '../../components/suspense/Suspense';
import HiwayLocalStorage from '../../config/storage';
import {AccountDropdownMobile} from '../../features/account-dropdown/AccountDropdown';
import {AnimationActions} from '../../features/animations/store/animations.action';
import {AnimationsSelectors} from '../../features/animations/store/animations.selector';
import {TransactionsSelector} from '../../features/bank/modules/account-balance/store/transactions.selector';
import {COMPANY_STATUSES} from '../../features/company/modules/setup-company/utils/constants';
import {FreelancerSelectors} from '../../features/freelancer';
import {CompaniesSelectors} from '../../features/freelancer/modules/companies';
import {
    FreelancerOnboardingSidebar,
} from '../../features/freelancer/modules/onboarding/components/FreelancerOnboardingSidebar';
import {OnBoardingLaunchedStatus} from '../../features/freelancer/modules/onboarding/utils/constants';
import {UmbrellaOnboardingSidebar} from '../../features/onboarding/components/stepper/UmbrellaOnboardingSidebar';
import {OnboardingStatus} from '../../features/onboarding/utils/onboarding.constants';
import {RevenueSimulatorSelectors} from '../../features/revenue-simulator/store/revenue-simulator.selector';
import {AdministratorSidebar} from '../../features/sidebar/components/AdministratorSidebar/AdministratorSidebar';
import {FreelancerSidebar} from '../../features/sidebar/components/FreelancerSidebar/FreelancerSidebar';
import {SidebarActions} from '../../features/sidebar/store/sidebar.action';
import {SidebarSelectors} from '../../features/sidebar/store/sidebar.selector';
import {LoggedInUserSelectors} from '../../features/user/modules/logged-in-user';
import {UserStatus} from '../../features/user/modules/utils/constants';
import {useMemoizedCreateSelector} from '../../hooks/use-memoized-create-selector';
import {RoutePaths} from '../../lib/router/route-paths';
import SidebarSearchResult from '../../screens/user-list/SidebarSearchResult';
import {
    UserRoleColors,
    UserRoles,
    isUserFn,
    isUserUmbrellaFn, userDefaultColor, userRoleLocalStorageKey,
} from '../../utils/user-roles';

const drawerWidth = 240;
const miniDrawerWidth = 80;
const headerHeight = 70;
const mobileHeaderHeight = 50;
const swipableDrawerWidth = `calc(100vw - 64px)`;

const headerBgColor = '#502131'; // TODO: change to 'backgroung.cocoa'

const Main = styled('main', {shouldForwardProp: prop => prop !== 'open' && prop !== 'sx'})(
    ({theme, open}) => ({
        padding: theme.spacing(4),
        paddingTop: theme.spacing(3),
        // paddingTop: `calc(${theme.spacing(3)} + ${headerHeight}px)`, // fixes the content offset
        flexBasis: `calc(100% - ${open ? drawerWidth : miniDrawerWidth}px)`,
        minHeight: '100%',
        position: 'relative',
    }),
);

const openedMixin = theme => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = theme => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(10)})`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    backgroundColor: headerBgColor,
    height: headerHeight,
    color: 'white',
}));

// const AppBar = styled(MuiAppBar, {
//     shouldForwardProp: prop => prop !== 'open',
// })(({theme, open}) => ({
//     backgroundColor: headerBgColor,
//     zIndex: theme.zIndex.drawer + 1,
//     marginLeft: miniDrawerWidth,
//     width: `calc(100% - ${miniDrawerWidth}px)`,
//     transition: theme.transitions.create(['width', 'margin'], {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.leavingScreen,
//     }),
//     ...(open && {
//         marginLeft: drawerWidth,
//         width: `calc(100% - ${drawerWidth}px)`,
//         transition: theme.transitions.create(['width', 'margin'], {
//             easing: theme.transitions.easing.sharp,
//             duration: theme.transitions.duration.enteringScreen,
//         }),
//     }),
// }));

const MobileAppBar = styled(MuiAppBar, {
    shouldForwardProp: prop => prop !== 'open',
})(({theme}) => ({
    backgroundColor: headerBgColor,
    zIndex: theme.zIndex.drawer + 1,
    marginLeft: miniDrawerWidth,
    width: `calc(100% - ${miniDrawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: prop => prop !== 'open'})(
    ({theme, open}) => ({
        'width': drawerWidth,
        'flexShrink': 0,
        'whiteSpace': 'nowrap',
        'boxSizing': 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

/**
 * When sharing routes i.e. /change-password (all roles have this route), we had an issue with which sidebar
 * should be displayed (different sidebar components for different roles or different states (i.e. when freelancer
 * is in the onboarding process)). This component solves that by checking the user data and determining which sidebar
 * should be displayed.
 */
const SidebarWrapper = () => {
    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const freelancerAccount = useSelector(FreelancerSelectors.selectAccount);
    const company = useSelector(
        CompaniesSelectors.createCompanyByIdSelector(freelancerAccount?.defaultCompanyId),
    );

    if (!loggedInUser) {
        // TODO: Skeleton
        return null;
    }

    if (isUserFn(loggedInUser)) {
        if (!freelancerAccount) {
            // TODO: Skeleton
            return null;
        }

        if (isUserUmbrellaFn(loggedInUser)) {
            if (loggedInUser?.onboardingStatus === OnboardingStatus.COMPLETED) {
                return (
                    <JoyUIThemeProvider>
                        <FreelancerSidebar />
                    </JoyUIThemeProvider>
                );
            }
            return <UmbrellaOnboardingSidebar />;
        }

        if (!company) {
            // TODO: Skeleton
            return null;
        }

        if (company?.status !== COMPANY_STATUSES.ACTIVE
            || (loggedInUser.onboardingLaunchedStatus === OnBoardingLaunchedStatus.PENDING
            || loggedInUser.onboardingLaunchedStatus === OnBoardingLaunchedStatus.READY_FOR_LAUNCH)) {
            return <FreelancerOnboardingSidebar />;
        }

        return (
            <JoyUIThemeProvider>
                <FreelancerSidebar />
            </JoyUIThemeProvider>
        );
    }

    return (
        <JoyUIThemeProvider>
            <AdministratorSidebar />
        </JoyUIThemeProvider>
    );
};

export const Logo = ({userRoleColor}) => {
    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const freelancer = useSelector(FreelancerSelectors.selectAccount);
    const company = useMemoizedCreateSelector(
        CompaniesSelectors.createCompanyByIdSelector,
        freelancer?.defaultCompanyId,
    );

    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const isSidebarExpanded = useSelector(SidebarSelectors.selectIsSidebarExpanded);

    const isUserClient = isUserFn(loggedInUser);
    const isUserUmbrella = isUserUmbrellaFn(loggedInUser);
    const onboardingRoute = isUserUmbrella ? RoutePaths.UMBRELLA_ONBOARDING : RoutePaths.ONBOARDING;
    const dashboardRoute = isUserUmbrella ? RoutePaths.MISSIONS : RoutePaths.MISSIONS;

    if (isUserClient) {
        if (loggedInUser?.status !== UserStatus.ACTIVE || company?.status !== 'ACTIVE') {
            if (!isMobileSize) {
                return (
                    <MuiLink
                        component={RouterLink}
                        to={onboardingRoute}
                        display="flex"
                        alignItems="center"
                        sx={{flex: 1, paddingTop: '6px'}}
                    >
                        {!isSidebarExpanded
                            ? <HiwayText fill={userRoleColor} />
                            : <HiText fill={userRoleColor} />
                        }
                    </MuiLink>
                );
            }
            return (
                <MuiLink
                    component={RouterLink}
                    to={onboardingRoute}
                    sx={{paddingTop: '6px'}}
                >
                    <HiwayText fill={userRoleColor} />
                </MuiLink>
            );
        }
    }

    if (!isMobileSize) {
        return (
            <MuiLink
                component={RouterLink}
                to={dashboardRoute}
                display="flex"
                alignItems="center"
                sx={{flex: 1, paddingTop: '6px'}}
            >
                {!isSidebarExpanded
                    ? <HiwayText fill={userRoleColor} />
                    : <HiText fill={userRoleColor} />
                }
            </MuiLink>
        );
    }

    return (
        <MuiLink
            component={RouterLink}
            to={dashboardRoute}
            sx={{paddingTop: '6px'}}
        >
            <HiwayText fill={userRoleColor} />
        </MuiLink>
    );
};

Logo.propTypes = {
    userRoleColor: PropTypes.string.isRequired,
};

export const AppLayout = ({suspenseFallback, mainSx}) => {
    const [overflowHidden, setOverflow] = useState(false);

    const dispatch = useDispatch();

    const timeoutRef = useRef();

    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const isSidebarExpanded = useSelector(SidebarSelectors.selectIsSidebarExpanded);
    const isProfileMenuExpanded = useSelector(SidebarSelectors.selectIsProfileMenuExpanded);
    const selectedRole = useSelector(SidebarSelectors.selectSelectedRole);
    const sidebarText = useSelector(SidebarSelectors.selectSearchText);

    const isUserUmbrella = isUserFn(loggedInUser);
    const onboardingRoute = isUserUmbrella ? RoutePaths.UMBRELLA_ONBOARDING : RoutePaths.ONBOARDING;
    const dashboardRoute = isUserUmbrella ? RoutePaths.MISSIONS : RoutePaths.MISSIONS;

    const hasNewCategorizationOpened = useSelector(TransactionsSelector.selectCategorizationScreenInfo);

    const isAnimationInProgress = useSelector(AnimationsSelectors.selectIsinProgress);
    const isSubAnimationInProgress = useSelector(AnimationsSelectors.selectIsSubAnimationActive);

    const handleDrawerButtonClick = useCallback(() => {
        dispatch(SidebarActions.setIsSidebarExpanded(!isSidebarExpanded));
    }, [dispatch, isSidebarExpanded]);

    const handleMenuDrawerClick = useCallback(() => {
        dispatch(SidebarActions.setIsProfileMenuExpanded(!isProfileMenuExpanded));
    }, [dispatch, isProfileMenuExpanded]);

    // TODO this is mobile quickfix for animations
    useEffect(() => {
        dispatch(AnimationActions.setIsMobile(isMobileSize));
    }, [isMobileSize, dispatch]);

    useEffect(() => {
        if (loggedInUser?.role) {
            HiwayLocalStorage.set(userRoleLocalStorageKey, loggedInUser?.role);
        }
    }, [loggedInUser?.role]);

    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        if (isAnimationInProgress || isSubAnimationInProgress) {
            setOverflow(true);
            return;
        }

        timeoutRef.current = setTimeout(() => {
            setOverflow(false);
        }, 200);

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [isAnimationInProgress, isSubAnimationInProgress]);

    const userRole = loggedInUser?.role;

    // Decide what logo link should be
    const logoLink = userRole === UserRoles.ADMINISTRATOR
        ? RoutePaths.USERS : loggedInUser?.isFullyActive
            ? dashboardRoute : onboardingRoute;

    const role = userRole === UserRoles.ADMINISTRATOR ? selectedRole : userRole;
    const userBackupColor = HiwayLocalStorage.get(userRoleLocalStorageKey) ?? userDefaultColor;
    const userRoleColor = UserRoleColors[role] ?? userBackupColor;

    // TODO: remove this hack after MUI removing or after rewrite revenue simulator to the JoyUI
    // task: HPD-5639
    const currentStepIndex = useSelector(RevenueSimulatorSelectors.selectCurrentStepIndex);
    const mainBgColor = window.location.pathname === RoutePaths.REVENUE_SIMULATOR_CREATE && currentStepIndex
        ? '#F1F4F6FF'
        : '';

    if (!isMobileSize) {
        return (
            <>
                {!hasNewCategorizationOpened && (
                    <JoyUIThemeProvider>
                        <JoyBox
                            aria-label="open drawer"
                            onClick={handleDrawerButtonClick}
                            color="neutral.solidActiveBg"
                            fontSize="sm"
                            component="div"
                            sx={{
                                'position': 'fixed',
                                'cursor': 'pointer',
                                'width': '24px',
                                'height': '24px',
                                'lineHeight': '24px',
                                'textAlign': 'center',
                                'backgroundColor': 'background.body',
                                'top': 28,
                                'left': isSidebarExpanded ? 227 : 70,
                                'boxShadow': 'sm',
                                'borderRadius': 'lg',
                                'zIndex': 1299, // The standard MUI confirm dialog backdrop has 1300;
                                '&:hover': {
                                    backgroundColor: 'background.level1',
                                },
                                'transition': 'left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                                // TODO: Joy UI doesn't support transitions.create jet:
                                // wait for it or create the theme variable
                                // 'transition': theme => theme.transitions.create('left', {
                                //     easing: theme.transitions.easing.sharp,
                                //     duration: theme.transitions.duration.enteringScreen,
                                // }),
                            }}
                        >
                            <FontAwesomeIcon
                                icon={isSidebarExpanded ? faChevronLeft : faChevronRight}
                                fixedWidth
                            />
                        </JoyBox>
                    </JoyUIThemeProvider>
                )}

                <Box display="flex" minHeight="100vh">
                    {!hasNewCategorizationOpened && (
                        <Drawer
                            variant="permanent"
                            open={isSidebarExpanded}
                            PaperProps={{
                                sx: {
                                    height: '100%',
                                    borderRight: 0,
                                    boxShadow: '0px 3px 1px -2px rgba(11, 35, 51, 0.05), 0px 2px 2px rgba(11, 35, 51, 0.07), 0px 1px 5px rgba(11, 35, 51, 0.09)',
                                },
                                elevation: 0,
                            }}
                        >
                            <DrawerHeader sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '80px',
                                minHeight: '80px',
                            }}
                            >
                                <MuiLink component={RouterLink} to={logoLink} sx={{paddingTop: '6px'}}>
                                    {!isSidebarExpanded
                                        ? <HiText height="28px" fill={userRoleColor} />
                                        : <HiwayText height="28px" fill={userRoleColor} />
                                    }
                                </MuiLink>
                            </DrawerHeader>

                            <SidebarWrapper />
                        </Drawer>
                    )}

                    <Main
                        open={isSidebarExpanded}
                        sx={{
                            ...(hasNewCategorizationOpened
                                ? {padding: '0 !important'}
                                : overflowHidden
                                    ? {overflow: 'hidden'}
                                    : {}
                            ),
                            ...mainSx,
                            ...(mainBgColor ? {
                                backgroundColor: mainBgColor,
                            } : {}),
                        }}
                    >
                        <Container
                            maxWidth={hasNewCategorizationOpened ? false : 'xl'}
                            disableGutters
                            id="mainContainer"
                            sx={{height: '100%'}}
                        >
                            <Suspense fallback={suspenseFallback || <SuspenseContainer />}>
                                {!!sidebarText && (<SidebarSearchResult />)}
                                <Box sx={{
                                    display: sidebarText ? 'none' : 'block',
                                    height: '100%',
                                }}
                                >
                                    <Outlet />
                                </Box>
                            </Suspense>
                        </Container>
                    </Main>
                </Box>
            </>
        );
    }

    return (
        <>
            <MobileAppBar position="fixed" sx={{zIndex: 200, marginLeft: 0, width: '100%'}}>
                <Toolbar sx={{height: headerHeight, pl: 0, justifyContent: 'center'}}>
                    <DrawerHeader sx={{
                        position: 'absolute',
                        left: '20px',
                    }}
                    >
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerButtonClick}
                            edge="start"
                        >
                            <MenuIcon />
                        </IconButton>
                    </DrawerHeader>

                    <Logo userRoleColor={userRoleColor} />

                    <Button
                        sx={{position: 'absolute', right: '0px'}}
                        aria-label="menu"
                        onClick={() => handleMenuDrawerClick()}
                        color="inherit"
                        data-cypress="account-dropdown"
                    >
                        <PersonIcon />
                    </Button>
                </Toolbar>
            </MobileAppBar>

            <Main
                open={true}
                sx={{
                    px: 0,
                    paddingTop: theme => `calc(${theme.spacing(3)} + ${mobileHeaderHeight}px)`,
                    ...mainSx,
                }}
            >
                <Suspense fallback={<SuspenseContainer />}>
                    <Outlet />
                </Suspense>
            </Main>

            <SwipeableDrawer
                sx={{width: drawerWidth}}
                open={!isSidebarExpanded}
                onClose={handleDrawerButtonClick}
                onOpen={handleDrawerButtonClick}
            >
                <>
                    <Grid
                        sx={{
                            height: '65px',
                            background: headerBgColor,
                            width: swipableDrawerWidth,
                        }}
                    >
                        <Toolbar sx={{height: headerHeight, pl: 3}}>
                            <HiwayText height="28px" fill={userRoleColor} />

                            <IconButton
                                sx={{color: 'white', position: 'absolute', right: '0.5rem'}}
                                aria-label="open drawer"
                                onClick={handleDrawerButtonClick}
                                edge="start"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </Grid>

                    <Box sx={{width: swipableDrawerWidth}}>
                        <SidebarWrapper />
                    </Box>
                </>
            </SwipeableDrawer>

            <SwipeableDrawer
                anchor="right"
                sx={{'width': drawerWidth}}
                open={isProfileMenuExpanded}
                onClose={handleMenuDrawerClick}
                onOpen={handleMenuDrawerClick}
            >
                <Grid
                    sx={{height: '65px', background: headerBgColor, width: swipableDrawerWidth}}
                >
                    <Toolbar sx={{height: headerHeight, pl: 3}}>
                        <HiwayText fill={userRoleColor} />

                        <IconButton
                            sx={{color: 'white', position: 'absolute', right: '0.5rem'}}
                            aria-label="open drawer"
                            onClick={handleMenuDrawerClick}
                            edge="start"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </Grid>

                <AccountDropdownMobile onClose={() => handleMenuDrawerClick()} />
            </SwipeableDrawer>
        </>
    );
};

AppLayout.propTypes = {
    suspenseFallback: PropTypes.element.isRequired,
    mainSx: PropTypes.object,
};

AppLayout.defaultProps = {
    mainSx: {},
};

export const PublicAccessLayout = () => {
    return (
        <Suspense fallback={<SuspenseContainer />}>
            <Box sx={{
                display: 'block',
            }}
            >
                <Outlet />
            </Box>
        </Suspense>
    );
};
