import {combineReducers} from 'redux';

import {companyInformationReducer} from './company-information/company-information.reducer';
import {nonConvictionDeclarationReducer} from './non-conviction-declaration/non-conviction-declaration.reducer';
import {personalInformationReducer} from './personal-information/personal-information.reducer';
import * as actions from './setup-company.actions';
import {createReducer} from '../../../../../app/utils/store/create-reducer';
import {SETUP_COMPANY_STEPS} from '../utils/constants';

export const setupCompanyReducer = combineReducers({
    activeStep: createReducer(SETUP_COMPANY_STEPS.PERSONAL_INFORMATION, actions.SET_ACTIVE_STEP),
    isLoadingForm: createReducer(false, actions.SET_IS_LOADING_SETUP_COMPANY_FORM),
    personalInformation: personalInformationReducer,
    nonConvictionDeclaration: nonConvictionDeclarationReducer,
    companyInformation: companyInformationReducer,
});
