import {Box, Container} from '@mui/joy';
import React from 'react';
import {useTranslation} from 'react-i18next';
import PageHeader from '../../../components/joy-ui/headers/PageHeader';
import JoyUIThemeProvider from '../../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import useMediaQuery from '../../../components/joy-ui/mui-legacy/useMediaQuery';
import {importLocaleBundle} from '../../../lib/i18next';
import {LocalesConstants} from '../../../utils/locales-constants';
import RibAndDocuments from '../modules/rib-and-documents/components/RibAndDocuments';

importLocaleBundle('bankRibAndDocuments');

const BankRibAndDocumentsPage = () => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const {t} = useTranslation(LocalesConstants.BankRibAndDocuments);

    return (
        <JoyUIThemeProvider>
            <Container maxWidth="xl" disableGutters>
                {!isMobileSize && (
                    <PageHeader title={t('pageTitle')} />
                )}

                <Box sx={{mt: isMobileSize ? 2 : 3}}>
                    <RibAndDocuments />
                </Box>
            </Container>
        </JoyUIThemeProvider>

    );
};

export default BankRibAndDocumentsPage;
