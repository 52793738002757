import {coreApiInstance} from '../../../lib/api-providers/core-api-instance';

// const Client = function (dto) {
//     this.name = dto?.name;
//     // TODO Bojan complete dto value
// };

const Missions = function (dto) {
    // TODO Bojan complete dto value
    return dto;
    // this.id = dto.id;
    // this.clientName = dto?.clientName;
    // this.clientEmail = dto?.clientEmail;
    // this.client = new Client(dto?.client);
};

const getMission = ({userId, missionId}) => {
    return coreApiInstance({
        method: 'GET',
        url: `/api/v2/users/${userId}/missions/${missionId}`,
    }).then(({data}) => {
        return new Missions(data);
    });
};

const getMissionList = ({userId, status}) => {
    return coreApiInstance({
        method: 'GET',
        params: {
            status,
        },
        url: `/api/v2/users/${userId}/missions/list`,
    }).then(({data}) => {
        return new Missions(data);
    });
};

const putEditMission = ({userId, missionId, data}) => {
    return coreApiInstance({
        method: 'PUT',
        data: data,
        url: `/api/v2/users/${userId}/missions/${missionId}`,
    }).then(({data}) => {
        return new Missions(data);
    });
};

const postCreateMission = ({userId, data}) => {
    return coreApiInstance({
        method: 'POST',
        data: data,
        url: `/api/v2/users/${userId}/missions`,
    }).then(({data}) => {
        return new Missions(data);
    });
};

const postServiceAgreement = ({userId, missionId, file}) => {
    const formData = new FormData();

    formData.append('file', file[0]);

    return coreApiInstance({
        method: 'POST',
        data: formData,
        url: `/api/v2/users/${userId}/missions/${missionId}/service-agreement`,
    }).then(({data}) => {
        return data;
    });
};

const postWorkContract = ({userId, file}) => {
    const formData = new FormData();

    formData.append('file', file[0]);

    return coreApiInstance({
        method: 'POST',
        data: formData,
        url: `/api/v2/users/${userId}/missions/work-contract`,
    }).then(({data}) => {
        return data;
    });
};

export const MissionsApi = {
    postCreateMission,
    getMission,
    getMissionList,
    putEditMission,
    postServiceAgreement,
    postWorkContract,
};
