import {fromSettingsDTO, toSettingsDTO} from './settings.dto';
import axiosInstance, {safeTransformData} from '../../../app/api/axios';

export const getSettingsRequest = userId => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/users/${userId}/settings`,
    }).then(response => safeTransformData(response, fromSettingsDTO));
};

export const updateSettingsRequest = (userId, settings) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/users/${userId}/settings`,
        data: toSettingsDTO(settings),
    }).then(response => safeTransformData(response));
};
