import {faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Stack, Typography} from '@mui/joy';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ModalWrapper} from '../../../../../../components/modal-wrapper/ModalWrapper';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {UiActions} from '../../../../../ui/store/ui.action';
import {UiSelectors} from '../../../../../ui/store/ui.selector';
import {ModalsKeys} from '../../../../../ui/utils/constants';
import {VATDeclarationActions} from '../../../../../vat-declaration/store/vat-declaration.action';

const modalKey = ModalsKeys.SEND_VAT_DECLARATIONS;

const SendDeclarationsModal = () => {
    const {t} = useTranslation(LocalesConstants.Formalities);
    const dispatch = useDispatch();

    const modalData = useSelector(
        UiSelectors.createModalDataSelector(modalKey),
    ) ?? {};
    const {declarationIds, declarationsCount, sendAll, month, year} = modalData;
    const isSingleDeclaration = declarationIds?.length === 1;

    const inProgress = useSelector(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.VAT_DECLARATIONS_SEND_IN_PROGRESS,
    ));

    const handleConfirmSend = () => {
        if (isSingleDeclaration) {
            dispatch(VATDeclarationActions.sendSingleDeclaration({declarationId: declarationIds[0]}));
        } else if (sendAll) {
            dispatch(VATDeclarationActions.sendAllDeclarationsInBatch({month, year}));
        } else {
            dispatch(VATDeclarationActions.sendDeclarationsInBatch({declarationIds}));
        }

        dispatch(VATDeclarationActions.storeBatchProgress({}));
    };

    const handleCancel = () => {
        dispatch(UiActions.setActiveModal(modalKey, false));
    };

    const title = isSingleDeclaration
        ? t('vatDeclaration.sendDeclarationsModal.sendSingleDeclaration.title')
        : t('vatDeclaration.sendDeclarationsModal.sendMultipleDeclarations.title', {count: declarationIds?.length ?? declarationsCount});

    const message = isSingleDeclaration
        ? t('vatDeclaration.sendDeclarationsModal.sendSingleDeclaration.message')
        : t('vatDeclaration.sendDeclarationsModal.sendMultipleDeclarations.message', {count: declarationIds?.length ?? declarationsCount});

    return (
        <ModalWrapper
            isV3
            modalKey={modalKey}
            hasShowClose={!inProgress}
            title={(
                <Typography level="h1">
                    {title}
                </Typography>
            )}
            containerSx={{
                width: '580px',
            }}
        >
            <Stack spacing={4}>
                <Typography sx={{color: 'text.secondary'}} level="body-lg">
                    {message}
                </Typography>
                <Box sx={{display: 'flex', gap: 2, flex: 1}}>
                    <Button
                        sx={{flex: 1}}
                        color="neutral"
                        variant="outlined"
                        startDecorator={<FontAwesomeIcon icon={faArrowLeft} />}
                        onClick={handleCancel}
                        disabled={inProgress}
                    >
                        {t('vatDeclaration.sendDeclarationsModal.ctaReturn')}
                    </Button>
                    <Button
                        sx={{flex: 1}}
                        loadingPosition="start"
                        endDecorator={<FontAwesomeIcon icon={faArrowRight} />}
                        onClick={handleConfirmSend}
                        loading={inProgress}
                    >
                        {t('vatDeclaration.sendDeclarationsModal.ctaDeclare')}
                    </Button>
                </Box>
            </Stack>
        </ModalWrapper>
    );
};

export default SendDeclarationsModal;
