import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import GradingIcon from '@mui/icons-material/Grading';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import {Box, CircularProgress} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {DOCUMENT_STATUSES} from '../../../consts/document-constants';
import {LocalesConstants} from '../../../utils/locales-constants';

const DocumentStatusTag = ({status}) => {
    const {t} = useTranslation(LocalesConstants.Documents);

    const {icon, label} = useMemo(() => {
        switch (status) {
            case DOCUMENT_STATUSES.ERROR:
                return {
                    icon: <ErrorIcon sx={{color: getColorByStatus(status).iconColor}} />,
                    label: t('documentsDatabaseStatuses.ERROR'),
                };

            case DOCUMENT_STATUSES.PENDING_SIGNING:
                return {
                    icon: <HourglassTopIcon sx={{color: getColorByStatus(status).iconColor}} />,
                    label: t('documentsDatabaseStatuses.PENDING_SIGNING'),
                };

            case DOCUMENT_STATUSES.PENDING_COMPANY_ID:
                return {
                    icon: <WatchLaterIcon sx={{color: getColorByStatus(status).iconColor}} />,
                    label: t('documentsDatabaseStatuses.PENDING_COMPANY_ID'),
                };

            case DOCUMENT_STATUSES.DONE:
                return {
                    icon: <CheckCircleIcon sx={{color: getColorByStatus(status).iconColor}} />,
                    label: t('documentsDatabaseStatuses.DONE'),
                };

            case DOCUMENT_STATUSES.GENERATING:
                return {
                    icon: <CircularProgress sx={{color: getColorByStatus(status).iconColor}} />,
                    label: t('documentsDatabaseStatuses.GENERATING'),
                };

            case DOCUMENT_STATUSES.SIGNED:
                return {
                    icon: <VerifiedUserIcon sx={{color: getColorByStatus(status).iconColor}} />,
                    label: t('documentsDatabaseStatuses.SIGNED'),
                };

            case DOCUMENT_STATUSES.GENERATED:
                return {
                    icon: <GradingIcon sx={{color: getColorByStatus(status).iconColor}} />,
                    label: t('documentsDatabaseStatuses.GENERATED'),
                };

            default:
                return null;
        }
    }, [status, t]);

    if (!icon || !label) {
        return null;
    }

    return (
        <Box
            sx={{
                'display': 'flex',
                'flexDirection': 'row',
                'backgroundColor': getColorByStatus(status).bgShade,
                'padding': '2px 8px',
                'alignSelf': 'strech',
                'alignItems': 'center',
                'borderRadius': '8px',
                'fontSize': theme => theme.typography.pxToRem(16),
                'color': getColorByStatus(status).shade,
                '.MuiSvgIcon-root': {
                    fontSize: theme => theme.typography.pxToRem(16),
                },
                'gap': 1,
                'fontWeight': 500,
            }}
        >
            {icon}
            <Box>
                {label}
            </Box>
        </Box>
    );
};

const getColorByStatus = status => {
    switch (status) {
        case DOCUMENT_STATUSES.ERROR: return {
            iconColor: 'v2.light.error.main',
            bgShade: 'v2.light.error.shades12',
            shade: 'v2.light.error.shades160',
        };
        case DOCUMENT_STATUSES.PENDING_SIGNING: return {
            iconColor: 'v2.light.warning.main',
            bgShade: 'v2.light.warning.shades12',
            shade: 'v2.light.warning.shades160',
        };
        case DOCUMENT_STATUSES.PENDING_COMPANY_ID: return {
            iconColor: 'v2.light.primary.main',
            bgShade: 'v2.light.primary.shades12',
            shade: 'v2.light.primary.shades160',
        };
        case DOCUMENT_STATUSES.DONE: return {
            iconColor: 'v2.light.success.main',
            bgShade: 'v2.light.success.shades12',
            shade: 'v2.light.success.shades160',
        };
        case DOCUMENT_STATUSES.GENERATING: return {
            iconColor: 'v2.light.primary.main',
            bgShade: 'v2.light.primary.shades12',
            shade: 'v2.light.primary.shades160',
        };
        case DOCUMENT_STATUSES.SIGNED: return {
            iconColor: 'v2.light.success.main',
            bgShade: 'v2.light.success.shades12',
            shade: 'v2.light.success.shades160',
        };
        case DOCUMENT_STATUSES.GENERATED: return {
            iconColor: 'v2.light.success.main',
            bgShade: 'v2.light.success.shades12',
            shade: 'v2.light.success.shades160',
        };
    }
};

DocumentStatusTag.propTypes = {
    status: PropTypes.oneOf(Object.values(DOCUMENT_STATUSES)).isRequired,
};

export default DocumentStatusTag;
