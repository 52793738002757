import CheckIcon from '@mui/icons-material/Check';
import HistoryIcon from '@mui/icons-material/History';
import {Box, Button, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import CategorisationSection from '../../../../../bank/modules/account-balance/components/categorisation/components/header/CategorisationSection';
import {UiActions} from '../../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../../ui/utils/constants';

const FlowStepFormalityStatus = () => {
    const {t} = useTranslation(LocalesConstants.Companies);
    const dispatch = useDispatch();

    return (
        <CategorisationSection
            title={t('formalities.depositOfAccounts.flowSteps.formalityValidated.title')}
            isFocused={true}
            isActive={true}
            statusTranslationSource="companies"
            statusTranslationPath="accounting.uploadFec.statuses"
            sx={{width: '100%', maxWidth: '600px'}}
        >
            <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                px: 2,
            }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography>
                        {t('formalities.depositOfAccounts.flowSteps.formalityValidated.message')}
                    </Typography>
                    <Box
                        sx={{
                            backgroundColor: 'v2.light.success.shades4',
                            padding: '2px 8px',
                            borderRadius: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontWeight: 500,
                            gap: 0.5,
                        }}
                    >
                        <CheckIcon sx={{color: 'v2.light.success.light'}} />
                        {t('formalities.depositOfAccounts.flowSteps.formalityValidated.validated')}
                    </Box>
                </Box>
                <Button
                    variant="text"
                    startIcon={<HistoryIcon />}
                    sx={{px: 2}}
                    onClick={() => {
                        dispatch(UiActions.setModalData(
                            ModalsKeys.DEPOSIT_OF_ACCOUNTS_MANUAL_MODE,
                            {resetFormality: true},
                        ));
                        dispatch(UiActions.setActiveModal(ModalsKeys.DEPOSIT_OF_ACCOUNTS_MANUAL_MODE, true));
                    }}
                >
                    {t('formalities.depositOfAccounts.flowSteps.formalityValidated.buttonResetStatus')}
                </Button>
            </Box>
        </CategorisationSection>
    );
};

export default FlowStepFormalityStatus;
