import {Box, Tooltip, Typography, Zoom} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import VatIcon from '../../../../../../../../assets/svg/pacman/blue.svg';
import CategoryIcon from '../../../../../../../../assets/svg/pacman/red.svg';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';

const PacmanGhosts = ({
    step,
    containerWidth,
    animationSpeed,
    zIndex,
    isReimbursement,
}) => {
    const {t} = useTranslation(LocalesConstants.Bank);

    return (
        <Box sx={{
            'display': 'flex',
            'width': '100%',
            'justifyContent': 'space-between',
            '& img': {
                display: 'block',
            },
            'pl': `calc(${containerWidth}px/2)`,
            'zIndex': zIndex.ghosts,
        }}
        >

            {/* First ghost */}
            <Box sx={{
                position: 'relative',
                height: '16px',
            }}
            >
                <Zoom
                    in={step === 0}
                    timeout={step === 0 ? animationSpeed : (animationSpeed / 2) + 100}
                    style={{transitionDelay: step === 1 ? '500ms' : '0ms'}}
                >
                    <Typography
                        sx={{
                            position: 'absolute',
                            color: 'v2.light.secondary.main',
                            fontFamily: 'Barlow',
                            fontSize: theme => theme.typography.pxToRem(9),
                            lineHeight: theme => theme.typography.pxToRem(16),
                            fontWeight: 600,
                            letterSpacing: '0.9px',
                            textTransform: 'uppercase',
                            top: 0,
                            right: '16px',
                            background: 'white',
                            display: 'inline-block',
                            px: 0.5,
                        }}
                    >
                        {t('categorisation.pacman.category')}
                    </Typography>
                </Zoom>

                <Box sx={{
                    visibility: step === 0 ? 'visible' : 'hidden',
                    transition: `0.5s linear ${(animationSpeed / 1000) - 0.6}s`,
                }}
                >
                    <img
                        style={{
                            position: 'relative',
                            zIndex: 1,
                        }}
                        src={CategoryIcon}
                        alt={t('categorisation.pacman.category')}
                    />
                </Box>
            </Box>

            {/* Second ghost */}
            <Box sx={{position: 'relative', height: '16px'}}>
                <Zoom
                    in={step === 1}
                    timeout={step === 1 ? animationSpeed : (animationSpeed / 2) + 100}
                    style={{transitionDelay: step === 1 ? '500ms' : '0ms'}}
                >
                    <Typography
                        variant="span"
                        sx={{
                            position: 'absolute',
                            color: '#0097A7',
                            fontFamily: 'Barlow',
                            fontSize: theme => theme.typography.pxToRem(9),
                            lineHeight: theme => theme.typography.pxToRem(16),
                            fontWeight: 600,
                            letterSpacing: '0.9px',
                            textTransform: 'uppercase',
                            top: 0,
                            zIndex: 0,
                            right: '16px',
                            background: 'white',
                            display: 'inline-block',
                            px: 0.5,
                        }}
                    >
                        {isReimbursement
                            ? t('categorisation.pacman.transactions')
                            : t('categorisation.pacman.invoices')}
                    </Typography>
                </Zoom>

                <Box sx={{
                    visibility: step < 2 ? 'visible' : 'hidden',
                    transition: `0.5s linear ${(animationSpeed / 1000) - 0.6}s`,
                }}
                >
                    <Tooltip
                        title={t('categorisation.pacman.invoices')}
                        placement="top"
                    >
                        <img
                            style={{
                                position: 'relative',
                                zIndex: 2,
                            }}
                            src={VatIcon}
                            alt={t('categorisation.pacman.invoices')}
                        />
                    </Tooltip>
                </Box>
            </Box>
        </Box>
    );
};

PacmanGhosts.propTypes = {
    step: PropTypes.number.isRequired,
    animationSpeed: PropTypes.number.isRequired,
    containerWidth: PropTypes.number.isRequired,
    zIndex: PropTypes.object.isRequired,
    isReimbursement: PropTypes.bool.isRequired,
};

export default PacmanGhosts;
