import {combineReducers} from 'redux';
import {MissionClientsActionType} from './missions-client.action-type';
import {createReducer} from '../../../../../app/utils/store/create-reducer';
import {MISSION_CLIENT_SCREENS} from '../utils/constants';

export const missionsClientReducer = combineReducers({
    clientScreen: createReducer(
        MISSION_CLIENT_SCREENS.PENDING_SIGNING,
        MissionClientsActionType.SET_CLIENT_SCREEN,
        true,
    ),
    documentInfo: createReducer(null, MissionClientsActionType.STORE_DOCUMENT_INFO, true),
    signature: createReducer(null, MissionClientsActionType.STORE_CLIENT_SIGNATURE, true),
});
