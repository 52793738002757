import {faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Checkbox, Stack, Typography} from '@mui/joy';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {ModalsKeys} from '../../../ui/utils/constants';
import {VATDeclarationActions} from '../../store/vat-declaration.action';
import {VatDeclarationSelectors} from '../../store/vat-declaration.selector';

const modalKey = ModalsKeys.VAT_DECLARATION_RESEND_DECLARATION;

export const ResendModal = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation(LocalesConstants.VatDeclaration);
    const [isChecked, setIsChecked] = useState(false);
    const declaration = useSelector(VatDeclarationSelectors.selectVATDeclaration);
    const isSubmitting = useSelector(LoadingSelectors.createLoadingSelectorByType(LoadingTypes.VAT_DECLARATION_RESEND));

    const handleConfirm = () => {
        dispatch(VATDeclarationActions.resendDeclaration({declarationId: declaration.id}));
    };

    return (
        <ModalWrapper
            isV3
            modalKey={modalKey}
            title={<Typography level="h1">{t('resendDeclarationModal.title')}</Typography>}
            buttons={() => (
                <Stack direction="row" spacing={2} width="100%">
                    <Button
                        variant="solid"
                        color="primary"
                        size="lg"
                        startDecorator={<FontAwesomeIcon icon={faPaperPlane} />}
                        loading={isSubmitting}
                        loadingPosition="end"
                        disabled={!isChecked || isSubmitting}
                        onClick={handleConfirm}
                        fullWidth
                    >
                        {t('resendDeclarationModal.confirm')}
                    </Button>
                </Stack>
            )}
        >
            <Typography level="body-lg" color="text.secondary">
                {t('resendDeclarationModal.description')}
            </Typography>
            <Stack direction="row" spacing={2} alignItems="center" mt={2}>
                <Checkbox checked={isChecked} onChange={() => setIsChecked(value => !value)} />
                <Typography level="body-lg" color="text.secondary">
                    {t('resendDeclarationModal.confirmationCheckbox')}
                </Typography>
            </Stack>
        </ModalWrapper>
    );
};
