import {combineReducers} from 'redux';

import * as actions from './auth.actions';
import {emailVerificationFormReducer} from './emailVerificationForm/emailVerificationFormReducer';
import {loginFormReducer} from './loginForm/loginForm.reducer';
import {newPasswordFormReducer} from './newPasswordForm/newPasswordForm.reducer';
import {resetPasswordFormReducer} from './resetPasswordForm/resetPasswordForm.reducer';
import {createReducer} from '../../../app/utils/store/create-reducer';

export const authReducer = combineReducers({
    challengeName: createReducer('', actions.STORE_CHALLENGE_NAME),
    currentCognitoUser: createReducer(null, actions.STORE_CURRENT_COGNITO_USER),
    emailVerificationForm: emailVerificationFormReducer,
    loginForm: loginFormReducer,
    newPasswordForm: newPasswordFormReducer,
    resetPasswordForm: resetPasswordFormReducer,
    user: createReducer(null, actions.STORE_USER),
});
