import {LoadingButton} from '@mui/lab';
import {Typography, useMediaQuery} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ButtonAction} from '../../../../components/buttons/ButtonAction';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {ModalsKeys} from '../../../ui/utils/constants';
import {TrainingActions} from '../../store/training.action';

export const DossierResetDateModal = () => {
    const dispatch = useDispatch();

    const {t} = useTranslation(LocalesConstants.Training);
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isLoadingNextStep = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.TRAINING_STEP_SUBMIT),
    );

    const onResetDates = () => {
        dispatch(TrainingActions.resetDates());
    };

    return (
        <ModalWrapper
            modalKey={ModalsKeys.TRAINING_DOSSIER_RESET_DATE}
            title={(
                <Typography variant={isMobileSize ? 'h4' : 'h5'}>
                    {t('dossierResetDateModal.header')}
                </Typography>
            )}
            buttons={({handleClose}) => (
                <>
                    <ButtonAction
                        fullWidth={isMobileSize}
                        onClick={handleClose}
                        sx={{mr: 3}}
                        color="grey"
                        disabled={isLoadingNextStep}
                    >
                        {t('dossierResetDateModal.cancel')}
                    </ButtonAction>

                    <LoadingButton
                        fullWidth={isMobileSize}
                        color="secondary"
                        type="submit"
                        onClick={onResetDates}
                        loading={isLoadingNextStep}
                        variant="contained"
                    >
                        {t('dossierResetDateModal.submit')}
                    </LoadingButton>
                </>
            )}
            containerSx={{
                width: '484px',
            }}
        >
            <Typography>
                {t('dossierResetDateModal.text')}
            </Typography>
        </ModalWrapper>
    );
};
