import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Textarea, Typography} from '@mui/joy';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ModalWrapper} from '../../../../../components/modal-wrapper/ModalWrapper';
import {useIsMobileSize} from '../../../../../hooks/use-is-mobile-size';
import {LocalesConstants} from '../../../../../utils/locales-constants';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';
import {ModalsKeys} from '../../../../ui/utils/constants';
import {MissionClientsActions} from '../store/missions-client.actions';

const modalKey = ModalsKeys.DEMAND_MODIFICATIONS_MODAL;

const DemandModificationsModal = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation(LocalesConstants.Client);
    const isMobileSize = useIsMobileSize();

    const [textValue, setTextValue] = useState();

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(
            LoadingTypes.GENERIC_CRUD_LOADER,
        ),
    );

    const onSubmit = () => {
        dispatch(MissionClientsActions.postRequireModifications(textValue));
    };

    return (
        <ModalWrapper
            isV3
            modalKey={modalKey}
            title={(
                <Typography level="h1">
                    {t('demandModifications.title')}
                </Typography>
            )}
            buttonsSx={{
                flexDirection: {desktop: 'row', mobile: 'column-reverse'},
            }}
            buttons={({handleClose}) => (
                <>
                    <Button
                        fullWidth={isMobileSize}
                        disabled={isLoading}
                        loadingPosition="start"
                        color="neutral"
                        size="lg"
                        variant="outlined"
                        onClick={handleClose}
                    >
                        {t('demandModifications.cancel')}
                    </Button>

                    <Button
                        fullWidth={isMobileSize}
                        endDecorator={(
                            <FontAwesomeIcon icon={faArrowRight} />
                        )}
                        loading={isLoading}
                        loadingPosition="start"
                        color="primary"
                        size="lg"
                        onClick={onSubmit}
                    >
                        {t('demandModifications.confirm')}
                    </Button>
                </>
            )}
        >
            <Typography>
                {t('demandModifications.text')}
            </Typography>

            <Textarea
                onChange={e => {
                    setTextValue(e?.currentTarget?.value);
                }}
                sx={{
                    mt: 2,
                    background: 'white',
                }}
                required
                minRows={4}
                placeholder={t('demandModifications.placeholder')}
            />
        </ModalWrapper>
    );
};

export default DemandModificationsModal;
