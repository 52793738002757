import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import DrawSignature from './DrawSignature';
import EmptySignature from './EmptySignature';
import mobilePending from '../../../assets/svg/signing/pending-mobile.svg';
import {LocalesConstants} from '../../../utils/locales-constants';
import {SIGNING_OPTIONS} from '../utils/constants';

export const SignatureDisplay = ({
    selectedSigningOption,
    signatureUrl,
    imagePreviewUrl,
    signaturePad,
    setSignaturePad,
    isPadEmpty,
    setIsPadEmpty,
    isUploaded,
    isMobileSize,
}) => {
    const {t} = useTranslation(LocalesConstants.Signature);

    const width = isMobileSize ? '100%' : '400px';

    if (!selectedSigningOption) {
        if (signatureUrl) {
            return (
                <img
                    style={{maxHeight: '256px', width}}
                    src={signatureUrl}
                    alt="Signature"
                />
            );
        }

        return <EmptySignature />;
    }

    if (selectedSigningOption === SIGNING_OPTIONS.DRAW) {
        return (
            <DrawSignature
                signaturePad={signaturePad}
                setSignaturePad={setSignaturePad}
                isPadEmpty={isPadEmpty}
                setIsPadEmpty={setIsPadEmpty}
                isMobileSize={isMobileSize}
            />
        );
    }

    if (selectedSigningOption === SIGNING_OPTIONS.UPLOAD) {
        if (imagePreviewUrl) {
            return (
                <Box style={{width, height: '256px', border: '1px solid #ccc'}}>
                    <img
                        src={imagePreviewUrl}
                        alt="Signature Preview"
                        style={{width, height: '100%', objectFit: 'contain'}}
                    />
                </Box>
            );
        }

        if (signatureUrl) {
            return (
                <img
                    style={{maxHeight: '256px'}}
                    src={signatureUrl}
                    width="100%"
                    alt="Signature"
                />
            );
        }

        return <EmptySignature />;
    }

    if (selectedSigningOption === SIGNING_OPTIONS.QR) {
        if (isUploaded) {
            return (
                <Box style={{width, height: '256px', border: '1px solid #ccc'}}>
                    <img
                        src={signatureUrl}
                        alt="Signature Preview"
                        style={{width: '100%', height: '100%', objectFit: 'contain'}}
                    />
                </Box>
            );
        }

        if (imagePreviewUrl) {
            return (
                <Box style={{width, height: '256px', border: '1px solid #ccc'}}>
                    <img
                        src={imagePreviewUrl}
                        alt="Signature Preview"
                        style={{width: '100%', height: '100%', objectFit: 'contain'}}
                    />
                </Box>
            );
        }

        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
            }}
            >
                <img src={mobilePending} style={{maxHeight: '72 px'}} alt="scan pending" />
                <Typography>
                    {t('uploadSignatureModal.waitScan')}
                </Typography>
            </Box>
        );
    }

    return null;
};

SignatureDisplay.propTypes = {
    selectedSigningOption: PropTypes.string.isRequired,
    signatureUrl: PropTypes.string,
    imagePreviewUrl: PropTypes.string,
    signaturePad: PropTypes.object.isRequired,
    setSignaturePad: PropTypes.func.isRequired,
    isPadEmpty: PropTypes.bool.isRequired,
    setIsPadEmpty: PropTypes.func.isRequired,
    isUploaded: PropTypes.bool.isRequired,
    isMobileSize: PropTypes.bool,
};

SignatureDisplay.defaultProps = {
    signatureUrl: '',
    imagePreviewUrl: '',
    isMobileSize: false,
};
