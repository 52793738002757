import {combineReducers} from 'redux';
import {adminExpensesReducer} from './admin-expenses/admin-expenses.reducer';
import * as actions from './expenses.actions';
import {homeOfficeTravelsFormReducer} from './home-office-travels-form/home-office-travels-form.reducer';
import {businessAllowanceReducer} from '../modules/business-allowance/store/business-allowance.reducer';
import {personalExpensesFormReducer} from '../modules/personal/store/personal-expenses-form/personal-expenses-form.reducer';
import {personalExpensesReducer} from '../modules/personal/store/personal-expenses.reducer';
import {professionalExpensesFormReducer} from '../modules/professional/store/professional-expenses-form/professional-expenses-form.reducer';
import {professionalExpensesReducer} from '../modules/professional/store/professional-expenses.reducer';
import {professionalTravelsFormReducer} from '../modules/professional/store/professional-travels-form/professional-travels-form.reducer';

const totalDistancePerVehicleTypeReducer = (state = {}, action) => {
    if (action.type === actions.STORE_TOTAL_DISTANCE) {
        return action.payload;
    }

    return state;
};

export const expensesReducer = combineReducers({
    personalExpenses: personalExpensesReducer,
    personalExpensesForm: personalExpensesFormReducer,
    professionalExpenses: professionalExpensesReducer,
    professionalExpensesForm: professionalExpensesFormReducer,
    homeOfficeTravelsForm: homeOfficeTravelsFormReducer,
    professionalTravelsForm: professionalTravelsFormReducer,
    businessAllowance: businessAllowanceReducer,
    adminExpenses: adminExpensesReducer,
    totalDistancePerVehicleType: totalDistancePerVehicleTypeReducer,
});
