import hiwayLocalStorage from '../config/storage';

export const getPaginationAndSortParams = ({
    search,
    queryPrefix,
    transformSortByParam = value => value,
    hasPagination = true,
}) => {
    const storageKey = `${queryPrefix}-savedRowsPerPage`;
    const limit = search?.[`${queryPrefix}-rowsPerPage`]
        ?? (hiwayLocalStorage.has(storageKey) ? parseInt(hiwayLocalStorage.get(storageKey), 10) : 10);

    return {
        ...(hasPagination && {
            limit: limit,
            offset: search?.[`${queryPrefix}-page`] ? parseInt(search[`${queryPrefix}-page`], 10) * limit : 0,
        }),
        sortBy: search?.[`${queryPrefix}-sortBy`]
            ? transformSortByParam(search[`${queryPrefix}-sortBy`])
            : undefined,
        sortOrder: search?.[`${queryPrefix}-sortBy`]
            ? search?.[`${queryPrefix}-sortDirection`]
                ? search?.[`${queryPrefix}-sortDirection`].toUpperCase()
                : 'DESC'
            : undefined,
    };
};
