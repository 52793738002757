import {
    faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Input} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {LocalesConstants} from '../../../../utils/locales-constants';

export const Search = ({value, setValue}) => {
    const {t} = useTranslation(LocalesConstants.Common);

    const handleSearch = e => {
        setValue(e.target.value);
    };

    return (
        <Input
            variant="soft"
            color="neutral"
            size="sm"
            placeholder={t('searchPlaceholder')}
            onChange={handleSearch}
            value={value}
            startDecorator={(
                <Box
                    color="text.icon"
                    fontSize="md"
                >
                    <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        fixedWidth
                    />
                </Box>
            )}
            sx={{
                'mb': 2,
                'py': 1,
                'backgroundColor': 'background.body',
                '&.--Input-placeholderColor': {
                    color: 'inherit',
                },
            }}
        />
    );
};

Search.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func.isRequired,
};

Search.defaultProps = {
    value: null,
};
