export const GET_USER = 'GET_USER';
export const SET_IS_LOADING_USER = 'SET_IS_LOADING_USER';
export const STORE_USER = 'STORE_USER';
export const ACTIVATE_USER = 'ACTIVATE_USER';
export const DEACTIVATE_USER = 'DEACTIVATE_USER';
export const REINVITE_USER = 'REINVITE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_PERSONAL_DATA = 'UPDATE_USER_PERSONAL_DATA';
export const SET_IS_UPDATING_USER = 'SET_IS_UPDATING_USER';
export const ENABLE_CAN_SETUP_COMPANY = 'ENABLE_CAN_SETUP_COMPANY';
export const SET_IS_UPDATING_CAN_SETUP_COMPANY = 'SET_IS_UPDATING_CAN_SETUP_COMPANY';
export const SET_ADMIN_MUST_ENTER_DATA_BEFORE_SETUP_COMPANY = 'SET_ADMIN_MUST_ENTER_DATA_BEFORE_SETUP_COMPANY';
export const SET_IS_UPDATING_USER_ACTIVE_STATUS = 'SET_IS_UPDATING_USER_ACTIVE_STATUS';
export const GET_LOGGED_IN_USER = 'GET_LOGGED_IN_USER';
export const UPDATE_LOGGED_IN_USER = 'UPDATE_LOGGED_IN_USER';
export const STORE_LOGGED_IN_USER = 'STORE_LOGGED_IN_USER';
export const SET_IS_LOADING_LOGGED_IN_USER = 'SET_IS_LOADING_LOGGED_IN_USER';
export const SET_IS_UPDATING_LOGGED_IN_USER = 'SET_IS_UPDATING_LOGGED_IN_USER';
export const STORE_COACH_ACCOUNTS = 'STORE_COACH_ACCOUNTS';

export const getUser = userId => ({
    type: GET_USER,
    payload: userId,
});

export const storeUser = user => ({
    type: STORE_USER,
    payload: user,
});

export const activateUser = userId => ({
    type: ACTIVATE_USER,
    payload: userId,
});

export const deactivateUser = userId => ({
    type: DEACTIVATE_USER,
    payload: userId,
});

export const reinviteUser = userId => ({
    type: REINVITE_USER,
    payload: userId,
});

export const updateUser = (userId, user, propagateAddressChange = false, callback = undefined) => ({
    type: UPDATE_USER,
    payload: {
        userId,
        user,
        propagateAddressChange,
        callback,
    },
});

export const updateUserPersonalData = (userId, data, callback = undefined) => ({
    type: UPDATE_USER_PERSONAL_DATA,
    payload: {
        userId,
        data,
        callback,
    },
});

export const setIsUpdatingUser = isLoading => ({
    type: SET_IS_UPDATING_USER,
    payload: isLoading,
});

export const enableCanSetupCompany = (data, companyType) => ({
    type: ENABLE_CAN_SETUP_COMPANY,
    payload: {
        data,
        companyType,
    },
});

export const setIsUpdatingCanSetupCompany = isUpdating => ({
    type: SET_IS_UPDATING_CAN_SETUP_COMPANY,
    payload: isUpdating,
});

export const setAdminMustEnterDataBeforeSetupCompany = setIsDataMissing => ({
    type: SET_ADMIN_MUST_ENTER_DATA_BEFORE_SETUP_COMPANY,
    payload: setIsDataMissing,
});

export const setIsUpdatingUserActiveStatus = isLoading => ({
    type: SET_IS_UPDATING_USER_ACTIVE_STATUS,
    payload: isLoading,
});

export const getLoggedInUser = () => ({
    type: GET_LOGGED_IN_USER,
});

export const updateLoggedInUser = user => ({
    type: UPDATE_LOGGED_IN_USER,
    payload: user,
});

export const storeLoggedInUser = user => ({
    type: STORE_LOGGED_IN_USER,
    payload: user,
});

export const setIsLoadingLoggedInUser = isLoading => ({
    type: SET_IS_LOADING_LOGGED_IN_USER,
    payload: isLoading,
});

export const setIsUpdatingLoggedInUser = isUpdating => ({
    type: SET_IS_UPDATING_LOGGED_IN_USER,
    payload: isUpdating,
});

export const setIsLoadingUser = isLoading => ({
    type: SET_IS_LOADING_USER,
    payload: isLoading,
});

export const storeCoachAccounts = coachAccounts => ({
    type: STORE_COACH_ACCOUNTS,
    payload: coachAccounts,
});
