import {all, call, delay, put, select, takeLatest} from 'redux-saga/effects';
import {MainOnboardingActionTypes} from './main-onboarding.action-types';
import {MainOnboardingActions} from './main-onboarding.actions';
import {MainOnboardingSelectors} from './main-onboarding.slector';
import {UmbrellaStepperOnboardingActions} from './umbrella/umbrella-onboarding.actions';
// eslint-disable-next-line import/no-cycle
import {watchUmbrellaStepperSaga} from './umbrella/umbrella-onboarding.saga';
import {push} from '../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../lib/router/route-paths';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import {AnimationActions} from '../../animations/store/animations.action';
import {LoadingActions, LoadingSelectors, LoadingTypes} from '../../loading';
import {LoggedInUserSelectors} from '../../user/modules/logged-in-user';
import {getLoggedInUserAccountFlow} from '../../user/modules/logged-in-user/store/logged-in-user.saga';
import {OnboardingApi} from '../api/onboarding.api';
import {TERM_DOCUMENTS} from '../utils/onboarding.constants';


const flow = {
    getOnboardingFlow: function* ({userId}) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, true));

            const onboarding = yield call(OnboardingApi.getOnboarding, {userId});

            yield put(MainOnboardingActions.storeOnboarding(onboarding));

            return onboarding;
        } catch (e) {
            Debug.error('main onboarding', 'Error: ', {e});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, false));
        }
    },

    postLaunchOnboardingFlow: function* ({userId, onboardingId}) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

            const onboarding = yield call(OnboardingApi.postLunchOnboarding, {userId, onboardingId});

            yield put(MainOnboardingActions.storeOnboarding(onboarding));

            return onboarding;
        } catch (e) {
            Debug.error('main onboarding', 'Error: ', {e});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
        }
    },

    postPersonalInformationFlow: function* ({userId, information}) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

            const onboarding = yield call(OnboardingApi.postPersonalInformation, {userId, information});

            yield put(MainOnboardingActions.storeOnboarding(onboarding));

            return onboarding;
        } catch (e) {
            Debug.error('main onboarding', 'Error: ', {e});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
        }
    },

    postPersonalDocumentsFlow: function* ({userId, documents}) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

            const onboarding = yield call(OnboardingApi.postDocumentsOnboarding, {userId, documents});

            yield put(MainOnboardingActions.storeOnboarding(onboarding));

            return onboarding;
        } catch (e) {
            Debug.error('main onboarding', 'Error: ', {e});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
        }
    },


    getTermsFlow: function* () {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

            return yield call(OnboardingApi.getTerms);
        } catch (e) {
            Debug.error('main onboarding', 'Error: ', {e});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
        }
    },

    putSignLegalDocumentFlow: function* ({userId, termId}) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

            const onboarding = yield call(OnboardingApi.postSignTerm, {userId, termId});

            yield put(MainOnboardingActions.storeOnboarding(onboarding));

            return onboarding;
        } catch (error) {
            Debug.error('signing', 'Error: ', {error});
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
        }
    },
};

const workers = {
    getOnboardingWorker: function* ({payload}) {
        const isOnboardingDataLoading = yield select(
            LoadingSelectors.createLoadingSelectorByType(LoadingTypes.ONBOARDING),
        );

        if (isOnboardingDataLoading) {
            return;
        }

        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

        yield call(flow.getOnboardingFlow, {userId: payload ?? loggedInUser?.id});
    },

    postLaunchOnboardingWorker: function* () {
        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
        const onboarding = yield select(MainOnboardingSelectors.selectOnboarding);

        if (loggedInUser?.id && onboarding?.id) {
            const postLunchOnboarding = yield call(
                flow.postLaunchOnboardingFlow,
                {userId: loggedInUser.id, onboardingId: onboarding.id},
            );

            if (postLunchOnboarding) {
                yield put(MainOnboardingActions.storeOnboarding(postLunchOnboarding));
                yield call(getLoggedInUserAccountFlow);
                yield delay(50);
                yield put(push(RoutePaths.MISSIONS)); // TODO Redirect to proper page once merged with missions
            }
        } else {
            Toast.error('genericError');
        }
    },

    postPersonalInformationWorker: function* ({payload}) {
        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
        let personalDocumentFlow = true; // Needed to stop flow if documents api crashes


        if (payload?.subscribedToHiwayInsurance === false) {
            personalDocumentFlow = yield call(
                flow.postPersonalDocumentsFlow,
                {
                    userId: loggedInUser.id,
                    documents: {personalInsuranceDocument: payload?.personalInsuranceDocument},
                },
            );
        }

        if (personalDocumentFlow) {
            const onboarding = yield call(
                flow.postPersonalInformationFlow,
                {userId: loggedInUser.id, information: payload},
            );

            if (onboarding) {
                yield put(AnimationActions.setIsAnimationActive(false));
                yield put(AnimationActions.storeNextDispatch(
                    UmbrellaStepperOnboardingActions.goToPersonalDocuments(),
                ));
            }
        }
    },

    postPersonalDocumentsWorker: function* ({payload}) {
        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

        const onboarding = yield call(flow.postPersonalDocumentsFlow, {userId: loggedInUser.id, documents: payload});

        if (onboarding) {
            yield put(AnimationActions.setIsAnimationActive(false));
            yield put(AnimationActions.storeNextDispatch(
                UmbrellaStepperOnboardingActions.goToFinalization(),
            ));
        }
    },

    putSignCGVSDocumentWorker: function* () {
        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

        const terms = yield call(flow.getTermsFlow);

        const onboarding = yield call(flow.putSignLegalDocumentFlow, {
            userId: loggedInUser?.id,
            termId: terms[TERM_DOCUMENTS.CGVS].id,
        });

        if (onboarding) {
            yield put(AnimationActions.setIsAnimationActive(false));
            yield put(AnimationActions.storeNextDispatch(
                UmbrellaStepperOnboardingActions.goToADPSigning(),
            ));
        }
    },

    putSignADDocumentWorker: function* () {
        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

        const terms = yield call(flow.getTermsFlow);

        const onboarding = yield call(flow.putSignLegalDocumentFlow, {
            userId: loggedInUser?.id,
            termId: terms[TERM_DOCUMENTS.ADP].id,
        });

        if (onboarding) {
            yield put(AnimationActions.setIsAnimationActive(false));
            yield put(AnimationActions.storeNextDispatch(
                UmbrellaStepperOnboardingActions.completedSigning(),
            ));
        }
    },
};

export const watchMainOnboardingSaga = function* () {
    yield all([
        takeLatest(MainOnboardingActionTypes.GET_ONBOARDING_DATA, workers.getOnboardingWorker),
        takeLatest(MainOnboardingActionTypes.PUT_SIGN_CGVS, workers.putSignCGVSDocumentWorker),
        takeLatest(MainOnboardingActionTypes.PUT_SIGN_AD, workers.putSignADDocumentWorker),
        takeLatest(MainOnboardingActionTypes.POST_PERSONAL_INFORMATION, workers.postPersonalInformationWorker),
        takeLatest(MainOnboardingActionTypes.POST_PERSONAL_DOCUMENTS, workers.postPersonalDocumentsWorker),
        takeLatest(MainOnboardingActionTypes.POST_LAUNCH_UMBRELLA_ONBOARDING, workers.postLaunchOnboardingWorker),
    ]);

    yield call(watchUmbrellaStepperSaga);
};

export const MainOnboardingSaga = {
    getOnboardingFlow: flow.getOnboardingFlow,
    getTerms: flow.getTermsFlow,
};
