export const UmbrellaUsersListActionTypes = {
    FETCH_USERS_LIST: 'FETCH_USERS_LIST',
    STORE_USERS_LIST: 'STORE_USERS_LIST',
};

const fetchUsersList = payload => ({type: UmbrellaUsersListActionTypes.FETCH_USERS_LIST, payload});
const storeUsersList = payload => ({type: UmbrellaUsersListActionTypes.STORE_USERS_LIST, payload});

export const UmbrellaUsersListActions = {
    fetchUsersList,
    storeUsersList,
};
