import {Box, Button, Link, Paper, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useTranslation} from 'react-i18next';
import ExcelIcon from '../../../../components/icons/ExcelIcon';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {RECRUTERS_LIST_FILE_LINK} from '../../utls/constants';

export const RecrutersLinkCard = () => {
    const {t} = useTranslation(LocalesConstants.Opportunity);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Paper
            sx={{
                borderRadius: 4,
                px: isMobileSize ? 2 : 4,
                py: isMobileSize ? 2 : 3,
                mb: 2,
                color: 'text_v2.secondary',
                display: 'flex',
                flexDirection: isMobileSize ? 'column' : 'row',
                alignItems: 'center',
                gap: isMobileSize ? 2 : 8,
            }}
            elevation={1}
        >
            <Box>
                <Typography
                    variant="h5"
                    sx={{
                        mb: isMobileSize ? 1 : 2,
                        alignSelf: 'flex-start',
                    }}
                >
                    {t('recruters.title')}
                </Typography>

                <Typography sx={{textAlign: isMobileSize ? 'center' : 'left'}}>
                    {t('recruters.text')}
                </Typography>
            </Box>

            <Link
                sx={{
                    'textDecoration': 'none',
                    'width': !isMobileSize ? 'auto' : '100%',
                    '&:hover': {
                        textDecoration: 'none',
                    },
                }}
                href={RECRUTERS_LIST_FILE_LINK}
                target="_blank"
            >
                <Button
                    variant="outlined"
                    color="success"
                    startIcon={<ExcelIcon />}
                    sx={{
                        color: 'success_v2.main',
                        borderColor: 'success_v2.main',
                        width: !isMobileSize ? '190px' : '100%',
                        px: 2,
                        py: 0.75,
                    }}
                    fullWidth={isMobileSize}
                >
                    {t('recruters.button')}
                </Button>
            </Link>
        </Paper>
    );
};
