import {all, call, fork, put, select, takeEvery, takeLatest} from 'redux-saga/effects';

import {watchAssignFreelancersFormSagas} from './assign-freelancers-form/assign-freelancers-form.sagas';
import {getAssignedFreelancers} from './assigned-freelancers/assigned-freelancers.actions';
import {watchAssignedFreelancersSagas} from './assigned-freelancers/assigned-freelancers.sagas';
import {getAvailableFreelancers} from './available-freelancers/available-freelancers.actions';
import {watchAvailableFreelancersSaga} from './available-freelancers/available-freelancers.sagas';
import {getCoachingInformationForm} from './coaching-information/coaching-information.actions';
import {
    watchCoachingInformationSagas,
} from './coaching-information/coaching-information.sagas';
import {UserActionTypes} from './user.action-type';
import {setIsLoadingUser} from './user.actions';
import * as actions from './user.actions';
import {selectCoachAccounts, selectLoggedInUser, selectUser} from './user.selectors';
import {getConfig} from '../../../config/get-config';
import {COMPANY_TYPES} from '../../../consts/company-constants';
import {push} from '../../../lib/router/connected-router-saga';
import {resolveRoute} from '../../../lib/router/resolveRoute';
import {ROUTE_PATHS} from '../../../lib/router/route-paths';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import {USER_ROLES, isUserFn, isUserUmbrellaFn} from '../../../utils/user-roles';
import {selectCurrentCognitoUser} from '../../auth/store/auth.selectors';
import {updateCompaniesFlow} from '../../backoffice/store/backoffice.saga';
import {getPortageUserLoaderSaga} from '../../company-profile/store/company-profile.saga';
import {getCoaches} from '../../company/store/company-list/company-list.actions';
import {loadFreelancerCompanies} from '../../freelancer/modules/companies/store/companies.saga';
import {getContractData} from '../../freelancer/store/contract/contract.actions';
import {getFreelancerAccount} from '../../freelancer/store/freelancer.actions';
import {OnboardingApi} from '../../onboarding/api/onboarding.api';
import {SidebarSelectors} from '../../sidebar/store/sidebar.selector';
import {UiActions} from '../../ui/store/ui.action';
import {ModalsKeys} from '../../ui/utils/constants';
import {
    activateUserRequest,
    deactivateUserRequest,
    getLoggedInUserRequest,
    getUserRequest,
    reinviteUserRequest,
    updateCanSetupCompanyRequest,
    updateCanSetupHoldingCompanyRequest,
    updateLoggedInUserRequest,
    updateUserRequest,
} from '../../user-list/api/user.api';
import * as userListActions from '../../user-list/store/userList.actions';
import {watchUserListSagas} from '../../user-list/store/userList.sagas';
import {UserApi} from '../api/user.api';
import {createUserFormSaga} from '../modules/create-user-form';
import {LoggedInUserActions, LoggedInUserSelectors, loggedInUserSaga} from '../modules/logged-in-user';
import {USER_TABS} from '../utils/constants';

export const getUserSaga = function* ({payload: userId}) {
    try {
        yield put(actions.setIsLoadingUser(true));

        const user = yield call(getUserRequest, userId);

        yield put(actions.storeUser(user));
    } catch (error) {
        // TODO:LOW Better error handling.
        // eslint-disable-next-line no-console
        console.error(error);

        if (error?.response?.status === 403) {
            // TODO:HIGH Redirect to 403, not to dashboard...
            yield put(push(getConfig().ROUTE_PATHS.DASHBOARD));

            return;
        }

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingUser(false));
    }
};

const activateUserSaga = function* ({payload: userId}) {
    try {
        yield put(actions.setIsUpdatingUserActiveStatus(true));

        yield call(activateUserRequest, userId);

        // TODO:LOW Remove this once API has the functionality to return the updated user.
        const user = yield select(selectUser);

        yield put(actions.storeUser({
            ...user,
            active: true,
        }));
    } catch (error) {
        // TODO:LOW Better error handling.
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsUpdatingUserActiveStatus(false));
    }
};

const deactivateUserSaga = function* ({payload: userId}) {
    try {
        yield put(actions.setIsUpdatingUserActiveStatus(true));

        yield call(deactivateUserRequest, userId);

        // TODO:LOW Remove this once API has the functionality to return the updated user.
        const user = yield select(selectUser);

        yield put(actions.storeUser({
            ...user,
            active: false,
        }));
    } catch (error) {
        // TODO:LOW Better error handling.
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsUpdatingUserActiveStatus(false));
    }
};

const reinviteUserSaga = function* ({payload: userId}) {
    try {
        yield put(actions.setIsUpdatingUser(true));

        yield call(reinviteUserRequest, userId);

        Toast.success('userReinvited');
    } catch (error) {
        if (error?.response?.data?.error_type === 'HTTP_CONFLICT_ERROR') {
            Toast.error('lessThanTwoMinutesError');

            return;
        }

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsUpdatingUser(false));
    }
};

const updateUserSaga = function* ({payload}) {
    try {
        yield put(actions.setIsUpdatingUser(true));

        const {userId, user, propagateAddressChange, callback} = payload;

        yield call(updateUserRequest, userId, user, propagateAddressChange);

        // TODO:LOW Remove this once API has the functionality to return the updated user.
        const oldUser = yield select(selectUser);

        yield put(actions.storeUser({
            ...oldUser,
            ...user,
        }));

        callback?.();

        if (!isUserUmbrellaFn(oldUser)) {
            yield put(push(resolveRoute(ROUTE_PATHS.USER, {
                userId: userId,
                tab: USER_TABS.ACCOUNT.value,
            })));
        }
    } catch (error) {
        // TODO:LOW Better error handling.
        // eslint-disable-next-line no-console
        console.error(error);

        if (error?.response?.data?.error_type === 'HTTP_CONFLICT_ERROR') {
            Toast.error('userExists');

            return;
        }

        if (error?.response?.data?.error) {
            Toast.error(error?.response?.data?.error);

            return;
        }

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsUpdatingUser(false));
    }
};

const updateUserPersonalDataSaga = function* ({payload}) {
    try {
        yield put(actions.setIsUpdatingUser(true));

        const {userId, data, callback} = payload;

        yield call(OnboardingApi.postPersonalInformation, {
            userId,
            information: data,
        });

        // TODO:LOW Remove this once API has the functionality to return the updated user personalData.
        yield call(getPortageUserLoaderSaga, {payload: {params: {userId}}});

        callback?.();
    } catch (e) {
        Debug.error('updatePersonalInformationSaga', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(actions.setIsUpdatingUser(false));
    }
};

const enableCanSetupCompanySaga = function* ({payload}) {
    try {
        yield put(actions.setAdminMustEnterDataBeforeSetupCompany(false));
        yield put(actions.setIsUpdatingCanSetupCompany(true));

        const {data, companyType} = payload;
        const {
            activityStartDate,
            firstBankTrxDate,
            userId,
            legalStatus,
            taxSystem,
            useCustomCompanyAddress,
            registrationType,
        } = data;

        const requestFunc = companyType === COMPANY_TYPES.HOLDING
            ? updateCanSetupHoldingCompanyRequest
            : updateCanSetupCompanyRequest;

        yield call(requestFunc,
            userId,
            activityStartDate,
            firstBankTrxDate,
            legalStatus,
            taxSystem,
            useCustomCompanyAddress,
            registrationType);

        Toast.success('userInvitationSent');

        yield put(UiActions.setActiveModal(ModalsKeys.DOCUMENT_SIGNING_STEP, false));
        yield call(updateCompaniesFlow);
    } catch (error) {
        // TODO:LOW Better error handling.
        // eslint-disable-next-line no-console
        console.error(error);
        if (error?.response?.data?.error_type === 'HTTP_CONFLICT_ERROR') {
            yield put(actions.setAdminMustEnterDataBeforeSetupCompany(true));
            return;
        }
        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsUpdatingCanSetupCompany(false));
    }
};

export const getLoggedInUserSaga = function* () {
    try {
        yield put(actions.setIsLoadingLoggedInUser(true));

        const data = yield call(getLoggedInUserRequest);

        yield put(actions.storeLoggedInUser(data?.users));

        yield put(LoggedInUserActions.storeLoggedInUserAccountForDashboard(data?.loggedInUsers));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingLoggedInUser(false));
    }
};

const updateLoggedInUserSaga = function* ({payload: user}) {
    try {
        yield put(actions.setIsUpdatingLoggedInUser(true));

        yield call(updateLoggedInUserRequest, user);

        const oldUser = yield select(selectLoggedInUser);

        yield put(actions.storeLoggedInUser({
            ...oldUser,
            ...user,
        }));

        yield put(push(getConfig().ROUTE_PATHS.MY_PROFILE));

        Toast.success('profileUpdated');
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsUpdatingLoggedInUser(false));
    }
};

export const usersLoaderSagas = function* usersLoaderSagas() {
    const coaches = yield select(selectCoachAccounts);
    if (coaches?.length === 0) {
        yield put(getCoaches());
    }

    const sidebarText = yield select(SidebarSelectors.selectSearchText);

    // If it is from global search we don't to run this
    if (!sidebarText) {
        yield put(userListActions.getUsers());
    }
};

export const userLoaderSaga = function* (action) {
    const {userId, tab} = action.payload.params;

    if (!userId) {
        return;
    }

    const currentCognitoUser = yield select(selectCurrentCognitoUser);

    let user = yield select(selectUser);

    if (!user || user.id !== userId) {
        yield call(getUserSaga, {
            payload: userId,
        });

        user = yield select(selectUser);
    }

    if (userId && isUserFn(user)) {
        yield put(getFreelancerAccount(userId));

        // yield put(getFreelancerCompanies(userId));
        yield call(loadFreelancerCompanies, {freelancerId: userId});
    }

    if (!tab || tab === 'account') {
        if (isUserFn(user)) {
            yield put(getContractData(userId));
        }

        return;
    }

    if (tab === 'managed-freelancers') {
        if (currentCognitoUser.role !== USER_ROLES.ADMINISTRATOR) {
            yield put(push(resolveRoute(ROUTE_PATHS.USER, {
                userId: userId,
            })));

            return;
        }

        yield put(getAssignedFreelancers(userId));
        yield put(getAvailableFreelancers(userId));
    }

    if (tab === 'coaching') {
        yield put(getCoachingInformationForm(userId));
    }

    yield put(setIsLoadingUser(false));
};

export const myProfileLoaderSaga = function* () {
    const userAccount = yield select(selectLoggedInUser);

    if (userAccount) {
        return;
    }

    yield put(actions.getLoggedInUser());
};

const submitAdminUpdatesUsersPersonalAddressWorker = function* ({payload}) {
    try {
        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
        const isAdmin = loggedInUser.role === USER_ROLES.ADMINISTRATOR;
        if (!isAdmin) {
            Toast.error('genericError');
            return;
        }

        const {userId, contactInfo} = payload;
        const user = yield select(selectUser);
        yield call(UserApi.putContactInfo, {userId, data: contactInfo});
        yield put(actions.storeUser({
            ...user,
            contactInfo,
        }));
    } catch (e) {
        Debug.error('user', 'Error: ', {e});
        Toast.error('genericError');
    }
};

export const watchUserSagas = function* watchUserSagas() {
    yield all([
        fork(watchUserListSagas),
        fork(watchAssignedFreelancersSagas),
        fork(watchAvailableFreelancersSaga),
        fork(watchAssignFreelancersFormSagas),
        // fork(watchChangePasswordFormSagas),
        fork(watchCoachingInformationSagas),
        takeLatest(actions.GET_USER, getUserSaga),
        fork(createUserFormSaga),
        fork(loggedInUserSaga),
        takeEvery(UserActionTypes.ADMIN_UPDATES_USERS_PERSONAL_ADDRESS, submitAdminUpdatesUsersPersonalAddressWorker),
        takeLatest(actions.ACTIVATE_USER, activateUserSaga),
        takeLatest(actions.DEACTIVATE_USER, deactivateUserSaga),
        takeLatest(actions.REINVITE_USER, reinviteUserSaga),
        takeLatest(actions.UPDATE_USER, updateUserSaga),
        takeLatest(actions.UPDATE_USER_PERSONAL_DATA, updateUserPersonalDataSaga),
        takeLatest(actions.ENABLE_CAN_SETUP_COMPANY, enableCanSetupCompanySaga),
        takeLatest(actions.GET_LOGGED_IN_USER, getLoggedInUserSaga),
        takeLatest(actions.UPDATE_LOGGED_IN_USER, updateLoggedInUserSaga),
    ]);
};
