import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Badge, Box, ListItem, Tooltip} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {ItemWithDispatchAction} from './ItemWithDispatchAction';
import {ItemWithLink} from './ItemWithLink';
import {selectRouterLocation} from '../../../../lib/router/connected-router-saga';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {SidebarItemActionType} from '../../utils/constants';

const ItemContent = ({listItem}) => {
    const {t} = useTranslation(LocalesConstants.Sidebar);

    const location = useSelector(selectRouterLocation);

    const isActive = listItem.path === '/'
        ? location.pathname === '/'
        : location.pathname.startsWith(listItem.path);

    return (
        <Tooltip title={t(listItem.title)} placement="right">
            <ListItem
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '64px',
                    height: '36px',
                    p: 1,
                    mt: 1,
                    borderRadius: 'sm',
                    ...(isActive ? {
                        backgroundColor: 'neutral.plainColor',
                    } : {
                        '&:hover': {
                            backgroundColor: 'background.level2',
                        },
                    }),
                }}
            >
                {listItem.icon && (
                    <Box
                        color={isActive ? 'primary.solidColor' : 'text.icon'}
                        fontSize="md"
                    >
                        <FontAwesomeIcon icon={listItem.icon} fixedWidth />
                    </Box>
                )}

                {listItem.hasBadge && (
                    <Badge
                        color="primary"
                        variant="solid"
                        size="sm"
                        sx={{
                            position: 'absolute',
                            right: '8px',
                        }}
                    />
                )}
            </ListItem>
        </Tooltip>
    );
};

ItemContent.propTypes = {
    listItem: PropTypes.object.isRequired,
};

export const CollapsedSingleItem = ({listItem}) => {
    switch (listItem?.actionType) {
        case SidebarItemActionType.Link:
            return (
                <ItemWithLink to={listItem.path}>
                    <ItemContent listItem={listItem} />
                </ItemWithLink>
            );
        case SidebarItemActionType.DispatchAction:
            return (
                <ItemWithDispatchAction action={listItem.action}>
                    <ItemContent listItem={listItem} />
                </ItemWithDispatchAction>
            );
        default:
            return null;
    }
};

CollapsedSingleItem.propTypes = {
    listItem: PropTypes.array.isRequired,
};
