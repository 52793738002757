import CheckIcon from '@mui/icons-material/Check';
import {Box, Button, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import InsuranceMidSectionCardWrapper from './InsuranceMidSectionCardWrapper';
import {LocalesConstants} from '../../../utils/locales-constants';

const InsuranceMidSection = ({handleOpenSubscribeModal}) => {
    const {t} = useTranslation(LocalesConstants.Insurance);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Box sx={{
            'display': 'flex',
            'padding': 5,
            'flexDirection': 'column',
            'alignItems': 'center',
            'alignSelf': 'stretch',
            'gap': '28px',
            'borderRadius': 4,
            'backgroundColor': '#FFFFFF',
            'boxShadow': '0px 2px 1px -1px rgba(11, 35, 51, 0.05), 0px 1px 1px 0px rgba(11, 35, 51, 0.05), 0px 1px 3px 0px rgba(11, 35, 51, 0.07)',
            '@media (max-width: 959.95px)': {
                padding: 3,
                gap: 3,
                borderRadius: 6,
            },
        }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 3,
                alignSelf: 'stretch',
            }}
            >
                <Typography sx={{
                    'textAlign': 'center',
                    'fontSize': theme => theme.typography.pxToRem(34),
                    'fontWeight': 700,
                    'whiteSpace': 'pre-line',
                    '@media (max-width: 959.95px)': {
                        textAlign: 'left',
                        fontSize: theme => theme.typography.pxToRem(21),
                    },
                }}
                >
                    {t('midSection.title')}
                </Typography>
                <Typography sx={{
                    'whiteSpace': 'pre-line',
                    'textAlign': 'center',
                    '@media (max-width: 959.95px)': {
                        fontSize: theme => theme.typography.pxToRem(14),
                    },
                }}
                >
                    {t('midSection.description')}
                </Typography>
            </Box>
            {isMobileSize ? (
                <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    startIcon={<CheckIcon />}
                    onClick={handleOpenSubscribeModal}
                    sx={{
                        width: isMobileSize ? '100%' : 'auto',
                        height: '36px',
                    }}
                >
                    {t('banner.cta')}
                </Button>
            ) : <InsuranceMidSectionCardWrapper />}
        </Box>
    );
};

InsuranceMidSection.propTypes = {
    handleOpenSubscribeModal: PropTypes.func.isRequired,
};

export default InsuranceMidSection;
