import {combineReducers} from 'redux';

import * as actions from './personal-information.actions';

const initialState = {
    data: null,
};

export const dataReducer = (state = initialState.data, action) => {
    if (action.type === actions.STORE_PERSONAL_INFORMATION_DATA) {
        return action.payload;
    }

    return state;
};

export const personalInformationReducer = combineReducers({
    data: dataReducer,
});
