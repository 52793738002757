import {
    faCheck,
    faClose,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    Button,
    RadioGroup,
    Typography,
} from '@mui/joy';

import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {AccountItem} from '../../../../../components/joy-ui/forms/AccountItem';
import {ModalWrapper} from '../../../../../components/modal-wrapper/ModalWrapper';
import {LocalesConstants} from '../../../../../utils/locales-constants';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';
import {ModalsKeys} from '../../../../ui/utils/constants';
import {BankSelector} from '../../../store/bank.selector';
import {BridgeActions} from '../store/bridge-api.action';

const modalKey = ModalsKeys.PICK_INTEGRATION_ACCOUNT_MODAL;
const PickIntegrationAccountModal = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation(LocalesConstants.Bank);

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.ONBOARDING),
    );

    const integrations = useSelector(BankSelector.selectIntegrations);
    const integration = integrations.find(integration => integration?.integrationAccounts?.length > 0
        && integration?.bankAccountHolders?.length === 0);

    const hasDefaultValue = integration?.integrationAccounts.find(integration => integration.isSuggested);

    const [value, setValue] = React.useState(hasDefaultValue?.iban);
    const handleChange = event => {
        setValue(event.target.value);
    };

    return (
        <ModalWrapper
            isV3
            modalKey={modalKey}
            title={(
                <Typography level="h1">
                    {t('bridgeAPI.pickIntegrationAccountModal.title')}
                </Typography>
            )}
            buttons={({handleClose}) => (
                <>
                    <Button
                        fullWidth
                        startDecorator={(
                            <FontAwesomeIcon icon={faClose} />
                        )}
                        onClick={() => {
                            handleClose();
                        }}
                        variant="outlined"
                        color="neutral"
                        size="lg"
                    >
                        {t('bridgeAPI.pickIntegrationAccountModal.cancel')}
                    </Button>
                    <Button
                        disabled={!value}
                        fullWidth
                        loading={isLoading}
                        color="primary"
                        size="lg"
                        variant="solid"
                        startDecorator={(
                            <FontAwesomeIcon icon={faCheck} />
                        )}
                        loadingPosition="start"
                        onClick={() => {
                            dispatch(BridgeActions.onboardAccount(
                                {
                                    integrationId: integration.id,
                                    iban: value,
                                },
                            ));
                        }}
                    >
                        {t('bridgeAPI.pickIntegrationAccountModal.confirm')}
                    </Button>
                </>
            )}
            hasShowClose={false}
        >
            <RadioGroup
                defaultValue={value}
                name="account-integration"
                onChange={handleChange}
                sx={{
                    gap: {desktop: 3, mobile: 2},
                    m: 0,
                }}
            >
                {integration?.integrationAccounts.map(({iban, name, balance}) => (
                    <AccountItem
                        key={iban}
                        value={iban}
                        accountNumber={iban}
                        name={name}
                        balance={balance}
                        isSelected={value === iban}
                    />
                ))}
            </RadioGroup>
        </ModalWrapper>
    );
};

export default PickIntegrationAccountModal;
