import {combineReducers} from 'redux';

import * as actions from './current-document.actions';
import {createReducer} from '../../../../../../app/utils/store/create-reducer';

export const currentDocumentReducer = combineReducers({
    isLoading: createReducer(true, actions.SET_IS_LOADING_CURRENT_DOCUMENT, true),
    documentId: createReducer(null, actions.SET_CURRENT_COMPANY_DOCUMENT, true),
    documentUrl: createReducer(null, actions.STORE_CURRENT_DOCUMENT_URL, true),
    isSigning: createReducer(false, actions.SET_IS_SIGNING_CURRENT_DOCUMENT, true),
    isResigning: createReducer(false, actions.SET_IS_RESIGNING_DOCUMENT, true),
});
