import {Box, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {motion} from 'framer-motion';
import React from 'react';
import {useTranslation} from 'react-i18next';
import confettiLarge from '../../../../assets/svg/training-confetti-large.svg';
import confettiSmall from '../../../../assets/svg/training-confetti-small.svg';
import doubleCheckmark from '../../../../assets/svg/training-double-checkmark.svg';
import {theme} from '../../../../lib/theme';
import {LocalesConstants} from '../../../../utils/locales-constants';

const FinalizationAnimation = () => {
    const {t: tTraining} = useTranslation(LocalesConstants.Training);
    const isMobileSize = useMediaQuery(`(max-width:599px)`);
    const color1 = 'rgba(89,91,93, 0.05)';
    const color2 = 'rgb(226,235,226)';
    const containerWidth = isMobileSize ? 320 : 444;
    const containerHeight = isMobileSize ? 128 : 250;
    const circleDiameter = isMobileSize ? 128 : 224;
    const checkmarkWidth = isMobileSize ? 128 : 224;
    const checkmarkHeight = isMobileSize ? 128 : 224;
    const confettiWidth = isMobileSize ? 264 : 444;
    const confettiHeight = isMobileSize ? 271 : 300;
    const confettiMarginTop = isMobileSize ? 50 : -50;

    return (
        <>
            <Box
                component="div"
                sx={{
                    width: containerWidth,
                    height: containerHeight,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <motion.div
                    style={{
                        position: 'absolute',
                        width: circleDiameter,
                        height: circleDiameter,
                        backgroundColor: color1,
                        zIndex: 2,
                    }}
                    animate={{
                        scale: [0, 1.2, 1, 1, 1, 1, 1],
                        rotate: [0, 0, 0, 0, 360, 0],
                        borderRadius: ['10%', '10%', '10%', '10%', '10%', '50%', '50%'],
                        backgroundColor: [color1, color1, color1, color1, color2, color2],
                    }}
                    transition={{duration: 2.35}}
                />
                <motion.div
                    style={{
                        position: 'absolute',
                        width: confettiWidth,
                        height: confettiHeight,
                        zIndex: 1,
                        marginTop: confettiMarginTop,
                    }}
                    animate={{scale: [0, 0, 0, 0, 0, 0, 0, 0, 1]}}
                    transition={{duration: 2.2}}
                >
                    <img src={isMobileSize ? confettiSmall : confettiLarge} width="100%" alt="confetti" />
                </motion.div>
                <motion.div
                    style={{
                        zIndex: 3,
                        position: 'absolute',
                        width: checkmarkWidth,
                        height: checkmarkHeight,
                    }}
                    animate={{scale: [0, 0, 0.9, 0.7, 0.7, 1, 0.57]}}
                    transition={{duration: 2}}
                >
                    <img src={doubleCheckmark} height="100%" alt="doubleCheckmark" />
                </motion.div>
            </Box>
            <Box
                component="div"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 0,
                    pt: {xs: 2, sm: 0},
                    gap: 0.5,
                    textAlign: 'center',
                }}
            >
                <motion.div
                    animate={{
                        scale: [0, 0, 0, 1.3, 1.3, 1.3, 1.3, 1.3, 1],
                        y: [30, 30, 30, 30, 30, 30, 30, 30, 0],
                    }}
                    transition={{duration: 2}}
                >
                    <Typography
                        sx={{
                            fontWeight: {xs: '600', sm: '700'},
                            fontSize: {
                                xs: theme.typography.pxToRem(21),
                                sm: theme.typography.pxToRem(34),
                            },
                        }}
                        color="text_v2.secondary"
                    >
                        {tTraining('finalization.finishedScreenLine1')}
                    </Typography>
                </motion.div>
                <motion.div
                    animate={{
                        scale: [0, 0, 0, 0, 0, 0, 0, 1],
                    }}
                    transition={{duration: 2}}
                >
                    <Typography
                        sx={{
                            fontSize: {
                                xs: theme.typography.pxToRem(14),
                                sm: theme.typography.pxToRem(16),
                            },
                        }}
                        color="text_v2.secondary"
                    >
                        {tTraining('finalization.finishedScreenLine2')}
                    </Typography>
                </motion.div>
            </Box>
        </>
    );
};

export default FinalizationAnimation;
