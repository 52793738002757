export const selectUser = state => state.user.singleUser;

export const selectIsLoadingUser = state => state.user.isLoadingUser;

export const selectIsUpdatingUser = state => state.user.isUpdatingUser;

export const selectIsUpdatingCanSetupCompany = state => state.user.isUpdatingCanSetupCompany;

export const selectAdminMustEnterDataBeforeSetupCompany = state => state.user.adminMustEnterDataBeforeSetupCompany;

export const selectIsUpdatingUserActiveStatus = state => state.user.isUpdatingUserActiveStatus;

export const selectLoggedInUser = state => state.user.loggedInUser;

export const selectIsLoadingLoggedInUser = state => state.user.isLoadingLoggedInUser;

export const selectIsUpdatingLoggedInUser = state => state.user.isUpdatingLoggedInUser;

export const selectCoachAccounts = state => state.user.coachAccounts;
