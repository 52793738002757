import Visibility from '@mui/icons-material/Visibility';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import DataTableRowActions from '../../../components/ui-kit/DataTableRowActions/DataTableRowActions';
import {resolveRoute} from '../../../lib/router/resolveRoute';
import {ROUTE_PATHS} from '../../../lib/router/route-paths';
import {LocalesConstants} from '../../../utils/locales-constants';

const getMainActionsConfig = (translate, rowData) => ([
    {
        title: translate('usersDataTable.actionButtonTooltips.viewProfile'),
        path: resolveRoute(ROUTE_PATHS.USER, {userId: rowData.id}),
        icon: <Visibility />,
    },
]);

const UserRowActions = ({rowData}) => {
    const {t} = useTranslation(LocalesConstants.Users);

    const config = useMemo(() => {
        return getMainActionsConfig(t, rowData);
    }, [rowData, t]);

    return <DataTableRowActions config={config} />;
};

UserRowActions.propTypes = {
    rowData: PropTypes.shape({
        id: PropTypes.string,
    }),
};

UserRowActions.defaultProps = {
    rowData: {},
};

export default React.memo(UserRowActions);
