import {faCopy} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, IconButton, Stack, Typography} from '@mui/joy';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import useMediaQuery from '../../../../../components/joy-ui/mui-legacy/useMediaQuery';
import {Toast} from '../../../../../lib/toast';
import {copyToClipboard} from '../../../../../utils/copy-to-clipboard';
import {LocalesConstants} from '../../../../../utils/locales-constants';
import {
    createEnterpirseAddress,
} from '../../../../company-profile/components/EnterpriseInformationForm/util';
import {FreelancerSelectors} from '../../../../freelancer';
import {CompaniesSelectors} from '../../../../freelancer/modules/companies';
import {BankSelector} from '../../../store/bank.selector';
import {getIntegrationInfo} from '../../bridge-api/utils/bridge-util';
import {getActiveHiwayIntegration} from '../utils/ribAndDocuments.util';

const DetailsSection = () => {
    const {t} = useTranslation(LocalesConstants.BankRibAndDocuments);
    const integrations = useSelector(BankSelector.selectIntegrations);
    const freelancer = useSelector(FreelancerSelectors.selectAccount);
    const company = useSelector(CompaniesSelectors.createCompanyByIdSelector(freelancer?.defaultCompanyId));
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const hiwayIntegration = getActiveHiwayIntegration(integrations);
    const {account} = getIntegrationInfo(hiwayIntegration);
    const {iban, bic} = account || {};

    const data = [
        {key: t('detailsSection.IBAN'), value: iban},
        {key: t('detailsSection.BIC'), value: bic},
        {key: t('detailsSection.companyName'), value: company?.name},
        {key: t('detailsSection.companyAddress'), value: createEnterpirseAddress(company?.enterpriseInformation)},
    ];

    const onCopyAllClick = () => {
        const transformedData = data.map(item => `${item.key} : ${item.value}`).join('\n');
        copyToClipboard(transformedData);
        Toast.success('copiedToClipboard');
    };

    return (
        <Stack
            sx={{
                overflow: 'hidden',
                flex: '1',
                borderRadius: 'lg',
                borderColor: 'neutral.outlinedBorder',
                borderWidth: '1px',
                borderStyle: 'solid',
                backgroundColor: 'background.body',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: {
                        mobile: 'column',
                        desktop: 'row',
                    },
                    justifyContent: 'space-between',
                    alignItems: {
                        mobile: 'flex-start',
                        desktop: 'center',
                    },
                    gap: 2,
                    pt: 2,
                    pr: 2,
                    pb: 2,
                    pl: 3,
                }}
            >
                <Typography
                    level={isMobileSize ? 'h4' : 'h3'}
                    textColor="text.secondary"
                >
                    {t('detailsSection.title')}
                </Typography>
                <Button
                    variant="outlined"
                    color="neutral"
                    size="sm"
                    startDecorator={
                        <FontAwesomeIcon icon={faCopy} />
                    }
                    onClick={onCopyAllClick}
                >
                    {t('detailsSection.copyAll')}
                </Button>
            </Box>
            <Stack
                sx={{
                    '& > :not(:last-child)': {
                        borderBottom: 1,
                        borderColor: 'neutral.outlinedBorder',
                    },
                    'color': 'text_v2.primary',
                    'flex': 1,
                    'height': '100%',
                }}
            >
                {data.map(item => (
                    <Box
                        key={item.key}
                        sx={{
                            [`&:hover`]: {
                                backgroundColor: 'background.surface',
                                ...(!isMobileSize && {
                                    '& .CopyIcon': {
                                        opacity: 1,
                                    },
                                }),
                                cursor: 'pointer',
                            },
                            transition: 'all 0.2s ease-in-out',
                            py: 2,
                            px: 3,
                            alignContent: 'center',
                            alignItems: 'center',
                            flex: 1,
                            height: '100%',
                            display: 'flex',
                        }}
                    >
                        <Box
                            sx={{
                                alignItems: 'center',
                                width: '100%',
                                display: 'grid',
                                gridTemplateColumns: '0.5fr 1fr',
                                ...(isMobileSize && {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }),
                            }}
                        >
                            <Typography
                                level="title-sm"
                                textColor="text.tertiary"
                            >
                                {item.key}
                            </Typography>
                            <Typography
                                sx={{py: 0.5}}
                                level={isMobileSize ? 'body-md' : 'body-lg'}
                                textColor="text.secondary"
                            >
                                {item.value}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flex: 1,
                                justifyContent: 'right',
                            }}
                        >
                            <IconButton
                                aria-label="copy"
                                className="CopyIcon"
                                sx={{
                                    color: 'neutral.plainColor',
                                    transition: 'all 0.2s ease-in-out',
                                    p: 0.5,
                                    ...(!isMobileSize && {
                                        opacity: 0,
                                    }),
                                }}
                                size="lg"
                                onClick={() => {
                                    copyToClipboard(item.value);
                                    Toast.success('copiedToClipboard');
                                }}
                            >
                                <FontAwesomeIcon icon={faCopy} />
                            </IconButton>
                        </Box>
                    </Box>
                ))}
            </Stack>
        </Stack>
    );
};

export default DetailsSection;
