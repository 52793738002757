import {faChartLine, faEuro, faRocket} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Stack, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {EurAmount} from '../../../../../../components/eur-amount/EurAmount';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {formatOrdinalNumber} from '../../../../../wealth-management/utils/helpers';
import {useSimulationData} from '../../utils/useSimulationData';

const RoadmapStep = ({icon, value, title, description}) => {
    return (
        <Stack direction="row" alignItems="center" sx={{borderRadius: 'md', gap: 1}}>
            <Box>
                <FontAwesomeIcon fixedWidth icon={icon} />
            </Box>
            <Typography level="title-sm" textColor="text.primary">
                {title}
            </Typography>
            {value && (
                <Typography level="title-md" textColor="text.secondary" fontStyle="normal">
                    {value}
                </Typography>
            )}
            <Typography level="body-xs" textColor="text.secondary" sx={{ml: -0.5}}>
                /{description}
            </Typography>
        </Stack>
    );
};

RoadmapStep.propTypes = {
    icon: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    value: PropTypes.node,
    description: PropTypes.string.isRequired,
};

RoadmapStep.defaultProps = {
    value: undefined,
};

export const MobileRoadmapSteps = () => {
    const {t, i18n} = useTranslation(LocalesConstants.RevenueSimulator);
    const simulation = useSimulationData();
    const months = simulation.simulationInput.professionalInfo.monthsToRestoreTravelSubvention || 0;
    const revenuePercent = simulation.simulationOutput.revenueProjectionPercentAsFreelancer;
    const {roadmapARCE} = simulation.simulationOutput.compensationRoadmap.graph;
    const lang = i18n.language;

    return (
        <Stack spacing={1} sx={{bgcolor: 'background.surface', p: 1}}>
            {months > 0 && (
                <RoadmapStep
                    description={t('roadmap.months1to4')}
                    title={`+${revenuePercent}% ${t('roadmap.income')}`}
                    icon={faChartLine}
                />
            )}
            <RoadmapStep
                description={t('roadmap.month', {month: formatOrdinalNumber(1 + months, lang)})}
                title={t('roadmap.companyCreation.mobile')}
                icon={faRocket}
            />
            <RoadmapStep
                description={t('roadmap.month', {month: formatOrdinalNumber(2 + months, lang)})}
                value={<EurAmount amount={roadmapARCE} />}
                title={`${t('roadmap.arce')} #1:`}
                icon={faEuro}
            />
            <RoadmapStep
                description={t('roadmap.month', {month: formatOrdinalNumber(8 + months, lang)})}
                value={<EurAmount amount={roadmapARCE} />}
                title={`${t('roadmap.arce')} #2:`}
                icon={faEuro}
            />
        </Stack>
    );
};
