import {Box} from '@mui/joy';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import AccountStatementsDataTableHeader from './datatable/AccountStatementsDataTableHeader';
import useMediaQuery from '../../../../../components/joy-ui/mui-legacy/useMediaQuery';
import DataTable from '../../../../../components/ui-kit/DataTable/DataTable';
import {LocalesConstants} from '../../../../../utils/locales-constants';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';
import {RibAndDocumentsSelector} from '../store/ribAndDocuments.selector';
import {getBankAccountStatementsDataTableConfig} from '../utils/bank-account-statement-datatable';
import {BANK_ACCOUNT_STATEMENTS_DATA_TABLE_ID, DEFAULT_ACCOUNT_STATEMENTS_ROWS_PER_PAGE} from '../utils/constants';

const BankAccountStatements = () => {
    const {t} = useTranslation(LocalesConstants.BankRibAndDocuments);
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(
            LoadingTypes.GET_BANK_ACCOUNT_STATEMENTS,
        ),
    );

    const columnConfig = useMemo(
        () => getBankAccountStatementsDataTableConfig(t, isMobileSize),
        [t, isMobileSize],
    );

    const renderHeader = useCallback(searchField => {
        return <AccountStatementsDataTableHeader searchField={searchField} />;
    }, []);

    return (
        <Box
            sx={{
                borderRadius: '16px',
                marginTop: '24px',
                overflow: 'hidden',
                border: '1px solid',
                borderColor: 'var(--joy-palette-neutral-outlinedBorder)',
                backgroundColor: 'background.body',
            }}
        >
            <DataTable
                tableId={BANK_ACCOUNT_STATEMENTS_DATA_TABLE_ID}
                hasSearch
                isSearchExpandedOnMobile
                // TODO: Sorting does not work if you set defaultSortByKey
                // defaultSortByKey={BANK_ACCOUNT_STATEMENTS_SORT_VALUES_ENUM.openingDate}
                // defaultSortDirection="desc"
                dataSelector={RibAndDocumentsSelector.createBankAccountStatementsSelector()}
                missingDataMessage={t('accountStatementsTable.noStatementsAvailable')}
                columnConfig={columnConfig}
                isLoading={isLoading !== false}
                hasPagination
                defaultRowsPerPage={DEFAULT_ACCOUNT_STATEMENTS_ROWS_PER_PAGE}
                customHeader={renderHeader}
                isV3
                hasPaper={false}
            />
        </Box>
    );
};

export default BankAccountStatements;
