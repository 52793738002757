import {combineReducers} from 'redux';

import * as actions from './sign-contract.actions';
import {createReducer} from '../../../../app/utils/store/create-reducer';

export const v1SignContractReducer = combineReducers({
    contractDocumentId: createReducer(null, actions.STORE_CONTRACT_DOCUMENT_ID),
    contractUrl: createReducer(null, actions.STORE_CONTRACT_URL),
    isLoading: createReducer(true, actions.SET_IS_LOADING_CONTRACT_URL),
});
