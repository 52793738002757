import {Helmet} from 'react-helmet';
import {useSelector} from 'react-redux';
import {useRoutes} from 'react-router-dom';
import AppContent from './components/app/AppContent';
import ErrorBoundary from './components/app/ErrorBoundary';
import InitialAnimation from './features/animations/components/InitialAnimation';
import {LoadingTypes, useLoadingState} from './features/loading';
import {NotificationsContainer} from './features/notifications/components/NotificationProvider';
import {LoggedInUserSelectors} from './features/user/modules/logged-in-user';
import {Routes} from './lib/router/routes';
import ScrollToTop from './utils/scroll-to-top';
import {UserRoles, isUserFn} from './utils/user-roles';
import './assets/fonts/avantt/avantt.css';

const RouteElements = () => {
    let routes;

    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const isMyAccountLoading = useLoadingState(LoadingTypes.MY_ACCOUNT);
    const isPublicUser = useSelector(LoggedInUserSelectors.isPublicUser);

    if (isPublicUser) {
        routes = [...Routes.TokenPublicRoutes];
    } else {
        routes = isMyAccountLoading ? [] : [
            ...Routes.PublicRoutes,
            ...(isUserFn(loggedInUser)
                ? [
                    ...Routes.FreelancerRoutes(),
                    ...Routes.UmbrellaRoutes(), // TODO Split umbrella routes for only umbrella users
                ] : []),
            ...(loggedInUser?.role === UserRoles.ADMINISTRATOR
                ? [
                    ...Routes.AdministratorRoutes,
                    ...Routes.DepositOfAccountsRoutes,
                ]
                : []
            ),
        ];
    }

    return useRoutes(routes);
};

export const App = () => {
    return (
        <>
            <Helmet>
                <title>HIWAY Platform</title>
            </Helmet>
            <ScrollToTop />
            <NotificationsContainer />
            <InitialAnimation />
            <ErrorBoundary>
                <AppContent>
                    <RouteElements />
                </AppContent>
            </ErrorBoundary>
        </>
    );
};
