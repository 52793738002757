import {faChartLine, faEuro, faRocket} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Stack, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {EurAmount} from '../../../../../../components/eur-amount/EurAmount';
import JoyUIThemeProvider from '../../../../../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {useIsMobileSize} from '../../../../../../hooks/use-is-mobile-size';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {flexCenter} from '../../../../../../utils/styles';
import {useSimulationData} from '../../utils/useSimulationData';

const RoadmapStep = ({icon, children}) => {
    const isMobileSize = useIsMobileSize();
    return (
        <Box
            sx={{
                px: isMobileSize ? 0 : 1,
                whiteSpace: 'nowrap',
                width: (1 / 13) * 100 + '%',
            }}
        >
            <Stack alignItems="center">
                <Box
                    sx={{
                        color: 'neutral.600',
                        fontSize: '16px',
                        width: '32px',
                        height: '32px',
                        borderRadius: '50%',
                        bgcolor: isMobileSize ? 'none' : 'background.body',
                        border: isMobileSize ? 'none' : '1px solid',
                        borderColor: 'neutral.outlinedBorder',
                        ...flexCenter,
                    }}
                >
                    <FontAwesomeIcon icon={icon} />
                </Box>
                {!isMobileSize && (
                    <Typography level="body-xs" textColor="text.primary" lineHeight="166%" textAlign="center" pt={0.5}>
                        {children}
                    </Typography>
                )}
            </Stack>
        </Box>
    );
};

RoadmapStep.propTypes = {
    icon: PropTypes.any.isRequired,
};

const RoadmapSeparator = ({sx}) => {
    return (
        <Box
            sx={{
                ...flexCenter,
                '&:first-of-type > div': {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderLeft: 'none',
                },
                '&:last-of-type > div': {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    borderRight: 'none',
                },
                ...sx,
            }}
        >
            <Box
                sx={{
                    height: {desktop: '10px', mobile: '6px'},
                    width: '100%',
                    borderRadius: 'md',
                    border: '1px solid',
                    borderColor: 'neutral.outlinedBorder',
                    bgcolor: 'common.white',
                }}
            />
        </Box>
    );
};

RoadmapSeparator.propTypes = {
    sx: PropTypes.object,
};

RoadmapSeparator.defaultProps = {
    sx: {},
};

const getSeparatorWidth = months => `${(months / 13) * 100}%`;

export const RoadmapSteps = () => {
    const {t} = useTranslation(LocalesConstants.RevenueSimulator);
    const simulation = useSimulationData();
    const {roadmapARCE} = simulation.simulationOutput.compensationRoadmap.graph;

    const months = simulation.simulationInput.professionalInfo.monthsToRestoreTravelSubvention || 0;
    const revenuePercent = simulation.simulationOutput.revenueProjectionPercentAsFreelancer;

    return (
        <JoyUIThemeProvider>
            <Stack direction="row" sx={{bgcolor: 'background.surface', py: 1, ml: '70px'}}>
                <RoadmapSeparator sx={{width: getSeparatorWidth(1), pr: '8px'}} />
                {months > 0 && (
                    <RoadmapStep icon={faChartLine}>
                        +{revenuePercent}% <br />
                        {t('roadmap.income')}
                    </RoadmapStep>
                )}
                {months === 4 && <RoadmapSeparator sx={{width: getSeparatorWidth(3)}} />}
                <RoadmapStep icon={faRocket}>
                    <Trans t={t} i18nKey="roadmap.companyCreation.desktop" />
                </RoadmapStep>
                <RoadmapStep icon={faEuro}>
                    {t('roadmap.arce')} #1 <br />
                    <EurAmount amount={roadmapARCE} />
                </RoadmapStep>
                <RoadmapSeparator sx={{width: getSeparatorWidth(5)}} />
                <RoadmapStep icon={faEuro}>
                    {t('roadmap.arce')} #2 <br />
                    <EurAmount amount={roadmapARCE} />
                </RoadmapStep>
                {months !== 4 && <RoadmapSeparator sx={{flex: 1}} />}
            </Stack>
        </JoyUIThemeProvider>
    );
};
