import {all, delay, fork, put} from 'redux-saga/effects';
import {watchEventSagas} from '../app/modules/event/event.sagas';
import {AnimationActions} from '../features/animations/store/animations.action';
import {watchAnimationsSaga} from '../features/animations/store/animations.saga';
import {MissionClientsActions} from '../features/missions/modules/client-side/store/missions-client.actions';
import {watchMissionsClientSaga} from '../features/missions/modules/client-side/store/missions-client.saga';
import {ClientMissionDocumentType} from '../features/missions/utils/constants';
import {replace} from '../lib/router/connected-router-saga';
import {PublicRoutesWithToken} from '../lib/router/route-paths';
import {SESSION_NAMES, SessionUtil} from '../utils/session';

export const initPublicUserSaga = function* (location) {
    yield put(MissionClientsActions.storeIsPublicUser(true));

    // TODO Decouple from missions
    const documentType = (
        location?.pathname === PublicRoutesWithToken.MISSION_CLIENT_LANDING
        || location?.pathname === PublicRoutesWithToken.MISSION_CLIENT
    ) ? ClientMissionDocumentType.Quote : ClientMissionDocumentType.Cra;

    SessionUtil.setSession(SESSION_NAMES.PUBLIC_USER_SESSION, location?.query?.token);

    SessionUtil.setSession(SESSION_NAMES.PUBLIC_USER_MISSION_ID, location?.query?.missionId);
    SessionUtil.setSession(SESSION_NAMES.PUBLIC_USER_DOCUMENT_TYPE, documentType);
    SessionUtil.setSession(
        SESSION_NAMES.PUBLIC_USER_CONTRACT_ID,
        documentType === ClientMissionDocumentType.Quote
            ? location?.query?.contractId
            : location?.query?.craDocumentId,
    );

    yield all([
        fork(watchMissionsClientSaga),
        fork(watchEventSagas),
        fork(watchAnimationsSaga),
    ]);

    yield delay(1000);
    yield put(AnimationActions.setInitialAnimation(false));

    if (location?.pathname === PublicRoutesWithToken.MISSION_CLIENT_LANDING) {
        yield put(replace(PublicRoutesWithToken.MISSION_CLIENT + location?.search));
    }

    if (location?.pathname === PublicRoutesWithToken.MISSION_CLIENT_CRA_LANDING) {
        yield put(replace(PublicRoutesWithToken.MISSION_CLIENT_CRA + location?.search));
    }
};
