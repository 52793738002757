import AddCircleIcon from '@mui/icons-material/AddCircle';
import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {useColumnConfig} from './useColumnConfig';
import DataTable from '../../../../components/ui-kit/DataTable/DataTable';
import {RoutePaths} from '../../../../lib/router/route-paths';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {RevenueSimulatorSelectors} from '../../store/revenue-simulator.selector';

export const RevenueSimulationsList = () => {
    const {t} = useTranslation(LocalesConstants.RevenueSimulator);
    const isLoading = useSelector(RevenueSimulatorSelectors.selectIsLoadingSimulations);
    const tableConfig = useColumnConfig();

    return (
        <Box>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 4}}>
                <Box sx={{fontSize: theme => theme.typography.pxToRem(34), fontWeight: 700}}>
                    {t('simulationsList.title')}
                </Box>
                <Link to={RoutePaths.REVENUE_SIMULATOR_CREATE}>
                    <Button
                        startIcon={<AddCircleIcon />}
                        variant="contained"
                        color="primary_v2"
                        sx={{height: theme => theme.typography.pxToRem(36)}}
                    >
                        {t('simulationsList.newSimulation')}
                    </Button>
                </Link>
            </Box>
            <DataTable
                title={t('simulationsList.title')}
                tableId="revenueSimulations"
                defaultSortByKey="createdAt"
                columnConfig={tableConfig}
                missingDataMessage=""
                dataSelector={RevenueSimulatorSelectors.createSimulationsListSelector}
                searchPlaceholder={t('simulationsList.search')}
                isLoading={isLoading}
                hasSearch
                hasPagination
                customHeader={searchField => <Box sx={{px: 2, pt: 2, width: '40%'}}>{searchField}</Box>}
            />
        </Box>
    );
};
