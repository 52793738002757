import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Stack, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {DocumentSigningV3} from './DocumentSigningV3';
import {UploadSignatureModal} from '../../../../../signature/components/UploadSignatureModal';
import {useSigningWrapper} from '../../hooks/use-signing-wrapper';

// TODO Design is not completed
export const DocumentSigningWrapperV3 = ({
    type,
    MainActionComponent,
    DocumentsLinkComponent,
    signingTitle,
    hasMultipleDocuments,
    isShowingSignatureBox,
    onSignFn,
    onNextFn,
    signedLocale,
    isSigned,
}) => {
    const {
        t,
        dt,
        isMobileSize,
        onPreviousDocumentClick,
        onNextDocumentClick,
        documents,
        currentDocumentId,
        currentDocumentIndex,
        numberOfDocuments,
    } = useSigningWrapper();

    return (
        <Box
            display="grid"
            gridTemplateRows="min-content 1fr"
            sx={{
                ...(isMobileSize ? {px: 2, py: 3} : {}),
            }}
        >
            <Typography
                level="h1"
            >
                {signingTitle ?? t('signing.title')}
            </Typography>

            <Box
                display="grid"
                gridTemplateColumns={!isMobileSize ? '1fr 292px' : '1fr 1fr'}
                gap={2}
                sx={isMobileSize ? {mx: 2} : {}}
            >
                <Typography
                    level="h4"
                    sx={{fontWeight: 'fontWeightMedium', mb: 2}}
                >
                    {!!DocumentsLinkComponent && DocumentsLinkComponent}
                    {!isMobileSize && documents[currentDocumentId] && (
                        <Box component="span" sx={{lineHeight: '30.75px'}}>
                            {dt(`documentsDatabaseNames.${documents[currentDocumentId].type}`)}
                        </Box>
                    )}
                </Typography>

                {hasMultipleDocuments && (
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            mb: 2,
                            mt: {desktop: -1, mobile: -2},
                        }}
                    >
                        <Box
                            disabled={currentDocumentIndex < 2}
                            aria-label="previous-document"
                            onClick={onPreviousDocumentClick}
                            sx={{
                                backgroundColor: 'background.body',
                                boxShadow: 'lg',
                            }}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </Box>

                        <Typography variant="h5">
                            {currentDocumentIndex} {t('signing.of')} {numberOfDocuments}
                        </Typography>

                        <Box
                            aria-label="next-document"
                            disabled={currentDocumentIndex === numberOfDocuments}
                            onClick={onNextDocumentClick}
                            sx={{
                                backgroundColor: 'background.body',
                                boxShadow: 'lg',
                            }}
                        >
                            <FontAwesomeIcon icon={faChevronRight} />
                        </Box>
                    </Stack>
                )}
            </Box>

            <DocumentSigningV3
                type={type}
                MainActionComponent={MainActionComponent}
                onSignFn={onSignFn}
                onNextFn={onNextFn}
                isShowingSignatureBox={isShowingSignatureBox}
                signedLocale={signedLocale}
            />

            <UploadSignatureModal
                isV3
            />
        </Box>
    );
};

DocumentSigningWrapperV3.propTypes = {
    type: PropTypes.string,
    MainActionComponent: PropTypes.object,
    DocumentsLinkComponent: PropTypes.object,
    signingTitle: PropTypes.string,
    hasMultipleDocuments: PropTypes.bool,
    onSignFn: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    onNextFn: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    isShowingSignatureBox: PropTypes.bool,
    signedLocale: PropTypes.string,
    isSigned: PropTypes.bool.isRequired,
};
DocumentSigningWrapperV3.defaultProps = {
    type: undefined,
    MainActionComponent: null,
    DocumentsLinkComponent: null,
    onSignFn: null,
    onNextFn: null,
    signingTitle: '',
    hasMultipleDocuments: true,
    isShowingSignatureBox: false,
    signedLocale: 'signing.documentSignedPolite',
};
