// Redirect section
import {call, delay, put, select} from 'redux-saga/effects';
import {BankActions} from './bank.action';
import {BankSelector} from './bank.selector';
import hiwayLocalStorage from '../../../config/storage';
import {push, selectRouterLocation} from '../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../lib/router/route-paths';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import {USER_ROLES, isUserFn} from '../../../utils/user-roles';
import {AnimationActions} from '../../animations/store/animations.action';
import {capitalDepositGenerateSecondBatchDocuments} from '../../company/modules/setup-company/store/setup-company.actions';
import {FULL_COMPANY_STATUSES} from '../../company/modules/setup-company/utils/constants';
import {DatabaseActions} from '../../document/modules/database/store/database.action';
import {FreelancerSelectors} from '../../freelancer';
import {
    FinalizationSubsteps,
} from '../../freelancer/modules/capital-deposit/utils/constants';
import {CompaniesSelectors} from '../../freelancer/modules/companies';
import {
    CompanySetupInternalSubSteps,
    CompanySetupSubSteps,
} from '../../freelancer/modules/company-setup/utils/constants';
// eslint-disable-next-line import/no-cycle
import {OnboardingHelper} from '../../freelancer/modules/onboarding/store/onboarding-helper.saga';
import {OnboardingActions} from '../../freelancer/modules/onboarding/store/onboarding.action';
import {OnboardingSteps} from '../../freelancer/modules/onboarding/utils/onboadingStepsConstant';
import {LoadingActions, LoadingTypes} from '../../loading';
import {LoggedInUserActions, LoggedInUserSelectors} from '../../user/modules/logged-in-user';
import {BankApi} from '../api/bank.api';
import {getHiwayBankIntegration, getIntegrationInfo} from '../modules/bridge-api/utils/bridge-util';
import {BANK_TYPES} from '../modules/bridge-api/utils/constants';
import {RibAndDocumentsActions} from '../modules/rib-and-documents/store/ribAndDocuments.actions';
import {
    BANK_ACCOUNT_STATEMENTS_DATA_TABLE_ID,
    BANK_ACCOUNT_STATEMENTS_SORT_VALUES_ENUM,
} from '../modules/rib-and-documents/utils/constants';
import {
    getActiveHiwayIntegration,
    hasRibAndDocumentsPageAccess,
} from '../modules/rib-and-documents/utils/ribAndDocuments.util';
import {BANK_ONBOARDING_STATUS, VerificationStatus} from '../utils/constants';

export const getHiwayAccountId = function* () {
    const integrationData = yield select(BankSelector.selectIntegrations);
    const bankIntegrationData = getHiwayBankIntegration(integrationData);

    // TODO Bridge
    if (!bankIntegrationData) {
        yield call(bankIntegrationMainCheckFlow);
        return;
    }

    const bankAccountHolders = bankIntegrationData?.bankAccountHolders;
    const companyBankAccount = bankAccountHolders
        ? bankAccountHolders.find(integration => integration?.type === 'COMPANY')
        : undefined;
    const account = companyBankAccount?.bankAccounts?.[0];

    if (!account) {
        yield call(bankIntegrationMainCheckFlow);
        return;
    }

    return account.id;
};

export const bankFreelancerLoginAuthFlow = function* () {
    // If redirected after creation flow continue creation flow
    const location = yield select(selectRouterLocation);
    if (location.pathname === RoutePaths.BANK_AFTER_CREATION_REDIRECT) {
        yield put(push(RoutePaths.BANK_ONBOARDING_SUCCESS));
        yield call(bankFreelancerCreateAccountFlow, false);
        return;
    }

    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    // User not logged in
    if (!loggedInUser || !loggedInUser.role) {
        return;
    }

    // If role is not freelancer do not go further in the logic for freelancer
    if (!isUserFn(loggedInUser)) {
        return;
    }

    // If freelancer is not fully active bank logic is not relevant
    if (!loggedInUser.isFullyActive) {
        return;
    }

    try {
        // Fetch bank integration data
        const bankIntegrationDataList = yield call(getBankIntegrationsFlow);

        // If freelancer does not have needed flag for bank active it is not relevant
        if (!loggedInUser.hasBankAccess) {
            return;
        }

        const bankIntegrationData = bankIntegrationDataList?.find(
            integration => integration.type === BANK_TYPES.hiway,
        );

        // Check if integration is SWAN IO
        if (bankIntegrationData?.type !== 'SWAN_IO') {
            return;
        }

        // TODO Bridge check if needed
        // Check if user is authenticated with provider
        if (bankIntegrationData && !bankIntegrationData.isAuthenticated) {
            yield call(authenticateSwanIoFlow);
        }
    } catch (e) {
        Toast.error('genericError');
    }
};

export const bankIntegrationMainCheckFlow = function* () {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    // User not logged in
    if (!loggedInUser || !loggedInUser.role) {
        return;
    }

    // If role is not freelancer do not go further in the logic for freelancer
    if (loggedInUser.role !== USER_ROLES.FREELANCER) {
        return;
    }

    // If freelancer is not fully active bank logic is not relevant
    if (!loggedInUser.isFullyActive) {
        return;
    }

    // If freelancer does not have needed flag for bank active it is not relevant
    if (!loggedInUser.hasBankAccess) {
        return;
    }

    const location = yield select(selectRouterLocation);

    try {
        let bankIntegrationUpdated = false;

        let bankIntegrationDataList = yield select(BankSelector.selectIntegrations);
        if (!bankIntegrationDataList || bankIntegrationDataList.length === 0) {
            // Fetch bank integration data
            bankIntegrationDataList = yield call(getBankIntegrationsFlow);

            bankIntegrationUpdated = true;
        }

        // If bank integration data list is empty go to promo screen
        // if (!bankIntegrationDataList || bankIntegrationDataList.length === 0) {
        //     yield call(redirectToBankPromotionScreen);
        //     return;
        // }

        let bankIntegrationData = bankIntegrationDataList.find(integration => integration.type === BANK_TYPES.hiway);

        if (!bankIntegrationUpdated
            && (
                !bankIntegrationData?.bankAccountHolders
                || bankIntegrationData.bankAccountHolders.length === 0
            )) {
            // Fetch bank integration data
            bankIntegrationDataList = yield call(getBankIntegrationsFlow);

            bankIntegrationData = bankIntegrationDataList.find(integration => integration.type === BANK_TYPES.hiway);
        }

        // TODO Bridge is needed?
        // // If bank integration data is empty go to promo screen
        // if (
        //     !bankIntegrationData.bankAccountHolders
        //     || bankIntegrationData.bankAccountHolders.length === 0
        // ) {
        //     yield call(redirectToBankPromotionScreen);
        //     return;
        // }

        // TODO Bridge Check if I should do it auto or user should click somewhere
        // if (
        //     !bankIntegrationData?.bankAccountHolders
        //     || bankIntegrationData.bankAccountHolders.length === 0
        // ) {
        //     yield call(bankFreelancerCreateAccountFlow);
        //     return;
        // }

        // Get correct bankAccountHolders data
        const bankAccountHolders = bankIntegrationData?.bankAccountHolders.length === 1
            ? bankIntegrationData.bankAccountHolders[0]
            : bankIntegrationData?.bankAccountHolders.find(integration => integration?.type === 'COMPANY');

        // TODO Handle
        if (!bankAccountHolders) {
            return;
        }

        // TODO Bridge is needed?
        // Check if bank integrations exist and have type SWAN_IO
        // if (bankIntegrationData.type !== 'SWAN_IO') {
        //     yield call(redirectToBankPromotionScreen);
        //     return;
        // }

        // Check if user is authenticated with provider
        if (!bankIntegrationData.isAuthenticated) {
            yield call(authenticateSwanIoFlow);
            return;
        }

        // TODO Bridge is needed?
        // If entity ID is not same as default company Id show promotion page
        // if (
        //     !bankAccountHolders
        //     || !bankAccountHolders.entityId
        //     || bankAccountHolders.entityId !== loggedInUser.defaultCompanyId
        // ) {
        //     yield call(redirectToBankPromotionScreen);
        //     return;
        // }

        // If onboarding status is finalized go to transactions list
        if (bankAccountHolders.onboardingStatus === BANK_ONBOARDING_STATUS.FINALIZED) {
            if (
                location.pathname === RoutePaths.BANK_TRANSFER_LIST
                || location.pathname === RoutePaths.BANK_CARDS
                || location.pathname === RoutePaths.BANK_RIB_AND_DOCUMENTS
            ) {
                // Add kyc check
                if (bankAccountHolders.verificationStatus === VerificationStatus.PENDING
                    || bankAccountHolders.verificationStatus === VerificationStatus.WAITING_FOR_VERIFICATION
                    || bankAccountHolders.verificationStatus === VerificationStatus.NOT_STARTED) {
                    yield call(redirectTOKycScreen);
                    return;
                }
            }

            yield call(redirectToTransactionListScreen);
            return;
        }

        if (bankAccountHolders.onboardingStatus === BANK_ONBOARDING_STATUS.VALID) {
            // yield put(BankActions.finalizeBankAccount());
            yield call(redirectToOnboardingSuccessScreen());
        }
    } catch (error) {
        Debug.error('bank login', 'Error: ', {error});
    }
};

export const bankIntegrationPostCareOnboardFailedFlow = function* () {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    // User not logged in
    if (!loggedInUser || !loggedInUser.role) {
        return;
    }

    // If role is not freelancer do not go further in the logic for freelancer
    if (loggedInUser.role !== USER_ROLES.FREELANCER) {
        return;
    }

    // If freelancer is not fully active bank logic is not relevant
    if (!loggedInUser.isFullyActive) {
        return;
    }

    // If freelancer does not have needed flag for bank active it is not relevant
    if (!loggedInUser.hasBankAccess) {
        return;
    }

    try {
        let bankIntegrationUpdated = false;

        let bankIntegrationDataList = yield select(BankSelector.selectIntegrations);
        if (!bankIntegrationDataList || bankIntegrationDataList.length === 0) {
            // Fetch bank integration data
            bankIntegrationDataList = yield call(getBankIntegrationsFlow);

            bankIntegrationUpdated = true;
        }

        // If bank integration data list is empty go to promo screen
        // if (!bankIntegrationDataList || bankIntegrationDataList.length === 0) {
        //     yield call(redirectToBankPromotionScreen);
        //     return;
        // }

        let bankIntegrationData = bankIntegrationDataList.find(integration => integration.type === BANK_TYPES.hiway);

        if (!bankIntegrationUpdated
            && (
                !bankIntegrationData?.bankAccountHolders
                || bankIntegrationData.bankAccountHolders.length === 0
            )) {
            // Fetch bank integration data
            bankIntegrationDataList = yield call(getBankIntegrationsFlow);

            bankIntegrationData = bankIntegrationDataList.find(integration => integration.type === BANK_TYPES.hiway);
        }

        // Get correct bankAccountHolders data
        const bankAccountHolders = bankIntegrationData?.bankAccountHolders.length === 1
            ? bankIntegrationData.bankAccountHolders[0]
            : bankIntegrationData?.bankAccountHolders.find(integration => integration?.type === 'COMPANY');

        if (!bankAccountHolders) {
            return;
        }

        // Check if user is authenticated with provider
        if (!bankIntegrationData.isAuthenticated) {
            yield call(authenticateSwanIoFlow);
            return;
        }

        // Add kyc check
        if (bankAccountHolders.verificationStatus === VerificationStatus.PENDING
            || bankAccountHolders.verificationStatus === VerificationStatus.WAITING_FOR_VERIFICATION
            || bankAccountHolders.verificationStatus === VerificationStatus.NOT_STARTED) {
            yield put(OnboardingActions.setStep(OnboardingSteps.FINAL_POINTS));
            yield put(OnboardingActions.setSubStep(FinalizationSubsteps.PENDING_BANK_ONBOARDING));
            yield put(OnboardingActions.setInternalSubStep(
                FinalizationSubsteps.PENDING_BANK_ONBOARDING,
            ));

            return;
        }

        if (bankAccountHolders.onboardingStatus === BANK_ONBOARDING_STATUS.VALID) {
            yield put(capitalDepositGenerateSecondBatchDocuments());
            yield put(OnboardingActions.setStep(OnboardingSteps.FINAL_POINTS));
        }
    } catch (error) {
        Debug.error('bank login', 'Error: ', {error});
    }
};

export const bankCareFinalizeOnboardingFlow = function* () {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.BANK_CREATION, true));

        yield put(DatabaseActions.clearDocuments());

        const bankIntegration = yield call(BankApi.getBankIntegrations);

        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
        const freelancer = yield select(FreelancerSelectors.selectAccount);
        const company = yield select(CompaniesSelectors.createCompanyByIdSelector(freelancer?.defaultCompanyId));

        // Call API for finishing onboarding if user is not already onboarded
        const hiwayIntegration = bankIntegration.find(integration => integration.type === BANK_TYPES.hiway);

        if (!hiwayIntegration) {
            return;
        }

        if (
            hiwayIntegration.bankAccountHolders?.[0]?.onboardingStatus !== BANK_ONBOARDING_STATUS.FINALIZED
        ) {
            yield call(BankApi.onboardCompany, hiwayIntegration.id, company.id);
        }

        yield delay(200);

        const bankIntegrationDataList = yield call(BankApi.getBankIntegrations);

        const isAccountCompleted = bankIntegrationDataList?.[0]?.bankAccountHolders?.[0]?.onboardingStatus
            === BANK_ONBOARDING_STATUS.FINALIZED;
        const isAccountVerified = bankIntegrationDataList?.[0]?.bankAccountHolders?.[0]?.verificationStatus
            === VerificationStatus.VERIFIED;

        yield call(OnboardingHelper.handleCompanyRegistrationCompletedCare, {
            isAccountCompleted,
            isAccountVerified,
            registrationCompletedProgress: {},
            loggedInUser,
            company,
        });

        yield put(LoadingActions.setLoading(LoadingTypes.BANK_CREATION, false));
    } catch (e) {
        if (e?.response?.status === 500) {
            Toast.error('genericError');
        } else if (e?.response?.status === 409 || e?.response?.status === 407) {
            yield call(bankIntegrationPostCareOnboardFailedFlow);
        } else {
            yield put(OnboardingActions.setSubStep(FinalizationSubsteps.PENDING_BANK_ONBOARDING));
            yield put(OnboardingActions.setInternalSubStep(
                FinalizationSubsteps.PENDING_BANK_ONBOARDING,
            ));
        }

        yield put(LoadingActions.setLoading(LoadingTypes.BANK_CREATION, false));
    }
};

export const bankFreelancerFinalizeOnboardingFlow = function* () {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.BANK_CREATION, true));

        const bankIntegration = yield call(BankApi.getBankIntegrations);

        const freelancer = yield select(FreelancerSelectors.selectAccount);
        const company = yield select(CompaniesSelectors.createCompanyByIdSelector(freelancer?.defaultCompanyId));

        // Call API for finishing onboarding if user is not already onboarded
        const hiwayIntegration = bankIntegration.find(integration => integration.type === BANK_TYPES.hiway);

        // TODO Check
        if (!hiwayIntegration) {
            return;
        }
        if (
            hiwayIntegration.bankAccountHolders?.[0]?.onboardingStatus !== BANK_ONBOARDING_STATUS.FINALIZED
            && company?.status === FULL_COMPANY_STATUSES.ACTIVE
        ) {
            yield call(BankApi.onboardCompany, hiwayIntegration.id, company.id);
        }

        yield call(getBankIntegrationsFlow);

        // Finalisation is successfull
        yield call(redirectToOnboardingSuccessScreen);

        yield put(LoadingActions.setLoading(LoadingTypes.BANK_CREATION, false));
    } catch (e) {
        if (e?.response?.status === 500) {
            // If error is 500 redirect to main login flow
            yield call(bankIntegrationMainCheckFlow);
            Toast.error('genericError');
        } else if (e?.response?.status === 409 || e?.response?.status === 407) {
            // Call main login flow
            yield call(bankIntegrationMainCheckFlow);
        } else {
            Toast.error('genericError');
        }

        yield put(LoadingActions.setLoading(LoadingTypes.BANK_CREATION, false));
    }
};

export const bankFreelancerCreateAccountFlow = function* (shouldCallSwanAuth = true) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.BANK_CREATION, true));

        const bankIntegration = yield call(BankApi.getBankIntegrations);

        // If there are no integrations login
        if (bankIntegration.length === 0 && shouldCallSwanAuth) {
            // TODO See how to handle these very unlikely cases after bank-after-creation without creating loop
            yield call(authenticateSwanIoFlow, RoutePaths.BANK_AFTER_CREATION_REDIRECT);
            return;
        }

        const freelancer = yield select(FreelancerSelectors.selectAccount);
        const company = yield select(CompaniesSelectors.createCompanyByIdSelector(freelancer?.defaultCompanyId));

        const hiwayIntegration = bankIntegration.find(integration => integration.type === BANK_TYPES.hiway);

        if (!hiwayIntegration && shouldCallSwanAuth) {
            // TODO See how to handle these very unlikely cases after bank-after-creation without creating loop
            yield call(authenticateSwanIoFlow, RoutePaths.BANK_AFTER_CREATION_REDIRECT);
            return;
        }

        // Call API for finishing onboarding if user is not already onboarded
        if (
            hiwayIntegration.bankAccountHolders?.[0]?.onboardingStatus !== BANK_ONBOARDING_STATUS.FINALIZED
            && company?.status === FULL_COMPANY_STATUSES.ACTIVE
        ) {
            yield call(BankApi.onboardCompany, hiwayIntegration.id, company.id);
        }

        yield call(getBankIntegrationsFlow);

        // Finalisation is successfull
        // TODO Bridge Check if needed
        // yield call(redirectToOnboardingSuccessScreen);

        yield put(LoadingActions.setLoading(LoadingTypes.BANK_CREATION, false));
    } catch (e) {
        // Company already onboarded?
        if (e?.response?.status === 409 || e?.response?.status === 407) {
            // Call main login flow
            yield call(bankIntegrationMainCheckFlow);
        }

        // Missing data
        if (e?.response?.status === 400) {
            yield call(redirectToMissingInformationScreen);
        }

        // If there is an error in proccess
        if (e?.response?.status === 500) {
            yield call(bankIntegrationMainCheckFlow);
            Toast.error('genericError');
        }

        yield put(LoadingActions.setLoading(LoadingTypes.BANK_CREATION, false));
    }
};

export const bankFreelancerCreateAccountOnboardingFlow = function* () {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.BANK_CREATION, true));

        const bankIntegrations = yield call(BankApi.getBankIntegrations);

        // If there are no integrations redirect user to swan
        if (bankIntegrations.length === 0) {
            yield call(authenticateSwanIoFlow, RoutePaths.ONBOARDING, {identificationScope: 'Individual'});
            return;
        }

        // If there are already some integrations go to next substep
        yield put(AnimationActions.storeNextDispatch(
            OnboardingActions.setInternalSubStep(CompanySetupInternalSubSteps.DATA_VALIDATED),
        ));

        yield put(AnimationActions.setIsSubAnimationActive(false));

        yield put(OnboardingActions.setSubStep(CompanySetupSubSteps.DOCUMENT_UPLOAD));

        yield put(LoadingActions.setLoading(LoadingTypes.BANK_CREATION, false));
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});

        // Company already onboarded?
        if (e?.response?.status === 409 || e?.response?.status === 407) {
            // Call main login flow
            yield call(bankIntegrationMainCheckFlow);
        }

        // Missing data
        if (e?.response?.status === 400) {
            yield call(redirectToMissingInformationScreen);
        }

        // If there is an error in proccess
        if (e?.response?.status === 500) {
            yield call(bankIntegrationMainCheckFlow);
            Toast.error('genericError');
        }

        yield put(LoadingActions.setLoading(LoadingTypes.BANK_CREATION, false));
    }
};

export const bankFillMissingData = function* () {
    try {
        const integrationData = yield select(BankSelector.selectIntegrations);

        const bankIntegrationData = integrationData.find(integration => integration.type === BANK_TYPES.hiway);

        // TODO Check
        if (!bankIntegrationData) {
            return;
        }

        const bankAccountHolders = bankIntegrationData.bankAccountHolders.length === 1
            ? bankIntegrationData.bankAccountHolders[0]
            : bankIntegrationData.bankAccountHolders.find(
                integration => integration?.type === 'COMPANY',
            );

        window.location = bankAccountHolders.integrationData.onboardingUrl;
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        Toast.error('genericError');
    }
};

export const setTransactionAlertFlow = function* ({payload}) {
    try {
        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

        yield call(BankApi.setTransactionAlertFlow, loggedInUser.id, payload);

        yield put(LoggedInUserActions.loadUser());
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        Toast.error('genericError');
    }
};

export const startKYCFlow = function* () {
    try {
        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

        const response = yield call(BankApi.startKyc, loggedInUser.id);

        if (response.authUri) {
            window.location = response.authUri;
        }
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        Toast.error('genericError');
    }
};

export const getBankIntegrationsFlow = function* () {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.BANK_GET_INTEGRATIONS, true));

        const bankIntegrations = yield call(BankApi.getBankIntegrations, {withArchived: true});

        yield call(determineMenuFlow, bankIntegrations?.nonArchived);

        // Store bank integration data
        yield put(BankActions.storeAllIntegrationData(bankIntegrations));

        yield put(LoadingActions.setLoading(LoadingTypes.BANK_GET_INTEGRATIONS, false));

        return bankIntegrations?.nonArchived;
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        yield put(LoadingActions.setLoading(LoadingTypes.BANK_GET_INTEGRATIONS, false));
        Toast.error('genericError');
    }
};

export const determineMenuFlow = function* (bankIntegrations) {
    const user = yield select(LoggedInUserSelectors.selectLoggedInUser);

    if (!bankIntegrations || bankIntegrations.length === 0) {
        yield put(BankActions.isBankMenuFullyActive(false));
        return;
    }

    const bankIntegrationData = bankIntegrations.find(integration => integration.type === BANK_TYPES.hiway);

    if (!bankIntegrationData) {
        return;
    }

    // Check if bank integrations exist and have type SWAN_IO
    if (bankIntegrationData.type !== 'SWAN_IO') {
        yield put(BankActions.isBankMenuFullyActive(false));
        return;
    }

    // Check if user is authenticated with provider
    if (!bankIntegrationData.isAuthenticated) {
        yield put(BankActions.isBankMenuFullyActive(false));
        return;
    }

    const bankAccountHolders = bankIntegrationData.bankAccountHolders.length === 1
        ? bankIntegrationData.bankAccountHolders[0]
        : bankIntegrationData.bankAccountHolders.find(integration => integration?.type === 'COMPANY');

    if (
        !bankAccountHolders
        || !bankAccountHolders.entityId
        || bankAccountHolders.entityId !== user.defaultCompanyId
    ) {
        yield put(BankActions.isBankMenuFullyActive(false));
        return;
    }

    if (bankAccountHolders.onboardingStatus === 'FINALIZED') {
        yield put(BankActions.isBankMenuFullyActive(true));
        return;
    }

    yield put(BankActions.isBankMenuFullyActive(false));
};


export const authenticateSwanIoFlow = function* (route, isIndividual = false) {
    try {
        const generatedAuthUri = yield call(
            BankApi.generateSwanAuthUri,
            route || RoutePaths.BANK_TRANSACTION_LIST,
            isIndividual,
        );

        if (generatedAuthUri.authUri) {
            window.location = generatedAuthUri.authUri;
        }
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        Toast.error('genericError');
    }
};

export const bankFlagAccessFlow = function* () {
    const user = yield select(LoggedInUserSelectors.selectLoggedInUser);

    if (!user || !user.hasBankAccess) {
        yield put(push(RoutePaths.DASHBOARD));
    }
};

export const redirectToDashboardForUsersWithBankAccess = function* () {
    const user = yield select(LoggedInUserSelectors.selectLoggedInUser);
    const location = yield select(selectRouterLocation);
    if (
        user?.hasBankAccess
        && RoutePaths.REQUEST_HIWAY_PRO_ACCESS_LP === location.pathname
    ) {
        yield put(push(RoutePaths.DASHBOARD));
    }
};

// const redirectToBankPromotionScreen = function* () {
//     const location = yield select(selectRouterLocation);
//
//     if (location.pathname !== RoutePaths.BANK_PROMOTION) {
//         yield put(push(RoutePaths.BANK_PROMOTION));
//     }
// };

const redirectTOKycScreen = function* () {
    const location = yield select(selectRouterLocation);

    if (location.pathname !== RoutePaths.BANK_KYC) {
        yield put(push(RoutePaths.BANK_KYC));
    }
};

const redirectToTransactionListScreen = function* () {
    const location = yield select(selectRouterLocation);

    // TODO Introduce smarter system here
    if (location.pathname !== RoutePaths.BANK_TRANSACTION_LIST
        && location.pathname !== RoutePaths.BANK_TRANSFER_LIST
        && location.pathname !== RoutePaths.BANK_CREATE_TRANSFER
        && location.pathname !== RoutePaths.TRANSFER_CREATED_REDIRECT
        && location.pathname !== RoutePaths.BANK_CARDS
        && location.pathname !== RoutePaths.CARDS_CONSENT
        && location.pathname !== RoutePaths.BANK_RIB_AND_DOCUMENTS
    ) {
        yield delay(20); // Due to the way sideeffects work in burnmaniac lib we need this
        yield put(push(RoutePaths.BANK_TRANSACTION_LIST));
    }
};

const redirectToMissingInformationScreen = function* () {
    const location = yield select(selectRouterLocation);

    if (location.pathname !== RoutePaths.BANK_CREATION_MISSING_INFORMATION) {
        yield delay(20); // Due to the way sideeffects work in burnmaniac lib we need this
        yield put(push(RoutePaths.BANK_CREATION_MISSING_INFORMATION));
    }
};

// const redirectToFinalizeBankCreationScreen = function* () {
//     const location = yield select(selectRouterLocation);
//
//     if (location.pathname !== RoutePaths.BANK_CREATION_FINALIZE) {
//         yield delay(20); // Due to the way sideeffects work in burnmaniac lib we need this
//         yield put(push(RoutePaths.BANK_CREATION_FINALIZE));
//     }
// };

const redirectToOnboardingSuccessScreen = function* () {
    const location = yield select(selectRouterLocation);

    if (location.pathname !== RoutePaths.BANK_ONBOARDING_SUCCESS) {
        yield delay(20); // Due to the way sideeffects work in burnmaniac lib we need this
        yield put(push(RoutePaths.BANK_ONBOARDING_SUCCESS));
    }
};

export const bankRiBAndDocumentsPageAccessControl = function* () {
    const location = yield select(selectRouterLocation);
    const integrations = yield select(BankSelector.selectIntegrations);

    if (!hasRibAndDocumentsPageAccess(integrations)) {
        if (location.pathname !== RoutePaths.DASHBOARD) {
            yield delay(20); // Due to the way sideeffects work in burnmaniac lib we need this
            yield put(push(RoutePaths.DASHBOARD));
        }
    }
};

export const bankRibDocumentFetchSaga = function* () {
    let bankAccountHolderId;
    let bankAccountId;

    try {
        const integrations = yield select(BankSelector.selectIntegrations);
        const hiwayIntegration = getActiveHiwayIntegration(integrations);
        const {account, bankAccountHolder} = getIntegrationInfo(hiwayIntegration);
        bankAccountHolderId = bankAccountHolder?.id;
        bankAccountId = account?.id;
        yield put(LoadingActions.setLoading(LoadingTypes.GET_RIB_DOCUMENT, true));

        const response = yield call(BankApi.getRibDocumentData, {bankAccountHolderId, bankAccountId});

        const {documentDataUrl} = response;
        yield put(RibAndDocumentsActions.storeRibDocumentUrl({bankAccountHolderId, bankAccountId, documentDataUrl}));
        yield put(LoadingActions.setLoading(LoadingTypes.GET_RIB_DOCUMENT, false));
    } catch (e) {
        yield put(RibAndDocumentsActions.storeRibDocumentUrl(
            {bankAccountHolderId, bankAccountId, documentDataUrl: null},
        ));

        Debug.error('bankRibAndDocumentsLoaderSaga', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GET_RIB_DOCUMENT, false));
    }
};

export const bankStatementsLoaderSaga = function* () {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.GET_BANK_ACCOUNT_STATEMENTS, true));

        const integrations = yield select(BankSelector.selectIntegrations);
        const hiwayIntegration = getActiveHiwayIntegration(integrations);
        const {account, bankAccountHolder} = getIntegrationInfo(hiwayIntegration);
        const bankAccountHolderId = bankAccountHolder?.id;
        const bankAccountId = account?.id;

        const location = yield select(selectRouterLocation);
        const search = location?.query;

        const dataTableId = BANK_ACCOUNT_STATEMENTS_DATA_TABLE_ID;

        const storageKey = `${dataTableId}-savedRowsPerPage`;
        const limit = search?.[`${dataTableId}-rowsPerPage`] ?? (hiwayLocalStorage.has(storageKey)
            ? parseInt(hiwayLocalStorage.get(storageKey), 10)
            : 10);


        const params = {
            limit: limit,
            offset: search?.[`${dataTableId}-page`] ? parseInt(search[`${dataTableId}-page`], 10) * limit : 0,
            sortBy: search?.[`${dataTableId}-sortBy`] ? BANK_ACCOUNT_STATEMENTS_SORT_VALUES_ENUM[search[`${dataTableId}-sortBy`]] : undefined,
            sortOrder: search?.[`${dataTableId}-sortBy`]
                ? search?.[`${dataTableId}-sortDirection`] ? search?.[`${dataTableId}-sortDirection`].toUpperCase() : 'DESC'
                : undefined,
        };

        const response = yield call(
            BankApi.getBankAccountStatements,
            {bankAccountHolderId, bankAccountId, params},
        );

        yield put(RibAndDocumentsActions.storeBankAccountStatements(response));
        yield put(LoadingActions.setLoading(LoadingTypes.GET_BANK_ACCOUNT_STATEMENTS, false));
    } catch (e) {
        Debug.error('bankStatementsLoaderSaga', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GET_BANK_ACCOUNT_STATEMENTS, false));
    }
};
