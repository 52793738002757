import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';
import {roundHalfUpToTwoDecimalPlaces} from '../../../../../../../../utils/number';

const ReimbursedInfo = ({
    amount,
    allocated,
    isCategorised,
}) => {
    const {t} = useTranslation(LocalesConstants.Bank);
    const percents = !amount
        ? 100
        : Math.round(100 * allocated / amount);

    const isCompleted = percents === 100;

    return (
        <Box sx={{
            backgroundColor: isCompleted ? 'v2.light.success.light' : 'gray_v2.50',
            borderRadius: '16px',
            padding: '16px 24px 24px',
            mb: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
        }}
        >
            <Box sx={{letterSpacing: '0.15px'}}>
                <Typography
                    component="span"
                    sx={{
                        fontWeight: 400,
                        color: isCompleted ? 'white' : 'text_v2.secondary',
                        fontSize: theme => theme.typography.pxToRem(12),
                    }}
                >
                    {t('transactions.allocatedAmount')}&nbsp;&nbsp;
                </Typography>
                <Typography
                    component="span"
                    sx={{
                        fontWeight: 500,
                        color: isCompleted ? 'white' : 'text_v2.secondary',
                        fontSize: theme => theme.typography.pxToRem(12),
                    }}
                >
                    {roundHalfUpToTwoDecimalPlaces(allocated)}€
                    &nbsp;/&nbsp;
                    {roundHalfUpToTwoDecimalPlaces(amount)}€
                </Typography>
                <Box sx={{
                    mt: 1,
                    height: '4px',
                    borderRadius: '2px',
                    backgroundColor: isCompleted ? 'white' : 'primary_v2.shades60',
                    position: 'relative',
                }}
                >
                    <Box sx={{
                        height: '4px',
                        borderRadius: '2px',
                        backgroundColor: isCompleted ? 'white'
                            : (isCategorised ? 'success_v2.light' : 'primary_v2.main'),
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: `${percents}%`,
                    }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

ReimbursedInfo.propTypes = {
    amount: PropTypes.number.isRequired,
    allocated: PropTypes.number.isRequired,
    isCategorised: PropTypes.bool.isRequired,
};

export default ReimbursedInfo;
