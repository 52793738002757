import {
    Box,
    Option,
    Select,
    Stack,
    Typography,
} from '@mui/joy';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {JoyActionsComponent} from './JoyActionsComponent';
import {LocalesConstants} from '../../../utils/locales-constants';
import useMediaQuery from '../mui-legacy/useMediaQuery';

const DataTablePagination = ({
    rowsPerPage,
    page,
    onPageChange,
    onRowsPerPageChange,
    count,
    rowsPerPageOptions,
}) => {
    const {t} = useTranslation(LocalesConstants.Common);
    const isMobileSize = useMediaQuery(`(max-width:625px)`);
    const currentRows = 1 + (page * rowsPerPage);
    const totalRows = Math.min(1 + (page * rowsPerPage) + rowsPerPage, count);

    return (
        <Box component="tfoot">
            <Box component="tr" sx={{borderBottom: 'none'}}>
                <Box
                    component="td"
                    colSpan={100}
                    sx={{
                        '--TableCell-paddingY': '16px',
                        '--TableCell-paddingX': '16px',
                        'borderBottom': 'none',
                    }}
                >
                    <Stack
                        direction={isMobileSize ? 'column' : 'row'}
                        gap={2}
                        justifyContent={isMobileSize ? 'center' : 'space-between'}
                        alignItems="center"
                    >
                        <Stack
                            direction="row"
                            justifyContent={isMobileSize ? 'center' : 'flex-start'}
                            alignItems="center"
                            gap={1}
                        >
                            <Typography level="body-md" textColor="text.secondary">
                                {t('table.rowsPerPage')}
                            </Typography>
                            <Select
                                variant="outlined"
                                color="neutral"
                                value={rowsPerPage}
                                defaultValue={rowsPerPage}
                                sx={{
                                    width: '70px',
                                    backgroundColor: 'background.surface',
                                    boxShadow: 'sm',
                                }}
                                onChange={(_, value) => {
                                    // We need to form event object with target
                                    // for backwards compatibility, until we replace
                                    // all MUI with Joy
                                    onRowsPerPageChange({target: {value}});
                                }}
                            >
                                {rowsPerPageOptions.map(rowsCount => (
                                    <Option key={rowsCount} value={rowsCount}>{rowsCount}</Option>
                                ))}
                            </Select>
                            {isMobileSize && (
                                <PaginationPagesInfo
                                    currentRows={currentRows}
                                    totalRows={totalRows}
                                    count={count}
                                    t={t}
                                />
                            )}
                        </Stack>
                        <Stack
                            direction="row"
                            alignItems="center"
                            gap={3}
                        >
                            {!isMobileSize && (
                                <PaginationPagesInfo
                                    currentRows={currentRows}
                                    totalRows={totalRows}
                                    count={count}
                                    t={t}
                                />
                            )}
                            <JoyActionsComponent
                                page={page}
                                onPageChange={onPageChange}
                                count={count}
                                rowsPerPage={rowsPerPage}
                            />
                        </Stack>
                    </Stack>
                </Box>
            </Box>
        </Box>
    );
};

DataTablePagination.propTypes = {
    count: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onRowsPerPageChange: PropTypes.func.isRequired,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
};

DataTablePagination.defaultProps = {
    rowsPerPageOptions: [10, 25, 50],
};

const PaginationPagesInfo = ({
    currentRows,
    totalRows,
    count,
    t,
}) => (
    <Stack
        direction="row"
        alignItems="center"
        gap={0.5}
    >
        <Typography level="body-md" textColor="text.secondary">
            {currentRows}-{totalRows}
        </Typography>
        <Typography level="body-md" textColor="text.secondary">
            {t('of')}
        </Typography>
        <Typography level="body-md" textColor="text.secondary">
            {count}
        </Typography>
    </Stack>
);

PaginationPagesInfo.propTypes = {
    count: PropTypes.number.isRequired,
    currentRows: PropTypes.number.isRequired,
    totalRows: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
};

export {
    DataTablePagination,
};
