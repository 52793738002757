import {
    faCircleInfo,
    faFileLines,
    faGear,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Chip, Stack, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {AccountsMenuHandler} from './AccountMenuHandler';
import useMediaQuery from '../../../../../../components/joy-ui/mui-legacy/useMediaQuery';
import {ConditionalTooltip} from '../../../../../../components/tooltip/ConditionalTooltip';
import {useIsMobileSize} from '../../../../../../hooks/use-is-mobile-size';
import {push} from '../../../../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../../../../lib/router/route-paths';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {UiActions} from '../../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../../ui/utils/constants';
import {BANK_TYPES, BankIntegrationErrorStatuses} from '../../../bridge-api/utils/constants';
import {AccountBalanceTotal} from '../account-balance/AccountBalanceTotal';

const panelButtonStyle = {
    fontSize: 'md',
    fontWeight: 'md',
    fontStyle: 'normal',
    textTransform: 'none',
};

export const AccountsHeaderPanel = ({
    tabValue,
    setIsMobileTabsOpen,
    combinedTotal,
    fullBankAccountList,
    setHasBankSidebarOpen,
}) => {
    const {t} = useTranslation(LocalesConstants.Bank);
    const dispatch = useDispatch();

    const isMobileSize = useIsMobileSize();
    const isMobile500 = useMediaQuery('(max-width: 500px)');

    const isLoading = useSelector(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.DOWNLOAD_DOCUMENT,
    ));

    const onMenuOpen = () => {
        if (isMobileSize) {
            setIsMobileTabsOpen(true);
        } else {
            dispatch(UiActions.setActiveModal(ModalsKeys.TRANSACTION_ACCOUNTS_MODAL, true));
        }
    };

    const downloadRib = () => {
        dispatch(push(RoutePaths.BANK_RIB_AND_DOCUMENTS));
    };

    if (tabValue === BANK_TYPES.combined) {
        return (
            <>
                <AccountsMenuHandler
                    label={t('creationProPage.combined')}
                    value={BANK_TYPES.combined}
                    onMenuOpen={onMenuOpen}
                    isMobile={isMobileSize}
                />
                <AccountBalanceTotal amount={combinedTotal} />
            </>
        );
    } else if (tabValue === BANK_TYPES.archived) {
        return (
            <>
                <AccountsMenuHandler
                    label={t('creationProPage.archived')}
                    value={BANK_TYPES.archived}
                    tabValue={tabValue}
                    onMenuOpen={onMenuOpen}
                    isMobile={isMobileSize}
                />
                <Stack
                    direction="row"
                    alignItems="baseline"
                    gap={0.5}
                    sx={{
                        order: {mobile: 1, desktop: 0},
                        flexWrap: 'wrap',
                    }}
                >
                    <Typography
                        level="h1"
                        sx={{
                            display: 'inline',
                            textWrap: 'nowrap',
                            color: 'common.white',
                            ...(isMobile500 ? {
                                fontSize: 'xl2',
                            } : {}),
                        }}
                    >
                        {t('accountArchiving.tabSubtitle1')}
                    </Typography>
                    <Typography
                        level="title-sm"
                        color="common.white"
                        sx={{
                            display: 'inline',
                            textWrap: 'nowrap',
                            color: 'text.tertiary',
                        }}
                    >
                        {t('accountArchiving.tabSubtitle2')}
                    </Typography>
                </Stack>
                {!isMobileSize && (
                    <Stack
                        direction="row"
                        gap={1}
                        alignItems="center"
                        sx={{ml: 'auto'}}
                    >
                        <ConditionalTooltip
                            isV3
                            tooltip={t('accountArchiving.tabSubtitle')}
                        >
                            <Chip
                                color="neutral"
                                size="lg"
                                variant="soft"
                                sx={{
                                    fontStyle: 'normal',
                                }}
                                startDecorator={(
                                    <FontAwesomeIcon icon={faCircleInfo} />
                                )}
                            >
                                {t('accountBalance.information')}
                            </Chip>
                        </ConditionalTooltip>
                    </Stack>
                )}
            </>
        );
    }

    const account = fullBankAccountList.find(({id}) => id === tabValue) ?? {};
    const isHiway = account?.type === BANK_TYPES.hiway;

    return (
        <>
            <AccountsMenuHandler
                label={account.name}
                value={account.id}
                onMenuOpen={onMenuOpen}
                uncategorized={account.uncategorized}
                hasError={BankIntegrationErrorStatuses.includes(account?.status)}
                tabValue={tabValue}
                isHiway={account.type === BANK_TYPES.hiway}
                isMobile={isMobileSize}
            />
            <AccountBalanceTotal amount={account.amount} />
            <Stack
                direction="row"
                alignSelf={isMobileSize ? 'stretch' : 'auto'}
                gap={1}
                alignItems="center"
                sx={{ml: {desktop: 'auto'}}}
            >
                {isHiway && (
                    <Button
                        fullWidth={isMobileSize}
                        loading={isLoading}
                        loadingPosition="start"
                        color="neutral"
                        variant="soft"
                        size="sm"
                        onClick={downloadRib}
                        startDecorator={(
                            <FontAwesomeIcon icon={faFileLines} />
                        )}
                        sx={panelButtonStyle}
                    >
                        {t('accountBalance.rib')}
                    </Button>
                )}
                <Button
                    fullWidth={isMobileSize}
                    color="neutral"
                    variant="soft"
                    size="sm"
                    onClick={() => {
                        setHasBankSidebarOpen(account);
                    }}
                    startDecorator={(
                        <FontAwesomeIcon icon={faGear} />
                    )}
                    sx={panelButtonStyle}
                >
                    {t('accountBalance.parameters')}
                </Button>
            </Stack>
        </>
    );
};

AccountsHeaderPanel.propTypes = {
    tabValue: PropTypes.string,
    setIsMobileTabsOpen: PropTypes.func.isRequired,
    combinedTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    fullBankAccountList: PropTypes.arrayOf(PropTypes.object).isRequired,
    setHasBankSidebarOpen: PropTypes.func.isRequired,
};

AccountsHeaderPanel.defaultProps = {
    tabValue: '',
};
