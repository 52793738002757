import axios from 'axios';
import {CONFIG} from '../config';
import {getDocumentationApiBase} from '../features/documentation/util/functions';

export const documentationApiInstance = role => axios.create({
    baseURL: getDocumentationApiBase(role),
    auth: {
        username: CONFIG.DOCUMENTATION.USERNAME,
        password: CONFIG.DOCUMENTATION.PASSWORD,
    },
    headers: {
        'Content-Type': 'application/json',
    },
});
