import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Paper, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useTranslation} from 'react-i18next';
import TaskAlt from '../../../../../../assets/png/bank/check.png';
import ReactRouterButtonLink from '../../../../../../components/app/router/ReactRouterButtonLink';
import {useQuery} from '../../../../../../hooks/use-url';
import {RoutePaths} from '../../../../../../lib/router/route-paths';
import {LocalesConstants} from '../../../../../../utils/locales-constants';

const TransferCreated = () => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    if (!isMobileSize) {
        return (
            <Paper
                elevation={1}
                sx={{
                    borderRadius: '8px',
                    p: 5,
                }}
            >
                <Box sx={{
                    backgroundColor: 'gray_v2.50',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '672px',
                }}
                >
                    <ConfirmedContent />
                </Box>
            </Paper>
        );
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 100px)',
        }}
        >
            <ConfirmedContent />
        </Box>
    );
};

const ConfirmedContent = () => {
    const amount = useQuery('amount');
    const label = useQuery('label');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const {t} = useTranslation(LocalesConstants.BankTransfer);

    return (
        <>
            <Box>
                <img src={TaskAlt} alt="Task alt" />
            </Box>
            <Box sx={{
                mt: 2,
                mb: 0.5,
            }}
            >
                <Typography sx={{
                    fontWeight: 700,
                    fontSize: theme => theme.typography.pxToRem(34),
                }}
                >
                    {t('transfer.create.madeTransfer')}
                </Typography>
            </Box>
            <Box sx={{
                fontWeight: 600,
                mb: 3,
                width: '308px',
                textAlign: 'center',
            }}
            >
                {t('transfer.create.madeTransferText', {label: label, amount: amount})}
            </Box>
            <Box sx={{
                width: isMobileSize ? 'calc(100% - 48px)' : 'inherit',
            }}
            >
                <ReactRouterButtonLink
                    to={RoutePaths.BANK_TRANSFER_LIST}
                    variant="contained"
                    endIcon={<ArrowForwardIcon />}
                    fullWidth={isMobileSize}
                >
                    {t('transfer.create.backToTransfers')}
                </ReactRouterButtonLink>
            </Box>
        </>
    );
};

export default TransferCreated;
