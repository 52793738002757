import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {MissionsCraActions} from './missions-cra.action';
import {MissionsCraActionTypes} from './missions-cra.action-type';
import {Toast} from '../../../../../lib/toast';
import {Debug} from '../../../../../utils/debug';
import {SigningActions} from '../../../../document/modules/signing/store/signing.action';
import {LoadingActions, LoadingTypes} from '../../../../loading';
import {loadSignature} from '../../../../signature/store/signature.saga';
import {UiActions} from '../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../ui/utils/constants';
import {LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';
import {MISSION_CRA_SCREENS} from '../../../utils/constants';
import {MissionSigningApi} from '../../missions-signing/api/missions-signing.api';
import {MissionsCraApi} from '../api/missions-cra.api';


const flow = {
    getCraDocuments: function* ({
        missionId,
        userId,
    }) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

            const documents = yield call(MissionsCraApi.getMissionContractDocuments, {userId, missionId});

            yield put(MissionsCraActions.storeCraDocuments(documents?.items));
        } catch (error) {
            Debug.error('getCraContract', 'Error: ', {error});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
        }
    },
    generateCraContract: function* ({
        missionId,
        dates,
        period,
        userId,
        documentId,
    }) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

            const documents = yield call(MissionsCraApi.getMissionContractDocuments, {userId, missionId});

            if (documents?.count === 0) {
                const generatedDoc = yield call(MissionsCraApi.generateMissionContract,
                    {userId, missionId, period, dates});

                // Get document for signing
                yield call(flow.getSignUrlDocument, {documentId: generatedDoc?.documentId});

                yield put(MissionsCraActions.storeGenerateCraContract(generatedDoc));
            } else {
                // Patch doc
                yield call(MissionsCraApi.patchMissionContract,
                    {
                        userId,
                        missionId,
                        dates,
                        documentId: documentId ?? documents?.items[0]?.id,
                    });

                const regeneratedDoc = yield call(
                    MissionsCraApi.reGenerateMissionContract,
                    {userId, missionId, period},
                );

                // Get document for signing
                yield call(flow.getSignUrlDocument, {documentId: regeneratedDoc?.documentId});

                yield put(MissionsCraActions.storeGenerateCraContract(regeneratedDoc));
            }

            yield put(UiActions.setActiveModal(ModalsKeys.CONFIRM_CRA_SENDING, false));
            yield put(MissionsCraActions.changeMissionCRAScreen(MISSION_CRA_SCREENS.DOCUMENT_SIGNING));
        } catch (error) {
            Debug.error('getCraContract', 'Error: ', {error});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
        }
    },
    signCraContract: function* ({missionId, documentId}) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.SIGN_CONTRACT, true));
            const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

            const generatedDoc = yield call(MissionsCraApi.signMissionContract, {
                userId: loggedInUser?.id,
                missionId,
                documentId,
            });

            yield put(MissionsCraActions.storeGenerateCraContract({
                ...generatedDoc,
                isSigned: true, // TODO Remove once BE fixes issue
            }));

            // Refresh documents after signing
            const documents = yield call(MissionsCraApi.getMissionContractDocuments,
                {userId: loggedInUser?.id, missionId: missionId});

            // Get signed document url
            yield call(flow.getSignUrlDocument, {documentId: documents.items[0]?.documentId});

            yield put(SigningActions.setCurrentDocumentIsSigned(true));
        } catch (error) {
            Debug.error('signCraContract', 'Error: ', {error});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.SIGN_CONTRACT, false));
        }
    },
    sendCraContract: function* ({missionId, documentId}) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));
            const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

            yield call(MissionsCraApi.sendMissionContract, {
                userId: loggedInUser?.id,
                missionId,
                documentId,
            });

            yield put(UiActions.setActiveModal(ModalsKeys.CONFIRM_MISSIONS_SENDING, false));

            Toast.success('genericSuccessSave');

            yield put(MissionsCraActions.changeMissionCRAScreen(MISSION_CRA_SCREENS.SUCCESS_SCREEN));
        } catch (error) {
            Debug.error('sendMissionContract', 'Error: ', {error});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
        }
    },
    getSignUrlDocument: function* ({documentId}) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

            const data = yield call(MissionSigningApi.getSignUrlDocument, {
                documentId,
            });

            yield put(SigningActions.setCurrentDocumentUrl(data?.signedUrl));
            yield put(SigningActions.setCurrentDocumentId(documentId));
            yield put(SigningActions.setCurrentDocumentIsSigned(false));
            yield put(SigningActions.setIsSigningCurrentDocument(false));
            yield put(SigningActions.setIsLoadingCurrentDocument(false));
        } catch (error) {
            Debug.error('getSignUrlDocument', 'Error: ', {error});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
        }
    },
    getSigningUrl: function* ({
        document,
    }) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

            // Get document for signing
            yield call(flow.getSignUrlDocument, {documentId: document?.documentId});

            yield put(MissionsCraActions.storeGenerateCraContract(document));
            yield put(MissionsCraActions.changeMissionCRAScreen(MISSION_CRA_SCREENS.DOCUMENT_SIGNING));
        } catch (error) {
            Debug.error('getSigningUrl', 'Error: ', {error});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
        }
    },
};

const workers = {
    getCraDocuments: function* ({payload}) {
        yield call(flow.getCraDocuments, payload);
    },
    generateCraContract: function* ({payload}) {
        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

        yield call(flow.generateCraContract, {...payload, userId: loggedInUser?.id});
        yield call(loadSignature);
    },
    signCraContract: function* ({payload}) {
        if (payload?.missionId && payload?.documentId) {
            yield call(flow.signCraContract, {
                missionId: payload.missionId,
                documentId: payload.documentId,
            });
        }
    },
    sendCraContract: function* ({payload}) {
        if (payload?.missionId && payload?.documentId) {
            yield call(flow.sendCraContract, {
                missionId: payload.missionId,
                documentId: payload.documentId,
            });
        }
    },
    getSigningUrl: function* ({payload}) {
        if (payload?.document) {
            yield call(flow.getSigningUrl, {
                document: payload.document,
            });

            yield call(loadSignature);
        }
    },
};

export const watchMissionsCraSaga = function* () {
    yield all([
        takeEvery(MissionsCraActionTypes.GET_CRA_DOCUMENTS, workers.getCraDocuments),
        takeEvery(MissionsCraActionTypes.GENERATE_CRA_CONTRACT, workers.generateCraContract),
        takeEvery(MissionsCraActionTypes.SIGN_CRA_CONTRACT, workers.signCraContract),
        takeEvery(MissionsCraActionTypes.SEND_CRA_CONTRACT, workers.sendCraContract),
        takeEvery(MissionsCraActionTypes.GET_SIGNING_URL, workers.getSigningUrl),
    ]);
};
