import {saveAs} from 'file-saver';
import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import * as actions from './professional-expenses.actions';
import {selectProfessionalExpenses} from './professional-expenses.selectors';
import {
    deleteProfessionalExpenseRequest,
    getProfessionalExpensesRequest,
} from '../../../../../app/api/providers/expenses/expenses.provider';
import {isMobileSafari, isSafari} from '../../../../../app/utils/app.helpers';
import {safe} from '../../../../../app/utils/store/safe-saga';
import {Toast} from '../../../../../lib/toast';
import {selectCurrentCognitoUser} from '../../../../auth/store/auth.selectors';
import {getSignedUrlForDocumentRequest} from '../../../../company/modules/documents/api/company.api';
import {FreelancerSelectors} from '../../../../freelancer';
// eslint-disable-next-line import/no-cycle
import {loadFreelancerAccountSaga} from '../../../../freelancer/store/freelancer.sagas';

const getProfessionalExpensesSaga = function* ({payload}) {
    try {
        yield put(actions.setIsLoadingProfessionalExpenses(true));

        const expenses = yield call(
            getProfessionalExpensesRequest,
            payload.freelancerId,
            payload.companyId,
        );

        yield put(actions.storeProfessionalExpenses(expenses));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingProfessionalExpenses(false));
    }
};

export const professionalExpensesLoaderSaga = function* ({payload}) {
    const {params = {}} = payload || {};
    let {companyId, freelancerId} = params;

    if (!companyId || !freelancerId) {
        const freelancer = yield select(FreelancerSelectors.selectAccount);
        const {id} = yield select(selectCurrentCognitoUser);

        freelancerId = id;

        companyId = freelancer.defaultCompanyId;
    }

    yield call(loadFreelancerAccountSaga, freelancerId);

    yield put(actions.getProfessionalExpenses(freelancerId, companyId));
};

export const deleteProfessionalExpenseSaga = function* ({payload}) {
    yield put(actions.setIsLoadingProfessionalExpenses(true));

    yield call(
        deleteProfessionalExpenseRequest,
        payload.freelancerId,
        payload.companyId,
        payload.expenseId,
    );

    const expenses = yield select(selectProfessionalExpenses);

    const newExpenses = Object.keys(expenses)
        .filter(expenseId => expenseId !== payload.expenseId)
        .reduce((result, current) => {
            result[current] = expenses[current];

            return result;
        }, {});

    yield put(actions.storeProfessionalExpenses(newExpenses));

    Toast.success('expenseDeleted');

    yield put(actions.setIsLoadingProfessionalExpenses(false));
};

export const downloadProfessionalExpenseSaga = function* ({payload}) {
    try {
        let childWindow;

        if ((isSafari || isMobileSafari) && !payload.isDownload) {
            childWindow = window.open('', '_blank');
        }

        yield put(actions.setIsLoadingProfessionalExpenses(true));

        const {signedUrl} = yield call(
            getSignedUrlForDocumentRequest,
            payload.receiptId,
            payload.isDownload,
        );

        if (!signedUrl) {
            throw new Error('The document URL is missing.');
        }

        if ((isSafari || isMobileSafari) && !payload.isDownload) {
            childWindow.location = signedUrl;

            return;
        }

        if ((isSafari || isMobileSafari) && payload.isDownload) {
            // TODO:HIGH: It's ugly but it works.
            fetch(signedUrl).then(response => {
                return response.blob();
            }).then(blob => {
                const matchedGroups = signedUrl.match(/filename[^;=\n]*%3D(%22(.*)%22[^;\n]*)/);
                const filename = matchedGroups[2];

                saveAs(blob, decodeURI(filename));
            });

            return;
        }

        window.open(signedUrl, '_blank');
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingProfessionalExpenses(false));
    }
};


export const watchProfessionalExpensesSagas = function* () {
    yield all([
        takeLatest(actions.GET_PROFESSIONAL_EXPENSES, getProfessionalExpensesSaga),
        takeLatest(actions.DELETE_PROFESSIONAL_EXPENSE, safe(deleteProfessionalExpenseSaga)),
        takeLatest(actions.DOWNLOAD_PROFESSIONAL_EXPENSE, downloadProfessionalExpenseSaga),
    ]);
};
