import {all, call, put, select} from 'redux-saga/effects';
// eslint-disable-next-line import/no-cycle
import {OnboardingLoader} from './onboarding-loader.saga';
import {OnboardingActions} from './onboarding.action';
import {OnboardingSelectors} from './onboarding.selectors';
import {getIsRegistrationWithExistingCompany} from '../../../../../utils/holidays';
import {getCompanyRequest} from '../../../../company/modules/documents/api/company.api';
import {FULL_COMPANY_STATUSES} from '../../../../company/modules/setup-company/utils/constants';
import * as actions from '../../../../company/store/company.actions';
import {LoadingActions, LoadingTypes} from '../../../../loading';
import {CompanySetupInternalSubSteps, CompanySetupSubSteps} from '../../company-setup/utils/constants';
import {ContractSigningSubSteps} from '../../contract-signing/utils/constants';
import {OnboardingSteps} from '../utils/onboadingStepsConstant';

const getCompanySaga = function* ({payload}) {
    try {
        yield put(actions.setIsLoadingCompany(true));

        const {companyId, freelancerId} = payload;

        const company = yield call(getCompanyRequest, freelancerId, companyId);

        yield put(actions.storeCompany(company));

        return company;
    } catch (error) {
        // TODO:LOW Better error handling.
        // eslint-disable-next-line no-console
        console.error(error);
    } finally {
        yield put(actions.setIsLoadingCompany(false));
    }
};

const setUserOnWelcomePage = function* ({progress, loggedInUser}) {
    yield put(OnboardingActions.setProgress({
        ...progress,
        [OnboardingSteps.CONTRACT_SIGNING]: {
            ...progress[OnboardingSteps.CONTRACT_SIGNING],
            subStepProgress: {
                [ContractSigningSubSteps.ENTER_PERSONAL_ADDRESS]: {
                    isCompleted: !!loggedInUser?.contactInfo?.address,
                },
                [ContractSigningSubSteps.SIGN_CONTRACT]: {
                    isCompleted: false,
                },
            },
        },
    }));
};

const setUserSignedContractOnContractSigning = function* ({progress}) {
    yield put(OnboardingActions.setProgress({
        ...progress,
        [OnboardingSteps.CONTRACT_SIGNING]: {
            ...progress[OnboardingSteps.CONTRACT_SIGNING],
            isCompleted: true,
            subStepProgress: {
                [ContractSigningSubSteps.ENTER_PERSONAL_ADDRESS]: {
                    isCompleted: true,
                },
                [ContractSigningSubSteps.SIGN_CONTRACT]: {
                    isCompleted: true,
                },
            },
        },
    }));
};

const setUserFinishedContractSigning = function* ({progress}) {
    yield put(OnboardingActions.setProgress({
        ...progress,
        [OnboardingSteps.CONTRACT_SIGNING]: {
            ...progress[OnboardingSteps.CONTRACT_SIGNING],
            isCompleted: true,
        },
    }));
};

const setUserFinishedWorkshop = function* ({progress}) {
    yield put(OnboardingActions.setProgress({
        ...progress,
        [OnboardingSteps.CONTRACT_SIGNING]: {
            ...progress[OnboardingSteps.CONTRACT_SIGNING],
            isCompleted: true,
        },
        [OnboardingSteps.WORKSHOPS]: {
            ...progress[OnboardingSteps.WORKSHOPS],
            isCompleted: true,
        },
    }));
};

const determineCompanySetupSubstep = function* ({progress, company, freelancer, isUserCareRole}) {
    const params = {freelancerId: freelancer.id, companyId: company.id};
    const subStepData = yield all([
        call(OnboardingLoader.loadPersonalInformation, params),
        call(OnboardingLoader.loadAddressInformation, params),
        call(OnboardingLoader.loadNonConvictionInformation, params),
        call(OnboardingLoader.loadCompanyInformation, params),
        call(OnboardingLoader.loadDocumentInformation, params),
        call(OnboardingLoader.loadBankInformation, params),
        call(getCompanySaga, {
            payload: params,
        }),
    ]);

    const [
        personalInformation, // 0
        addressInformation, // 1
        nonConvictionInformation, // 2
        companyInfo, // 3
        documentInfo, // 4
        bankInfo, // 7
        // companyNew, // 5 // TODO Not used?
        // documentsInfo, // 6 // TODO Not used?
    ] = subStepData;

    const hasPersonalInformation = !!personalInformation;
    const hasAddressInformation = !!addressInformation;
    const hasNonConvictionInformation = !!nonConvictionInformation;
    const hasCompanyInfo = !!companyInfo;
    const hasDocumentInfo = !!documentInfo;
    const hasBankInfo = !!bankInfo;

    if (subStepData.some(subStep => !!subStep)) {
        const registrationType = company?.enterpriseInformation?.registration_type;
        const registrationWithExistingCompany = getIsRegistrationWithExistingCompany(registrationType);
        progress = yield select(OnboardingSelectors.selectProgress);
        yield put(OnboardingActions.setProgress({
            ...progress,
            [OnboardingSteps.CONTRACT_SIGNING]: {
                ...progress[OnboardingSteps.CONTRACT_SIGNING],
                isCompleted: true,
            },
            [OnboardingSteps.WORKSHOPS]: {
                ...progress[OnboardingSteps.WORKSHOPS],
                isCompleted: true,
            },
            [OnboardingSteps.COMPANY_SETUP]: {
                ...progress[OnboardingSteps.COMPANY_SETUP],
                isCompleted: company.status === FULL_COMPANY_STATUSES.DATA_VALIDATED
                    || company.status === FULL_COMPANY_STATUSES.ACTIVE,
                subStepProgress: {
                    ...progress[OnboardingSteps.COMPANY_SETUP].subStepProgress,
                    [CompanySetupSubSteps.PERSONAL_INFORMATION]: {
                        isCompleted: hasPersonalInformation,
                    },
                    [CompanySetupSubSteps.ADDRESS_INFORMATION]: {
                        isCompleted: hasAddressInformation && hasPersonalInformation,
                    },
                    [CompanySetupSubSteps.DECLARATION_OF_NON_CONVICTION]: {
                        isCompleted: hasNonConvictionInformation && hasAddressInformation,
                    },
                    [CompanySetupSubSteps.COMPANY_INFORMATION]: {
                        // If it has company info (bank name + professional activity)
                        // and if its existing company and has address information
                        // and not if not check if it is care or it has non conviction information (for some reason)
                        isCompleted: hasCompanyInfo
                            && (registrationWithExistingCompany
                                ? hasAddressInformation
                                : (isUserCareRole || hasNonConvictionInformation)
                            ),
                    },
                    [CompanySetupSubSteps.BANK]: {
                        isCompleted: (hasBankInfo || company?.hiway_bank_info)
                            && (registrationWithExistingCompany ? hasAddressInformation : hasBankInfo),
                    },
                    [CompanySetupSubSteps.DOCUMENT_UPLOAD]: {
                        isCompleted: hasDocumentInfo && hasCompanyInfo,
                    },
                    [CompanySetupSubSteps.DOCUMENT_GENERATION]: {
                        isCompleted: company.status === FULL_COMPANY_STATUSES.DATA_VALIDATED,
                    },
                },
            },
        }));

        if (registrationWithExistingCompany) {
            yield put(OnboardingActions.setStep(OnboardingSteps.COMPANY_SETUP));
            yield put(OnboardingActions.setSubStep(null));
            yield put(OnboardingActions.setInternalSubStep(
                null,
            ));

            yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, false));
            return;
        }

        if (company?.status === FULL_COMPANY_STATUSES.PENDING_EDITS) {
            yield all([
                put(OnboardingActions.setStep(OnboardingSteps.COMPANY_SETUP)),
                put(OnboardingActions.setInternalSubStep(null)),
                put(OnboardingActions.setSubStep(null)),
            ]);
            return;
        }

        // TODO Check whether this should be called always
        if (company?.status === FULL_COMPANY_STATUSES.PENDING_DATA_INPUT) {
            if (hasBankInfo || company?.hiway_bank_info) {
                yield all([
                    put(OnboardingActions.setStep(OnboardingSteps.COMPANY_SETUP)),
                    put(OnboardingActions.setInternalSubStep(null)),
                    put(OnboardingActions.setSubStep(CompanySetupSubSteps.DOCUMENT_UPLOAD)),
                ]);
            } else {
                // TODO this could be improved to go to precise step based on completion system from above
                yield all([
                    put(OnboardingActions.setStep(OnboardingSteps.COMPANY_SETUP)),
                    put(OnboardingActions.setInternalSubStep(null)),
                    put(OnboardingActions.setSubStep(null)),
                ]);
            }
            return;
        }

        if (company?.status === FULL_COMPANY_STATUSES.PENDING_INIT_SIGS) {
            yield call(setCompanyPendingInitSigsProgress);
            return;
        } else if (company?.status === FULL_COMPANY_STATUSES.READY_FOR_REVIEW) {
            yield call(setCompanyReadyForReviewProgress);
            return;
        } else if (company?.status === FULL_COMPANY_STATUSES.DATA_VALIDATED) {
            yield call(setCompanyDataValidatedProgress, {progress});
            return;
        }

        // If it has company info, but not uploaded documents
        if (
            (hasBankInfo || company?.hiway_bank_info)
            && !(hasDocumentInfo && hasCompanyInfo)
        ) {
            yield put(OnboardingActions.setSubStep(CompanySetupSubSteps.DOCUMENT_UPLOAD));
            yield put(OnboardingActions.setInternalSubStep(
                CompanySetupInternalSubSteps.DATA_VALIDATED,
            ));

            yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, false));
        }
    }
};

const setCompanyPendingInitSigsProgress = function* () {
    yield all([
        put(OnboardingActions.setStep(OnboardingSteps.COMPANY_SETUP)),
        put(OnboardingActions.setSubStep(CompanySetupSubSteps.DOCUMENT_GENERATION)),
    ]);
};

const setCompanyReadyForReviewProgress = function* () {
    yield all([
        put(OnboardingActions.setStep(OnboardingSteps.COMPANY_SETUP)),
        put(OnboardingActions.setSubStep(CompanySetupSubSteps.DOCUMENT_GENERATION)),
        put(OnboardingActions.setInternalSubStep(
            CompanySetupInternalSubSteps.AWAITING_DOCUMENT_VERIFICATION,
        )),
    ]);
};

const setCompanyDataValidatedProgress = function* ({progress}) {
    yield put(OnboardingActions.setProgress({
        ...progress,
        [OnboardingSteps.WORKSHOPS]: {
            ...progress[OnboardingSteps.WORKSHOPS],
            isCompleted: true,
        },
        [OnboardingSteps.COMPANY_SETUP]: {
            ...progress[OnboardingSteps.COMPANY_SETUP],
            isCompleted: true,
        },
    }));

    yield all([
        put(OnboardingActions.setStep(OnboardingSteps.COMPANY_SETUP)),
        put(OnboardingActions.setSubStep(CompanySetupSubSteps.DATA_VALIDATED)),
        put(OnboardingActions.setInternalSubStep(CompanySetupInternalSubSteps.DATA_VALIDATED)),
    ]);
};

export const OnboardingProgress = {
    setUserOnWelcomePage,
    setUserSignedContractOnContractSigning,
    setUserFinishedContractSigning,
    setUserFinishedWorkshop,

    determineCompanySetupSubstep,
};
