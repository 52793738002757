import GetAppIcon from '@mui/icons-material/GetApp';
import LaunchIcon from '@mui/icons-material/Launch';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import DataTableRowActions from '../../../../components/ui-kit/DataTableRowActions/DataTableRowActions';
import {DOCUMENT_STATUSES, DOCUMENT_TYPES} from '../../../../consts/document-constants';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {getCompanyDocument} from '../../../company/store/company.actions';

const getMainActionsConfig = (
    translate,
    dispatch,
    rowData,
) => ([
    {
        title: translate('documentsDatabaseTable.actionButtons.viewDocument'),
        icon: <LaunchIcon />,
        disabled: rowData.status === DOCUMENT_STATUSES.GENERATING,
        buttonProps: {
            onClick: () => {
                dispatch(getCompanyDocument(rowData.freelancerId, rowData.companyId, rowData.id));
            },
        },
    },
    {
        title: translate('documentsDatabaseTable.actionButtons.downloadDocument'),
        icon: <GetAppIcon />,
        disabled: rowData.status === DOCUMENT_STATUSES.GENERATING,
        buttonProps: {
            onClick: () => {
                dispatch(getCompanyDocument(rowData.freelancerId, rowData.companyId, rowData.id, true));
            },
        },
    },
]);

const DocumentRowActions = ({rowData}) => {
    const {t} = useTranslation(LocalesConstants.Documents);

    const dispatch = useDispatch();

    const config = useMemo(() => {
        return getMainActionsConfig(
            t,
            dispatch,
            rowData,
        );
    }, [
        dispatch,
        rowData,
        t,
    ]);

    return (
        <>
            <DataTableRowActions config={config} breakdown="xs" />
        </>
    );
};

DocumentRowActions.propTypes = {
    rowData: PropTypes.shape({
        id: PropTypes.string.isRequired,
        fileName: PropTypes.string.isRequired,
        docName: PropTypes.string.isRequired,
        subType: PropTypes.string,
        category: PropTypes.string.isRequired,
        uploaderId: PropTypes.string,
        freelancerId: PropTypes.string.isRequired,
        companyId: PropTypes.string.isRequired,
        type: PropTypes.oneOf(Object.values(DOCUMENT_TYPES)).isRequired,
    }).isRequired,
};

export default DocumentRowActions;
