import {
    faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Stack} from '@mui/joy';
import {Box as MuiBox, Paper} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import TransactionDetailsCloseSection
    from '../../features/bank/modules/account-balance/components/transaction-details/TransactionDetailsCloseSection';
import {LocalesConstants} from '../../utils/locales-constants';
import CloseButton from '../buttons/CloseButton';

let timeout;
const ExpandableSidebar = ({
    isMobileSize,
    onClose,
    isOpen,
    mt,
    hasClose,
    children,
    width,
    scrollHeader,
    closeText,
    isV3,
}) => {
    const {t} = useTranslation(LocalesConstants.Bank);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        timeout = setTimeout(() => {
            setIsExpanded(isOpen);
        }, 20);
        return () => {
            clearTimeout(timeout);
        };
    }, [isOpen]);

    if (isV3) {
        return (
            <Box sx={{
                width: isExpanded ? (isMobileSize ? '100%' : width) : '1px',
                transition: 'width 0.4s ease-in-out',
                mt,
                position: !isMobileSize ? 'fixed' : 'inherit',
                right: 0,
                zIndex: 20,
            }}
            >
                <Stack
                    direction="column"
                    sx={{
                        display: !isMobileSize && !isOpen ? 'none' : 'flex',
                    }}
                >
                    {isExpanded && hasClose && !isMobileSize && (
                        <CloseButton
                            onClose={onClose}
                            buttonStyles={{
                                position: 'absolute',
                                top: '16px',
                                right: '16px',
                                zIndex: 1,
                            }}
                            isV3
                        />
                    )}
                    {isExpanded && hasClose && isMobileSize && (
                        <Button
                            color="neutral"
                            variant="plain"
                            size="sm"
                            startDecorator={(
                                <FontAwesomeIcon size="md" icon={faArrowLeft} />
                            )}
                            onClick={onClose}
                            sx={{
                                justifyContent: 'flex-start',
                                height: '48px',
                                color: 'primary.solidColor',
                                backgroundColor: 'background.cocoa',
                                borderRadius: '0px',
                                fontStyle: 'normal',
                                fontWeight: 'md',
                            }}
                        >
                            {closeText ?? t('translationDetails.account')}
                        </Button>
                    )}

                    {scrollHeader}

                    <Box
                        sx={{
                            height: !isMobileSize ? '100vh' : '100%',
                            width: isMobileSize ? '100%' : width,
                            backgroundColor: 'background.body',
                            boxShadow: 'xl',
                        }}
                    >
                        <Box
                            id="expandable-sidebar-details"
                            sx={{
                                overflowY: 'auto',
                                height: isMobileSize ? 'calc(100vh - 118px)' : '100vh',
                            }}
                        >
                            {children}
                        </Box>
                    </Box>
                </Stack>
            </Box>
        );
    }

    return (
        <MuiBox sx={{
            width: isExpanded ? (isMobileSize ? '100%' : width) : '1px',
            transition: 'width 0.4s ease-in-out',
            mt,
            position: !isMobileSize ? 'fixed' : 'inherit',
            right: 0,
            zIndex: 20,
        }}
        >
            <MuiBox
                sx={isMobileSize ? {display: 'flex', flexDirection: 'column'}
                    : {
                        display: isOpen ? 'flex' : 'none',
                        flexDirection: 'column',
                        boxShadow: isOpen
                            ? '0px 11px 15px -7px rgba(11, 35, 51, 0.08), 0px 24px 38px 3px rgba(11, 35, 51, 0.08), 0px 9px 46px 8px rgba(11, 35, 51, 0.1);'
                            : 'none',
                    }}
            >
                {isExpanded && hasClose && (
                <TransactionDetailsCloseSection
                    isMobileSize={isMobileSize}
                    onClose={onClose}
                    isOpen={!!isOpen}
                    closeText={closeText}
                />
                )}

                {scrollHeader}

                <Paper
                    elevation={1}
                    sx={{
                        height: !isMobileSize ? '100vh' : '100%',
                        width: isMobileSize ? '100%' : width,
                    }}
                >
                    <MuiBox
                        id="expandable-sidebar-details"
                        sx={{
                            overflowY: 'auto',
                            height: isMobileSize ? 'calc(100vh - 118px)' : '100vh',
                        }}
                    >
                        {children}
                    </MuiBox>
                </Paper>
            </MuiBox>
        </MuiBox>
    );
};

ExpandableSidebar.propTypes = {
    isMobileSize: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    hasClose: PropTypes.bool,
    mt: PropTypes.number,
    width: PropTypes.string,
    scrollHeader: PropTypes.node,
    closeText: PropTypes.string,
    isV3: PropTypes.bool, // TODO: make true by default and delete the code, related to false value
};

ExpandableSidebar.defaultProps = {
    hasClose: true,
    width: '400px',
    isOpen: false,
    scrollHeader: null,
    closeText: '',
    mt: 0,
    isV3: false,
};

export default ExpandableSidebar;
