import {
    faDownload,
    faFilter,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FilterListIcon from '@mui/icons-material/FilterList';
import GetAppIcon from '@mui/icons-material/GetApp';
import {
    Badge,
    Box,
    Button,
    IconButton,
    Typography,
} from '@mui/joy';
import {
    Badge as MuiBadge,
    Box as MuiBox,
    IconButton as MuiIconButton,
    Typography as MuiTypography,
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {useDataTableHeaderStyles} from './styles';
import {requiredIf} from '../../../utils/conditional-prop-type';
import {LocalesConstants} from '../../../utils/locales-constants';
import ButtonWithCircularProgress from '../ButtonWithCircularProgress';

const DataTableHeader = props => {
    const {
        hasColumnFilters,
        onColumnFilterToggleClick,
        children,
        hasDownloadAsZipButton,
        isDownloadAsZipButtonDisabled,
        isDownloadingAsZip,
        onDownloadAsZipButtonClick,
        additionalFilters,
        hasSearch,
        hasCheckboxes,
        areColumnFiltersOpen,
        title,
        titleCount,
        isV3,
    } = props;

    const styles = useDataTableHeaderStyles();
    const {t} = useTranslation(LocalesConstants.Invoicing);

    const handleColumnFilterToggleClick = () => {
        onColumnFilterToggleClick();
    };

    const hasFilters = hasDownloadAsZipButton || !!additionalFilters || hasColumnFilters;

    return isV3 ? (
        <Box
            component="section"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                pt: 3,
                pr: hasCheckboxes ? 3 : 2,
                pb: 0,
                pl: 2,
            }}
        >
            {title && (
                <Typography
                    level="title-lg"
                    sx={{color: 'text.secondary'}}
                >
                    {title}
                    {titleCount !== 0 && (
                        <Badge
                            badgeContent={titleCount}
                            sx={{
                                color: 'white !important',
                                ml: 2.5,
                                fontWeight: 'md',
                            }}
                        />

                    )}
                </Typography>
            )}

            {hasSearch && (
                <Box sx={{
                    width: hasFilters ? '25%' : '40%',
                    textAlign: title ? 'right' : 'left',
                }}
                >
                    {children}
                </Box>
            )}

            {hasFilters && (
                <Box
                    component="div"
                    sx={{
                        display: 'grid',
                        alignItems: 'center',
                        alignContent: 'center',
                        gridAutoFlow: 'column',
                        gridColumnGap: 4,
                    }}
                >
                    {hasDownloadAsZipButton && (
                        <Button
                            variant="solid"
                            size="sm"
                            onClick={onDownloadAsZipButtonClick}
                            startDecorator={(
                                <FontAwesomeIcon icon={faDownload} fixedWidth />
                            )}
                            disabled={isDownloadingAsZip || isDownloadAsZipButtonDisabled}
                            loadingPosition="start"
                            loading={isDownloadingAsZip}
                        >
                            {t('common:downloadAsZipButtonLabel')}
                        </Button>
                    )}

                    {!!additionalFilters && additionalFilters}

                    {hasColumnFilters && (
                        <IconButton
                            aria-label="display-filters"
                            onClick={handleColumnFilterToggleClick}
                            size="lg"
                            color={areColumnFiltersOpen ? 'primary' : 'neutral'}
                        >
                            <FontAwesomeIcon icon={faFilter} fixedWidth />
                        </IconButton>
                    )}
                </Box>
            )}
        </Box>
    ) : (
        <section
            className={clsx(styles.root, {
                [styles.withCheckboxes]: hasCheckboxes,
            })}
        >
            {title && (
                <MuiTypography className={styles.title}>
                    {title}
                    {titleCount !== 0 && (
                        <MuiBadge
                            badgeContent={titleCount}
                            color="v2Grey300Palette"
                            sx={{
                                color: 'white !important',
                                ml: 2.5,
                                fontWeight: 500,
                            }}
                        />

                    )}
                </MuiTypography>
            )}

            {hasSearch && (
                <MuiBox sx={{
                    width: hasFilters ? '25%' : '40%',
                    textAlign: title ? 'right' : 'left',
                }}
                >
                    {children}
                </MuiBox>
            )}

            {hasFilters && (
                <div className={styles.actionContainer}>
                    {hasDownloadAsZipButton && (
                        <ButtonWithCircularProgress
                            buttonProps={{
                                variant: 'contained',
                                size: 'small',
                                onClick: onDownloadAsZipButtonClick,
                                startIcon: <GetAppIcon />,
                                disabled: isDownloadingAsZip || isDownloadAsZipButtonDisabled,
                            }}
                            isLoading={isDownloadingAsZip}
                        >
                            {t('common:downloadAsZipButtonLabel')}
                        </ButtonWithCircularProgress>
                    )}

                    {!!additionalFilters && additionalFilters}

                    {hasColumnFilters && (
                        <MuiIconButton
                            aria-label="display-filters"
                            onClick={handleColumnFilterToggleClick}
                            size="large"
                        >
                            <FilterListIcon htmlColor={areColumnFiltersOpen ? '#3779BE' : undefined} />
                        </MuiIconButton>
                    )}
                </div>
            )}
        </section>
    );
};

DataTableHeader.propTypes = {
    hasColumnFilters: PropTypes.bool,
    hasDownloadAsZipButton: PropTypes.bool,
    isDownloadAsZipButtonDisabled: PropTypes.bool,
    isDownloadingAsZip: PropTypes.bool,
    hasSearch: PropTypes.bool,
    hasCheckboxes: PropTypes.bool,
    areColumnFiltersOpen: PropTypes.bool,
    additionalFilters: PropTypes.node,
    // eslint-disable-next-line react/require-default-props
    onColumnFilterToggleClick: requiredIf(
        props => props.hasColumnFilters && typeof props.onColumnFilterToggleClick !== 'function',
        '"onColumnFilterToggleClick" must be a function if "hasColumnFilters" is true"',
    ),
    // eslint-disable-next-line react/require-default-props
    onDownloadAsZipButtonClick: requiredIf(
        props => props.hasDownloadAsZipButton && typeof props.onDownloadAsZipButtonClick !== 'function',
        '"onDownloadAsZipButtonClick" must be a function if "hasDownloadAsZipButton" is true"',
    ),
    title: PropTypes.string.isRequired,
    titleCount: PropTypes.number.isRequired,
    isV3: PropTypes.bool,
};

DataTableHeader.defaultProps = {
    hasColumnFilters: false,
    hasDownloadAsZipButton: false,
    isDownloadAsZipButtonDisabled: true,
    isDownloadingAsZip: false,
    hasCheckboxes: false,
    areColumnFiltersOpen: false,
    hasSearch: false,
    additionalFilters: null,
    isV3: false,
};

export default DataTableHeader;
