import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Slide, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import CapitalDepositCompletedImage from '../../../../../assets/svg/bank/capital-deposit-completed.svg';
import BankInformationLayout from '../../../../../layout/BankInformationLayout/BankInformationLayout';
import {LocalesConstants} from '../../../../../utils/locales-constants';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../../animations/utils/constants';
import {BankSelector} from '../../../../bank/store/bank.selector';
import {
    capitalDepositGenerateSecondBatchDocuments,
} from '../../../../company/modules/setup-company/store/setup-company.actions';
import {CapitalDepositStatus} from '../../capital-deposit/utils/constants';

const CapitalDepositCompleted = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation(LocalesConstants.FreelancerOnboarding);

    const capitalDeposit = useSelector(BankSelector.selectCapitalDeposit);
    const isCapitalDepositCompleted = capitalDeposit?.status === CapitalDepositStatus.COMPLETED;

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const onNextClick = () => {
        dispatch(capitalDepositGenerateSecondBatchDocuments());
    };

    return (
        <BankInformationLayout
            containerMaxWidth="720px"
            header={t('capitalDepositCompleted.header')}
            subTitle={t('capitalDepositCompleted.subtitle')}
            Button={(
                <Button
                    color="secondary"
                    variant="contained"
                    disabled={!isCapitalDepositCompleted}
                    endIcon={<ArrowForwardIcon />}
                    onClick={onNextClick}
                >
                    {t('capitalDepositCompleted.continue')}
                </Button>
            )}
        >
            <Slide in={isAnimationActive} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                >
                    <Box>
                        <img src={CapitalDepositCompletedImage} alt="pending" />
                    </Box>
                    <Typography sx={{my: 2, maxWidth: '555px'}} variant="h4">{t('capitalDepositCompleted.capitalDepositCompletedText1')}</Typography>
                    <Typography sx={{
                        maxWidth: '485px',
                        color: 'text_v2.secondary',
                    }}
                    >
                        {t('capitalDepositCompleted.capitalDepositCompletedText2')}
                    </Typography>
                </Box>
            </Slide>
        </BankInformationLayout>
    );
};

export default CapitalDepositCompleted;
