import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import * as actions from './contract.actions';
import {getContractDataRequest} from '../../../../app/api/providers/freelancer/freelancer.provider';
import {Toast} from '../../../../lib/toast';
import {USER_ROLES} from '../../../../utils/user-roles';
import {selectCurrentCognitoUser} from '../../../auth/store/auth.selectors';
import {getAndStoreCurrentUserInfo} from '../../../auth/store/getAndStoreCurrentUserInfo';

const getContractDataSaga = function* ({payload}) {
    try {
        yield put(actions.setIsLoadingContractData(true));

        const contractData = yield call(getContractDataRequest, payload.freelancerId);

        yield put(actions.storeContractDocumentId(contractData.documentId ?? null));
        yield put(actions.storeContractDocumentUrl(contractData.signedUrl ?? null));
        yield put(actions.storeContractSignatureDate(contractData.signatureDate ?? null));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        // covers a case not to throw error
        // for users who don't own contract yet
        if (error?.response?.data?.status === 404) {
            yield put(actions.storeContractDocumentId(null));
            yield put(actions.storeContractDocumentUrl(null));
            yield put(actions.storeContractSignatureDate(null));
        } else {
            Toast.error('anErrorOccurred');
        }
    } finally {
        yield put(actions.setIsLoadingContractData(false));
    }
};

export const myProfileContractDataLoader = function* () {
    let currentCognitoUser = yield select(selectCurrentCognitoUser);

    if (!currentCognitoUser) {
        yield call(getAndStoreCurrentUserInfo);
    }

    currentCognitoUser = yield select(selectCurrentCognitoUser);

    if (currentCognitoUser.role !== USER_ROLES.FREELANCER) {
        return;
    }

    yield put(actions.getContractData(currentCognitoUser.id));
};

export const watchContractDataSagas = function* () {
    yield all([
        takeLatest(actions.GET_CONTRACT_DATA, getContractDataSaga),
    ]);
};
