export const LocalesConstants = {
    Aria: 'aria',
    Backoffice: 'backoffice',
    Bank: 'bank',
    BankCards: 'bankCards',
    BankRibAndDocuments: 'bankRibAndDocuments',
    BankTransfer: 'bankTransfer',
    Breadcrumbs: 'breadcrumbs',
    Common: 'common',
    Companies: 'companies',
    CreateUser: 'createUser',
    Dashboard: 'dashboard',
    DashboardV3: 'dashboardV3',
    Document: 'document',
    Documentation: 'documentation',
    Documents: 'documents',
    Dropzone: 'dropzone',
    Expenses: 'expenses',
    FinancialStatements: 'financialStatements',
    Form: 'form',
    Formalities: 'formalities',
    FreelancerOnboarding: 'freelancerOnboarding',
    Insurance: 'insurance',
    Invoicing: 'invoicing',
    InvoicingNew: 'invoicingNew',
    JobProfessions: 'jobProfessions',
    Login: 'login',
    Notifications: 'notifications',
    Opportunity: 'opportunity',
    RevenueSimulator: 'revenueSimulator',
    Setup: 'setup',
    Sidebar: 'sidebar',
    Signature: 'signature',
    Training: 'training',
    Users: 'users',
    VatDeclaration: 'vatDeclaration',
    WealthManagement: 'wealthManagement',
    Missions: 'missions',
    Datepickers: 'datepickers',
    Client: 'client',
};
