import {MissionsActionType} from './missions.action-type';

export const MissionsActions = {
    getMission: id => ({
        type: MissionsActionType.GET_MISSION,
        payload: id,
    }),
    getMissionList: userId => ({
        type: MissionsActionType.GET_MISSION_LIST,
        payload: userId ?? null,
    }),
    createMission: data => ({
        type: MissionsActionType.CREATE_MISSION,
        payload: data,
    }),
    storeMission: mission => ({
        type: MissionsActionType.STORE_MISSION,
        payload: mission,
    }),
    storeMissionList: missionList => ({
        type: MissionsActionType.STORE_MISSION_LIST,
        payload: missionList,
    }),
    editMission: ({
        userId,
        missionId,
        data,
    }) => ({
        type: MissionsActionType.PUT_EDIT_MISSION,
        payload: {
            userId,
            missionId,
            data,
        },
    }),
    deactivateMission: ({
        userId,
        missionId,
        mission,
    }) => ({
        type: MissionsActionType.DEACTIVATE_MISSION,
        payload: {
            userId,
            missionId,
            mission,
        },
    }),
    storeMissionInProgress: mission => ({
        type: MissionsActionType.STORE_MISSION_IN_PROGRESS,
        payload: mission,
    }),
    changeMissionInCreationScreen: screen => ({
        type: MissionsActionType.STORE_MISSION_SCREEN,
        payload: screen,
    }),
    getMissionDetails: data => ({
        type: MissionsActionType.GET_MISSION_DETAILS,
        payload: {
            missionId: data?.missionId,
            userId: data?.userId,
        },
    }),
    storeMissionDetails: data => ({
        type: MissionsActionType.STORE_MISSION_DETAILS,
        payload: data,
    }),
    postServiceAgreement: data => ({
        type: MissionsActionType.POST_SERVICE_AGREEMENT,
        payload: data,
    }),
    postWorkContract: data => ({
        type: MissionsActionType.POST_WORK_CONTRACT,
        payload: data,
    }),
};
