import {call, fork, put, select} from 'redux-saga/effects';
import {setIsLoadingApp} from './app.actions';
import {selectCurrentCognitoUser} from '../../features/auth/store/auth.selectors';
import {getAndStoreCurrentUserInfo} from '../../features/auth/store/getAndStoreCurrentUserInfo';
import {loadFreelancerAccountSaga} from '../../features/freelancer/store/freelancer.sagas';
import {selectFreelancerCompanies} from '../../features/freelancer/store/freelancer.selectors';
import {SettingsActions} from '../../features/settings/store/settings.actions';
import {SettingsSelectors} from '../../features/settings/store/settings.selectors';
import {TermsApi} from '../../features/terms/api/terms.api';
import {UiActions} from '../../features/ui/store/ui.action';
import {ModalsKeys} from '../../features/ui/utils/constants';
import {LoggedInUserSelectors} from '../../features/user/modules/logged-in-user';
import {USER_STATUSES} from '../../features/user/utils/constants';
import {isUserAdmin, isUserAdminFn, isUserUmbrellaFn} from '../../utils/user-roles';

const loadUserSettings = function* () {
    const settings = yield select(SettingsSelectors.selectSettings);

    if (!settings || typeof settings.shouldReceiveOpportunityNotifications === 'undefined') {
        yield put(SettingsActions.getUserSettings());
    } else {
        yield put(SettingsActions.setIsLoadingUserSettings(false));
    }
};

// TODO It is not used?
// const loadAdditionalDataForAdministrators = function* (freelancerId, companyId) {
//     if (freelancerId) {
//         yield put(getUser(freelancerId));
//
//         if (companyId) {
//             yield put(getCompany(freelancerId, companyId));
//         }
//     }
//
//     const isLoadingApp = yield select(selectIsLoadingApp);
//
//     if (isLoadingApp) {
//         yield put(setIsLoadingApp(false));
//     }
// };

const checkRequiredConsents = function* () {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
    if (
        !isUserAdmin(loggedInUser)
        && loggedInUser.status !== USER_STATUSES.CONTRACT_PENDING
        && loggedInUser.status !== USER_STATUSES.INVITED
    ) {
        const response = yield call(TermsApi.getRequiredConsents, loggedInUser.id);
        const cgvsItem = response?.find?.(item => item.type === 'CGVS');
        if (cgvsItem) {
            yield put(UiActions.setModalData(ModalsKeys.UPDATED_TERMS_CONSENT_MODAL, cgvsItem));
            yield put(UiActions.setActiveModal(ModalsKeys.UPDATED_TERMS_CONSENT_MODAL, true));
        }
    }
};

export const appLoaderSaga = function* () {
    let currentCognitoUser = yield select(selectCurrentCognitoUser);

    if (!currentCognitoUser) {
        yield call(getAndStoreCurrentUserInfo);
    }

    currentCognitoUser = yield select(selectCurrentCognitoUser);

    yield call(loadUserSettings);
    yield fork(checkRequiredConsents);

    if (isUserAdminFn(currentCognitoUser)) {
        return;
    }

    yield call(loadFreelancerAccountSaga, currentCognitoUser.id);

    if (isUserUmbrellaFn(currentCognitoUser)) {
        return;
    }

    const companies = yield select(selectFreelancerCompanies);

    if (!companies || !Object.keys(companies).length) {
        yield put(setIsLoadingApp(false));

        return;
    }

    yield put(setIsLoadingApp(false));
};
