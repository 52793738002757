import {faFileInvoice} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Stack, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {flexCenter} from '../../../utils/styles';

export const InfoSheet = ({icon, title, titleProps, description, cta, descriptionProps, sx, isMobileSize}) => {
    return (
        <Box
            sx={{
                borderRadius: 'lg',
                bgcolor: 'background.surface',
                border: '1px solid',
                borderColor: 'neutral.outlinedBorder',
                p: 2,
                display: 'flex',
                alignItems: {mobile: 'flex-start', desktop: 'center'},
                flexDirection: {mobile: 'column', desktop: 'row'},
                gap: 2,
                textWrap: 'pretty',
                ...sx,
            }}
        >
            <Stack direction="row" gap={2} flexGrow={1}>
                <Box
                    sx={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        bgcolor: 'background.body',
                        border: '1px solid',
                        borderColor: 'neutral.outlinedBorder',
                        color: 'success.outlinedColor',
                        ...flexCenter,
                    }}
                >
                    <FontAwesomeIcon icon={icon} />
                </Box>
                <Stack direction="column" gap={0.5} flexGrow={1}>
                    <Typography
                        level="title-sm"
                        textColor="success.outlinedColor"
                        {...titleProps}
                    >
                        {title}
                    </Typography>
                    <Typography level="body-xs" textColor="text.tertiary" {...descriptionProps}>
                        {description}
                    </Typography>
                </Stack>
            </Stack>
            {cta}
        </Box>
    );
};

InfoSheet.propTypes = {
    icon: PropTypes.any,
    title: PropTypes.node,
    titleProps: PropTypes.object,
    description: PropTypes.node,
    descriptionProps: PropTypes.object,
    cta: PropTypes.node,
    sx: PropTypes.object,
    isMobileSize: PropTypes.bool,
};
InfoSheet.defaultProps = {
    icon: faFileInvoice,
    title: '',
    titleProps: {},
    description: '',
    descriptionProps: {},
    cta: null,
    sx: {},
    isMobileSize: false,
};
