import {
    faArrowLeft,
    faArrowRight,
    faSignature,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '@mui/joy';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import JoyUIThemeProvider from '../../../../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {useIsMobileSize} from '../../../../../hooks/use-is-mobile-size';
import {LocalesConstants} from '../../../../../utils/locales-constants';
import {DocumentSigningWrapperV3} from '../../../../document/modules/signing/components/v3/DocumentSigningWrapperV3';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';
import {UiSelectors} from '../../../../ui/store/ui.selector';
import {SidebarDrawerKeys} from '../../../../ui/utils/constants';
import {MissionsSelector} from '../../../store/missions.selector';
import {MISSION_CRA_SCREENS} from '../../../utils/constants';
import {MissionsCraActions} from '../store/missions-cra.action';
import {MissionsCraSelector} from '../store/missions-cra.selector';

const drawerKey = SidebarDrawerKeys.BOTTOM_DRAWER;

const MainActionComponent = () => {
    const dispatch = useDispatch();

    const {t} = useTranslation(LocalesConstants.Document);
    const isMobileSize = useIsMobileSize();

    const isDrawerOpen = useSelector(UiSelectors.createIsSidebarDrawerActiveSelector(drawerKey));

    const mission = useSelector(MissionsSelector.selectMission);
    const contract = useSelector(MissionsCraSelector.selectContract);

    const isSigning = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.SIGN_CONTRACT),
    );

    const isContractGenerated = contract?.id;
    const isSigned = contract?.isSigned;

    const onNextClick = () => {
        dispatch(MissionsCraActions.postSendCraContract({
            documentId: contract?.id,
            missionId: mission?.id,
        }));
    };

    const onBackClick = () => {
        dispatch(MissionsCraActions.changeMissionCRAScreen(MISSION_CRA_SCREENS.RE_CREATE_FORM));
    };

    const onSignClick = () => {
        dispatch(MissionsCraActions.postSignCraContract({
            missionId: mission?.id,
            documentId: contract?.id,
        }));
    };

    const isExpanded = isDrawerOpen || !isMobileSize;


    return (
        <>
            <Button
                sx={{my: {desktop: 2, mobile: 0}}}
                startDecorator={isSigned ? null : <FontAwesomeIcon icon={faSignature} />}
                endDecorator={isSigned ? <FontAwesomeIcon icon={faArrowRight} /> : null}
                color={isSigned ? 'success' : 'primary'}
                loading={isSigning}
                disabled={!isContractGenerated}
                onClick={isSigned ? onNextClick : onSignClick}
                fullWidth
            >
                {isSigned ? t('userOrder.continue') : t('userOrder.signCRA')}
            </Button>

            {!isSigned && isExpanded && (
                <Button
                    startDecorator={<FontAwesomeIcon icon={faArrowLeft} />}
                    variant="outlined"
                    color="neutral"
                    disabled={isSigning}
                    onClick={onBackClick}
                    fullWidth
                >
                    {t('userOrder.backCRA')}
                </Button>
            )}
        </>
    );
};

const CraSigning = () => {
    const {t} = useTranslation(LocalesConstants.Missions);

    return (
        <JoyUIThemeProvider>
            <DocumentSigningWrapperV3
                signingTitle={t('cra.title')}
                hasMultipleDocuments={false}
                isShowingSignatureBox
                MainActionComponent={MainActionComponent}
                signedLocale="signing.contractSignedPolite"
            />
        </JoyUIThemeProvider>
    );
};


export default CraSigning;
