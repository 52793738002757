export const CONFIG = {
    API: {
        HIWAY_API_BASE_URL: process.env.REACT_APP_BASE_API_URL,
        BANK_API_BASE_URL: process.env.REACT_APP_BANK_API_BASE_URL,
        INVOICE_API_BASE_URL: process.env.REACT_APP_INVOICE_API_BASE_URL,
        CORE_API_BASE_URL: process.env.REACT_APP_HIWAY_CORE_BASE_URL,
    },
    NODE_ENVIRONMENT: process.env.NODE_ENV,
    FR_API_BASE_URL: process.env.REACT_APP_FR_API_BASE_URL,
    GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
    WEB_BASE_URL: process.env.REACT_APP_WEB_BASE_URL,
    CRISP_ID: process.env.REACT_APP_CRISP_ID,
    AWS_AMPLIFY: {
        COGNITO: {
            REGION: process.env.REACT_APP_AWS_REGION,
            USER_POOL_ID: process.env.REACT_APP_AWS_USER_POOL_ID,
            CLIENT_ID: process.env.REACT_APP_APP_CLIENT_ID,
        },
    },
    DOCUMENTATION: {
        API_BASE_URL: process.env.REACT_APP_WP_API_BASE_URL,
        USERNAME: process.env.REACT_APP_WP_USERNAME,
        PASSWORD: process.env.REACT_APP_WP_PASSWORD,
    },
    NO_LOGGING: process.env.REACT_APP_NO_LOGGING,
};
