import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {Box, Button, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Brochure from '../../../assets/pdf/brochure-rc-prestataires-de-services.pdf';
import {LocalesConstants} from '../../../utils/locales-constants';

const BrochureCard = () => {
    const {t} = useTranslation(LocalesConstants.Insurance);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: isMobileSize ? 'column' : 'row',
            gap: isMobileSize ? 3 : null,
            padding: 2.5,
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: 'stretch',
            borderRadius: isMobileSize ? 4 : 2,
            backgroundImage: isMobileSize ? null : 'linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%)',
            backgroundColor: isMobileSize ? 'whitePallete.main' : 'primary_v2.main',
        }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 1,
                flex: '1 0 0',
            }}
            >
                <Typography sx={{fontSize: theme => theme.typography.pxToRem(21), fontWeight: 700}}>
                    {t(isMobileSize ? 'brochureCard.mobileTitle' : 'brochureCard.title')}
                </Typography>
                <Typography sx={{fontSize: theme => theme.typography.pxToRem(14)}}>
                    {t('brochureCard.subtitle')}
                </Typography>
            </Box>
            <Button
                href={Brochure}
                download
                fullWidth={isMobileSize}
                variant="contained"
                size="medium"
                startIcon={<PictureAsPdfIcon />}
                sx={{height: '36px'}}
            >
                {t('brochureCard.download')}
            </Button>
        </Box>
    );
};

export default BrochureCard;
