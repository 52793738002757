import '@react-pdf-viewer/core/lib/styles/index.css';
import {Box, GlobalStyles, Skeleton, Stack} from '@mui/joy';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import {useSelector} from 'react-redux';
import {Scrollbar} from 'smooth-scrollbar-react';
import {useIsMobileSize} from '../../../../../../hooks/use-is-mobile-size';
import {LoadingTypes, useLoadingState} from '../../../../../loading';
import {SigningSelectors} from '../../store/signing.selector';

export const PdfViewerV3 = ({scrollRef, onLoadSuccess}) => {
    const [pageNumbers, setPageNumbers] = useState(null);
    const [outerWidth, setOuterWidth] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const containerRef = useRef();
    const internalScrollRef = useRef();

    const url = useSelector(SigningSelectors.selectCurrentDocumentUrl);
    const isSigningDocument = useSelector(SigningSelectors.selectIsSigningCurrentDocument);
    const isSigningContract = useLoadingState(LoadingTypes.SIGN_CONTRACT);
    const isMobileSize = useIsMobileSize();

    const handleDocumentLoadSuccess = ({numPages}) => {
        const allPageNumbers = [];

        for (let p = 1; p < numPages + 1; p += 1) {
            allPageNumbers.push(p);
        }

        setPageNumbers(allPageNumbers);
        setOuterWidth(containerRef.current.offsetWidth);
        setIsLoading(false);

        if (onLoadSuccess && typeof onLoadSuccess === 'function') {
            onLoadSuccess();
        }
    };

    useEffect(() => {
        setIsLoading(true);

        if (internalScrollRef.current) {
            internalScrollRef.current.scrollTo(0, 0);
            if (scrollRef) {
                scrollRef.current = internalScrollRef.current;
            }
        }
    }, [scrollRef, url]);

    return (
        <>
            <GlobalStyles
                styles={{
                    '.scrollbar-track': {
                        backgroundColor: 'transparent !important',
                        width: '7px !important',
                    },
                    '.scrollbar-track-y': {
                        right: '1px !important',
                    },
                    '.scrollbar-thumb': {
                        backgroundColor: '#7F7F7F !important',
                        borderRadius: '4px !important',
                        width: '7px !important',
                    },
                    '.react-pdf__Document': {
                        backgroundColor: '#fff',
                    },
                }}
            />

            <div ref={containerRef}>
                {(isLoading || isSigningContract || isSigningDocument) && (
                    <Stack
                        direction="column"
                        sx={{
                            height: `calc(100vh - ${isMobileSize ? '280px' : '100px'})`,
                            backgroundColor: 'background.surface',
                            boxShadow: 'lg',
                            borderRadius: 'md',
                            padding: 5,
                            overflow: 'hidden',
                        }}
                    >
                        <Skeleton component="div" width="10vw" variant="rectangular" height="60px" sx={{mb: 2}} />
                        <Skeleton component="div" width="20vw" variant="rectangular" height="60px" sx={{mb: 2}} />
                        <Skeleton component="div" width="13vw" variant="rectangular" height="60px" sx={{mb: 2}} />
                        <Skeleton component="div" width="100%" variant="rectangular" height="60px" sx={{mb: 2}} />
                        <Skeleton component="div" width="100%" variant="rectangular" height="60px" sx={{mb: 2}} />
                        <Skeleton component="div" width="100%" variant="rectangular" height="60px" sx={{mb: 2}} />
                        <Skeleton component="div" width="23vw" variant="rectangular" height="60px" sx={{mb: 2}} />
                        <Skeleton component="div" width="28vw" variant="rectangular" height="60px" sx={{mb: 2}} />
                        <Skeleton component="div" width="39vw" variant="rectangular" height="60px" sx={{mb: 2}} />
                        <Skeleton component="div" width="33vw" variant="rectangular" height="60px" sx={{mb: 2}} />
                        <Skeleton component="div" width="46vw" variant="rectangular" height="60px" sx={{mb: 2}} />
                        <Skeleton component="div" width="100%" variant="rectangular" height="60px" sx={{mb: 2}} />
                        <Skeleton component="div" width="100%" variant="rectangular" height="60px" sx={{mb: 2}} />
                        <Skeleton component="div" width="100%" variant="rectangular" height="60px" sx={{mb: 2}} />
                        <Skeleton component="div" width="23vw" variant="rectangular" height="60px" sx={{mb: 2}} />
                    </Stack>
                )}

                <Box sx={{display: isLoading || isSigningContract || isSigningDocument ? 'none' : 'block'}}>
                    <Scrollbar
                        damping={0.2}
                        alwaysShowTracks
                        ref={internalScrollRef}
                    >
                        <Box
                            sx={{
                                boxShadow: 'lg',
                                borderRadius: 'md',
                                backgroundColor: 'background.body',
                                height: `calc(100vh - ${isMobileSize ? '280px' : '100px'})`,
                            }}
                        >
                            <Document
                                file={url}
                                onLoadStart
                                onLoadSuccess={handleDocumentLoadSuccess}
                                loading={() => null}
                            >
                                {pageNumbers && pageNumbers.map(pageNumber => (
                                    <Page
                                        key={pageNumber}
                                        width={outerWidth ? outerWidth - 17 : containerRef.current.offsetWidth}
                                        pageNumber={pageNumber}
                                        renderTextLayer={false}
                                        renderAnnotationLayer={true}
                                        loading={() => null}
                                    />
                                ))}
                            </Document>
                        </Box>
                    </Scrollbar>
                </Box>
            </div>
        </>
    );
};

PdfViewerV3.propTypes = {
    scrollRef: PropTypes.object,
    onLoadSuccess: PropTypes.func,
};

PdfViewerV3.defaultProps = {
    scrollRef: undefined,
    onLoadSuccess: null,
};
