import AssessmentIcon from '@mui/icons-material/Assessment';
import EuroIcon from '@mui/icons-material/Euro';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Card, Grid, Typography} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {generatePath} from 'react-router-dom';
import {useCompanyCardStyles} from './styles';
import {COMPANY_TYPES} from '../../../consts/company-constants';
import {COMPANY_STATUSES, FULL_COMPANY_STATUSES} from '../../../features/company/modules/setup-company/utils/constants';
import {COMPANY_CARD_BUTTONS} from '../../../features/company/utils/constants';
import {LoggedInUserSelectors} from '../../../features/user/modules/logged-in-user';
import {resolveRoute} from '../../../lib/router/resolveRoute';
import {ROUTE_PATHS} from '../../../lib/router/route-paths';
import {isUserFn} from '../../../utils/user-roles';
import ReactRouterButtonLink from '../../app/router/ReactRouterButtonLink';
import ReactRouterLink from '../../app/router/ReactRouterLink';
import QuickBooksIcon from '../../icons/QuickBooksIcon'; // TODO STUPAR: Do not remove this, should be renamed
import ActionButton from '../ActionButton/ActionButton';
import CompanyStatus from '../CompanyStatus';

// TODO Move component to v2
const CompanyCard = React.memo(props => {
    const {
        companyName,
        status,
        companyId,
        isUserActive,
        visibleButtons,
        hasElevation,
        userId,
        companyType,
    } = props;
    const styles = useCompanyCardStyles();

    const currentCognitoUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const isFreelancer = isUserFn(currentCognitoUser);

    const isCompanyActive = status === FULL_COMPANY_STATUSES.ACTIVE;

    const companyUrl = useMemo(() => {
        if (!currentCognitoUser) {
            return null;
        }

        if (isFreelancer) {
            return resolveRoute(ROUTE_PATHS.MY_COMPANY, {
                companyId: companyId,
            });
        }

        return resolveRoute(ROUTE_PATHS.COMPANY, {
            freelancerId: userId,
            companyId,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId, currentCognitoUser, userId]);

    const buttons = useMemo(() => {
        if (!visibleButtons.length) {
            return null;
        }

        const buttons = [];

        visibleButtons.forEach(buttonKey => {
            switch (buttonKey) {
                case COMPANY_CARD_BUTTONS.VIEW:
                    buttons.push((
                        <Grid item key={COMPANY_CARD_BUTTONS.VIEW}>
                            <ActionButton
                                size="small"
                                component={ReactRouterButtonLink}
                                to={companyUrl}
                            >
                                <VisibilityIcon />
                            </ActionButton>
                        </Grid>
                    ));
                    break;

                case COMPANY_CARD_BUTTONS.QUICKBOOKS: // TODO STUPAR: Do not remove this, should be renamed
                    if (companyType === COMPANY_TYPES.DEFAULT && isCompanyActive) {
                        buttons.push((
                            <Grid
                                item
                                key={COMPANY_CARD_BUTTONS.QUICKBOOKS}
                            > {/* TODO STUPAR: Do not remove this, should be renamed */}
                                <ActionButton
                                    size="small"
                                    component={ReactRouterButtonLink}
                                    to={resolveRoute(
                                        ROUTE_PATHS.COMPANY_QUICKBOOKS,
                                        {/* TODO STUPAR: Do not remove this, should be renamed */
                                            freelancerId: userId,
                                            companyId,
                                        },
                                    )}
                                >
                                    <QuickBooksIcon /> {/* TODO STUPAR: Do not remove this, should be renamed */}
                                </ActionButton>
                            </Grid>
                        ));
                    }
                    break;

                case COMPANY_CARD_BUTTONS.DASHBOARD:
                    if (companyType === COMPANY_TYPES.DEFAULT && isCompanyActive) {
                        buttons.push((
                            <Grid item key={COMPANY_CARD_BUTTONS.DASHBOARD}>
                                <ActionButton
                                    size="small"
                                    component={ReactRouterButtonLink}
                                    to={isFreelancer
                                        ? generatePath(ROUTE_PATHS.DASHBOARD)
                                        : resolveRoute(ROUTE_PATHS.COMPANY_DASHBOARD, {
                                            freelancerId: userId,
                                            companyId,
                                        })}
                                >
                                    <AssessmentIcon />
                                </ActionButton>
                            </Grid>
                        ));
                    }
                    break;

                case COMPANY_CARD_BUTTONS.BACKOFFICE:
                    if (companyType === COMPANY_TYPES.DEFAULT && isCompanyActive) {
                        buttons.push((
                            <Grid item key={COMPANY_CARD_BUTTONS.BACKOFFICE}>
                                <ActionButton
                                    size="small"
                                    component={ReactRouterButtonLink}
                                    to={resolveRoute(ROUTE_PATHS.COMPANY_BACKOFFICE, {
                                        freelancerId: userId,
                                        companyId,
                                    })}
                                >
                                    <EuroIcon />
                                </ActionButton>
                            </Grid>
                        ));
                    }
                    break;

                default:
                    break;
            }
        });

        return (
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
                {buttons}
            </Grid>
        );
    }, [companyId, companyType, companyUrl, isFreelancer, userId, visibleButtons, isCompanyActive]);

    return (
        <Card
            className={clsx(styles.root, {
                [styles.noBorder]: hasElevation,
            })}
            elevation={hasElevation ? 1 : 0}
        >
            <ReactRouterLink className={styles.header} to={companyUrl}>
                <div className={styles.imageContainer}>
                    <Typography className={styles.image} variant="body1">
                        {companyName.charAt(0) || <span>&nbsp;</span>}
                    </Typography>
                </div>

                <div>
                    <Typography className={styles.name} color="textPrimary">
                        {companyName || <span>&nbsp;</span>}
                    </Typography>

                    <CompanyStatus status={!isUserActive ? COMPANY_STATUSES.USER_DEACTIVATED : status} />
                </div>
            </ReactRouterLink>

            {buttons}
        </Card>
    );
});

CompanyCard.propTypes = {
    companyName: PropTypes.string.isRequired,
    companyId: PropTypes.string.isRequired,
    companyType: PropTypes.string.isRequired,
    status: PropTypes.oneOf(Object.values(COMPANY_STATUSES)).isRequired,
    isUserActive: PropTypes.bool.isRequired,
    visibleButtons: PropTypes.arrayOf(PropTypes.string),
    hasElevation: PropTypes.bool,
    userId: PropTypes.string.isRequired,
};

CompanyCard.defaultProps = {
    visibleButtons: Object.values(COMPANY_CARD_BUTTONS),
    hasElevation: false,
};

export default CompanyCard;
