import {FreelancerActionTypes} from './freelancer.action-type';

export const getFreelancerAccount = freelancerId => ({
    type: FreelancerActionTypes.GET_FREELANCER_ACCOUNT,
    payload: freelancerId,
});

const storeFreelancerAccount = freelancerAccount => ({
    type: FreelancerActionTypes.STORE_FREELANCER_ACCOUNT,
    payload: freelancerAccount,
});

export const setIsLoadingFreelancerAccount = isLoading => ({
    type: FreelancerActionTypes.SET_IS_LOADING_FREELANCER_ACCOUNT,
    payload: isLoading,
});

export const getFreelancerCompanies = freelancerId => ({
    type: FreelancerActionTypes.GET_FREELANCER_COMPANIES,
    payload: {
        freelancerId,
    },
});

export const storeFreelancerCompanies = freelancerCompanies => ({
    type: FreelancerActionTypes.STORE_FREELANCER_COMPANIES,
    payload: freelancerCompanies,
});

const setIsLoadingFreelancerCompanies = isLoading => ({
    type: FreelancerActionTypes.SET_IS_LOADING_FREELANCER_COMPANIES,
    payload: isLoading,
});

export const FreelancerActions = {
    getFreelancerAccount,
    storeFreelancerAccount,
    setIsLoadingFreelancerAccount,
    getFreelancerCompanies,
    storeFreelancerCompanies,
    setIsLoadingFreelancerCompanies,
};
