import {CONFIG} from '../../../config';
import {UserRoles} from '../../../utils/user-roles';

export const compareOrder = (a, b) => {
    if (Number(a.order) < Number(b.order)) {
        return -1;
    } else if (Number(a.order) > Number(b.order)) {
        return 1;
    }
    return 0;
};

const getApiBaseSuffix = role => {
    if (role === UserRoles.CARE) {
        return '/care';
    } else if (role === UserRoles.UMBRELLA) {
        return '/portage';
    }
    return '/tech';
};

export const getDocumentationApiBase = role => {
    const suffix = getApiBaseSuffix(role);
    return `${CONFIG.DOCUMENTATION.API_BASE_URL}${suffix}/wp-json/wp/v2`;
};

export const getDocumentationCssUrl = role => {
    const suffix = getApiBaseSuffix(role);
    return `${CONFIG.DOCUMENTATION.API_BASE_URL}${suffix}/wp-content/themes/hiway/style.css`;
};

