import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {v4 as uuidv4} from 'uuid';
import {SignatureHelperSaga} from './signature-helper.saga';
import {SignatureActions} from './signature.action';
import {SignatureActionTypes} from './signature.action-type';
import {SignatureSelectors} from './signature.selector';
import {selectRouterLocation} from '../../../lib/router/connected-router-saga';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import {LoadingActions, LoadingTypes} from '../../loading';
import {LoggedInUserSelectors} from '../../user/modules/logged-in-user';
import {SignatureApi} from '../api/signature.api';
import {SIGNING_OPTIONS} from '../utils/constants';
import {dataURLToBlob} from '../utils/functions';

export const handleSSEUploadSignatureFailed = function* (data) {
    const message = JSON.parse(data.data);

    const eventId = yield select(SignatureSelectors.selectEventId);

    if (eventId !== message?.id) {
        return;
    }

    yield call(SignatureHelperSaga.uploadSignatureFailedHandle);

    yield put(SignatureActions.storeSignatureUrl(null));

    Toast.error('genericError');
};

export const handleSSEUploadSignatureSuccess = function* (data) {
    const message = JSON.parse(data.data);

    const eventId = yield select(SignatureSelectors.selectEventId);

    if (eventId !== message?.id) {
        return;
    }

    let freelancerId;
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
    if (loggedInUser) {
        freelancerId = loggedInUser.id;
    } else {
        const location = yield select(selectRouterLocation);
        if (location?.query?.userId) {
            freelancerId = location?.query.userId;
        }
    }

    if (!freelancerId) {
        return;
    }

    yield call(SignatureHelperSaga.uploadSignatureSuccessHandle, {freelancerId, getSignatureFlow});
};

const uploadSignatureFlow = function* ({freelancerId, file, type, eventId}) {
    try {
        if (type === SIGNING_OPTIONS.UPLOAD) {
            yield call(SignatureApi.putSignature, {freelancerId, file, eventId});
        }

        if (type === SIGNING_OPTIONS.DRAW) {
            const blob = dataURLToBlob(file);

            yield call(SignatureApi.putSignature, {freelancerId, file: blob, eventId, skipBackgroundRemoval: true});
        }
    } catch (error) {
        // If upload failed set flag to false (qr scan screen reset) reset loader, show error
        yield put(SignatureActions.hasSignatureUploadSucced(false));
        yield put(SignatureActions.storeSignatureUrl(null));
        yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_SIGNATURE, false));

        Toast.error('genericError');

        Debug.error('signature', 'Error: ', {error});
    }
};

const uploadSignatureWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_SIGNATURE, true));
    yield put(SignatureActions.hasSignatureUploadSucced(null));

    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    const location = yield select(selectRouterLocation);
    const eventId = location?.query?.eventId ?? uuidv4();

    const freelancerId = location?.query?.userId ?? loggedInUser.id;

    yield call(uploadSignatureFlow, {
        freelancerId,
        file: payload.file,
        type: payload.type,
        eventId,
    });

    yield put(SignatureActions.putEventId(eventId));

    // TODO Improve SSE connection system if possible
    yield call(SignatureHelperSaga.checkSignatureSSEFallback, {freelancerId, getSignatureFlow});
};

const getSignatureFlow = function* ({freelancerId}) {
    try {
        const data = yield call(SignatureApi.getSignature, {freelancerId});

        const blob = yield call(SignatureHelperSaga.downloadFromS3, data?.signedUrl);

        yield put(SignatureActions.storeSignatureUrl(window.URL.createObjectURL(blob)));
        yield put(SignatureActions.storeSignatureInfo(data));
    } catch (error) {
        Debug.error('signature', 'Error: ', {error});
    }
};

export const loadSignature = function* () {
    yield put(LoadingActions.setLoading(LoadingTypes.SIGNATURE, true));

    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    if (loggedInUser) {
        yield call(getSignatureFlow, {
            freelancerId: loggedInUser.id,
        });
    }

    yield put(LoadingActions.setLoading(LoadingTypes.SIGNATURE, false));
};

export const signatureSaga = function* () {
    yield all([
        takeEvery(SignatureActionTypes.UPLOAD_SIGNATURE, uploadSignatureWorker),
    ]);
};
