import moment from 'moment';
import {DAY_SELECTION_TYPE} from './constants';
import {isPublicHoliday} from '../../../utils/holidays';

export const isWeekend = weekday => weekday === 7 || weekday === 6;

export const getAllWorkingDaysForMonth = (startDay, endDay) => {
    const clonedStartDay = moment(startDay);

    const days = [];

    const clonedEndDay = endDay.clone().add(1, 'day');

    while (!clonedStartDay.isSame(clonedEndDay, 'day')) {
        if (!isPublicHoliday(clonedStartDay) && !isWeekend(clonedStartDay.isoWeekday())) {
            days.push({
                day: clonedStartDay.clone(),
                type: DAY_SELECTION_TYPE.FULL,
            });
        }

        clonedStartDay.add(1, 'day');
    }

    return days;
};

export const getDaysBetween = (startDate, endDate) => {
    const dateList = [];
    let currentDate = moment(startDate);

    if (currentDate.isBefore(endDate, 'day')) {
        while (currentDate <= moment(endDate)) {
            dateList.push(currentDate.clone());
            currentDate = currentDate.clone().add(1, 'day');
        }
    } else {
        while (currentDate >= moment(endDate)) {
            dateList.push(currentDate.clone());
            currentDate = currentDate.clone().subtract(1, 'day');
        }
    }

    return dateList;
};

export const findDate = (dates, date, key = 'date') => {
    return dates.find(item => {
        return item[key].isSame(date, 'day');
    });
};
