import {CLOSE_NOTIFICATION, ENQUEUE_NOTIFICATION, REMOVE_NOTIFICATION} from './notifications.actions';

const initialState = [];

export const v1NotificationReducer = (state = initialState, action) => {
    if (action.type === ENQUEUE_NOTIFICATION) {
        return [
            ...state,
            {
                key: action.payload.options.key || new Date().getTime() + Math.random(),
                message: action.payload.message,
                options: action.payload.options,
            },
        ];
    }

    if (action.type === CLOSE_NOTIFICATION) {
        return state.map(notification => {
            if (action.dismissAll || notification.options.key === action.payload) {
                return {
                    ...notification,
                    options: {
                        ...notification.options,
                        dismissed: true,
                    },
                };
            }
            return notification;
        });
    }

    if (action.type === REMOVE_NOTIFICATION) {
        return state.filter(notification => notification.options.key !== action.payload);
    }

    return state;
};
