import {
    faArrowRight,
    faSignature,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {LocalesConstants} from '../../../../../../utils/locales-constants';

const DocumentSigningToolbarV3 = ({
    MainActionComponent,
    onNextClick,
    onSignClick,
    isSigning,
    isLoadingNextStep,
    documentId,
    isSigned,
}) => {
    const {t} = useTranslation(LocalesConstants.Document);

    if (MainActionComponent) {
        return React.createElement(MainActionComponent);
    }

    // Signing
    return (
        <Button
            sx={{mt: {mobile: 0, desktop: 2}}}
            startDecorator={isSigned ? null : <FontAwesomeIcon icon={faSignature} />}
            endDecorator={isSigned ? <FontAwesomeIcon icon={faArrowRight} /> : null}
            color={isSigned ? 'success' : 'primary'}
            loading={isSigning || isLoadingNextStep}
            disabled={!documentId}
            onClick={isSigned ? onNextClick : onSignClick}
            fullWidth
        >
            {isSigned ? t('signing.next') : t('signing.signButton')}
        </Button>
    );
};

DocumentSigningToolbarV3.propTypes = {
    MainActionComponent: PropTypes.any,
    onNextClick: PropTypes.func.isRequired,
    onSignClick: PropTypes.func.isRequired,
    isSigning: PropTypes.bool.isRequired,
    isLoadingNextStep: PropTypes.bool.isRequired,
    documentId: PropTypes.string.isRequired,
    isSigned: PropTypes.bool.isRequired,
};

DocumentSigningToolbarV3.defaultProps = {
    MainActionComponent: null,
};

export default DocumentSigningToolbarV3;
