import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import * as actions from './personal-expenses-form.actions';
import {createPersonalExpenseRequest} from '../../../../../../app/api/providers/expenses/expenses.provider';
import {Toast} from '../../../../../../lib/toast';
import {selectFreelancerAccount} from '../../../../../freelancer/store/freelancer.selectors';
import {getPersonalExpenses} from '../personal-expenses.actions';

const submitPersonalExpensesFormSaga = function* ({payload}) {
    try {
        yield put(actions.setIsLoadingPersonalExpensesForm(true));

        const freelancerAccount = yield select(selectFreelancerAccount);

        yield call(
            createPersonalExpenseRequest,
            freelancerAccount.id,
            payload.formData,
        );

        if (payload.onSuccess && typeof payload.onSuccess === 'function') {
            payload.onSuccess();
        }

        yield put(getPersonalExpenses(freelancerAccount.id, freelancerAccount.defaultCompanyId));

        Toast.success('expensePersonalCreated');
    } catch (error) {
        if (error?.response?.status === 413) {
            Toast.error('fileTooLarge');

            return;
        }

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingPersonalExpensesForm(false));
    }
};

export const watchPersonalExpensesFormSagas = function* () {
    yield all([
        takeLatest(actions.SUBMIT_PERSONAL_EXPENSES_FORM, submitPersonalExpensesFormSaga),
    ]);
};
