import {combineReducers} from 'redux';
import {UiActionTypes} from './ui.action-type';
import {createReducer} from '../../../utils/create-reducer';

const activeModalsReducer = (state = {}, {type, payload}) => {
    if (type === UiActionTypes.SET_ACTIVE_MODAL) {
        return {
            ...state,
            [payload.modalKey]: payload.state,
        };
    }

    return state;
};

const modalDataReducer = (state = {}, {type, payload}) => {
    if (type === UiActionTypes.SET_MODAL_DATA) {
        return {
            ...state,
            [payload.modalKey]: payload.data,
        };
    }

    return state;
};

const activeSidebarDrawersReducer = (state = {}, {type, payload}) => {
    if (type === UiActionTypes.SET_ACTIVE_SIDEBAR_DRAWER) {
        return {
            ...state,
            [payload.drawerKey]: payload.state,
        };
    }

    return state;
};

const sidebarDrawerDataReducer = (state = {}, {type, payload}) => {
    if (type === UiActionTypes.SET_SIDEBAR_DRAWER_DATA) {
        return {
            ...state,
            [payload.drawerKey]: payload.data,
        };
    }

    return state;
};

export const uiReducer = combineReducers({
    activeModals: activeModalsReducer,
    modalData: modalDataReducer,
    activeSidebarDrawers: activeSidebarDrawersReducer,
    sidebarDrawerData: sidebarDrawerDataReducer,
    isMaintenanceActive: createReducer(false, UiActionTypes.SET_IS_MAINTENANCE_ACTIVE),
});
