import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import DownloadTwoToneIcon from '@mui/icons-material/DownloadTwoTone';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone';
import {Alert, Box, CircularProgress, Divider, Fade, Paper, Popper, Typography} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {fileErrorCodes} from '../../../../../../../../components/dropzone/BankDropZone';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';
import {isUserFn} from '../../../../../../../../utils/user-roles';
import {getCompanyDocument} from '../../../../../../../company/store/company.actions';
import {LoadingSelectors, LoadingTypes} from '../../../../../../../loading';
import {LoggedInUserSelectors} from '../../../../../../../user/modules/logged-in-user';
import {TransfersActions} from '../../../../../transfers/store/transfers.actions';
import {TransactionsActions} from '../../../../store/transactions.action';
import {emptyFunction} from '../../../../util/constants';

export const TransactionFile = ({
    file,
    parentId,
    type,
    isEditable,
    hasDelayedUpload,
    deleteNotUploadedFile,
    isFileMenuOpen,
    setIsFileMenuOpen,
    companyId,
    freelancerId,
    accountId,
    isInCategorisation,
}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation(LocalesConstants.Bank);
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const buttonRef = useRef();

    const isOpen = (file.id ?? file.path) === isFileMenuOpen;
    const setIsOpen = shouldOpen => {
        if (shouldOpen === false) {
            setIsFileMenuOpen(null);
            return;
        }

        if (isOpen) {
            setIsFileMenuOpen(null);
        } else {
            setIsFileMenuOpen((file.id ?? file.path));
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);

    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);

    const isDeleteInProgress = useSelector(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.DELETE_BANK_FILE,
    ));

    const isError = type === 'ERROR';
    const isTransaction = type === 'TRANSACTION';

    if (typeof file === 'string') {
        return null;
    }

    const fileSplit = file.originalFilename ? file.originalFilename?.split('.') : file.name?.split('.');
    const extension = fileSplit[fileSplit.length - 1];

    const onDropdownClick = event => {
        setAnchorEl(event.currentTarget);
        setIsOpen();
    };

    const onViewFile = () => {
        dispatch(getCompanyDocument(
            isUserFn(loggedInUser) ? loggedInUser.id : freelancerId,
            isUserFn(loggedInUser) ? file.companyId : companyId,
            file.id,
        ));
    };

    const onDownloadFile = () => {
        dispatch(getCompanyDocument(
            isUserFn(loggedInUser) ? loggedInUser.id : freelancerId,
            isUserFn(loggedInUser) ? file.companyId : companyId,
            file.id,
            true,
        ));
    };

    const onDeleteFile = () => {
        if (hasDelayedUpload) {
            deleteNotUploadedFile(file);
            return;
        }

        if (isError) {
            deleteNotUploadedFile(file.id);
            return;
        }

        if (isTransaction) {
            dispatch(TransactionsActions.deleteBankFile({
                fileId: file.id,
                transactionId: parentId,
                accountId,
            }));
        } else {
            dispatch(TransfersActions.deleteTransferFile({
                fileId: file.id,
                transferId: parentId,
                accountId,
            }));
        }

        setIsOpen(false);
        setIsDeleting(true);
    };

    if (!isMobileSize) {
        return (
            <>
                <Box
                    ref={buttonRef}
                    sx={{
                        'mb': isInCategorisation ? 0 : 2,
                        'fontSize': theme => theme.typography.pxToRem(13),
                        'display': 'flex',
                        'justifyContent': 'space-between',
                        'alignItems': 'center',
                        'cursor': 'pointer',
                        'p': 1,
                        'backgroundColor': isError
                            ? 'v2.light.error.dark'
                            : (isOpen ? 'primary_v2.dark' : 'primary_v2.main'),
                        'color': 'white',
                        'borderRadius': '8px',
                    }}
                    onClick={onDropdownClick}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    >
                        {extension === 'pdf'
                            ? (<PictureAsPdfIcon sx={{mr: 1.8}} />)
                            : (<FilePresentIcon sx={{mr: 1.8}} />)
                        }
                        <Box component="span" sx={{wordBreak: 'break-word'}}>
                            {file.originalFilename ?? file.name}
                        </Box>
                    </Box>
                    {isDeleteInProgress && isDeleting ? (
                        <CircularProgress sx={{color: 'white'}} size={25} color="secondary" />
                    )
                        : isOpen ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                </Box>

                {isError && (
                    <Box sx={{
                        mt: -1,
                    }}
                    >
                        <Alert
                            sx={{
                                py: 0,
                            }}
                            severity="error"
                        >
                            <Typography sx={{
                                fontWeight: '500',
                                fontSize: theme => theme.typography.pxToRem(12),
                                display: 'inline',
                            }}
                            >
                                {t('fileDetails.error')} :{' '}
                            </Typography>
                            {file?.errorCode === fileErrorCodes['file-too-large'] && (
                                <>
                                    <Typography sx={{
                                        fontSize: theme => theme.typography.pxToRem(12),
                                        display: 'inline',
                                    }}
                                    >
                                        <Trans
                                            t={t}
                                            i18nKey="fileDetails.10mbMax"
                                            components={{bold: <Box component="span" sx={{fontWeight: 'bold'}} />}}
                                        />
                                    </Typography>
                                </>
                            )}
                        </Alert>
                    </Box>
                )}

                <Popper
                    open={isOpen}
                    anchorEl={anchorEl}
                    transition
                    sx={{
                        zIndex: '433',
                    }}
                >
                    {({TransitionProps}) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper
                                sx={{
                                    width: buttonRef?.current?.clientWidth ?? '328px',
                                    borderRadius: '16px',
                                }}
                                elevation={8}
                            >
                                <TransactionFileDropownOptions
                                    onViewFile={onViewFile}
                                    onDownloadFile={onDownloadFile}
                                    onDeleteFile={onDeleteFile}
                                    isEditable={isEditable}
                                    hasDelayedUpload={hasDelayedUpload}
                                    isError={isError}
                                />
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </>
        );
    }

    return (
        <>
            <Box
                sx={{
                    'mb': 2,
                    'fontSize': theme => theme.typography.pxToRem(13),
                    'display': 'flex',
                    'justifyContent': 'space-between',
                    'alignItems': 'center',
                    'cursor': 'pointer',
                    'p': 1,
                    'backgroundColor': isError
                        ? 'v2.light.error.dark'
                        : (isOpen ? 'primary_v2.dark' : 'primary_v2.main'),
                    'color': 'white',
                    'borderRadius': '8px',
                }}
                onClick={onDropdownClick}
            >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
                >
                    {extension === 'pdf'
                        ? (<PictureAsPdfIcon sx={{mr: 1.8}} />)
                        : (<FilePresentIcon sx={{mr: 1.8}} />)
                    }
                    {file.originalFilename}
                </Box>
                {isDeleteInProgress ? (
                    <CircularProgress sx={{color: 'white'}} size={25} color="secondary" />
                )
                    : isOpen ? (
                        <KeyboardArrowUpIcon />
                    ) : (
                        <KeyboardArrowDownIcon />
                    )}
            </Box>
            <Drawer
                anchor="bottom"
                open={isOpen}
                onClose={() => {
                    setIsOpen(false);
                }}
                sx={{
                    '.MuiDrawer-paperAnchorBottom': {
                        borderTopRightRadius: '32px',
                        borderTopLeftRadius: '32px',
                    },
                }}
            >
                <Box sx={{
                    minHeight: '100px',
                    borderTopRightRadius: '32px',
                    borderTopLeftRadius: '32px',
                    display: 'flex',
                    flexDirection: 'column',
                    px: 3,
                }}
                >
                    <TransactionFileDropownOptions
                        onViewFile={onViewFile}
                        onDownloadFile={onDownloadFile}
                        onDeleteFile={onDeleteFile}
                        isEditable={isEditable}
                        hasDelayedUpload={false}
                        isError={false}
                    />
                </Box>
            </Drawer>
        </>
    );
};

TransactionFile.propTypes = {
    file: PropTypes.object.isRequired,
    parentId: PropTypes.string,
    type: PropTypes.string,
    isEditable: PropTypes.bool.isRequired,
    hasDelayedUpload: PropTypes.bool,
    deleteNotUploadedFile: PropTypes.func,
    isFileMenuOpen: PropTypes.bool,
    setIsFileMenuOpen: PropTypes.func.isRequired,
    companyId: PropTypes.string,
    freelancerId: PropTypes.string,
    accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isInCategorisation: PropTypes.bool,
};

TransactionFile.defaultProps = {
    parentId: '',
    type: 'TRANSACTIONS',
    hasDelayedUpload: false,
    isFileMenuOpen: false,
    deleteNotUploadedFile: emptyFunction,
    companyId: '',
    freelancerId: '',
    accountId: null,
    isInCategorisation: false, // TODO: delete this param, it should be true always
};

const TransactionFileDropownOptions = ({
    onViewFile,
    onDownloadFile,
    onDeleteFile,
    isEditable,
    hasDelayedUpload,
    isError,
}) => {
    const {t} = useTranslation(LocalesConstants.Bank);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                py: 1,
            }}
        >
            {!hasDelayedUpload && !isError && (
                <>
                    <Box
                        sx={{
                            'display': 'flex',
                            'flexDirection': 'row',
                            'alignItems': 'center',
                            'py': 2,
                            'px': 2,
                            'cursor': 'pointer',
                            '&:hover': {
                                backgroundColor: 'v2.light.primary.shades',
                            },
                        }}
                        onClick={onViewFile}
                    >
                        <RemoveRedEyeTwoToneIcon sx={{mr: 2, color: 'primary_v2.main'}} />
                        <Typography
                            sx={{
                                fontSize: theme => theme.typography.pxToRem(13),
                                textTransform: 'uppercase',
                                fontWeight: 500,
                                color: 'v2.blueGray.600',
                            }}
                        >
                            {t('fileDetails.view')}
                        </Typography>
                    </Box>

                    <Divider sx={{mx: 2}} />

                    <Box
                        sx={{
                            'display': 'flex',
                            'flexDirection': 'row',
                            'alignItems': 'center',
                            'px': 2,
                            'py': 2,
                            'cursor': 'pointer',
                            '&:hover': {
                                backgroundColor: 'v2.light.primary.shades',
                            },
                        }}
                        onClick={onDownloadFile}
                    >
                        <DownloadTwoToneIcon sx={{mr: 2, color: 'primary_v2.main'}} />
                        <Typography
                            sx={{
                                fontSize: theme => theme.typography.pxToRem(13),
                                textTransform: 'uppercase',
                                fontWeight: 500,
                                color: 'v2.blueGray.600',
                            }}
                        >
                            {t('fileDetails.download')}
                        </Typography>
                    </Box>
                </>
            )}

            {(isEditable || hasDelayedUpload) && (
                <>
                    {!isError && (
                        <Divider sx={{mx: 2}} />
                    )}

                    <Box
                        sx={{
                            'display': 'flex',
                            'flexDirection': 'row',
                            'alignItems': 'center',
                            'px': 2,
                            'py': 2,
                            'cursor': 'pointer',
                            '&:hover': {
                                backgroundColor: 'v2.light.primary.shades',
                            },
                        }}
                        onClick={onDeleteFile}
                    >
                        <DeleteTwoToneIcon sx={{mr: 2, color: 'v2.light.error.main'}} />
                        <Typography
                            sx={{
                                fontSize: theme => theme.typography.pxToRem(13),
                                textTransform: 'uppercase',
                                fontWeight: 500,
                                color: 'v2.light.error.main',
                            }}
                        >
                            {t('fileDetails.delete')}
                        </Typography>
                    </Box>
                </>
            )}
        </Box>
    );
};

TransactionFileDropownOptions.propTypes = {
    onViewFile: PropTypes.func.isRequired,
    onDownloadFile: PropTypes.func.isRequired,
    onDeleteFile: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
    hasDelayedUpload: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
};
