import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {LoadingButton} from '@mui/lab';
import {Alert, Box, Checkbox, FormControlLabel, Typography, useMediaQuery} from '@mui/material';
import Button from '@mui/material/Button';
import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {deactivateSubscriptionModalType} from './DeactivateSubscriptionModal';
import {ModalWrapper} from '../../../../../../../../components/modal-wrapper/ModalWrapper';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';
import {LoadingTypes, useLoadingState} from '../../../../../../../loading';
import {UiActions} from '../../../../../../../ui/store/ui.action';
import {UiSelectors} from '../../../../../../../ui/store/ui.selector';
import {ModalsKeys} from '../../../../../../../ui/utils/constants';
import {TransactionsActions} from '../../../../store/transactions.action';

const modalKey = ModalsKeys.CATEGORIZATION_PROOF_OF_DOCUMENTS;

const ConfirmProofOfDocumentsModal = () => {
    const {t} = useTranslation(LocalesConstants.Bank);
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const dispatch = useDispatch();

    const [isChecked, setIsChecked] = useState(false);

    const data = useSelector(UiSelectors.createModalDataSelector(modalKey));
    const isInProgress = useLoadingState(LoadingTypes.GENERIC_CRUD_LOADER);

    const handleConfirm = () => {
        if (data?.details?.hasActiveSubscription) {
            dispatch(UiActions.setActiveModal(ModalsKeys.CATEGORIZATION_PROOF_OF_DOCUMENTS, false));

            dispatch(UiActions.setModalData(ModalsKeys.CATEGORIZATION_DEACTIVATE_SUBSCRIPTION, {
                type: deactivateSubscriptionModalType.CHANGE_CATEGORY,
                action: TransactionsActions.refuseShareProofOfDocuments(
                    {shouldRefuse: true, id: data?.details?.id},
                ),
            }));

            dispatch(UiActions.setActiveModal(ModalsKeys.CATEGORIZATION_DEACTIVATE_SUBSCRIPTION, true));
            return;
        }

        dispatch(TransactionsActions.refuseShareProofOfDocuments(
            {shouldRefuse: true, id: data?.details?.id},
        ));
    };

    return (
        <ModalWrapper
            containerSx={{width: '652px'}}
            modalKey={modalKey}
            title={(
                <Typography variant="h4">
                    {t('categorisation.proofOfDocuments.title')}
                </Typography>
            )}
            buttons={({handleClose}) => (
                <>
                    <Button
                        disableElevation
                        fullWidth={isMobileSize}
                        startIcon={<CloseIcon />}
                        onClick={() => {
                            handleClose();
                        }}
                        variant="outlined"
                        color="primary"
                    >
                        {t('categorisation.proofOfDocuments.cancel')}
                    </Button>

                    <LoadingButton
                        loading={isInProgress}
                        fullWidth={isMobileSize}
                        startIcon={<CheckIcon />}
                        onClick={handleConfirm}
                        disabled={!isChecked}
                        color="secondary"
                        variant="contained"
                    >
                        {t('categorisation.proofOfDocuments.confirm')}
                    </LoadingButton>
                </>
            )}
        >
            <Box>
                <Alert
                    sx={{
                        mt: 2,
                    }}
                    severity="info"
                >
                    <Typography sx={{
                        fontWeight: 700,
                    }}
                    >
                        {t('categorisation.proofOfDocuments.warning')}
                    </Typography>

                    <Typography sx={{
                        my: 1,
                        fontSize: theme => theme.typography.pxToRem(14),
                    }}
                    >
                        <Trans
                            t={t}
                            i18nKey="categorisation.proofOfDocuments.paragraph1"
                            components={{bold: <Box component="span" sx={{fontWeight: '700'}} />}}
                        />
                    </Typography>

                    <Typography sx={{
                        fontSize: theme => theme.typography.pxToRem(14),
                    }}
                    >
                        <Trans
                            t={t}
                            i18nKey="categorisation.proofOfDocuments.paragraph2"
                            components={{bold: <Box component="span" sx={{fontWeight: '700'}} />}}
                        />
                    </Typography>
                </Alert>

                <FormControlLabel
                    sx={{
                        'mt': 2,
                        '> .MuiFormControlLabel-label': {
                            fontSize: theme => theme.typography.pxToRem(14),
                        },
                    }}
                    control={(
                        <Checkbox
                            checked={isChecked}
                            onChange={(_, value) => {
                                setIsChecked(value);
                            }}
                        />
                    )}
                    label={t('categorisation.proofOfDocuments.label')}
                />
            </Box>
        </ModalWrapper>
    );
};

export default ConfirmProofOfDocumentsModal;
