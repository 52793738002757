import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import DownloadIcon from '@mui/icons-material/Download';
import {Box, Slide, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link, generatePath} from 'react-router-dom';
import CapitalDepositValidationRejectedSvg from '../../../../../../assets/svg/bank/bank-validation-capital-rejected.svg';
import CapitalDepositValidationSuccessSvg from '../../../../../../assets/svg/bank/bank-validation-capital-success.svg';
import CapitalDepositValidationPendingSvg from '../../../../../../assets/svg/bank/bank-validation-capital.svg';
import BankInformationLayout from '../../../../../../layout/BankInformationLayout/BankInformationLayout';
import {RoutePaths} from '../../../../../../lib/router/route-paths';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {AnimationActions} from '../../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../../../animations/utils/constants';
import {BankActions} from '../../../../../bank/store/bank.action';
import {BankSelector} from '../../../../../bank/store/bank.selector';
import {CapitalDepositDocumentStatus} from '../../../../../bank/utils/constants';
import {DocumentActions} from '../../../../../document/store/document.action';
import {LoggedInUserSelectors} from '../../../../../user/modules/logged-in-user';
import {CapitalDepositStatus} from '../../utils/constants';

const CapitolDepositValidation = () => {
    const dispatch = useDispatch();

    const {t} = useTranslation(LocalesConstants.FreelancerOnboarding);

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    const deposit = useSelector(BankSelector.selectCapitalDeposit);
    const user = useSelector(LoggedInUserSelectors.selectLoggedInUser);

    const onNextClick = () => {
        // If user went back and status is already transitioned, just go to the next step
        const isDoingTransition = ![
            CapitalDepositStatus.WAITING_FOR_PROOF_OF_REGISTRATION_UPLOAD,
            CapitalDepositStatus.WAITING_FOR_NOTARY_TRANSFER,
            CapitalDepositStatus.COMPLETED,
        ].includes(deposit?.status);

        dispatch(BankActions.transitionCapitalDepositStatus(isDoingTransition));
    };

    const onDownload = () => {
        dispatch(DocumentActions.getDocument(deposit?.certificate?.documentId, true));
    };

    const isRejected = deposit?.certificate.status === CapitalDepositDocumentStatus.REFUSED;
    const isPending = !deposit?.certificate?.documentId && !isRejected;

    return (
        <BankInformationLayout
            header={t('capitalValidation.headline')}
            subTitle={t('capitalValidation.subtitle')}
            Button={(
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 3,
                }}
                >
                    {!isRejected && !isPending && (
                        <Box>{t('capitalValidation.confirmationText')}</Box>
                    )}
                    {isRejected && (
                        <Box>{t('capitalValidation.rejectedButtonText')}</Box>
                    )}
                    <Button
                        color="secondary"
                        variant="contained"
                        disabled={isPending || isRejected}
                        endIcon={<ArrowForwardIcon />}
                        onClick={onNextClick}
                    >
                        {t('capitalValidation.continueButton')}
                    </Button>
                </Box>
            )}
        >
            <Slide in={isAnimationActive} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '600px',
                }}
                >
                    {isRejected && (
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        >
                            <Box>
                                <img src={CapitalDepositValidationRejectedSvg} alt="capital deposit rejected" />
                            </Box>

                            <Typography sx={{mt: 2}} variant="h4">
                                {t('capitalValidation.rejectedTitle')}
                            </Typography>

                            <Typography sx={{mt: 2, color: 'text_v2.secondary'}}>
                                {t('capitalValidation.rejectedText')}
                            </Typography>

                            <Box sx={{
                                display: 'flex',
                                gap: 2,
                                mt: 3,
                                alignItems: 'center',
                            }}
                            >
                                <Button
                                    variant="contained"
                                    onClick={onDownload}
                                    startIcon={<DownloadIcon />}
                                >
                                    {t('capitalValidation.download')}
                                </Button>
                            </Box>
                        </Box>
                    )}

                    {isPending && (
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        >
                            <Box>
                                <img src={CapitalDepositValidationPendingSvg} alt="capital deposit pending" />
                            </Box>

                            <Typography sx={{mt: 2}} variant="h4">
                                {t('capitalValidation.pendingTitle')}
                            </Typography>

                            <Typography sx={{mt: 2, color: 'text_v2.secondary'}}>
                                {t('capitalValidation.pendingText')}
                            </Typography>
                        </Box>
                    )}

                    {!isPending && (
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        >
                            <Box>
                                <img src={CapitalDepositValidationSuccessSvg} alt="capital deposit success" />
                            </Box>

                            <Typography sx={{mt: 2}} variant="h4">
                                {t('capitalValidation.successTitle')}
                            </Typography>

                            <Typography sx={{mt: 2, color: 'text_v2.secondary'}}>
                                {t('capitalValidation.successText')}
                            </Typography>

                            <Box sx={{
                                display: 'flex',
                                gap: 2,
                                mt: 3,
                                alignItems: 'center',
                            }}
                            >
                                <Button
                                    variant="contained"
                                    onClick={onDownload}
                                    startIcon={<DownloadIcon />}
                                >
                                    {t('capitalValidation.download')}
                                </Button>
                                <Button
                                    component={Link}
                                    startIcon={<DocumentScannerIcon />}
                                    variant="outlined"
                                    to={generatePath(`${RoutePaths.DOCUMENTS}`, {
                                        companyId: user?.defaultCompanyId,
                                    })}
                                >
                                    {t('capitalValidation.myDocuments')}
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Slide>
        </BankInformationLayout>
    );
};

export default CapitolDepositValidation;
