import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Box, Paper} from '@mui/material';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import BeneficiariesList from './beneficiaries/BeneficiariesList';
import BeneficiariesSidebar, {BeneficiariesDelete} from './beneficiaries/BeneficiariesSidebar';
import CreateNewTransfer from './transfers/CreateNewTransfer';
import {CreatePaymentPanel} from './transfers/CreatePaymentPanel';
import RecurringTransferSidebar from './transfers/RecurringTransferSidebar';
import {StandingOrders} from './transfers/TransferOrders';
import PaperWrapper from './util/PaperWrapper';
import ExpandableSidebar from '../../../../../components/expandable-sidebar/ExpandableSidebar';
import {LocalesConstants} from '../../../../../utils/locales-constants';
import BankNewPageWrapper from '../../../components/BankNewPageWrapper';
import ConnectProPage from '../../../components/connect/ConnectProPage';
import {BankSelector} from '../../../store/bank.selector';
import {LANDING_PAGE_MODE} from '../../../utils/constants';
import {BANK_TYPES} from '../../bridge-api/utils/constants';
import {SIDEBAR_TYPES} from '../utils/constants';

const BankTransfer = () => {
    const {t} = useTranslation(LocalesConstants.BankTransfer);
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const [isFlexDirectionColumn, setIsFlexDirectionColumn] = useState(false);

    const integrations = useSelector(BankSelector.selectIntegrations);
    const hasHiwayIntegration = integrations.find(integration => integration.type === BANK_TYPES.hiway);
    const isAboveMobileSize = useMediaQuery(`(min-width:960px)`);
    const isFullHD = useMediaQuery(`(max-width:1700px)`);
    const isNonMobileSmallScreen = isAboveMobileSize && isFullHD;

    const [sidebarOpenInformation, setSidebarOpenInformation] = useState(null);
    const sidebarTimeout = useRef();

    useEffect(() => {
        if (!isNonMobileSmallScreen) {
            return;
        }

        if (sidebarOpenInformation) {
            setIsFlexDirectionColumn(true);
        }

        if (!sidebarOpenInformation) {
            sidebarTimeout.current = setTimeout(() => {
                setIsFlexDirectionColumn(false);
            }, 200);
            return;
        }

        let targetElement;
        if (sidebarOpenInformation.type === SIDEBAR_TYPES.beneficiaries) {
            targetElement = document.getElementById(`beneficiary-item-${sidebarOpenInformation?.id}`);
        } else {
            targetElement = document.getElementById(`transfers-item-${sidebarOpenInformation?.order?.id}`);
        }

        if (!targetElement) {
            return;
        }

        setTimeout(() => {
            const viewportHeight = window.innerHeight;
            const elementHeight = targetElement.clientHeight;

            // Calculate the scroll position to center the element
            const scrollPosition = targetElement.offsetTop - (viewportHeight - elementHeight) / 2;

            window.scrollTo({top: scrollPosition, behavior: 'smooth'});
        }, 50);

        return () => {
            clearTimeout(sidebarTimeout.current);
        };
        // eslint-disable-next-line
    }, [sidebarOpenInformation]);

    const onCloseSidebar = useCallback(() => {
        setSidebarOpenInformation(null);
    }, [setSidebarOpenInformation]);

    if (integrations?.length === 0 || !hasHiwayIntegration) {
        return (
            <ConnectProPage type={LANDING_PAGE_MODE.TRANSFERS} />
        );
    }

    if (sidebarOpenInformation) {
        if (sidebarOpenInformation.type === SIDEBAR_TYPES.beneficiaries && isMobileSize) {
            return (
                <BankNewPageWrapper
                    onBackClick={onCloseSidebar}
                    returnText={t('beneficiaries.home.back')}
                >
                    <Paper
                        elevation={0}
                        sx={{
                            borderRadius: '16px',
                        }}
                    >
                        <BeneficiariesSidebar
                            key={sidebarOpenInformation?.id || Math.random()}
                            information={sidebarOpenInformation}
                            onClose={onCloseSidebar}
                        />
                    </Paper>

                    <BeneficiariesDelete
                        onClose={onCloseSidebar}
                        information={sidebarOpenInformation}
                    />

                    <Box sx={{display: 'none'}}>
                        <BeneficiariesList
                            sidebarOpenInformation={sidebarOpenInformation}
                            setSidebarOpenInformation={setSidebarOpenInformation}
                            type={SIDEBAR_TYPES.beneficiaries}
                        />
                    </Box>
                </BankNewPageWrapper>
            );
        }

        if (sidebarOpenInformation.type === SIDEBAR_TYPES.transferList && isMobileSize) {
            return (
                <Box>
                    <Box sx={{backgroundColor: 'v2.blueGray.50', mt: -2.5}}>
                        <Button
                            onClick={() => {
                                setSidebarOpenInformation(null);
                            }}
                            sx={{
                                pl: 3,
                                color: 'v2.blueGray.900',
                                fontSize: theme => theme.typography.pxToRem(22),
                                textTransform: 'none',
                                fontWeight: 400,
                            }}
                            startIcon={(
                                <ArrowBackIcon
                                    sx={{
                                        mr: 1,
                                        height: '24px',
                                        width: '24px',
                                    }}
                                />
                            )}
                        >
                            {t('transfer.create.topPageBack')}
                        </Button>
                    </Box>
                    <Box sx={{pt: 2}}>
                        <StandingOrders
                            setSidebarOpenInformation={setSidebarOpenInformation}
                            sidebarOpenInformation={sidebarOpenInformation}
                        />
                    </Box>
                </Box>
            );
        }

        if (sidebarOpenInformation.type === SIDEBAR_TYPES.editRecurringTransfer && isMobileSize) {
            return (
                <RecurringTransferSidebar
                    key={sidebarOpenInformation?.id || Math.random()}
                    information={sidebarOpenInformation}
                    onCloseSidebar={onCloseSidebar}
                />
            );
        }

        if (sidebarOpenInformation.type === SIDEBAR_TYPES.transfers) {
            return (
                <CreateNewTransfer
                    setSidebarOpenInformation={setSidebarOpenInformation}
                />
            );
        }
    }

    if (!isMobileSize) {
        return (
            <Box sx={{
                display: 'flex',
            }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: isFlexDirectionColumn ? 'column' : 'row',
                    width: sidebarOpenInformation ? 'calc(100% - 400px)' : '100%',
                    transition: 'width 0.4s ease-in-out',
                }}
                >
                    <Box sx={{
                        width: sidebarOpenInformation && isNonMobileSmallScreen ? '100%' : '50%',
                        transition: 'width 0.4s ease-in-out',
                        display: 'flex',
                        flexDirection: 'column',
                        pr: sidebarOpenInformation && isNonMobileSmallScreen ? 0 : 1.5,
                    }}
                    >
                        <Box
                            sx={{
                                mb: 3,
                            }}
                        >
                            <PaperWrapper elevation={8}>
                                <CreatePaymentPanel
                                    setSidebarOpenInformation={setSidebarOpenInformation}
                                />
                            </PaperWrapper>
                        </Box>

                        <Box>
                            <PaperWrapper>
                                <BeneficiariesList
                                    sidebarOpenInformation={sidebarOpenInformation}
                                    setSidebarOpenInformation={setSidebarOpenInformation}
                                    type={SIDEBAR_TYPES.beneficiaries}
                                />
                            </PaperWrapper>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            width: sidebarOpenInformation && isNonMobileSmallScreen ? '100%' : '50%',
                            transition: 'width 0.4s ease-in-out',
                            pl: sidebarOpenInformation && isNonMobileSmallScreen ? 0 : 1.5,
                            mt: sidebarOpenInformation && isNonMobileSmallScreen ? 2 : 0,
                        }}
                    >
                        <PaperWrapper sx={{px: 0, pb: 1}}>
                            <StandingOrders
                                setSidebarOpenInformation={setSidebarOpenInformation}
                                sidebarOpenInformation={sidebarOpenInformation}
                            />
                        </PaperWrapper>
                    </Box>
                </Box>

                <ExpandableSidebar
                    key={sidebarOpenInformation?.id || Math.random()}
                    isOpen={!!sidebarOpenInformation}
                    onClose={onCloseSidebar}
                    isMobileSize={isMobileSize}
                    mt={-11}
                >
                    {sidebarOpenInformation && sidebarOpenInformation.type === SIDEBAR_TYPES.beneficiaries
                    && (
                        <BeneficiariesSidebar
                            key={sidebarOpenInformation?.id || Math.random()}
                            information={sidebarOpenInformation}
                            onClose={onCloseSidebar}
                        />
                    )}
                    {sidebarOpenInformation && sidebarOpenInformation.type === SIDEBAR_TYPES.editRecurringTransfer
                        && (
                            <RecurringTransferSidebar
                                key={sidebarOpenInformation?.id || Math.random()}
                                information={sidebarOpenInformation}
                                onCloseSidebar={onCloseSidebar}
                            />
                        )}
                </ExpandableSidebar>
            </Box>
        );
    }

    return (
        <>
            <Box sx={{display: 'flex', flexDirection: 'column', px: 2}}>
                {/* <Paper */}
                {/*    elevation={0} */}
                {/*    sx={{ */}
                {/*        borderRadius: '16px', */}
                {/*        p: 3, */}
                {/*        pt: 2, */}
                {/*        mb: 4.5, */}
                {/*    }} */}
                {/* > */}
                {/*     <PaymentsPanelMobile */}
                {/*        setSidebarOpenInformation={setSidebarOpenInformation} */}
                {/*     /> */}
                {/* </Paper> */}

                <PaperWrapper elevation={8}>
                    <CreatePaymentPanel
                        setSidebarOpenInformation={setSidebarOpenInformation}
                    />
                </PaperWrapper>

                <Paper
                    elevation={0}
                    sx={{
                        borderRadius: '16px',
                        p: 3,
                        pt: 2,
                        mt: 2,
                    }}
                >
                    <BeneficiariesList
                        sidebarOpenInformation={sidebarOpenInformation}
                        setSidebarOpenInformation={setSidebarOpenInformation}
                        type={SIDEBAR_TYPES.beneficiaries}
                    />
                </Paper>

                <PaperWrapper sx={{px: 0, pb: 1, my: 2}}>
                    <StandingOrders
                        setSidebarOpenInformation={setSidebarOpenInformation}
                        sidebarOpenInformation={sidebarOpenInformation}
                    />
                </PaperWrapper>
            </Box>
        </>
    );
};

export default BankTransfer;
