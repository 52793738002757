import LockIcon from '@mui/icons-material/Lock';
import {Box} from '@mui/material';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ConditionalTooltip} from '../../../../../../../../components/tooltip/ConditionalTooltip';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';
import {SECTION_STATUS} from '../../utils/constants';

const chipStyles = {
    '& .MuiChip-label': {
        lineHeight: '1em',
        px: 1,
        fontSize: theme => theme.typography.pxToRem(10),
    },
    '&.MuiChip-root': {
        borderRadius: 1,
        height: '18px',
    },
};

const SectionStatus = ({status}) => {
    const {t} = useTranslation(LocalesConstants.Bank);

    const chip = useMemo(() => {
        switch (status) {
            case SECTION_STATUS.REQUIRED:
                return (
                    <Chip
                        sx={{
                            'backgroundColor': 'v2.light.warning.shades4',
                            'color': 'v2.light.warning.main',
                            ...chipStyles,
                        }}
                        label={t(`categorisation.sectionStatus.${SECTION_STATUS.REQUIRED}`)}
                    />
                );

            case SECTION_STATUS.OPTIONAL:
                return (
                    <ConditionalTooltip
                        tooltip={t(`categorisation.section.notRequired`)}
                    >
                        <Chip
                            sx={{
                                'backgroundColor': 'v2.light.info.shades4',
                                'color': 'v2.light.info.light',
                                ...chipStyles,
                            }}
                            label={t(`categorisation.sectionStatus.${SECTION_STATUS.OPTIONAL}`)}
                        />
                    </ConditionalTooltip>
                );

            case SECTION_STATUS.WAITING_FOR_CATEGORY:
                return (
                    <Chip
                        sx={{
                            'backgroundColor': 'gray_v2.100',
                            'color': 'v2.blueGray.300',
                            ...chipStyles,
                        }}
                        label={t(`categorisation.sectionStatus.${SECTION_STATUS.WAITING_FOR_CATEGORY}`)}
                    />
                );

            case SECTION_STATUS.WAITING_FOR_FILE:
                return (
                    <Chip
                        sx={{
                            'backgroundColor': 'gray_v2.100',
                            'color': 'v2.blueGray.300',
                            ...chipStyles,
                        }}
                        label={t(`categorisation.sectionStatus.${SECTION_STATUS.WAITING_FOR_FILE}`)}
                    />
                );

            case SECTION_STATUS.NON_EDITABLE:
                return (
                    <Chip
                        sx={{
                            'backgroundColor': 'v2.light.info.shades4',
                            'color': 'v2.blueGray.400',
                            ...chipStyles,
                        }}
                        icon={(
                            <LockIcon fontSize="14px" sx={{color: '#78909C !important'}} />
                        )}
                        label={t(`categorisation.sectionStatus.${SECTION_STATUS.NON_EDITABLE}`)}
                    />
                );

            case SECTION_STATUS.NO_INFORMATION_EXPECTED:
                return (
                    <Chip
                        sx={{
                            'backgroundColor': 'gray_v2.100',
                            'color': 'v2.blueGray.300',
                            ...chipStyles,
                        }}
                        icon={null}
                        label={t(`categorisation.sectionStatus.${SECTION_STATUS.NO_INFORMATION_EXPECTED}`)}
                    />
                );

            default:
                return null;
        }
    }, [status, t]);

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
        }}
        >
            {chip}
        </Box>
    );
};

SectionStatus.propTypes = {
    status: PropTypes.string.isRequired,
};

export default React.memo(SectionStatus);
