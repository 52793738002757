import React from 'react';
import {appLoaderSaga} from '../../../app/store/app-loader.sagas';
import {SuspenseContainer} from '../../../components/suspense/Suspense';
import {DOCUMENT_CONTEXTS} from '../../../consts/document-constants';
import {AppLayoutV3} from '../../../layout/AppLayoutV3/AppLayoutV3';
import {RoutePaths} from '../../../lib/router/route-paths';
import {accessControl} from '../../auth/store/auth.sagas';
import {companyDocumentsLoaderSaga} from '../../company/modules/documents/store/company-documents.sagas';
import {clearSignableDocumentsSaga} from '../../document/modules/signing/store/signing.saga';
import {getGlobalPopularArticlesWorker} from '../../documentation/store/documentation.saga';
import {getLoggedInUserAccountFlow} from '../../user/modules/logged-in-user/store/logged-in-user.saga';
import {dashboardV3LoaderSaga} from '../store/dashboard-v3.saga';

const DashboardView = React.lazy(() => import('../../dashboard/components/DashboardView/DashboardView'));

export const DashboardRoutes = {
    element: <AppLayoutV3 suspenseFallback={<SuspenseContainer />} />,
    children: [{
        element: <DashboardView />,
        path: RoutePaths.DASHBOARD,
        locationChangeSideEffects: [
            [accessControl, {
                roles: [],
                isPrivate: true,
            }],
            [appLoaderSaga],
            // TODO: Verify if it is safe to remove dashboardLoaderSaga in favor of DashboardV3LoaderSaga
            // [dashboardLoaderSaga],
            [dashboardV3LoaderSaga],
            [clearSignableDocumentsSaga],
            [companyDocumentsLoaderSaga, {
                documentContext: DOCUMENT_CONTEXTS.PENDING_SIGNING,
            }],
            [getGlobalPopularArticlesWorker],
            // TODO This kills route locationChangeSideEffects flow by setting new user for routerFn
            // It is needed for remuneration for now
            [getLoggedInUserAccountFlow],
        ],
    }],
};
