/* eslint-disable react/require-default-props */
/* eslint-disable func-style */
/* eslint-disable react/boolean-prop-naming */
import {
    faArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    ButtonUnstyled,
    unstable_composeClasses as composeClasses,
    generateUtilityClass,
    generateUtilityClasses,
} from '@mui/base';
import {styled, useThemeProps} from '@mui/system';
import {unstable_capitalize as capitalize} from '@mui/utils';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as React from 'react';

// TODO: ???Filter par

function getTableSortLabelUtilityClass(slot) {
    return generateUtilityClass('MuiTableSortLabel', slot);
}

const tableSortLabelClasses = generateUtilityClasses('MuiTableSortLabel', [
    'root',
    'active',
    'icon',
    'iconDirectionDesc',
    'iconDirectionAsc',
]);

const useUtilityClasses = ownerState => {
    const {classes, direction, active} = ownerState;

    const slots = {
        root: ['root', active && 'active'],
        icon: ['icon', `iconDirection${capitalize(direction)}`],
    };

    return composeClasses(slots, getTableSortLabelUtilityClass, classes);
};

const TableSortLabelRoot = styled(ButtonUnstyled, {
    name: 'MuiTableSortLabel',
    slot: 'Root',
    overridesResolver: (props, styles) => {
        const {ownerState} = props;
        return [styles.root, ownerState.active && styles.active];
    },
})(() => ({
    'cursor': 'pointer',
    'display': 'inline-flex',
    'justifyContent': 'flex-start',
    'flexDirection': 'inherit',
    'alignItems': 'center',
    'color': 'var(--joy-palette-neutral-plainDisabledColor)',
    'fontWeight': 'var(--joy-fontWeight-xl)',
    '&:focus': {
        color: 'var(--joy-palette-text-secondary)',
    },
    '&:hover': {
        color: 'var(--joy-palette-neutral-solidHoverBg)',
        [`& .${tableSortLabelClasses.icon}`]: {
            opacity: 0.5,
        },
    },
    [`&.${tableSortLabelClasses.active}`]: {
        color: 'var(--joy-palette-neutral-solidActiveBg)',
        [`& .${tableSortLabelClasses.icon}`]: {
            opacity: 1,
            color: 'var(--joy-palette-text-secondary)',
        },
    },
}));

const TableSortLabelIcon = styled('span', {
    name: 'MuiTableSortLabel',
    slot: 'Icon',
    overridesResolver: (props, styles) => {
        const {ownerState} = props;
        return [styles.icon, styles[`iconDirection${capitalize(ownerState.direction)}`]];
    },
})(({ownerState}) => ({
    fontSize: 16,
    marginRight: 4,
    marginLeft: 4,
    opacity: 0,
    userSelect: 'none',
    ...(ownerState.direction === 'desc' && {
        transform: 'rotate(0deg)',
    }),
    ...(ownerState.direction === 'asc' && {
        transform: 'rotate(180deg)',
    }),
    color: 'var(--joy-palette-text-secondary)',
}));

/**
 * A button based label for placing inside `TableCell` for column sorting.
 */
const TableSortLabel = React.forwardRef((inProps, ref) => {
    const props = useThemeProps({props: inProps, name: 'MuiTableSortLabel'});
    const {
        active = false,
        children,
        className,
        direction = 'asc',
        hideSortIcon = false,
        ...other
    } = props;

    const ownerState = {
        ...props,
        active,
        direction,
        hideSortIcon,
    };

    const classes = useUtilityClasses(ownerState);

    return (
        <TableSortLabelRoot
            className={clsx(classes.root, className)}
            component="span"
            disableRipple
            ownerState={ownerState}
            ref={ref}
            {...other}
        >
            {children}
            {hideSortIcon && !active ? null : (
                <TableSortLabelIcon
                    className={clsx(classes.icon)}
                    ownerState={ownerState}
                >
                    <FontAwesomeIcon icon={faArrowDown} fixedWidth />
                </TableSortLabelIcon>
            )}
        </TableSortLabelRoot>
    );
});

TableSortLabel.propTypes /* remove-proptypes */ = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // |     To update them edit the d.ts file and run "yarn proptypes"     |
    // ----------------------------------------------------------------------
    /**
     * If `true`, the label will have the active styling (should be true for the sorted column).
     * @default false
     */
    active: PropTypes.bool,
    /**
     * Label contents, the arrow will be appended automatically.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The current sort direction.
     * @default 'asc'
     */
    direction: PropTypes.oneOf(['asc', 'desc']),
    /**
     * Hide sort icon when active is false.
     * @default false
     */
    hideSortIcon: PropTypes.bool,
    /**
     * The system prop that allows defining system overrides as well as additional CSS styles.
     */
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
        PropTypes.func,
        PropTypes.object,
    ]),
};

export default TableSortLabel;
