import {Box, CircularProgress, Skeleton, Typography} from '@mui/material';
import {useSelector} from 'react-redux';
// eslint-disable-next-line import/no-cycle
import BankForm from './BankForm';
import {CompanyInformationForm} from './CompanyInformationForm';
import {ContactInformationForm} from './ContactInformationForm';
import {DataValidatedScreen} from './DataValidatedScreen';
import {DocumentsUploadForm} from './DocumentsUploadForm';
import {InitialScreen} from './InitialScreen';
import {NonConvictionDeclarationForm} from './NonConvictionDeclarationForm';
import {PersonalInformationForm} from './PersonalInformationForm';
import {useMemoizedCreateSelector} from '../../../../../hooks/use-memoized-create-selector';
// eslint-disable-next-line import/no-cycle
import {
    DocumentsSigningOnboardingWrapper,
} from '../../../../document/modules/signing/components/document-wrappers/DocumentsSigningOnboardingWrapper';
import {LoadingTypes, useLoadingState} from '../../../../loading';
import {FreelancerSelectors} from '../../../store/freelancer.selectors';
import {CompaniesSelectors} from '../../companies';
import {DocumentGeneration} from '../../document-generation/components/DocumentGeneration';
import {WeVerifyDocuments} from '../../document-generation/components/WeVerifyDocuments';
import {OnboardingSelectors} from '../../onboarding/store/onboarding.selectors';
import {CompanySetupInternalSubSteps, CompanySetupSubSteps} from '../utils/constants';

export const CompanySetup = () => {
    const isLoading = useLoadingState(LoadingTypes.ONBOARDING);

    const subStep = useSelector(OnboardingSelectors.selectSubStep);
    const internalSubStep = useSelector(OnboardingSelectors.selectInternalSubStep);

    const freelancer = useSelector(FreelancerSelectors.selectAccount);

    const company = useMemoizedCreateSelector(
        CompaniesSelectors.createCompanyByIdSelector,
        freelancer.defaultCompanyId,
    );

    const hasSubmitModificationsButton = company.status === 'PENDING_EDITS' || company.status === 'READY_FOR_REVIEW';

    if (isLoading) {
        return (
            <>
                <Skeleton sx={{transform: 'none'}} height={39} width={277}>
                    <Typography variant="h3" />
                </Skeleton>

                <Box
                    mt={3}
                    minHeight={320}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius={4}
                    backgroundColor="#fff"
                    boxShadow="0 1px 1px 0 rgb(11 35 51 / 5%), 0 1px 1px -1px rgb(11 35 51 / 5%), 0 1px 3px 0 rgb(11 35 51 / 17%)"
                >
                    <CircularProgress />
                </Box>
            </>
        );
    }

    if (subStep === CompanySetupSubSteps.PERSONAL_INFORMATION) {
        return (
            <PersonalInformationForm hasSubmitModificationsButton={hasSubmitModificationsButton} />
        );
    } else if (subStep === CompanySetupSubSteps.ADDRESS_INFORMATION) {
        return (
            <ContactInformationForm hasSubmitModificationsButton={hasSubmitModificationsButton} />
        );
    } else if (subStep === CompanySetupSubSteps.DECLARATION_OF_NON_CONVICTION) {
        return (
            <NonConvictionDeclarationForm hasSubmitModificationsButton={hasSubmitModificationsButton} />
        );
    } else if (subStep === CompanySetupSubSteps.COMPANY_INFORMATION) {
        return (
            <CompanyInformationForm hasSubmitModificationsButton={hasSubmitModificationsButton} />
        );
    } else if (subStep === CompanySetupSubSteps.BANK) {
        return (
            <BankForm hasSubmitModificationsButton={hasSubmitModificationsButton} />
        );
    } else if (subStep === CompanySetupSubSteps.DOCUMENT_UPLOAD) {
        return (
            <DocumentsUploadForm hasSubmitModificationsButton={hasSubmitModificationsButton} />
        );
    } else if (subStep === CompanySetupSubSteps.DOCUMENT_GENERATION) {
        if (internalSubStep === CompanySetupInternalSubSteps.DOCUMENT_SIGNING) {
            return (
                <DocumentsSigningOnboardingWrapper />
            );
        } else if (internalSubStep === CompanySetupInternalSubSteps.AWAITING_DOCUMENT_VERIFICATION) {
            return (
                <WeVerifyDocuments />
            );
        }

        return (
            <DocumentGeneration />
        );
    } else if (subStep === CompanySetupSubSteps.DATA_VALIDATED) {
        return (
            <DataValidatedScreen />
        );
    }

    return (
        <>
            <InitialScreen />
        </>
    );
};
