import {combineReducers} from 'redux';
import {MissionsCraActionTypes} from './missions-cra.action-type';
import {createReducer} from '../../../../../app/utils/store/create-reducer';
import {MISSION_CRA_SCREENS} from '../../../utils/constants';

export const missionsCraReducer = combineReducers({
    contract: createReducer(null, MissionsCraActionTypes.STORE_CRA_CONTRACT, true),
    screen: createReducer(MISSION_CRA_SCREENS.CREATE_FORM, MissionsCraActionTypes.CHANGE_MISSION_CRA_SCREEN, true),
    documents: createReducer([], MissionsCraActionTypes.STORE_CRA_DOCUMENTS, true),
});
