import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Button, Link, Paper, SvgIcon, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {InsuranceActions} from './store/insurance.action';
import {InsuranceType} from './utils/constants';
import {ReactComponent as TaskAlt} from '../../assets/svg/task-alt.svg';
import {LocalesConstants} from '../../utils/locales-constants';

const InsuranceFinalPage = ({insuranceType}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation(LocalesConstants.Insurance);
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const handleFinalization = () => dispatch(InsuranceActions.finalizeInsurance());

    return (
        <Paper
            elevation={1}
            sx={{
                display: 'flex',
                padding: 2.5,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                flex: '1 0 0',
                alignSelf: 'stretch',
                borderRadius: 2,
                height: isMobileSize ? 'calc(100vh - 170px)' : 'calc(100vh - 56px)',
                p: isMobileSize ? 3 : 5,
            }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                flex: '1 0 0',
                alignSelf: 'stretch',
                borderRadius: 2,
                backgroundColor: 'v2.gray.50',
                p: isMobileSize ? 3 : 0,
            }}
            >
                <SvgIcon
                    component={TaskAlt}
                    inheritViewBox
                    sx={{
                        width: isMobileSize ? '150px' : '192px',
                        height: isMobileSize ? '150px' : '192px',
                    }}
                />
                <Box sx={{
                    maxWidth: '820px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 0.5,
                }}
                >
                    <Typography sx={{
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                        fontSize: theme => theme.typography.pxToRem(isMobileSize ? 28 : 34),
                        fontWeight: 700,
                        lineHeight: '116.7%',
                    }}
                    >
                        {t(`finalPage.${insuranceType}.title`)}
                    </Typography>
                    <Typography sx={{textAlign: 'center', maxWidth: '550px'}}>
                        {t(`finalPage.${insuranceType}.subtitle`)}
                    </Typography>
                    {insuranceType === InsuranceType.RC ? (
                        <Box
                            color="inherit"
                            sx={{
                                fontSize: theme => theme.typography.pxToRem(13),
                                textAlign: 'center',
                                py: 4,
                                maxWidth: '550px',
                                color: 'inherit',
                            }}
                        >
                            <Trans
                                i18nKey={t('finalPage.RC.additionalInfo')}
                                components={{
                                    anchor: <Link
                                        underline="hover"
                                        color="inherit"
                                        sx={{fontWeight: 600}}
                                        rel="noopener noreferrer"
                                        href="mailto:paie@hiway.fr"
                                            />,
                                    strong: <Typography
                                        sx={{
                                            fontSize: theme => theme.typography.pxToRem(13),
                                            fontWeight: 600,
                                            display: 'inline',
                                        }}
                                            />,
                                }}
                            />
                        </Box>
                    ) : null}
                </Box>
                <Button
                    variant="contained"
                    size="large"
                    onClick={handleFinalization}
                    endIcon={<ArrowForwardIcon />}
                    sx={{
                        width: isMobileSize ? '100%' : 'auto',
                        height: '56px',
                    }}
                >
                    {t(`finalPage.${insuranceType}.cta`)}
                </Button>
            </Box>
        </Paper>
    );
};

InsuranceFinalPage.propTypes = {
    insuranceType: PropTypes.string.isRequired,
};

export default InsuranceFinalPage;
