import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import DOAFile from './DOAFile/DOAFile';
import FlowStepWrapper from './FlowStepWrapper';
import Alert from '../../../../../../components/ui-kit/Alert/Alert';
import UploadDocuments from '../../../../../../components/ui-kit/UploadDocuments';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import CategorisationSection from '../../../../../bank/modules/account-balance/components/categorisation/components/header/CategorisationSection';
import {getCompanyDocument} from '../../../../../company/store/company.actions';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {AccountingActions} from '../../../accounting/store/accounting.action';
import {FORMALITY_SECTION_STATUS} from '../../utils/constants';

const FlowStepBalanceSheetUpload = ({
    annualAccountsId,
    balanceSheet,
    isBalanceSheetLocked,
    onBalanceSheetReset,
    isActive,
    isFocused,
}) => {
    const {t} = useTranslation(LocalesConstants.Companies);
    const [files, setFiles] = useState([]);
    const dispatch = useDispatch();
    const {
        freelancerId,
        companyId,
    } = useParams();

    const handleFileChange = newFiles => {
        setFiles(newFiles);

        if (newFiles?.length === 1) {
            dispatch(AccountingActions.uploadBalanceSheet({
                companyId,
                annualAccountsId,
                file: newFiles[0],
            }));
        }
    };

    const handleViewFile = () => {
        dispatch(getCompanyDocument(freelancerId, companyId, balanceSheet.id));
    };

    const handleDownloadFile = () => {
        dispatch(getCompanyDocument(freelancerId, companyId, balanceSheet.id, true));
    };

    const handleReplaceFile = () => {
        setFiles([]);
        setBalanceSheetInUse(false);
        onBalanceSheetReset();
    };

    const [balanceSheetInUse, setBalanceSheetInUse] = useState(isBalanceSheetLocked);

    const isUploadingOrDeletingBalanceSheet = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.UPLOAD_BALANCE_SHEET_FILE),
    );

    useEffect(() => {
        setBalanceSheetInUse(!!Object.keys(balanceSheet).length || isBalanceSheetLocked);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [balanceSheet]);

    return (
        <FlowStepWrapper>
            <CategorisationSection
                title={t('formalities.depositOfAccounts.flowSteps.reportUpload.title')}
                status={isFocused && FORMALITY_SECTION_STATUS.REQUIRED}
                isActive={isActive}
                isFocused={isActive && isFocused}
                statusTranslationSource="companies"
                statusTranslationPath="accounting.uploadFec.statuses"
                sx={{width: '100%', maxWidth: '600px'}}
            >
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <Typography variant="h6">
                        {t('formalities.depositOfAccounts.flowSteps.reportUpload.subtitle')}
                    </Typography>
                    {!balanceSheetInUse && (
                    <Alert
                        title=""
                        severity="info"
                        elevation={0}
                        message={t('formalities.depositOfAccounts.flowSteps.reportUpload.message')}
                        sx={{mb: 0}}
                    />
                    )}
                    <Box
                        sx={{
                            width: '100%',
                        }}
                    >
                        {!balanceSheetInUse && (
                            <UploadDocuments
                                acceptFiles="application/pdf"
                                fileLimit={1}
                                files={files}
                                buttonText={t('accounting.uploadFEC.fileUploadButton')}
                                dropZoneText={t('formalities.depositOfAccounts.flowSteps.reportUpload.uploadMessage')}
                                onAddFiles={handleFileChange}
                                onDeleteFile={() => {}}
                                isUploadInProgress={isUploadingOrDeletingBalanceSheet}
                                isDisabled={isUploadingOrDeletingBalanceSheet}
                            />
                        )}
                        {balanceSheetInUse && (
                            <DOAFile
                                fileName={balanceSheet.originalFilename}
                                onView={handleViewFile}
                                onDownload={handleDownloadFile}
                                onReplace={handleReplaceFile}
                                isDeletingPossible={!isBalanceSheetLocked}
                            />
                        )}
                    </Box>
                </Box>
            </CategorisationSection>
        </FlowStepWrapper>
    );
};

FlowStepBalanceSheetUpload.propTypes = {
    annualAccountsId: PropTypes.string.isRequired,
    balanceSheet: PropTypes.object.isRequired,
    isBalanceSheetLocked: PropTypes.bool.isRequired,
    onBalanceSheetReset: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
    isFocused: PropTypes.bool.isRequired,
};

export default React.memo(FlowStepBalanceSheetUpload);
