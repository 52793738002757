export const SET_CURRENT_COMPANY_DOCUMENT = 'company/companyDocuments/current-document/SET_CURRENT_COMPANY_DOCUMENT';
export const STORE_CURRENT_DOCUMENT_URL = 'company/companyDocuments/current-document/STORE_CURRENT_DOCUMENT_URL';
export const SET_IS_LOADING_CURRENT_DOCUMENT = 'company/companyDocuments/current-document/SET_IS_LOADING_CURRENT_DOCUMENT';
export const SIGN_CURRENT_DOCUMENT = 'company/companyDocuments/current-document/SIGN_CURRENT_DOCUMENT';
export const SET_IS_SIGNING_CURRENT_DOCUMENT = 'company/companyDocuments/current-document/SET_IS_SIGNING_CURRENT_DOCUMENT';
export const SET_IS_RESIGNING_DOCUMENT = 'company/companyDocuments/current-document/SET_IS_RESIGNING_DOCUMENT';

export const setCurrentCompanyDocument = documentId => ({
    type: SET_CURRENT_COMPANY_DOCUMENT,
    payload: documentId,
});

export const storeCurrentDocumentUrl = documentUrl => ({
    type: STORE_CURRENT_DOCUMENT_URL,
    payload: documentUrl,
});

export const setIsLoadingCurrentDocument = isLoading => ({
    type: SET_IS_LOADING_CURRENT_DOCUMENT,
    payload: isLoading,
});

export const signCurrentDocument = () => ({
    type: SIGN_CURRENT_DOCUMENT,
});

export const setIsSigningCurrentDocument = isSigning => ({
    type: SET_IS_SIGNING_CURRENT_DOCUMENT,
    payload: isSigning,
});

export const setIsResigningDocument = isResigning => ({
    type: SET_IS_RESIGNING_DOCUMENT,
    payload: isResigning,
});

