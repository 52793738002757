export const selectCompany = state => state.company.singleCompany;

export const selectIsLoadingSingleCompany = state => state.company.isLoadingSingleCompany;

export const selectIsUpdatingPersonalInformation = state => state.company.isUpdatingPersonalInformation;

export const selectIsUpdatingNonConvictionDeclaration = state => state.company.isUpdatingNonConvictionDeclaration;

export const selectIsUpdatingCompanyInformation = state => state.company.isUpdatingCompanyInformation;

export const selectIsApprovingCompanyInProgress = state => state.company.isApprovingCompanyInProgress;

export const selectIsRequestingEditsInProgress = state => state.company.isRequestingEditsInProgress;

export const selectCompanyDocuments = state => state.company.companyPersonalDocuments;

export const selectIsLoadingCompanyDocuments = state => state.company.isLoadingPersonalCompanyDocuments;

export const selectUploadingDocumentIds = state => state.company.uploadingDocumentIds;

export const selectIsLoadingCompanyProfileView = state => state.company.isLoadingCompanyProfileView;
