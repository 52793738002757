import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import {Box, Button, Typography} from '@mui/material';
import Chip from '@mui/material/Chip';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import FakeReimbursementTransaction from './FakeReimbursementTransaction';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';
import {roundHalfUpToTwoDecimalPlaces} from '../../../../../../../../utils/number';
import {SettingsSelectors} from '../../../../../../../settings/store/settings.selectors';
import {TransactionsSelector} from '../../../../store/transactions.selector';
import {findCategoryNameInList} from '../../utils/category';

export const ReimbursedPanelInfo = ({
    amount,
    allocated,
    onOpenRembursement,
    selectedReimbursementTransactionsArray,
    setIsFakeReimbursementOpen,
    onSelectTransaction,
}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const {t} = useTranslation(LocalesConstants.Bank);
    const percents = !amount
        ? 100
        : Math.round(100 * allocated / amount);

    const isCompleted = percents >= 100;

    const categoryList = useSelector(TransactionsSelector.selectCategories);

    const userSettings = useSelector(SettingsSelectors.selectSettings);
    const language = userSettings?.language ?? 'fr';

    return (
        <Box sx={{
            backgroundColor: 'gray_v2.50',
            borderRadius: '16px',
            padding: '16px 24px 24px',
            mb: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
        }}
        >
            <Box sx={{letterSpacing: '0.15px'}}>
                {isMobileSize && (
                    <ReimbursementButton
                        isCompleted={isCompleted}
                        onOpenRembursement={onOpenRembursement}
                        isMobileSize={isMobileSize}
                        t={t}
                    />
                )}

                <Typography
                    component="span"
                    sx={{
                        fontWeight: 500,
                        color: 'text_v2.secondary',
                    }}
                >
                    {t('transactions.allocatedAmount')}&nbsp;&nbsp;
                </Typography>

                <Typography
                    component="span"
                    sx={{
                        fontWeight: 500,
                        color: 'text_v2.secondary',
                    }}
                >
                    {roundHalfUpToTwoDecimalPlaces(allocated)}€
                    &nbsp;/&nbsp;
                    {roundHalfUpToTwoDecimalPlaces(amount)}€
                </Typography>

                {!isMobileSize && (
                    <ReimbursementButton
                        isCompleted={isCompleted}
                        onOpenRembursement={onOpenRembursement}
                        isMobileSize={isMobileSize}
                        t={t}
                    />
                )}

                <Box sx={{
                    mt: 1,
                    height: '4px',
                    borderRadius: '2px',
                    backgroundColor: 'primary_v2.shades60',
                    position: 'relative',
                }}
                >
                    <Box sx={{
                        height: '4px',
                        borderRadius: '2px',
                        backgroundColor: isCompleted ? 'success_v2.light' : 'primary_v2.main',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: `${percents}%`,
                    }}
                    />
                </Box>

                {selectedReimbursementTransactionsArray?.length > 0 && (
                    <Box sx={{
                        mt: 2,
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1,
                    }}
                    >
                        {selectedReimbursementTransactionsArray.map(reimbursement => {
                            if (reimbursement?.isFakeReimbursement) {
                                return (
                                    <FakeReimbursementTransaction
                                        key={reimbursement?.id}
                                        category={reimbursement?.category}
                                        amount={reimbursement?.reimbursedAmount}
                                        setIsFakeReimbursementOpen={setIsFakeReimbursementOpen}
                                        hasDelete={false}
                                    />
                                );
                            }

                            const categoryName = findCategoryNameInList(
                                categoryList,
                                reimbursement?.category,
                                language,
                                reimbursement?.category,
                            );

                            return (
                                <Chip
                                    key={reimbursement?.id}
                                    onDelete={() => onSelectTransaction(reimbursement.id)}
                                    color="primary"
                                    label={`${reimbursement?.label} • ${reimbursement?.amount}€ • ${moment(reimbursement?.createdAt).format('DD MMM YYYY')} ${categoryName ? '• ' + categoryName : ''}`}
                                />
                            );
                        })}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

ReimbursedPanelInfo.propTypes = {
    amount: PropTypes.number.isRequired,
    allocated: PropTypes.number.isRequired,
    onOpenRembursement: PropTypes.func.isRequired,
    selectedReimbursementTransactionsArray: PropTypes.array,
    setIsFakeReimbursementOpen: PropTypes.func.isRequired,
    onSelectTransaction: PropTypes.func.isRequired,
};

ReimbursedPanelInfo.defaultProps = {
    selectedReimbursementTransactionsArray: [],
};

const ReimbursementButton = ({
    isCompleted,
    onOpenRembursement,
    isMobileSize,
    t,
}) => {
    return (
        <Box sx={isMobileSize ? {
            display: 'flex',
            mb: 1.5,
            justifyContent: 'flex-end',
        } : {
            display: 'inline',
            float: 'right',
            position: 'relative',
            bottom: '10px',
        }}
        >
            {isCompleted ? (
                <Button
                    onClick={onOpenRembursement}
                    startIcon={<EditIcon />}
                >
                    {t(`categorisation.reimbursementPanel.editButton`)}
                </Button>
            ) : (
                <Button
                    onClick={onOpenRembursement}
                    startIcon={<AddBoxIcon />}
                >
                    {t(`categorisation.reimbursementPanel.addButton`)}
                </Button>
            )}
        </Box>
    );
};

ReimbursementButton.propTypes = {
    isCompleted: PropTypes.bool.isRequired,
    onOpenRembursement: PropTypes.func.isRequired,
    isMobileSize: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
};
