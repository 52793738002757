import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {MissionClientsActionType} from './missions-client.action-type';
import {MissionClientsActions} from './missions-client.actions';
import {MissionsClientSelector} from './missions-client.selector';
import {isMobileSafari, isSafari} from '../../../../../app/utils/app.helpers';
import {Toast} from '../../../../../lib/toast';
import {Debug} from '../../../../../utils/debug';
import {SESSION_NAMES, SessionUtil} from '../../../../../utils/session';
import {SigningActions} from '../../../../document/modules/signing/store/signing.action';
import {LoadingActions, LoadingTypes} from '../../../../loading';
import {SignatureActions} from '../../../../signature/store/signature.action';
import {SIGNING_OPTIONS} from '../../../../signature/utils/constants';
import {dataURLToBlob} from '../../../../signature/utils/functions';
import {UiActions} from '../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../ui/utils/constants';
import {MissionClientApi} from '../api/missions-client.api';
import {MISSION_CLIENT_SCREENS, MissionContractDocumentStatus} from '../utils/constants';

const flow = {
    getDocumentInfo: function* ({
        missionDocumentId,
        documentType,
        missionId,
    }) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

            const document = yield call(MissionClientApi.getDocumentInfo, {
                missionDocumentId,
                documentType,
                missionId,
            });

            yield put(MissionClientsActions.storeDocumentInfo(document));

            if (document?.missionDocumentStatus === MissionContractDocumentStatus.ModificationRequested) {
                yield put(MissionClientsActions.postSetClientScreen(MISSION_CLIENT_SCREENS.REQUESTED_MODIFICATIONS));
                yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
                return;
            }

            yield put(SigningActions.setCurrentDocumentUrl(document?.signedUrl));
            yield put(SigningActions.setCurrentDocumentId(document?.signedUrl)); // Needed any truthy value
            yield put(SigningActions.setCurrentDocumentIsSigned(false));
            yield put(SigningActions.setIsSigningCurrentDocument(false));
            yield put(SigningActions.setIsLoadingCurrentDocument(false));
        } catch (error) {
            Debug.error('getDocumentInfo', 'Error: ', {error});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
        }
    },

    getClientSignature: function* ({
        missionId,
    }) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

            const signature = yield call(MissionClientApi.getClientSignature, {
                missionId,
            });

            yield put(MissionClientsActions.storeClientSignature(signature));
            yield put(SignatureActions.storeSignatureUrl(signature?.signedUrl)); // Needed for preview
        } catch (error) {
            Debug.error('getClientSignature', 'Error: ', {error});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
        }
    },

    postClientSignature: function* ({
        file,
        type,
        missionId,
    }) {
        yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_SIGNATURE, true));

        try {
            let signature;
            if (type === SIGNING_OPTIONS.UPLOAD) {
                signature = yield call(MissionClientApi.postClientSignature, {
                    missionId,
                    file,
                });
            }

            if (type === SIGNING_OPTIONS.DRAW) {
                const blob = dataURLToBlob(file);

                signature = yield call(MissionClientApi.postClientSignature, {
                    missionId,
                    file: blob,
                });
            }

            yield put(MissionClientsActions.storeClientSignature(signature));
            yield put(SignatureActions.storeSignatureUrl(signature?.signedUrl)); // Needed for preview

            yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_SIGNATURE, false));
        } catch (error) {
            Debug.error('getClientSignature', 'Error: ', {error});
            Toast.error('genericError');

            yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_SIGNATURE, false));
        }
    },

    postSignDocument: function* ({
        missionDocumentId,
        documentType,
        missionId,
    }) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.SIGN_CONTRACT, true));

            yield call(MissionClientApi.postSignDocument, {
                missionDocumentId,
                documentType,
                missionId,
            });

            yield call(flow.postValidateDocument, {
                missionDocumentId,
                documentType,
                missionId,
            });

            // TODO BE Needs to extend token for this to work
            // In MissionClientApi.postSignDocument response is only new signed url, but we need statuses also
            yield put(MissionClientsActions.getDocumentInfo());
        } catch (error) {
            Debug.error('postSignDocument', 'Error: ', {error});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.SIGN_CONTRACT, false));
        }
    },

    postValidateDocument: function* ({
        missionDocumentId,
        documentType,
        missionId,
    }) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

            const document = yield call(MissionClientApi.postValidateDocument, {
                missionDocumentId,
                documentType,
                missionId,
            });

            yield put(MissionClientsActions.storeDocumentInfo(document));
        } catch (error) {
            Debug.error('postValidateDocument', 'Error: ', {error});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
        }
    },

    postRequireModifications: function* ({
        missionDocumentId,
        documentType,
        missionId,
        comment,
    }) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

            yield call(MissionClientApi.postRequireModification, {
                missionDocumentId,
                documentType,
                missionId,
                comment,
            });

            yield call(flow.getDocumentInfo, {
                missionDocumentId,
                documentType,
                missionId,
            });

            yield put(MissionClientsActions.postSetClientScreen(MISSION_CLIENT_SCREENS.REQUESTED_MODIFICATIONS));
        } catch (error) {
            Debug.error('postRequireModifications', 'Error: ', {error});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
        }
    },
};

const workers = {
    getDocumentInfo: function* () {
        const missionId = SessionUtil.getSession(SESSION_NAMES.PUBLIC_USER_MISSION_ID);
        const documentType = SessionUtil.getSession(SESSION_NAMES.PUBLIC_USER_DOCUMENT_TYPE);
        const missionDocumentId = SessionUtil.getSession(SESSION_NAMES.PUBLIC_USER_CONTRACT_ID);

        yield call(flow.getDocumentInfo, {
            missionId,
            documentType,
            missionDocumentId,
        });
    },
    getClientSignature: function* () {
        const missionId = SessionUtil.getSession(SESSION_NAMES.PUBLIC_USER_MISSION_ID);
        const documentType = SessionUtil.getSession(SESSION_NAMES.PUBLIC_USER_DOCUMENT_TYPE);
        const missionDocumentId = SessionUtil.getSession(SESSION_NAMES.PUBLIC_USER_CONTRACT_ID);

        yield call(flow.getClientSignature, {
            missionId,
            documentType,
            missionDocumentId,
        });
    },
    postClientSignature: function* ({payload}) {
        const missionId = SessionUtil.getSession(SESSION_NAMES.PUBLIC_USER_MISSION_ID);

        try {
            yield call(flow.postClientSignature, {
                missionId,
                type: payload?.type,
                file: payload?.file,
            });

            yield put(UiActions.setActiveModal(ModalsKeys.UPLOAD_SIGNATURE, false));
        } catch (error) {
            Debug.error('postClientSignature worker', 'Error: ', {error});
            Toast.error('genericError');
        }
    },
    postSignDocument: function* () {
        const missionId = SessionUtil.getSession(SESSION_NAMES.PUBLIC_USER_MISSION_ID);
        const documentType = SessionUtil.getSession(SESSION_NAMES.PUBLIC_USER_DOCUMENT_TYPE);
        const missionDocumentId = SessionUtil.getSession(SESSION_NAMES.PUBLIC_USER_CONTRACT_ID);

        yield call(flow.postSignDocument, {
            missionId,
            documentType,
            missionDocumentId,
        });
    },
    postValidateDocument: function* () {
        const missionId = SessionUtil.getSession(SESSION_NAMES.PUBLIC_USER_MISSION_ID);
        const documentType = SessionUtil.getSession(SESSION_NAMES.PUBLIC_USER_DOCUMENT_TYPE);
        const missionDocumentId = SessionUtil.getSession(SESSION_NAMES.PUBLIC_USER_CONTRACT_ID);

        yield call(flow.postValidateDocument, {
            missionId,
            documentType,
            missionDocumentId,
        });
    },
    postRequireModifications: function* ({payload}) {
        const missionId = SessionUtil.getSession(SESSION_NAMES.PUBLIC_USER_MISSION_ID);
        const documentType = SessionUtil.getSession(SESSION_NAMES.PUBLIC_USER_DOCUMENT_TYPE);
        const missionDocumentId = SessionUtil.getSession(SESSION_NAMES.PUBLIC_USER_CONTRACT_ID);

        yield call(flow.postRequireModifications, {
            missionId,
            documentType,
            missionDocumentId,
            comment: payload,
        });

        yield put(UiActions.setActiveModal(ModalsKeys.DEMAND_MODIFICATIONS_MODAL, false));
    },
    downloadDocument: function* () {
        const document = yield select(MissionsClientSelector.selectDocumentInfo);

        if (!document?.signedUrl) {
            return;
        }

        const signedUrl = document.signedUrl;

        if (isSafari || isMobileSafari) {
            // TODO:HIGH: It's ugly but it works.
            fetch(signedUrl).then(response => {
                return response.blob();
            }).then(blob => {
                const matchedGroups = signedUrl.match(/filename[^;=\n]*%3D(%22(.*)%22[^;\n]*)/);
                const filename = matchedGroups[2];

                window.saveAs(blob, decodeURI(filename));
            });

            return;
        }

        window.open(signedUrl, '_blank');
    },
};


export const watchMissionsClientSaga = function* () {
    yield all([
        takeEvery(MissionClientsActionType.GET_DOCUMENT_INFO, workers.getDocumentInfo),
        takeEvery(MissionClientsActionType.GET_CLIENT_SIGNATURE, workers.getClientSignature),
        takeEvery(MissionClientsActionType.POST_CLIENT_SIGNATURE, workers.postClientSignature),
        takeEvery(MissionClientsActionType.POST_SIGN_DOCUMENT, workers.postSignDocument),
        takeEvery(MissionClientsActionType.POST_VALIDATE_DOCUMENT, workers.postValidateDocument),
        takeEvery(MissionClientsActionType.POST_REQUIRE_MODIFICATIONS, workers.postRequireModifications),
        takeEvery(MissionClientsActionType.DOWNLOAD_DOCUMENT, workers.downloadDocument),
    ]);
};
