import {Box, Chip} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ConditionalTooltip} from '../../../../components/tooltip/ConditionalTooltip';
import {LocalesConstants} from '../../../../utils/locales-constants';

const NumberOfBids = ({rowData, isMobile}) => {
    const {t} = useTranslation(LocalesConstants.Opportunity);
    const {numberOfBids, accepted} = rowData;

    const tooltip = accepted ? t('freelancerOpportunity.accomplished') : '';
    const bgColor = accepted ? 'success_v2.light' : (
        numberOfBids ? 'v2.blueGray.500' : 'v2.light.text.disabledLight'
    );

    return (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <ConditionalTooltip tooltip={tooltip} placement="top">
                <Chip
                    sx={{
                        textTransform: 'none',
                        fontSize: isMobile ? '14px' : '20px',
                        lineHeight: '20px',
                        fontWeight: '400',
                        height: isMobile ? '20px' : '40px',
                        minWidth: isMobile ? '20px' : '40px',
                        color: '#fff',
                        backgroundColor: bgColor,
                        borderRadius: '100%',
                    }}
                    size="small"
                    label={numberOfBids}
                />
            </ConditionalTooltip>
        </Box>
    );
};

NumberOfBids.propTypes = {
    rowData: PropTypes.any.isRequired,
    isMobile: PropTypes.bool,
};

NumberOfBids.defaultProps = {
    isMobile: false,
};

export default React.memo(NumberOfBids);
