import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Tab} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useTranslation} from 'react-i18next';
import CreateRecurringTransfer from './CreateRecurringTransfer';
import CreateSingleTransfer from './CreateSingleTransfer';
import {InstantTransferModal} from './InstantTransferModal';
import ReactRouterButtonLink from '../../../../../../components/app/router/ReactRouterButtonLink';
import {useQuery} from '../../../../../../hooks/use-url';
import {RoutePaths} from '../../../../../../lib/router/route-paths';
import {LocalesConstants} from '../../../../../../utils/locales-constants';

const TABS = {
    singleTransfer: '1',
    recurringTransfer: '2',
};

const TAB_MAP_TO_CATEGORY = {
    single: TABS.singleTransfer,
    recurring: TABS.recurringTransfer,
};

const CreateNewTransfer = () => {
    const {t} = useTranslation(LocalesConstants.BankTransfer);
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const paramTab = useQuery('tab');

    const [tab, setTab] = React.useState(
        paramTab
            ? TAB_MAP_TO_CATEGORY[paramTab]
            : TABS.singleTransfer,
    );

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <TabContext
            value={tab}
            sx={isMobileSize ? {} : {
                'mx': 3,
                'px': 3,
            }}
        >
            {isMobileSize && (
                <Box>
                    <ReactRouterButtonLink
                        to={RoutePaths.BANK_TRANSFER_LIST}
                        sx={{
                            pl: 3,
                            color: 'v2.blueGray.900',
                            fontSize: theme => theme.typography.pxToRem(22),
                            textTransform: 'none',
                            fontWeight: 400,
                        }}
                        startIcon={(
                            <ArrowBackIcon
                                sx={{
                                    mr: 1,
                                    height: '24px',
                                    width: '24px',
                                }}
                            />
                        )}
                    >
                        {t('transfer.create.topPageBack')}
                    </ReactRouterButtonLink>
                </Box>
            )}
            <Box sx={{
                borderBottom: 1,
                borderColor: 'divider',
                mx: isMobileSize ? 3 : 3,
            }}
            >
                <TabList
                    centered={true}
                    onChange={handleChange}
                >
                    <Tab
                        sx={{width: isMobileSize ? 'inherit' : '240px'}}
                        label={t('transfer.create.unique')}
                        value={TABS.singleTransfer}
                    />
                    <Tab
                        sx={{width: isMobileSize ? 'inherit' : '240px'}}
                        label={t('transfer.create.recurring')}
                        value={TABS.recurringTransfer}
                    />
                </TabList>
            </Box>

            <TabPanel sx={{pt: 2}} value={TABS.singleTransfer}>
                <CreateSingleTransfer />
            </TabPanel>

            <TabPanel sx={{pt: 2}} value={TABS.recurringTransfer}>
                <CreateRecurringTransfer />
            </TabPanel>

            <InstantTransferModal />

        </TabContext>
    );
};

export default CreateNewTransfer;
