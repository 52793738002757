import {yupResolver} from '@hookform/resolvers/yup';
import {Box, FormControlLabel, Switch, TextField, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import NumberFormat from 'react-number-format';
import {useDispatch, useSelector} from 'react-redux';
import {useUpdateProfileFormStyles} from './styles';
import {emailRegexp, phoneRegexp} from '../../../../../app/utils/app.helpers';
import FullAddressAutocomplete from '../../../../../components/address/FullAddressAutocomplete';
import ReactRouterButtonLink from '../../../../../components/app/router/ReactRouterButtonLink';
import {ButtonWithCircularProgress} from '../../../../../components/buttons/ButtonWithCircularProgress';
import {DataItemRow} from '../../../../../components/data-item-row/DataItemRow';
import {DataItemRowFormLabel} from '../../../../../components/data-item-row/DataItemRowFormLabel';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import Yup from '../../../../../lib/yup/yup';
import {LocalesConstants} from '../../../../../utils/locales-constants';
import {USER_ROLES, isUserCareFn, isUserFn} from '../../../../../utils/user-roles';
import {
    createContactInfoAddress,
} from '../../../../company-profile/components/EnterpriseInformationForm/util';
import {
    COMPANY_STATUSES,
    HAS_AVAILABLE_COMPANY_EDIT_ADDRESS_STATUSES,
} from '../../../../company/modules/setup-company/utils/constants';
import {FreelancerSelectors} from '../../../../freelancer';
import {CompaniesSelectors} from '../../../../freelancer/modules/companies';
import {LoggedInUserActions} from '../store/logged-in-user.action';
import {LoggedInUserSelectors} from '../store/logged-in-user.selector';

export const UpdateProfileForm = ({user}) => {
    const styles = useUpdateProfileFormStyles();
    const {t} = useTranslation(LocalesConstants.Setup);
    const dispatch = useDispatch();
    const isFreelancer = isUserFn(user);
    const freelancer = useSelector(FreelancerSelectors.selectAccount);
    const company = useSelector(CompaniesSelectors.createCompanyByIdSelector(freelancer?.defaultCompanyId));
    const useCustomCompanyAddress = company?.enterpriseInformation?.use_custom_company_address;

    const isUserCareRole = isUserCareFn(user);

    // Company address can be editable only if company has statuses available for edit
    // or user is admin and company status is active (so we do not regenerated docs)
    const canEditHeadquartersAddress = company?.status
        && (
            HAS_AVAILABLE_COMPANY_EDIT_ADDRESS_STATUSES.indexOf(company?.status) !== -1
            || (user?.role === USER_ROLES.ADMINISTRATOR && company?.status === COMPANY_STATUSES.ACTIVE)
        );

    const [
        isModifyPersonalAddressSelected,
        setIsModifyPersonalAddressSelected,
    ] = useState(useCustomCompanyAddress && canEditHeadquartersAddress);

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .min(2, t('validations.minMessage'))
            .max(26, t('validations.maxMessage'))
            .noSpecialCharactersInUserName(t('form:validation/v2.string/noSpecialCharacters'))
            .required(t('validations.isRequired', {what: t('firstName')})),
        lastName: Yup.string()
            .min(2, t('validations.minMessage'))
            .max(26, t('validations.maxMessage'))
            .noSpecialCharactersInUserName(t('form:validation/v2.string/noSpecialCharacters'))
            .required(t('validations.isRequired', {what: t('lastName')})),
        email: Yup
            .string()
            .max(255, t('validations.maxMessage'))
            .required(t('validations.isRequired', {what: t('email')}))
            .matches(emailRegexp, {
                message: t('validations.notValidEmail'),
            }),
        phone: Yup
            .string()
            .required(t('validations.isRequired', {what: t('phoneNumber')}))
            .matches(phoneRegexp, {
                message: t('validations.notValidPhone'),
            }),
        ...(isFreelancer && {
            address: Yup.string().required(),
            streetName: Yup.string(),
            streetNumber: Yup.string(),
            zipCode: Yup.string().required(),
            city: Yup.string().required(),
            country: Yup.string(),
            formattedAddress: Yup.string().required(),
        }),
    });

    const {firstName, lastName, email, phone} = user;

    const {control, handleSubmit, formState, setValue, watch} = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            firstName: firstName || '',
            lastName: lastName || '',
            email: email || '',
            phone: phone?.match(/.{1,2}/g).join(' ') || '',
            address: user?.contactInfo?.address,
            streetName: user?.contactInfo?.streetName,
            streetNumber: user?.contactInfo?.streetNumber,
            zipCode: user?.contactInfo?.zipCode,
            city: user?.contactInfo?.city,
            country: user?.contactInfo?.country,
            formattedAddress: createContactInfoAddress(user?.contactInfo),
        },
    });
    const errors = formState.errors;
    const formattedAddress = watch('formattedAddress');

    const onSubmit = data => {
        const propagateAddressChange = useCustomCompanyAddress
            && isModifyPersonalAddressSelected
            && canEditHeadquartersAddress;
        dispatch(LoggedInUserActions.updateUser(
            {
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                contactInfo: {
                    address: data.address,
                    streetName: data.streetName,
                    streetNumber: data.streetNumber,
                    zipCode: data.zipCode,
                    city: data.city,
                    country: data.country,
                },
                phone: data?.phone?.replace(/\s+/g, ''),
            },
            propagateAddressChange,
        ));
    };

    const isUpdatingUser = useSelector(LoggedInUserSelectors.selectIsUpdatingUser);

    const fieldProps = {
        control,
        variant: 'outlined',
        disabled: isUpdatingUser,
        fullWidth: true,
        required: true,
    };

    const onPlaceDetailsChange = useCallback(value => {
        const address = (value.streetNumber ?? '') + ' ' + (value.street ?? '');
        setValue('formattedAddress', value.formattedAddress, {shouldDirty: true, shouldValidate: true});
        setValue('address', address, {shouldDirty: true, shouldValidate: true});
        setValue('streetName', value.street ?? '', {shouldDirty: true, shouldValidate: true});
        setValue('streetNumber', value.streetNumber ?? '', {shouldDirty: true, shouldValidate: true});
        setValue('zipCode', value.zipCode ?? '', {shouldDirty: true, shouldValidate: true});
        setValue('city', value.city ?? '', {shouldDirty: true, shouldValidate: true});
        setValue('country', value.country ?? '', {shouldDirty: true, shouldValidate: true});
    }, [setValue]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <DataItemRow
                label={(
                    <DataItemRowFormLabel htmlFor="firstName">
                        {t('users:userInformation.firstName')}
                    </DataItemRowFormLabel>
                )}
                content={(
                    <Controller
                        {...fieldProps}
                        id="firstName"
                        name="firstName"
                        render={({field}) => {
                            return (
                                <TextField
                                    fullWidth
                                    error={!!errors?.firstName}
                                    helperText={errors?.firstName?.message}
                                    {...field}
                                />
                            );
                        }}
                    />
                )}
            />

            <DataItemRow
                label={(
                    <DataItemRowFormLabel htmlFor="lastName">
                        {t('users:userInformation.lastName')}
                    </DataItemRowFormLabel>
                )}
                content={(
                    <Controller
                        {...fieldProps}
                        id="lastName"
                        name="lastName"
                        render={({field}) => {
                            return (
                                <TextField
                                    fullWidth
                                    error={!!errors?.lastName}
                                    helperText={errors?.lastName?.message}
                                    {...field}
                                />
                            );
                        }}
                    />
                )}
            />

            <DataItemRow
                label={(
                    <DataItemRowFormLabel htmlFor="email">
                        {t('users:userInformation.email')}
                    </DataItemRowFormLabel>
                )}
                content={(
                    <Controller
                        {...fieldProps}
                        id="email"
                        name="email"
                        type="email"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        render={({field}) => {
                            return (
                                <TextField
                                    fullWidth
                                    error={!!errors?.email}
                                    helperText={errors?.email?.message}
                                    {...field}
                                />
                            );
                        }}
                    />
                )}
            />

            <DataItemRow
                label={(
                    <DataItemRowFormLabel htmlFor="phone">
                        {t('users:userInformation.phone')}
                    </DataItemRowFormLabel>
                )}
                content={(
                    <Controller
                        {...fieldProps}
                        id="phone"
                        name="phone"
                        render={({field}) => {
                            return (
                                <NumberFormat
                                    fullWidth
                                    inputMode="numeric"
                                    type="tel"
                                    customInput={TextField}
                                    allowEmptyFormatting
                                    error={!!errors?.phone?.message}
                                    helperText={errors?.phone?.message}
                                    format="## ## ## ## ##"
                                    mask="#"
                                    {...field}
                                />
                            );
                        }}
                    />
                )}
            />

            {isFreelancer && (
                <DataItemRow
                    label={(
                        <DataItemRowFormLabel htmlFor="address">
                            {t('users:userInformation.personalAddress')}
                        </DataItemRowFormLabel>
                    )}
                    content={(
                        <>
                            <FullAddressAutocomplete
                                id="formattedAddress"
                                address={formattedAddress}
                                fullWidth={true}
                                onPlaceDetailsChange={onPlaceDetailsChange}
                                onAddressChange={() => {}}
                                clearIcon={null}
                                defaultValue={formattedAddress}
                                renderInput={params => (
                                    <Controller
                                        name="formattedAddress"
                                        control={control}
                                        render={({field}) => (
                                            <TextField
                                                variant="outlined"
                                                error={!!errors.address}
                                                helperText={errors.address?.message}
                                                value={field.value}
                                                autoComplete="false"
                                                {...params}
                                                {...field}
                                                {...fieldProps}
                                            />
                                        )}
                                    />
                                )}
                            />
                            {useCustomCompanyAddress && (
                                <Box>
                                    <FormControlLabel
                                        control={(
                                            <Switch
                                                checked={!!isModifyPersonalAddressSelected}
                                                onChange={event => {
                                                    setIsModifyPersonalAddressSelected(event.target.checked);
                                                }}
                                                disabled={!canEditHeadquartersAddress}
                                            />
                                        )}
                                        label={isUserCareRole
                                            ? t('companies:modifyBothPersonalAndHeadquartersAddress.titleCare')
                                            : t('companies:modifyBothPersonalAndHeadquartersAddress.title')}
                                    />
                                    <Typography sx={{
                                        ml: 6,
                                        color: 'rgba(0, 0, 0, 0.38)',
                                        fontSize: theme => theme.typography.pxToRem(12),
                                        whiteSpace: 'pre-line !important',
                                    }}
                                    >
                                        {isUserCareRole
                                            ? t('companies:modifyBothPersonalAndHeadquartersAddress.bodyCare')
                                            : t('companies:modifyBothPersonalAndHeadquartersAddress.body')}
                                    </Typography>
                                </Box>
                            )}
                        </>
                    )}
                />
            )}

            <Box
                component="div"
                sx={{display: 'flex', alignItems: 'center', p: theme => theme.spacing(3), justifyContent: {xs: 'center', md: 'flex-end'}}}
            >
                <ReactRouterButtonLink
                    className={styles.cancelButton}
                    to={RoutePaths.MY_PROFILE}
                    variant="contained"
                    size="large"
                    disabled={isUpdatingUser}
                >
                    {t('common:cancel')}
                </ReactRouterButtonLink>

                <ButtonWithCircularProgress
                    isLoading={isUpdatingUser}
                    buttonProps={{
                        type: 'submit',
                        size: 'large',
                        color: 'secondary',
                        variant: 'contained',
                        disabled: !formState.isValid || isUpdatingUser,
                    }}
                >
                    {t('common:apply')}
                </ButtonWithCircularProgress>
            </Box>
        </form>
    );
};

UpdateProfileForm.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        phone: PropTypes.string,
        contactInfo: PropTypes.object.isRequired,
        role: PropTypes.oneOf(Object.values(USER_ROLES)).isRequired,
    }).isRequired,
};
