import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {Box, Button, Typography} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import FlowStepWrapper from './FlowStepWrapper';
import INPIStepper from './INPIStepper/INPIStepper';
import SectionStatus from './SectionStatus';
import ReactRouterButtonLink from '../../../../../../components/app/router/ReactRouterButtonLink';
import {getConfig} from '../../../../../../config/get-config';
import {BE_DATE_FORMAT} from '../../../../../../utils/constants';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import CategorisationSection from '../../../../../bank/modules/account-balance/components/categorisation/components/header/CategorisationSection';
import {AccountingActions} from '../../../accounting/store/accounting.action';
import {AccountingSelector} from '../../../accounting/store/accounting.selector';
import FormalityStatusTag from '../../components/FormalityStatusTag';
import {DEPOSIT_OF_ACCOUNTS_FLOW_ORDER, DEPOSIT_OF_ACCOUNTS_INPI_STATUS, DEPOSIT_OF_ACCOUNTS_STATUS, FORMALITY_SECTION_STATUS} from '../../utils/constants';

const FlowStepINPI = ({isActive, isFocused, status, companyId, annualAccountId}) => {
    const {t} = useTranslation(LocalesConstants.Companies);
    const dispatch = useDispatch();
    const [hasRefreshedINPI, setHasRefreshedINPI] = useState(false);

    const inpiSubmissionDetailsAll = useSelector(AccountingSelector.selectINPISubmission);
    const inpiSubmissionDetails = inpiSubmissionDetailsAll[annualAccountId];
    const inpiStatus = inpiSubmissionDetails?.status;

    /*
     * TODO: When BE adds support for VALIDATION_PENDING status
     * remove this garbage.
     */
    if (inpiStatus === DEPOSIT_OF_ACCOUNTS_INPI_STATUS.VALIDATION_PENDING) {
        status = DEPOSIT_OF_ACCOUNTS_STATUS.VALIDATION_PENDING;
    }

    const inpiInProcess = inpiSubmissionDetails?.init
        || DEPOSIT_OF_ACCOUNTS_FLOW_ORDER[inpiSubmissionDetails?.status] > 0
        || inpiSubmissionDetails?.retry
        || inpiSubmissionDetails?.error
        || inpiSubmissionDetails?.warnings?.length > 0;

    const shouldDisplayStepper = inpiStatus !== DEPOSIT_OF_ACCOUNTS_INPI_STATUS.REJECTED
                                && inpiStatus !== DEPOSIT_OF_ACCOUNTS_INPI_STATUS.VALIDATED
                                && inpiStatus !== DEPOSIT_OF_ACCOUNTS_INPI_STATUS.AMENDMENT_PENDING;

    useEffect(() => {
        if (!hasRefreshedINPI && inpiSubmissionDetails?.status === DEPOSIT_OF_ACCOUNTS_INPI_STATUS.VALIDATION_PENDING) {
            dispatch(AccountingActions.refreshINPIStatus({
                companyId,
                annualAccountId,
            }));
            setHasRefreshedINPI(true);
        }
    }, [inpiSubmissionDetails, dispatch, companyId, annualAccountId, hasRefreshedINPI]);

    const handleSendToInpi = (retry = false) => {
        dispatch(AccountingActions.sendFormalitiesToINPI(
            {companyId, annualAccountId, retry},
        ));
    };

    return (
        <FlowStepWrapper>
            <CategorisationSection
                title={t('formalities.depositOfAccounts.flowSteps.inpi.title')}
                status={isActive ? FORMALITY_SECTION_STATUS.REQUIRED : FORMALITY_SECTION_STATUS.WAITING_FOR_PAYMENT}
                SectionStatusComponent={SectionStatus}
                isActive={isActive}
                isFocused={isActive && isFocused}
                statusTranslationSource="companies"
                statusTranslationPath="accounting.uploadFec.statuses"
                sx={{flex: 1, width: '100%', maxWidth: '600px'}}
            >
                {(inpiStatus === DEPOSIT_OF_ACCOUNTS_INPI_STATUS.REJECTED
                || inpiStatus === DEPOSIT_OF_ACCOUNTS_INPI_STATUS.AMENDMENT_PENDING) && (
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-start'}}>
                        <Box sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%'}}>
                            <Box>
                                <Typography sx={{fontWeight: 500, fontSize: '16px'}}>
                                    {t('formalities.depositOfAccounts.flowSteps.inpi.dateOfCreation')}:{' '}
                                    {moment(inpiSubmissionDetails?.submissionDate).format(BE_DATE_FORMAT)}
                                </Typography>
                                <Typography sx={{fontSize: '12px'}}>
                                    {t('formalities.depositOfAccounts.flowSteps.inpi.formalityId')}:{' '}
                                    {inpiSubmissionDetails?.integrationId}
                                </Typography>
                            </Box>
                            <FormalityStatusTag status={status} />
                        </Box>
                        <ReactRouterButtonLink
                            sx={{
                                py: 1,
                            }}
                            to={`${getConfig().inpiUrl}/annual-accounts/${inpiSubmissionDetails?.integrationId}`}
                            variant="contained"
                            endIcon={<OpenInNewIcon />}
                            color="error"
                            target="_blank"
                        >
                            {t('formalities.depositOfAccounts.flowSteps.inpi.buttonRegulateOnInpi')}
                        </ReactRouterButtonLink>
                    </Box>
                )}
                {inpiStatus === DEPOSIT_OF_ACCOUNTS_INPI_STATUS.VALIDATED && (
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-start'}}>
                        <Box sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%'}}>
                            <Box>
                                <Typography sx={{fontWeight: 500, fontSize: '16px'}}>
                                    {t('formalities.depositOfAccounts.flowSteps.inpi.dateOfCreation')}:{' '}
                                    {moment(inpiSubmissionDetails?.createdAt).format('DD/MM/YYYY')}
                                </Typography>
                                <Typography sx={{fontSize: '12px'}}>
                                    {t('formalities.depositOfAccounts.flowSteps.inpi.formalityId')}:{' '}
                                    {inpiSubmissionDetails?.integrationId}
                                </Typography>
                            </Box>
                            <FormalityStatusTag status={status} />
                        </Box>
                        <ReactRouterButtonLink
                            sx={{
                                py: 1,
                            }}
                            to={`${getConfig().inpiUrl}/annual-accounts/${inpiSubmissionDetails?.integrationId}`}
                            variant="contained"
                            endIcon={<OpenInNewIcon />}
                            color="info"
                            target="_blank"
                        >
                            {t('formalities.depositOfAccounts.flowSteps.inpi.buttonViewOnInpi')}
                        </ReactRouterButtonLink>
                    </Box>
                )}
                {shouldDisplayStepper && (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                sx={{
                                    py: 1,
                                }}
                                variant="contained"
                                endIcon={<ArrowForwardIcon />}
                                disabled={inpiInProcess}
                                onClick={() => handleSendToInpi()}
                            >
                                {t('formalities.depositOfAccounts.flowSteps.inpi.buttonSendFormality')}
                            </Button>
                            <FormalityStatusTag status={status} />
                        </Box>
                        <INPIStepper
                            inpiSubmissionDetails={inpiSubmissionDetails}
                            isINPIInProcess={inpiInProcess}
                            onRetry={() => handleSendToInpi(true)}
                        />
                    </>
                )}
            </CategorisationSection>
        </FlowStepWrapper>
    );
};

FlowStepINPI.propTypes = {
    isActive: PropTypes.bool.isRequired,
    isFocused: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    companyId: PropTypes.string.isRequired,
    annualAccountId: PropTypes.string.isRequired,
};


export default FlowStepINPI;
