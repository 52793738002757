import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import DropZone from '../../../../components/ui-kit/DropZone';
import {LocalesConstants} from '../../../../utils/locales-constants';

export const OpportunityFiles = ({files, setFiles, errors}) => {
    const {t} = useTranslation(LocalesConstants.Opportunity);

    const onDropAccepted = file => {
        const newFiles = [...files];
        newFiles.push(...file);
        setFiles(newFiles);
    };

    const onDeleteFile = fileName => {
        const remainingFiles = files.filter(file => {
            return file.path !== fileName;
        });
        setFiles(remainingFiles);
    };

    return (
        <Box sx={{mb: 3}}>
            <Typography sx={{
                mb: 1.5,
                fontSize: theme => theme.typography.pxToRem(16),
                fontWeight: 600,
            }}
            >
                {t('submitOpportunity.missionHeader')}
            </Typography>
            <DropZone
                setIsOpen={true}
                isMultiple={true}
                fileLimit={3}
                name="files"
                buttonText={t('submitOpportunity.uploadFileButton')}
                dropZoneText={t('submitOpportunity.uploadFileText')}
                acceptFiles="application/pdf"
                readableFileExtension="PDF"
                onDropAccepted={onDropAccepted}
                onDeleteFile={onDeleteFile}
                files={files}
                errorMessage={errors.files?.message}
            />
        </Box>
    );
};

OpportunityFiles.propTypes = {
    files: PropTypes.array.isRequired,
    setFiles: PropTypes.func.isRequired,
    errors: PropTypes.object,
};

OpportunityFiles.defaultProps = {
    errors: {},
};
