import DangerousIcon from '@mui/icons-material/Dangerous';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {LoadingButton} from '@mui/lab';
import {Box, FormControlLabel, FormGroup, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ButtonAction} from '../../../../components/buttons/ButtonAction';
import {Checkbox} from '../../../../components/checkbox/Checkbox';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {useMemoizedCreateSelector} from '../../../../hooks/use-memoized-create-selector';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {UiActions} from '../../../ui/store/ui.action';
import {UiSelectors} from '../../../ui/store/ui.selector';
import {ModalsKeys} from '../../../ui/utils/constants';

const modalKey = ModalsKeys.FUNDING_APPROVAL;

export const FundingApproveWarningModal = () => {
    const dispatch = useDispatch();

    const [isChecked, setIsChecked] = useState(false);

    const data = useSelector(UiSelectors.createModalDataSelector(modalKey));
    const toValidate = data?.toValidate ?? null;
    const translateSection = toValidate ? 'pendingValidateModal' : 'pendingNonValidateModal';

    const isOpen = useMemoizedCreateSelector(
        UiSelectors.createIsModalActiveSelector, modalKey,
    );

    const onCheckboxClick = e => {
        const checked = e.target.checked;
        setIsChecked(checked);
    };

    const {t} = useTranslation(LocalesConstants.Training);
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isLoadingNextStep = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.TRAINING_STEP_SUBMIT),
    );

    const onSubmitClick = () => {
        data.onSubmitCb();
        dispatch(UiActions.setActiveModal(modalKey, false));
    };

    useEffect(() => {
        if (!isOpen) {
            setIsChecked(false);
        }
    }, [isOpen]);

    return (
        <ModalWrapper
            modalKey={modalKey}
            title={(
                <Typography variant="h4">
                    {t(`${translateSection}.header`)}
                </Typography>
            )}
            buttons={({handleClose}) => (
                <>
                    <ButtonAction
                        fullWidth={isMobileSize}
                        onClick={handleClose}
                        sx={{mr: 3}}
                        color="grey"
                        disabled={isLoadingNextStep}
                    >
                        {t(`${translateSection}.cancel`)}
                    </ButtonAction>

                    <LoadingButton
                        fullWidth={isMobileSize}
                        color="secondary"
                        type="submit"
                        onClick={onSubmitClick}
                        loading={isLoadingNextStep}
                        disabled={!isChecked}
                        variant="contained"
                    >
                        {t(`${translateSection}.submit`)}
                    </LoadingButton>
                </>
            )}
            containerSx={{
                width: '631px',
            }}
            buttonsSx={{
                flexDirection: 'row',
            }}
        >
            <>
                <Box sx={{
                    display: 'flex',
                    mt: {
                        xs: 2,
                        md: 3,
                    },
                    flexDirection: {
                        xs: 'column',
                        md: 'row',
                    },
                }}
                >
                    <Box sx={{
                        display: 'flex',
                        width: {
                            xs: '100%',
                            md: '80px',
                        },
                        height: {
                            xs: '112px',
                            md: '80px',
                        },
                        background: toValidate ? 'rgba(2, 136, 209, 0.04)' : 'rgba(211, 47, 47, 0.04)',
                        borderRadius: 2,
                        flexShrink: 0,
                        justifyContent: 'center',
                        alignItems: 'center',
                        mr: {
                            md: 2,
                            xs: 0,
                        },
                    }}
                    >
                        {toValidate
                            ? (
                                <WarningAmberIcon
                                    sx={{
                                        color: '#0288D1',
                                        fontSize: {
                                            xs: '80px',
                                            md: '44px',
                                        },
                                    }}
                                />
                            )
                            : (
                                <DangerousIcon
                                    sx={{
                                        color: '#D54942',
                                        fontSize: {
                                            xs: '85px',
                                            md: '48px',
                                        },
                                    }}
                                />
                            )
                        }
                    </Box>

                    <Box sx={{
                        fontSize: theme => theme.typography.pxToRem(16),
                        lineHeight: '24px',
                        mt: {
                            xs: 2,
                            md: 0,
                        },
                    }}
                    >
                        {t(`${translateSection}.text`)}
                    </Box>
                </Box>

                <Box sx={{
                    mt: 2,
                    py: 2,
                    pl: 2,
                    background: '#FAFAFA',
                    borderRadius: 2,
                }}
                >
                    <FormGroup>
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    onClick={onCheckboxClick}
                                    checked={isChecked}
                                />
                            )}
                            label={t(`${translateSection}.checkbox`)}
                        />
                    </FormGroup>
                </Box>
            </>
        </ModalWrapper>
    );
};
