import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {MissionSigningActionType} from './missions-signing.action-type';
import {MissionSigningActions} from './missions-signing.actions';
import {push} from '../../../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {Toast} from '../../../../../lib/toast';
import {Debug} from '../../../../../utils/debug';
import {SigningActions} from '../../../../document/modules/signing/store/signing.action';
import {getDocumentInfoFlow} from '../../../../document/store/document.saga';
import {LoadingActions, LoadingTypes} from '../../../../loading';
import {loadSignature} from '../../../../signature/store/signature.saga';
import {UiActions} from '../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../ui/utils/constants';
import {LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';
import {MissionsSelector} from '../../../store/missions.selector';
import {MissionCraStatus, MissionStatus} from '../../client-side/utils/constants';
import {MissionSigningApi} from '../api/missions-signing.api';

const flow = {
    getQuotaContract: function* (id) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.SIGN_CONTRACT, true));
            const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

            const documents = yield call(MissionSigningApi.getMissionContractDocuments,
                {userId: loggedInUser?.id, missionId: id});

            const missionInProgress = yield select(MissionsSelector.selectMissionInProgress);

            let documentId;
            if (documents?.count === 0 || missionInProgress?.status === MissionStatus.QuoteModificationRequested) {
                const generatedDoc = yield call(MissionSigningApi.generateMissionContract,
                    {
                        userId: loggedInUser?.id,
                        id,
                        shouldRegenerate: documents?.count !== 0,
                    });

                // Get document for signing
                yield call(flow.getSignUrlDocument, {documentId: generatedDoc?.documentId});

                yield put(MissionSigningActions.storeGenerateQuoteContract(generatedDoc));

                documentId = generatedDoc?.documentId;
            } else {
                // Get document for signing
                yield call(flow.getSignUrlDocument, {documentId: documents.items[0]?.documentId});

                yield put(MissionSigningActions.storeGenerateQuoteContract(documents.items[0]));

                documentId = documents.items[0]?.documentId;

                // We need to mark doc as signed if status on document items is signed
                if (documents.items[0]?.status === MissionCraStatus.UserSigned) {
                    yield put(SigningActions.setCurrentDocumentIsSigned(true));
                }
            }

            yield call(getDocumentInfoFlow, {documentId});
        } catch (error) {
            Debug.error('getQuotaContract', 'Error: ', {error});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.SIGN_CONTRACT, false));
        }
    },
    signQuotaContract: function* ({missionId, contractId}) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.SIGN_CONTRACT, true));
            const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

            const generatedDoc = yield call(MissionSigningApi.signMissionContract, {
                userId: loggedInUser?.id,
                missionId,
                contractId,
            });

            yield put(MissionSigningActions.storeGenerateQuoteContract({
                ...generatedDoc,
                isSigned: true, // TODO Remove once BE fixes issue
            }));

            // Refresh documents after signing
            const documents = yield call(MissionSigningApi.getMissionContractDocuments,
                {userId: loggedInUser?.id, missionId: missionId});

            // Get signed document url
            yield call(flow.getSignUrlDocument, {documentId: documents.items[0]?.documentId});

            yield put(SigningActions.setCurrentDocumentIsSigned(true));
        } catch (error) {
            Debug.error('signQuotaContract', 'Error: ', {error});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.SIGN_CONTRACT, false));
        }
    },
    sendQuotaContract: function* ({missionId, contractId}) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));
            const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

            yield call(MissionSigningApi.sendMissionContract, {
                userId: loggedInUser?.id,
                missionId,
                contractId,
            });

            yield put(UiActions.setActiveModal(ModalsKeys.CONFIRM_MISSIONS_SENDING, false));

            Toast.success('genericSuccessSave');

            yield put(push(RoutePaths.MISSIONS));
        } catch (error) {
            Debug.error('sendMissionContract', 'Error: ', {error});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
        }
    },
    getSignUrlDocument: function* ({documentId}) {
        try {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

            const data = yield call(MissionSigningApi.getSignUrlDocument, {
                documentId,
            });

            yield put(SigningActions.setCurrentDocumentUrl(data?.signedUrl));
            yield put(SigningActions.setCurrentDocumentId(documentId));
            yield put(SigningActions.setCurrentDocumentIsSigned(false));
            yield put(SigningActions.setIsSigningCurrentDocument(false));
            yield put(SigningActions.setIsLoadingCurrentDocument(false));
        } catch (error) {
            Debug.error('getSignUrlDocument', 'Error: ', {error});
            Toast.error('genericError');
        } finally {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
        }
    },
};

const workers = {
    getQuotaContract: function* ({payload}) {
        yield call(loadSignature);

        yield call(flow.getQuotaContract, payload);
    },
    signQuotaContract: function* ({payload}) {
        if (payload?.missionId && payload?.contractId) {
            yield call(flow.signQuotaContract, {
                missionId: payload.missionId,
                contractId: payload.contractId,
            });
        }
    },
    sendQuotaContract: function* ({payload}) {
        if (payload?.missionId && payload?.contractId) {
            yield call(flow.sendQuotaContract, {
                missionId: payload.missionId,
                contractId: payload.contractId,
            });
        }
    },
};

export const watchMissionsSigningSaga = function* () {
    yield all([
        takeEvery(MissionSigningActionType.GET_QUOTA_CONTRACT, workers.getQuotaContract),
        takeEvery(MissionSigningActionType.SIGN_QUOTA_CONTRACT, workers.signQuotaContract),
        takeEvery(MissionSigningActionType.SEND_QUOTA_CONTRACT, workers.sendQuotaContract),
    ]);
};
