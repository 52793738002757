import DeleteIcon from '@mui/icons-material/Delete';
import EuroTwoToneIcon from '@mui/icons-material/EuroTwoTone';
import WarningIcon from '@mui/icons-material/Warning';
import {Box, Divider, TextField, Typography} from '@mui/material';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import NumberFormat from 'react-number-format';
import {ConditionalTooltip} from '../../../../../../../../components/tooltip/ConditionalTooltip';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';
import SelectedCategory from '../categories/SelectedCategory';
import TVASelect from '../debit/TVASelect';

const Article = ({
    article,
    articleNumber,
    onRemoveArticle,
    onChangeArticle,
    setIsShowAllCategories,
}) => {
    const {t} = useTranslation(LocalesConstants.Bank);

    const [isAmountValid, setIsAmountValid] = useState(true);
    const [isDescValid, setIsDescValid] = useState(true);

    const onAmountChange = debounce(e => {
        const value = e.target.value;
        const newAmount = value ?? 0;

        const isValid = e.target.value !== '' && value >= 0;
        setIsAmountValid(isValid);

        if (newAmount >= 0) {
            onChangeArticle({...article, amount: newAmount});
        }
    }, 400);

    const onDescriptionChange = debounce(e => {
        const newDesc = e.target.value;

        const isValid = newDesc !== '';
        setIsDescValid(isValid);

        onChangeArticle({...article, description: newDesc});
    }, 400);

    const onTvaChange = newTva => {
        onChangeArticle({...article, tva: newTva});
    };

    const onTvaAmountChange = newAmount => {
        onChangeArticle({...article, tvaAmount: newAmount});
    };

    const isInvalid = !isAmountValid || !isDescValid;

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid black',
            borderColor: 'v2.light.other.divider',
            backgroundColor: isInvalid ? 'v2.light.warning.shades4' : 'inherit',
            borderRadius: '16px',
            padding: '16px 32px 32px 32px',
            gap: 1,
        }}
        >
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
            }}
            >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                }}
                >
                    {isInvalid && (
                        <ConditionalTooltip
                            tooltip={t('categorisation.articles.warningMessage')}
                        >
                            <WarningIcon
                                sx={{
                                    color: 'warning_v2.main',
                                }}
                            />
                        </ConditionalTooltip>
                    )}
                    <Typography
                        sx={{
                            color: 'primary_v2.main',
                            fontWeight: 500,
                            letterSpacing: '0.1px',
                        }}
                    >
                        {t('categorisation.articles.article')} #{articleNumber}
                    </Typography>
                </Box>

                <Box>
                    <DeleteIcon
                        onClick={onRemoveArticle}
                        sx={{
                            cursor: 'pointer',
                            color: 'v2.light.secondary.main',
                        }}
                    />
                </Box>
            </Box>

            <Divider sx={{my: 2}} />

            <Box>
                <Typography sx={{mb: 0.5}}>
                    {t('translationDetails.category')}
                </Typography>
                <SelectedCategory
                    mccKey={article?.mccKey}
                    onOpenCategories={setIsShowAllCategories}
                    isEditable={true}
                />
            </Box>

            <Divider sx={{my: 1}} />

            <Box>
                <TextField
                    sx={{
                        'mb': 1,
                        'backgroundColor': 'white',
                        '> .MuiFormHelperText-contained': {
                            backgroundColor: 'v2.light.warning.shades4',
                            margin: 0,
                            paddingTop: '3px',
                            paddingLeft: '14px',
                            paddingRight: '14px',
                        },
                    }}
                    fullWidth
                    onChange={onDescriptionChange}
                    defaultValue={article?.description}
                    error={!isDescValid}
                    label={t('translationDetails.description')}
                    helperText={!isDescValid && t('categorisation.articles.amountInvalid')}
                />
            </Box>

            <Box sx={{my: 1}}>
                <NumberFormat
                    customInput={TextField}
                    decimalSeparator="."
                    thousandSeparator={false}
                    allowNegative={false}
                    decimalScale={2}
                    error={!isAmountValid}
                    helperText={!isAmountValid && t('categorisation.articles.amountInvalid')}
                    sx={{
                        'mb': 1,
                        'backgroundColor': 'white',
                        '> .MuiFormHelperText-contained': {
                            backgroundColor: 'v2.light.warning.shades4',
                            margin: 0,
                            paddingTop: '3px',
                            paddingLeft: '14px',
                            paddingRight: '14px',
                        },
                    }}
                    fullWidth
                    label={t('translationDetails.amountIncludingTax')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: <EuroTwoToneIcon />,
                    }}
                    onChange={onAmountChange}
                    defaultValue={article?.amount}
                />
            </Box>

            <Divider sx={{my: 1}} />

            <Box>
                <Typography>
                    TVA
                </Typography>
                <TVASelect
                    isEditable={article?.editableVat}
                    tva={article?.tva}
                    defaultValue={article?.tvaAmount}
                    tvaAmount={article?.tvaAmount}
                    onTvaChange={onTvaChange}
                    onTvaAmountChange={onTvaAmountChange}
                    isAmountInNewRow={true}
                />
            </Box>
        </Box>
    );
};

Article.propTypes = {
    article: PropTypes.object.isRequired,
    onRemoveArticle: PropTypes.func.isRequired,
    onChangeArticle: PropTypes.func.isRequired,
    articleNumber: PropTypes.number.isRequired,
    setIsShowAllCategories: PropTypes.func.isRequired,
};

export default Article;
