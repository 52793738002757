import {Box, Chip, Stack, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {LocalesConstants} from '../../../utils/locales-constants';

export const VatDeclarationSection = ({title, isRequired, sx, children}) => {
    const {t} = useTranslation(LocalesConstants.VatDeclaration);
    return (
        <Box
            sx={{
                p: 2,
                borderRadius: 'lg',
                border: '1px solid',
                borderColor: 'neutral.outlinedBorder',
                ...sx,
            }}
        >
            <Stack direction="row" justifyContent="space-between" mb={2}>
                <Typography level="title-md" textColor="text.secondary">
                    {title}
                </Typography>
                {isRequired && (
                    <Chip size="md" color="warning">
                        {t('required')}
                    </Chip>
                )}
            </Stack>
            {children}
        </Box>
    );
};

VatDeclarationSection.propTypes = {
    title: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    sx: PropTypes.object,
};

VatDeclarationSection.defaultProps = {
    isRequired: false,
    sx: {},
};
