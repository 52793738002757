import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import * as actions from './upload-documents.actions';
import {selectUploadDocumentsData} from './upload-documents.selectors';
import {safe} from '../../../../../../app/utils/store/safe-saga';
import {DOCUMENT_CONTEXTS, DOCUMENT_TYPES, UPLOAD_DOCUMENTS_STEPS} from '../../../../../../consts/document-constants';
import {Toast} from '../../../../../../lib/toast';
import {Debug} from '../../../../../../utils/debug';
import {isUserFreelancer} from '../../../../../../utils/user-roles';
import {selectCurrentCognitoUser} from '../../../../../auth/store/auth.selectors';
import {selectFreelancerAccount} from '../../../../../freelancer/store/freelancer.selectors';
import {getFreelancerId} from '../../../../../freelancer/utils/get-freelancer-id';
import {UiActions} from '../../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../../ui/utils/constants';
import {storeUser} from '../../../../../user/store/user.actions';
import {selectUser} from '../../../../../user/store/user.selectors';
import * as companyActions from '../../../../store/company.actions';
import {selectCompanyDocuments, selectUploadingDocumentIds} from '../../../../store/company.selectors';
import {getCompanyId} from '../../../../utils/get-company-id';
import {
    getSignedUploadUrlForDocumentRequest,
    updateCompanyDocumentRequest,
    uploadCompanyBalanceSheetRequest,
    uploadCompanyDocumentRequest,
    uploadDocumentRequest,
    uploadUmbrellaPersonalDocumentRequest,
} from '../../api/company.api';
import {uploadUmbrellaCompanyDocumentRequest} from '../../api/umbrella/umbrella-company.api';
import {getCompanyDocuments} from '../company-documents.actions';
import {UmbrellaCompanyActions} from '../umbrella/umbrella-company.actions';

const uploadDocumentFlow = function* (companyId, userId, isReplace, file, type, category, id, subType, year) {
    try {
        if (type === DOCUMENT_TYPES.BALANCE_SHEET) {
            // TODO: Portage users?
            yield call(uploadCompanyBalanceSheetRequest, companyId, file, type, category, id, year);
        } else {
            if (companyId) {
                yield call(uploadCompanyDocumentRequest, companyId, file, type, category, id, subType, year);
            }

            /* Upload documents for Umbrella users is handled separately */
            if (userId) {
                yield call(uploadUmbrellaCompanyDocumentRequest, userId, file, type, category, id, subType, year);
            }
        }

        if (isReplace) {
            Toast.success('fileReplacedSuccessfully', {
                translationValues: {
                    fileName: file.name,
                },
            });

            return;
        }

        Toast.success('fileUploadedSuccessfully', {
            translationValues: {
                fileName: file.name,
            },
        });
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error({error});

        // KBIS UPLOAD 409 - This is covered on three separate places, use this comment content to find them in code
        if (error?.response?.status === 409) {
            if (error?.response.data?.error === 'Could not handle Kbis document upload, company name missing') {
                Toast.warning('kbisUploadCompanyNameMissing');
                return;
            }
            if (error?.response.data?.error === 'Could not handle Kbis document upload, activityStartDate missing') {
                Toast.warning('kbisUploadStartDateMissing');
                return;
            }
            if (error?.response.data?.error === 'Kbis document can not be uploaded until company reaches REGISTRATION_COMPLETED status during onboarding.') {
                Toast.warning('kbisUploadWrongCompanyStatus');
                return;
            }
            if (error?.response.data?.message.startsWith('Document with type BALANCE_SHEET and for year')) {
                Toast.error('balanceSheetUploadAlreadyExists');
                return;
            }
            if (error?.response.data?.message.endsWith('annual accounting process has already started')) {
                Toast.error('annualAccountingAlreadyStarted');
                return;
            }
        }

        if (error?.response?.status === 413) {
            Toast.error('fileTooLarge');

            return;
        }

        throw error;
    }
};

const uploadDocumentsFlow = function* (companyId, userId, isReplace) {
    const data = yield select(selectUploadDocumentsData);

    yield all(data.map(item => {
        return call(
            uploadDocumentFlow,
            companyId,
            userId,
            isReplace,
            item.file,
            item.type,
            item.category,
            item.id || undefined,
            item.subType || undefined,
            item.year || undefined,
        );
    }));
};

const uploadDocumentsWorker = function* ({payload}) {
    yield put(actions.setIsUploadingDocuments(true));

    let {freelancerId, userId, companyId, ...rest} = payload;

    if (!freelancerId) {
        freelancerId = yield call(getFreelancerId);
    }

    if (!companyId) {
        companyId = yield call(getCompanyId);
    }

    try {
        yield call(uploadDocumentsFlow, companyId, userId, rest?.isReplace);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error({error});
    }

    if (userId) {
        yield put(UmbrellaCompanyActions.getUmbrellaCompanyDocuments(userId, DOCUMENT_CONTEXTS.DATABASE));
    } else if (companyId) {
        yield put(getCompanyDocuments(freelancerId, companyId, DOCUMENT_CONTEXTS.DATABASE));
    }

    yield put(UiActions.setActiveModal(ModalsKeys.UPLOAD_DOCUMENTS, false));
    yield put(actions.storeUploadDocumentsData([]));
    yield put(actions.setUploadDocumentsStep(UPLOAD_DOCUMENTS_STEPS.ATTACH_DOCUMENTS));
    yield put(actions.setIsUploadingDocuments(false));
};

export const uploadDocumentSaga = function* ({payload}) {
    try {
        const uploadingDocumentIds = yield select(selectUploadingDocumentIds);
        yield put(companyActions.setUploadingDocumentIds([
            ...uploadingDocumentIds,
            payload.documentId,
        ]));

        const currentCognitoUser = yield select(selectCurrentCognitoUser);

        let freelancerId = currentCognitoUser.id;

        if (!isUserFreelancer(currentCognitoUser)) {
            const freelancerAccount = yield select(selectFreelancerAccount);

            freelancerId = freelancerAccount.id;
        }

        const {
            companyId,
            documentId,
            file: document,
            onSuccess,
            hasNotification,
        } = payload;

        const {signedUrl} = yield call(
            getSignedUploadUrlForDocumentRequest,
            freelancerId,
            companyId,
            documentId,
        );

        const file = document?.file || document;
        const subType = document?.subType || null;

        yield call(uploadDocumentRequest, signedUrl, file);

        yield call(updateCompanyDocumentRequest, freelancerId, companyId, documentId, file.name, subType);

        const {
            identificationDocument,
            residenceCertificationDocument,
            insuranceDocument,
            hostIdentificationDocument,
            hostCertificationDocument,
        } = yield select(selectCompanyDocuments);

        if (identificationDocument?.id === documentId) {
            identificationDocument.fileName = file.name;
        } else if (residenceCertificationDocument?.id === documentId) {
            residenceCertificationDocument.fileName = file.name;
        } else if (insuranceDocument?.id === documentId) {
            insuranceDocument.fileName = file.name;
        } else if (hostIdentificationDocument?.id === documentId) {
            hostIdentificationDocument.fileName = file.name;
        } else if (hostCertificationDocument?.id === documentId) {
            hostCertificationDocument.fileName = file.name;
        }

        yield put(companyActions.storeCompanyDocuments({
            identificationDocument,
            residenceCertificationDocument,
            insuranceDocument,
            hostIdentificationDocument,
            hostCertificationDocument,
        }));

        if (onSuccess && typeof onSuccess === 'function') {
            onSuccess();
        }

        if (hasNotification) {
            Toast.success('documentUploaded');
        }
    } catch (e) {
        Debug.error('upload-documents uploadDocumentSaga', 'Error: ', {e});
        Toast.error('anErrorOccurred');
    } finally {
        const uploadingDocumentIds = yield select(selectUploadingDocumentIds);
        yield put(companyActions.setUploadingDocumentIds(
            uploadingDocumentIds.filter(documentId => documentId !== payload.documentId),
        ));
    }
};

export const uploadUmbrellaUserPersonalDocument = function* ({payload}) {
    try {
        const {
            userId,
            documentId,
            documentType,
            file: document,
            onSuccess,
        } = payload;

        if (documentId) {
            const uploadingDocumentIds = yield select(selectUploadingDocumentIds);
            yield put(companyActions.setUploadingDocumentIds([
                ...uploadingDocumentIds,
                payload.documentId,
            ]));
        }

        const uploadedDocument = yield call(
            uploadUmbrellaPersonalDocumentRequest,
            userId,
            documentId,
            documentType,
            document,
        );

        const oldUser = yield select(selectUser);
        const {documents} = oldUser;

        let updatedDocuments = [];
        if (documentId) {
            // document replaced
            updatedDocuments = documents.map(currentDocument => {
                if (currentDocument.id === documentId) {
                    return uploadedDocument;
                }
                return currentDocument;
            });
        } else {
            // new document uploaded
            updatedDocuments = documents;
            updatedDocuments.push(uploadedDocument);
        }

        yield put(storeUser({
            ...oldUser,
            documents: updatedDocuments,
        }));

        if (onSuccess && typeof onSuccess === 'function') {
            onSuccess();
        }

        Toast.success('documentUploaded');
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        if (payload.documentId) {
            const uploadingDocumentIds = yield select(selectUploadingDocumentIds);
            yield put(companyActions.setUploadingDocumentIds(
                uploadingDocumentIds.filter(documentId => documentId !== payload.documentId),
            ));
        }
    }
};

export const watchUploadDocumentsSaga = function* () {
    yield all([
        takeLatest(actions.UPLOAD_DOCUMENTS, safe(uploadDocumentsWorker)),
    ]);
};
