import {Box, Typography, useMediaQuery} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import AppleStoreImage from '../../../../assets/png/bank/app-store-button.png';
import GooglePlayImage from '../../../../assets/png/bank/google-play-button.png';
import DownloadMobileImage from '../../../../assets/png/bank/landing-download-mobile.png';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {LANDING_PAGE_BORDER_RADIUS} from '../../utils/constants';

const DownloadApplicationSection = () => {
    const {t} = useTranslation(LocalesConstants.Bank);
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Box sx={{
            borderRadius: LANDING_PAGE_BORDER_RADIUS,
            background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%), #E84C4C',
            display: 'flex',
            flexDirection: isMobileSize ? 'column' : 'row',
            pt: isMobileSize ? 3 : 6,
            pb: 4,
        }}
        >
            <Box
                sx={{
                    width: isMobileSize ? '100%' : '50%',
                    pl: isMobileSize ? 3 : 8,
                }}
            >
                <img
                    style={
                    isMobileSize ? {width: '180px'} : {width: '534px'}
                }
                    src={DownloadMobileImage}
                    alt="hiway mobile"
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    pt: isMobileSize ? 2 : 6,
                    pl: isMobileSize ? 3 : 7,
                    width: isMobileSize ? '100%' : '50%',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                }}
            >
                <Box>
                    <Typography sx={{color: 'white'}} variant="h3">
                        {t('landing.downloadMobileApplicatoin')}
                    </Typography>
                </Box>
                <Box sx={{
                    color: 'white',
                    mt: 1,
                }}
                >
                    {t('landing.mobileAppDescription')}
                </Box>
                <Box sx={{display: 'flex', pt: 3}}>
                    <Box sx={{mr: 3}}>
                        <img
                            style={{
                                width: '138px',
                            }}
                            src={GooglePlayImage}
                            alt="Google play download"
                        />
                    </Box>
                    <Box>
                        <img
                            style={{
                                width: '138px',
                            }}
                            src={AppleStoreImage}
                            alt="App store download"
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default DownloadApplicationSection;
