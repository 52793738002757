import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import {useDataTableRowActionsStyles} from './styles';
import ReactRouterLink from '../../app/router/ReactRouterLink';
import ActionButton from '../ActionButton/ActionButton';

const DataTableRowActions = ({config, breakdown}) => {
    const styles = useDataTableRowActionsStyles();

    const [menuAnchorElement, setMenuAnchorElement] = React.useState(null);

    const theme = useTheme();
    const shouldRenderAllItemsInMenu = useMediaQuery(theme.breakpoints.down(breakdown));

    const actions = shouldRenderAllItemsInMenu && config.length > 1 ? [] : config.slice(0, 4);
    const menuActions = shouldRenderAllItemsInMenu && config.length > 1 ? config : config.slice(4);

    const handleClick = event => {
        setMenuAnchorElement(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchorElement(null);
    };

    return (
        <div className={styles.container}>
            {actions.map((configItem, index) => {
                const Button = configItem.component || ActionButton;
                const button = (
                    <Button
                        key={index}
                        className={styles.actionButton}
                        size="medium"
                        disabled={configItem.disabled}
                        {...(configItem.buttonProps || {})}
                        {...(!configItem?.buttonProps?.onClick && {to: configItem.path})}
                    >
                        {configItem.icon}
                    </Button>
                );

                return configItem.disabled ? button : (
                    <Tooltip key={index} placement="top" arrow title={configItem.title}>
                        {button}
                    </Tooltip>
                );
            })}

            {!!menuActions.length && (
                <>
                    <IconButton size="medium" className={styles.actionButton} onClick={handleClick}>
                        <MoreVertIcon />
                    </IconButton>

                    <Menu
                        open={!!menuAnchorElement}
                        anchorEl={menuAnchorElement}
                        getContentAnchorEl={null}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        {menuActions.map((configItem, index) => (
                            <MenuItem
                                key={index}
                                button
                                className={styles.menuLink}
                                component={configItem?.buttonProps?.onClick ? undefined : ReactRouterLink}
                                disabled={configItem.disabled}
                                {...(!configItem?.buttonProps?.onClick && {to: configItem.path})}
                                {...(configItem?.buttonProps?.onClick && {onClick: configItem?.buttonProps?.onClick})}
                                dense
                            >
                                <ListItemIcon>
                                    {configItem.icon}
                                </ListItemIcon>

                                <ListItemText primaryTypographyProps={{variant: 'body2'}}>
                                    {configItem.title}
                                </ListItemText>
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            )}
        </div>
    );
};

DataTableRowActions.propTypes = {
    config: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        path: PropTypes.string,
        icon: PropTypes.node,
        buttonProps: PropTypes.shape({
            color: PropTypes.string,
            variant: PropTypes.string,
            style: PropTypes.object,
            onClick: PropTypes.func,
        }),
    })).isRequired,
    breakdown: PropTypes.string,
};

DataTableRowActions.defaultProps = {
    breakdown: 'lg',
};

export default React.memo(DataTableRowActions);
