import {Checkbox as JoyCheckbox} from '@mui/joy';

export const Checkbox = props => (
    <JoyCheckbox
        variant="soft"
        color="neutral"
        sx={{
            p: 1.25,
        }}
        {...props}
    />
);
