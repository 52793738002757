import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {Grid, Typography, useMediaQuery} from '@mui/material';
import Box from '@mui/material/Box';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {useContractInformationStyles} from './styles';
import {DataItemRow} from '../../../components/data-item-row/DataItemRow';
import PdfIcon from '../../../components/icons/PdfEditIcon';
import ActionButton from '../../../components/ui-kit/ActionButton/ActionButton';
import DataItemRowLabel from '../../../components/ui-kit/DataItemRowLabel';
import NoInput from '../../../components/ui-kit/NoInput/NoInput';
import {LocalesConstants} from '../../../utils/locales-constants';
import {ContractSelectors} from '../../contract/store/contract.selector';

export const ContractInformation = ({hasTitle}) => {
    const styles = useContractInformationStyles();
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const {t} = useTranslation(LocalesConstants.Users);

    const contractData = useSelector(ContractSelectors.selectData);

    const memoizedSignatureDate = useMemo(() => {
        if (!contractData || !contractData.signedAt) {
            return <NoInput />;
        }

        return (
            <Typography component="span" noWrap>
                {moment(contractData.signedAt).format('L')}
            </Typography>
        );
    }, [contractData]);

    const memoizedFileName = useMemo(() => {
        if (!contractData || !contractData.signedAt) {
            return <NoInput />;
        }

        return (
            <Typography component="span" className={styles.fileName} noWrap>
                <PdfIcon className={styles.pdfIcon} />

                {t('contractInformation.fileName')}
            </Typography>
        );
    }, [contractData, styles.fileName, styles.pdfIcon, t]);

    if (!contractData) {
        return null;
    }

    const contractActionButton = contractData.signedAt && (
        <ActionButton
            component="a"
            aria-label={t('openContractInNewWindow')}
            color="inherit"
            size="small"
            href={contractData.url}
            target="_blank"
        ><OpenInNewIcon />
        </ActionButton>
    );

    if (!isMobileSize) {
        return (
            <>
                {hasTitle && (
                    <Typography className={styles.sectionTitle} variant="h5" color="textPrimary">
                        {t('contractInformation.contractInformation')}
                    </Typography>
                )}
                <div className={styles.informationContainer}>
                    <DataItemRow
                        label={(
                            <DataItemRowLabel>
                                {t('contractInformation.hiwayContract')}
                            </DataItemRowLabel>
                        )}
                        content={memoizedFileName}
                        actions={contractActionButton}
                    />
                    <DataItemRow
                        label={(
                            <DataItemRowLabel>
                                {t('contractInformation.contractSignatureDate')}
                            </DataItemRowLabel>
                        )}
                        content={memoizedSignatureDate}
                        hasBorderDivider={false}
                    />
                </div>
            </>
        );
    }


    return (
        <>
            {hasTitle && (
                <Typography className={styles.sectionTitle} variant="h5" color="textPrimary">
                    {t('contractInformation.contractInformation')}
                </Typography>
            )}
            <div className={styles.informationContainer}>
                <Grid container sx={{py: 2, px: 3, borderBottom: 1, borderColor: 'grey.300'}}>
                    <Grid item md={6} xs={12} sx={{color: 'rgba(0, 0, 0, 0.38)'}}>
                        {t('contractInformation.hiwayContract')}
                    </Grid>
                    <Grid item md={6} xs={12} sx={{display: 'flex'}}>
                        {memoizedFileName}
                        <Box component="span" sx={{ml: 1}}>
                            {contractActionButton}
                        </Box>
                    </Grid>
                </Grid>
                <Grid container sx={{py: 2, px: 3}}>
                    <Grid item md={6} xs={12} sx={{color: 'rgba(0, 0, 0, 0.38)'}}>
                        {t('contractInformation.contractSignatureDate')}
                    </Grid>
                    <Grid item md={6} xs={12} sx={{display: 'flex'}}>
                        {memoizedSignatureDate}
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

ContractInformation.propTypes = {
    hasTitle: PropTypes.bool,
};

ContractInformation.defaultProps = {
    hasTitle: true,
};
