import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import {LoadingButton} from '@mui/lab';
import {Box, FormControlLabel, FormGroup} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Checkbox} from '../../../../components/checkbox/Checkbox';
import {LocalesConstants} from '../../../../utils/locales-constants';

export const FundingApprovalButtons = ({
    onButtonClick,
    onCheckboxClick,
    isChecked,
    isDisabled,
    isLoading,
    onDownloadClick,
    isDownloading,
}) => {
    const {t} = useTranslation(LocalesConstants.Training);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <>
            <Box sx={{
                mb: {
                    xs: 2,
                    md: 0,
                },
            }}
            >
                <FormGroup>
                    <FormControlLabel
                        control={(
                            <Checkbox
                                checked={isChecked}
                                onClick={onCheckboxClick}
                                disabled={isDisabled}
                            />
                        )}
                        label={t('fundingApproval.toggleSuccessCheckbox')}
                    />
                </FormGroup>
            </Box>
            <Box>
                {!isDisabled && (
                <LoadingButton
                    sx={isMobileSize ? {mb: 2} : {mr: 2}}
                    fullWidth={isMobileSize}
                    startIcon={<DownloadForOfflineIcon />}
                    onClick={onDownloadClick}
                    color="primary"
                    variant={!isChecked ? 'contained' : 'outlined'}
                    disabled={isLoading}
                    disableElevation
                    loading={isDownloading}
                >
                    {t('dossier.download')}
                </LoadingButton>
                )
                }
                <LoadingButton
                    endIcon={<ArrowForwardIcon />}
                    onClick={onButtonClick}
                    fullWidth={isMobileSize}
                    color="secondary"
                    variant="contained"
                    disableElevation
                    disabled={!isChecked}
                    loading={isLoading}
                >
                    {t('fundingApproval.submit')}
                </LoadingButton>
            </Box>
        </>
    );
};

FundingApprovalButtons.propTypes = {
    onButtonClick: PropTypes.func.isRequired,
    onCheckboxClick: PropTypes.func.isRequired,
    isChecked: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    onDownloadClick: PropTypes.func.isRequired,
    isDownloading: PropTypes.bool,
};

FundingApprovalButtons.defaultProps = {
    isDisabled: false,
    isLoading: false,
    isDownloading: false,
};
