import {Box, LinearProgress, Stack, Typography} from '@mui/joy';
import merge from 'lodash.merge';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import NumberFormat from 'react-number-format';
import {EurAmount} from '../../../../../../components/eur-amount/EurAmount';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {useSimulationData} from '../../utils/useSimulationData';

const Cell = ({sx, isSelected, ...props}) => {
    const regularStyles = {
        'px': 2,
        'height': '56px',
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'center',
        'borderBottom': '1px solid',
        'borderBottomColor': 'divider',
        'fontSize': {mobile: '14px', desktop: '16px'},
        'color': 'text.secondary',
        '&:first-of-type': {
            backgroundColor: 'background.surface',
            textTransform: 'uppercase',
            fontWeight: 700,
            height: '39px',
            fontStyle: 'italic',
            borderBottomWidth: '2px',
        },
        '&:last-child': {
            borderBottom: 'none',
        },
    };

    const selectedStyles = merge({}, regularStyles, {
        'backgroundColor': 'background.surface',
        'color': 'neutral.plainColor',
        'borderBottomColor': 'background.surface',
        'fontWeight': 700,
        'fontSize': '16px',
        '&:first-of-type': {
            fontSize: {mobile: '14px', desktop: '16px'},
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            backgroundColor: 'primary.solidBg',
            color: 'white',
            borderBottomColor: 'primary.solidBg',
            fontWeight: 700,
            height: '48px',
        },
        '&:last-child': {
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            height: '60px',
        },
    });

    const styles = isSelected ? selectedStyles : regularStyles;

    return (
        <Typography
            component="div"
            sx={{
                ...styles,
                ...sx,
            }}
            {...props}
        />
    );
};

Cell.propTypes = {
    sx: PropTypes.object,
    isSelected: PropTypes.bool,
};

Cell.defaultProps = {
    sx: undefined,
    isSelected: false,
};

const Column = ({heading, entries, isSelected}) => {
    return (
        <Box
            sx={{
                'boxShadow': isSelected ? 'sm' : 'none',
                'flex': 1,
                'zIndex': isSelected ? 1 : 0,
                'textAlign': 'center',
                'border': 'none',
                'borderRadius': isSelected ? '8px' : 0,
                'overflow': 'hidden',
                'minWidth': 'fit-content',
                'text-wrap': 'pretty',
                '&:first-of-type': {
                    'borderTopLeftRadius': '8px',
                    'borderBottomLeftRadius': '8px',
                    'flex': 'none',
                    'maxWidth': '40vw',
                    'minWidth': 0,
                    'textAlign': 'left',
                    'fontWeight': 600,
                    '& > *': {
                        justifyContent: 'flex-start',
                    },
                },
                '&:last-child': {
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                    borderRightWidth: '1px',
                },
            }}
        >
            <Cell isSelected={isSelected}>{heading || <span>&nbsp;</span>}</Cell>
            {entries.map((entry, index) => (
                <Cell key={index} isSelected={isSelected}>
                    {entry}
                </Cell>
            ))}
        </Box>
    );
};

Column.propTypes = {
    heading: PropTypes.string.isRequired,
    entries: PropTypes.array.isRequired,
    isSelected: PropTypes.bool,
};

Column.defaultProps = {
    isSelected: false,
};

const linearProgressStyles = {
    'bgcolor': 'white',
    'border': '1px solid',
    'borderColor': 'divider',
    '--LinearProgress-thickness': '8px',
};

export const ComparisonTable = () => {
    const {t} = useTranslation(LocalesConstants.RevenueSimulator);
    const simulation = useSimulationData();
    const {netIncomeBeforeIR, restituationCA, netAfterIr, netDelta} = simulation.simulationOutput.comparisons;
    const hasSpouse = simulation.simulationInput.personalInfo.numberOfAdults === 2;
    const shouldShowMicroEnt = !hasSpouse;
    const shouldShowNetAfterIr = !hasSpouse;

    const getColumnData = key => ({
        heading: t(`comparisonTable.${key}`),
        entries: [
            <EurAmount key="nibir" amount={netIncomeBeforeIR[key]} />,
            <Stack key="rca" spacing={0.5} sx={{width: '100%'}}>
                <NumberFormat decimalScale={2} value={restituationCA[key]} suffix="%" displayType="text" />
                {key === 'precoHiway' && (
                    <LinearProgress determinate value={restituationCA[key]} sx={linearProgressStyles} />
                )}
            </Stack>,
            ...(shouldShowNetAfterIr ? [<EurAmount key="nai" amount={netAfterIr[key]} />] : []),
            key === 'precoHiway' ? '/' : <EurAmount key="nd" amount={-1 * netDelta[key]} />,
        ],
        isSelected: key === 'precoHiway',
    });

    const columns = [
        {
            heading: '',
            entries: [
                t('comparisonTable.netIncomeBeforeIR'),
                t('comparisonTable.restituationCA'),
                ...(shouldShowNetAfterIr ? [t('comparisonTable.netAfterIr')] : []),
                hasSpouse ? t('comparisonTable.netDeltaBeforeIR') : t('comparisonTable.netDeltaAfterIR'),
            ],
        },
        getColumnData('precoHiway'),
        getColumnData('eurlIS'),
        getColumnData('sasuIS'),
        ...(shouldShowMicroEnt ? [getColumnData('microEnterprise')] : []),
        getColumnData('portageSalarie'),
    ];

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
            }}
        >
            {columns.map(column => (
                <Column key={column.heading} {...column} />
            ))}
        </Box>
    );
};
