import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {useCompanyChipStyles} from './styles';
import {LocalesConstants} from '../../../utils/locales-constants';

const CompanyChip = ({companyId}) => {
    const {t} = useTranslation(LocalesConstants.Companies);
    const styles = useCompanyChipStyles();

    return (
        <div className={styles.root}>
            <span className={styles.companyIdLabel}>
                {t('companyId')}&nbsp;
            </span>

            <span className={styles.companyId}>
                {companyId}
            </span>
        </div>
    );
};

CompanyChip.propTypes = {
    companyId: PropTypes.string.isRequired,
};

export default CompanyChip;
