import {Box} from '@mui/joy';
import {TableCell, TableRow} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import {useDataTableHeaderColumns} from './styles';
import {requiredIf} from '../../../utils/conditional-prop-type';
import {Checkbox as JoyCheckbox} from '../../joy-ui/forms/Checkbox';
import TableSortLabel from '../../joy-ui/mui-legacy/TableSortLabel';
import Checkbox from '../Checkbox';

const DataTableHeaderColumns = props => {
    const {
        columnConfig,
        sortBy,
        sortDirection,
        onSortClick,
        hasCheckboxes,
        selectedCount,
        count,
        onSelectAllClick,
        isV3,
    } = props;

    const styles = useDataTableHeaderColumns();

    const CheckboxComponent = isV3 ? JoyCheckbox : Checkbox;

    return isV3 ? (
        <Box component="tr">
            {hasCheckboxes && (
                <Box
                    component="th"
                    sx={{
                        '--TableCell-paddingY': 0,
                        '--TableCell-paddingX': '4px',
                    }}
                >
                    <CheckboxComponent
                        indeterminate={selectedCount > 0 && selectedCount < count}
                        checked={count > 0 && selectedCount === count}
                        onChange={onSelectAllClick}
                    />
                </Box>
            )}

            {columnConfig.map(column => (
                <Box
                    key={column.key}
                    component="th"
                    sx={{
                        '--TableCell-paddingY': '16px',
                        '--TableCell-paddingX': '16px',
                        'color': 'text.tertiary',
                        'textTransform': 'uppercase',
                        'fontFamily': 'body',
                        'fontSize': 'sm',
                        'fontStyle': 'italic',
                        'fontWeight': 'xl',
                        'lineHeight': '142%',
                        'letterSpacing': '0.42px',
                        'verticalAlign': 'middle',
                        'textAlign': column.textAlign || 'left',
                    }}
                    style={{width: column.width || 'auto'}}
                    {...(column.additionalCellProps || {})}
                    {...(column.isSortable ? {sortDirection, onClick: () => onSortClick(column.key)} : {})}
                >
                    {column.isSortable ? (
                        <TableSortLabel direction={sortDirection} active={sortBy === column.key}>
                            {column.title}
                        </TableSortLabel>
                    ) : (
                        <Box component="span" sx={{color: 'text.tertiary', fontWeight: 'xl'}}>
                            {column.title}
                        </Box>
                    )}
                </Box>
            ))}
        </Box>
    ) : (
        <TableRow>
            {hasCheckboxes && (
                <TableCell padding="checkbox">
                    <CheckboxComponent
                        indeterminate={selectedCount > 0 && selectedCount < count}
                        checked={count > 0 && selectedCount === count}
                        onChange={onSelectAllClick}
                    />
                </TableCell>
            )}

            {columnConfig.map(column => (
                <TableCell
                    key={column.key}
                    className={styles.tableCell}
                    style={{width: column.width || 'auto', textAlign: column.textAlign || 'left'}}
                    {...(column.additionalCellProps || {})}
                    {...(column.isSortable ? {sortDirection, onClick: () => onSortClick(column.key)} : {})}
                >
                    {column.isSortable ? (
                        <TableSortLabel direction={sortDirection} active={sortBy === column.key}>
                            {column.title}
                        </TableSortLabel>
                    ) : column.title}
                </TableCell>
            ))}
        </TableRow>
    );
};

DataTableHeaderColumns.propTypes = {
    columnConfig: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        title: PropTypes.string.isRequired,
        additionalCellProps: PropTypes.object,
    })).isRequired,
    sortDirection: PropTypes.string.isRequired,
    sortBy: PropTypes.string,
    onSortClick: PropTypes.func.isRequired,
    hasCheckboxes: PropTypes.bool,
    selectedCount: PropTypes.number,
    count: PropTypes.number,
    // eslint-disable-next-line react/require-default-props
    onSelectAllClick: requiredIf(
        props => props.hasCheckboxes && typeof props.onSelectAllClick !== 'function',
        '"onSelectAllClick" must be a function if "hasCheckboxes" is true"',
    ),
    isV3: PropTypes.bool,
};

DataTableHeaderColumns.defaultProps = {
    hasCheckboxes: false,
    selectedCount: 0,
    count: 0,
    sortBy: null,
    isV3: false,
};

export default DataTableHeaderColumns;
