import {combineReducers} from 'redux';
import {contractDataReducer} from './contract/contract.reducer';
import {FreelancerActionTypes} from './freelancer.action-type';
import {createReducer} from '../../../utils/create-reducer';
import {companiesReducer} from '../modules/companies/store/companies.reducer';
import {contractSigningReducer} from '../modules/contract-signing/store/contract-signing.reducer';
import {onboardingReducer} from '../modules/onboarding/store/onboarding.reducer';

export const freelancerReducer = combineReducers({
    companies: createReducer({}, FreelancerActionTypes.STORE_FREELANCER_COMPANIES),
    // TODO: Do we need this?
    companyEntities: companiesReducer,
    onboarding: onboardingReducer,
    contractData: contractDataReducer,
    contractSigning: contractSigningReducer,
    account: createReducer(null, FreelancerActionTypes.STORE_FREELANCER_ACCOUNT),
    isLoadingAccount: createReducer(true, FreelancerActionTypes.SET_IS_LOADING_FREELANCER_ACCOUNT, true),
    isLoadingCompanies: createReducer(true, FreelancerActionTypes.SET_IS_LOADING_FREELANCER_COMPANIES, true),
});
