import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import {Alert, AlertTitle} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {LocalesConstants} from '../../../../utils/locales-constants';

export const FundingPending = () => {
    const {t} = useTranslation(LocalesConstants.Training);

    return (
        <Alert
            sx={{borderRadius: '8px'}}
            severity="info"
            icon={<HourglassTopIcon fontSize="inherit" />}
        >
            <AlertTitle sx={{fontWeight: 700}}>{t('fundingApproval.pendingAlertTitle')}</AlertTitle>
            {t('fundingApproval.pendingAlertText')}
        </Alert>
    );
};
