const selectIsSidebarExpanded = state => state.sidebar.isSidebarExpanded;
const selectIsProfileMenuExpanded = state => state.sidebar.isProfileMenuExpanded;
const selectSelectedRole = state => state.sidebar.roleSelected;
const selectSearchText = state => state.sidebar.sidebarText;
const selectSearchInputValue = state => state.sidebar.searchInputValue;

export const SidebarSelectors = {
    selectIsSidebarExpanded,
    selectIsProfileMenuExpanded,
    selectSelectedRole,
    selectSearchText,
    selectSearchInputValue,
};
