import {faCheck, faGamepad} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Checkbox, Chip, Stack, Switch, Typography} from '@mui/joy';
import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import JoyUIThemeProvider from '../../../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {UiActions} from '../../../ui/store/ui.action';
import {ModalsKeys} from '../../../ui/utils/constants';
import {VATDeclarationActions} from '../../store/vat-declaration.action';
import {VatDeclarationSelectors} from '../../store/vat-declaration.selector';
import {getDeclarationPeriodString} from '../../utils';

const modalKey = ModalsKeys.VAT_DECLARATION_SWITCH_TO_MANUAL_MODE;

export const ManualModeSwitch = () => {
    const {t} = useTranslation(LocalesConstants.VatDeclaration);
    const isActive = useSelector(VatDeclarationSelectors.selectIsManualMode);
    const currentDeclaration = useSelector(VatDeclarationSelectors.selectVATDeclaration);
    const declarationPeriod = useMemo(() => getDeclarationPeriodString(currentDeclaration), [currentDeclaration]);
    const dispatch = useDispatch();
    const [isChecked, setIsChecked] = useState(false);
    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.VAT_DECLARATION_ACTIVATE_MANUAL_MODE),
    );
    const handleSwitchOn = () => {
        dispatch(UiActions.setActiveModal(modalKey, true));
    };
    const handleConfirm = () => {
        dispatch(VATDeclarationActions.activateManualMode({declarationId: currentDeclaration.id}));
    };

    return (
        <JoyUIThemeProvider>
            <ModalWrapper
                isV3
                modalKey={modalKey}
                title={<Typography level="h1">{t('manualModeSwitchModal.title')}</Typography>}
                buttons={({handleClose}) => (
                    <Stack direction="row" spacing={2} width="100%">
                        <Button
                            variant="outlined"
                            color="neutral"
                            size="lg"
                            onClick={handleClose}
                            fullWidth
                            disabled={isLoading}
                        >
                            {t('manualModeSwitchModal.cancel')}
                        </Button>
                        <Button
                            variant="soft"
                            color="success"
                            size="lg"
                            startDecorator={<FontAwesomeIcon icon={faCheck} />}
                            disabled={!isChecked}
                            onClick={handleConfirm}
                            loading={isLoading}
                            loadingPosition="end"
                            fullWidth
                        >
                            {t('manualModeSwitchModal.confirm')}
                        </Button>
                    </Stack>
                )}
            >
                <Typography level="body-lg" color="text.secondary">
                    {t('manualModeSwitchModal.description', {declarationPeriod})}
                </Typography>
                <Stack direction="row" spacing={2} alignItems="center" mt={2}>
                    <Checkbox
                        checked={isChecked}
                        onChange={() => setIsChecked(value => !value)}
                        disabled={isLoading}
                    />
                    <Typography level="body-lg" color="text.secondary">
                        {t('manualModeSwitchModal.confirmationCheckbox')}
                    </Typography>
                </Stack>
            </ModalWrapper>
            {isActive ? (
                <Chip
                    color="danger"
                    variant="soft"
                    startDecorator={(
                        <Box px={0.5} fontSize="12px">
                            <FontAwesomeIcon icon={faGamepad} />
                        </Box>
                      )}
                >
                    {t('manualModeSwitchLabel')}
                </Chip>
            ) : (
                <Switch
                    sx={{'--Switch-trackWidth': '49px'}}
                    checked={false}
                    onClick={handleSwitchOn}
                    endDecorator={(
                        <Typography level="body-md" textColor="background.body">
                            {t('manualModeSwitchLabel')}
                        </Typography>
                      )}
                />
            )}
        </JoyUIThemeProvider>
    );
};
