import {Global} from '@emotion/react';
import {
    faChevronDown,
    faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Drawer, IconButton, Stack} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {UiActions} from '../../../features/ui/store/ui.action';
import {UiSelectors} from '../../../features/ui/store/ui.selector';
import {SidebarDrawerKeys} from '../../../features/ui/utils/constants';

const drawerKey = SidebarDrawerKeys.BOTTOM_DRAWER;

const BottomDrawerWrapper = ({
    drawerSx,
    containerSx,
    children,
    size,
}) => {
    const dispatch = useDispatch();
    const isOpen = useSelector(UiSelectors.createIsSidebarDrawerActiveSelector(drawerKey));

    const handleClose = () => {
        dispatch(UiActions.setActiveSidebarDrawer(drawerKey, !isOpen));
    };

    return (
        <Drawer
            anchor="bottom"
            size={size}
            open
            onClose={handleClose}
            sx={{
                '.MuiDrawer-content': {
                    borderTopRightRadius: '12px',
                    borderTopLeftRadius: '12px',
                    height: 'auto',
                    borderTop: '1px solid',
                    borderColor: 'neutral.outlinedBorder',
                    boxShadow: 'md',
                },
                ...drawerSx,
            }}
            hideBackdrop
        >
            <Global
                styles={{
                    'div.MuiDrawer-root': {
                        position: 'relative',
                    },
                }}
            />
            <Box sx={{
                minHeight: '50px',
                maxHeight: 'calc(100vh - 100px)',
                borderTopRightRadius: '12px',
                borderTopLeftRadius: '12px',
                display: 'flex',
                flexDirection: 'column',
                pt: 1,
                px: 2,
                ...containerSx,
            }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    mb: 1,
                }}
                >
                    <IconButton
                        onClick={handleClose}
                        variant="plain"
                        color="neutral"
                        size="sm"
                    >
                        <FontAwesomeIcon
                            size="xl"
                            icon={isOpen ? faChevronDown : faChevronUp}
                        />
                    </IconButton>
                </Box>

                <Stack
                    direction="column"
                    gap={2}
                    sx={{
                        pb: 3,
                    }}
                >
                    {children}
                </Stack>
            </Box>
        </Drawer>
    );
};

BottomDrawerWrapper.propTypes = {
    drawerSx: PropTypes.object,
    containerSx: PropTypes.object,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

BottomDrawerWrapper.defaultProps = {
    drawerSx: {},
    containerSx: {},
    size: 'lg',
};

export default BottomDrawerWrapper;
