import momentTz from 'moment-timezone';
import {DEFAULT_TIME_ZONE} from '../../utils/constants';
import {getCurrentDateTz} from '../../utils/date';
import {COMPANY_TVA_FREQUENCY_PAYMENTS} from '../company/utils/constants';
import {capitalizeFirstLetter} from '../dashboard-v3/utils/constants';

export const isBiAnnual = item => {
    // TODO: Keeping SEMESTRIAL temporarily for backwards compatibility. Delete after migration.
    return (
        COMPANY_TVA_FREQUENCY_PAYMENTS[item.type] === COMPANY_TVA_FREQUENCY_PAYMENTS.SEMESTRIAL
        || COMPANY_TVA_FREQUENCY_PAYMENTS[item.type] === COMPANY_TVA_FREQUENCY_PAYMENTS.BI_ANNUALLY
    );
};

export const canViewDeclaration = () => {
    // TODO: Remove this if not needed
    return true;
};

export const getDeclarationPeriodString = declaration => {
    if (declaration?.dueDate) {
        return capitalizeFirstLetter(
            momentTz.tz(declaration.dueDate, DEFAULT_TIME_ZONE).subtract(1, 'months').format('MMMM YYYY'),
        );
    }
};

export const getCurrentDeclarationPeriod = () => {
    return getCurrentDateTz().subtract(1, 'month');
};
