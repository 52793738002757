import {
    fromBusinessAllowanceRecapDTO, fromBusinessAllowanceTravelDTO,
    fromPersonalExpenseDTO, fromTotalDistanceDto,
    transformBusinessAllowanceRecapDTOs,
    transformBusinessAllowanceTravelDTOs,
    transformPersonalExpenseDTOs,
    transformProfessionalExpenseDTOs,
} from './expenses.dto';
import {invoiceApiInstance} from '../../../../lib/invoice-api-instance';
import axiosInstance, {safeTransformData} from '../../axios';

export const getPersonalExpensesRequest = freelancerId => {
    return invoiceApiInstance({
        method: 'GET',
        url: `/v1/users/${freelancerId}/expenses`,
    }).then(response => safeTransformData(response, transformPersonalExpenseDTOs));
};

export const createPersonalExpenseRequest = (freelancerId, data) => {
    const formData = new FormData();

    formData.append('file', data.files[0]);
    formData.append('category', data.category);
    formData.append('date', data.purchaseDate);
    formData.append('totalAmount', data.amount);
    formData.append('reimbursed', data.isReimbursed);

    return invoiceApiInstance({
        method: 'POST',
        url: `/v1/users/${freelancerId}/expenses`,
        data: formData,
    }).then(response => safeTransformData(response));
};

export const updatePersonalExpenseRequest = (freelancerId, expenseId, data) => {
    return invoiceApiInstance({
        method: 'PUT',
        url: `/v1/users/${freelancerId}/expenses/${expenseId}`,
        data,
    }).then(response => safeTransformData(response, fromPersonalExpenseDTO));
};

export const deletePersonalExpenseRequest = (freelancerId, expenseId) => {
    return invoiceApiInstance({
        method: 'DELETE',
        url: `/v1/users/${freelancerId}/expenses/${expenseId}`,
    }).then(response => safeTransformData(response));
};

export const getProfessionalExpensesRequest = (freelancerId, companyId) => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/professional-expenses`,
    }).then(response => safeTransformData(response, transformProfessionalExpenseDTOs));
};

export const createProfessionalExpenseRequest = (freelancerId, companyId, data) => {
    const formData = new FormData();

    formData.append('file', data.files[0]);
    formData.append('category', data.category);
    formData.append('date', data.purchaseDate);
    formData.append('amount_ttc', data.amount);

    return axiosInstance({
        method: 'POST',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/professional-expenses`,
        data: formData,
    }).then(response => safeTransformData(response));
};

export const deleteProfessionalExpenseRequest = (freelancerId, companyId, expenseId) => {
    return axiosInstance({
        method: 'DELETE',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/professional-expenses/${expenseId}`,
    }).then(response => safeTransformData(response));
};

export const createCompanyBusinessKmTravelRequest = (freelancerId, companyId, data) => {
    return axiosInstance({
        method: 'POST',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/business-km/travels`,
        data: data,
    }).then(response => safeTransformData(response, fromBusinessAllowanceTravelDTO));
};

export const updateCompanyBusinessKmTravelRequest = (freelancerId, companyId, travelId, data) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/business-km/travels/${travelId}`,
        data: data,
    }).then(response => safeTransformData(response, fromBusinessAllowanceTravelDTO));
};

export const getBusinessAllowanceTravelsRequest = (freelancerId, companyId) => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/business-km/travels`,
    }).then(response => safeTransformData(response, transformBusinessAllowanceTravelDTOs));
};

export const deleteBusinessAllowanceTravelRequest = (freelancerId, companyId, travelId) => {
    return axiosInstance({
        method: 'DELETE',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/business-km/travels/${travelId}`,
    }).then(response => safeTransformData(response));
};

export const generateBusinessAllowanceRecapRequest = (freelancerId, companyId) => {
    return axiosInstance({
        method: 'POST',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/business-km/recaps/generate`,
    }).then(response => safeTransformData(response));
};

export const getBusinessAllowanceRecapsRequest = (freelancerId, companyId) => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/business-km/recaps`,
    }).then(response => safeTransformData(response, transformBusinessAllowanceRecapDTOs));
};

export const deleteBusinessAllowanceRecapRequest = (freelancerId, companyId, recapId) => {
    return axiosInstance({
        method: 'DELETE',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/business-km/recaps/${recapId}`,
    }).then(response => safeTransformData(response));
};

export const updateBusinessAllowanceRecapRequest = (freelancerId, companyId, recapId, isReimbursed) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/business-km/recaps/${recapId}`,
        data: {
            reimbursed: isReimbursed,
        },
    }).then(response => safeTransformData(response, fromBusinessAllowanceRecapDTO));
};

export const getBusinessAllowanceTotalDistanceRequest = (freelancerId, companyId, date) => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/business-km/travels/total-distance`,
        params: {
            date,
        },
    }).then(response => safeTransformData(response, fromTotalDistanceDto));
};
