import {safeTransformData} from '../../../../../app/api/axios';
import {coreApiInstance} from '../../../../../lib/api-providers/core-api-instance';

const getMissionContractDocuments = ({userId, missionId}) => {
    return coreApiInstance({
        method: 'GET',
        url: `/api/v2/users/${userId}/missions/${missionId}/quota-contract-documents`,
    }).then(response => safeTransformData(response));
};

const generateMissionContract = ({userId, id, shouldRegenerate = false}) => {
    return coreApiInstance({
        method: 'POST',
        data: {
            shouldRegenerate,
        },
        url: `/api/v2/users/${userId}/missions/${id}/generate-quota-contract`,
    }).then(response => safeTransformData(response));
};

const sendMissionContract = ({userId, missionId, contractId}) => {
    return coreApiInstance({
        method: 'POST',
        data: {
            contractId,
        },
        url: `/api/v2/users/${userId}/missions/${missionId}/send-quota-contract`,
    }).then(response => safeTransformData(response));
};

const signMissionContract = ({userId, missionId, contractId}) => {
    return coreApiInstance({
        method: 'POST',
        data: {
            contractId,
        },
        url: `/api/v2/users/${userId}/missions/${missionId}/sign-quota-contract`,
    }).then(response => safeTransformData(response));
};

const getSignUrlDocument = ({documentId, isDownload = true}) => {
    return coreApiInstance({
        method: 'GET',
        params: {
            contentDisposition: isDownload ? 'attachment' : 'inline',
            preview: isDownload ? undefined : true,
        },
        url: `/api/v2/documents/${documentId}/sign-url-get-document`,
    }).then(response => safeTransformData(response));
};

export const MissionSigningApi = {
    getMissionContractDocuments,
    generateMissionContract,
    sendMissionContract,
    signMissionContract,
    getSignUrlDocument,
};
