import {MissionClientsActionType} from './missions-client.action-type';

export const MissionClientsActions = {
    storeIsPublicUser: value => ({
        type: MissionClientsActionType.STORE_IS_PUBLIC_USER,
        payload: value,
    }),

    getDocumentInfo: () => ({
        type: MissionClientsActionType.GET_DOCUMENT_INFO,
    }),

    storeDocumentInfo: info => ({
        type: MissionClientsActionType.STORE_DOCUMENT_INFO,
        payload: info,
    }),

    getClientSignature: () => ({
        type: MissionClientsActionType.GET_CLIENT_SIGNATURE,
    }),

    storeClientSignature: signature => ({
        type: MissionClientsActionType.STORE_CLIENT_SIGNATURE,
        payload: signature,
    }),

    postClientSignature: (file, type) => ({
        type: MissionClientsActionType.POST_CLIENT_SIGNATURE,
        payload: {
            type,
            file,
        },
    }),

    postRequireModifications: note => ({
        type: MissionClientsActionType.POST_REQUIRE_MODIFICATIONS,
        payload: note,
    }),

    postSetClientScreen: screen => ({
        type: MissionClientsActionType.SET_CLIENT_SCREEN,
        payload: screen,
    }),

    postSignDocument: () => ({
        type: MissionClientsActionType.POST_SIGN_DOCUMENT,
    }),

    postValidateDocument: () => ({
        type: MissionClientsActionType.POST_VALIDATE_DOCUMENT,
    }),

    downloadDocument: () => ({
        type: MissionClientsActionType.DOWNLOAD_DOCUMENT,
    }),

};
