import {yupResolver} from '@hookform/resolvers/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SaveIcon from '@mui/icons-material/Save';
import {LoadingButton} from '@mui/lab';
import {
    Alert,
    Box,
    Button,
    FormControl,
    FormLabel,
    Grid,
    Grow,
    Paper,
    Slide,
    TextField,
    Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useContactInformationFormStyles} from './styles';
import {CityAutocomplete} from '../../../../../../components/CityAutocomplete';

import {PageHeader} from '../../../../../../components/page-header/PageHeader';
import Yup from '../../../../../../lib/yup/yup';
import {
    HEADQUARTERS_DEFAULT_CITY,
    HEADQUARTERS_DEFAULT_STREET_NAME,
    HEADQUARTERS_DEFAULT_STREET_NUMBER, HEADQUARTERS_DEFAULT_ZIP_CODE,
} from '../../../../../../utils/constants';
import {LocalesConstants} from '../../../../../../utils/locales-constants';
import {ZIP_CODE_REGEXES} from '../../../../../../utils/zip-code-validation';
import {AnimationActions} from '../../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../../animations/store/animations.selector';
import {ANIMATION_TYPE, FORM_ANIMATION_DELAY} from '../../../../../animations/utils/constants';
import {submitActiveStepForm} from '../../../../../company/modules/setup-company/store/setup-company.actions';
// import {FreelancerSelectors} from '../../../../features/freelancer';
// import {CompaniesSelectors} from '../../../../features/freelancer/modules/companies';
import {LoggedInUserSelectors} from '../../../../../user/modules/logged-in-user';
import {UserStatus} from '../../../../../user/modules/utils/constants';
import {
    ContractSigningActions,
} from '../../../contract-signing/store/contract-signing.actions';
import {
    ContractSigningSelector,
} from '../../../contract-signing/store/contract-signing.selectors';
import {OnboardingActions} from '../../../onboarding/store/onboarding.action';
import {OnboardingSteps} from '../../../onboarding/utils/onboadingStepsConstant';
import {CompanySetupSubSteps} from '../../utils/constants';

const defaultValues = {
    address: '',
    streetNumber: '',
    streetName: '',
    city: '',
    country: '',
    zipCode: '',
};

export const ContactInformationForm = ({hasSubmitModificationsButton, currentStep}) => {
    const styles = useContactInformationFormStyles();
    const {t} = useTranslation(LocalesConstants.Setup);
    const dispatch = useDispatch();
    const isContractSigningStep = OnboardingSteps.CONTRACT_SIGNING === currentStep;

    const isLoadingForm = useSelector(ContractSigningSelector.getIsLoadingPersonalAddressForm);
    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);
    const isSubAnimationActive = useSelector(AnimationsSelectors.selectIsSubAnimationActive);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const [addressError, setAddressError] = useState(false);

    const validationSchema = Yup.object({
        zipCode: Yup.string().required(t('validations.isRequired', {what: t('zip')})).matches(ZIP_CODE_REGEXES.fr, t('form:validation.notValidZipCode')),
        address: Yup.string().required(t('validations.isRequired', {what: t('address')})).max(400, t('validations.maxMessage')),
        city: Yup.string().required(t('validations.isRequired', {what: t('city')})).max(50, t('validations.maxMessage')),
        streetNumber: Yup.string(),
        streetName: Yup.string(),
        country: Yup.string(),
    }).test('addressError', 'addressError', ({address, city, zipCode}) => {
        const addressCondition = (`${HEADQUARTERS_DEFAULT_STREET_NUMBER} ${HEADQUARTERS_DEFAULT_STREET_NAME}`)
            .toLowerCase().trim() === address.trim().toLowerCase();
        const cityCondition = HEADQUARTERS_DEFAULT_CITY.toLowerCase().trim() === city.trim().toLowerCase();
        const zipCodeCondition = HEADQUARTERS_DEFAULT_ZIP_CODE.trim().toLowerCase() === zipCode.trim().toLowerCase();
        const result = addressCondition && cityCondition && zipCodeCondition;
        setAddressError(result);
        return !result;
    });

    const {
        setValue,
        formState: {isValid, isDirty, errors},
        control,
        reset,
        handleSubmit: createSubmitHandler,
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    const formRef = useRef(null);
    const isSubmittingForApproval = useRef(false);

    const handleSubmit = useCallback(data => {
        switch (currentStep) {
            case OnboardingSteps.COMPANY_SETUP:
                dispatch(submitActiveStepForm(data, isDirty, null, isSubmittingForApproval.current));
                isSubmittingForApproval.current = false;
                break;
            case OnboardingSteps.CONTRACT_SIGNING:
                dispatch(ContractSigningActions.submitPersonalAddress(data, isDirty));
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isDirty]);

    const handleSubmitModifications = useCallback(() => {
        isSubmittingForApproval.current = true;

        // Trigger form submit
        formRef.current.dispatchEvent(new Event('submit', {
            cancelable: true,
            bubbles: true,
        }));
    }, []);

    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const isContractSigned = UserStatus.CONTRACT_SIGNED === loggedInUser?.status;

    const isSubmitButtonDisabled = useMemo(() => {
        return !isValid;
    }, [isValid]);

    const handlePlaceDetailsChange = useCallback(value => {
        const {streetNumber, streetName, zipCode, city, country} = value;
        setValue('streetNumber', streetNumber ?? '', {shouldDirty: true, shouldValidate: true});
        setValue('streetName', streetName ?? '', {shouldDirty: true, shouldValidate: true});
        setValue('zipCode', zipCode ?? '', {shouldDirty: true, shouldValidate: true});
        setValue('city', city ?? '', {shouldDirty: true, shouldValidate: true});
        setValue('country', country ?? '', {shouldDirty: true, shouldValidate: true});
    }, [setValue]);

    useEffect(() => {
        if (!loggedInUser) {
            return;
        }
        const contactInformationData = loggedInUser?.contactInfo;
        reset({
            address: contactInformationData?.address || '',
            streetNumber: contactInformationData?.streetNumber || '',
            streetName: contactInformationData?.streetName || '',
            city: contactInformationData?.city || '',
            zipCode: contactInformationData?.zipCode || '',
            country: contactInformationData?.country || '',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedInUser, reset, setValue]);

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    if (!isMobileSize) {
        return (
            <form ref={formRef} onSubmit={createSubmitHandler(handleSubmit)} noValidate>
                <PageHeader disableGutterBottom={isContractSigningStep}>
                    {t('stepTitles.contactInformation')}
                </PageHeader>

                {isContractSigningStep && (
                    <Typography
                        sx={{
                            fontSize: theme => theme.typography.pxToRem(16),
                            lineHeight: 2,
                            fontWeight: 500,
                            color: 'v2.light.text.disabled',
                            letterSpacing: '0.1px',
                            my: 1,
                        }}
                    >
                        {t('stepSubtitles.contractPersonalAddress')}
                    </Typography>
                )}

                <Slide
                    direction="left"
                    in={isAnimationActive}
                    timeout={FORM_ANIMATION_DELAY}
                    onEntered={onEndedAnimation}
                    onExited={onEndedAnimation}
                >
                    <Paper radius={16} elevation={2} sx={{px: 5, py: 4}}>
                        <FormLabel component="legend" className={styles.formLabel}>
                            {t('personalAddress')}
                        </FormLabel>

                        {
                            addressError && (
                            <Alert
                                severity="warning"
                                variant="filled"
                                sx={{background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #ED6C02',
                                    fontWeight: 400,
                                    borderRadius: 1.5,
                                    mb: 2,
                                    color: 'text_v2.primary'}}
                            >
                                {t('validations.hiwayAddress')}
                            </Alert>
                            )
                        }

                        <Grid container spacing={4}>
                            <Grow
                                in={isSubAnimationActive}
                                timeout={FORM_ANIMATION_DELAY}
                                onExited={() => {
                                    dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.SECONDARY));
                                }}
                            >
                                <Grid item xs={12} md={6}>
                                    <CityAutocomplete
                                        name="address"
                                        label={t('address')}
                                        control={control}
                                        types={['address']}
                                        onPlaceDetailsChange={handlePlaceDetailsChange}
                                        initialValue={loggedInUser?.contactInfo?.address || ''}
                                        disabled={isContractSigned}
                                    />
                                </Grid>
                            </Grow>

                            <Grow
                                in={isSubAnimationActive}
                                timeout={FORM_ANIMATION_DELAY * 2}
                            >
                                <Grid item xs={12} md={4}>
                                    <FormControl variant="filled" fullWidth>
                                        <Controller
                                            // as={TextField}
                                            name="city"
                                            control={control}
                                            render={({field}) => (
                                                <TextField
                                                    error={!!errors.city}
                                                    helperText={errors.city?.message}
                                                    variant="filled"
                                                    label={t('city')}
                                                    disabled={isContractSigned}
                                                    required
                                                    fullWidth
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grow>

                            <Grow
                                in={isSubAnimationActive}
                                timeout={FORM_ANIMATION_DELAY * 3}
                            >
                                <Grid item xs={12} md={2}>
                                    <Controller
                                        // as={TextField}
                                        name="zipCode"
                                        control={control}
                                        render={({field}) => (
                                            <TextField
                                                disabled={isContractSigned}
                                                error={!!errors.zipCode}
                                                helperText={errors.zipCode?.message}
                                                variant="filled"
                                                label={t('zip')}
                                                required
                                                fullWidth
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grow>
                        </Grid>
                    </Paper>
                </Slide>

                <Box display="flex" justifyContent={isContractSigningStep ? 'end' : 'space-between'} sx={{mt: 3}}>
                    <Slide
                        direction="right"
                        in={!isContractSigningStep && isAnimationActive}
                        timeout={FORM_ANIMATION_DELAY * 4}
                    >
                        <Button
                            size="large"
                            variant="outlined"
                            color="primary"
                            startIcon={<ArrowBackIcon />}
                            onClick={() => {
                                dispatch(OnboardingActions.setSubStep(CompanySetupSubSteps.PERSONAL_INFORMATION));
                            }}
                        >
                            {t('back')}
                        </Button>
                    </Slide>
                    <Slide
                        direction="left"
                        in={isAnimationActive}
                        timeout={FORM_ANIMATION_DELAY * 4}
                    >
                        <Box display="flex" justifyContent="flex-end">
                            <Grow in={hasSubmitModificationsButton} mountOnEnter unmountOnExit>
                                <Box sx={{mr: 2}}>
                                    <LoadingButton
                                        loading={isLoadingForm}
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        startIcon={<SaveIcon />}
                                        onClick={handleSubmitModifications}
                                        disabled={isSubmitButtonDisabled}
                                    >
                                        {t('submitForApproval')}
                                    </LoadingButton>
                                </Box>
                            </Grow>

                            <LoadingButton
                                loading={isLoadingForm}
                                size="large"
                                variant="contained"
                                color="secondary"
                                endIcon={<ArrowForwardIcon />}
                                disabled={isSubmitButtonDisabled}
                                type="submit"
                            >
                                {t('saveAndProceed')}
                            </LoadingButton>
                        </Box>
                    </Slide>
                </Box>
            </form>
        );
    }

    return (
        <form ref={formRef} onSubmit={createSubmitHandler(handleSubmit)} noValidate>
            {isContractSigningStep && (
                <Box sx={{px: 2}}>
                    <PageHeader subTitle={t('stepSubtitles.contractPersonalAddress')}>
                        {t('stepTitles.contactInformation')}
                    </PageHeader>
                </Box>
            )}
            <Paper radius={16} elevation={2} sx={{px: 5, py: 4}}>
                <FormLabel component="legend" className={styles.formLabel}>
                    {t('personalAddress')}
                </FormLabel>

                {
                    addressError && (
                        <Alert
                            severity="warning"
                            variant="filled"
                            sx={{background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #ED6C02',
                                fontWeight: 400,
                                borderRadius: 1.5,
                                mb: 3,
                                color: 'text_v2.primary'}}
                        >
                            {t('validations.hiwayAddress')}
                        </Alert>
                    )
                }

                <Grid container>

                    <Grid item xs={12} md={6}>
                        <CityAutocomplete
                            name="address"
                            label={t('address')}
                            control={control}
                            types={['address']}
                            onPlaceDetailsChange={handlePlaceDetailsChange}
                            initialValue={loggedInUser?.contactInfo?.address || ''}
                        />
                    </Grid>

                    <Grid item xs={12} md={4} sx={{pt: 2}}>
                        <FormControl variant="filled" fullWidth>
                            <Controller
                                name="city"
                                control={control}
                                render={({field}) => (
                                    <TextField
                                        error={!!errors.city}
                                        helperText={errors.city?.message}
                                        variant="filled"
                                        label={t('city')}
                                        required
                                        fullWidth
                                        {...field}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={2} sx={{pt: 4}}>
                        <Controller
                            name="zipCode"
                            control={control}
                            render={({field}) => (
                                <TextField
                                    error={!!errors.zipCode}
                                    helperText={errors.zipCode?.message}
                                    variant="filled"
                                    label={t('zip')}
                                    required
                                    fullWidth
                                    {...field}
                                />
                            )}
                        />
                    </Grid>

                </Grid>
            </Paper>

            <Box display="flex" flexDirection="column" justifyContent="center" sx={{mt: 3}}>
                {!isContractSigningStep && (
                    <Button
                        sx={{mx: 2}}
                        size="large"
                        variant="outlined"
                        color="primary"
                        startIcon={<ArrowBackIcon />}
                        onClick={() => {
                            dispatch(OnboardingActions.setSubStep(CompanySetupSubSteps.PERSONAL_INFORMATION));
                        }}
                    >
                        {t('back')}
                    </Button>
                )}

                <LoadingButton
                    sx={{mt: 3, mx: 2}}
                    loading={isLoadingForm}
                    size="large"
                    variant="contained"
                    color="secondary"
                    endIcon={<ArrowForwardIcon />}
                    disabled={isSubmitButtonDisabled}
                    type="submit"
                >
                    {t('saveAndProceed')}
                </LoadingButton>

                {hasSubmitModificationsButton && (
                <LoadingButton
                    loading={isLoadingForm}
                    sx={{mt: 3, mx: 2}}
                    size="large"
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    onClick={handleSubmitModifications}
                    disabled={isSubmitButtonDisabled}
                >
                    {t('submitForApproval')}
                </LoadingButton>
                )}
            </Box>
        </form>
    );
};

ContactInformationForm.propTypes = {
    isLoading: PropTypes.bool,
    hasSubmitModificationsButton: PropTypes.bool,
    currentStep: PropTypes.string,
};

ContactInformationForm.defaultProps = {
    isLoading: false,
    hasSubmitModificationsButton: false,
    currentStep: OnboardingSteps.COMPANY_SETUP,
};
