import {MissionsCraActionTypes} from './missions-cra.action-type';

export const MissionsCraActions = {
    getCraDocuments: data => ({
        type: MissionsCraActionTypes.GET_CRA_DOCUMENTS,
        payload: {
            missionId: data?.missionId,
            userId: data?.userId,
        },
    }),
    storeCraDocuments: data => ({
        type: MissionsCraActionTypes.STORE_CRA_DOCUMENTS,
        payload: data,
    }),
    generateCraContract: data => ({
        type: MissionsCraActionTypes.GENERATE_CRA_CONTRACT,
        payload: {
            missionId: data?.missionId,
            dates: data?.dates,
            period: data?.period,
        },
    }),
    getSigningUrl: document => ({
        type: MissionsCraActionTypes.GET_SIGNING_URL,
        payload: {document},
    }),
    storeGenerateCraContract: document => ({
        type: MissionsCraActionTypes.STORE_CRA_CONTRACT,
        payload: document,
    }),
    postSignCraContract: data => ({
        type: MissionsCraActionTypes.SIGN_CRA_CONTRACT,
        payload: {
            documentId: data?.documentId,
            missionId: data?.missionId,
        },
    }),
    changeMissionCRAScreen: screen => ({
        type: MissionsCraActionTypes.CHANGE_MISSION_CRA_SCREEN,
        payload: screen,
    }),
    postSendCraContract: data => ({
        type: MissionsCraActionTypes.SEND_CRA_CONTRACT,
        payload: {
            documentId: data?.documentId,
            missionId: data?.missionId,
        },
    }),

};
