const selectLoggedInUser = state => state.user.loggedInUser;
const selectLoggedInUserDashboard = state => state.user.loggedInUserDashboard;
const selectIsUpdatingUser = state => state.user.isUserUpdating;
const isPublicUser = state => state.user.isPublicUser;

export const LoggedInUserSelectors = {
    selectLoggedInUser,
    selectIsUpdatingUser,
    selectLoggedInUserDashboard,
    isPublicUser,
};
