import PersonIcon from '@mui/icons-material/Person';
import {Alert, Box, Button, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, generatePath} from 'react-router-dom';
import {ConceptCard} from './freelancer-opportunity/ConceptCard';
import {FreelancerOpportunityHeader} from './freelancer-opportunity/FreelancerOpportunityHeader';
import FreelancerOpportunityTable from './freelancer-opportunity/FreelancerOpportunityTable';
import MyBidsCard from './freelancer-opportunity/MyBidsCard';
import {NewOpportunityCard} from './freelancer-opportunity/NewOpportunityCard';
import {RecrutersLinkCard} from './freelancer-opportunity/RecrutersLinkCard';
import {importLocaleBundle} from '../../../lib/i18next';
import {RoutePaths} from '../../../lib/router/route-paths';
import {LocalesConstants} from '../../../utils/locales-constants';

importLocaleBundle(LocalesConstants.Opportunity);

export const FreelancerOpportunity = () => {
    const {t} = useTranslation(LocalesConstants.Opportunity);

    const [isScrollTimestamp, setIsScrollTimestamp] = useState(false);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const alertAction = (
        <Box sx={{
            fontWeight: 500,
            textTransform: 'uppercase',
            cursor: 'pointer',
        }}
        >
            <Button
                sx={{
                    fontSize: theme => theme.typography.pxToRem(13),
                    textDecoration: 'none',
                    color: 'primary_v2.main',
                    height: '30px',
                }}
                component={Link}
                to={generatePath(RoutePaths.MY_PROFILE)}
                startIcon={(
                    <PersonIcon sx={{
                        color: 'primary_v2.main',
                        fontSize: '18px !important',
                        position: 'relative',
                        bottom: '1px',
                    }}
                    />
                )}
            >
                {t('myProfile')}
            </Button>
        </Box>
    );

    const onScrollToTable = () => {
        setIsScrollTimestamp(true);
    };

    return (
        <Box sx={isMobileSize ? {p: 2} : {}}>
            <FreelancerOpportunityHeader />

            <Alert
                sx={{mb: isMobileSize ? 2 : 3}}
                variant="outlined"
                severity="info"
                icon={isMobileSize ? false : ''}
                action={!isMobileSize && alertAction}
            >
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography
                        sx={{
                            fontSize: theme => theme.typography.pxToRem(14),
                            color: '#013654',
                        }}
                    >
                        {t('infoAlert')}
                    </Typography>
                </Box>
                { isMobileSize && (
                    <Box sx={{mt: 1, display: 'flex', justifyContent: 'flex-end'}}>
                        {alertAction}
                    </Box>
                )}
            </Alert>

            <Box sx={{
                display: 'flex',
                mb: isMobileSize ? 2 : 3,
                flexDirection: !isMobileSize ? 'row' : 'column',
                alignItems: !isMobileSize ? 'stretch' : 'flex-start',
                gap: isMobileSize ? 2 : 3,
            }}
            >
                <NewOpportunityCard />
                <ConceptCard />
                <MyBidsCard scrollToTable={onScrollToTable} />
            </Box>

            <RecrutersLinkCard />

            <FreelancerOpportunityTable
                isScrollTimestamp={isScrollTimestamp}
                setIsScrollTimestamp={setIsScrollTimestamp}
            />
        </Box>
    );
};
