
import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {OpportunitiesActions} from './opportunity.action';
import {OpportunityActionTypes} from './opportunity.action-type';
import {OpportunitySelector} from './opportunity.selector';
import {appLoaderSaga} from '../../../app/store/app-loader.sagas';
import {push} from '../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../lib/router/route-paths';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import {camelToSnakeCase} from '../../../utils/string';
import {getSearchParameters} from '../../invoicing/util/functions';
import {LoadingActions, LoadingTypes} from '../../loading';
import {LoggedInUserSelectors} from '../../user/modules/logged-in-user';
import {OpportunityApi} from '../api/opportunity.api';
import {
    CLUSTER_TABLE_DEFAULT,
    CLUSTER_TABLE_DEFAULT_SORT_ORDER,
    FREELANCER_CLUSTER_TABLE_DEFAULT,
} from '../utls/constants';

const getFreelancerOpportunityClusterFlow = function* (id) {
    try {
        const params = prepareFreelancerParams();

        const {items, totalRecords} = yield call(OpportunityApi.getFreelancerOpportunityList, {id, params});

        yield put(OpportunitiesActions.storeOpportunityList({
            count: totalRecords,
            items,
        }));
    } catch (error) {
        Debug.error('opportunity', 'Error: ', {error});
        yield put(LoadingActions.setLoading(LoadingTypes.GET_OPPORTUNITY, false));
        Toast.error('genericError');
    }
};

const getFreelancerOtherOpportunityClusterFlow = function* (id) {
    try {
        const params = prepareOtherParams();

        const {items, totalRecords} = yield call(OpportunityApi.getFreelancerOtherOpportunityList, {id, params});

        yield put(OpportunitiesActions.storeOtherOpportunityList({
            count: totalRecords,
            items,
        }));
    } catch (error) {
        Debug.error('opportunity', 'Error: ', {error});
        yield put(LoadingActions.setLoading(LoadingTypes.GET_OTHER_OPPORTUNITY, false));
        Toast.error('genericError');
    }
};

const getMyBidsListFlow = function* (id) {
    try {
        const items = yield call(OpportunityApi.getMyBidsList, id);

        yield put(OpportunitiesActions.storeMyBidsList(items));
    } catch (error) {
        Debug.error('opportunity', 'Error: ', {error});
        yield put(LoadingActions.setLoading(LoadingTypes.GET_MY_BIDS, false));
        Toast.error('genericError');
    }
};

const submitOpportunityClusterFlow = function* ({freelancerId, data}) {
    try {
        yield call(OpportunityApi.submitOpportunityCluster, {freelancerId, data});

        Toast.success('genericSuccessSave');

        yield put(push(RoutePaths.CLUSTER_SUBMIT_SUCCESS));
    } catch (error) {
        Debug.error('opportunity', 'Error: ', {error});
        yield put(LoadingActions.setLoading(LoadingTypes.SUBMIT_OPPORTUNITY, false));
        Toast.error('genericError');
    }
};

const getFreelancerSpecialisationsFlow = function* () {
    try {
        const data = yield call(OpportunityApi.getSpecialisations);

        yield put(OpportunitiesActions.storeFreelancerSpecialisations(data));
    } catch (error) {
        Debug.error('opportunity', 'Error: ', {error});
        yield put(LoadingActions.setLoading(LoadingTypes.GET_SPECIALISATIONS, false));
        Toast.error('genericError');
    }
};

const applyOpportunityClusterFlow = function* (freelancerId, opportunityId) {
    try {
        const bid = yield call(OpportunityApi.applyFreelancerOpportunity, freelancerId, opportunityId);

        Toast.success('bidSuccessful');

        return bid;
    } catch (error) {
        Debug.error('opportunity', 'Error: ', {error});
        yield put(LoadingActions.setLoading(LoadingTypes.APPLY_OPPORTUNITY, false));

        // Case when user is already applied
        if (error?.response?.data?.error_type === 'HTTP_CONFLICT_ERROR') {
            Toast.success('bidSuccessful');
            return;
        }

        Toast.error('genericError');
    }
};

const unapplyOpportunityClusterFlow = function* (freelancerId, opportunityId) {
    try {
        const bid = yield call(OpportunityApi.unapplyFreelancerOpportunity, freelancerId, opportunityId);

        Toast.success('bidUnapplySuccessful');

        return bid;
    } catch (error) {
        Debug.error('opportunity', 'Error: ', {error});
        yield put(LoadingActions.setLoading(LoadingTypes.APPLY_OPPORTUNITY, false));

        // Case when user is already unapplied
        if (error?.response?.data?.error_type === 'HTTP_CONFLICT_ERROR') {
            Toast.success('bidUnapplySuccessful');
            return;
        }

        Toast.error('genericError');
    }
};

const getAdminClusterFlow = function* () {
    try {
        const params = prepareParams();
        const cluster = yield call(OpportunityApi.getAdminCluster, {params});

        yield put(OpportunitiesActions.storeOpportunityCluster(cluster));
    } catch (error) {
        Debug.error('opportunity', 'Error: ', {error});
        yield put(LoadingActions.setLoading(LoadingTypes.GET_OPPORTUNITY, false));
        Toast.error('genericError');
    }
};

const searchFreelancerFlow = function* ({text, limit}) {
    try {
        const data = yield call(OpportunityApi.searchFreelancer, text, limit);

        yield put(OpportunitiesActions.storeSearch(data));
    } catch (error) {
        Debug.error('opportunity', 'Error: ', {error});
        yield put(LoadingActions.setLoading(LoadingTypes.SEARCH_FREELANCER, false));
        Toast.error('genericError');
    }
};

const toggleAcceptFlow = function* (data) {
    try {
        yield call(OpportunityApi.toggleAccept, data);
    } catch (error) {
        Debug.error('opportunity', 'Error: ', {error});
        yield put(LoadingActions.setLoading(LoadingTypes.TOGGLE_ACCEPT, false));
        Toast.error('genericError');
    }
};

const prepareOtherParams = () => {
    const queryParams = getSearchParameters();
    const params = {
        limit: FREELANCER_CLUSTER_TABLE_DEFAULT.limit,
    };

    if (queryParams['other-opportunities-rowsPerPage']) {
        params.limit = queryParams['other-opportunities-rowsPerPage'];
    }

    if (queryParams['other-opportunities-page']) {
        const page = queryParams['other-opportunities-page'];
        params.offset = page * FREELANCER_CLUSTER_TABLE_DEFAULT.limit;
    }

    if (queryParams['other-opportunities-sortBy']) {
        const sortBy = camelToSnakeCase(queryParams['other-opportunities-sortBy']);
        params.sort_column = sortBy;
        params.sort_direction = CLUSTER_TABLE_DEFAULT_SORT_ORDER[sortBy];
    }

    if (queryParams['other-opportunities-sortDirection']) {
        params.sort_direction = queryParams['other-opportunities-sortDirection'];
    }

    // TODO: add filter (positions_sought parameter)

    return params;
};

const prepareFreelancerParams = () => {
    const queryParams = getSearchParameters();
    const params = {
        limit: FREELANCER_CLUSTER_TABLE_DEFAULT.limit,
    };

    if (queryParams['opportunities-rowsPerPage']) {
        params.limit = queryParams['opportunities-rowsPerPage'];
    }

    if (queryParams['opportunities-page']) {
        const page = queryParams['opportunities-page'];
        params.offset = page * FREELANCER_CLUSTER_TABLE_DEFAULT.limit;
    }

    if (queryParams['opportunities-sortBy']) {
        const sortBy = camelToSnakeCase(queryParams['opportunities-sortBy']);
        params.sort_column = sortBy;
        params.sort_direction = CLUSTER_TABLE_DEFAULT_SORT_ORDER[sortBy];
    }

    if (queryParams['opportunities-sortDirection']) {
        params.sort_direction = queryParams['opportunities-sortDirection'];
    }

    return params;
};

const prepareParams = () => {
    const queryParams = getSearchParameters();

    const params = {
        limit: CLUSTER_TABLE_DEFAULT.limit,
    };

    if (queryParams['cluster-page']) {
        const page = queryParams['cluster-page'] - 1;
        params.offset = page * CLUSTER_TABLE_DEFAULT.limit;
    }

    if (queryParams['cluster-sortBy']) {
        const sortBy = queryParams['cluster-sortBy'];
        params.sort_column = sortBy;
        params.sort_direction = CLUSTER_TABLE_DEFAULT_SORT_ORDER[sortBy];
    }

    if (queryParams['cluster-sortDirection']) {
        params.sort_direction = queryParams['cluster-sortDirection'];
    }

    return params;
};

// Workers

export const getFreelancerOpportunityClusterWorker = function* () {
    const user = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield put(LoadingActions.setLoading(LoadingTypes.GET_OPPORTUNITY, true));

    yield call(getFreelancerOpportunityClusterFlow, user.id);

    yield put(LoadingActions.setLoading(LoadingTypes.GET_OPPORTUNITY, false));
};

export const getFreelancerOtherOpportunityClusterWorker = function* () {
    const user = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield put(LoadingActions.setLoading(LoadingTypes.GET_OTHER_OPPORTUNITY, true));

    yield call(getFreelancerOtherOpportunityClusterFlow, user.id);

    yield put(LoadingActions.setLoading(LoadingTypes.GET_OTHER_OPPORTUNITY, false));
};

export const getMyBidsListWorker = function* () {
    const user = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield put(LoadingActions.setLoading(LoadingTypes.GET_MY_BIDS, true));

    yield call(getMyBidsListFlow, user.id);

    yield put(LoadingActions.setLoading(LoadingTypes.GET_MY_BIDS, false));
};

export const submitOpportunityClusterWorker = function* ({payload}) {
    const {freelancerId, data} = payload;

    yield put(LoadingActions.setLoading(LoadingTypes.SUBMIT_OPPORTUNITY, true));

    yield call(submitOpportunityClusterFlow, {freelancerId, data});

    yield put(LoadingActions.setLoading(LoadingTypes.SUBMIT_OPPORTUNITY, false));
};

export const getFreelancerSpecialisationsWorker = function* () {
    yield put(LoadingActions.setLoading(LoadingTypes.GET_SPECIALISATIONS, true));

    yield call(getFreelancerSpecialisationsFlow);

    yield put(LoadingActions.setLoading(LoadingTypes.GET_SPECIALISATIONS, false));
};

export const applyToOpportunityAsAdminWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.APPLY_OPPORTUNITY, true));

    yield call(applyOpportunityClusterFlow, payload.userId, payload.opportunityId);
    yield call(getAdminClusterFlow);

    yield put(LoadingActions.setLoading(LoadingTypes.APPLY_OPPORTUNITY, false));
};

export const applyToOpportunityFromClusterPageWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.APPLY_OPPORTUNITY, true));

    const {opportunityId} = payload;
    const user = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(applyOpportunityClusterFlow, user.id, opportunityId);
    // TODO: replace this, update the store instead
    yield call(getFreelancerOtherOpportunityClusterFlow, user.id);
    yield call(getMyBidsListFlow, user.id);

    yield put(LoadingActions.setLoading(LoadingTypes.APPLY_OPPORTUNITY, false));
};

export const applyToOpportunityFromEmailLinkWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.APPLY_OPPORTUNITY, true));

    const {opportunityId} = payload?.params || {};
    const user = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(applyOpportunityClusterFlow, user.id, opportunityId);
    // TODO: replace this, update the store instead
    yield call(getFreelancerOtherOpportunityClusterFlow, user.id);
    yield call(getMyBidsListFlow, user.id);

    yield put(LoadingActions.setLoading(LoadingTypes.APPLY_OPPORTUNITY, false));
};

export const unapplyOpportunityClusterWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.APPLY_OPPORTUNITY, true));
    const {opportunityId} = payload;

    const user = yield select(LoggedInUserSelectors.selectLoggedInUser);
    yield call(unapplyOpportunityClusterFlow, user.id, opportunityId);
    // TODO: replace this, update the store instead
    yield call(getFreelancerOtherOpportunityClusterFlow, user.id);
    yield call(getMyBidsListFlow, user.id);

    yield put(LoadingActions.setLoading(LoadingTypes.APPLY_OPPORTUNITY, false));
};

export const getAdminClusterWorker = function* (params) {
    yield put(LoadingActions.setLoading(LoadingTypes.GET_OPPORTUNITY, true));

    yield call(appLoaderSaga, params);

    yield call(getAdminClusterFlow);

    yield put(LoadingActions.setLoading(LoadingTypes.GET_OPPORTUNITY, false));
};

export const searchFreelancerWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.SEARCH_FREELANCER, true));

    yield call(searchFreelancerFlow, payload);

    yield put(LoadingActions.setLoading(LoadingTypes.SEARCH_FREELANCER, false));
};

export const toggleAcceptWorker = function* ({payload}) {
    const {freelancerId, opportunityId, bidId, isTurningOn} = payload;

    yield put(LoadingActions.setLoading(LoadingTypes.TOGGLE_ACCEPT, true));

    yield call(toggleAcceptFlow, {freelancerId, opportunityId, bidId});

    const clusters = yield select(OpportunitySelector.getClusters);

    const bids = clusters.entities[opportunityId].bids;

    const newBids = bids.map(bid => {
        return {...bid, accepted: isTurningOn ? bidId === bid.bidId : false};
    });

    const newCluster = {...clusters,
        entities: {...clusters.entities,
            [opportunityId]: {
                ...clusters.entities[opportunityId],
                bids: newBids,
            }}};

    yield put(OpportunitiesActions.storeOpportunityCluster(newCluster));

    yield put(LoadingActions.setLoading(LoadingTypes.TOGGLE_ACCEPT, false));
};

export const watchOpportunitiesSaga = function* () {
    yield all([
        takeLatest(OpportunityActionTypes.GET_FREELANCER, getFreelancerOpportunityClusterWorker),
        takeLatest(OpportunityActionTypes.GET_OTHER, getFreelancerOtherOpportunityClusterWorker),
        takeLatest(OpportunityActionTypes.GET_MY_BIDS, getMyBidsListWorker),
        takeLatest(OpportunityActionTypes.SUBMIT, submitOpportunityClusterWorker),
        takeLatest(OpportunityActionTypes.SEARCH_FREELANCER, searchFreelancerWorker),
        takeLatest(OpportunityActionTypes.TOGGLE_ACCEPT, toggleAcceptWorker),
        takeLatest(OpportunityActionTypes.APPLY_FROM_CLUSTER_PAGE, applyToOpportunityFromClusterPageWorker),
        takeLatest(OpportunityActionTypes.APPLY_AS_ADMIN, applyToOpportunityAsAdminWorker),
        takeLatest(OpportunityActionTypes.UNAPPLY, unapplyOpportunityClusterWorker),
    ]);
};
