import {CircularProgress} from '@mui/material';
import Box from '@mui/material/Box';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CapitalDepositClosing from './CapitalDepositClosing';
import CapitalDepositCompleted from './CapitalDepositCompleted';
import {FinalScreen} from './FinalScreen';
import PendingBankOnboarding from './PendingBankOnboarding';
import WaitingForCapitalDepositClosing from './WaitingForCapitalDepositClosing';
import {DOCUMENT_STATUSES, DOCUMENT_TYPES} from '../../../../../consts/document-constants';
import {getIsRegistrationWithExistingCompany} from '../../../../../utils/holidays';
import {isUserCareFn} from '../../../../../utils/user-roles';
import {BankSelector} from '../../../../bank/store/bank.selector';
import {FULL_COMPANY_STATUSES} from '../../../../company/modules/setup-company/utils/constants';
import {DatabaseSelectors} from '../../../../document/modules/database/store/database.selector';
import DocumentsSigningInsuranceWrapper
    from '../../../../document/modules/signing/components/document-wrappers/DocumentsSigningInsuranceWrapper';
import {
    DocumentsSigningOnboardingWrapper,
} from '../../../../document/modules/signing/components/document-wrappers/DocumentsSigningOnboardingWrapper';
import Insurance from '../../../../insurance/Insurance';
import InsuranceFinalPage from '../../../../insurance/InsuranceFinalPage';
import {InsuranceActions} from '../../../../insurance/store/insurance.action';
import {InsuranceSelectors} from '../../../../insurance/store/insurance.selector';
import {InsuranceStatus} from '../../../../insurance/utils/constants';
import {LoadingTypes, useLoadingState} from '../../../../loading';
import {LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';
import {selectIsLoadingLoggedInUser} from '../../../../user/store/user.selectors';
import {USER_STATUSES} from '../../../../user/utils/constants';
import {
    CapitalDepositStatus,
    FinalizationSubsteps,
    IndividualIdentificationStatus,
} from '../../capital-deposit/utils/constants';
import {CompaniesSelectors} from '../../companies';
import {CompanyRegistrationFileList} from '../../company-registration/components/CompanyRegistrationFileList';
import {CompanyRegistrationInternalSubSteps} from '../../company-registration/utils/constants';
import {OnboardingSelectors} from '../../onboarding/store/onboarding.selectors';
import {OnboardingSteps} from '../../onboarding/utils/onboadingStepsConstant';

export const Finalization = () => {
    const dispatch = useDispatch();
    const freelancer = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const company = useSelector(CompaniesSelectors.createCompanyByIdSelector(freelancer?.defaultCompanyId));

    const insurance = useSelector(InsuranceSelectors.selectInsurance);
    const documents = useSelector(DatabaseSelectors.selectDocuments);

    const freelancerId = freelancer?.id;
    const companyId = freelancer?.defaultCompanyId;

    const insuranceDocs = Object
        ?.values(documents)
        ?.find(doc => doc?.type === DOCUMENT_TYPES.INSURANCE_GROUP_INSURANCE_DIRECT_DEBIT_MANDATE);

    const unsignedInsuranceDocs = Object
        ?.values(documents)
        ?.find(doc => doc?.type === DOCUMENT_TYPES.INSURANCE_GROUP_INSURANCE_DIRECT_DEBIT_MANDATE
            && doc?.status !== DOCUMENT_STATUSES.SIGNED);

    const capitalDeposit = useSelector(BankSelector.selectCapitalDeposit);

    const bankIntegration = useSelector(BankSelector.selectIntegrations);

    const isOnboardingDataLoading = useLoadingState(LoadingTypes.ONBOARDING);
    const isCapitalDepositLoading = useLoadingState(LoadingTypes.GET_CAPITAL_DEPOSIT);
    const isCompaniesLoading = useLoadingState(LoadingTypes.FREELANCER_COMPANIES);
    const isMyAccountLoading = useLoadingState(LoadingTypes.MY_ACCOUNT);
    const isTransactionsLoading = useLoadingState(LoadingTypes.BANK_TRANSACTIONS);
    const isLoadingLoggedInUser = useSelector(selectIsLoadingLoggedInUser);

    const internalSubStep = useSelector(OnboardingSelectors.selectInternalSubStep);

    const bankAccountHolders = bankIntegration?.[0]?.bankAccountHolders;
    const bankAccountHolder = bankAccountHolders ? bankAccountHolders.find(holder => holder.type === 'INDIVIDUAL') : {};
    const companyBankAccountHolder = bankAccountHolders ? bankAccountHolders.find(holder => holder.type === 'COMPANY') : {};

    const registrationType = company?.enterpriseInformation?.registration_type;
    const registrationWithExistingCompany = getIsRegistrationWithExistingCompany(registrationType);

    const integrations = useSelector(BankSelector.selectIntegrations);
    const hasNoIntegration = !integrations?.[0];

    const dataIsLoading = isOnboardingDataLoading
        || isCapitalDepositLoading === true
        || isCompaniesLoading
        || isMyAccountLoading
        || isLoadingLoggedInUser
        || isTransactionsLoading;

    useEffect(() => {
        if (!unsignedInsuranceDocs && internalSubStep === FinalizationSubsteps.INSURANCE) {
            dispatch(InsuranceActions.loadInsuranceDocument({freelancerId, companyId}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalSubStep]);

    let stepComponent = <FinalScreen />;

    if (dataIsLoading) {
        stepComponent = (
            <CircularProgress />
        );
    }

    if (isUserCareFn(freelancer)) {
        if (internalSubStep === FinalizationSubsteps.PENDING_BANK_ONBOARDING) {
            stepComponent = (
                <PendingBankOnboarding />
            );
        } else if (company?.status === FULL_COMPANY_STATUSES.PENDING_FINAL_SIGS
            || company?.status === FULL_COMPANY_STATUSES.REGISTRATION_COMPLETED
            || (company?.status === FULL_COMPANY_STATUSES.ACTIVE && freelancer?.status !== USER_STATUSES.ACTIVE)
            || internalSubStep === CompanyRegistrationInternalSubSteps.FILE_LIST) {
            stepComponent = <CompanyRegistrationFileList hasIntegration={true} />;

            if (internalSubStep === CompanyRegistrationInternalSubSteps.DOCUMENT_SIGNING) {
                stepComponent = (
                    <DocumentsSigningOnboardingWrapper
                        step={OnboardingSteps.COMPANY_REGISTRATION}
                        isBackButtonVisible={false}
                    />
                );
            }
        }
    } else if ((capitalDeposit?.status && !dataIsLoading)
        || (hasNoIntegration && !registrationWithExistingCompany)) {
        if (!hasNoIntegration) {
            if (company?.status === FULL_COMPANY_STATUSES.PENDING_FINAL_SIGS
                || internalSubStep === CompanyRegistrationInternalSubSteps.FILE_LIST) {
                stepComponent = <CompanyRegistrationFileList hasIntegration={true} />;

                if (internalSubStep === CompanyRegistrationInternalSubSteps.DOCUMENT_SIGNING) {
                    stepComponent = (
                        <DocumentsSigningOnboardingWrapper
                            step={OnboardingSteps.COMPANY_REGISTRATION}
                            isBackButtonVisible={false}
                        />
                    );
                }
            } else {
                if (capitalDeposit.status !== CapitalDepositStatus.COMPLETED
                    || companyBankAccountHolder?.verificationStatus !== IndividualIdentificationStatus.VERIFIED) {
                    return (
                        <WaitingForCapitalDepositClosing />
                    );
                }

                if (capitalDeposit.status === CapitalDepositStatus.COMPLETED
                    && (bankAccountHolder?.bankAccounts?.[0]?.status !== 'CLOSED'
                        && bankAccountHolder?.bankAccounts?.[0]?.status !== 'CLOSING')) {
                    return (
                        <CapitalDepositClosing />
                    );
                }

                if (
                    capitalDeposit?.status === CapitalDepositStatus.COMPLETED
                    && companyBankAccountHolder?.verificationStatus === IndividualIdentificationStatus.VERIFIED
                    && company?.status === FULL_COMPANY_STATUSES.REGISTRATION_COMPLETED
                ) {
                    return <CapitalDepositCompleted />;
                }
            }
        }

        if (company?.status === FULL_COMPANY_STATUSES.ACTIVE) {
            // If status is WAITING_FOR_COMPANY_REGISTRATION (not yet signed)
            // show insurance page & signing
            if (insurance?.status === InsuranceStatus.WAITING_FOR_COMPANY_REGISTRATION) {
                // If there are no docs show insurance page, otherwise for signing
                if (!insuranceDocs) {
                    stepComponent = <Insurance />;
                } else {
                    stepComponent = <DocumentsSigningInsuranceWrapper isBackButtonVisible={false} />;
                }
            } else if (insurance?.status) {
                // If there is a status and it is not WAITING_FOR_COMPANY_REGISTRATION (it is signed)
                stepComponent = internalSubStep === FinalizationSubsteps.ACCESS_PLATFORM
                    ? <FinalScreen />
                    : <InsuranceFinalPage insuranceType={insurance?.insuranceType} />;
            } else {
                // Wait while there is no insurance status
                stepComponent = (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '300px',
                        width: 'calc(100vw - 240px)',
                    }}
                    >
                        <CircularProgress />
                    </Box>
                );
            }
        }
    }

    return (
        <Box display="grid" gridTemplateRows="min-content 1fr">
            {stepComponent}
        </Box>
    );
};

