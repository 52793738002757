import {Box, Container, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import BrochureCard from './components/BrochureCard';
import InsuranceBanner from './components/InsuranceBanner';
import InsuranceBottomSection from './components/InsuranceBottomSection';
import InsuranceMidSection from './components/insuranceMidSection';
import InsuranceMidSectionCardWrapper from './components/InsuranceMidSectionCardWrapper';
import SignModal from './components/SignModal';
import SkipModal from './components/SkipModal';
import SubscribeModal from './components/subscribe-modal/SubscribeModal';
import {INSURANCE_TABS} from './utils/constants';
import {LocalesConstants} from '../../utils/locales-constants';
import {FreelancerSelectors} from '../freelancer';
import {UiActions} from '../ui/store/ui.action';
import {ModalsKeys} from '../ui/utils/constants';

const Insurance = () => {
    const {t} = useTranslation(LocalesConstants.Insurance);
    const dispatch = useDispatch();
    const [tab, setTab] = useState(INSURANCE_TABS.RC_TAB);

    const freelancer = useSelector(FreelancerSelectors.selectAccount);

    const taxSystem = freelancer?.defaultCompanyInfo?.tax_system;
    const freelancerId = freelancer?.id;
    const companyId = freelancer?.defaultCompanyId;

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleOpenSkipModal = () => dispatch(UiActions.setActiveModal(ModalsKeys.INSURANCE_SKIP_MODAL, true));
    const handleOpenSubscribeModal = () => {
        dispatch(UiActions.setActiveModal(ModalsKeys.INSURANCE_SUBSCRIBE_MODAL, true));
    };

    return (
        <Container
            maxWidth="xl"
            disableGutters
            sx={{
                px: isMobileSize ? 2 : 0,
                pt: isMobileSize ? 3 : 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: isMobileSize ? 2 : 3,
            }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 2.5,
                alignSelf: 'stretch',
            }}
            >
                <Typography variant="alt_h4">{t('pageTitle')}</Typography>

                <InsuranceBanner
                    taxSystem={taxSystem}
                    handleOpenSkipModal={handleOpenSkipModal}
                    handleOpenSubscribeModal={handleOpenSubscribeModal}
                />
            </Box>
            <InsuranceMidSection handleOpenSubscribeModal={handleOpenSubscribeModal} />
            {isMobileSize && <InsuranceMidSectionCardWrapper />}
            {(isMobileSize && tab === INSURANCE_TABS.RC_TAB) && <BrochureCard />}
            <InsuranceBottomSection
                taxSystem={taxSystem}
                handleOpenSubscribeModal={handleOpenSubscribeModal}
                tab={tab}
                handleTabChange={handleTabChange}
            />
            <SkipModal
                taxSystem={taxSystem}
                freelancerId={freelancerId}
                companyId={companyId}
            />
            <SubscribeModal
                taxSystem={taxSystem}
                freelancerId={freelancerId}
                companyId={companyId}
            />
            <SignModal
                freelancerId={freelancerId}
                companyId={companyId}
            />
        </Container>
    );
};

export default Insurance;
