import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CategoryIcon from '@mui/icons-material/Category';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import {Alert, AlertTitle, Box, Button, CircularProgress, Grow, Typography, useMediaQuery} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import RejectedAndCancelled from './RejectedAndCancelled';
import TransactionFileSection from './TransactionFileSection';
import TransactionTva from './TransactionTva';
import DrawerWrapper from '../../../../../../../../components/drawer-wrapper/DrawerWrapper';
import {countDifferentValuesInArray, hasAtLeastTwoDifferentValues} from '../../../../../../../../utils/array';
import {LocalesConstants} from '../../../../../../../../utils/locales-constants';
import {LoadingSelectors, LoadingTypes} from '../../../../../../../loading';
import {UiActions} from '../../../../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../../../../ui/utils/constants';
import {TransactionsActions} from '../../../../store/transactions.action';
import {TransactionsSelector} from '../../../../store/transactions.selector';
import {
    TRANSACTION_CATEGORIZATION_SCREENS,
    TVA_ENUM,
    TVA_FACTORS_ENUM,
    TransactionSide,
    TransactionStatus,
    VAT_MATCH_STATUS,
    VAT_VALIDATION_STATUS,
    categorizationState,
} from '../../../../util/constants';
import {SECTION_STATUS} from '../../utils/constants';
import {
    hasMismatchArticleAmount,
    hasMissingArticleData,
} from '../../utils/functions';
import CategorizaitionAriclesSection from '../articles/ArticlesSection';
import PopularCategories from '../categories/PopularCategories';
import SelectedCategory, {selectedCategoryStyles} from '../categories/SelectedCategory';
import SuggestedCategories from '../categories/SuggestedCategories';
import AllCategoriesList from '../common/AllCategoriesList';
import CategorisationFooter from '../footer/CategorisationFooter';
import CategorisationSection from '../header/CategorisationSection';
import ConfirmProofOfDocumentsModal from '../modals/ConfirmProofOfDocumentsModal';
import DeactivateSubscriptionModal, {deactivateSubscriptionModalType} from '../modals/DeactivateSubscriptionModal';
import QuitWithInvalidArticlesModal from '../modals/QuitWithInvalidArticlesModal';
import Pacman from '../pacman/Pacman';
import SubscriptionPanel from '../subscription/SubscriptionPanel';
import VATValidation from '../vat-validation/VATValidation';

const currentYear = moment().format('YYYY');
const previousYear = moment(currentYear).subtract(1, 'year').format('YYYY');
const nextYear = moment(currentYear).add(1, 'year').format('YYYY');

const DebitCategorizationPage = ({
    isShowAllCategories,
    setIsShowAllCategories,
    isArticleViewOpen,
    setIsArticleViewOpen,
    isSubscriptionOpened,
    setIsSubscriptionOpened,
    defaultNumberOfUncategorized,
    isValidatingVATOpen,
    onViewValidatedVAT,
    onCompleteVATValidation,
}) => {
    const {t} = useTranslation(LocalesConstants.Bank);
    const dispatch = useDispatch();
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(
            LoadingTypes.BANK_TRANSACTION,
        ),
    );

    const [articleId, setUsedArticleId] = useState(false);

    const categorizationInfo = useSelector(TransactionsSelector.selectCategorizationScreenInfo);

    const details = useSelector(TransactionsSelector.createSelectTransactionById(categorizationInfo?.transactionId));
    const totalOverview = useSelector(TransactionsSelector.selectTransactionTotalOverview);
    const totalCategorizedInSession = useSelector(TransactionsSelector.selectTotalCategorizedInSession);

    const categoryList = useSelector(TransactionsSelector.selectCategories);
    const mostUsedCategoryList = useSelector(TransactionsSelector.selectMostUsedDebitCategories);

    const mapCategoriesByMccKeys = useCallback(mccKeys => {
        return categoryList.reduce((finalCategoryMap, categoryGroup) => {
            const categoryMap = categoryGroup?.mccGroupItems.reduce((resultCategory, category) => {
                if (mccKeys.includes(category.mccKey)) {
                    return {...resultCategory, [category.mccKey]: category};
                }

                return resultCategory;
            }, {});

            return {...finalCategoryMap, ...categoryMap};
        }, {});
    }, [categoryList]);

    const mccKeyCategoryMap = useMemo(() => {
        return mapCategoriesByMccKeys(details.articles.map(article => article.mccKey));
    }, [details, mapCategoriesByMccKeys]);

    // Multiple articles are disabled for subscription atm
    const isMultiArticleAvailable = details?.flags?.multipleArticles
        && !details?.flags?.subscriptionCategorizationSupport;
    const isDocumentRequired = details?.flags?.documentsRequired;

    const isCategorized = details?.categorizationState === categorizationState.CATEGORIZED;
    const hasSubscriptionSupport = !!details?.articles?.[0]?.mccKey
        && details?.flags?.subscriptionCategorizationSupport;

    const isRejected = details.status === TransactionStatus.REJECTED;
    const isCanceled = details.status === TransactionStatus.CANCELED;
    const isRejectedOrCancelled = isRejected || isCanceled;

    const hasCategory = !!details.articles?.[0]?.mccKey;
    const hasDocuments = details?.documents?.length > 0;
    const hasMultipleArticles = details?.articles?.length > 1;
    const hasDifferentCategory = hasMultipleArticles
        && hasAtLeastTwoDifferentValues(details.articles, 'mccKey');
    const differentCategoryCount = hasDifferentCategory
        ? countDifferentValuesInArray(details?.articles, 'mccKey') : 1;

    const detailsYear = details?.date?.format('YYYY');


    const vatValidationStatus = details?.suggestedVat?.validationStatus;
    const vatMatchStatus = details?.suggestedVat?.matchStatus;

    const categoryDefaultVATAmounts = details?.articles
        .map(article => (
            Number(article.amount) - Number(article.amount)
            / (1 + (TVA_FACTORS_ENUM?.[mccKeyCategoryMap[details?.articles[0]?.mccKey]?.tva] ?? 0))
        ).toFixed(2));

    let pacmanStep = 0;
    if (isLoading === false && details?.flags) {
        if (isCategorized) {
            pacmanStep = 3;
        } else if (hasCategory && (isDocumentRequired && !hasDocuments)) {
            pacmanStep = 1;
        } else if (hasCategory) { // TODO Check for multiple articles
            pacmanStep = 2;
        }
    }

    const isEditable = !!(
        detailsYear === currentYear
            || detailsYear === previousYear
            || detailsYear === nextYear
    ) && !isRejected && !isCanceled;

    const isVATEditable = isEditable && !!details?.articles?.[0]?.editableVat;

    /*
     * TODO: As we progress further with the feature, OCR flag(s) should be modified accordingly
     */
    const isInvoiceIgnored = details?.ignoreProofDocument || details?.refuseToShareProofDocuments;
    const ocrEnabledFlag = !isInvoiceIgnored // We disable OCR if the invoice will not be provided
        && isVATEditable // We disable OCR if VAT is not editable
        && !hasMultipleArticles // We disable OCR for multi-article transactions
        && details?.documents?.length === 1; // We disable OCR for multi-document transactions


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (!mostUsedCategoryList) {
            dispatch(TransactionsActions.getMostUsedCategories({type: TransactionSide.DEBIT}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (isSubscriptionOpened && !hasSubscriptionSupport) {
            setIsSubscriptionOpened(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasSubscriptionSupport]);

    const createTransactionForSave = (newArticles, ignoreProofDocument) => {
        return TransactionsActions.saveArticles({
            id: details.id,
            articles: newArticles.map(article => {
                return {
                    id: article?.id,
                    amount: article?.amount,
                    description: article?.description,
                    mccKey: article?.mccKey,
                    tva: article?.tva === TVA_ENUM.NO_VAT ? TVA_ENUM.TVA_0 : article?.tva,
                    tvaAmount: article?.tvaAmount,
                };
            }),
            ignoreProofDocument: ignoreProofDocument ?? details?.ignoreProofDocument,
            accountId: details?.bankAccountId,
        });
    };

    const saveTransaction = (newArticles, ignoreProofDocument) => {
        if (details?.hasActiveSubscription) {
            dispatch(UiActions.setModalData(ModalsKeys.CATEGORIZATION_DEACTIVATE_SUBSCRIPTION, {
                type: deactivateSubscriptionModalType.CHANGE_CATEGORY,
                action: createTransactionForSave(newArticles, ignoreProofDocument),
                setIsShowAllCategories,
            }));

            dispatch(UiActions.setActiveModal(ModalsKeys.CATEGORIZATION_DEACTIVATE_SUBSCRIPTION, true));
            return;
        }

        dispatch(createTransactionForSave(newArticles, ignoreProofDocument));
    };

    const handleOpenAllCategories = () => {
        // If user has multiple articles category should be chosen on multiple article screen
        if (hasMultipleArticles) {
            setIsArticleViewOpen(true);
            return;
        }

        setIsShowAllCategories(true);
    };

    const handleCloseAllCategories = () => {
        setIsShowAllCategories(false);
    };

    const handleOpenArticles = () => {
        setIsArticleViewOpen(true);
    };

    const onSelectCategory = category => {
        if (!isEditable) {
            return;
        }

        let index = 0;
        if (hasMultipleArticles && articleId !== false) {
            const newIndex = details?.articles.findIndex(article => article?.id === articleId);
            if (newIndex !== -1) {
                index = newIndex;
            }
        }

        // If same is chosen do not save, just close the window for chosing categories
        if (details?.articles?.[index]?.mccKey === category?.mccKey) {
            setIsShowAllCategories(false);
            setUsedArticleId(false);
            return;
        }

        // Since suggested mcc category has only mcc we need to
        // find category details from list of categories
        const suggestedCategory = {...category};
        categoryList.forEach(categoryGroup => {
            categoryGroup?.mccGroupItems.forEach(category => {
                if (category.mccKey === suggestedCategory.mccKey) {
                    suggestedCategory.tva = category.tva;
                }
            });
        });

        const newArticles = [...details?.articles];

        newArticles[index] = {
            ...details?.articles[index],
            mccKey: suggestedCategory.mccKey,
            tva: suggestedCategory?.tva ?? details?.articles[index].tva,
        };

        if (details?.hasActiveSubscription) {
            dispatch(UiActions.setModalData(ModalsKeys.CATEGORIZATION_DEACTIVATE_SUBSCRIPTION, {
                type: deactivateSubscriptionModalType.CHANGE_CATEGORY,
                action: createTransactionForSave(newArticles),
                setIsShowAllCategories,
            }));

            dispatch(UiActions.setActiveModal(ModalsKeys.CATEGORIZATION_DEACTIVATE_SUBSCRIPTION, true));
        } else {
            if (isShowAllCategories) {
                setIsShowAllCategories(false);
            }

            saveTransaction(newArticles);
        }

        setUsedArticleId(false);
    };

    if (isLoading) {
        return (
            <>
                <Box sx={{
                    width: isMobileSize ? '100vw' : '600px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    alignSelf: 'center',
                    p: isMobileSize ? 2 : 0,
                    pb: isMobileSize ? 3 : 0,
                    my: isMobileSize ? 0 : 4,
                }}
                >
                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                        alignSelf: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '450px',
                    }}
                    >
                        <CircularProgress />
                    </Box>
                </Box>
                <CategorisationFooter
                    categorized={totalCategorizedInSession}
                    defaultNumberOfUncategorized={defaultNumberOfUncategorized}
                    nonCategorized={totalOverview?.totalUncategorizedTransactions}
                    details={details}
                />
            </>
        );
    }

    if (isShowAllCategories) {
        if (!isMobileSize) {
            return (
                <Grow in={true}>
                    <Box sx={{
                        width: '100vw',
                        p: 3,
                    }}
                    >
                        <AllCategoriesList
                            onSelectCategory={onSelectCategory}
                            side={TransactionSide.DEBIT}
                            text={isShowAllCategories}
                            isSubscribed={details?.hasActiveSubscription}
                            mccKey={details?.articles?.[0]?.mccKey}
                            hasMultipleArticles={hasMultipleArticles}
                            setIsShowAllCategories={setIsShowAllCategories}
                        />

                        <DeactivateSubscriptionModal />
                    </Box>
                </Grow>
            );
        }
        return (
            <DrawerWrapper
                isOpen={isShowAllCategories}
                onClose={handleCloseAllCategories}
                containerSx={{
                    pb: 5,
                }}
            >
                <AllCategoriesList
                    onSelectCategory={onSelectCategory}
                    isSubscribed={details?.hasActiveSubscription}
                    hasMultipleArticles={hasMultipleArticles}
                    mccKey={details?.articles?.[0]?.mccKey}
                    side={TransactionSide.DEBIT}
                    setIsShowAllCategories={setIsShowAllCategories}
                />

                <DeactivateSubscriptionModal />
            </DrawerWrapper>
        );
    }

    if (isArticleViewOpen) {
        if (!isMobileSize) {
            return (
                <Grow in={true}>
                    <div>
                        <CategorizaitionAriclesSection
                            details={details}
                            setIsShowAllCategories={setIsShowAllCategories}
                            setUsedArticleId={setUsedArticleId}
                            onClose={() => setIsArticleViewOpen(false)}
                            saveTransaction={saveTransaction}
                        />

                        <QuitWithInvalidArticlesModal
                            onCloseArticles={() => setIsArticleViewOpen(false)}
                        />
                    </div>
                </Grow>
            );
        }
        return (
            <DrawerWrapper
                isOpen={true}
                onClose={() => setIsArticleViewOpen(false)}
                containerSx={{
                    pb: 0,
                    px: 0,
                    pt: 1,
                }}
            >
                <CategorizaitionAriclesSection
                    details={details}
                    setIsShowAllCategories={setIsShowAllCategories}
                    setUsedArticleId={setUsedArticleId}
                    onClose={() => setIsArticleViewOpen(false)}
                    saveTransaction={saveTransaction}
                />
            </DrawerWrapper>
        );
    }

    let fileSectionStatus = !hasCategory
        ? SECTION_STATUS.WAITING_FOR_CATEGORY
        : (hasDocuments ? undefined
            : (
                isDocumentRequired
                    ? SECTION_STATUS.REQUIRED
                    : SECTION_STATUS.OPTIONAL
            ));

    let tvaSectionStatus = !hasCategory
        ? SECTION_STATUS.WAITING_FOR_CATEGORY
        : (!hasDocuments && !details?.ignoreProofDocument && isDocumentRequired)
            ? SECTION_STATUS.WAITING_FOR_FILE
            : (
                !details?.articles?.[0]?.editableVat
                    ? SECTION_STATUS.NON_EDITABLE
                    : null
            );

    if (isRejectedOrCancelled) {
        if (hasCategory) {
            fileSectionStatus = undefined;
            tvaSectionStatus = undefined;
        } else {
            fileSectionStatus = SECTION_STATUS.NO_INFORMATION_EXPECTED;
            tvaSectionStatus = SECTION_STATUS.NO_INFORMATION_EXPECTED;
        }
    }


    const hasArticleAmountAlert = hasMultipleArticles
        && hasMismatchArticleAmount(details?.categorizationWarnings);
    const hasArticleMissingData = hasMultipleArticles
        && hasMissingArticleData(details?.categorizationWarnings);

    if (isValidatingVATOpen) {
        return (
            <VATValidation
                transactionId={categorizationInfo?.transactionId}
                onCompleteVATValidation={onCompleteVATValidation}
                defaultVATPercent={t(`vat.${mccKeyCategoryMap[details?.articles[0]?.mccKey].tva}`)}
                defaultVATAmount={categoryDefaultVATAmounts[0]}
            />
        );
    }

    return (
        <>
            <Box sx={{
                width: isMobileSize ? '100vw' : '600px',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                alignSelf: 'center',
                p: isMobileSize ? 2 : 0,
                pb: isMobileSize ? 3 : 0,
                my: isMobileSize ? 0 : 4,
            }}
            >
                {!isRejectedOrCancelled && (
                    <Grow in={!isRejectedOrCancelled}>
                        <div>
                            <Pacman step={pacmanStep} isMobileSize={isMobileSize} />
                        </div>
                    </Grow>
                )}

                {isRejectedOrCancelled && (
                    <Grow in={isRejectedOrCancelled}>
                        <div>
                            <RejectedAndCancelled
                                isCanceled={isCanceled}
                                isRejected={isRejected}
                            />
                        </div>
                    </Grow>
                )}

                {hasArticleAmountAlert && !isRejectedOrCancelled && (
                    <Grow in={true}>
                        <div>
                            <Alert
                                severity="error"
                                action={(
                                    <Button
                                        onClick={() => setIsArticleViewOpen(true)}
                                        sx={{
                                            color: 'v2.light.error.shades160',
                                            fontSize: theme => theme.typography.pxToRem(13),
                                            letterSpacing: '0.46px',
                                            fontWeight: 500,
                                            py: 0.6,
                                        }}
                                    >
                                        {t(`categorisation.fixArticlesButton`)}
                                    </Button>
                                )}
                            >
                                {t(`categorisation.hasArticleAmountAlert`)}
                            </Alert>
                        </div>
                    </Grow>
                )}

                {hasArticleMissingData && !isRejectedOrCancelled && (
                    <Grow in={true}>
                        <div>
                            <Alert
                                severity="error"
                                action={(
                                    <Button
                                        onClick={() => setIsArticleViewOpen(true)}
                                        sx={{
                                            color: 'v2.light.error.shades160',
                                            fontSize: theme => theme.typography.pxToRem(13),
                                            letterSpacing: '0.46px',
                                            fontWeight: 500,
                                            py: 0.6,
                                        }}
                                    >
                                        {t(`categorisation.fixArticlesButton`)}
                                    </Button>
                                )}
                            >
                                {t(`categorisation.hasArticleMissingData`)}
                            </Alert>
                        </div>
                    </Grow>
                )}

                {!hasCategory ? (
                    <>
                        {details?.suggestedMccKey ? (
                            <Grow in={true}>
                                <div>
                                    <SuggestedCategories
                                        setIsShowAllCategories={setIsShowAllCategories}
                                        suggestedMccKey={details.suggestedMccKey}
                                        onSelectCategory={onSelectCategory}
                                        isEditable={isEditable}
                                    />
                                </div>
                            </Grow>
                        ) : (
                            <Grow in={true}>
                                <div>
                                    <PopularCategories
                                        setIsShowAllCategories={setIsShowAllCategories}
                                        onSelectCategory={onSelectCategory}
                                        mostUsedCategoryList={mostUsedCategoryList}
                                        side={TransactionSide.DEBIT}
                                        isSubscribed={details?.hasActiveSubscription}
                                        isEditable={isEditable}
                                        isRejectedOrCancelled={isRejectedOrCancelled}
                                    />
                                </div>
                            </Grow>
                        )}
                    </>
                ) : (
                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                        alignSelf: 'stretch',
                        flexDirection: isMobileSize ? 'column' : 'row',
                    }}
                    >
                        <Grow
                            style={{
                                width: '100%',
                            }}
                            in={true}
                        >
                            <div>
                                <CategorisationSection
                                    title={hasDifferentCategory
                                        ? <>{t(`categorisation.section.category`)}: <Box sx={{color: 'elephant.main'}} component="span">{differentCategoryCount}</Box></>
                                        : t(`categorisation.section.category`)}
                                    sx={{
                                        flex: '2 0 0',
                                        p: 2,
                                        pt: 1,
                                        justifyContent: 'space-between',
                                        gap: 1.5,
                                    }}
                                    isActive
                                    isFocused={
                                        !isRejectedOrCancelled && (
                                            !categorizationInfo?.screen
                                        || categorizationInfo?.screen
                                            === TRANSACTION_CATEGORIZATION_SCREENS.CATEGORY
                                        )
                                    }
                                    status={undefined}
                                >
                                    {hasDifferentCategory ? (
                                        <Box
                                            sx={selectedCategoryStyles}
                                            onClick={handleOpenAllCategories}
                                        >
                                            <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                                                <CategoryIcon />
                                                {t('categorisation.articles.multipleCategories')}
                                            </Box>
                                            <EditIcon />
                                        </Box>
                                    ) : (
                                        <SelectedCategory
                                            mccKey={details?.articles[0]?.mccKey}
                                            onOpenCategories={handleOpenAllCategories}
                                            isEditable={isEditable}
                                        />
                                    )}
                                </CategorisationSection>
                            </div>
                        </Grow>

                        {isMultiArticleAvailable && !isRejectedOrCancelled && (
                            <Grow in={true}>
                                <div>
                                    <CategorisationSection
                                        title={(
                                            <Trans
                                                t={t}
                                                i18nKey="categorisation.section.articles"
                                                components={{
                                                    bold: (
                                                        <Typography
                                                            component="span"
                                                            sx={{
                                                                color: 'text_v2.secondary',
                                                                fontSize: 'inherit',
                                                                fontWeight: 'inherit',
                                                            }}
                                                        />
                                                    ),
                                                }}
                                                values={{
                                                    number: details?.articles?.length,
                                                }}
                                            />
                                        )}
                                        sx={{
                                            flex: '1 0 0',
                                            p: 2,
                                            pt: 1,
                                            justifyContent: 'space-between',
                                            minWidth: '180px',
                                        }}
                                        isActive
                                    >
                                        <Button
                                            disabled={!isEditable}
                                            fullWidth
                                            variant={hasMultipleArticles ? 'contained' : 'outlined'}
                                            color="primary"
                                            size="small"
                                            onClick={handleOpenArticles}
                                            disableElevation
                                            sx={{p: 0.75}}
                                            startIcon={hasMultipleArticles ? <EditIcon /> : <AddCircleIcon />}
                                        >
                                            {hasMultipleArticles ? t('categorisation.edit') : t('categorisation.add')}
                                        </Button>

                                    </CategorisationSection>
                                </div>
                            </Grow>
                        )}
                    </Box>
                )}

                {hasSubscriptionSupport && (
                    <Grow in={true}>
                        <div>
                            <CategorisationSection
                                isFocused={false}
                                isActive={hasSubscriptionSupport && !isRejectedOrCancelled}
                                title=""
                                sx={{p: 2, pt: 1}}
                            >
                                <SubscriptionPanel
                                    details={details}
                                    isOpen={isSubscriptionOpened}
                                    setIsOpen={setIsSubscriptionOpened}
                                    hasFilesCompleted={hasDocuments
                                        || !isDocumentRequired
                                        || details?.ignoreProofDocument
                                        || details?.refuseToShareProofDocuments
                                    }
                                />
                            </CategorisationSection>
                        </div>
                    </Grow>
                )}

                <Grow in={true}>
                    <div>
                        <CategorisationSection
                            isFocused={
                                categorizationInfo?.screen === TRANSACTION_CATEGORIZATION_SCREENS.DOCUMENT
                                && hasCategory && !isRejectedOrCancelled
                            }
                            isActive={hasCategory && !(isRejectedOrCancelled && !hasCategory)}
                            title={t(`categorisation.section.proof`)}
                            status={fileSectionStatus}
                            sx={isRejectedOrCancelled && !hasCategory ? {} : {p: 2, pt: 1}}
                        >
                            <TransactionFileSection
                                details={details}
                                isEditable={isEditable}
                                isRejectedOrCancelled={isRejectedOrCancelled}
                                saveTransaction={saveTransaction}
                            />
                        </CategorisationSection>
                    </div>
                </Grow>

                <Grow in={true}>
                    <div>
                        <CategorisationSection
                            isActive={
                                hasCategory
                                    && (
                                        hasDocuments
                                        || details?.ignoreProofDocument
                                        || !isDocumentRequired
                                        || details?.refuseToShareProofDocuments
                                    )
                            }
                            isFocused={
                                categorizationInfo?.screen === TRANSACTION_CATEGORIZATION_SCREENS.TVA
                            }
                            title={t(`categorisation.section.VAT`)}
                            status={tvaSectionStatus}
                            sx={isRejectedOrCancelled && !hasCategory ? {} : {p: 2, pt: 1}}
                        >
                            {ocrEnabledFlag
                                && vatValidationStatus === VAT_VALIDATION_STATUS.pending
                                && vatMatchStatus === VAT_MATCH_STATUS.match && (
                                <Alert
                                    severity="info"
                                    icon={<AutoFixHighIcon />}
                                    action={(
                                        <Button
                                            endIcon={<ArrowForwardIcon />}
                                            onClick={() => onViewValidatedVAT()}
                                            sx={{
                                                color: 'v2.light.info.main',
                                                fontSize: theme => theme.typography.pxToRem(13),
                                                letterSpacing: '0.46px',
                                                fontWeight: 500,
                                                py: 0.6,
                                            }}
                                        >
                                            {t('categorisation.vatValidation.viewVATValidationResult')}
                                        </Button>
                                        )}
                                >
                                    {t('categorisation.vatValidation.validatedByAI')}
                                </Alert>
                            )}
                            {(ocrEnabledFlag
                                && (vatValidationStatus === VAT_VALIDATION_STATUS.validated
                                || vatValidationStatus === VAT_VALIDATION_STATUS.corrected))
                                && (
                                    <Alert
                                        severity="success"
                                        icon={<CheckCircleOutline />}
                                        sx={{
                                            '.MuiSvgIcon-root': {
                                                color: 'v2.light.success.main',
                                            },
                                        }}
                                    >
                                        {t('categorisation.vatValidation.vatConfirmed')}
                                    </Alert>
                                )}
                            {ocrEnabledFlag
                                && vatValidationStatus === VAT_VALIDATION_STATUS.pending
                                && vatMatchStatus === VAT_MATCH_STATUS.mismatch
                                && (
                                    <Alert
                                        severity="warning"
                                        sx={{
                                            '.MuiSvgIcon-root': {
                                                color: 'v2.light.warning.shades160',
                                            },
                                        }}
                                        action={(
                                            <Button
                                                endIcon={<ArrowForwardIcon />}
                                                onClick={() => onViewValidatedVAT()}
                                                sx={{
                                                    color: 'v2.light.warning.shades160',
                                                    fontSize: theme => theme.typography.pxToRem(13),
                                                    letterSpacing: '0.46px',
                                                    fontWeight: 500,
                                                    py: 0.6,
                                                }}
                                            >
                                                {t('categorisation.vatValidation.viewVATValidationResult')}
                                            </Button>
                                        )}
                                    >
                                        <AlertTitle sx={{fontWeight: 700}}>
                                            {t('categorisation.vatValidation.validatedByAIErrorTitle')}
                                        </AlertTitle>
                                        {t('categorisation.vatValidation.validatedByAIErrorMessage', {
                                            vatPercent: t(`vat.${mccKeyCategoryMap[details?.articles[0]?.mccKey].tva}`),
                                            vatAmount: categoryDefaultVATAmounts[0],
                                        })}
                                    </Alert>
                                )}
                            <TransactionTva
                                details={details}
                                isEditable={isVATEditable}
                                saveTransaction={saveTransaction}
                                handleOpenArticles={handleOpenArticles}
                            />
                        </CategorisationSection>
                    </div>
                </Grow>
            </Box>

            <CategorisationFooter
                categorized={totalCategorizedInSession}
                nonCategorized={totalOverview?.totalUncategorizedTransactions}
                defaultNumberOfUncategorized={defaultNumberOfUncategorized}
                details={details}
            />

            <DeactivateSubscriptionModal />
            <ConfirmProofOfDocumentsModal />
        </>
    );
};

DebitCategorizationPage.propTypes = {
    isShowAllCategories: PropTypes.bool.isRequired,
    setIsShowAllCategories: PropTypes.func.isRequired,
    isArticleViewOpen: PropTypes.bool.isRequired,
    setIsArticleViewOpen: PropTypes.func.isRequired,
    isSubscriptionOpened: PropTypes.bool.isRequired,
    setIsSubscriptionOpened: PropTypes.func.isRequired,
    defaultNumberOfUncategorized: PropTypes.number.isRequired,
    isValidatingVATOpen: PropTypes.bool.isRequired,
    onViewValidatedVAT: PropTypes.func.isRequired,
    onCompleteVATValidation: PropTypes.func.isRequired,
};

export default DebitCategorizationPage;
