import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import ReactRouterLink from '../../../components/app/router/ReactRouterLink';
import {resolveRoute} from '../../../lib/router/resolveRoute';
import {RoutePaths} from '../../../lib/router/route-paths';
import {LocalesConstants} from '../../../utils/locales-constants';
import {DEPOSIT_OF_ACCOUNTS_STATUS} from '../../company-profile/modules/formalities/utils/constants';
import {FINANCIAL_STATEMENTS_QUERY_PARAMS} from '../utils/consts';

const DetailsLink = ({rowData}) => {
    const {t} = useTranslation(LocalesConstants.FinancialStatements);

    const [searchParams] = useSearchParams();
    const year = searchParams.get(FINANCIAL_STATEMENTS_QUERY_PARAMS.YEAR);

    if (!rowData?.company.id || !rowData?.freelancer?.id) {
        return null;
    }

    const freelancerId = rowData.freelancer.id;
    const companyId = rowData.company.id;

    const detailsLink = resolveRoute(RoutePaths.COMPANY_DEPOSIT_OF_ACCOUNTS, {freelancerId, companyId});
    let linkWithYear = detailsLink;

    if (rowData.annualAccountingStatus !== DEPOSIT_OF_ACCOUNTS_STATUS.MISSING) {
        linkWithYear = linkWithYear.concat(`?${FINANCIAL_STATEMENTS_QUERY_PARAMS.ANNUAL_ACCOUNT_ID}=${rowData.id}`);
        linkWithYear = linkWithYear.concat(`&${FINANCIAL_STATEMENTS_QUERY_PARAMS.YEAR}=${year}&${FINANCIAL_STATEMENTS_QUERY_PARAMS.BACK_TO_TABLE}`);
    } else {
        linkWithYear = linkWithYear.concat(`?${FINANCIAL_STATEMENTS_QUERY_PARAMS.YEAR}=${year}&${FINANCIAL_STATEMENTS_QUERY_PARAMS.BACK_TO_TABLE}`);
    }

    if (rowData?.isDisabled) {
        return (
            <Button
                variant="outlined"
                sx={{py: 0.75}}
                endIcon={<ArrowForwardIcon />}
                disabled={true}
            >
                {t('view')}
            </Button>
        );
    }

    return (
        <ReactRouterLink
            to={linkWithYear}
            sx={{'&:hover': {textDecoration: 'none'}}}
            onClick={e => e.stopPropagation()}
        >
            <Button
                variant="outlined"
                sx={{py: 0.75}}
                endIcon={<ArrowForwardIcon />}
            >
                {t('view')}
            </Button>
        </ReactRouterLink>
    );
};

DetailsLink.propTypes = {
    rowData: PropTypes.object.isRequired,
};

export default DetailsLink;
