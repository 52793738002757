import {Auth} from 'aws-amplify';
import {applyMiddleware, compose, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import axiosInstance from './app/api/axios';
import {AccessDeniedError, MaintenanceError, UnauthorizedError} from './app/errors';
import {setIsMaintenanceModeActive} from './app/store/app.actions';
import {CONFIG} from './config';
import {hiwayApiInstance} from './lib/hiway-api-instance';
import {monitorReducerEnhancer} from './lib/redux/enhancers/monitor';
import {loggerMiddleware} from './lib/redux/middlewares/logger';
import {createRouterMiddleware, push} from './lib/router/connected-router-saga';
import {history} from './lib/router/history';
import {RoutePaths} from './lib/router/route-paths';
import {createRootReducer} from './reducer';
import {rootSaga} from './saga';

let composeFn = compose;

if (CONFIG.NODE_ENVIRONMENT !== 'production') {
    // eslint-disable-next-line no-underscore-dangle
    const {__REDUX_DEVTOOLS_EXTENSION_COMPOSE__} = window;

    if (typeof window === 'object' && __REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
        // eslint-disable-next-line no-underscore-dangle
        composeFn = __REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    }
}

const sagaMiddleware = createSagaMiddleware({
    onError(error, errorInfo) {
        // eslint-disable-next-line no-console
        console.error({error, errorInfo});
    },
});
const routerMiddleware = createRouterMiddleware(history);
const rootReducer = createRootReducer(history);
const middlewareEnhancer = applyMiddleware(routerMiddleware, loggerMiddleware, sagaMiddleware);
const composedEnhancers = composeFn(middlewareEnhancer, monitorReducerEnhancer);

/**
 * Resets all reducers to initial state after the logout, except the router state.
 */
const wrappedRootReducer = (state, action) => {
    if (action.type === 'LOGOUT_SUCCESS') {
        state = {
            router: rootReducer(state, action).router,
            loading: rootReducer(state, action).loading,
        };
    }

    return rootReducer(state, action);
};

/**
 * Setup error interceptors and show corresponding errors
 */
const setupAxiosInterceptors = () => {
    axiosInstance.interceptors.response.use(null, error => {
        if (error?.response?.status === 401) {
            Auth.signOut().then(() => {
                store.dispatch(push('/login'));

                store.dispatch({
                    type: 'LOGOUT_SUCCESS',
                });
            });

            throw new UnauthorizedError(error?.response?.data?.message || error?.message || undefined);
        } else if (error?.response?.status === 403) {
            if (window.location.pathname !== RoutePaths.ACCESS_DENIED) {
                store.dispatch(push(RoutePaths.ACCESS_DENIED));
            }
            throw new AccessDeniedError(error?.response?.data?.message || error?.message || undefined);
        } else if (error?.response?.data?.error_type === 'MAINTENANCE_MODE' && error?.response?.status === 503) {
            store.dispatch(setIsMaintenanceModeActive(true));

            throw new MaintenanceError(error?.response?.data?.message || error?.message || undefined);
        }

        throw error;
    });

    hiwayApiInstance.interceptors.response.use(null, error => {
        if (error?.response?.status === 401) {
            Auth.signOut().then(() => {
                store.dispatch(push('/login'));

                store.dispatch({
                    type: 'LOGOUT_SUCCESS',
                });
            });

            throw new UnauthorizedError(error?.response?.data?.message || error?.message || undefined);
        } else if (error?.response?.status === 403) {
            store.dispatch(push('/access-denied'));

            throw new AccessDeniedError(error?.response?.data?.message || error?.message || undefined);
        } else if (error?.response?.data?.error_type === 'MAINTENANCE_MODE' && error?.response?.status === 503) {
            store.dispatch(setIsMaintenanceModeActive(true));

            throw new MaintenanceError(error?.response?.data?.message || error?.message || undefined);
        }

        throw error;
    });
};

export const store = createStore(wrappedRootReducer, undefined, composedEnhancers);

sagaMiddleware.run(rootSaga);

setupAxiosInterceptors();
