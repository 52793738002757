import Block from '@mui/icons-material/Block';
import BorderColor from '@mui/icons-material/BorderColor';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import MailIcon from '@mui/icons-material/Mail';
import NextWeekIcon from '@mui/icons-material/NextWeek';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {LocalesConstants} from '../../../utils/locales-constants';
import {USER_STATUSES} from '../utils/constants';

const UserStatus = ({status, isUserActive}) => {
    const {t} = useTranslation(LocalesConstants.Users);

    const statuses = {
        'COMPANY_PENDING': {
            icon: <NextWeekIcon fontSize="small" style={{color: '#E84C4C'}} />,
            label: t('userStatuses.COMPANY_PENDING'),
        },
        'ACTIVE': {
            icon: <CheckCircleIcon fontSize="small" style={{color: '#2EB63D'}} />,
            label: t('userStatuses.ACTIVE'),
        },
        'CONTRACT_PENDING': {
            icon: <DescriptionIcon fontSize="small" style={{color: '#0288D1'}} />,
            label: t('userStatuses.CONTRACT_PENDING'),
        },
        'CONTRACT_SIGNED': {
            icon: <BorderColor fontSize="small" style={{color: '#0288D1'}} />,
            label: t('userStatuses.CONTRACT_SIGNED'),
        },
        'WORKSHOPS_SCHEDULED': {
            icon: <HourglassTopIcon fontSize="small" style={{color: '#E84C4C'}} />,
            label: t('userStatuses.WORKSHOPS_SCHEDULED'),
        },
        'INVITED': {
            icon: <MailIcon fontSize="small" style={{color: '#ECA02E'}} />,
            label: t('userStatuses.INVITED'),
        },
        'DEACTIVATED': {
            icon: <Block fontSize="small" style={{color: '#607D8B'}} />,
            label: t('userStatuses.DEACTIVATED'),
        },
    };

    return (
        <Box alignItems="center" display="flex" style={{columnGap: 10}}>
            {isUserActive ? statuses[status]?.icon : statuses['DEACTIVATED']?.icon}
            {isUserActive ? statuses[status]?.label : statuses['DEACTIVATED']?.label}
        </Box>
    );
};

UserStatus.propTypes = {
    isUserActive: PropTypes.bool.isRequired,
    status: PropTypes.oneOf(Object.values(USER_STATUSES)).isRequired,
};

export default UserStatus;
