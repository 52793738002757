/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable react/jsx-child-element-spacing */
// TERM_ID : b04326a2-2545-485d-8bf0-ec583cffd04f, VERSION_CODE: 1
import {Card, CardContent, Container, Typography} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import {ReactComponent as HiwayText} from '../../../../assets/svg/logo/hiway.svg';
import {UserRoleColors} from '../../../../utils/user-roles';

const LegalPortageCGVS = ({isHeaderShown}) => {
    const styles = {}; // useLegalPageCGVSStyles(); // TODO Can't find in

    return (
        <div className={styles.wrapper}>
            <Container className={styles.container}>
                {isHeaderShown && (
                <header className={styles.header}>
                    <div className={styles.logoContainer}>
                        <HiwayText fill={UserRoleColors.PORTAGE} />
                    </div>
                    <h2 className={styles.subTitle}>
                        Conditions générales de vente et de service
                    </h2>
                </header>
                )}

                <Card>
                    <CardContent>
                        <Typography className={styles.paragraph}>
                            La société HIWAY est une SAS au capital de 9 446,85€, immatriculée au Registre du Commerce et
                            des Sociétés de Paris sous le n° 801 666 074, dont le siège social est situé au 14 rue
                            Bausset, 75015 Paris, représentée par Monsieur Alexandre FRANCHI, en sa qualité de
                            Président, dûment habilité aux fins des présentes, ci-après dénommée le « <b>Prestataire</b>
                            {' '}» ou « <b>HIWAY</b> » ; elle a pour activité principale le conseil en accompagnement et en
                            gestion auprès d’entreprises, afin de les assister dans la création et le développement de
                            leur activité en Libéral.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Elle dispose d’une solide expertise dans ce domaine et des qualifications requises pour
                            répondre
                            aux demandes de ces entrepreneurs.

                        </Typography>
                        <Typography className={styles.paragraph}>HIWAY a développé un site internet accessible à
                            l’adresse
                            URL suivante :
                            <a
                                className={styles.link}
                                href="https://hiway.fr/"
                            >https://hiway.fr/
                            </a> ayant vocation à présenter
                            son
                            savoir-faire et ses services
                            d’accompagnement (ci-après les <strong>« Services »</strong>). Au titre de ces Services,
                            HIWAY a
                            créé une
                            plateforme innovante accessible sur abonnement permettant aux entreprises de suivre toutes
                            les
                            étapes de création et de gestion comptable, administrative, sociale, fiscale, financière et
                            commerciale de leur activité (ci-après la <strong>« Plateforme »</strong>).

                        </Typography>
                        <Typography className={styles.paragraph}>
                            Dans le cadre du lancement et du développement de son activité, le Libéral a souhaité
                            faire
                            appel aux compétences et au réseau relationnel spécifique de HIWAY, afin de bénéficier de
                            ses
                            Services.
                        </Typography>

                        <Typography className={styles.paragraph}>
                            C’est dans ce contexte que les Parties se sont rapprochées, et ont décidé d’arrêter et de
                            formaliser aux termes des présentes Conditions Générales de Vente et de Service (ci-après
                            les <strong>« CGVS »</strong>), les conditions et modalités notamment opérationnelles
                            et financières de leurs accords.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Les présentes CGVS constituent le socle de la relation commerciale entre les Parties,
                            conformément à l’article L. 441-1 du Code de commerce, aux fins de mise à disposition des
                            Services.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Il est d’ores et déjà précisé que les relations particulières entre HIWAY et le Libéral,
                            consignant les modalités notamment financières et de durée, ainsi que les caractéristiques
                            des Services commandés, feront l’objet d’un Abonnement valant conditions particulières,
                            par acte séparé.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Il est par ailleurs indiqué que dès la signature des présentes CGVS ainsi que de
                            l’Abonnement,
                            HIWAY proposera un accompagnement transitoire vers le statut cible du Libéral, jusqu’à la
                            date
                            de son immatriculation.
                        </Typography>

                        <Typography className={styles.paragraph}>
                            L’accès à la Plateforme ne sera donc possible, pendant cette période transitoire, qu’aux
                            seules fins de signature des présentes CGVS et de l’Abonnement, et à la réception de
                            propositions de missions avant toute démarche de constitution et d’immatriculation
                            d’entreprise.
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Autrement dit, les présentes CGVS ainsi que l’Abonnement, à l’exception de certaines
                            dispositions qui seront précisées ci-après, commenceront à s’appliquer au Libéral à
                            compter de
                            la date d’immatriculation de son entreprise.
                        </Typography>

                        <Typography className={styles.heading}>
                            <span className={styles.redText}>ARTICLE&nbsp;0&nbsp;:</span> DEFINITIONS
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Aux termes des présentes Conditions Générales de Vente et de Service, les termes commençant
                            par
                            une majuscule auront la signification suivante :
                        </Typography>
                        <Typography className={styles.paragraph}>
                            <strong>« Abonnement »</strong> : désigne la formule d’abonnement proposée par HIWAY aux
                            termes
                            de l’Abonnement
                            et décrite à l’article 5 des présentes, permettant au Libéral de bénéficier des Services.
                            Il
                            est précisé que ce terme désigne, ensemble, le paiement d’une somme forfaitaire et d’un
                            abonnement mensuel sans limitation de durée dans les conditions de l’article 5.3. Il est
                            accepté
                            par le Libéral, par voie électronique via la Plateforme Hiway;
                        </Typography>
                        <Typography className={styles.paragraph}>
                            <strong>« Commande »</strong> : désigne la souscription par le Libéral à un Abonnement aux
                            Services dans les conditions prévues par les présentes CGVS ;
                        </Typography>
                        <Typography className={styles.paragraph}>
                            <strong>« Compte personnel »</strong> : désigne l’espace accessible depuis la le Site
                            Internet que tout Libéral ayant souscrit à un Abonnement aux Services a consenti à créer
                            pour accéder à la Plateforme et plus généralement aux Services, comportant un identifiant
                            et un mot de passe personnels au Libéral ;

                        </Typography>
                        <Typography className={styles.paragraph}>
                            <strong>« Données Personnelles »</strong> ou
                            <strong>« Données à caractère personnel »
                            </strong>:
                            désigne toute information se rapportant à une personne physique identifiée ou identifiable ;
                            est réputée être une « personne physique identifiable » une « personne physique qui peut
                            être identifiée, directement ou indirectement, notamment par référence à un identifiant,
                            tel qu’un nom, un numéro d'identification, des données de localisation, un identifiant en
                            ligne, ou à un ou plusieurs éléments spécifiques propres à son identité physique,
                            physiologique, génétique, psychique, économique, culturelle ou sociale » conformément à la
                            définition du Règlement (UE) n°2016/679 du 27 avril 2016 (« RGPD ») ;
                        </Typography>
                        <Typography className={styles.paragraph}>
                            <strong>« Libéral »</strong> : désigne le contractant des présentes CGVS ayant procédé à
                            la
                            souscription de
                            l’Abonnement aux Services, disposant d’un Compte personnel sur la plateforme HIWAY et
                            accessible
                            via le lien suivant :&nbsp;
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://portal.hiway.fr"
                            >https://portal.hiway.fr
                            </a>
                            ;
                        </Typography>
                        <Typography className={styles.paragraph}>
                            <strong>« Plateforme »</strong> : désigne la plateforme éditée par HIWAY et développée par
                            la
                            société THE DIGITAL FACTORY (« TDF »), elle-même représentée par la société FEELOË EUROPE
                            BVBA -
                            Louiselaan 391, Box 4 Company number: 0861.238.749 1050 Bruxelles, BELGIQUE, dont les
                            données
                            sont hébergées par la société AMAZON WEB SERVICES EMEA (« AWS »), société à responsabilité
                            limitée, 38 avenue john f. kennedy, l-1855 Luxembourg.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Cette Plateforme est un espace dématérialisé ayant vocation à collecter différentes données
                            d’identification du Libéral, dont des Données Personnelles, afin de lui permettre de
                            bénéficier des Services d’accompagnement visés à l’article 2 des CGVS et de le mettre en
                            relation avec les partenaires de HIWAY pour l’assister dans sa gestion comptable,
                            administrative, sociale, fiscale et commerciale.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            La Plateforme est accessible dans les conditions des présentes depuis le site
                            Internet <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://portal.hiway.fr"
                        >https://portal.hiway.fr
                        </a> et via son Compte personnel.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            <strong>« Responsable de Traitement »</strong> : désigne la personne physique ou morale,
                            l’autorité publique, le service ou tout autre organisme qui, seul ou conjointement avec
                            d’autres, détermine les finalités et les moyens du traitement des Données à caractère
                            personnel.
                            Il s’agit ici de la société HIWAY s’agissant des Données Personnelles collectées depuis la
                            Plateforme. Le Libéral aura également le rôle de Responsable de Traitement s’agissant des
                            Données Personnelles susceptibles de figurer sur les contrats commerciaux et factures passés
                            avec ses clients et transmis via la Plateforme aux fins d’exécution des Services/de
                            l’Abonnement
                            par HIWAY (cf. article 7 des CGVS) ;

                        </Typography>
                        <Typography className={styles.paragraph}>
                            <strong>« Services »</strong> : services d’accompagnement fournis par HIWAY au Libéral
                            dans le
                            cadre de toute souscription à un Abonnement par le Libéral, dont le descriptif figure à
                            l’article 2 des présentes ;
                        </Typography>
                        <Typography className={styles.paragraph}>
                            <strong>« Site Internet »</strong> : désigne le site internet édité par HIWAY et consultable
                            à l’adresse suivante : <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://portal.hiway.fr"
                        >https://portal.hiway.fr
                        </a> depuis lequel le Libéral peut se connecter à
                            la Plateforme) via un Compte personnel ;
                        </Typography>
                        <Typography className={styles.paragraph}>
                            <strong>« Sous-Traitant »</strong> : désigne au sens du RGPD, la personne physique ou morale
                            (entreprise ou organisme public) qui traite des Données Personnelles pour le compte d’un
                            autre organisme (le Responsable de traitement), dans le cadre d’un service ou d’une
                            prestation. Il s’agit ici de la société TDF (qui a développé la Plateforme) de la
                            société AWS (hébergeur de la Plateforme), de Hubspot (qui est l’outil CRM choisi par HIWAY),
                            Google Drive (outil de stockage) et de la société HIWAY COMPTA.

                        </Typography>
                        <Typography className={styles.paragraph}>
                            HIWAY peut également avoir la qualité de Sous-Traitant dans le cadre des Données
                            Personnelles susceptibles de figurer sur les contrats commerciaux et factures passés par le
                            Libéral avec ses clients et transmis via la Plateforme aux fins d’exécution des Services/de
                            l’Abonnement par HIWAY (cf. article 7 des CGVS).
                        </Typography>

                        <Typography className={styles.heading}>
                            <span className={styles.redText}>ARTICLE 1 :</span> OBJET DES CONDITIONS GENERALES DE VENTE
                            ET
                            DE SERVICE
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Les présentes CGVS ont pour objet de déterminer les conditions de mise à disposition par le
                            Prestataire au Libéral des Services dont le descriptif figure à l’article 2 ci-après, dans
                            le
                            cadre de la souscription du Libéral à un Abonnement dont les modalités et caractéristiques
                            seront décrites à l’article 5 des présentes.
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Dans ce cadre, les présentes CGVS ont notamment pour objet de fixer les modalités
                            d’exploitation de la Plateforme tels que proposés au Libéral dans le cadre des Services,
                            et de déterminer la politique de confidentialité afférente aux Données Personnelles qui
                            seront traitées par le Prestataire et le Libéral sur ces supports, dans les conditions de
                            l’article 7.

                        </Typography>

                        <Typography className={styles.paragraph}>
                            En cas de contradiction entre les CGVS et l’Abonnement, les dispositions de ce dernier
                            prévalent.
                        </Typography>

                        <Typography className={styles.heading}>
                            <span className={styles.redText}>ARTICLE 2</span> : DESCRIPTIF DES SERVICES
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Dès la signature par le Libéral des présentes CGVS ainsi que de l’Abonnement
                            correspondant, et dès l’acceptation d’une mission, HIWAY mettra à sa disposition les
                            Services d’accompagnement listés à au point (i) ci-après.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Les Services visés aux points (ii) à (vi) ci-après ne seront accessibles au Libéral qu’à
                            compter de la date d’immatriculation de sa société.
                        </Typography>
                        <div className={styles.paddedText}>
                            <Typography className={styles.subItemHeader}>
                                <span className={styles.listItemBullet}>(i)</span> Création ou transformation de
                                structure :
                            </Typography>
                            <ul className={styles.bulletList}>
                                <li className={styles.customBullet}>
                                    Accompagnement durant la transition entre le statut actuel et le statut cible du
                                    Libéral via le système du Guichet Unique de l’Institut National de la Propriété
                                    Industrielle (INPI) accessible directement depuis la Plateforme. La demande
                                    d’immatriculation se fera sur le portail e-procédures de l’INPI:&nbsp;
                                    <a href="https://procedures.inpi.fr/?/" target="_blank" rel="noreferrer">
                                        https://procedures.inpi.fr/?/
                                    </a>
                                </li>
                                <li className={styles.customBullet}>Conseil en création d’entreprise
                                </li>
                                <li className={styles.customBullet}>Réalisation des démarches de création ou de
                                    transformation de structure.
                                </li>
                            </ul>

                            <Typography className={styles.subItemHeader}>
                                <span className={styles.listItemBullet}>(ii)</span> Mise en place d’un service de
                                gestion
                                comptable et d’établissement du bilan comptable :
                            </Typography>
                            <ul className={styles.bulletList}>
                                <li className={styles.customBullet}>
                                    Démarches d’adhésion auprès d’OGI France : Organisme de gestion agréé
                                </li>
                                <li className={styles.customBullet}>
                                    Réalisation des formalités associées, par le cabinet d’expertise-comptable filiale
                                    de HIWAY, intervenant via la société HIWAY COMPTA. Il est toutefois précisé que le
                                    Libéral sera accompagné sur la production de son bilan comptable annuel, ces
                                    prestations devront faire l’objet d’une acceptation en sus dans les termes  d’une
                                    lettre de mission de la société HIWAY COMPTA et d’une facturation complémentaire
                                    d’un montant forfaitaire de 500 € HT par exercice comptable (cf. article 5.3).
                                </li>
                            </ul>

                            <Typography className={styles.subItemHeader}>
                                <span className={styles.listItemBullet}>(iii)</span> Accès aux services de la société
                                « Swan » via la Plateforme  :
                            </Typography>

                            <Typography
                                className={styles.paragraph}
                                sx={{
                                    marginTop: '20px',
                                }}
                            >
                                Swan est un établissement de monnaie électronique agréé par l'Autorité de contrôle
                                prudentiel et de résolution (ACPR), sous le code bancaire (CIB) 17328, et habilité à
                                fournir des services de paiement de droit français.
                            </Typography>

                            <Typography className={styles.paragraph}>
                                HIWAY a intégré à sa Plateforme les fonctionnalités d’un établissement de monnaie
                                électronique régulé par l’ACPR et proposées par Swan, permettant au Libéral de
                                disposer d’un compte professionnel et d’effectuer des mouvements depuis ce compte,
                                sur la Plateforme. Il est précisé qu’aucun découvert bancaire n’est autorisé.
                            </Typography>

                            <Typography className={styles.paragraph}>
                                HIWAY reste un tiers à la relation contractuelle directe entre Swan et le Libéral,
                                titulaire d'un compte de paiement et utilisateur de services de paiement. Ces relations
                                seront régies exclusivement par les conditions générales de Swan que le Libéral a
                                préalablement acceptées et qui sont accessibles depuis le lien suivant<a href="https://www.swan.io/terms-of-use" target="_blank" rel="noreferrer">
                                https://www.swan.io/terms-of-use
                            </a>.
                            </Typography>

                            <Typography className={styles.paragraph}>
                                Swan reste à ce titre responsable de la fourniture et de la maintenance des
                                fonctionnalités accessibles depuis la Plateforme, et du bon déroulement de toutes les
                                opérations de paiement qui seront effectuées sur le compte de paiement par le Libéral.
                            </Typography>

                            <Typography className={styles.paragraph}>
                                Les données personnelles collectées par Swan du fait de l’utilisation des
                                fonctionnalités depuis la Plateforme font l'objet d'un traitement, dont Swan est le
                                responsable, conformément à la réglementation relative à la protection des données
                                personnelles, sa politique de gestion pouvant être consultée depuis le lien
                                suivant :&nbsp;
                                <a href="https://fr.swan.io/privacy-policy" target="_blank" rel="noreferrer">
                                    https://fr.swan.io/privacy-policy
                                </a>.

                            </Typography>

                            <Typography className={styles.subItemHeader}>
                                <span className={styles.listItemBullet}>(iv)</span> Services d’accompagnement à la
                                gestion :
                            </Typography>
                            <ul className={styles.bulletList}>
                                <li className={styles.customBullet}>Accès à la Plateforme et à l’environnement de
                                    gestion dédié
                                </li>
                                <li className={styles.customBullet}>Facturation, suivi des règlements,
                                </li>
                                <li className={styles.customBullet}>Administration des charges, notes de frais et
                                    indemnités
                                    kilométriques
                                </li>
                                <li className={styles.customBullet}>Mise à disposition d’un outil de gestion  financière
                                </li>
                                <li className={styles.customBullet}>Accompagnement sur la mise en place de dispositifs
                                    financiers et patrimoniaux
                                </li>
                                <li className={styles.customBullet}>Suivi de la bonne tenue de la comptabilité en lien
                                    avec la société HIWAY COMPTA
                                </li>
                                <li className={styles.customBullet}>Préparation des documents nécessaires à l’édition
                                    du bilan réalisé par l’expert-comptable partenaire d’HIWAY, HIWAY COMPTA
                                </li>
                                <li className={styles.customBullet}>Domiciliation de l’entreprise du Libéral (optionnel)
                                </li>
                                <li className={styles.customBullet}>Réception, traitement et classement du courrier.
                                </li>
                            </ul>

                            <Typography className={clsx([styles.subItemHeader, styles.paragraph])}>
                                <span className={styles.listItemBullet}>(v)</span> Mise en relation avec différents
                                partenaires de HIWAY : <span className={styles.paragraphNormalText}>Assureur, Banque,
                                Gestionnaire de patrimoine,
                                Professionnels Immobiliers, Sociétés de conseil en investissement, Organismes de
                                formations.</span>
                            </Typography>

                            <Typography className={clsx([styles.subItemHeader, styles.paragraph])}>
                                <span className={styles.listItemBullet}>(vI)</span> Accès en tant que membre abonné aux
                                Services, aux évènements et à la communauté
                                HIWAY <span className={styles.paragraphNormalText}> (constituée de l’ensemble des
                                libéraux abonnés à la Plateforme/aux Services) types webinar, meetups, soirées,
                                participation à des conférences (sur invitation), mais également à l’outil « Crisp »:
                                le Libéral pourra transmettre toute demande à HIWAY directement via cet outil intégré à
                                la Plateforme. </span>
                            </Typography>
                            <Typography className={styles.paragraph}>Les échanges avec la communauté HIWAY pourront
                                également intervenir via la messagerie « Slack ».</Typography>

                            <Typography className={styles.paragraph}>
                                (constituée de l’ensemble des libéraux abonnés à la Plateforme/aux Services) types
                                webinar, meetups, soirées, participation à des conférences (sur invitation),
                                mais également à l’outil « Crisp »: le Libéral pourra transmettre toute demande à
                                HIWAY directement via cet outil intégré à la Plateforme.
                            </Typography>

                            <Typography className={styles.paragraph}>
                                Les échanges avec la communauté HIWAY pourront également intervenir via la messagerie
                                « Slack ».
                            </Typography>

                            <Typography className={clsx([styles.subItemHeader, styles.paragraph])}>
                                <span className={styles.listItemBullet}>(vi)</span> Support permanent et de toutes
                                natures
                                pour toutes questions en lien avec l’activité du Libéral
                            </Typography>
                        </div>

                        <Typography className={styles.heading}>
                            <span className={styles.redText}>ARTICLE 3</span> : CONDITIONS D’ACCES ET D’UTILISATION DE
                            LA
                            PLATEFORME
                        </Typography>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>3.1</span> : Généralités
                        </Typography>

                        <Typography className={styles.paragraph}>
                            De manière générale, afin de pouvoir utiliser la Plateforme, le Libéral doit disposer d’un
                            navigateur internet et d’une connexion au réseau internet. Les coûts de toutes
                            communications
                            rendant accessible la connexion au réseau internet restent à la charge exclusive du
                            Libéral.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Pour pouvoir utiliser plus spécifiquement la Plateforme, le Libéral doit avoir
                            préalablement
                            souscrit à l’Abonnement correspondant (dans les conditions de l’article 5 des CGVS), puis
                            doit
                            posséder un accès mobile, dont les éventuels coûts de connexion restent à sa charge.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            La Plateforme est accessible 24h/24 et 7j/7, sauf en cas de force majeure ou d’évènement
                            hors du
                            contrôle de HIWAY.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            HIWAY se réserve la possibilité d’interrompre l’accès à la Plateforme pendant toute la
                            durée de l’Abonnement, sans en informer préalablement le Libéral, pour des raisons de
                            maintenance technique ou pour toute autre raison de nature technique (actualisation,
                            restriction d’accès au réseau etc.) ou de sécurité (notamment en cas de faille de
                            sécurité), sans que le Libéral ne puisse réclamer aucune indemnité ni mettre en jeu la
                            responsabilité d’HIWAY pour quelque raison que ce soit.
                        </Typography>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>3.2</span> Accès spécifique à la Plateforme
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Un premier accès à la Plateforme sera octroyé au Libéral aux seules fins de signature des
                            présentes CGVS et de l’Abonnement ainsi qu’à la constitution et au suivi du dossier
                            d’immatriculation.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            L’accès complet à la Plateforme ne sera possible qu’à compter de l’immatriculation de
                            l’entreprise du Libéral, laquelle constitue le point de départ de la souscription de
                            l’Abonnement.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Le Libéral disposera d’un Compte personnel dès la signature des CGVS et de l’Abonnement, à
                            partir d’une adresse email et d’un mot de passe personnels (ci-après les
                            <strong>« Identifiants de Connexion »</strong>).
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Afin d’accéder pleinement à la Plateforme, le Libéral devra donc obligatoirement :
                        </Typography>
                        <Typography className={styles.paragraph}>
                            <span className={styles.listItemBullet}>(i)</span>se rendre sur le Site Internet accessible
                            à
                            l’adresse&nbsp;
                            <a
                                rel="noreferrer noopener"
                                target="_blank"
                                href="https://portal.hiway.fr/"
                            >https://portal.hiway.fr/
                            </a> ;
                        </Typography>
                        <Typography className={styles.paragraph}>
                            <span className={styles.listItemBullet}>(ii)</span>renseigner son adresse email
                            (identifiant) et
                            son mot de passe.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Le Compte personnel du Libéral sera ainsi lié à la Plateforme et permettra à HIWAY de
                            suivre
                            son activité et de mettre à sa disposition les différents Services.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Les Identifiants de Connexion sont nécessaires au Libéral pour se connecter sur son
                            Compte personnel et sont susceptibles d'être modifiés par ce dernier, à tout moment,
                            au sein de son Compte. Ils sont strictement personnels et ne peuvent, sous peine de voir
                            le Compte suspendu ou supprimé par HIWAY, être communiqués ou exploités par un tiers.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Le Libéral est intégralement responsable de l’utilisation et de la conservation de ses
                            Identifiants de Connexion qu’il devra conserver comme confidentiels, et devra informer sans
                            délai HIWAY s’il constate une faille de sécurité liée à la communication ou au détournement
                            de
                            ceux-ci.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            En cas de perte ou de vol par un tiers de l’un de ses Identifiants de Connexion, le
                            Libéral
                            s’engage à en informer HIWAY sans délai par courrier électronique à l’adresse suivante:
                            digital@hiway.fr.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            HIWAY suspendra alors l’accès au Compte personnel, et le Libéral ne pourra plus récupérer
                            ses
                            Identifiants de Connexion.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Le Libéral peut déconnecter son Compte en cliquant sur « se déconnecter ».
                        </Typography>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>3.3</span>La suppression du Compte personnel
                        </Typography>

                        <Typography className={styles.paragraph}>
                            La création du Compte personnel étant liée à la souscription de l’Abonnement, il est
                            entendu que la résiliation de l’Abonnement (telle que détaillée à l’article 9) entraînera
                            la suppression automatique du Compte du Libéral correspondant, en ce compris, de toutes
                            Données Personnelles collectées lors de l’achat de l’Abonnement, dans les conditions et sous
                            les réserves prévues aux termes de la politique de confidentialité détaillée à l’article 7.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Par ailleurs, tout manquement aux présentes CGVS, tout comportement préjudiciable aux
                            intérêts d’HIWAY, toute falsification des informations transmises à HIWAY et/ou à ses
                            partenaires (dont le cabinet d’expertise comptable partenaire recommandé par HIWAY) et
                            plus généralement, toute violation des dispositions légales et règlementaires par le
                            Libéral, entraînera de plein droit la suppression du Compte personnel dudit Libéral et
                            la résiliation de son Abonnement, de sorte que la Plateforme ne lui sera plus accessible.
                        </Typography>

                        <Typography className={styles.heading}>
                            <span className={styles.redText}>ARTICLE 4</span>: OBLIGATIONS DES PARTIES
                        </Typography>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>4.1</span>Utilisation de la Plateforme
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Le Libéral déclare, garantit et s’engage à n’utiliser la Plateforme qu’à des fins licites,
                            dans les conditions et les limites des présentes, sans porter atteinte aux droits de tiers
                            ou à
                            ceux d’HIWAY.
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Le Libéral s’engage en particulier à :
                        </Typography>

                        <Typography className={styles.paragraph}>
                            <span className={styles.listItemBullet}>(i)</span>respecter en toute circonstance l’image de
                            la
                            Plateforme et d’HIWAY ;
                        </Typography>
                        <Typography className={styles.paragraph}>
                            <span className={styles.listItemBullet}>(ii)</span>respecter les caractéristiques et
                            contraintes, notamment techniques, de la Plateforme ;
                        </Typography>
                        <Typography className={styles.paragraph}>
                            <span className={styles.listItemBullet}>(iii)</span>collaborer avec HIWAY en lui fournissant
                            toutes les informations nécessaires à la bonne exécution de la Plateforme.
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Tout manquement aux stipulations du présent article pourra entrainer de plein droit la
                            suppression du Compte personnel et la résiliation de l’Abonnement.
                        </Typography>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>4.2</span>Obligations spécifiques du Libéral
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Le Libéral s’engage à :
                        </Typography>
                        <Typography className={styles.paragraph}>
                            <span className={styles.listItemBullet}>(i)</span>ne pas faire de tentative d’accès illicite
                            aux
                            réseaux et systèmes connectés à la Plateforme mis en service par HIWAY, ou de tentative de
                            transmission de virus ou de tout autre programme informatique nuisibles à HIWAY ;
                        </Typography>
                        <Typography className={styles.paragraph}>
                            <span className={styles.listItemBullet}>(ii)</span>faire un usage conforme de la Plateforme,
                            et plus généralement des Services et à ne pas porter atteinte à l’image et la réputation
                            d’HIWAY dans le cadre de l’utilisation des Services et notamment de toutes mises en relation
                            avec les partenaires d’HIWAY (dont le cabinet d’expertise comptable HIWAY COMPTA) ;

                        </Typography>
                        <Typography className={styles.paragraph}>
                            <span className={styles.listItemBullet}>(iii)</span>faire un usage conforme en particulier
                            des outils « Crisp » et « Slack » mis à sa disposition aux fins d’échanger avec HIWAY et
                            sa communauté. Il est précisé que le défaut de réponse d’HIWAY sur l’un ou l’autre de ces
                            outils ne saurait engager sa responsabilité à quelque titre que ce soit. S’agissant de
                            Slack, il est précisé que le Libéral s’engage à respecter la politique d’utilisation de
                            l’outil Slack spécifiant une liste de conduites acceptables ou non accessible sur le lien
                            suivant :&nbsp;
                            <a href="https://slack.com/intl/fr-fr/acceptable-use-policy" target="_blank" rel="noreferrer">
                                https://slack.com/intl/fr-fr/acceptable-use-policy.
                            </a>

                        </Typography>
                        <Typography className={styles.paragraph}>
                            Les propos tenus sur ces outils engagent la seule responsabilité du Libéral ;

                        </Typography>
                        <Typography className={styles.paragraph}>
                            <span className={styles.listItemBullet}>(iv)</span>à fournir à HIWAY des informations
                            exactes,
                            vérifiées et mises à jour ;

                        </Typography>
                        <Typography className={styles.paragraph}>
                            <span className={styles.listItemBullet}>(v)</span>dès lors qu’il effectue la Commande de
                            l’Abonnement, à honorer les sommes qui seront dues à HIWAY dans les conditions y indiquées
                            et sauf accord spécifique prévu dans l’Abonnement, et à les régler dans les délais
                            impartis ;
                        </Typography>
                        <Typography className={styles.paragraph}>
                            <span className={styles.listItemBullet}>(vi)</span>à avertir directement et sans délai HIWAY
                            de
                            toute difficulté éventuelle relative à l’exécution de ses obligations – de paiement
                            notamment –
                            à l’égard d’HIWAY ;

                        </Typography>
                        <Typography className={styles.paragraph}>
                            <span className={styles.listItemBullet}>(vii)</span>à faire son affaire du respect de la
                            législation du droit du travail et du droit fiscal en vigueur et de tout paiement de charges
                            sociales, taxes et de toutes contributions dues au titre de son activité, sans que la
                            responsabilité d’HIWAY ne puisse en aucun cas être recherchée à ce titre.
                        </Typography>

                        <Typography className={styles.paragraph}>De manière générale, le Libéral garantit HIWAY contre
                            toutes plaintes, réclamations, actions et/ou revendications quelconques qu’HIWAY pourrait
                            subir
                            du fait de la violation, par le Libéral, de l’une quelconque de ses obligations ou
                            garanties à
                            l’égard des tiers ou aux termes des présentes CGVS.
                        </Typography>
                        <Typography className={styles.paragraph}>Il s’engage à indemniser HIWAY de tout préjudice
                            qu’elle
                            subirait à ce titre et à lui payer tous les frais, charges et/ou condamnations qu’elle
                            pourrait
                            avoir à supporter de ce fait.
                        </Typography>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>4.3</span>Obligations spécifiques d’HIWAY
                        </Typography>

                        <Typography className={styles.paragraph}>
                            De manière générale, HIWAY s’engage à apporter tout le soin et les diligences nécessaires
                            dans le cadre de la fourniture des Services, en particulier aux fins de connexion et
                            d’utilisation de la Plateforme dans les conditions de l’article 3 ci-avant.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Le Libéral accepte qu’HIWAY mette en place et à sa disposition toutes solutions logicielles
                            de son choix  nécessaires à la mise en œuvre des Services.
                        </Typography>

                        <Typography className={styles.heading}>
                            <span className={styles.redText}>ARTICLE 5</span>: MODALITES DE SOUSCRIPTION À L’ABONNEMENT
                            ET
                            DUREE
                        </Typography>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>5.1</span>Conditions de la Commande
                        </Typography>

                        <Typography className={styles.paragraph}>
                            L’adhésion à l’Abonnement / aux Services d’HIWAY est ouverte à toute personne physique ou
                            morale
                            résidant en France souhaitant être assistée dans le lancement et le développement de son
                            activité en Libéral.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Pour bénéficier des Services de HIWAY, le Libéral devra accepter l’Abonnement Electronique
                            qui
                            lui aura été soumis par HIWAY et cocher la case d’acceptation prévue à cet effet, ce qui
                            vaut
                            signature électronique et engagement du Libéral sur l’Abonnement et les présentes CGVS qui
                            lui
                            seront transmises concomitamment.

                        </Typography>
                        <Typography className={styles.paragraph}>
                            C’est dans ces conditions que le Libéral procèdera à la création de ses Identifiants de
                            Connexion, selon les modalités prévues à l’article 3 ci-avant.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Chaque Libéral ne pourra être titulaire que d’un seul et unique Compte personnel par
                            Abonnement.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Il est toutefois précisé que l’accès à la Plateforme et aux Services souscrits ne sera
                            totalement effectif pour le Libéral, sous les réserves indiquées en préambule et à
                            l’article 2
                            des présentes, qu’à compter de l’immatriculation de sa société en vue d’effectuer une
                            première
                            mission.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Les Parties conviennent à ce titre qu’en cas de défaut d’immatriculation de l’entreprise du
                            Libéral dans un délai de 9 (neuf) mois à compter de la signature des présentes CGVS et de
                            l’Abonnement, les présentes prendront fin automatiquement.
                        </Typography>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>5.2</span> Conditions spécifiques de souscription à
                            l’Abonnement, objet de la Commande
                        </Typography>

                        <Typography className={styles.paragraph}>
                            La souscription à l’Abonnement dans les conditions et sous les réserves de l’article 5.1
                            ci-avant est obligatoire afin de permettre au Libéral d’accéder à la Plateforme.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Par la souscription à l’Abonnement, le Libéral s’engage à un paiement récurrent d’un
                            montant
                            défini lors de la Commande initiale, sur l’Abonnement Électronique.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Seul le paiement de chaque échéance de cet Abonnement (dans les conditions de l’article 5.3
                            ci-après) permettra au Libéral la pleine exploitation des Services conformément au
                            descriptif
                            de l’article 2.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Il est précisé que l’Abonnement est conclu à durée indéterminée auprès d’HIWAY, et ce, à
                            compter
                            du règlement de sa première échéance.
                        </Typography>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>5.3</span> Conditions financières
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Le règlement de l’Abonnement souscrit auprès de HIWAY est effectué par le Libéral
                            (dûment constitué en entreprise individuelle ou société), selon les tarifications
                            suivantes acceptées par les deux Parties et reproduites sur le Bon de Commande
                            Électronique :
                        </Typography>
                        <ul>
                            <li className={styles.customBullet}>
                                Abonnement mensuel pour les Micro BNC : 79€HT/ mois
                            </li>
                            <li className={styles.customBullet}>
                                Abonnement mensuel pour les BNC, SELASU, SELARLU, : 249€HT/mois. Une
                                facturation complémentaire annuelle de 500€HT pour le bilan sera
                                appliquée par HIWAY COMPTA
                            </li>
                            <li className={styles.customBullet}>
                                Abonnement mensuel pour les BNC, SELASU, SELARLU + HOLDING : 329€HT/mois. Une
                                facturation complémentaire annuelle de 500€HT par entreprise, pour le bilan, sera
                                appliquée par HIWAY COMPTA.
                            </li>
                        </ul>
                        <Typography className={styles.footnote}>
                            Les Services inclus dans l’Abonnement, sous les réserves visées en préambule et à l’article
                            2 des présentes CGVS, seront accessibles à compter de la date d’immatriculation de
                            l’entreprise du Libéral.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            La facturation par HIWAY du montant forfaitaire et des mensualités précités démarrera à
                            compter de la date d’immatriculation de l’entreprise du Libéral, et sauf meilleur accord
                            trouvé entre les Parties dans l’Abonnement.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            HIWAY rendra disponible au Libéral une facture mensuelle à compter du mois d’immatriculation.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Le Libéral autorise d’ores et déjà HIWAY à prélever chaque montant facturé sur son compte
                            bancaire professionnel ou dédié dès l’immatriculation de son entreprise.. Le règlement des
                            mensualités sera effectué par prélèvement automatique mensuel sur le compte bancaire
                            professionnel ou dédié du Libéral. Un mandat de prélèvement sera adressé à cet effet au
                            Libéral pour signature et acceptation.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            En cas de non-paiement ou de rejet de paiement d’une échéance mensuelle de l’Abonnement,
                            HIWAY suspendra automatiquement l’accès aux Services, et notamment à la Plateforme, le
                            temps de l’incident et jusqu’à régularisation par le Libéral, ce que le Libéral reconnaît
                            et accepte aux termes des présentes, sauf meilleur accord entre les Parties.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Il est entendu qu’indépendamment des sommes précitées, des frais pourront être facturés au
                            Libéral aux fins d’exécution de certains des Services.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Il en est ainsi des frais suivants :
                        </Typography>
                        <ul>
                            <li className={styles.customBullet}>
                                frais légaux de création de société (frais de publication dans un journal d’annonces
                                légale, frais de greffe) à hauteur de 185 € HT, avancés par HIWAY puis refacturés
                                au Libéral ;
                            </li>
                            <li className={styles.customBullet}>
                                frais d’adhésion (non obligatoire) à l’Organisme de gestion agréé OGI France : 41,66 €
                                HT pour la 1ère année puis 85 € HT/an, facturés directement au Libéral ;
                            </li>
                            <li className={styles.customBullet}>
                                bilan comptable et formalités associées, effectués par l’expert-comptable partenaire
                                HIWAY COMPTA : 500 € HT/an facturés directement au Libéral.
                            </li>
                        </ul>

                        <Typography className={styles.heading}>
                            <span className={styles.redText}>ARTICLE 6</span>: PROPRIETE INTELLECTUELLE - UTILISATION
                            DES
                            ATTRIBUTS DE LA PERSONNALITE
                        </Typography>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>6.1</span>Propriété intellectuelle de la Plateforme
                        </Typography>

                        <Typography className={styles.paragraph}>
                            L&apos;ensemble des éléments réalisés pour les besoins de l’animation et du fonctionnement
                            de la
                            Plateforme tels que les sons, les images, les photographies, les vidéos, les écrits, les
                            animations, les programmes, la charte graphique, les utilitaires, les bases de données, les
                            logiciels et toutes autres technologies sous-jacentes, est protégé par les dispositions du
                            Code
                            de propriété intellectuelle et appartient à HIWAY.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Tout signe distinctif figurant sur la Plateforme, notamment les dénominations sociales, les
                            marques et les logotypes sont des marques déposées par HIWAY ou par des tiers ou partenaires
                            et
                            exploités sur la Plateforme avec leur autorisation.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Toute reproduction totale ou partielle, modification ou utilisation de ces dénominations
                            sociales, marques, logotypes, photographies, images, sons, vidéos, écrits, animations,
                            programmes, charte graphiques, utilitaires, bases de données, logiciels ou autres
                            technologies
                            sous-jacentes, pour quelque motif et sur quelque support que ce soit, sans l’accord exprès
                            et
                            préalable de HIWAY ou des tiers concernés est strictement interdite et constituerait une
                            contrefaçon sanctionnée par le Code de la propriété intellectuelle.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Le Libéral s’engage à une utilisation du contenu de la Plateforme dans un cadre
                            strictement
                            privé.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Une utilisation de la Plateforme par le Libéral à des fins commerciales est strictement
                            interdite.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Le Libéral devra solliciter l’autorisation préalable d’HIWAY pour toute reproduction,
                            publication, copie des différents contenus de la Plateforme.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            HIWAY concède toutefois au Libéral un droit d’utilisation gratuit, non exclusif, personnel
                            et
                            non-transférable sur les contenus de la Plateforme édités par HIWAY, qu’il s’interdit
                            expressément de copier, modifier, adapter, représenter, intégrer ou exploiter de quelque
                            manière
                            que ce soit.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Plus spécifiquement, le droit d’utilisation concédé sur la Plateforme est limité au
                            Libéral
                            pour ses besoins personnels et exclusifs, lequel s’interdit formellement de laisser un tiers
                            accéder à la Plateforme. Il est également limité aux supports mobiles compatibles
                            uniquement.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Le Libéral s’interdit par ailleurs d’adapter, de corriger, d’arranger et/ou d’adapter la
                            Plateforme ou de les mettre à disposition des tiers, de les commercialiser, de les exploiter
                            ou
                            de les consentir en prêt.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Il s’interdit également d’effectuer ou de faire effectuer la correction de toute anomalie
                            sur la
                            Plateforme sans l’accord préalable d’HIWAY.
                        </Typography>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>6.2</span>Utilisation des Attributs de la personnalité
                        </Typography>
                        <Typography className={styles.paragraph}>
                            HIWAY souhaite pouvoir exploiter sur son Site Internet et ses réseaux sociaux les
                            témoignages de
                            ses abonnés, personnes physiques.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            A cette fin, le Libéral/ représentant légal du Libéral autorise HIWAY, pour la durée de
                            l’Abonnement ainsi que pour une durée de un (1) an à son issue, et pour le territoire
                            français, à fixer, reproduire et diffuser ses éléments de la Personnalité (image fixe ou
                            animée,
                            voix, nom et tout autre attribut de la personnalité, ensemble ou séparément,
                            ci-après
                            dénommés&nbsp;
                            <strong>
                                « les Éléments de la Personnalité »
                            </strong>
                            ), tels que figurant notamment sur toutes
                            photographies qui lui auront été communiquées, par tout moyen et sur tous supports de
                            communication électronique de HIWAY, notamment sur son Site Internet et ses réseaux sociaux.

                        </Typography>
                        <Typography className={styles.paragraph}>
                            La présente autorisation d’exploitation des Éléments de la personnalité du le
                            Libéral/ représentant légal du Libéral est consentie à titre gracieux, aux seules fins
                            promotionnelles de l’activité de HIWAY et sur les supports précités.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Le Libéral/ représentant légal du Libéral garantit HIWAY contre tous recours et/ou
                            réclamation émanant de tiers relatifs à une atteinte à ses Éléments de la Personnalité.

                        </Typography>
                        <Typography className={styles.paragraph}>
                            Il est expressément entendu que le Libéral/ représentant légal du Libéral conserve la
                            possibilité de retirer son autorisation à tout moment, par email à l’adresse
                            <a className={styles.link} href="mailto:digital@hiway.fr"> digital@hiway.fr</a>, ou par
                            courrier recommandé avec
                            accusé
                            de réception adressé à HIWAY, à
                            l’adresse mentionnée en tête des présentes, précisant son refus de toute nouvelle
                            utilisation
                            promotionnelle de ses Eléments de la personnalité et ce sans aucune condition.
                        </Typography>

                        <Typography className={styles.heading}>
                            <span className={styles.redText}>ARTICLE 7</span>: DONNEES PERSONNELLES
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Chaque Partie s’engage à se conformer à la règlementation applicable à la protection des
                            Données
                            Personnelles, incluant (i) le Règlement Général européen de Protection des Données
                            personnelles
                            n°2016-679 dit « RGPD » et (ii) la loi n°78-17 du 6 janvier 1978 dite « Informatique &
                            Libertés
                            » telle qu’applicable à la date des présentes.
                        </Typography>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>7.1</span>Signature des présentes CGVS
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Les Données Personnelles collectées dans le cadre de la signature et de l’exécution des
                            CGVS,
                            notamment dans le cadre de la signature de l’Abonnement Électronique, sont :
                        </Typography>

                        <Typography className={styles.paragraph}>
                            <span className={styles.listItemBullet}>(i)</span>les nom, prénom et fonction du (futur)
                            représentant légal du Libéral et du représentant légal d’HIWAY dans le cadre de la
                            signature
                            de l’Abonnement Électronique et des présentes CGVS ;
                        </Typography>

                        <Typography className={styles.paragraph}>
                            <span className={styles.listItemBullet}>(ii)</span>les nom, prénom, adresse électronique et
                            fonction des collaborateurs du Libéral et des collaborateurs d’HIWAY dans le cadre de
                            l’exécution de la Commande et des présentes CGVS.
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Les traitements concernés sont la collecte, l’enregistrement, l’organisation, la
                            conservation et
                            la consultation des Données Personnelles des personnes concernées susmentionnées aux fins de
                            conclusion et de suivi de la Commande et des présentes CGVS, par chacune des Parties en tant
                            que
                            Responsable de Traitement indépendant.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Ces Données Personnelles sont traitées dans le but de :
                        </Typography>

                        <Typography className={styles.paragraph}>
                            <span className={styles.listItemBullet}>(i)</span>disposer d’un contact permanent avec les
                            collaborateurs de l’autre Partie afin que HIWAY fournisse les Services décrits à l’article 2
                            des
                            présentes ;
                        </Typography>

                        <Typography className={styles.paragraph}>
                            <span className={styles.listItemBullet}>(ii)</span>contacter les collaborateurs de chaque
                            Partie
                            dans le cadre de la gestion de la Commande et des CGVS ;
                        </Typography>

                        <Typography className={styles.paragraph}>
                            <span className={styles.listItemBullet}>(iii)</span>suivre la facturation et l’exécution des
                            présentes CGVS.
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Ces Données Personnelles sont conservées par la société HIWAY et stockées sur les serveurs
                            de la société AWS pendant toute la durée de l’Abonnement souscrit augmentée de la durée
                            légale de conservation des documents contractuels

                        </Typography>

                        <Typography className={styles.paragraph}>
                            Il est précisé qu’aux termes du partenariat conclu par HIWAY avec la société AWS exerçant
                            en qualité de Sous-Traitant au sens du RGPD, la société AWS garantit offrir un niveau de
                            protection adéquat aux Données Personnelles des résidents des États membres de l'Union
                            Européenne, en particulier par la mise en place de « Clauses Contractuelles Types » (CCT)
                            conforment au RGPD et validées à ce titre par la Commission Européenne.

                        </Typography>

                        <Typography className={styles.paragraph}>
                            Les présentes dispositions s’appliquent dès signature des CGVS et de l’Abonnement
                            Électronique.
                        </Typography>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>7.2</span>Fourniture de la Plateforme
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Dans le cadre de la fourniture de la Plateforme, les Parties s’engagent à ce que la collecte
                            et
                            le traitement de toutes Données Personnelles effectués via la Plateforme soient réalisés
                            dans le
                            respect du RGPD et de la loi « informatique et libertés » n°78-17 du 6 janvier 1978
                            régulièrement modifiée, dont les nouvelles dispositions sont applicables depuis le 1er juin
                            2019.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            La politique de confidentialité applicable aux traitements de Données Personnelles réalisés
                            via
                            la Plateforme est prévue à <strong>« l’Accord Données Personnelles »</strong> visé à
                            l’Annexe 1
                            des présentes.
                        </Typography>

                        <Typography className={styles.heading}>
                            <span className={styles.redText}>ARTICLE 8</span>: DECLARATION D’INDEPENDANCE RECIPROQUE
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Aucune disposition des présentes ne pourra être interprétée comme autorisant l’une des
                            Parties à
                            agir pour le compte de l’autre Partie, comme agent, représentant ou mandataire de l’autre
                            Partie
                            ou encore comme constituant un contrat de société, lesquelles ne sont nullement animées de
                            l’affectio societatis.
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Chaque Partie agira en son nom propre et pour son propre compte et aucune Partie ne pourra
                            souscrire d’engagement au nom et pour le compte de l’autre Partie sans l’accord préalable
                            écrit
                            de cette dernière.
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Il est entendu notamment que dans le cadre de l’exécution des présentes CGVS, HIWAY
                            n’intervient
                            jamais en qualité de représentant légal du Libéral.
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Tout au long de l’exécution des présentes CGVS, le Libéral reste responsable de tous ses
                            actes
                            auprès de l’ensemble des tiers, administrations, clients, fournisseurs, partenaires, y
                            compris
                            auprès de ceux avec lesquels ils sont entrés en relation par l’intermédiaire et/ou en
                            qualité de
                            partenaires d’HIWAY.
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Dans ce cadre, le Libéral fera son affaire du respect des dispositions légales et
                            règlementaires le cas échéant applicables à ses différents engagements auprès de ces tiers,
                            et
                            notamment de tout éventuel paiement de charges, taxes et contributions dues de toutes
                            natures,
                            de telle sorte que la responsabilité de HIWAY ne puisse en aucune être façon recherchée.
                        </Typography>

                        <Typography className={styles.heading}>
                            <span className={styles.redText}>ARTICLE 9</span>: Résiliation
                        </Typography>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>9.1</span>Modalités de résiliation
                        </Typography>

                        <Typography className={styles.paragraph}>
                            L’Abonnement est souscrit par le Libéral sans engagement de durée.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Le Libéral est ainsi libre de résilier son Abonnement à tout moment et sans condition de
                            motif. Pour résilier son Abonnement, le Libéral notifiera sa demande par email à
                            l’adresse contact@hiway.fr ou par courrier en recommandé avec accusé de réception à
                            HIWAY, à l’adresse mentionnée en tête des présentes.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            La résiliation de l’Abonnement prendra effet au dernier jour du mois suivant la date de
                            réception de la demande par le Libéral.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Il est entendu qu’une résiliation de l’Abonnement et une suppression des accès à la
                            Plateforme,
                            et au Compte personnel pourra intervenir de plein droit à l’initiative de HIWAY en cas de
                            manquement aux obligations des présentes CGVS (notamment telles que visées aux articles 3.3
                            et
                            4), moyennant le respect d’un préavis de quinze (15) jours après l’envoi d’une mise en
                            demeure
                            adressée au Libéral et restée sans effet.
                        </Typography>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>9.2</span>Effets de la résiliation
                        </Typography>

                        <Typography className={styles.paragraph}>
                            A l’issue du dernier jour du mois suivant la demande de résiliation, pour quelque raison
                            que ce soit :
                        </Typography>

                        <Typography className={styles.paragraph}>
                            <span className={styles.redText}>(i)</span>HIWAY cessera les prélèvements automatiques sur
                            le
                            compte bancaire du Libéral ;
                        </Typography>

                        <Typography className={styles.paragraph}>
                            <span className={styles.redText}>(ii)</span>le Libéral n’aura plus la possibilité d’accéder
                            à la Plateforme, et son Compte personnel ne sera plus accessible via le
                            Site Internet d’HIWAY ;
                        </Typography>

                        <Typography className={styles.paragraph}>
                            <span className={styles.redText}>(iii)</span>le Libéral accepte d’ores et déjà de prendre
                            à sa charge les éventuels frais légaux de radiation ou de mise en sommeil de sa structure.
                            Il s’engage également à réaliser l’ensemble des démarches nécessaires au transfert de son
                            siège social et de l’ensemble de sa tenue comptable, fiscale et sociale avant le terme de
                            la collaboration et à la radiation de son entreprise étant précisé que l’intégralité des
                            frais légaux liés à ces formalités seront réglés par le Libéral au même titre que les frais
                            d’annonces légales et de greffe y afférents ;
                        </Typography>

                        <Typography className={styles.paragraph}>
                            <span className={styles.redText}>(iv)</span>les relations du Libéral avec HIWAY COMPTA en
                            ce compris l’utilisation aux logiciels Listo et Swan prendront fin automatiquement.
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Il est précisé qu’à compter de la demande de résiliation et jusqu’au dernier jour du mois
                            suivant cette demande, il appartient au Libéral de récupérer l’ensemble des informations
                            et documentations relatives à son entreprise, tels que figurant sur la Plateforme.
                        </Typography>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>9.3</span>Inactivité du Libéral
                        </Typography>

                        <Typography className={styles.paragraph}>
                            <span className={styles.redText}>9.3.1</span>Il est entendu que l’exécution des présentes
                            est fonction de l'immatriculation de l’entreprise du Libéral, ce qui doit être considéré
                            comme une condition essentielle et déterminante de la validité des présentes.
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Dans ces conditions, il est convenu entre les Parties que si le Libéral ne devait pas
                            immatriculer son entreprise dans un délai de neuf  (9) mois à compter de la date de
                            signature des présentes CGVS et de l’Abonnement Électronique, les présentes CGVS prendront
                            fin de plein droit et sans préavis, à la date d’échéance de ce neuvième mois.
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Aucune indemnité ni aucun remboursement d’aucune somme ne pourra être sollicité par le
                            Libéral
                            auprès de HIWAY.
                        </Typography>

                        <Typography className={styles.heading}>
                            <span className={styles.redText}>ARTICLE 10</span>: FORCE MAJEURE
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Les cas de force majeure tels que définis habituellement par la loi et les tribunaux
                            français (ci-après « <b>Événement de Force Majeure</b> ») empêchant une Partie de s’acquitter des
                            obligations prévues par les présentes, auront pour effet, s’ils sont avérés, de suspendre
                            l’obligation de ladite Partie concernée d’honorer les présentes pendant la durée requise
                            pour éliminer ledit Événement de Force Majeure.

                        </Typography>

                        <Typography className={styles.paragraph}>
                            La Partie victime de l’Evénement de Force Majeure le notifiera à l&apos;autre Partie en
                            précisant la
                            cause dudit Evénement de Force Majeure dans les 3 (trois) jours suivant la survenue de
                            l’Evènement de Force Majeure.
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Le délai d’exécution sera prolongé d’une période égale à la totalité de la période pendant
                            laquelle cet événement se sera poursuivi étant entendu que tant les Parties s’obligeront à
                            tout
                            mettre en œuvre pour minimiser les effets de cet événement.
                        </Typography>

                        <Typography className={styles.paragraph}>
                            A l’issue de l’événement de force majeure, la Partie affectée s’engage à faire ses meilleurs
                            efforts pour reprendre l’exécution des présentes dans les plus brefs délais et pour réduire
                            le
                            plus possible les retards dus à cet événement.
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Il est entendu, toutefois, que si l’Evénement de Force Majeure continue pendant une durée
                            supérieure à 1 (un) mois, chacune des Parties aura le droit de résilier les présentes à tout
                            moment avec effet immédiat sans pénalité, responsabilité ou autre obligation autre que le
                            paiement du prix de la rémunération au prorata des exploitations qui auront été faites.
                        </Typography>

                        <Typography className={styles.heading}>
                            <span className={styles.redText}>ARTICLE 11</span>: CONFIDENTIALITE
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Pendant toute la durée de l’Abonnement et pendant une durée de 5 (cinq) ans à compter de son
                            terme, chacune des Parties s’oblige à tenir strictement confidentiels tous les documents et
                            informations non publiés et de toutes natures dont elle aura connaissance à quelque titre
                            que ce
                            soit, relativement à l’activité de l&apos;autre Partie et aux conditions qui lui auront été
                            consenties dans le cadre des présentes CGVS.
                        </Typography>

                        <Typography className={styles.paragraph}>
                            A cette obligation de confidentialité s’ajoute pour chacune des Parties celle de ne jamais
                            exploiter ou utiliser pour son propre compte ou pour le compte d&apos;autrui, d’une
                            quelconque
                            manière, tout ou partie des informations et documents confidentiels ci-dessus définis.
                        </Typography>

                        <Typography className={styles.paragraph}>
                            De la même façon, les Parties s’engagent à assurer le secret et la confidentialité des
                            présentes
                            qui en aucun cas ne pourra être communiqué à des tiers, sauf en cas d&apos;obligation légale
                            ou
                            fiscale, ou accord préalable écrit des Parties.
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Il est entendu que cette obligation s’applique à tous salariés, collaborateurs, partenaires,
                            mandataires, dirigeants, associés de toutes natures de HIWAY et du Libéral, intervenant
                            dans
                            le cadre de l’exécution des présentes.
                        </Typography>

                        <Typography className={styles.heading}>
                            <span className={styles.redText}>ARTICLE 12</span>: STIPULATIONS GENERALES
                        </Typography>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>12.1</span>Élection de domicile
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Pour toutes notifications effectuées dans le cadre des présentes, chacune des Parties
                            déclare
                            faire respectivement élection de domicile aux adresses stipulées en-tête des présentes.
                        </Typography>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>12.2</span>Non renonciation
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Le fait pour l’une Partie de ne pas exiger le strict respect d’une quelconque obligation des
                            présentes, en quelque occasion que ce soit, ne sera pas considéré comme une renonciation à
                            s’en
                            prévaloir ultérieurement.
                        </Typography>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>12.3</span>Intégralité
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Les présentes CGVS constituent l’intégralité de l’accord des Parties concernant l’objet
                            couvert
                            par les présentes et prévaut sur tout autre accord verbal ou écrit pouvant être intervenu
                            entre
                            les Parties préalablement à sa signature. Aucun avenant ni aucune modification au contenu
                            des
                            présentes ne pourra lier les Parties sans avoir fait l’objet d’un avenant signé par les
                            représentants habilités de chacune des Parties.
                        </Typography>
                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>12.4</span>Nullité
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Pour le cas où l’une des clauses des présentes CGVS serait déclarée nulle ou ne pourrait
                            être
                            mise en œuvre, toutes les autres clauses demeureront valides et continueront de lier les
                            Parties, sauf à ce que l’annulation ou l’absence d’effet de ladite clause modifie l’économie
                            et/ou l’esprit des présentes.
                        </Typography>

                        <Typography className={styles.heading}>
                            <span className={styles.redText}>ARTICLE 13</span>: LOI APPLICABLE / ATTRIBUTION DE
                            COMPETENCE
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Les présentes CGVS sont régies par la loi française.
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Les Parties s’efforceront de résoudre à l’amiable tout différend susceptible de naître de
                            l’interprétation et/ou l’exécution des présentes CGVS. Toutefois, et à défaut d’accord,
                            toute
                            contestation portant sur l’application ou l’interprétation des présentes relèvera de la
                            compétence exclusive des tribunaux de Paris.
                        </Typography>
                    </CardContent>
                </Card>
                <Typography className={styles.info}>
                    Hiway SAS - Capital social : 9 446,85€ - Siège social : 14 rue Bausset 75015 Paris - SIRET 80106660074
                    000035 RCS Paris - TVA FR22080106660074
                </Typography>
            </Container>
        </div>
    );
};

LegalPortageCGVS.propTypes = {
    isHeaderShown: PropTypes.bool,
};

LegalPortageCGVS.defaultProps = {
    isHeaderShown: true,
};

export default LegalPortageCGVS;
