import {Divider, FormLabel, Grid, MenuItem, TextField} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {Controller} from 'react-hook-form';
import PersonalInformationMedical from './PersonalInformationMedical';
import PersonalInformationMutual from './PersonalInformationMutual';
import {BE_DATE_FORMAT} from '../../../../../../utils/constants';
import {COUNTRIES} from '../../../../../../utils/countries';
import {NATIONALITIES} from '../../../../../../utils/nationalities';
import {REGIONAL_DEPARTMENTS} from '../../../../../../utils/regional-departments';
import {TITLES} from '../../../../../company/modules/setup-company/utils/constants';
import {
    usePersonalInformationFormStyles,
} from '../../../../../freelancer/modules/company-setup/components/PersonalInformationForm/styles';

const PersonalInformationMobile = ({
    t,
    control,
    errors,
    userLanguage,
    isRegionalDepartmentOfBirthDisabled,
    hasNoMedicalCheckup,
    hasPersonalInsuranceDocument,
    isSubscribedToHiwayInsurance,
    setValue,
}) => {
    const styles = usePersonalInformationFormStyles();

    const maxDate = moment('01/01/2100', BE_DATE_FORMAT);
    const minDate = moment('01/01/1900', BE_DATE_FORMAT);

    const titleOptions = useMemo(() => {
        return Object.values(TITLES).map(title => (
            <MenuItem value={title} key={title}>
                {t(`companies:titles.${title}`)}
            </MenuItem>
        ));
    }, [t]);

    const nationalityOptions = useMemo(() => {
        return NATIONALITIES.map(nationality => (
            <MenuItem value={nationality.code} key={nationality.code}>
                {nationality.name[userLanguage]}
            </MenuItem>
        ));
    }, [userLanguage]);

    const countryOptions = useMemo(() => {
        return COUNTRIES
            .sort((a, b) => {
                return a.name[userLanguage].localeCompare(b.name[userLanguage], userLanguage);
            })
            .map(country => (
                <MenuItem value={country.code} key={country.code}>
                    {country.name[userLanguage]}
                </MenuItem>
            ));
    }, [userLanguage]);

    const regionalDepartmentOptions = useMemo(() => {
        return REGIONAL_DEPARTMENTS
            .sort((a, b) => a.id.localeCompare(b.id, undefined, {numeric: true, sensitivity: 'base'}))
            .map(regionalDepartment => {
                let name = regionalDepartment.name;

                if (typeof name === 'object' && name !== null) {
                    name = name[userLanguage];
                }

                return (
                    <MenuItem value={regionalDepartment.id} key={regionalDepartment.id}>
                        {`${regionalDepartment.id}${name ? ` (${name})` : ''}`}
                    </MenuItem>
                );
            });
    }, [userLanguage]);

    return (
        <div className={styles.container}>
            <FormLabel component="legend" className={styles.formLabel}>
                {t('fullName')}
            </FormLabel>

            <Grid container spacing={4}>
                <Grid item xs={12} md={2}>
                    <Controller
                        name="title"
                        control={control}
                        rules={{required: true}}
                        render={({field}) => {
                            return (
                                <TextField
                                    {...field}
                                    label={t('title')}
                                    variant="filled"
                                    error={!!errors.title}
                                    helperText={errors.title?.message}
                                    select
                                    required
                                    fullWidth
                                >
                                    {titleOptions}

                                </TextField>
                            );
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={5}>
                    <Controller
                        name="firstName"
                        control={control}
                        render={({field}) => {
                            return (
                                <TextField
                                    {...field}
                                    label={t('firstName')}
                                    variant="filled"
                                    error={!!errors.firstName}
                                    helperText={errors.firstName?.message}
                                    required
                                    fullWidth
                                />
                            );
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={5}>
                    <Controller
                        name="lastName"
                        control={control}
                        render={({field}) => {
                            return (
                                <TextField
                                    {...field}
                                    label={t('lastName')}
                                    variant="filled"
                                    error={!!errors.lastName}
                                    helperText={errors.lastName?.message}
                                    required
                                    fullWidth
                                />
                            );
                        }}
                    />
                </Grid>
            </Grid>

            <Divider className={styles.divider} />

            <FormLabel component="legend" className={styles.formLabel}>
                {t('dateOfBirth')}
            </FormLabel>

            <Grid container spacing={4}>
                <Grid item xs={12} md={7}>
                    <Controller
                        name="dateOfBirth"
                        control={control}
                        render={({field}) => {
                            return (
                                <DatePicker
                                    {...field}
                                    label={t('dateOfBirth')}
                                    invalidDateMessage={t('form:validation.notDate')}
                                    error={!!errors.dateOfBirth}
                                    helperText={errors.dateOfBirth?.message}
                                    openTo="year"
                                    format={moment.localeData().longDateFormat('L')}
                                    views={['year', 'month', 'day']}
                                    inputVariant="filled"
                                    minDateMessage={t('form:validation.minDate', {date: minDate.format('L')})}
                                    maxDateMessage={t('form:validation.maxDate', {date: maxDate.format('L')})}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="filled"
                                            sx={{width: '100%'}}
                                        />
                                    )}
                                />
                            );
                        }}
                        required
                        fullWidth
                        disableFuture
                    />
                </Grid>
            </Grid>

            <Divider className={styles.divider} />

            <FormLabel component="legend" className={styles.formLabel}>
                {t('and', {first: t('placeOfBirth'), second: t('nationality')})}
            </FormLabel>

            <Grid container spacing={4}>
                <Grid item xs={12} md={7}>
                    <Controller
                        name="countryOfBirth"
                        control={control}
                        rules={{required: true}}
                        render={({field}) => {
                            return (
                                <TextField
                                    {...field}
                                    label={t('countryOfBirth')}
                                    variant="filled"
                                    error={!!errors.countryOfBirth}
                                    helperText={errors.countryOfBirth?.message}
                                    required
                                    fullWidth
                                    select
                                >
                                    {countryOptions}
                                </TextField>
                            );
                        }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={4} sx={{mt: -2}}>
                <Grid item xs={12} md={7}>
                    <Controller
                        name="placeOfBirth"
                        control={control}
                        rules={{required: true}}
                        render={({field}) => {
                            return (
                                <TextField
                                    {...field}
                                    label={t('placeOfBirth')}
                                    variant="filled"
                                    error={!!errors.placeOfBirth}
                                    helperText={errors.placeOfBirth?.message}
                                    required
                                    fullWidth
                                />
                            );
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={5}>
                    <Controller
                        name="postalCode"
                        control={control}
                        render={({field}) => {
                            return (
                                <TextField
                                    {...field}
                                    label={t('postalCode')}
                                    variant="filled"
                                    error={!!errors.postalCode}
                                    helperText={errors.postalCode?.message}
                                    required
                                    fullWidth
                                />
                            );
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={5}>
                    <Controller
                        name="regionalDepartmentOfBirth"
                        control={control}
                        rules={{required: true}}
                        render={({field}) => {
                            return (
                                <TextField
                                    {...field}
                                    label={t('regionalDepartmentOfBirth')}
                                    variant="filled"
                                    error={!!errors.regionalDepartmentOfBirth}
                                    helperText={errors.regionalDepartmentOfBirth?.message}
                                    disabled={isRegionalDepartmentOfBirthDisabled}
                                    required
                                    fullWidth
                                    select
                                >
                                    {regionalDepartmentOptions}
                                </TextField>
                            );
                        }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={4} sx={{mt: -2}}>
                <Grid item xs={12} md={7}>
                    <Controller
                        name="nationality"
                        control={control}
                        rules={{required: true}}
                        render={({field}) => {
                            return (
                                <TextField
                                    {...field}
                                    label={t('nationality')}
                                    variant="filled"
                                    error={!!errors.nationality}
                                    helperText={errors.nationality?.message}
                                    required
                                    fullWidth
                                    select
                                >
                                    {nationalityOptions}
                                </TextField>
                            );
                        }}
                    />
                </Grid>
            </Grid>

            <Divider className={styles.divider} />

            <FormLabel component="legend" className={styles.formLabel}>
                {t('socialSecurityNumber')}
            </FormLabel>

            <Grid container spacing={4}>
                <Grid item xs={12} md={7}>
                    <Controller
                        name="socialSecurityNumber"
                        control={control}
                        rules={{required: true}}
                        render={({field}) => {
                            return (
                                <TextField
                                    {...field}
                                    label={t('socialSecurityNumber')}
                                    variant="filled"
                                    error={!!errors.socialSecurityNumber}
                                    helperText={errors.socialSecurityNumber?.message}
                                    required
                                    fullWidth
                                />
                            );
                        }}
                    />
                </Grid>
            </Grid>

            <Divider className={styles.divider} />

            <PersonalInformationMedical
                t={t}
                control={control}
                errors={errors}
                hasNoMedicalCheckup={hasNoMedicalCheckup}
                isMobileSize

            />

            <Divider className={styles.divider} />

            <PersonalInformationMutual
                t={t}
                personalInsuranceDocument={hasPersonalInsuranceDocument}
                subscribedToHiwayInsurance={isSubscribedToHiwayInsurance}
                formErrors={errors}
                setValue={setValue}
            />
        </div>
    );
};

PersonalInformationMobile.propTypes = {
    t: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    userLanguage: PropTypes.string.isRequired,
    isRegionalDepartmentOfBirthDisabled: PropTypes.bool.isRequired,
    hasNoMedicalCheckup: PropTypes.bool.isRequired,
    hasPersonalInsuranceDocument: PropTypes.bool.isRequired,
    isSubscribedToHiwayInsurance: PropTypes.bool.isRequired,
    setValue: PropTypes.func.isRequired,
};

export default PersonalInformationMobile;
