import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Button, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {QuickAccessAction} from './store/quick-access.action';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {LocalesConstants} from '../../../../utils/locales-constants';
import {UiActions} from '../../../ui/store/ui.action';
import {ModalsKeys} from '../../../ui/utils/constants';

const modalKey = ModalsKeys.RENEW_CERTIFICATE_MODAL;

const RenewCertificateModal = ({freelancerId, companyId}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation(LocalesConstants.Documents);
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const handleCloseModal = () => {
        dispatch(UiActions.setActiveModal(modalKey, false));
    };

    const handleConfirm = () => {
        dispatch(QuickAccessAction.reissueVigilanceCert({freelancerId, companyId}));

        handleCloseModal();
    };

    return (
        <ModalWrapper
            modalKey={modalKey}
            handleCloseCB={handleCloseModal}
            hasShowClose={true}
            containerSx={{width: '600px'}}
            title={(
                <Typography sx={{
                    fontSize: theme => theme.typography.pxToRem(34),
                    fontWeight: 700,
                    lineHeight: '116.7%',
                    pb: 1,
                }}
                >
                    {t('quickAccess.modal.title')}
                </Typography>
            )}
        >
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 6}}>
                <Typography>
                    {t('quickAccess.modal.description')}
                </Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: isMobileSize ? 'column' : 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: isMobileSize ? 2 : 3,
                    flex: '1 0 0',
                    alignSelf: 'stretch',
                }}
                >
                    <Button
                        fullWidth={isMobileSize}
                        variant="outlined"
                        startIcon={<ArrowBackIcon />}
                        onClick={handleCloseModal}
                    >
                        {t('quickAccess.modal.cancel')}
                    </Button>
                    <Button
                        fullWidth={isMobileSize}
                        variant="contained"
                        color="secondary"
                        endIcon={<ArrowForwardIcon />}
                        onClick={handleConfirm}
                    >
                        {t('quickAccess.modal.confirm')}
                    </Button>
                </Box>
            </Box>
        </ModalWrapper>
    );
};

RenewCertificateModal.propTypes = {
    freelancerId: PropTypes.string.isRequired,
    companyId: PropTypes.string.isRequired,
};

export default RenewCertificateModal;
